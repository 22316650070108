<template>
    <div class="content-filter-elements" id="menuAnchor">
        <v-autocomplete
            :loading="searching"
            :items="items"
            item-text="name"
            :search-input.sync="search"
            filled
            hide-no-data
            :label="label"
            append-icon="mdi-magnify"
            return-object
            clearable
            :spellcheck="false"
            :disabled="disabled"
            attach="#menuAnchor" 
        ></v-autocomplete>
    </div>
</template>

<script>
import { CancellingHttp } from "@/infrastructure/communication";

export default {
    name: "ContentFilterElements",
    components: {},
    emits: ['items-loaded'],
    props: {
        getSearchUrl: null,
        label: {
            type: String,
        },
        disabled: Boolean
    },
    data() {
        return {
            com: new CancellingHttp(),
            abortController: null,
            searching: false,
            items: [],
            search: null
        }
    },
    watch: {
        search(val) {
            val && val !== this.select && this.querySelections(val)
        },
    },
    methods: {
        async querySelections(val) {
            if (val !== '') {
                let url = this.getSearchUrl(val)
                this.searching = true
                let response = await this.com.get(url)
                this.items = (response && response.data) || []
                if (this.items.length > 0) {
                    this.$emit('items-loaded', this.items)
                }
                if (response && response.data) {
                    this.searching = false
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">

.v-autocomplete__content {
    display: none;
}

</style>
