<template>
  <div>
    <v-btn
        v-if="hasChanges && !isLoading"
        elevation="0"
        color="primary"
        class="filter-apply icon-left"
        @click="$emit('click', arguments)"
    >
      <v-icon>mdi-check</v-icon>
      {{ $t('components.filter-apply.label') }}
    </v-btn>
    <v-btn
        v-if="!hasChanges && !isLoading"
        elevation="0"
        color="primary"
        class="filter-apply icon-left"
        @click="$emit('click', arguments)"
    >
      <v-icon>mdi-refresh</v-icon>
      {{ $t('components.filter-refresh.label') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "AppFilterApplyRefreshButton",
  props: {
    isLoading: Boolean,
    hasChanges: Boolean,
  }
}
</script>

<style scoped>

</style>
