<template>
  <div class="range-input">
    <div class="range-input range-input__slider">
      <vue-slider ref="target" v-model="sliderVal"></vue-slider>
    </div>
    <md-input-container class="range-input range-input__number sp-float-left">
      <input type="number" :required="true" name="range" v-model="inputVal" min="min" max="max"/>
    </md-input-container>
    <span class="range-input range-input__description">Prozent <sup>*</sup></span>
    <div class="sp-clearfix"></div>
    <span v-if="error" class="md-error">{{ error[name][0] }}</span>
  </div>
</template>

<script>
  import vueSlider from 'vue-slider-component'
  import {EventBus} from './../../event-bus.js'

  export default {
    components: {
      vueSlider
    },
    props: {
      name: null,
      value: {
        required: false,
        default: 0
      },
      target: {
        required: false
      },
      min: {
        type: Number,
        required: false,
        default: 0
      },
      max: {
        type: Number,
        required: false,
        default: 100
      },
      error: null
    },
    data () {
      return {
        sliderVal: this.inputValue(),
        inputVal: this.inputValue()
      }
    },
    mounted () {
      this.inputValue()
    },
    methods: {
      inputValue () {
        if (this.value === false) {
          return 0
        } else {
          this.inputVal = this.value
          return this.inputVal
        }
      },
      'update': function (val) {
        if (val > this.max) {
          this.sliderVal = this.max
          this.inputVal = this.max
        }

        if (val < this.min) {
          this.sliderVal = this.min
          this.inputVal = this.min
        }

        if (val === '') {
          this.sliderVal = 0
        }
        EventBus.$emit(this.target, val)
      }
    },
    watch: {
      'sliderVal': function (val) {
        this.inputVal = val
        this.update(val)
      },
      'inputVal': function (val) {
        this.sliderVal = val
        this.update(val)
      }
    }
  }
</script>

<style lang="scss">
  .md-error {
    color: #FF5252 !important;
    height: 20px;
    font-size: 12px;
  }
  .range-input {
    margin-bottom: 0 !important;
    &__slider {
      width: 100%;
      margin-top: 40px;
    }
    &__number {
      width: 20% !important;
    }
    &__description {
      line-height: 70px;
    }

  }
</style>
