<template>
    <div>
      <BasicNewContainer
          :title="t('create.title')"
          ref="editor"
          @cancel="close"
          :saveAction="saveItem"
          @saved="onSaved"
          :has-changes="hasChanges">
        <template>
          <v-card
              elevation="0"
          >
            <v-card-title>{{ t('details.title') }}</v-card-title>
            <v-card-text>
  
              <v-text-field
                  :label="t('details.title')"
                  v-model="data.title"
                  data-cy="title"
                  counter="120"
                  filled
                  :rules="[
                                  () => !!data.title || $t('validation.required.default'),
                                  () => (data.title && data.title.length <= 120) || $t('validation.length.default')
                              ]"
              ></v-text-field>
  
            </v-card-text>
          </v-card>
  
        </template>
      </BasicNewContainer>
    </div>
  </template>
  
  <script>
  import {HTTP} from './../auth'
  import BasicNewContainer from "@/components/inputs/BasicNewContainer";
  
  export default {
    name: 'SettingsNotificationTypeCreate',
    components: {
      BasicNewContainer
    },
    data() {
      return {
        data: {
          id: null,
          title: ''
        },
        rolesData: [],
        dataOriginal: null,
        dataLoaded: false,
        indexRouteName: 'SettingsNotificationTypeIndex'
      }
    },
    computed: {
      hasChanges() {
        return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
      },
    },
    mounted() {
      this.dataLoaded = true
    },
    created() {
      this.dataOriginal = JSON.parse(JSON.stringify(this.data));
    },
    methods: {
  
      t: function (key) {
        return this.$t('settings.notification-type.detail.' + key);
      },
      setRole(role, active) {
        let newList = new Set([...this.data.roles])
        if (active) {
          newList.add(role)
        } else {
          newList.delete(role)
        }
        this.data.roles = Array.from(newList);
      },
      saveItem() {
        return HTTP.post('notification_types', this.data)
      },
      async onSaved() {
        this.dataOriginal = null;
        await this.close();
      },
      async close() {
        await this.$router.push({name: this.indexRouteName})
      }
    }
  }
  </script>
  