<template>
  <md-list-item >
    <v-icon>{{ item.icon }}</v-icon>
    <span class="module-title">{{ item.title }}</span>
    <md-list-expand>
      <md-list>
        <md-list-item class="md-inset">
          <router-link :to="{ path: '/checklists' }">
            <span>{{ $t('navigation.checklists-create') }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset">
          <router-link :to="{ path: '/checklist-tasks' }">
            <span>{{ $t('navigation.checklists-taskcreate') }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset">
          <router-link :to="{ path: '/checklist-subtasks' }">
            <span>{{ $t('navigation.checklists-subtaskcreate') }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset">
          <router-link :to="{ path: '/quality-reports' }">
            <span>{{ $t('navigation.qualityreports') }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset">
          <router-link :to="{ path: '/statistics' }">
            <span>{{ $t('navigation.reports') }}</span>
          </router-link>
        </md-list-item>
        <md-list-item class="md-inset">
          <router-link :to="{ path: '/settings/checklists/tags' }">
            <span>{{ $t('navigation.settings-checklisttags') }}</span>
          </router-link>
        </md-list-item>
      </md-list>
    </md-list-expand>
  </md-list-item>
</template>

<script>
export default {
  name: 'ChecklistListItem',
  data() {
      return {}
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: {}
  },
    user: {
      type: Object,
      required: false,
      default: {}
    },
    modules: {
      type: Array,
      required: false,
      default: []
    }
  }  
}
</script>