import { defineStore, acceptHMRUpdate } from 'pinia'

const store = defineStore('ShopFilterStore', {
  state: () => {
    return {
      filter: {
        searchTerm: '',
        categories: [],
        status: ['active']
      },
      appliedFilter: '',
      table: {
        currentPage: 1,
        itemsPerPage: 25,
        sortBy: 'title',
        sortDesc: false
      }
    }
  },
  getters: {
    hasChanges: (state) => {
      return JSON.stringify(state.filter) !== JSON.stringify(state.appliedFilter)
    }
  },
  actions: {
    filterApplied() {
      this.appliedFilter = { ...this.filter }
    }
  },
  persist: true,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const useShopFilterStore = store

