<template>
  <div>
    <BasicSettingsContainer
        :title="$t('settings.work-reports-settings.title')"
        :data-loaded="dataLoaded"
        :has-changes="hasChanges"
        @reset="reset"
        :save-action="save"
    >

      <v-card elevation="0">
        <v-card-title>{{ $t('settings.work-reports-settings.employee-selection') }}</v-card-title>
        <v-card-text>
          <v-text-field
              :label="$t('settings.work-reports-settings.employee-selection-label')"
              v-model="item.label_text"
              filled
          />

          <v-select
              :title="$t('components.permissiongroup-select.select')"
              :label="$t('components.permissiongroup-select.select')"
              v-model="item.permission_group"
              :items="permissionGroups"
              item-text="name"
              item-value="id"
          >
          </v-select>

        </v-card-text>
      </v-card>

      <v-card elevation="0" class="sp-mar-top-3">
        <v-card-title>{{ $t('settings.work-reports-settings.email-config') }}</v-card-title>
        <v-card-text>
          <v-checkbox
              :label="$t('settings.work-reports-settings.workreport-created-email')"
              v-model="item.email_workreport_created"
              filled
          />
        </v-card-text>
      </v-card>
    </BasicSettingsContainer>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import toolbar from '../components/layouts/Navigation.vue'
import loading from '../components/layouts/Loading.vue'
import AppDialogConfirmSave from "../components/vuetify/AppDialogConfirmSave";
import BasicSettingsContainer from "../components/inputs/BasicSettingsContainer.vue";

export default {
  name: 'WorkReportSettings',
  components: {
    BasicSettingsContainer,
    toolbar,
    loading,
    AppDialogConfirmSave
  },
  data() {
    return {
      item: {
        label_text: '',
        permission_group: null,
        email_workreport_created: false,
      },
      itemOriginal: null,
      dataLoaded: false,
    }
  },
  async created() {
    await this.loadPermissionGroups()
    await this.getData()
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
  },

  methods: {
    async loadPermissionGroups() {
      let response = await HTTP.get('permission-groups?sortBy[]=name')
      this.permissionGroups = response.data.data
    },
    async getData() {
      this.dataLoaded = false
      let response = await HTTP.get('work-reports/settings')
      this.item.label_text = response.data.label_text
      this.item.permission_group = response.data.permission_group
      this.item.email_workreport_created = response.data.email_workreport_created
      this.itemOriginal = JSON.parse(JSON.stringify(this.item));
      this.dataLoaded = true
    },
    async reset() {
      await this.getData()
    },
    async save() {
      await HTTP.post('work-reports/settings', this.item)
      await this.getData()
    },
  }
}
</script>

<style scoped lang="scss">

.settings-wrapper {
  justify-content: space-between;

  .v-card {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
}

</style>
