<template>
    <file-tree-list-item
        :model="treeData"
        :preselectedItems="value"
        v-model="selectedItems"
        @item-selected="onItemSelected"
        @item-unselected="onItemUnselected"
    />
</template>

<script>
export default {
    name: "FileTreeSelector",
    props: {
        treeData: {},
        value: {}
    },
    data() {
        return {
            selectedItems: [],
        }
    },
    created() {
    },
    methods: {
        onItemSelected(item) {
            if (!this.selectedItems.includes(item.id)) {
                this.selectedItems.push(item.id)
            }
            this.$emit('input', this.selectedItems)
        },
        onItemUnselected(item) {
            if (this.selectedItems.includes(item.id)) {
                this.selectedItems.splice(this.selectedItems.indexOf(item.id), 1)
            }
            this.$emit('input', this.selectedItems)
        },
    }
}
</script>
