var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gmap-map',{ref:"mapRef",staticStyle:{"width":"100%","height":"350px"},attrs:{"center":{lat: 47.3778579, lng: 8.5403226},"zoom":17,"map-type-id":"terrain","options":{
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: false
  }}},[_c('gmap-marker',{attrs:{"icon":_vm.markerOptions,"position":{
      lat: _vm.data.lat,
      lng: _vm.data.lng
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }