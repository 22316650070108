<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">
        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter()">
          <AppFilterApplyButton
              v-if="filterChanged"
              @click="applyFilter()"
          ></AppFilterApplyButton>

          <v-slide-group
              show-arrows
          >
            <v-slide-item>

              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <app-filter-search-field
                        v-model="searchTerm"
                        :filterLabel="$t('components.app-filter-search-field.label.for-customer-projects')"
                        clearable
                        @click:clear="clearSearchTerm"
                        append-icon="mdi-magnify"
                    ></app-filter-search-field>
                  </div>
                </div>
              </div>

            </v-slide-item>
            <v-slide-item>

              <div class="filter-status-filter">
                <div class="filter-item">
                  <v-select
                      v-model="selectedFilterStatus"
                      :label="t('filter.status')"
                      :items="filterStatus"
                      item-text="text"
                      item-value="slug"
                      multiple
                      chips
                  ></v-select>
                </div>
              </div>

            </v-slide-item>

          </v-slide-group>

        </v-container>
      </div>

      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div>
            <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                data-cy="createButton"
                :to="{ path: '/customer-projects/create?custid=' + this.customerId}"
            >
              <v-icon>mdi-plus</v-icon>
              {{ t('create') }}
            </v-btn>
          </div>
        </v-row>
        <v-row v-if="!loading && items.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>
        <v-row v-else>
          <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              class="elevation-0"
              :footer-props="{
						showFirstLastPage: true,
						firstIcon: 'mdi-arrow-collapse-left',
						lastIcon: 'mdi-arrow-collapse-right',
						prevIcon: 'mdi-minus',
						nextIcon: 'mdi-plus',
						'items-per-page-options': [10, 25, 50],
						pageText: '{0}-{1} von {2}',
						'items-per-page-text': t('table.items-per-page')
					}"
          >
            <v-progress-linear v-show="loading" slot="progress" color="primary" indeterminate></v-progress-linear>

            <template v-slot:[`item.name`]="{ item }">
              <span class="ellipsis">{{ item.name }}</span>
            </template>

            <template v-slot:[`item.cost-center`]="{ item }">
              {{ item.cost_center }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
              {{
                item.is_active
                    ? $t('customer-projects.data.status.active')
                    : $t('customer-projects.data.status.inactive')
              }}
            </template>

            <template v-slot:[`item.details`]="{ item }">
              <router-link :to="{ path: '/customer-projects/'+item.id }" data-cy="editButton">
                <v-icon small class="edit-row-button">edit</v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </div>
  </div>

</template>

<script>
import {HTTP} from "@/auth";
import AppFilterApplyButton from "@/components/vuetify/AppFilterApplyButton";
import toolbar from '../components/layouts/Navigation.vue'
import AppFilterSearchField from "../components/vuetify/AppFilterSearchField"

export default {
  name: "CustomersProjectsIndex",
  components: {
    AppFilterApplyButton,
    AppFilterSearchField,
    toolbar,
  },
  data() {
    return {
      customerId: null,
      loading: false,
      selectedFilterStatus: [
        'ACTIVE', 'INACTIVE'
      ],
      filterChanged: false,
      filter: {},
      items: [],
      searchTerm: ''
    }
  },
  async created() {
    this.customerId = this.$route.params.id;
    await this.loadData();
  },
  watch: {
    filter: {
      handler() {
        this.filterChanged = JSON.stringify(this.filter) !== JSON.stringify(this.filterApplied);
      }, deep: true
    },
    selectedFilterStatus: function (after, pre) {
      this.filterChanged = after.length !== pre.length
    },
    searchTerm: function (after, pre) {
      if (typeof after === 'string' || after instanceof String) {
        this.filterChanged = this.filterChanged || (pre.length > 1 && after.length !== pre.length)
      } else {
        this.searchTerm = ''
      }
    }
  },
  computed: {
    filterStatus() {
      return [
        {
          text: this.$t('customer-projects.data.status.active'),
          slug: 'ACTIVE'
        },
        {
          text: this.$t('customer-projects.data.status.inactive'),
          slug: 'INACTIVE'
        },
      ]
    },
    headers() {
      return [
        {
          text: this.t('table.project-name'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: this.t('table.cost-center'),
          align: 'start',
          sortable: false,
          value: 'cost-center'
        },
        {
          text: this.t('table.assigned-contacts'),
          align: 'start',
          sortable: false,
          value: 'assigned-contacts'
        },
        {
          text: this.t('table.status'),
          align: 'start',
          sortable: false,
          value: 'status'
        },
        {
          text: this.t('table.details'),
          align: 'center',
          sortable: false,
          value: 'details'
        },
      ]
    },
  },
  methods: {
    t: function (key) {
      return this.$t('customer-projects.list.' + key);
    },
    buildFilter() {
      return {};
    },
    async loadData() {
      this.filterApplied = JSON.parse(JSON.stringify(this.filter));

      let filter = this.buildFilter();
      try {
        this.loading = true;
        let response = await HTTP.get('customers/' + this.customerId + '/projects', {params: filter})
        this.items = response.data.data;
      } finally {
        this.loading = false;
      }
    },
    async reload() {
      this.items = [];
      await this.loadData();
    },
    applyFilter() {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      this.reload()
    },
    clearSearchTerm() {
      this.filterChanged = true
    },
  }
}
</script>

<style scoped lang="scss">

.sp-hero .filter-status-filter {
  width: calc(50% - 134px);
}

.ellipsis {
  display: block;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.status-filter {
  position: relative;
  float: right;
  max-width: calc(50% - 12px);
  min-height: 48px;
  margin: 4px 0 24px;
  padding-top: 6px;
}

.status-filter.extended {
  padding-right: 160px;
}

.status-filter button {
  position: absolute;
  top: 12px;
  right: 0;
}

.comment {
  margin-right: 10px;
}

.status i,
.status-new i,
.status-approved i,
.status-declined i,
.status-deleted i,
.status-approved-exported i {
  font-size: 30px;
  position: relative;
  left: -8px;
  top: -6px;
}

.status-new i {
  color: var(--v-warning-base);
}

.status-approved i {
  color: var(--v-success-base);
  font-size: 28px;
}

.status-declined i {
  color: var(--v-error-base);
}

.status-approved-exported {
  position: relative;
}

.status-approved-exported i {
  position: absolute;
  font-size: 18px;
  left: -8px;
  top: -5px;
  color: var(--v-success-base);
}

</style>
