<template>
    <md-list-item >
        <v-icon>{{ item.icon }}</v-icon>
        <span class="module-title">{{ item.title }}</span>
        <md-list-expand>
            <md-list>
            <md-list-item class="md-inset">
                <router-link :to="{ path: '/servappt/requests' }">
                <span>{{ $t('navigation.servappt-requests') }}</span>
                </router-link>
            </md-list-item>
            <md-list-item class="md-inset">
                <router-link :to="{ path: '/servappt/responses' }">
                <span>{{ $t('navigation.servappt-responses') }}</span>
                </router-link>
            </md-list-item>
            <md-list-item class="md-inset">
                <router-link :to="{ path: '/servappt/appointments' }">
                <span>{{ $t('navigation.servappt-appointments') }}</span>
                </router-link>
            </md-list-item>
            <md-list-item class="md-inset" v-if="canAppointmentsSee()">
                <router-link :to="{ path: '/servappt/servappt-areas' }">
                  <span>{{ $t('navigation.servappt-servapptareas') }}</span>
                </router-link>
              </md-list-item>
              <md-list-item class="md-inset">
                <router-link :to="{ path: '/servappt/email-settings' }">
                <span>{{ $t('navigation.servappt-emailsettings') }}</span>
                </router-link>
            </md-list-item>
            </md-list>
        </md-list-expand>
    </md-list-item>
</template>
<script>
</script>

<script>
import {hasPermission} from "@/services/PermissionService"
import store from 'store';


export default {
    name: 'ServapptListItem',
    data() {
        return {
            user: ''
        }
    },
    props: {
        item: {
            type: Object,
            required: false,
            default: {}
        },
        modules: {
            type: Array,
            required: false,
            default: []
        }
    },
    mounted() {
       this.getUser();

    },
    methods: {
        getUser() {
            this.user = store.get('user')
        },
        canAppointmentsSee() {
            return hasPermission('servappt_appointment_manage') || this.isAdmin();
        },
        isAdmin() {
            return this.user && this.user.role === "admin"
        },
    }
    
}
</script>