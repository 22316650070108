<template>
  <div>
    <v-container>
      <v-row class="header-row">
        <v-col cols="3" class="header-header pa-0">
          <span>{{ weekNumber }}</span>
        </v-col>
        <v-col v-for="date in dates" :key="date.unix()" class="date-header pa-0 mb-2">
          <span>{{ date.format('ddd') }} {{ date.format('DD.MM.') }}</span>
        </v-col>
        <v-col class="total-header  pa-0">
          <span>{{ t('total') }} {{ t('hours-short') }}</span>
        </v-col>
      </v-row>
      <v-row class="content-row mb-4" v-for="project in projects " :key="getProjectKey(project)">
        <v-col cols="3" class="content-header pa-3 bg-field">
          {{ getProjectDisplay(project) }}
        </v-col>
        <v-col v-for="hoursOfDayOfProject in getHoursPerDayByProject(project)" class="time pa-3 bg-field text-center">
          {{ convertToHourDecimal(hoursOfDayOfProject) }}
        </v-col>
        <v-col class="total pa-3 text-right">
          {{ convertToHourDecimal(getProjectTotal(project)) }}
        </v-col>
      </v-row>

      <v-row class="footer-row pa-0">
        <v-col cols="3" class="total-row day-total-header pa-3">
          {{ t("total") }}
        </v-col>
        <v-col v-for="date in dates" class="total-row day-total pa-3 text-center">
          {{ convertToHourDecimal(getDayTotal(date)) }}
        </v-col>
        <v-col class="total-row total-of-total pa-3 text-right">
          {{ convertToHourDecimal(getTotalOfTotal()) }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: "TimesheetApprovalEditWeekView",
  props: ['startDate', 'value'],
  data() {
    return {
      entries: this.value
    }
  },
  computed: {
    weekNumber() {
      return this.t('week-short') + moment(this.startDate).isoWeek();
    },
    dates() {
      let dates = [];
      for (let i = 0; i < 7; i++) {
        dates[i] = moment(this.startDate).add(i, 'days');
      }
      return dates;
    },
    projects() {
      const projectsMap = new Map();
      this.entries.forEach(z => {
        if (z.project) {
          projectsMap.set(z.project.id, z.project);
        } else {
          projectsMap.set(null, null);
        }
      });
      return [...projectsMap.values()];
    },
  },
  mounted() {
  },
  methods: {
    t: function (key) {
      return this.$t('timesheet-approval.week.' + key);
    },
    getProject: function (id) {
      if (id) {
        return this.entries.filter(z => z.project.id === id)
      }
      return null;
    },
    getProjectKey: function (project) {
      if (project) {
        return project.id;
      }
      return null;
    },
    getProjectDisplay: function (project) {
      if (project) {
        if (project.cost_center) {
          return project.cost_center + ' - ' + project.name
        }
        return project.name
      }
      return this.$t('timesheet-approval.work-time')
    },
    getHoursPerDayByProject(project) {
      let entriesOfProject = project
          ? this.entries.filter(z => z.project.id === project.id)
          : this.entries.filter(z => !z.project);
      let dayHours = [];
      for (let i = 0; i < 7; i++) {
        dayHours[i] = this.getEntriesForDay(entriesOfProject, i);
      }
      return dayHours;
    },
    getEntriesForDay(entries, dayOffset) {
      let compareDate = moment(this.startDate).add(dayOffset, 'days')
      let entriesOfDay = entries.filter(z => moment(z.date).isSame(compareDate, 'day'));
      return entriesOfDay.reduce((sum, entry) => sum + entry.duration_minutes, 0)
    },
    getProjectTotal(project) {
      let entriesOfProject = project
          ? this.entries.filter(z => z.project.id === project.id)
          : this.entries.filter(z => !z.project);

      let sum = entriesOfProject.reduce((sum, entry) => sum + entry.duration_minutes, 0)
      return sum
    },
    convertToHourDecimal(minutes) {
      return (Math.floor(minutes / 60) + (minutes % 60) / 60).toFixed(2);
    },
    getDayTotal(date) {
      let entries = this.entries.filter(z => moment(z.date).isSame(moment(date), 'day'))
      return entries.reduce((sum, entry) => sum + entry.duration_minutes, 0);
    },
    getTotalOfTotal() {
      return this.entries.reduce((sum, entry) => sum + entry.duration_minutes, 0);
    },
  }
}
</script>

<style scoped>
.header-row {
  font-weight: bold;
  text-align: right;
}

.header-header {
  text-align: left;
}

.content-row {
  text-align: right;
}

.content-header {
  text-align: left;
}

.footer-row {
  font-weight: bold;
  text-align: right;
}

.time {
  margin-right: 2px;
  margin-left: 2px;

}

.bg-field {
  padding: 8px;
  background-color: #F5F5F5;
}

</style>
