<template>
  <div>
    <v-card>
      <v-card-title>
        {{ t('title') }}
      </v-card-title>
      <v-card-text
          class="d-flex"
      >
        <v-container>
          <v-row>
            <span>{{ t('form.value-date') }}</span>
            <v-date-picker v-model="data.date" class="mb-8"/>
          </v-row>
          <v-row>
            <v-text-field filled :label="t('form.amount')" v-model="data.amount"/>
          </v-row>
          <v-row>
            <v-text-field filled :label="t('form.comment')" v-model="data.comment"/>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()"
               text
        >
          <v-icon>mdi-close</v-icon>
          {{ t('cancel') }}
        </v-btn>
        <v-btn @click="save()"
               color="primary"
               elevation="0"
        >
          <v-icon>mdi-check</v-icon>
          {{ t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ExpenseEditReimbursement",
  props: ["initialAmount"],
  data() {
    return {
      data: {
        title: this.$t('expenses.reimbursement.title'),
        date: new Date().toISOString().substr(0, 10),
        amount: '',
        comment: '',
      },
    }
  },
  mounted() {
    this.data.amount = this.initialAmount
  },
  methods: {
    t: function (key) {
      return this.$t('expenses.reimbursement.' + key);
    },
    cancel() {
      this.$emit('cancel');
    },
    save() {
      let data = this.data;
      this.$emit('save', data);
    },
  }
}
</script>

<style scoped>

</style>
