<template>
  <div>
    <toolbar :dataLoaded="dataLoaded">{{ title }}</toolbar>

    <v-container fluid class="edit-screen">
      <loading :error="error" :dataLoaded="dataLoaded"></loading>
      <v-form ref="form" v-on:submit.prevent>
        <v-row v-if="dataLoaded">
          <v-col class="content-area" cols="12" sm="12" md="8">
            <slot/>
          </v-col>
          <v-col class="meta-area" cols="12" sm="12" md="4">
            <slot name="meta"/>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <transition name="fade">
      <div v-if="dataLoaded" class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
                color="primary"
                text
                class="icon-left"
                @click="doReset"
                :disabled="!hasChanges"
            >
              <v-icon>mdi-reload</v-icon>
              {{ $t('components.basic-settings-container.reset') }}
            </v-btn>
            <v-spacer></v-spacer>
            <slot name="action-buttons">
              <v-btn
                  color="primary"
                  elevation="0"
                  class="icon-left"
                  @click="doSave"
                  :disabled="!hasChanges"
                  :loading="processing"
              >
                <v-icon>mdi-check</v-icon>
                {{ $t('components.basic-settings-container.save') }}
              </v-btn>
            </slot>
          </v-row>
        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import toolbar from '@/components/layouts/Navigation.vue'
import DeleteButton from "@/components/inputs/DeleteButton";
import loading from "@/components/layouts/Loading";
import CancelButtonDialog from "@/components/layouts/CancelButtonDialog";

export default {
  name: "BasicSettingsContainer",
  components: {
    toolbar,
    loading,
  },
  emits: ['saved', 'reset'],
  props: {
    title: String,
    dataLoaded: {
      type: Boolean,
      required: true,
    },
    objectType: String,
    objectName: String,
    saveAction: Function,
    hasChanges: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
      error: ''
    }
  },
  methods: {
    doReset() {
      this.$emit('reset');
    },
    doSave() {
      if (this.validate()) {

        if (this.processing === true) {
          return
        }
        this.processing = true

        this.saveAction()
            .then(function (response) {
              this.$emit('saved');
            }.bind(this))
            .catch(function (error) {
              let message = error.response.data.message;
              if (!message) {
                message = "Ein Fehler ist aufgetreten."
              }
              this.$root.infoNotification.showMessage(message);
            }.bind(this))
            .finally(function () {
              this.processing = false;
            }.bind(this))
      }
    },
    validate() {
      return this.$refs.form.validate();
    }
  }
  ,
}
</script>

<style scoped>

</style>
