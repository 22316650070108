<template>
  <!-- CURRENTLY NOT IN USE AS WE OPEN THE GOOGLE MAPS IN AN EXTERNAL LINK -->
  <gmap-map ref="mapRef"
    :center="{lat: 47.3778579, lng: 8.5403226}"
    :zoom="17"
    map-type-id="terrain"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false
    }"
    style="width: 100%; height: 350px"
  >
    <gmap-marker
      :icon="markerOptions"
      :position="{
        lat: data.lat,
        lng: data.lng
      }"
    />
  </gmap-map>
</template>

<script>
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

const mapMarker = require('../assets/images/map-marker.png')
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAN5sUA-UFqoeI2J1Zru9NYv3QaETjMiCY',
    libraries: 'places'
  }
})

export default {
  name: "MapDisplay",
  components: {},
  emits: ['close'],
  props: {
    show: Boolean,
    initLocation: Object,
  },
  data() {
    return {
      data: {
        lat: null,
        lng: null,
      },
      markerOptions: {
        url: mapMarker
      },
    };
  },
  mounted() {
    if (this.initLocation) {
      this.setPosition(this.initLocation)
      this.panMapAfterLoad()
    }
  },
  methods: {
    setPosition(data) {
      this.data.lat = data.lat
      this.data.lng = data.lng
    },
    panMapAfterLoad() {
      setTimeout(() => {
        this.$refs.mapRef.$mapObject.panTo({lat: this.data.lat, lng: this.data.lng})
      }, 1000)
    }
  }
}
</script>
