<template>
  <v-img 
    :src="imageUrl"
    :style="[{ maxWidth: maxWidthValue }, { maxHeight: maxHeightValue }]"
  >
    <v-btn v-if="canRemove" @click="remove" class="ma-2" outlined style="background-color: white;">
      <md-icon class="sp-text-blue">delete</md-icon>
    </v-btn>
  </v-img>
</template>

<script>
import { HTTP } from "../auth";
import { Buffer } from "buffer";
import store from 'store'

export default {
  name: "ImageWrapper",
  emits: ['delete'],
  props: {
    link: '',
    photoId: '',
    maxWidth: {
      type: Number,
      default: 300
    },
    maxHeight: {
      type: Number,
      default: 150
    },
    canRemove: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      imageUrl: null,
    };
  },
  mounted() {
    this.fetchImage();
  },
  computed: {
    maxWidthValue() {
      return this.maxWidth + 'px'
    },
    maxHeightValue() {
      return this.maxHeight + 'px'
    }
  },
  methods: {
    async fetchImage() {      
      try {
        const response = await HTTP.get(this.link, {
          headers: {
            // Add any headers you need
            // "Authorization": "Bearer YourAccessToken",
            // "User-Agent": "Your User Agent",
            Authorization: 'Bearer ' + store.get('id_token')

          },
          responseType: 'arraybuffer',
        });

        const buffer = Buffer.from(response.data, 'binary');
        const base64Image = buffer.toString('base64');

        this.imageUrl = `data:image/jpeg;base64,${base64Image}`;
      } catch (error) {
        console.error("Error fetching image:", error);
        // Handle the error or set a placeholder image URL
        this.imageUrl = "path/to/placeholder-image.jpg";
      }
    },
    async remove() {
      this.$emit('delete', this.photoId);
    }
  }
}
</script>
