<template>
  <div>
    <basic-edit-container
        :has-changes="hasChanges"
        :data-loaded="dataLoaded"
        :title="item.filename + ' ' + t('edit.title')"
        :delete-action="deleteItem"
        :save-action="save"
        :object-name="item.title"
        @saved="onSaved"
        @cancel="cancel"
    >
      <template v-slot:default>
        <v-card
            elevation="0"
        >
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>

            <v-text-field
                :label="t('details.title') + '*'"
                counter="120"
                v-model="item.title"
                filled
                :rules="[
								() => !!item.title || $t('validation.required.title'),
								() => item.title.length <= 120 || $t('validation.length.title')
							]"
            />

            <v-text-field
                :label="t('details.description')"
                counter="120"
                v-model="item.description"
                filled
                :rules="[
								() => (!item.description || (item.description && item.description.length <= 120)) || $t('validation.length.description')
							]"
            />

            <v-text-field
                :label="t('details.keywords')"
                v-model="item.keywords"
                filled
                :hint="t('details.keywords-hint')"
                class="has-hint-message"
            />

          </v-card-text>
        </v-card>
      </template>
      <template v-slot:meta>
        <v-card
            elevation="0"
        >
          <v-card-title>{{ $t('components.permissiongroup-select.label') }}</v-card-title>
          <v-card-text>

            <permission-group-selector
                  v-model="item.assigned_permission_groups"
                  :required="true"
                  mode="dropdown"/>

          </v-card-text>
        </v-card>

        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('move-document.group-title') }}</v-card-title>
          <v-card-text>

            <div>
              <v-btn
                  elevation="0"
                  color="primary"
                  class="icon-left"
                  @click="dialogVisible=true"
              >
                <v-icon>mdi-folder-search-outline</v-icon>
                {{ t('move-document.choose-folder') }}
              </v-btn>
              <p v-if="moveToDir" class="sp-mar-top-2">{{ t('move-document.will-be-moved') }}: {{ moveToDir.name }}</p>
            </div>

          </v-card-text>
        </v-card>

        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('replace-document.group-title') }}</v-card-title>
          <v-card-text>

            <div>
              <v-file-input
                  :label="t('details.choose-file')"
                  truncate-length="40"
                  show-size
                  filled
                  v-model="document"
              />
            </div>

          </v-card-text>
        </v-card>
      </template>
    </basic-edit-container>

    <v-dialog
        v-model="dialogVisible"
        width="500"
        persistent
        content-class="documents-chooser custom-class-dialog"
    >
      <v-card>
        <v-card-title>
          {{ t('move-document.move-to') }}: <b v-if="moveToDirCandidate">{{ moveToDirCandidate.name }}</b>
        </v-card-title>
        <v-card-text>
          <ul class="tree-list">
            <documents-edit-folder-item v-model="dirMoveTargets" v-if="dirMoveTargets"/>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn class="md-secondary" @click="dialogVisible=false">{{ t('move-document.reset') }}</v-btn>
          <v-btn class="md-primary" @click="useFolder">{{ t('move-document.select') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import {EventBus} from './../event-bus'
import toolbar from '../components/layouts/Navigation.vue'
import BasicEditContainer from "../components/inputs/BasicEditContainer.vue";
import DocumentsEditFolderItem from "./DocumentsEditFolderItem.vue";
import PermissionGroupSelector from "@/components/PermissionGroupSelector.vue";


export default {
  name: 'DocumentsEditData',
  components: {
    DocumentsEditFolderItem,
    BasicEditContainer,
    toolbar,
    PermissionGroupSelector
  },
  data() {
    return {
      item: {
        title: '',
        description: '',
        keywords: '',
        filename: '',
        assigned_permission_groups: [],
      },
      document: null,
      itemOriginal: '',
      dirMoveTargets: null,
      moveToDirCandidate: null,
      moveToDir: null,
      allPermissionGroups: [],
      dataLoaded: false,
      dialogVisible: false,
    }
  },
  props: {
    itemId: null
  },
  async created() {
    await this.getPermissionGroups()
    await this.getDocument()
    await this.getFolderTree()
    this.dataLoaded = true
  },
  mounted() {
    let self = this
    EventBus.$on('set-moveToDir', function (item) {
      self.moveToDirCandidate = item
    })
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return (JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal))
          || this.moveToDir != null
          || this.document != null
    },
  },
  methods: {
    t: function (key) {
      return this.$t('documents.detail.' + key);
    },
    async getFolderTree() {
      let response = await HTTP.get('documents/folders/all')
      this.dirMoveTargets = response.data
    },
    async getPermissionGroups() {
      let response = await HTTP.get('permission-groups?sortBy[]=name')
      this.allPermissionGroups = response.data.data
    },
    async getDocument() {
      let response = await HTTP.get('documents/' + this.itemId)
      this.item = response.data
      // Prepare permission groups for select input
      const tempPermGroupsIds = []
      this.item.assigned_permission_groups.forEach((element) => {
        tempPermGroupsIds.push(element.permission_group_id)
      })
      this.item.assigned_permission_groups = tempPermGroupsIds

      this.itemOriginal = JSON.parse(JSON.stringify(this.item))
    },
    async save() {
      if (this.moveToDir) {
        this.item.move_to_folder = this.moveToDir.folder_id ?? -1
      }

      let formData = new FormData()
      formData.append('data', JSON.stringify(this.item))
      if (this.document) {
        formData.append('document', this.document)
      }
      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }

      await HTTP.patch('documents/' + this.itemId, formData, config)

      this.close()
    },
    useFolder() {
      this.moveToDir = this.moveToDirCandidate
      this.dialogVisible = false
    },
    close() {
      EventBus.$emit('update-document')
    },
    cancel() {
      EventBus.$emit('cancel-document')
    },
    async deleteItem() {
      await HTTP.delete('documents/' + this.itemId)
      this.close()
    },
    onSaved() {
      this.close()
    },
  }
}
</script>

<style type="text/css">
.sp-mar-bottom-3 {
  margin-bottom: calc(8px * 3);
}

ol, ul {
  list-style: none;
  padding: 0px;
}

a {
  color: #a0f6aa;
  text-decoration: none;
}

h1 {
  text-align: center;
  width: 90%;
  margin: 2em auto 0;
  color: #507b55;
  font-weight: bold;
}

.cd-accordion-menu {
  width: 90%;
  max-width: 600px;
  margin: 4em auto;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
}

.cd-accordion-menu li {
  user-select: none;
}

.cd-accordion-menu li span {
  float: right;
}

.cd-accordion-menu label, .cd-accordion-menu a {
  position: relative;
  display: block;
  padding: 18px 18px 18px 45px;
  box-shadow: inset 0 -1px #000;
  color: #ffffff;
}

.no-touch .cd-accordion-menu label:hover,
.no-touch .cd-accordion-menu a:hover {
  background: #52565d;
}

.cd-accordion-menu label::before,
.cd-accordion-menu label::after,
.cd-accordion-menu a::after {
  /* icons */
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cd-accordion-menu label {
  cursor: pointer;
}

.cd-accordion-menu label span {
  float: right;
  color: #828282;
}

.cd-accordion-menu li.file > label::before {
  content: "\f15b";
}

.cd-accordion-menu li.folder > label::before {
  content: "\f054";
}

.cd-accordion-menu li.file > label::before {
  content: "\f15b";
}

.cd-accordion-menu li.add > label::before {
  content: "\f067";
}


.cd-accordion-menu label::before {
  /* arrow icon */
  font: normal normal normal 14px/1 FontAwesome;
  left: 18px;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.cd-accordion-menu label.open::before {
  transform: translateY(-25%) rotate(90deg);
}

.cd-accordion-menu ul label,
.cd-accordion-menu ul a {
  background: #000;
  box-shadow: inset 0 -1px #141617;
  padding-left: 82px;
}

.no-touch .cd-accordion-menu ul label:hover,
.no-touch .cd-accordion-menu ul a:hover {
  background: #3c3f45;
}

.cd-accordion-menu > li:last-of-type > label,
.cd-accordion-menu > li:last-of-type > a,
.cd-accordion-menu > li > ul > li:last-of-type label,
.cd-accordion-menu > li > ul > li:last-of-type a {
  box-shadow: none;
}

.cd-accordion-menu ul label::before {
  left: 36px;
}

.cd-accordion-menu ul label::after,
.cd-accordion-menu ul a::after {
  left: 59px;
}

.cd-accordion-menu ul ul label,
.cd-accordion-menu ul ul a {
  padding-left: 100px;
}

.cd-accordion-menu ul ul label::before {
  left: 54px;
}

.cd-accordion-menu ul ul label::after,
.cd-accordion-menu ul ul a::after {
  left: 77px;
}

.cd-accordion-menu ul ul ul label,
.cd-accordion-menu ul ul ul a {
  padding-left: 118px;
}

.cd-accordion-menu ul ul ul label::before {
  left: 72px;
}

.cd-accordion-menu ul ul ul label::after,
.cd-accordion-menu ul ul ul a::after {
  left: 95px;
}

.moveTo-dialog .md-dialog {
  width: 80vw;
  max-width: 1000px;
}

.custom-flex-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

</style>
