<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">
        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

          <app-filter-apply-button
            v-if="filterStore.hasChanges"
            @click="applyFilter(); options.page = 1"
          ></app-filter-apply-button>

          <v-slide-group show-arrows>
            <v-slide-item>

              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <app-filter-search-field
                      v-model="filterStore.filter.searchTerm"
                      data-cy="searchTerm"
                      :filterLabel="$t('components.app-filter-search-field.label.title')"
                      clearable
                      @click:clear="clearSearchTerm"
                      append-icon="mdi-magnify"
                    ></app-filter-search-field>
                  </div>
                </div>
              </div>

            </v-slide-item>
            <v-slide-item>

              <div class="filter-status-filter">
                <div class="filter-item">
                  <v-select
                    v-model="filterStore.filter.status"
                    data-cy="status"
                    :label="t('filter.status')"
                    :items="filterStatus"
                    item-text="name"
                    item-value="slug"
                    multiple
                    chips
                  ></v-select>
                </div>
              </div>

            </v-slide-item>

          </v-slide-group>

        </v-container>

      </div>

      <v-container fluid>

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div>
            <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              data-cy="createButton"
              :to="{ path: '/news/create'}"
            >
              <v-icon>mdi-plus</v-icon>
              {{ t('create') }}
            </v-btn>
          </div>
        </v-row>

        <v-row v-if="dataLoaded && newsPosts.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>
          <v-data-table
            calculate-widths
            :headers="headers"
            :items="newsPosts"
            :options.sync="options"
            :server-items-length="totalNews"
            :loading="loading"
            class="elevation-0"
            :sort-by.sync="filterStore.table.sortBy"
            :sort-desc.sync="filterStore.table.sortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.thumbnail`]="{ item }">
              <div>
                <image-wrapper
                  v-if="item.imageExists"
                  :link="getPreviewPictureLink(item.id)"
                  :can-remove="false"
                  :max-width="150"
                  :max-height="100"/>
              </div>
            </template>

            <template v-slot:[`item.title`]="{ item }">
              <div class="ellipsis-wrapper">
                <span class="ellipsis ellipsis-title" v-if="item.title"><b>{{ item.title }}</b></span>
                <span class="ellipsis preview-text">{{ item.preview_text }}</span>
              </div>
            </template>

            <template v-slot:[`item.published_at`]="{ item }">
              <span v-if="item.status === 'published' || item.status === 'publishat'" class="has-column-icon">
                <v-icon v-if="isPublished(item.published_at)">mdi-checkbox-marked-circle-outline</v-icon>
                <v-icon v-if="!item.deleted_at && !isPublished(item.published_at)">mdi-clock-outline</v-icon>
                {{ formatDate(item.published_at) }}<br>{{ formatTime(item.published_at) }}
              </span>
              <span v-if="item.status === 'draft'" title="Entwurf" class="has-column-icon"><v-icon>mdi-email-open</v-icon>Entwurf</span>
              <span v-if="item.status === 'archived'" title="Archiv" class="has-column-icon"><v-icon>mdi-delete-outline</v-icon>Archiv</span>
            </template>

            <template v-slot:[`item.pushed_at`]="{ item }">
              <span class="has-column-icon">
                <span v-if="item.pushed_at" title="Push versendet" class="d-block">
                    <v-icon>mdi-bell-outline</v-icon> {{ formatDate(item.pushed_at) }}<br/>{{ formatTime(item.pushed_at) }}
                </span>
                <span v-if="item.push_next_at" title="Push geplant" class="d-block">
                    <v-icon>mdi-clock-outline</v-icon> {{ formatDate(item.push_next_at) }}<br/>{{ formatTime(item.push_next_at) }}
                </span>
              </span>
            </template>

            <template v-slot:[`item.read`]="{ item }">
              {{ item.analytics.read }}
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ path: '/news/'+item.id }" class="edit-row-table" data-cy="editButton">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <router-link :to="{ path: '/news/create?fromId='+item.id }" class="edit-row-table">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  content_copy
                </v-icon>
              </router-link>
            </template>
          </v-data-table>

        </v-row>

      </v-container>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import moment from 'moment'
import toolbar from '@/components/layouts/Navigation'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import ImageWrapper from '@/components/ImageWrapper'
import ContentCustomer from '@/components/filter-components/ContentCustomer'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import { useNewsFilterStore } from '@/stores/NewsFilterStore'

export default {
  name: 'NewsIndex',
  components: {
    ContentCustomer,
    ImageWrapper,
    toolbar,
    AppFilterSearchField,
    AppFilterApplyButton
  },
  setup() {
    const filterStore = useNewsFilterStore()
    return { filterStore }
  },
  data() {
    return {
      loading: false,
      newsPosts: [],
      totalNews: 0,
      options: {},
      dataLoaded: false,
      message: null,
      error: false,
      pictureCache: {}
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  computed: {
    filterStatus() {
      return [
        {
          name: this.$t('news.data.status.published'),
          slug: 'PUBLISHED'
        },
        {
          name: this.$t('news.data.status.draft'),
          slug: 'DRAFT'
        },
        {
          name: this.$t('news.data.status.archived'),
          slug: 'ARCHIVED'
        }
      ]
    },
    headers() {
      return [
        {
          text: this.t('table.thumbnail'),
          align: 'start',
          sortable: false,
          value: 'thumbnail',
          width: 200
        },
        {
          text: this.t('table.title'),
          value: 'title',
          sortable: true,
          align: 'start',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.published-at'),
          value: 'published_at',
          sortable: true,
          align: 'center',
          width: 160
        },
        {
          text: this.t('table.pushed-at'),
          value: 'pushed_at',
          sortable: true,
          align: 'center',
          width: 120
        },
        {
          text: this.t('table.read'),
          value: 'read',
          sortable: false,
          align: 'center',
          width: 120
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 100
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: 100
        },
      ];
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  beforeDestroy() {
  },
  methods: {
    t: function (key) {
      return this.$t('news.list.' + key)
    },
    moment: function () {
      return moment()
    },
    async reload() {
      await this.getDataFromApi()
    },
    async applyFilter() {
      this.filterStore.filterApplied()
      await this.reload()
    },
    clearSearchTerm() {
      this.filterStore.filter.searchTerm = ''
    },
    prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.filterStore.filter.status.length > 0) {
        queryStringParts.push('st=' + this.filterStore.filter.status)
      }
      if (this.filterStore.filter.searchTerm ?? '' > 1) {
        queryStringParts.push('term=' + this.filterStore.filter.searchTerm)
      }
      if (sortBy != null)
      queryStringParts.push('sortBy=' + sortBy[0])
      if (sortDesc != null) {
        let sort = sortDesc[0] ? 'desc' : 'asc'
        queryStringParts.push('sortDesc=' + sort)
      }
      page = page ? page : 1
      queryStringParts.push('page=' + page)

      if (isNaN(itemsPerPage)) 
        itemsPerPage = this.filterStore.table.itemsPerPage;

      queryStringParts.push('itemsPerPage=' + itemsPerPage)
      return '?' + queryStringParts.join('&')
    },
    getDataFromApi() {
      this.loading = true
      this.dataLoaded = false
      this.apiCall().then(data => { 
        this.newsPosts = data.newsPosts
        this.totalNews = data.total
        this.loading = false
        this.dataLoaded = true
      });
    },
    async apiCall() {

      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

      let response = await HTTP.get('news' + url)
      let newsPosts = response.data.data
      let total = response.data.total

      return { newsPosts, total }
    },
    isPublished(publishedAt) {
      return moment().isAfter(publishedAt)
    },
    getPreviewPictureLink(id) {
      if (this.pictureCache[id]) {
        // If it's in the cache, return the cached value
        return this.pictureCache[id]
      } else {
        // If it's not in the cache, generate the picture link
        let cacheBuster = new Date().getTime()
        let val = '/news/thumb/' + id
        let selectedPicture = `${val}?${cacheBuster}`

        // Save the picture link in the global array
        this.pictureCache[id] = selectedPicture

        // Return the generated picture link
        return selectedPicture
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sp-hero .filter-status-filter,
.sp-hero .filter-search-list {
  width: calc(50% - 134px);
}

.ellipsis-wrapper {
  position: relative;
  height: 100%;
  min-height: 84px;
  min-width: 300px;

  .ellipsis {
    position: absolute;
    padding-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    &.ellipsis-title {
      top: 20px;
    }

    &.preview-text {
      top: 42px;
    }
  }
}

.preview-text {
  color: #000000DE;
  font-size: 14px;
}

.has-column-icon {
  display: inline-block;
  position: relative;
  padding: 0 5px 0 28px;
  font-size: 14px;

  i {
    position: absolute;
    left: 0;
  }
}

.thumbnail {
  width: 100px;
}

.analytics-loading {
  left: 35px;
  top: 4px;
}
</style>

