<template>
    <div :class="{ 'is-loading': !analyticsLoaded }">
        <loading :dataLoaded="analyticsLoaded"></loading>
        <div class="sp-flex sp-flex-dir-col full-width" v-if="analyticsLoaded">
            <div>{{ $t('components.post-read-analytics.read') }}:
                {{ analytics.read_total }}
            </div>
            <div v-if="analytics.read_total > 0"
                 @click="toggleShow" class="show-analytics-users"
            >
                <md-icon>supervisor_account</md-icon>
                {{ $t('components.post-read-analytics.show-users') }}
            </div>
            <div v-if="showAnalyticsUsers">
                <div v-for="(analytics_user, index) in analytics.users" :key="index" class="analytics-user">
                    <span v-if="analytics_user.display_name">{{ analytics_user.display_name }} ({{ formatDateTime(analytics_user.timestamp) }})</span>
                    <span v-else>{{ analytics_user }}</span>
                </div>
            </div>
        </div>
        <div class="sp-flex sp-flex-dir-col" v-if="analyticsError">
            <span>{{ $t('components.post-read-analytics.error-onload') }}</span>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import loading from '@/components/layouts/Loading'

export default {
    name: "AnalyticsInfo",
    components: {
        loading
    },
    props: [
        'getUrl',
    ],
    data() {
        return {
            analyticsLoaded: false,
            showAnalyticsUsers: false,
            analyticsError: false,
            analytics: {
                read_total: '',
                total: ''
            },
        }
    },
    async created() {
        await this.getAnalyticsData()
    },
    methods: {
        async getAnalyticsData() {
            let url = this.getUrl()
            try {
                let response = await HTTP.get(url)
                this.analytics = response.data
                this.analyticsLoaded = true
            } catch (e) {
                this.analyticsError = true
            }
        },
        toggleShow() {
            this.showAnalyticsUsers = !this.showAnalyticsUsers
        }
    }
}
</script>

<style scoped lang="scss">
.show-analytics-users {
  display: inline-block;
  margin: 10px 0;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

.show-analytics-users i {
  color: rgba(0, 0, 0, 0.54);
}

.analytics-user {
  padding: 10px;
}

.analytics-user:nth-child(odd) {
  background: #f7f7f7;
}
</style>
