<template>
    <md-list-item>
        <v-icon>{{ item.icon }}</v-icon>
        <span class="module-title">{{ item.title }}</span>
        <md-list-expand>
            <md-list>
                <md-list-item class="md-inset">
                    <router-link :to="{ path: '/object-control' }">
                    <span>{{ $t('navigation.object-control') }}</span>
                    </router-link>
                </md-list-item>
                <md-list-item class="md-inset">
                    <router-link :to="{ path: '/object-control-elements' }">
                    <span>{{ $t('navigation.object-control-elements') }}</span>
                    </router-link>
                </md-list-item>
                <md-list-item class="md-inset">
                    <router-link :to="{ path: '/object-control-settings' }">
                    <span>{{ $t('navigation.object-control-settings') }}</span>
                    </router-link>
                </md-list-item>
            </md-list>
        </md-list-expand>
    </md-list-item>
</template>

<script>
export default {
    name: 'ObjectControlListItem',
    data() {
        return {}
    },
    props: {
        item: {
            type: Object,
            required: false,
            default: {}
        },
        user: {
            type: Object,
            required: false,
            default: {}
        },
        modules: {
            type: Array,
            required: false,
            default: []
        }
    }
    
}
</script>