import TasksIndex from '@/views/TasksIndex'
import TaskCategoriesIndex from '@/views/TaskCategoriesIndex'
import TaskCreate from '@/views/TaskCreate'
import TaskEdit from '@/views/TaskEdit'

const tasksRoutes = [
    {
        path: '/tasks',
        name: 'Pendenzen',
        component: TasksIndex,
        meta: {
            accessConfig:  {
                moduleName: ['tasks'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/tasks/create',
        name: 'PendenzCreate',
        component: TaskCreate,
        meta: {
            accessConfig:  {
                moduleName: ['tasks'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/tasks/:id?',
        name: 'Pendenz',
        component: TaskEdit,
        meta: {
            accessConfig:  {
                moduleName: ['tasks'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/tasks',
        name: 'TaskCategoriesIndex',
        component: TaskCategoriesIndex,
        meta: {
            accessConfig:  {
                moduleName: ['tasks'],
                requiresAuth: true
            }
        }
    }
]

export default tasksRoutes
