<template>
  <div :class="[{ 'has-hero-container' : !hasOverlay }]">
    <div>

      <toolbar v-if="hasOverlay" :dataLoaded="true" :cancelLeft="true" :event="true">{{ detailCustomer.name }}</toolbar>
      <toolbar v-else :dataLoaded="true">{{ t('title') }}</toolbar>

      <div v-show="!hasOverlay">

        <div class="sp-hero sp-bg-light-blue">

          <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">

            <app-filter-apply-button
                v-if="filterStore.hasChanges"
                @click="applyFilter();"
            />

            <v-slide-group show-arrows>

              <v-slide-item>

                <div class="filter-item">
                  <app-filter-date-picker
                      :filterLabel="$t('components.app-filter-date-picker.label.startdate')"
                      v-model="filterStore.filter.dateStart"
                      id="dateStart"
                      :clearable="true"
                  ></app-filter-date-picker>
                </div>

              </v-slide-item>
              <v-slide-item>

                <div class="filter-item">
                  <app-filter-date-picker
                      :filterLabel="$t('components.app-filter-date-picker.label.enddate')"
                      v-model="filterStore.filter.dateEnd"
                      id="dateEnd"
                      :clearable="true"
                  ></app-filter-date-picker>
                </div>

              </v-slide-item>
              <v-slide-item>

                <div class="filter-search-list">
                  <div class="filter-item">
                    <div class="filter-item-wrapper">
                      <v-text-field
                        elevation="0"
                        :label="t('customer-search')"
                        v-model="filterStore.filter.searchCustomer"
                        append-icon="mdi-magnify"
                        clearable
                        filled
                      ></v-text-field>
                    </div>
                  </div>
                </div>

              </v-slide-item>

            </v-slide-group>

          </v-container>

        </div>

        <v-container fluid class="has-bottom-navi">

          <v-row v-if="!dataLoaded" class="mt-8">
            <loading></loading>
          </v-row>

          <v-row v-else class="timelock-wrapper" ref="timeLockWrapper">

            <div class="timelock-customers-wrapper">
              <div class="timelock-customers-header">Objekt/Kunde</div>
              <div v-for="(customer, index) in customers" :key="index" class="timelock-customers-entry">
                <span class="ellipsis" :title="customer.name">{{ customer.name }}</span>
              </div>
            </div>

            <div id="timelock-timelocks-wrapper" class="timelock-timelocks-wrapper">
              <div id="timelock-timelocks-scroll-wrapper" class="timelock-timelocks-scroll-wrapper">
                <div class="timelock-timelocks-header">
                  <div v-for="(month, index) in daysOfMonth" :key="index" class="timelock-timelocks-month-entry">
                    <div class="timelock-timelocks-month-name" :style="[{ width: getMonthWidth(index) + 'px' }]">{{ getMonthName(index) }}</div>
                    <div class="timelock-timelocks-days-wrapper">
                      <div v-for="(day, index) in month" :key="index">
                        {{ day }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="timelock-limelocks-timespan-wrapper">
                  <div v-for="(customer, index) in customers" :key="index" class="timelock-limelocks-timespan">
                    <span
                      :style="{ width: calculateTimespanWidth(customer) + 'px' }"
                      :class="[{ 'lock-is-paused': customerIsPaused(customer) }, { 'outside-range': lockDateOutsideRange(customer) }]">
                      <span v-if="customer.lock_target_date">{{ customer.lock_target_date | momentDate }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="timelock-tools-wrapper">
              <div class="timelock-tools-header">
                <div class="timelock-tools-header-col">{{ t('tools-header-state') }}</div>
                <div class="timelock-tools-header-col">{{ t('tools-header-details') }}</div>
                <div class="timelock-tools-header-col">
                  <v-checkbox
                    v-model="selectAll"
                    hide-details
                    class="shrink mr-0 mt-0"
                    :ripple="false"
                    @change="checkAll($event)"
                  ></v-checkbox>
                </div>
              </div>
              <div v-for="(customer, index) in customers"
                :key="index"
                :class="[{ 'lock-is-paused': customerIsPaused(customer) }]"
                class="timelock-tool-entry">
                <div class="timelock-tools-header-col">
                  <v-icon @click="showPauseDialog(customer)">
                    {{ getPausedIconClass(customer) }}
                  </v-icon>
                </div>
                <div class="timelock-tools-header-col">
                  <v-icon v-if="customer.lock_author" @click="showOverlay(customer)">mdi-eye-outline</v-icon>
                </div>
                <div class="timelock-tools-header-col">
                  <v-checkbox
                    v-model="customer.selected"
                    hide-details
                    :ripple="false"
                    class="shrink mr-0 mt-0"
                  ></v-checkbox>
                </div>
              </div>
            </div>

          </v-row>

        </v-container>

      </div>

      <transition v-if="!hasOverlay" name="fade">
        <div class="bottom-navigation modal-nav">
          <v-container fluid>
            <v-row>
              <v-spacer></v-spacer>
              <div>
                <v-btn
                    elevation="0"
                    color="primary"
                    outlined
                    class="icon-left"
                    @click="showLockDialog(false)"
                >
                  <v-icon>mdi-delete</v-icon>
                  {{ t('delete-lock') }}
                </v-btn>
              </div>
              <v-btn
                  color="primary"
                  elevation="0"
                  class="icon-left"
                  @click="showLockDialog(true)"
              >
                <v-icon>mdi-plus</v-icon>
                {{ t('add-lock') }}
              </v-btn>
            </v-row>
          </v-container>
        </div>
      </transition>

      <v-container v-if="hasOverlay" fluid>

        <v-row class="pt-16 pb-4">
          <b>{{ getDetailStartDate() }} - {{ detailCustomer.lock_target_date | momentDate }}, {{ $t('timelog-locktime.list.details.locked-by', { lockAuthor: getLockAuthorName(detailCustomer) }) }}</b>
        </v-row>      

        <v-row v-if="timelogsLoaded && timelogs.data.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('details.table.no-data') }}
            <loading v-if="timelogsLoading"></loading>
          </div>
        </v-row>

        <v-row v-else>

          <v-data-table
            :headers="headers"
            :items="timelogs.data"
            :options.sync="options"
            :server-items-length="timelogsTotal"
            :loading="timelogsLoading"
            class="elevation-0"
            :items-per-page="10000"
            hide-default-footer
          >
          <template v-slot:[`item.duration`]="{ item }">
            {{ item.duration | hourFormat }}
          </template>
          </v-data-table>

        </v-row>

      </v-container>

    </div>  

    <v-dialog
      v-model="pauseDialog"
      content-class="timelock-dialog pause-dialog"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ t('dialog.' + pauseMode + '.title') }}
        </v-card-title>
        <v-card-text>
          <b>{{ t('dialog.' + pauseMode + '.content-title') }}</b><br>
          {{ t('dialog.' + pauseMode + '.content') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            text
            class="icon-left px-4"
            @click="hidePauseDialog"
          >
            <v-icon>mdi-close</v-icon>
            {{ t('dialog.' + pauseMode + '.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left px-4"
            :disabled="isPausing"
            @click="doPauseUnpause"
          >
            <v-icon>mdi-check</v-icon>
            {{ t('dialog.' + pauseMode + '.execute') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="lockDialog"
      content-class="timelock-dialog lock-dialog"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ t('dialog.' + lockMode + '.title') }}
        </v-card-title>
        <v-card-text>
          <v-tabs>
            <v-tabs-items v-model="lockTab">

              <v-tab-item>
                <v-text-field
                  elevation="0"
                  :label="t('customer-search')"
                  v-model="customerSearchValue"
                  append-icon="mdi-magnify"
                  filled
                  hide-details="auto"
                ></v-text-field>
                <div class="dialog-customer-entry dialog-customer-select-all">
                  {{ t('dialog.select-all') }}
                  <v-checkbox
                    v-model="fCselectAll"
                    hide-details
                    class="shrink mr-0 mt-0"
                    :ripple="false"
                    @change="fcCheckAll($event)"
                  ></v-checkbox>
                </div>
                <div class="filtered-customers-wrapper">
                  <div v-for="(fc, index) in filteredCustomers"
                    :key="index"
                    class="dialog-customer-entry">
                    <span>{{ fc.name }}</span>
                    <v-checkbox
                      v-model="fc.selected"
                      hide-details
                      :ripple="false"
                      class="shrink mr-0 mt-0"
                    ></v-checkbox>
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item>
                <div class="selected-customers-title-wrapper">
                  <p><b>{{ t('dialog.' + lockMode + '.selection-title') }}</b></p>
                  <span>{{ t('dialog.' + lockMode + '.last-lock') }}</span>
                </div>
                <div class="selected-customers-wrapper">
                  <div v-for="(cust, index) in customers" :key="index">
                    <div v-if="cust.selected" class="dialog-customer-selected-entry">
                      <span>{{ cust.name }}</span>
                      <span class="last-lock-date">{{ cust.lock_target_date | momentDate }}</span>
                    </div>
                  </div>
                </div>
                <p><b>{{ t('dialog.' + lockMode + '.date-selection') }}</b></p>
                <div class="dialog-customer-date-selection">
                  <app-date-picker
                      :filter-label="$t('components.app-filter-date-picker.label.startdate')"
                      :date="lockDateStart"
                      id="dateStart"
                      :disabled="lockMode === 'lock'"
                      :disabled-text="t('dialog.' + lockMode + '.date-last-lock')"
                  ></app-date-picker>
                  <app-date-picker
                      :filter-label="$t('components.app-filter-date-picker.label.enddate')"
                      :date="lockDateEnd"
                      id="dateEnd"
                      :disabled="lockMode === 'unlock'"
                      :disabled-text="t('dialog.' + lockMode + '.date-last-lock')"
                  ></app-date-picker>
                </div>
              </v-tab-item>
              
            </v-tabs-items>
          </v-tabs>
        </v-card-text>

        <v-card-actions>
          <transition name="fade">

            <div key="1" v-if="lockTab == 0" class="button-flex-wrapper">
              <v-btn
                elevation="0"
                color="primary"
                text
                class="icon-left px-4"
                @click="hideLockDialog"
              >
                <v-icon>mdi-close</v-icon>
                {{ t('dialog.' + lockMode + '.cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                color="primary"
                text
                class="icon-right px-4"
                @click="doLockNext"
              >        
                {{ t('dialog.' + lockMode + '.next') }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div key="1" v-if="lockTab == 1" class="button-flex-wrapper">
              <v-btn
                elevation="0"
                color="primary"
                text
                class="icon-left px-4"
                @click="doLockPrevious"
              >        
                <v-icon>mdi-chevron-left</v-icon>
                {{ t('dialog.' + lockMode + '.previous') }}         
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left px-4"
                :loading="isLocking"
                @click="doLockUnlock"
              >
                <v-icon>mdi-check</v-icon>
                {{ t('dialog.' + lockMode + '.lock') }}
              </v-btn>
            </div>

          </transition>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from './../event-bus'
import moment from 'moment'
import toolbar from '@/components/layouts/Navigation.vue'
import loading from '@/components/layouts/Loading.vue'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppDatePicker from '@/components/vuetify/AppDatePicker'
import ContentFilterCustomer from '@/components/filter-components/ContentFilterCustomer.vue'
import { useTimelockFilterStore } from '@/stores/TimelockFilterStore'

export default {
  name: 'TimeLockIndex',
  components: {
    toolbar,
    loading,
    AppFilterApplyButton,
    AppFilterDatePicker,
    AppDatePicker,
    ContentFilterCustomer
  },
  setup() {
    const filterStore = useTimelockFilterStore()
    return { filterStore }
  },
  data() {
    return {
      loading: false,
      totalCustomers: 0,
      customers: [],
      daysOfMonth: [],
      dataLoaded: false,
      selectAll: false,
      fCselectAll: false,
      monthNames: {
        '1' : this.t('months.january'),
        '2' : this.t('months.february'),
        '3' : this.t('months.march'),
        '4' : this.t('months.april'),
        '5' : this.t('months.may'),
        '6' : this.t('months.june'),
        '7' : this.t('months.july'),
        '8' : this.t('months.august'),
        '9' : this.t('months.september'),
        '10' : this.t('months.october'),
        '11' : this.t('months.november'),
        '12' : this.t('months.december'),
      },
      years: [],
      pauseDialog: false,
      pauseMode: 'pause',
      pauseCustomer: null,
      lockDialog: false,
      lockMode: 'lock',
      customerSearchValue: '',
      lockTab: 0,
      lockDateStart: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      lockDateEnd: moment().format('YYYY-MM-DD'),
      hasOverlay: false,
      detailCustomer: null,
      timelogs: {
        page: 1,
        current_page: 1,
        last_page: 1,
        total: null,
        data: []
      },
      maxDays: 0,
      timelogsLoading: false,
      timelogsLoaded: false,
      options: {},
      timelogsTotal: 0,
      isPausing: false,
      isLocking: false
    }
  },
  created() {
    this.getDaysList()
  },
  mounted() {
    EventBus.$on('filter-date-changed', function (msg) {
      if (msg.id === 'dateStart') {
        this.lockDateStart = msg.newDate
      } else {
        this.lockDateEnd = msg.newDate
      }
      this.filterChanged = true
    }.bind(this))
    EventBus.$on('cancel', function (filter) {
      this.closeOverlay()
    }.bind(this))
  },
  beforeDestroy() {
    EventBus.$off('filter-date-changed')
    EventBus.$off('cancel')
  },
  computed: {
    filteredCustomers() {
      if (this.customerSearchValue !== '') {
        return this.customers.filter((cust) =>
          cust.name.toLowerCase().includes(this.customerSearchValue.toLowerCase())
        )
      } else {
        return this.customers
      }
    },
    headers() {
        return [
          {
            text: this.t('details.table.headers.first-entry'),
            align: 'start',
            sortable: false,
            value: 'first_date',
            width: '25%'
          },
          {
            text: this.t('details.table.headers.last-entry'),
            align: 'start',
            sortable: false,
            value: 'last_date',
            width: '25%'
          },
          {
            text: this.t('details.table.headers.timespan'),
            align: 'start',
            sortable: false,
            value: 'duration',
            width: '25%'
          },
          {
            text: this.t('details.table.headers.employee'),
            align: 'start',
            sortable: false,
            value: 'name',
            width: '25%'
          }
        ]
      }
  },
  filters: {
    momentDate: function (date) {
      return date ? moment(date).format('DD.MM.YYYY') : ''
    },
    hourFormat: function (duration) {
      return (duration / 3600).toFixed(1) + ' h'
    }
  },
  watch: {
    options: {
      handler() {
        this.getTimelogs()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('timelog-locktime.list.' + key)
    },
    moment: function () {
      return moment()
    },
    async reload() {
      this.$nextTick(function () {
        this.dataLoaded = false
        this.selectAll = false
        this.getDaysList()
      }.bind(this))
    },
    async applyFilter() {
      this.filterStore.filterApplied()
      await this.reload()
    },
    getDaysList() {
      this.loading = true

      let url = this.prepareUrl()

      HTTP.get('timelog/daysList' + url).then(function (response) {
        this.daysOfMonth = response.data.days
        this.years = response.data.years
        this.getCustomers()
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.loading = false
        }
      }.bind(this))
    },
    getCustomers() {
      this.loading = true

      let url = this.prepareUrl()

      HTTP.get('customers-compact' + url).then(function (response) {
        this.customers = response.data.data
        this.customers.forEach((cust) => {
            this.$set(cust, 'selected', false)
        })
        this.totalCustomers = response.data.total
        this.maxDays = response.data.days
        this.error = false
        this.loading = false
        this.dataLoaded = true
        this.$nextTick(function () {
          let timeLockWrapper = this.$refs['timeLockWrapper']
          let timelockWrapperHeight = timeLockWrapper.clientHeight
          if (timelockWrapperHeight % 40 === 0) {
            timeLockWrapper.classList.add('is-ios')
          }
          this.handleDrag()
        }.bind(this))
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.loading = false
        }
      }.bind(this))
    },
    getTimelogs() {
      this.timelogsLoaded = false
      this.timelogsLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let url = this.prepareUrlForTimelogs(sortBy, sortDesc, page, itemsPerPage)
      HTTP.get('timelog' + url).then(function (response) {
        let timelogs = []
        response.data.data.forEach((tl) => {
            let existingTLIndex = timelogs.findIndex((t) => { return t.id === tl.user.id })
            if (existingTLIndex === -1) {
              timelogs.push({
                'id': tl.user.id,
                'name': tl.user.firstname + ' ' + tl.user.lastname,
                'duration': tl.work_duration_seconds,
                'first_date': moment(tl.work_start_time).format('DD.MM.YYYY'),
                'last_date': moment(tl.work_end_time).format('DD.MM.YYYY')
              })
            } else {
              timelogs[existingTLIndex].duration += tl.work_duration_seconds
              let startDate = moment(tl.work_start_time).format('DD.MM.YYYY')
              if (timelogs[existingTLIndex].first_date > startDate) {
                timelogs[existingTLIndex].first_date = startDate
              }
              let endDate = moment(tl.work_end_time).format('DD.MM.YYYY')
              if (timelogs[existingTLIndex].last_date < endDate) {
                timelogs[existingTLIndex].last_date = endDate
              }
            }
        })
        this.timelogs.data = timelogs
        this.timelogsTotal = timelogs.length
        this.timelogsLoading = false
        this.timelogsLoaded = true
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.timelogsLoading = false
          this.timelogsLoaded = true
        }
      }.bind(this))
    },
    prepareUrlForTimelogs(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.filterStore.filter.dateStart) {
        queryStringParts.push('after=' + encodeURIComponent(moment(this.filterStore.filter.dateStart).startOf('day').format()))
      } else {
        queryStringParts.push('after=' + encodeURIComponent(moment(this.detailCustomer.lock_target_date).subtract(3, 'months').startOf('day').format()))
      }
      if (this.detailCustomer.lock_target_date) {
        queryStringParts.push('before=' + encodeURIComponent(moment(this.detailCustomer.lock_target_date).endOf('day').format()))
      } else {
        queryStringParts.push('before=' + encodeURIComponent(moment().endOf('day').format()))
      }
      queryStringParts.push('c=' + this.detailCustomer.id)
      queryStringParts.push('page=1')
      queryStringParts.push('itemsPerPage=' + itemsPerPage)
      return '?' + queryStringParts.join('&')
    },
    showPauseDialog(customer) {
      this.pauseMode = customer.lock_is_paused ? 'unpause' : 'pause'
      this.pauseCustomer = customer
      this.pauseDialog = true
    },
    hidePauseDialog() {
      this.pauseMode = 'pause'
      this.pauseCustomer = null
      this.pauseDialog = false
    },
    doPauseUnpause() {
      if (this.pauseCustomer.id) {
        this.isPausing = true
        HTTP.get('customer-pause/' + this.pauseCustomer.id).then(function () {
          this.hidePauseDialog()
          this.isPausing = false
          this.reload()
        }.bind(this)).catch(function (error) {
          this.$root.infoNotification.showMessage(this.t('http-error.unknown') + error.response.statusText)
          this.isPausing = false
        }.bind(this))
      }
    },
    showLockDialog(lock) {
      this.lockMode = lock ? 'lock' : 'unlock'
      this.lockDialog = true
    },
    hideLockDialog() {
      this.lockMode = 'lock'
      this.fCselectAll = false
      this.lockDialog = false
    },
    doLockNext() {
      let selectedCustomers = this.customers.filter((cust) =>
        cust.selected
      )
      if (selectedCustomers.length == 0) {
        this.$root.infoNotification.showMessage(this.t('validation.select-customers'))
        return
      }
      this.lockTab = 1
    },
    doLockPrevious() {
      this.lockTab = 0
    },
    doLockUnlock() {
      if (this.isLocking) {
        return
      }
      this.isLocking = true

      const json = JSON.stringify({
        customers: this.customers.filter((cust) => cust.selected),
        lockmode: this.lockMode,
        lockdate: this.lockMode == 'lock' ? this.lockDateEnd : this.lockDateStart
      })

      HTTP.post('customers-lock', { 'data': json }).then(function () {
        this.hideLockDialog()
        this.isLocking = false
        this.lockTab = 0
        this.reload()
      }.bind(this)).catch(function (error) {
        if (error.response.status && error.response.status === 304) {
          this.$root.infoNotification.showMessage(this.t('http-error.304'))
        } else {
          this.$root.infoNotification.showMessage(this.t('http-error.unknown') + error.response.statusText)
        }
        this.isLocking = false
      }.bind(this))
    },
    prepareUrl() {
      let queryStringParts = []
      if (this.filterStore.filter.dateStart) {
        queryStringParts.push('dateStart=' + this.filterStore.filter.dateStart)
      }
      if (this.filterStore.filter.dateEnd) {
        queryStringParts.push('dateEnd=' + this.filterStore.filter.dateEnd)
      }
      if (this.filterStore.filter.searchCustomer !== '') {
        queryStringParts.push('q=' + this.filterStore.filter.searchCustomer)
      }
      return '?' + queryStringParts.join('&')
    },
    getMonthName(index) {
      if (this.daysOfMonth[index][0] == '01') {
        let month = index.split('-')[1]
        let year = this.years[index]
        return this.monthNames[month] + ' ' + year
      } else {
        return ' '
      }
    },
    getMonthWidth(index) {
      return this.daysOfMonth[index].length * 16
    },
    showMonthName(index) {
      return this.daysOfMonth[index][0] == '01'
    },
    showOverlay(customer) {
      this.detailCustomer = customer
      this.$nextTick(function () {
        this.hasOverlay = true
      })
    },
    closeOverlay() {
      this.hasOverlay = false
      this.detailCustomer = null
      this.timelogs = {
        page: 1,
        current_page: 1,
        last_page: 1,
        total: null,
        data: []
      }
      this.timelogsLoaded = false
    },
    calculateTimespanWidth(customer) {
      let limitDaysToTargetDate = customer.days_to_target_date > this.maxDays ? this.maxDays : customer.days_to_target_date
      return customer.days_to_target_date ? (limitDaysToTargetDate * 12) + ((limitDaysToTargetDate - 1) * 6) + 16 : 0
    },
    customerIsPaused(customer) {
      return customer.lock_is_paused
    },
    lockDateOutsideRange(customer) {
      return customer.days_to_target_date > this.maxDays
    },
    getPausedIconClass(customer) {
      return customer.lock_is_paused ? 'mdi-lock-open-outline' : 'mdi-lock-outline'
    },
    getDetailStartDate() {
      return this.filterStore.filter.dateStart ? moment(this.filterStore.filter.dateStart).format('DD.MM.YYYY') : moment(this.detailCustomer.lock_target_date).subtract(3, 'months').startOf('day').format('DD.MM.YYYY')
    },
    checkAll(checkAll) {
      this.customers.forEach((cust) => {
         cust.selected = checkAll
      })
    },
    fcCheckAll(checkAll) {
      this.filteredCustomers.forEach((fcust) => {
         fcust.selected = checkAll
      })
    },
    getLockAuthorName(cust) {
      if (cust.lock_author.firstname && cust.lock_author.name) {
        return cust.lock_author.firstname + ' ' + cust.lock_author.name
      } else {
        return cust.lock_author.display_name
      }
    },
    handleDrag() {
      const parent = document.getElementById('timelock-timelocks-wrapper')
      let maxScrollLeft = parent.scrollWidth - parent.clientWidth
      parent.scrollLeft = maxScrollLeft
      parent.style.cursor = 'grab'

      let pos = { left: 0, x: 0 }

      const mouseDownHandler = function (e) {
        parent.style.cursor = 'grabbing'
        parent.style.userSelect = 'none'

          pos = {
              left: parent.scrollLeft,
              x: e.clientX
          };

          parent.addEventListener('mousemove', mouseMoveHandler)
          parent.addEventListener('mouseup', mouseUpHandler)
      }

      const mouseMoveHandler = function (e) {
          const dx = e.clientX - pos.x
          parent.scrollLeft = pos.left - dx
      }

      const mouseUpHandler = function () {
          parent.style.cursor = 'grab'
          parent.style.removeProperty('user-select')

          parent.removeEventListener('mousemove', mouseMoveHandler)
          parent.removeEventListener('mouseup', mouseUpHandler)
      }

      parent.addEventListener('mousedown', mouseDownHandler)
      parent.addEventListener('mouseleave', mouseUpHandler)

    }
  }
}
</script>

<style scoped lang="scss">
.sp-app.v-application .container--fluid.has-bottom-navi {
  padding-bottom: 110px;
}

.button-flex-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
