const themesPon = {
  themes: {
    light: {
      primary: '#EAB200',
      secondary: '#FFF2C8',
      success: '#4CAF50',
      warning: '#FB8C00',
      error: '#FF5252',
      buttons:'#EAB200'
    }
  },
}

export default themesPon;