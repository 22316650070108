<template>
  <v-select
      class=""
      v-model="selectedReasons"
      :label="$t('components.absence-reason-filter.label')"
      :items="reasons"
      item-text="name"
      item-value="id"
      multiple
      chips
  ></v-select>

</template>

<script>
import { HTTP } from '../../auth'

export default {
  name: "AbsenceReasonFilter",
  props: ['value'],
  data() {
    return {
      selectedReasons: this.value,
      reasons: [],
    }
  },
  watch: {
    selectedReasons: function (val) {
      this.$emit('input', val)
    }
  },
  async created() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      let response = await HTTP.get('absences/reasons')
      this.reasons = response.data.data
    },
  },
}
</script>
