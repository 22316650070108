<template>
    <div>
        <BasicCreateEditContainer
            :title="$t('shop.orders.edit.title', { orderNumber: order.number })"
            ref="editor"
            object-type="Order"
            :object-name="order.number"
            :data-loaded="dataLoaded"
            @cancel="close"
            :save-action="saveOrder"
            :allow-delete="false"
            :has-changes="hasChanges"
        >
            <template v-slot:content> 
                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('product.card-title') }}</v-card-title>
                    <v-card-text>

                        <v-data-table
                            :headers="productHeaders"
                            :items="order.order_products"
                            :no-data-text="t('table.no-data')"
                            class="simple-data-table elevation-0 mt-5 mb-5"
                            hide-default-footer
                        >
                            <template v-slot:[`item.image`]="{ item }">
                                <image-wrapper
                                    :link="getPreviewPictureLink(item.product_id)"
                                    :can-remove="false"
                                    :max-width="60"
                                    :max-height="60" />
                            </template>
                        </v-data-table>

                    </v-card-text>

                </v-card>
            </template>
                
            <template v-slot:meta>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('status-title') }}</v-card-title>
                    <v-card-text v-if="dataLoaded">
                        <v-radio-group class="radio-group" v-model="order.status">
                            <v-radio
                                class="radio-status-processing"
                                :label="$t('order-status-icons.processing')" 
                                :disabled="dataOriginal.status === 'cancelled' || dataOriginal.status === 'completed'"
                                value="processing">
                            </v-radio>
                            <v-radio
                                class="radio-status-ready"
                                :disabled="dataOriginal.status === 'cancelled' || dataOriginal.status === 'completed'"
                                :label="$t('order-status-icons.ready')" 
                                value="ready"></v-radio>
                            <v-radio
                                class="radio-status-ready"
                                :disabled="dataOriginal.status === 'cancelled' || dataOriginal.status === 'completed'"
                                :label="$t('order-status-icons.delivered')" 
                                value="delivered"></v-radio>
                            <v-radio
                                class="radio-status-completed"
                                :label="$t('order-status-icons.completed')" 
                                :disabled="dataOriginal.status === 'cancelled'"
                                value="completed"></v-radio>
                            <v-radio
                                class="radio-status-cancelled"
                                :disabled="dataOriginal.status === 'completed'"
                                :label="$t('order-status-icons.cancelled')"
                                value="cancelled"></v-radio>
                        </v-radio-group>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('order-title') }}</v-card-title>
                    <v-card-text>
                        <p>{{ t('order-number') }}: #{{ order.number }}</p>
                        <p>{{ t('ordered-at') }}: {{ formatDate(order.date) }}</p>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('client-info-title') }}</v-card-title>
                    <v-card-text>
                        <p>{{ order.user.display_name }}</p>
                        <p>{{ order.user.email }}</p>
                        <p>{{ order.user.phone_number }}</p>
                    </v-card-text>
                </v-card>
            </template>

        </BasicCreateEditContainer>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
import ImageWrapper from '@/components/ImageWrapper'

export default {
    name: 'ShopOrdersDetails',
    components: {
        BasicCreateEditContainer,
        ImageWrapper
    },
    data() {
        return {
            order: {
                id: null,
                user_id: null,
                status: "new",
                number: "",
                date: "",
                status_date: "",
                total: "12.00",
                order_products: [],
                user: {
                    display_name: '',
                    email: '',
                    phone_number: ''
                }
            },
            dataOriginal: null,
            dataLoaded: false,
            pictureCache: {}
        }
    },
    created() {
        this.order.id = this.$route.params.id
        if (this.order.id) {
            this.getOrder()
        }
    },
    methods: {
        t: function (key) {
            return this.$t('shop.orders.edit.' + key)
        },
        async saveOrder() {
            await HTTP.put('/shop_orders/' + this.order.id, this.order)
            this.close()
        },
        close() {
            this.$router.push({ name: 'ShopOrdersIndex' })
        },
        getOrder() {
            HTTP.get('shop_orders/' + this.order.id).then(function (response) {
                this.order = response.data
                this.dataOriginal = JSON.parse(JSON.stringify(this.order))                  
                this.dataLoaded = true
            }.bind(this)).catch(function (error) {
                console.log(error)
                this.dataLoaded = true
            }.bind(this))
        },
        getPreviewPictureLink(id) {
            if (this.pictureCache[id]) {
                // If it's in the cache, return the cached value
                return this.pictureCache[id]
            } else {
                // If it's not in the cache, generate the picture link
                let cacheBuster = new Date().getTime()
                let val = '/shop_products/image/' + id
                let selectedPicture = `${val}?${cacheBuster}`

                // Save the picture link in the global array
                this.pictureCache[id] = selectedPicture

                // Return the generated picture link
                return selectedPicture
            }
        }
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.order) !== JSON.stringify(this.dataOriginal)
        },
        productHeaders() {
            return [
                {
                    text: this.t('table.headers.product'),
                    align: 'start',
                    sortable: false,
                    value: 'image',
                    width: 150
                },
                {
                    text: this.t('table.headers.details'),
                    align: 'middle',
                    sortable: false,
                    value: 'title',
                    width: 150
                },
                {
                    text: this.t('table.headers.variant'),
                    align: 'middle',
                    sortable: false,
                    value: 'subtitle',
                    width: 150
                },
                {
                    text: this.t('table.headers.product_number'),
                    align: 'middle',
                    sortable: false,
                    value: 'sku',
                    width: 150
                },
                {
                    text: this.t('table.headers.amount'),
                    align: 'middle',
                    sortable: false,
                    value: 'amount',
                    width: 150
                }
            ]
        }
    }
}
</script>

<style scoped lang="scss">  
.radio-group {
    width: 50%;
    border-radius: 20px;

    ::v-deep .v-label {
        padding: 3px 8px;
        border-radius: 8px;
    }

    .radio-status-processing ::v-deep .v-label {
        background-color: #FEC57D3D;
        color: #FB8C00;
        flex-grow: inherit;
    }

    .radio-status-ready ::v-deep .v-label {
        background-color: #70D27330;
        color: #49894B;
        flex-grow: inherit;
    }

    .radio-status-completed ::v-deep .v-label {
        background-color: #F5F5F5;
        color: #666666;
        flex-grow: inherit;
    }

    .radio-status-cancelled ::v-deep .v-label {
        background-color: #FBE9E7;
        color: #E30613;
        flex-grow: inherit;
    }

    .v-radio--is-disabled {
        opacity: 0.6;
    }
}
</style>
