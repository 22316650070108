<template>
  <div class="sp-flex-col sp-pwreset">
    <div class="sp-flex-col sp-flex-1 sp-flex-v-center">
      <div class="sp-login-logo"></div>
    </div>

    <md-layout md-gutter>
      <md-layout md-gutter md-hide-xsmall md-flex="10"></md-layout>
      <md-layout md-gutter md-flex-xsmall="100" md-flex="80">
        <md-whiteframe :md-elevation="0" style="overflow: hidden; width: 100%;">
          <md-input-container :class="{ 'md-input-invalid': errors.invalid }">
            <label> {{ $t('settings.email-address') }} </label>
            <md-input type="email" v-model="credentials.email"></md-input>
            <span class="md-error">
              <span v-for="(item, index) in errors.validationError">{{ item }}</span>
            </span>
          </md-input-container>
          <md-button class="md-raised md-accent md-fullwidth" @click.native="resetPassword()">reset</md-button>
          <router-link :to="{name: 'Login'}">
          <md-button class="md-fullwidth sp-text-blue sp-mar-r-0" type="button">{{ $t('logout.back-to-login') }}</md-button>
        </router-link>
        </md-whiteframe>
      </md-layout>
    </md-layout>

    <md-snackbar ref="snackbar" :md-duration="4000" @close="removeErrors()">
      <span v-for="error in errors.validationError" >{{ error }}</span>
      <span v-if="errors.successMessage">{{ errors.successMessage }}</span>
    </md-snackbar>

  </div>
</template>

<script>
  import axios from 'axios'
let BASE_URL = process.env.VUE_APP_API_BASE_URL ?? ''

  export default {
    data () {
      return {
        credentials: {
          email: '',
          password: ''
        },
        message: '',
        errors: {
          invalid: false,
          successMessage: '',
          validationError: ''
        }
      }
    },
    mounted () {
    },
    methods: {
      removeErrors () {
        this.errors.validationError = ''
        this.errors.successMessage = ''
      },
      resetPassword () {
        this.removeErrors()
        axios.post(BASE_URL + '/password/email', {email: this.credentials.email})
            .then(function (response) {
              this.errors.successMessage = response.data.message
              this.errors.invalid = false
              this.openSnackbar()
            }.bind(this))
            .catch(function (error) {
              if (error.response) {
                this.errors.validationError = error.response.data.errors.email
                // this.openSnackbar()
                this.errors.invalid = true
              }

              if (!error.status) {
                this.error = true
                this.dataLoaded = false
              }
            }.bind(this))
      },
      openSnackbar () {
        let that = this
        setTimeout(() => {
          that.$refs.snackbar.open()
        }, 500)
      }
    }
  }
</script>
