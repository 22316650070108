<template>
  <div>
    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>


    <v-container fluid class="settings">

      <loading :error="error" :dataLoaded="dataLoaded"></loading>

      <v-form ref="form">

        <div v-if="dataLoaded">

          <v-row class="settings-wrapper">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ t('email-recipient-new-absence') }}</v-card-title>
              <v-card-text>

                <v-switch
                    v-model="data.absence_email_enabled.value"
                    inset
                    :label="data.absence_email_enabled.value ? t('yes') : t('no')"
                ></v-switch>

                <br>

                <v-text-field
                    label="Email"
                    v-model="data.absence_email.value"
                    filled
                    :rules="[
                v => (data.absence_email_enabled.value && !data.absence_email.value) ? $t('validation.required.default') : true

							]"
                ></v-text-field>

              </v-card-text>
            </v-card>

            <v-card
                elevation="0"
            >
              <v-card-title>{{ t('email-recipient-confirmed-absence') }}</v-card-title>
              <v-card-text>

                <v-switch
                    v-model="data.absence_email_approved_enabled.value"
                    inset
                    :label="data.absence_email_approved_enabled.value ? t('yes') : t('no')"
                ></v-switch>

                <br>

                <v-text-field
                    label="Email"
                    v-model="data.absence_email_approved.value"
                    filled
                    :rules="[
                v => (data.absence_email_approved_enabled.value && !data.absence_email_approved.value) ? $t('validation.required.default') : true
							]"
                ></v-text-field>

              </v-card-text>
            </v-card>

          </v-row>

        </div>

      </v-form>

    </v-container>


    <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        timeout="4000"
    >
      {{ message }}
    </v-snackbar>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
                color="primary"
                text
                class="icon-left"
                @click="clear()"
            >
              <v-icon>mdi-reload</v-icon>
              {{ t('reset') }}
            </v-btn>
            <v-spacer></v-spacer>
            <app-dialog-confirm-save
                :validateForm="$refs.form"
                @confirmed="event => update(event)"
            />
          </v-row>
        </v-container>
      </div>
    </transition>

  </div>

</template>


<script>
import {HTTP} from './../auth'
import toolbar from '../components/layouts/Navigation.vue'
import loading from '../components/layouts/Loading.vue'
import AppDialogConfirmSave from "../components/vuetify/AppDialogConfirmSave";

export default {
  name: 'SettingsAbsence',
  components: {
    toolbar,
    loading,
    AppDialogConfirmSave
  },
  data() {
    return {
      data: [],
      settings: {
        absence_email: {},
        absence_email_enabled: {},
        absence_email_approved: {},
        absence_email_approved_enabled: {},
      },
      dataLoaded: false,
      validated: false,
      message: 'Erfolgreich gespeichert.',
      messageSuccess: 'Erfolgreich gespeichert.',
      error: null,
      errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.',
      snackbar: false
    }
  },
  created() {
    this.getData()
  },
  methods: {
    t: function (key) {
      return this.$t('absence-settings.' + key);
    },
    reload() {
      this.$nextTick(function () {
        this.getData()
      }.bind(this))
    },
    update (isConfirmed) {
      if(!isConfirmed){
        this.clear()
      }

      for (var item in this.data) {
        HTTP.patch('settings/' + this.data[item].id, this.data[item]).then(function (response) {
          this.message = this.messageSuccess
          this.openSnackbar()
        }.bind(this)).catch(function (error) {
          if (error.response) {
            this.message = this.errorMessage
          }
        }.bind(this))
      }
    },
    clear() {
      this.dataLoaded = false
      this.data = []
      this.getData()
    },
    getData() {
      HTTP.get('settings?key=absence').then(function (response) {
        this.settings.absence_email = response.data.filter(item => item.key === 'absence_email').shift()
        this.settings.absence_email_enabled = response.data.filter(item => item.key === 'absence_email_enabled').shift()
        this.settings.absence_email_approved = response.data.filter(item => item.key === 'absence_email_approved').shift()
        this.settings.absence_email_approved_enabled = response.data.filter(item => item.key === 'absence_email_approved_enabled').shift()
        this.data = Object.assign({}, this.settings)
        this.data.absence_email_enabled.value = this.data.absence_email_enabled.value === 'true'
        this.data.absence_email_approved_enabled.value = this.data.absence_email_approved_enabled.value === 'true'
        this.dataLoaded = true
        this.error = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 600)
    }
  }
}
</script>

<style scoped lang="scss">

.apply-setting {
  color: #FFF !important;
  background: #FF5252 !important;
}

.settings-wrapper {
  justify-content: space-between;

  .v-card {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
}

</style>
