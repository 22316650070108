<template>
  <div>
    <app-search-field
        v-model="searchTerm"
        :disable="!!selectedItem"
        filterLabel="Nach Kundenprojekt suchen"
        append-icon="mdi-magnify"
        @click:clear="clear"
        @keydown.esc="clearSearch"
        :clearable="clearable"
    ></app-search-field>
    <v-list class="search-results elevation-2"
            v-if="showList">
      <v-list-item v-for="item in foundItems" :key="item.id"
                   @click="setItem(item.id, item)">
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}, {{ item.customer.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { HTTP } from "@/auth";
import axios from "axios";
import AppSearchField from "@/components/vuetify/AppSearchField";

export default {
  name: "CustomerProjectSelector",
  components: { AppSearchField },
  emits: ['item-selected'],
  props: {
    clearable: Boolean
  },
  data() {
    return {
      searchTerm: '',
      selectedItem: null,
      foundItems: []
    }
  },
  computed: {
    showList() {
      return this.foundItems.length > 0 && this.foundItems.length < 15;
    }
  },
  watch: {
    searchTerm: function (val) {
      if (val.trim() !== '') {
        this.search(val)
      }
    },
  },
  methods: {
    clearSearch() {
      this.foundItems = [];
      this.searchTerm = '';
    },
    clear() {
      this.clearSearch();
      this.selectedItem = null;
    },
    setItem(id, data) {
      this.clearSearch();
      this.selectedItem = id;
      this.$emit('item-selected', id, data);
    },
    search(val) {
      if (this.cancelHTTP) {
        this.cancelHTTP();
      }
      let options = {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      };
      HTTP.get('customer-projects/search?q=' + val, options)
          .then(function (response) {
            this.foundItems = response.data
          }.bind(this))
          .catch(function (error) {
          }.bind(this));
    },
  }
}
</script>

<style scoped>

</style>
