<template>
  <div class='ImageModal imagemodal' v-if='showModal'>
    <div class='modal-content'>
      <h1>Bild einfügen</h1>
      <div>
        <md-input-container >
          <label>Bild auswählen</label>
          <md-file 
            ref="mdfile"
            v-model="inputDocumentFile"
            @selected="fileChange($event)"
            :required="true"
            accept="image/*">
          </md-file>
          <span class="md-error">Bitte ein Bild auswählen</span>
        </md-input-container>
      </div>
      <footer class='modal-footer'>
        <md-button  @click.native="submit" class="md-raised" :disabled="noImageAdded">Bild einfügen</md-button>
        <md-button  @click.native="closeModal" class="md-raised">Abbrechen</md-button>
        <div v-if="imageUploading"><p><b>uploading... please wait</b></p></div>
      </footer>
    </div>
  </div>
</template>
<script>
import { HTTP } from './../../auth'

export default {
  name: "ImageModal",
  data () {
    return {
      data: null,
      uploadTarget: '',
      imageUploading: false,
      isUpload: false,
      externImageSrc: '',
      uploadImageSrc: '',
      imageSrc: '',
      noImageAdded: true,
      image: '',
      tempImages: [],
      inputDocumentFile: null,
      command: null,
      showModal: false,
      tab: 2,
    }
  },
  props: {
    timestamp: Number,
    newsPostID: null
  },
  created () {
    this.uploadTarget = this.$parent.uploadTarget
  },
  watch: {
    changeData: {
      handler: function (val) {
        // console.log('value change: ', val)
      },
      deep: true
    }
  },
  computed: {
    changeData () {
      const { externImageSrc, uploadImageSrc } = this

      if (this.externImageSrc !== '' || this.uploadImageSrc !== '') {
        this.noImageAdded = false
      } else {
        this.noImageAdded = true
      }
      return {
        externImageSrc,
        uploadImageSrc
      }
    }
  },
  methods: {
    showImageModal (command) {
      this.command = command
      this.showModal = true
    },
    fileChange (e) {
      this.files = e[0]
      const file = e[0]
      this.uploadImageSrc = URL.createObjectURL(file)
    },
    submit () {
      if (this.tab === 2) {
        this.imageUploading = true
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }

        let formData = new FormData()
        if (this.files) {
          formData.append('file', this.files)
        }

        const json = JSON.stringify({
          timestamp: this.timestamp,
          newsPostID: this.newsPostID,
          uploadTarget: this.uploadTarget
        })

        formData.append('data', json)

        HTTP.post('content/upload', formData, config)
          .then(function (response) {
            this.imageSrc = response.data.path
            this.tempImages.push(response.data.path)
            this.insertImage()
            this.imageUploading = false
          }.bind(this))
          .catch(function (error) {
            console.log(error.response)
          })
      } else {
        this.imageSrc = this.externImageSrc
        this.insertImage()
      }
    },
    insertImage () {
      const data = {
        command: this.command,
        data: {
          src: this.imageSrc
        }
      }

      this.$emit('onConfirm', data)
      this.closeModal()
      this.resetFile()
    },
    resetFile (ref) {
      this.$refs[ref].parentContainer.value = ''
      this.$refs[ref].textInput.value = ''
    },
    closeModal () {
      this.showModal = false
      this.uploadImageSrc = ''
      this.externImageSrc = ''
      this.imageSrc = ''
      this.tab = 1
      this.imageUploading = false
      this.inputDocumentFile = null
    }
  }
}

</script>
<style scoped>

.md-button {
	margin: 0;
}

.md-button + .md-button {
	margin-left: 10px;
}

.imagemodal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal-content {
  width: 50%;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
}

.modal-footer {
  margin-top: 10px;
}

label {
  display: block;
  margin: 0.25em 0;
}


.tab-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #222;
}

.tab-header button {
  color: #222;
  background: none;
  border: 0;
  flex: 1;
  padding: 5px 10px;
  cursor: pointer;
}

.tab-header button.active {
  background-color: #222;
  color: #fff;
}

</style>
