<template>
  <div class="edit-screen has-hero-container">

    <div :class="{'hide-content' : showResult}">

      <toolbar :dataLoaded="true">{{t('title')}}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter">

          <v-btn
              v-if="filterChanged"
              elevation="0"
              color="primary"
              class="filter-apply icon-left"
              @click="applyFilter()"
          >
            <v-icon>mdi-check</v-icon>
            {{t('list.apply')}}
          </v-btn>

          <v-slide-group show-arrows>

            <v-slide-item>

              <div>
                <div class="filter-item">
                  <app-filter-date-picker
                    :filterLabel="t('label.startdate')"
                    :date="dateStart"
                    id="dateStart">
                  </app-filter-date-picker>
                </div>
              </div>

            </v-slide-item>
            <v-slide-item>

              <div>
                <div class="filter-item">
                  <app-filter-date-picker
                    :filterLabel="t('label.enddate')"
                    :date="dateEnd"
                    id="dateEnd">
                  </app-filter-date-picker>
                </div>
              </div>

            </v-slide-item>
            <v-slide-item>

              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <app-filter-search-field
                        v-model="searchTermCustomer"
                        :disable="!!selectedCustomer"
                        :loading="loadingCustomer"
                        :filterLabel="t('label.customer')"
                        clearable
                        append-icon="mdi-magnify"
                        @click:clear="clearSearchCustomer"
                    ></app-filter-search-field>
                    <v-list class="search-results elevation-2"
                            v-if="!selectedCustomer && customers.length > 0 && customers.length < 15">
                      <v-list-item v-for="customer in customers" :key="customer.id"
                                   @click="setCustomer(customer.id, customer.name)">
                        <v-list-item-content>
                          <v-list-item-title>{{ customer.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>

            </v-slide-item>
            <!-- <v-slide-item v-if="selectedCustomer">

              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <app-filter-search-field
                        v-model="searchTerm"
                        :disable="!!selectedChecklist"
                        :filterLabel="t('label.checklist')"
                        :loading="loadingChecklist"
                        clearable
                        append-icon="mdi-magnify"
                        @click:clear="clearSearch"
                    ></app-filter-search-field>
                    <v-list class="search-results elevation-2"
                            v-if="!selectedChecklist && checklists.length > 0 && checklists.length < 15">
                      <v-list-item v-for="checklist in checklists" :key="checklist.id"
                                   @click="setChecklist(checklist.id, checklist.name)">
                        <v-list-item-content>
                          <v-list-item-title>{{ checklist.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>

            </v-slide-item> -->

          </v-slide-group>

        </v-container>

      </div>

      <v-container fluid v-if="isProcessing" class="statistics">
        <v-row class="justify-center">
          <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </v-row>
      </v-container>

      <v-container fluid v-if="allDataLoaded && !hasAnyResult" class="statistics">
        <v-row>
          <h3>{{ t('list.no-data') }}</h3>
        </v-row>
      </v-container>

      <v-container fluid v-if="allDataLoaded && hasAnyResult" class="statistics">

        <v-row class="data-table-toolbar">
          <h2>{{ t('list.qualityreports') }}</h2>
          <v-spacer></v-spacer>
          <div>
            <v-btn
                outlined
                elevation="0"
                color="primary"
                class="icon-left"
                :disabled="!readyToDownload"
                :loading="isDownloadingExcel"
                @click="saveAsExcel()"
            >
              <v-icon>mdi-download</v-icon>
              {{ t('list.excel-save') }}            </v-btn>
          </div>
          <div>
            <v-btn
                outlined
                elevation="0"
                color="primary"
                class="icon-left"
                :disabled="!readyToDownload"
                :loading="isDownloadingPDF"
                @click="saveAsPdf()"
            >
              <v-icon>mdi-download</v-icon>
              {{ t('list.pdf-save') }}
            </v-btn>
          </div>
        </v-row>

        <!-- <v-row v-if="hasAnyResult && reportLevel == 'checklist'">
          <h3>{{ evaluations.checklist.name }}</h3>
        </v-row> -->

        <div v-if="hasAnyResult">
          <v-row class="light-border">

          <v-col class="stat-item stat-item-centered">
            <div class="sp-pad-2 stat-item-header">{{ t('list.trend') }}</div>
            <div class="sp-pad-2 trend-wrapper">
              <span v-if="evaluations.trend.slope > 0"><span class="trend-up"></span></span>
              <span v-if="evaluations.trend.slope < 0"><span class="trend-down"></span></span>
              <span v-if="evaluations.trend.slope == 0"><span class="trend-even"></span></span>
            </div>
          </v-col>

          <v-col class="stat-item stat-item-centered">
            <div class="sp-pad-2 stat-item-header"># {{ t('list.qualityreports') }}</div>
            <div class="sp-pad-2">
              {{ evaluations.overall.total }}
            </div>
          </v-col>

          <v-col class="stat-item stat-item-centered">
            <div class="sp-pad-2 stat-item-header">{{ t('list.minimum-quality-value') }}</div>
            <div class="sp-pad-2">
              {{ evaluations.overall.min }}%
            </div>
          </v-col>

          <v-col class="stat-item stat-item-centered">
            <div class="sp-pad-2 stat-item-header">{{ t('list.maximum-quality-value') }}</div>
            <div class="sp-pad-2">
              {{ evaluations.overall.max }}%
            </div>
          </v-col>

          <v-col class="stat-item stat-item-centered">
            <div class="sp-pad-2 stat-item-header">{{ t('list.average') }}</div>
            <div class="sp-pad-2">
              {{ evaluations.overall.average }}%
            </div>
          </v-col>

          </v-row>
        </div>

        <div v-if="hasAnyResult && reportLevel == 'customer'" class="checklist-wrapper">

          <v-row>
            <h3>Checklisten</h3>
          </v-row>

          <div class="light-border-background">

            <v-row no-gutters>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-header">{{ t('list.checklist') }}</div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-header"># {{ t('list.qualityreports') }}</div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-header">{{ t('list.minimum-quality-value') }}</div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-header">{{ t('list.maximum-quality-value') }}</div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-header">{{ t('list.average') }}</div>
              </v-col>

            </v-row>

            <v-row no-gutters v-for="(checklist, index) in evaluations.checklists" :key="index"
                   class="no-top-margin border-bottom">

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-content checklist-name">
                  <span class="checklist-color" :style="'background-color:' + checklist.checklist_color"></span>
                  {{ checklist.checklist_name }}
                </div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-1 stat-item-content">
                  <v-btn
                    rounded
                    text
                    @click="checklist.checklist_total == 1 ? showReport(checklist.reports[0].id) : openReportDialog(checklist.reports)"
                  >
                    {{ checklist.checklist_total }}
                  </v-btn>
                </div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-content">
                  {{ checklist.checklist_min }}%
                </div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-content">
                  {{ checklist.checklist_max }}%
                </div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-content">
                  {{ checklist.checklist_average }}%
                </div>
              </v-col>

            </v-row>

          </div>

        </div>

        <!-- <div v-if="hasAnyResult && reportLevel == 'checklist'" class="task-wrapper">

          <v-row>
            <h3>Tasks</h3>
          </v-row>

          <div class="light-border-background">

            <v-row no-gutters>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-header">{{ t('list.task') }}</div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-header"># {{ t('list.test') }}</div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-header">{{ t('list.minimum-quality-value') }}</div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-header">{{ t('list.minimum-quality-value') }}</div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-header">{{ t('list.average') }}</div>
              </v-col>

            </v-row>

            <v-row no-gutters v-for="(task, index) in evaluations.tasks" :key="index" class="no-top-margin border-bottom">

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-content checklist-name">
                  <span class="checklist-color" :style="'background-color:' + task.color"></span>
                  {{ task.name }}
                </div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-content">
                  {{ task.total }}
                </div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-content">
                  {{ task.min }}%
                </div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-content">
                  {{ task.max }}%
                </div>
              </v-col>

              <v-col class="stat-item stat-item-centered stat-item-noborder">
                <div class="sp-pad-2 stat-item-content">
                  {{ task.average }}%
                </div>
              </v-col>

            </v-row>

          </div>

        </div> -->

        <div v-if="hasAnyResult" class="stats-chart-wrapper">
          <v-row>
            <line-chart-with-quality-targets
              :dataCollection="evaluations.data" :title="lineChartDurationTitle"
              :average="evaluations.overall.average"
              :trend="evaluations.trend">
            </line-chart-with-quality-targets>
          </v-row>
        </div>

        <div v-if="hasAnyResult">
          <v-row>

            <v-card elevation="0">
              <v-card-title>{{ t('list.quality-reports-rating') }}</v-card-title>
              <v-card-text>
                <div v-for="report in reportsByRating" :key="report.report_id" :value="report.report_id"
                     class="report-list-item" @click="showReport(report.report_id)">
                  <span class="report-list-name">{{ report.report_name }}</span>
                  <span class="report-list-customer">{{ report.customer_name }}</span>
                  <span class="report-list-checklist">{{ report.checklist_name }}</span>
                  <span class="report-list-date">{{ report.report_date | momentDate }}</span>
                  <div class="report-list-value">{{ report.average_value }}%</div>
                  <div class="report-list-show">
                    <v-icon md-list-icon>mdi-eye-outline</v-icon>
                  </div>
                </div>
              </v-card-text>
            </v-card>

          </v-row>
        </div>

      </v-container>

      <v-snackbar
          ref="snackbar"
          v-model="snackbar"
          timeout="4000"
      >
        {{ message }}
      </v-snackbar>

    </div>

    <v-dialog
      v-model="reportDialog"
      content-class="checklist-report-dialog"
      width="700"
    >
      <v-card>
        <v-card-title>
          {{ t('dialog.title') }}
        </v-card-title>
        <v-card-text>
          <div v-for="(report, index) in this.dialogReports" :key="index" class="reports-wrapper pa-4">
            <div class="reports-date">{{ report['date'] | momentDate }}</div>
            <div class="reports-name">{{ report['name'] }}</div>
            <v-icon @click="showReport(report['id'])">mdi-eye-outline</v-icon>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            text
            class="icon-left px-4"
            @click="hideReportDialog"
          >
            <v-icon>mdi-close</v-icon>
            {{ t('dialog.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <show v-if="showResult" :reportId="reportId" :isOverlay="true"></show>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import axios from 'axios'
import moment from 'moment'
import { EventBus } from '@/event-bus.js'
import toolbar from '@/components/layouts/Navigation'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import show from '@/views/ReportsShow'
import LineChartWithQualityTargets from '@/components/evaluations/LineChartWithQualityTargets'
import loading from '@/components/layouts/Loading'

export default {
  name: 'StatisticsIndex',
  components: {
    toolbar,
    loading,
    AppFilterDatePicker,
    AppFilterSearchField,
    LineChartWithQualityTargets,
    show
  },
  data() {
    return {
      modules: [],
      showResult: false,
      reportId: null,
      dateStart: moment().subtract(28, 'days').format('YYYY-MM-DD'),
      dateEnd: moment().format('YYYY-MM-DD'),
      filterChanged: false,
      reportLevel: 'general',
      searchTerm: '',
      searchTermCustomer: '',
      selectedChecklist: null,
      selectedCustomer: null,
      customers: [],
      checklists: [],
      lineChartDurationTitle: '',
      message: null,
      allDataLoaded: false,
      isDownloadingPDF: false,
      isDownloadingExcel: false,
      isProcessing: false,
      error: null,
      evaluations: {},
      hasAnyResult: false,
      httpCancelHandler: null,
      snackbar: false,
      loadingCustomer: false,
      loadingChecklist: false,
      reportDialog: false,
      dialogReports: []
    }
  },
  mounted() {
    EventBus.$on('http-error', function (msg) {
      this.message = msg
      this.isDownloadingPDF = false
      this.error = true
      this.openSnackbar()
    }.bind(this))
    EventBus.$on('filter-date-changed', function (msg) {
      if (msg.id === 'dateStart') {
        this.dateStart = msg.newDate
      } else {
        this.dateEnd = msg.newDate
      }
      this.filterChanged = true
    }.bind(this))
    EventBus.$on('cancel', function (filter) {
      this.closeReportOverlay()
    }.bind(this))
    this.getStatistics()
  },
  beforeDestroy() {
    EventBus.$off('http-error')
    EventBus.$off('cancel')
    EventBus.$off('filter-date-changed')
  },
  watch: {
    /*searchTerm: function (val) {
      this.loading = false
      if (val !== '') {
        this.getSearchResult(encodeURIComponent(val))
      } else {
        this.checklists = []
      }
    },*/
    searchTermCustomer: function (val) {
      this.loading = false
      if (val !== '') {
        this.getSearchResultCustomer(encodeURIComponent(val))
      } else {
        this.customers = []
      }
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY');
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm');
    }
  },
  computed: {
    readyToDownload: function () {
      return this.allDataLoaded && !this.isDownloadingPDF && !this.isDownloadingExcel
    },
    reportsByRating:function(){
      return this.evaluations.reports.sort(function(a,b){
        return a.average_value < b.average_value
      })
    },
  },
  methods: {
    t: function (key) {
      return this.$t('statistics.' + key);
    },
    getStatistics() {
      this.isProcessing = true
      if (this.httpCancelHandler) {
        this.httpCancelHandler()
      }
      this.httpCancelHandler = null

      var url = this.prepareUrl()
      HTTP.get('statistics/reports' + url, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.httpCancelHandler = c
        }.bind(this))
      }).then(function (response) {
        if (!response.data || response.data.length === 0) {
          return
        }
        this.evaluations = response.data
        if (this.evaluations.reports.length > 0) {
          this.lineChartDurationTitle = 'Zeitraum ' + moment(this.dateStart).format('DD.MM.YYYY') + ' bis ' + moment(this.dateEnd).format('DD.MM.YYYY')
          this.hasAnyResult = true
        }
        this.allDataLoaded = true
        this.isProcessing = false
      }.bind(this))
    },
    saveAsExcel() {
      this.isDownloadingExcel = true
      let lineChart = document.getElementById('line-chart')
      let lineChartData = lineChart.toDataURL()
      var url = this.prepareUrl()
      HTTP.post('dataexport/excel/statistics' + url, {
        'lineChartData': lineChartData,
        'reportLevel': this.reportLevel
      }, {responseType: 'arraybuffer'}).then(function (response) {
        let name = 'Stats'
        let headers = response.headers
        let blob = new Blob([response.data], {type: headers['content-type']})
        let link = document.createElement('a')
        link.style = 'display: none'
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Quali_Report_' + moment().format('DDMMYYYY-HHmm') + '_' + name.replace(' ', '-') + '.xlsx'
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(blob)
        }, 100)
        this.isDownloadingExcel = false
      }.bind(this))
    },
    saveAsPdf() {
      this.isDownloadingPDF = true
      let lineChart = document.getElementById('line-chart')
      let lineChartData = lineChart.toDataURL()
      var url = this.prepareUrl()
      HTTP.post('statistics/statisticspdf' + url, {
        'lineChartData': lineChartData,
        'reportLevel': this.reportLevel
      }, {responseType: 'arraybuffer'}).then(function (response) {
        let name = 'Stats'
        let headers = response.headers
        let blob = new Blob([response.data], {type: headers['content-type']})
        let link = document.createElement('a')
        link.style = 'display: none'
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Quali_Report_' + moment().format('DDMMYYYY-HHmm') + '_' + name.replace(' ', '-') + '.pdf'
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(blob)
        }, 100)
        this.isDownloadingPDF = false
      }.bind(this))
    },
    /*getSearchResult(val) {
      this.loadingChecklist = true
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('checklists?q=' + val + '&c=' + this.selectedCustomer, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        this.checklists = response.data.data
        this.error = false
        this.loadingChecklist = false
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    setChecklist(id, name) {
      this.selectedChecklist = id
      this.checklists = []
      this.searchTerm = name
      this.filterChanged = true
    },
    clearSearch() {
      this.filterChanged = true
      this.selectedChecklist = null
      this.searchTerm = ''
    },*/
    getSearchResultCustomer(val) {
      this.loadingCustomer = true
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('customers?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        this.customers = response.data.data
        this.error = false
        this.loadingCustomer = false
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    setCustomer(id, name) {
      this.selectedCustomer = id
      this.customers = []
      this.searchTermCustomer = name
      this.filterChanged = true
    },
    clearSearchCustomer() {
      this.filterChanged = true
      this.selectedCustomer = null
      this.selectedChecklist = null
      this.searchTermCustomer = ''
      this.searchTerm = ''
    },
    applyFilter() {
      this.filterChanged = false
      this.hasAnyResult = false
      this.allDataLoaded = false
      this.reportLevel = this.getReportLevel()
      this.getStatistics()
    },
    showReport(reportID) {
      this.reportId = reportID
      this.hideReportDialog()
      this.showResult = true
    },
    openReportDialog(reports) {
      this.dialogReports = reports
      this.reportDialog = true
    },
    hideReportDialog() {
      this.reportDialog = false
      this.dialogReports = []
    },
    getReportLevel() {
      if (this.selectedCustomer && this.selectedChecklist) {
        return 'checklist'
      } else if (this.selectedCustomer && !this.selectedChecklist) {
        return 'customer'
      } else {
        return 'general'
      }
    },
    closeReportOverlay() {
      this.reportId = null
      this.showResult = false
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 600)
    },
    prepareUrl() {
      let queryStringParts = []
      if (this.dateStart) {
        queryStringParts.push('dateStart=' + moment(this.dateStart).format('YYYY-MM-DD'))
      }
      if (this.dateEnd) {
        queryStringParts.push('dateEnd=' + moment(this.dateEnd).format('YYYY-MM-DD'))
      }
      if (this.selectedChecklist) {
        queryStringParts.push('l=' + this.selectedChecklist)
      }
      if (this.selectedCustomer) {
        queryStringParts.push('c=' + this.selectedCustomer)
      }
      return '?' + queryStringParts.join('&')
    },
  }
}
</script>

<style scoped lang="scss">

$border-color: #E0E0E0;

h2 {
  margin: 0;
  padding-bottom: 32px;
}

h3 {
  margin: 0;
  padding-bottom: 32px;
}

.light-border {
  border: 1px solid $border-color;
  border-radius: 4px;
}

.light-border-background {
  position: relative;

  &:before {
    content: "";
    width: calc(100% + 24px);
    height: 100%;
    position: absolute;
    top: 0;
    left: -12px;
    border: 1px solid $border-color;
    border-radius: 4px;
  }

}

.row {

  &.no-top-margin {
    margin-top: 0;
  }

  &.border-bottom {
    border-bottom: 1px solid $border-color;
  }
}

.statistics-chart {
  width: 100%;
}

.stat-item {
  border-right: 1px solid $border-color;
  padding: 0;

  &-centered {
    text-align: center;
  }

  &-no-border {
    border-bottom: 0;
  }

  &-header {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .01em;
    line-height: 24px;
    border-bottom: 1px solid $border-color;
    background: var(--v-secondary-base);
  }

  &:last-child {
    border-right: 0;
    margin-right: -1px;
  }
}

.checklist-wrapper,
.task-wrapper {
  margin-top: 32px;
}

.stats-chart-wrapper {
  padding: 48px 0;
}

.report-list-item {
  position: relative;
  padding: 6px 60px 6px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  cursor: pointer;
}

.report-list-item:nth-child(odd) {
  background: #f7f7f7;
}

.report-list-item span {
  display: block;
}

.report-list-name {
  font-weight: bold;
  font-size: 16px;
}

.report-list-value {
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 20px;
  font-weight: bold;
}

.report-list-show {
  position: absolute;
  bottom: 16px;
  right: 28px;
}

.report-list-show i {
  color: #999;
  font-size: 32px;
  transition: color 0.3s;
}

.report-list-item:hover i {
  color: #666;
}

.checklist-name {
  position: relative;
  padding-left: 28px;
}

.checklist-name .sp-pad-2 {
  padding-left: 52px;
}

.checklist-color {
  position: absolute;
  top: 11px;
  left: 11px;
  width: 30px;
  height: 30px;
}

.trend-wrapper {
  position: relative;
  height: 52px;
}

.trend-wrapper > span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.trend-up {
  position: absolute;
  left: calc(50% - 18px);
  top: calc(50% - 9px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 18px 18px 18px;
  border-color: transparent transparent #009D85 transparent;
}

.trend-down {
  position: absolute;
  left: calc(50% - 18px);
  top: calc(50% - 9px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18px 18px 0 18px;
  border-color: #BD3954 transparent transparent transparent;
}

.trend-even {
  position: absolute;
  left: calc(50% - 18px);
  top: calc(50% - 2px);
  width: 36px;
  height: 4px;
  background-color: #CBC32D;
}

.reports-wrapper {
  display: flex;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #F5F5F5;
  }

  .reports-date {
    width: 90px;
    line-height: 24px;
  }

  .reports-name {
    line-height: 24px;
    margin-right: auto;
  }

  .v-icon {
    width: 24px;
    margin-left: 32px;
  }
}
</style>

