<template>
  <div>

    <v-container class="checklist-element-chooser"> 
      <v-row class="ga-4 checklist-element-chooser-wrapper">

        <v-col class="pa-0">
          <content-filter-elements
            :label="objectType === 'Task' ? t('task.filter.name-search') : t('subtask.filter.name-search')"
            :disabled="tagResultsLoading"
            @items-loaded="itemsLoaded"
            :get-search-url="getElementsSearchUrl"
          />

          <v-tabs
            class="checklist-element-chooser-tabs"
            v-model="tab"
            fixed-tabs
          >
            <v-tab>{{ t('latest') }}</v-tab>
            <v-tab v-if="objectType === 'Task'">
              {{ t('tags') }}
            </v-tab>
            <v-tab>{{ t('search-results') }}</v-tab>
          </v-tabs>
          <v-window v-model="tab">

            <v-window-item>
              <v-container class="px-0">
                <div v-if="list.length > 0">
                  <div
                      v-for="(item, index) in list"
                      class="drag-element passive"
                      :class="{'expandable' : objectType === 'Task'}"
                      :key="index"
                  >
                    <div>
                      <v-icon 
                        v-if="objectType === 'Task'" 
                        class="drag-action expand"
                        color="black"
                        @click.native="expandElement(item)"
                      >
                        mdi-playlist-edit
                      </v-icon>
                      <span class="ellipsis">{{ item.name }}</span>
                      <v-icon class="drag-action" color="primary" @click.native="addElement(item)">mdi-plus-circle</v-icon>
                    </div>
                    <div v-if="objectType === 'Task' && item.expanded" class="expand-wrapper">
                      <div class="task-rename">
                        <div v-if="item.description !== ''" class="task-description">{{ item.description }}</div>
                        <v-text-field
                          :label="t('task.label.rename')"
                          v-model="item.rename"
                          counter="40"
                          filled
                          :rules="[
                            () => !!item.rename || t('task.validation.error-name-required'),
                            () => (item.rename && item.rename.length <= 40) || t('task.validation.error-max-name')
                          ]"
                        ></v-text-field>
                      </div>
                      <div
                        v-for="(subtask, index) in item.subtasks"
                        class="drag-element passive"
                        :key="index"
                      >
                        <v-checkbox
                          v-model="subtask.active"
                          :label="subtask.name"
                          hide-details
                        ></v-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <loading v-else :dataLoaded="false"></loading>
              </v-container>
            </v-window-item>

            <v-window-item v-if="objectType === 'Task'">

              <v-container class="px-0">
                <div v-if="tags.length > 0" class="chips-wrapper">
                  <v-chip
                    v-for="(tag, index) in tags" :key="index"
                    :ripple="false"
                    @click.native="searchTagResults(tag.id)"       
                  >
                  <span class="ellipsis">{{ tag.name }}</span>
                  </v-chip>
                </div>
                <loading v-else :dataLoaded="false"></loading>
              </v-container>  

            </v-window-item>

            <v-window-item>
              <v-container v-if="!tagResultsLoading" class="px-0">
                <div v-if="listSearch.length > 0">
                  <div
                      v-for="(item, index) in listSearch"
                      class="drag-element passive"
                      :class="{'expandable' : objectType === 'Task'}"
                      :key="index"
                  >
                    <div>
                      <v-icon 
                        v-if="objectType === 'Task'" 
                        class="drag-action expand"
                        color="black"
                        @click.native="expandElement(item)"
                      >
                        mdi-playlist-edit
                      </v-icon>
                      <span class="ellipsis">{{ item.name }}</span>
                      <v-icon class="drag-action" color="primary" @click.native="addElement(item)">mdi-plus-circle</v-icon>
                    </div>
                    <div v-if="objectType === 'Task' && item.expanded" class="expand-wrapper">
                      <div class="task-rename">
                        <div v-if="item.description !== ''" class="task-description">{{ item.description }}</div>
                        <v-text-field
                          :label="t('task.label.rename')"
                          v-model="item.rename"
                          counter="40"
                          filled
                          :rules="[
                            () => !!item.rename || t('task.validation.error-name-required'),
                            () => (item.rename && item.rename.length <= 40) || t('task.validation.error-max-name')
                          ]"
                        ></v-text-field>
                      </div>
                      <div
                        v-for="(subtask, index) in item.subtasks"
                        class="drag-element passive"
                        :key="index"
                      >
                        <v-checkbox
                          v-model="subtask.active"
                          :label="subtask.name"
                          hide-details
                        ></v-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center">
                  {{ t('no-search-results') }}
                </div>
              </v-container>
              <loading v-else :dataLoaded="false"></loading>
            </v-window-item>

          </v-window>

        </v-col>

        <v-col class="pa-0">
          <v-card elevation="0">
            <v-card-title>{{ t('selection') }}</v-card-title>
            <v-card-text>

                <div class="drag-area-wrapper">
                  <draggable
                    class="drag-area"
                    :list="subelements"
                    :class="{'drag-area-how-to' : subelements.length === 0}"
                    group="elements"
                  >
                    <div 
                      class="drag-element" 
                      :class="{'expandable' : objectType === 'Task'}"
                      v-for="(item, index) in subelements" 
                      :key="index"
                    >
                      <div>
                        <v-icon class="drag-handle">mdi-drag</v-icon>
                        <v-icon 
                          v-if="objectType === 'Task'" 
                          class="drag-action expand"
                          color="black"
                          @click.native="expandElement(item)"
                        >
                          mdi-playlist-edit
                        </v-icon>
                        <span class="ellipsis">{{ objectType === 'Task' ? item.rename : item.name }}</span>
                        <v-icon class="drag-action" color="primary" @click.native="removeElement(index)">mdi-minus-circle</v-icon>
                      </div>
                      <div v-if="objectType === 'Task' && item.expanded" class="expand-wrapper">
                        <div
                          v-for="(subtask, index) in item.subtasks"
                          class="drag-element passive"
                          :key="index"
                        >
                          <v-checkbox
                            v-model="subtask.active"
                            :label="subtask.name"
                            hide-details
                          ></v-checkbox>
                        </div>
                      </div>
                    </div>
                  </draggable>
                </div>

          </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-container>

  </div>
</template>

<script>
  import { HTTP } from '../../auth/index'
  import draggable from 'vuedraggable'
  import loading from '@/components/layouts/Loading.vue'
  import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
  import ContentFilterElements from '@/components/filter-components/ContentFilterElements'

  export default {
    components: {
      draggable,
      loading,
      AppFilterSearchField,
      ContentFilterElements
    },
    data () {
      return {
        list: [],
        listSearch: [],
        tags: [],
        loading: false,
        tagResultsLoading: false,
        error: false,
        cancel: null,
        tab: null,
      }
    },
    props: {
      objectType: null,
      subelements: [],
    },
    created() {
      if (this.objectType === 'Task') {
        this.getTasks(1)
        this.getTags()
      } else {
        this.getSubtasks(1)
      }
    },
    methods: {
      t: function (key) {
        return this.$t('checklists.chooser.' + key);
      },
      getTasks (page) {
        HTTP.get('mainelements?page=' + page + '&itemsPerPage=15&sortBy[]=latest_assignment&sortDesc[]=true').then(function (response) {
          this.list = response.data.data
          this.setAdditionalTaskProperties(this.list)
          this.elements.current_page = response.data.current_page
          this.elements.last_page = response.data.last_page
          this.error = false
          this.loading = false
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
            this.loading = false
          }
        }.bind(this))
      },
      getSubtasks (page) {
        this.loading = true
        HTTP.get('elements?page=' + page + '&itemsPerPage=15&sortBy[]=latest_assignment&sortDesc[]=true').then(function (response) {
          this.list = response.data.data
          this.elements.current_page = response.data.current_page
          this.elements.last_page = response.data.last_page
          this.dataTotal = response.data.total
          this.error = false
          this.loading = false
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
            this.loading = false
          }
        }.bind(this))
      },
      getTags () {
        HTTP.get('settings/checklist/tags').then(function (response) {
          this.tags = response.data
        }.bind(this)).catch(function (error) {
            if (!error.status) {
              this.error = true
            }
        }.bind(this))
      },
      searchTagResults (tagId) {
        this.tagResultsLoading = true
        this.tab = 2
        HTTP.get('mainelements?t=' + tagId).then(function (response) {
          this.listSearch = response.data.data
          this.setAdditionalTaskProperties(this.listSearch)
          this.tagResultsLoading = false
        }.bind(this)).catch(function (error) {
            if (!error.status) {
              this.error = true
            }
        }.bind(this))
      },
      removeElement (index) {
        this.subelements.splice(index, 1)
      },
      addElement (item) {
        if (item.rename === '') {
          this.$root.infoNotification.showMessage(this.$t('checklists.chooser.task.validate-rename'))
          return
        }
        let duplicates = this.subelements.filter(x => x.id === item.id)
        let itemCopy = JSON.parse(JSON.stringify(item))
        if (this.objectType === 'Task') {
          // check for active subtasks > 0
          if (!this.hasActiveSubtasks(itemCopy)) {
            this.$root.infoNotification.showMessage(this.$t('checklists.chooser.task.validate-subtasks', { taskName: item.rename}))
          } else {
            // check for unique display name/instance
            if (duplicates.length === 0 || this.hasUniqueRename(duplicates, itemCopy)) {
              itemCopy.expanded = false
              this.subelements.push(itemCopy)
            } else {
              this.$root.infoNotification.showMessage(this.$t('checklists.chooser.task.validate-duplicate', { taskName: item.rename}))
            }
          }
        } else {
          // check for unique subtask
          if (duplicates.length === 0) {
            this.subelements.push(itemCopy)
          } else {
            this.$root.infoNotification.showMessage(this.$t('checklists.chooser.subtask.validate-duplicate', { subtaskName: item.name}))
          }
        }
      },
      expandElement (item) {
        item.expanded = !item.expanded
      },
      hasActiveSubtasks (task) {
        let actives = task.subtasks.filter(x => x.active)
        return actives.length > 0
      },
      hasUniqueRename (duplicates, item) {
        let duplicateNames =  duplicates.filter(x => x.rename === item.rename)
        return duplicateNames.length === 0
      },
      getElementsSearchUrl(val) {
        return this.objectType === 'Task' ? 
          'mainelements?itemsPerPage=15&q=' + val : 
          'elements?itemsPerPage=15&q=' + val
      },
      setAdditionalTaskProperties (tasks) {
        tasks.forEach((task) => {
          this.$set(task, 'expanded', false)
          this.$set(task, 'rename', task.name)
          task.subtasks.forEach((subtask) => {
            this.$set(subtask, 'active', true)
          })
        })
      },
      itemsLoaded (items) {
        this.listSearch = items
        if (this.objectType === 'Task') {
          this.setAdditionalTaskProperties(this.listSearch)
          this.tab = 2
        } else {
          this.tab = 1
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .drag-area-how-to {
	  background-size: 90% auto;
  }
</style>
