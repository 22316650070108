<template>
    <div>
        <v-autocomplete
                v-model="selectedUser"
                :loading="loading"
                :items="items"
                :item-text="getItemText"
                :search-input.sync="search"
                cache-items
                filled
                hide-no-data
                :label="labelData"
                append-icon="mdi-magnify"
                return-object
                :disabled="readonly"
                clearable
                :rules="rules"
                :data-cy="dataCy"
        ></v-autocomplete>
    </div>
</template>

<script>
import {CancellingHttp} from "@/infrastructure/communication";

export default {
    name: "ContentFilterUser",
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
      dataCy: {
        default: ''
      },
        model: {
            type: Object,
            default: () => [null]
        },
        rules: {
            type: Array,
            default: () => []
        },
        readonly: Boolean,
        label: {
            type: String,
        }
    },
    data() {
        return {
            com: new CancellingHttp(),
            abortController: null,
            loading: false,
            items: [],
            search: null,
            selectedUser: this.model
        }
    },
    computed: {
        labelData() {
            return (this.label) ? this.label : this.$t('components.content-filter-object-leader.label');
        },
        rulesData() {
            return this.rules;
        }
    },
    watch: {
        selectedUser: function(val) {
            this.$emit('input', val);
        },
        search(val) {
            val && val !== this.select && this.querySelections(val)
        },
        model: function(val) {
            if (val !== null) {
                this.items.push(val)
                this.selectedUser = val;
            }
        }
    },
    mounted() {
        if (this.model !== null) {
            this.items.push(this.model)
            this.selectedUser = this.model;

        }
    },
    created() {
        this.com.on('loading', (val) => {
            this.loading = val
        })
    },
    methods: {
        t: function (key) {
            return this.$t(key);
        },
        getItemText(item) {

            if (item.display_name) {
                return item.display_name
            }

            if (typeof item.name === "undefined" ) {
            
            return "";
            }

            return `${item.name}, ${item.firstname}`
        },
        async querySelections(val) {
            let url = 'users?q=' + encodeURIComponent(val) + '&onlyActive=true'
            let response = await this.com.get(url)
            if (response) {
                this.items = response.sort((a, b) =>
                    a.display_name.localeCompare(b.display_name, undefined, {sensitivity: 'accent'})
                )
            }
        },
    }
}
</script>

<style scoped>

</style>
