<template>
  <md-layout class="text-center">
    <span v-if="status === 'new'" class="status-new">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-update</v-icon>
        </template>
        <span>{{ $t('status-icons.new') }}</span>
      </v-tooltip>
    </span>
    <span v-if="status === 'submitted'" class="status-submitted">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-update</v-icon>
        </template>
        <span>{{ $t('status-icons.submitted') }}</span>
      </v-tooltip>
    </span>
    <span v-if="status === 'completed'" class="status-completed">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-check-circle-outline</v-icon>
        </template>
        <span>{{ $t('status-icons.completed') }}</span>
      </v-tooltip>
    </span>
    <span v-if="status === 'approved'" class="status-approved">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-check-circle-outline</v-icon>
        </template>
        <span>{{ $t('status-icons.approved') }}</span>
      </v-tooltip>
    </span>
    <span v-if="status === 'invoiced'" class="status-invoiced">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-export-variant</v-icon>
        </template>
        <span>{{ $t('status-icons.invoiced') }}</span>
      </v-tooltip>
    </span>
    <span v-if="status === 'deleted'" class="status-deleted">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-close-circle-outline</v-icon>
        </template>
        <span>{{ $t('status-icons.deleted') }}</span>
      </v-tooltip>
    </span>
    <span v-if="status === 'filed'" class="status-filed">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-update</v-icon>
        </template>
        <span>{{ $t('status-icons.filed') }}</span>
      </v-tooltip>
    </span>
    <span v-if="status === 'rejected'" class="status-rejected">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-close-circle-outline</v-icon>
        </template>
        <span>{{ $t('status-icons.rejected') }}</span>
      </v-tooltip>
    </span>
    <span v-if="status === 'reimbursed'" class="status-invoiced">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-export-variant</v-icon>
        </template>
        <span>{{ $t('status-icons.reimbursed') }}</span>
      </v-tooltip>
    </span>
  </md-layout>
</template>

<script>
export default {
  name: "StatusIcons",
  props: {
    status: String
  }
}
</script>

<style scoped lang="scss">
span {
  width: 100%;
}

.status i,
.status-new i,
.status-submitted i,
.status-completed i,
.status-approved i,
.status-invoiced i,
.status-filed i,
.status-deleted i,
.status-rejected i, {
  font-size: 30px;
  position: relative;
}

.status-submitted i,
.status-filed i,
.status-new i {
  color: var(--v-warning-base);
}

.status-completed i,
.status-invoiced i,
.status-approved i {
  color: var(--v-success-base);
}

.status-rejected i,
.status-deleted i {
  color: var(--v-error-base);
}
</style>
