<template>
	<div class="app-date-picker">
		<v-menu
			v-model="menu"
			:close-on-content-click="false"
			:nudge-right="40"
			transition="scale-transition"
			offset-y
			min-width="auto"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
					:label="filterLabel"
					v-model="formattedDate"
					prepend-inner-icon="mdi-calendar"
					readonly
					:disabled="disabled"
					v-bind="attrs"
					v-on="on"
					@click="display = true"
					@click:prepend-inner="menu = true"
				></v-text-field>
			</template>
			<v-date-picker
				v-if="internalDate"
				v-model="internalDate"
				scrollable
				locale="de-de"
				:first-day-of-week="1"
				@input="applyDate"
			></v-date-picker>
      </v-menu>
	</div>
</template>
<script>
import { VDatePicker } from 'vuetify/lib'
import moment from 'moment'
import { EventBus } from './../../event-bus'

export default {
  name: "AppDatePicker",
  extends: VDatePicker,
  props: {
    filterLabel: String,
	date: {
      required: true
    },
	id: {
      required: true
    },
	disabled: {
		default: false,
		required: false
	},
	disabledText: {
		default: '',
		required: false
	}
  },
  data () {
    return {
	  internalDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
	  menu: false
	}
  },
  methods: {
    applyDate: function () {
      this.menu = false
	  let msg = {
		newDate: this.internalDate,
		id: this.id
	  }
	  EventBus.$emit('filter-date-changed', msg)
    },
  },
  computed: {
    /*computedProps() {
      return {
        ...this.$props,
      }
    },*/
	formattedDate() {
		if (this.disabled && this.disabledText != '') {
			return this.disabledText
		} else {
			return this.date ? moment(this.date).format('DD.MM.YYYY') : ''
		}
	}
  }
}
</script>
