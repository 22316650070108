<template>
  <v-select
      v-model="selectedCategories"
      :label="$t('components.tasks-categories-filter.label')"
      :items="categories"
      item-text="name"
      item-value="id"
      multiple
      chips
  ></v-select>
</template>

<script>
import { HTTP } from '@/auth'

export default {
  name: "TasksCategoriesFilter",
  props: ['value'],
  data() {
    return {
      selectedCategories: this.value,
      categories: [],
    }
  },
  watch: {
    selectedCategories: function (val) {
      this.$emit('input', val)
    }
  },
  async created() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      let response = await HTTP.get('tasks/task-categories')
      this.categories = response.data
    },
  },
}
</script>
