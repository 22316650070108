<template>
  <v-select
      class=""
      v-model="selectedFilterStatus"
      :label="$t('components.enrollment-status-filter.label')"
      :items="filterStatus"
      item-text="name"
      item-value="slug"
      multiple
      chips
  >
<!--
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index < maxDisplay">{{ item.name }} &nbsp;</v-chip>
      <span
          v-if="index === maxDisplay"
          class="grey&#45;&#45;text caption"
      >(+{{ selectedFilterStatus.length - maxDisplay }} selects)</span>
    </template>
-->

  </v-select>

</template>

<script>
export default {
  name: "EnrollmentStatusFilter",
  props: ['value'],
  data() {
    return {
      maxDisplay: 3,
      selectedFilterStatus: this.value,
    }
  },
  computed: {
    filterStatus() {
      return [
        {
          name: this.$t('enrollments.data.status.draft'),
          slug: 'draft'
        },
        {
          name: this.$t('enrollments.data.status.published'),
          slug: 'published'
        },
        {
          name: this.$t('enrollments.data.status.running'),
          slug: 'running'
        },
        {
          name: this.$t('enrollments.data.status.finished'),
          slug: 'finished'
        }
      ];
    },
  },
  watch: {
    selectedFilterStatus: function (val) {
      this.$emit('input', val);
    }
  },
  created() {
    let me = this;
    this.filterStatus.forEach(function (value) {
      value.name = me.$t('enrollments.data.status.' + value.slug);
    });
  }
}
</script>

<style scoped>

</style>
