<template>
  <div class="dashboard-card">

    <v-card
        elevation="0"
        v-if="hasPermission"
    >
      <v-card-title>
        <v-icon>mdi-account-group</v-icon>
        {{ $t('dashboard.user-per-day.title') }}
      </v-card-title>
      <v-card-text>

        <div v-if="os && !isLoading" class="sp-flex sp-flex-dir-col analytics-container">
          <div class="text-center">
            <v-sparkline
                :labels="totalUserPerDayCount"
                :value="totalUserPerDayCount"
                color="primary"
                line-width="2"
                padding="16"
            ></v-sparkline>

          </div>

        </div>
        <div v-else class="analytics-loading-wrapper">
          <span v-if="isLoading" class="analytics-loading"></span>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import store from "store";
import {HTTP} from './../../auth'

export default {
  name: 'DashboardUsersPerDay',
  data() {
    return {
      os: null,
      user: null,
      isLoading: true,
      userStatistics: null,
    }
  },
  async mounted() {
    this.user = store.get('user')
    if (['admin'].includes(this.user.role)) {
      await this.loadData()
    }
  },
  methods: {
    async loadData() {
      let response = await HTTP.get('user_statistics')
      this.os = response.data.os
      this.totalUserPerDayCount = response.data.totalUserPerDayCount
      this.isLoading = false
    }
  },
  computed: {
    hasPermission() {
      if (!this.user) {
        return false
      }
      return ['admin'].includes(this.user.role)
    }
    /*,
    registeredOSTotal() {
      var count = 0;
      for (var i = 0; i < this.os.length; i++) {
        count += this.os[i].cnt;
      }

      return count
    },
    registeredIosDevices() {
      var count = 0;
      for (var i = 0; i < this.os.length; i++) {
        if (this.os[i].os == "iOS") {
          count = this.os[i].cnt
        }
      }

      return count
    },
    registeredAndroidDevices() {
      return this.registeredOSTotal - this.registeredIosDevices
    },
    registeredPercentage() {
      return this.registeredIosDevices / this.registeredOSTotal * 100

    },

     */
  }
}
</script>

<style scoped>
.list-content {
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
}
</style>
