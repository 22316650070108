<template>
  <div>

    <toolbar :dataLoaded="dataLoaded"> {{ t('login-data') }}</toolbar>

    <v-container fluid class="edit-screen">

      <loading :error="errorLoading" :dataLoaded="dataLoaded"></loading>

      <v-row v-if="dataLoaded">

        <v-col class="content-area" cols="12" sm="12" md="8">

          <v-card
              elevation="0"
          >
            <v-card-title>{{ t('login-details') }}</v-card-title>
            <v-card-text>

              <v-text-field
                  :label="t('text-field.title')"
                  v-model="enrollmentData.title"
                  filled
                  :disabled="editDisabled"
              ></v-text-field>

              <v-textarea
                  :label="t('text-field.description')"
                  v-model="enrollmentData.description"
                  filled
                  :disabled="editDisabled"
              ></v-textarea>

              <div class="custom-label">{{ t('time-range') }}</div>

              <div class="survey-start">
                <app-date-time-picker
                    :label="t('text-field.startdate') + '*'"
                    :datetimeinput="enrollmentData.start_date"
                    date-format="dd.MM.yyyy"
                    :id="'startDate'"
                    :clearable="false"
                    :disabled="editDisabled"
                ></app-date-time-picker>
              </div>

              <div class="survey-end">
                <app-date-time-picker
                    :label="t('text-field.enddate') + '*'"
                    :datetimeinput="enrollmentData.end_date"
                    date-format="dd.MM.yyyy"
                    :id="'endDate'"
                    :clearable="false"
                    :disabled="editDisabled"
                ></app-date-time-picker>
              </div>

            </v-card-text>
          </v-card>

        </v-col>

        <v-col class="meta-area" cols="12" sm="12" md="4">

          <v-card
              elevation="0"
          >
            <v-card-title>{{ t('permission-groups') }}*</v-card-title>
            <v-card-text>

              <v-select
                  title="Berechtigungsgruppe auswählen"
                  :label="t('text-field.permission-groups-select')"
                  v-model="enrollmentData.assigned_permission_groups"
                  :items="permissionGroups"
                  multiple
                  item-text="name"
                  item-value="id"
                  :disabled="editDisabled"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.name }} ({{ data.item.user_count }})
                </template>
              </v-select>

            </v-card-text>
          </v-card>

          <v-card
              elevation="0"
              class="sp-mar-top-3"
          >
            <v-card-title>{{ t('start-surveys-push') }}</v-card-title>
            <v-card-text>

              <v-switch
                  v-model="enrollmentData.push_on_start"
                  inset
                  :label="enrollmentData.push_on_start ? t('yes'): t('no')"
                  :disabled="editDisabled || isFinished"
              ></v-switch>

            </v-card-text>
          </v-card>

          <v-card
              elevation="0"
              class="sp-mar-top-3"
          >
            <v-card-title>{{ t('analytics') }}</v-card-title>
            <v-card-text>

              <div class="sp-flex-1 edit-list" :class="{ 'is-loading': !analyticsLoaded }">
                <div class="sp-pad-2" v-if="analyticsLoaded">
                  Teilnehmer: {{ analytics.read_total + ' von ' + analytics.total }}
                </div>
                <div class="sp-flex sp-flex-dir-col" v-if="analyticsError">
                  <span>{{ t('analytics-failed') }}</span>
                </div>
              </div>

            </v-card-text>
          </v-card>

        </v-col>

      </v-row>

      <v-row v-if="dataLoaded">

        <v-col v-if="resultDataLoaded" class="content-area" cols="12" sm="12" md="8">

          <v-card
              elevation="0"
              class="sp-mar-top-3"
          >
            <v-card-title>
              {{ t('results') }}
              <div v-if="!enrollmentData.anonymized" class="survey-print">
                <md-button @click.native="exportEnrollmentResults()" :title="'Excelexport ' + enrollmentData.title"
                           class="sp-text-blue sp-mar-r-0">
                  <md-icon class="sp-text-blue">file_download</md-icon>
                </md-button>
              </div>
            </v-card-title>
            <v-card-text>

              <div>

                <div class="question-element" v-for="(question, index) in questions" :key="index">
                  <div
                      v-if="[4].includes(question.type) || (resultsData[question.id].datasets && resultsData[question.id].datasets[0].data_users && resultsData[question.id].datasets[0].data_users.filter(users => users.length).length)"
                      class="question-print">
                    <md-button @click.native="exportQuestionResults(question)" :title="'Excelexport ' + question.title"
                               class="sp-text-blue sp-mar-r-0">
                      <md-icon class="sp-text-blue">file_download</md-icon>
                    </md-button>
                  </div>
                  <div class="custom-label">{{ question.title }}</div>
                  <div v-if="question.description !== ''" class="question-description">{{ question.description }}</div>

                  <!-- Bewertung -->
                  <div v-if="[1].includes(question.type)">
                    <div class="rating-chart-wrapper">
                      <rating-chart :data="resultsData[question.id]" :options="chartOptionsRating"></rating-chart>
                    </div>
                    <div class="average-wrapper">
                      <div class="vertical-align">
                        {{ t('average-value') }}
                        {{ getAverageRating(resultsData[question.id].datasets[0].data) }}
                      </div>
                    </div>
                    <div v-if="resultsData[question.id].datasets[0].noRatingsCount == 1" class="question-unanswered">
                      <md-icon>info</md-icon>
                      {{ resultsData[question.id].datasets[0].noRatingsCount }} {{ t('abstentions') }}
                    </div>
                    <div v-if="resultsData[question.id].datasets[0].noRatingsCount > 1" class="question-unanswered">
                      <md-icon>info</md-icon>
                      {{ resultsData[question.id].datasets[0].noRatingsCount }} {{ t('abstentions') }}
                    </div>

                    <!-- Teilnehmer Details Dropdown List -->
                    <div v-if="resultsData[question.id].datasets[0].data_users" class="question-user-details">
                      <users-dropdown-list v-for="(users, index) in resultsData[question.id].datasets[0].data_users"
                                           v-if="users.length > 0"
                                           :key="index" class="users-dropdown"
                                           :index="index"
                                           :title="question.title"
                                           :users="users"></users-dropdown-list>
                    </div>
                  </div>

                  <!-- Einzelauswahl & Mehrfachauswahl -->
                  <div v-if="[2, 3, 5].includes(question.type)">
                    <div class="pie-chart-wrapper">
                      <pie-chart :data="resultsData[question.id]" :options="chartOptionsPie"></pie-chart>
                    </div>
                    <div class="options-wrapper">
                      <div class="vertical-align">
                        <div v-for="(option, index) in question.options" :key="index" class="option-wrapper">
                          <span class="option" :class="'option-' + index"></span>
                          {{ option }}
                        </div>
                      </div>
                    </div>
                    <div v-if="resultsData[question.id].datasets[0].noSinglesCount == 1" class="question-unanswered">
                      <md-icon>info</md-icon>
                      {{ resultsData[question.id].datasets[0].noSinglesCount }} {{ t('abstentions') }}
                    </div>
                    <div v-if="resultsData[question.id].datasets[0].noMultiplesCount == 1" class="question-unanswered">
                      <md-icon>info</md-icon>
                      {{ resultsData[question.id].datasets[0].noMultiplesCount }} {{ t('abstentions') }}
                    </div>
                    <div v-if="resultsData[question.id].datasets[0].noSinglesCount > 1" class="question-unanswered">
                      <md-icon>info</md-icon>
                      {{ resultsData[question.id].datasets[0].noSinglesCount }} {{ t('abstentions') }}
                    </div>
                    <div v-if="resultsData[question.id].datasets[0].noMultiplesCount > 1" class="question-unanswered">
                      <md-icon>info</md-icon>
                      {{ resultsData[question.id].datasets[0].noMultiplesCount }} {{ t('abstentions') }}
                    </div>

                    <!-- Teilnehmer Details Dropdown List -->
                    <div v-if="resultsData[question.id].datasets[0].data_users" class="question-user-details">
                      <users-dropdown-list v-for="(users, index) in resultsData[question.id].datasets[0].data_users"
                                           v-if="users.length > 0"
                                           :key="index" class="users-dropdown"
                                           :index="index"
                                           :title="question.options[index]"
                                           :users="users"></users-dropdown-list>
                    </div>
                  </div>

                  <!-- Feedback -->
                  <div v-if="[4].includes(question.type)" class="feedback-wrapper clearfix">
                    <div v-masonry transition-duration="0.3s" item-selector=".feedback-item">
                      <div v-masonry-tile v-for="(feedback, index) in resultsData[question.id]" :key="index"
                           class="feedback-item">
                        <span v-if="feedback.user !== ''">{{ feedback.user }}</span>
                        {{ feedback.message }}
                      </div>
                    </div>
                  </div>

                  <div class="sp-clearfix"></div>
                </div>

              </div>

            </v-card-text>
          </v-card>

        </v-col>

        <v-col class="meta-area" cols="12" sm="12" md="4"></v-col>

      </v-row>

    </v-container>

    <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        timeout="4000"
    >
      {{ errorMessage }}
    </v-snackbar>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
                color="primary"
                text
                class="icon-left"
                @click="cancel(false)"
            >
              <v-icon>mdi-close</v-icon>
              {{ t('cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </div>
    </transition>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import {EventBus} from './../event-bus'
import loading from '../components/layouts/Loading.vue'
import toolbar from '../components/layouts/Navigation.vue'
import AppDateTimePicker from '../components/vuetify/AppDateTimePicker'
import moment from 'moment'
import PieChart from '../components/charts/PieChart.js'
import RatingChart from '../components/charts/RatingChart.js'
import {VueMasonryPlugin} from 'vue-masonry';
import Vue from 'vue'

Vue.use(VueMasonryPlugin)

// users list per each choice/option
Vue.component('users-dropdown-list', {
  props: {
    users: null,
    index: null,
    title: ''
  },
  data() {
    return {
      isActive: false
    }
  },
  template: `
    <div>
    <div @click="handleToggle" class="dropdown-toggler">
		    <span class="option">
		      <md-icon :class="'option-' + index">supervisor_account</md-icon> Teilnehmer anzeigen ({{ users.length }})
		    </span>
    </div>
    <div :class="{dropdown: isActive}" v-if="isActive">
      <div class="analytics-list">
        <div v-for="user in users" :key="user.id" class="analytics-item one-line">
          <span v-if="user.cost_centre != ''">{{ user.name }} {{ user.firstname }}, {{ user.cost_centre }}</span>
          <span v-else>{{ user.name }} {{ user.firstname }}</span>
        </div>
      </div>
    </div>
    </div>
  `,
  mounted() {

  },
  methods: {
    handleToggle() {
      this.isActive = !this.isActive;
    }
  }
})

export default {
  name: 'EnrollmentsReport',
  components: {
    loading,
    toolbar,
    AppDateTimePicker,
    PieChart,
    RatingChart,
    VueMasonryPlugin
  },
  data() {
    return {
      editDisabled: true,
      permissionGroups: [],
      data: {},
      dataLoaded: false,
      resultDataLoaded: false,
      errorLoading: false,
      error: null,
      errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.',
      cancelHTTP: null,
      errorCancel: null,
      enrollmentData: {},
      questions: [],
      questionTypes: [
        {id: 1, name: 'Bewertung'},
        {id: 2, name: 'Einzelauswahl'},
        {id: 3, name: 'Mehrfachauswahl'},
        {id: 4, name: 'Feedback'},
        {id: 5, name: 'Abbruchfrage'}
      ],
      isEditing: true,
      config: {
        headers: {'content-type': 'application/json'}
      },
      analytics: {},
      analyticsLoaded: false,
      analyticsError: false,
      resultsData: null,
      chartOptionsPie: {
        hoverBorderWidth: 20,
        legend: {
          display: false
        }
      },
      chartOptionsRating: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            },
            barPercentage: 1.0
          }]
        }
      },
      isDownloadingExcel: false,
      snackbar: false
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY');
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm');
    }
  },
  created() {
    this.itemId = this.$route.params.id
    this.getPermissionGroups()
  },
  methods: {
    t: function (key) {
      return this.$t('enrollments.report.' + key);
    },
    moment: function () {
      return moment();
    },
    getPermissionGroups() {
      HTTP.get('permission-groups?sortBy[]=name').then(function (response) {
        this.permissionGroups = response.data.data
        this.getEnrollment()
      }.bind(this)).catch(function (error) {
        this.error = error
        this.openSnackbar()
      }.bind(this))
    },
    getEnrollment() {
      HTTP.get('enrollments/' + this.itemId).then(function (response) {
        this.enrollmentData = response.data
        this.questions = this.enrollmentData.questions
        this.questions.forEach((element) => {
          element.options = JSON.parse(element.options)
        })
        this.enrollmentData.anonymized = !!this.enrollmentData.anonymized
        this.enrollmentData.push_on_start = !!this.enrollmentData.push_on_start

        const tempPermGroupsIds = []
        this.enrollmentData.assigned_permission_groups.forEach((element) => {
          tempPermGroupsIds.push(element.permission_group_id)
        })
        this.enrollmentData.assigned_permission_groups = tempPermGroupsIds

        this.getResultsData(this.enrollmentData.id)
        this.getAnalyticsData(this.enrollmentData.id)

        this.dataLoaded = true

      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    getAverageRating(data) {
      let sum = 0
      data.forEach((element, index) => {
        sum += element * (index + 1)
      })
      let votes = data.reduce((a, b) => a + b, 0)
      return (sum / votes).toFixed(1)
    },
    getResultsData(itemId) {
      HTTP.get('enrollments/results/' + itemId)
          .then(function (response) {
            this.resultsData = response.data
            this.resultDataLoaded = true
          }.bind(this))
          .catch(function (error) {
            //
          }.bind(this))
    },
    getAnalyticsData(itemId) {
      HTTP.get('analytics/enrollments/' + itemId)
          .then(function (response) {
            this.analytics = response.data
            this.analyticsLoaded = true
          }.bind(this))
          .catch(function (error) {
            //
          }.bind(this))
    },
    exportQuestionResults(question) {
      if (this.isDownloadingExcel) {
        return
      }
      this.isDownloadingExcel = true

      const config = {
        headers: {'content-type': 'application/json'},
        responseType: 'arraybuffer'
      }

      var resultsData = {}
      resultsData.enrollmentTitle = this.enrollmentData.title
      resultsData.questionTitle = question.title
      if ([1, 2, 3, 5].includes(question.type)) {
        resultsData.labels = this.resultsData[question.id].labels
        resultsData.data = this.resultsData[question.id].datasets[0].data
        resultsData.users = this.resultsData[question.id].datasets[0].data_users
      } else {
        resultsData.labels = null
        resultsData.data = this.resultsData[question.id]
        resultsData.users = null
      }

      HTTP.post('dataexport/excel/enrollments/questions/' + question.id, resultsData, config)
          .then(function (response) {
            let headers = response.headers
            let blob = new Blob([response.data], {type: headers['content-type']})
            let link = document.createElement('a')
            link.style = 'display: none'
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Anmeldung_' + this.enrollmentData.title + '_' + question.title + '_' + moment().format('DDMMYYYY-HHmm') + '.xlsx'
            document.body.appendChild(link)
            link.click()
            setTimeout(function () {
              document.body.removeChild(link)
              window.URL.revokeObjectURL(blob)
            }, 100)
            this.isDownloadingExcel = false
          }.bind(this))
          .catch(function (error) {
            this.errorMessage = error
            this.error = true
            this.openSnackbar()
            this.isDownloadingExcel = false
          }.bind(this))
    },
    exportEnrollmentResults() {
      if (this.isDownloadingExcel) {
        return
      }
      this.isDownloadingExcel = true

      const config = {
        headers: {'content-type': 'application/json'},
        responseType: 'arraybuffer'
      }

      var resultsData = {}
      resultsData.enrollmentTitle = this.enrollmentData.title
      resultsData.labels = {}
      resultsData.data = {}
      resultsData.users = {}
      Object.keys(this.resultsData).forEach(id => {
        resultsData.labels[id] = this.resultsData[id].labels ? this.resultsData[id].labels : null
        resultsData.data[id] = this.resultsData[id].datasets ? this.resultsData[id].datasets[0].data : this.resultsData[id]
        resultsData.users[id] = this.resultsData[id].datasets ? this.resultsData[id].datasets[0].data_users : null
      })
      resultsData.keys = []
      resultsData.questions = []
      this.questions.forEach((element) => {
        resultsData.keys.push(element.id)
        resultsData.questions.push(element.title)
      })

      HTTP.post('dataexport/excel/enrollments/' + this.enrollmentData.id, resultsData, config)
          .then(function (response) {
            let headers = response.headers
            let blob = new Blob([response.data], {type: headers['content-type']})
            let link = document.createElement('a')
            link.style = 'display: none'
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Anmeldung_' + this.enrollmentData.title + '_' + moment().format('DDMMYYYY-HHmm') + '.xlsx'
            document.body.appendChild(link)
            link.click()
            setTimeout(function () {
              document.body.removeChild(link)
              window.URL.revokeObjectURL(blob)
            }, 100)
            this.isDownloadingExcel = false
          }.bind(this))
          .catch(function (error) {
            this.errorMessage = error
            this.error = true
            this.openSnackbar()
            this.isDownloadingExcel = false
          }.bind(this))
    },
    cancel(reload) {
      this.$router.push({path: '/enrollments/results'})
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>

.edit-screen .v-card .v-card__title i {
  margin: 0;
}

.custom-label {
  clear: both;
  padding: 16px 0;
  font-size: 16px;
}

.custom-label-inline {
  width: 50px;
  float: left;
  line-height: 52px;
  font-size: 16px;
}

.survey-start {
  float: left;
  width: calc(50% - 16px);

  .md-error {
    display: block;
  }
}

.survey-end {
  float: right;
  width: calc(50% - 16px);

  .md-error {
    display: block;
  }
}

.show-analytics-users {
  display: inline-block;
  margin: 10px 0;
  cursor: pointer;
}

.show-analytics-users i {
  color: rgba(0, 0, 0, 0.54);
}

.analytics-user {
  padding: 10px;
}

.analytics-user:nth-child(odd) {
  background: #f7f7f7;
}

.question-element {
  position: relative;
}

.question-element .sp-clearfix {
  margin: 16px 0;
  height: 1px;
  background: rgba(227, 227, 227, 0.7);
}

.question-element:last-child .sp-clearfix {
  display: none;
}

.question-print {
  position: absolute;
  top: -12px;
  right: -8px;
}

.survey-print {
  float: right;
}

.question-print .md-button,
.survey-print .md-button {
  min-width: unset;
}

.survey-print .md-button {
  margin: 0;
}

.question-description {
  margin-top: 8px;
  max-width: 800px;
  font-size: 13px;
  line-height: 1.3;
  color: #979797;
}

.pie-chart-wrapper,
.rating-chart-wrapper {
  display: inline-block;
  width: 300px;
  margin-top: 16px;
}

.options-wrapper,
.average-wrapper {
  width: calc(100% - 320px);
  height: 300px;
  display: inline-block;
  margin-top: 16px;
  margin-left: 16px;
  vertical-align: top;
}

.vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.option-wrapper {
  line-height: 22px;
  margin: 6px 0;
}

.option {
  display: inline-block;
  width: 42px;
  height: 21px;
  vertical-align: bottom;
  margin: 2px 2px 2px 0;
}

.feedback-wrapper {
  width: calc(100% + 16px);
  margin-top: 8px;
  margin-left: -8px;
}

.feedback-item {
  margin: 8px;
  width: calc(50% - 16px);
  padding: 16px;
  float: left;
  border: 1px solid rgba(227, 227, 227, 0.7);
  color: #979797;
  font-size: 13px;
  line-height: 1.3;
  background: #FDFDFD;
}

.feedback-item span {
  display: block;
  margin-bottom: 8px;
  color: #000;
}

.question-unanswered {
  text-align: right;
}

.question-unanswered .md-icon {
  position: relative;
  top: -2px;
  margin-right: 4px;
  color: #e89e17;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

@media screen and (min-width: 900px) {

  .feedback-item {
    width: calc(33.333% - 16px);
  }

}

@media screen and (min-width: 1350px) {

  .feedback-item {
    width: calc(25% - 16px);
  }

}

@media screen and (min-width: 1600px) {

  .feedback-item {
    width: calc(20% - 16px);
  }

}

@media screen and (min-width: 2000px) {

  .feedback-item {
    width: calc(16.666% - 16px);
  }

}

.show-analytics-users {
  display: inline-block;
  margin: 10px 0;
  cursor: pointer;
}

.show-analytics-users i {
  color: rgba(0, 0, 0, 0.54);
}

.analytics-user {
  padding: 10px;
}

.analytics-user:nth-child(odd) {
  background: #f7f7f7;
}
</style>

<style lang="scss">

$option_0: #9ae49a;
$option_1: #36c936;
$option_2: #1b651b;
$option_3: #207920;
$option_4: #72da72;
$option_5: #258d25;
$option_6: #5ed45e;
$option_7: #2ba12b;
$option_8: #4acf4a;
$option_9: #30b530;
$option_10: #36c936;

.option-0 {
  background-color: $option_0;

  &.md-icon {
    background-color: transparent;
    color: $option_0;
  }
}

.option-1 {
  background-color: $option_1;

  &.md-icon {
    background-color: transparent;
    color: $option_1;
  }
}

.option-2 {
  background-color: $option_2;

  &.md-icon {
    background-color: transparent;
    color: $option_2;
  }
}

.option-3 {
  background-color: $option_3;

  &.md-icon {
    background-color: transparent;
    color: $option_3;
  }
}

.option-4 {
  background-color: $option_4;

  &.md-icon {
    background-color: transparent;
    color: $option_4;
  }
}

.option-5 {
  background-color: $option_5;

  &.md-icon {
    background-color: transparent;
    color: $option_5;
  }
}

.option-6 {
  background-color: $option_6;

  &.md-icon {
    background-color: transparent;
    color: $option_6;
  }
}

.option-7 {
  background-color: $option_7;

  &.md-icon {
    background-color: transparent;
    color: $option_7;
  }
}

.option-8 {
  background-color: $option_8;

  &.md-icon {
    background-color: transparent;
    color: $option_8;
  }
}

.option-9 {
  background-color: $option_9;

  &.md-icon {
    background-color: transparent;
    color: $option_9;
  }
}

.option-10 {
  background-color: $option_10;

  &.md-icon {
    background-color: transparent;
    color: $option_10;
  }
}

// users dropdown list
.question-user-details {
  margin-top: 20px;
  max-width: 500px;

  .users-dropdown {
    border-top: 1px solid #f7f7f7;

    &:last-child {
      border-bottom: 1px solid #f7f7f7;
    }

    .dropdown-toggler {
      cursor: pointer;
      padding: 10px;
    }

    .analytics-item {
      padding: 10px;
    }

    .analytics-item:nth-child(odd) {
      background: #f7f7f7;
    }
  }
}

</style>
