<template>
  <md-list-item>
    <v-icon>{{ item.icon }}</v-icon>
    <span class="module-title">{{ item.title }}</span>
    <md-list-expand>
        <md-list>
            <md-list-item class="md-inset">
                <router-link :to="{ path: '/shop-orders' }">
                <span>{{ $t('navigation.shop-orders') }}</span>
                </router-link>
            </md-list-item>
            <md-list-item class="md-inset">
                <router-link :to="{ path: '/shop-inventory' }">
                <span>{{ $t('navigation.shop-inventory') }}</span>
                </router-link>
            </md-list-item>
            <md-list-item class="md-inset">
                <router-link :to="{ path: '/shop-products' }">
                <span>{{ $t('navigation.shop-products') }}</span>
                </router-link>
            </md-list-item>
            <md-list-item class="md-inset">
                <router-link :to="{ path: '/settings/shop-categories' }">
                <span>{{ $t('navigation.shop-categories') }}</span>
                </router-link>
            </md-list-item>
            <md-list-item class="md-inset">
                <router-link :to="{ path: '/settings/shop' }">
                <span>{{ $t('navigation.settings-shop') }}</span>
                </router-link>
            </md-list-item>
        </md-list>
    </md-list-expand>
    </md-list-item>
</template>

<script>
export default {
    name: 'ShopListItem',
    props: {
        item: {
            type: Object,
            required: false,
            default: {}
        },
        user: {
            type: Object,
            required: false,
            default: {}
        },
        modules: {
            type: Array,
            required: false,
            default: []
        }
    }
    
}
</script>