<template>
    <div class="content-filter-elements">
        <v-autocomplete
            ref="autocomplete"
            v-model="select"
            :loading="searching"
            :items="items"
            :item-text="getItemText"
            :search-input.sync="search"
            filled
            hide-details
            hide-no-data
            :label="label"
            append-icon="mdi-magnify"
            return-object
            clearable
            :spellcheck="false"
            @change="itemChange"
        ></v-autocomplete>
    </div>
</template>

<script>
import { CancellingHttp } from "@/infrastructure/communication";

export default {
    name: "ContentFilterChecklistUser",
    components: {},
    emits: ['items-loaded'],
    props: {
        label: {
            type: String,
        }
    },
    data() {
        return {
            com: new CancellingHttp(),
            abortController: null,
            searching: false,
            items: [],
            search: null,
            select: null
        }
    },
    watch: {
        select(val) {
            if (this.select) {
                this.$emit('item-selected', this.select.id)
            }
        },
        search(val) {
            if (!this.select) {
                if (this.select && this.select.hasOwnProperty('display_name')) {
                    val && val !== this.select.display_name && this.querySelections(val)
                } else {
                    val && val !== this.select && this.querySelections(val)
                }
            }
        },
    },
    methods: {
        getItemText(item) {
            if (item.display_name) {
                return item.display_name
            }
            return `${item.name}, ${item.firstname}`
        },
        itemChange(e) {
            this.$nextTick(() => {
                this.$refs.autocomplete.reset()
                this.items.splice(0)
            });
        },
        async querySelections(val) {
            if (val !== '') {
                let url = 'users?q=' + encodeURIComponent(val) + '&onlyActive=true'
                this.searching = true
                let response = await this.com.get(url)
                if (response) {
                    this.items = response.sort((a, b) =>
                        a.display_name.localeCompare(b.display_name, undefined, {sensitivity: 'accent'})
                    )
                    if (this.items.length > 0) {
                        this.$emit('items-loaded', this.items)
                        this.searching = false
                    }
                }
            }
        }
    }
}
</script>

<style scoped lang="scss"></style>
