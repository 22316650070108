import {HTTP} from "@/auth";

const EventEmitter = require('events');

export class CancellingHttp extends EventEmitter {
    async get(url) {
        if (this.abortController) {
            this.abortController.abort()
        }

        this.emit('loading', true);
        this.abortController = new AbortController()
        try {
            let response = await HTTP.get(url, {
                signal: this.abortController.signal
            });
            return response.data
        } catch (err) {
            if (err.name !== "CanceledError") {
                throw err
            }
        } finally {
            this.emit('loading', false);
        }
    }
}
