<template>
  <div>
    <md-toolbar class="sp-main-toolbar">
      <!-- LEFT -->
      <!-- Hamburger button -->
      <md-button class="md-icon-button" @click.native="menuClicked" v-if="showMenu">
        <md-icon>menu</md-icon>
      </md-button>

      <!-- back button -->
      <span v-if="back && dataLoaded">
        <span v-if="path">
         <router-link tag="button" class="md-button md-icon-button" :to="{ path: path }"><md-icon>arrow_back</md-icon></router-link>
        </span>
        <span v-if="event">
          <md-button class="md-icon-button" @click.native="backClicked">
            <md-icon>arrow_back</md-icon>
          </md-button>
        </span>
      </span>

      <span v-if="cancelLeft && dataLoaded">
        <span v-if="path">
         <router-link tag="button" class="md-button md-icon-button" :to="{ path: path }"><md-icon>close</md-icon></router-link>
        </span>
        <span v-if="event">
           <md-button class="md-icon-button" @click.native="cancelLeftClicked()"><md-icon>close</md-icon></md-button>
        </span>
      </span>

      <!-- CENTER -->
      <h2 v-if="dataLoaded" class="md-title" style="flex: 1">
        <slot></slot>
      </h2>

      <!-- RIGHT -->
      <!-- cancel button -->

      <md-button v-if="cancel && dataLoaded" class="md-icon-button" @click.native="cancelClicked">
        <md-icon>close</md-icon>
      </md-button>

      <!-- edit button -->
      <md-button class="md-icon-button" @click.native="searchClicked" v-if="search">
        <md-icon>search</md-icon>
      </md-button>

      <!-- ok button -->
      <md-button class="md-icon-button" @click.native="okClicked" v-if="ok">
        <md-icon>check</md-icon>
      </md-button>

    </md-toolbar>

    <!-- sidenav -->
    <side-navigation ref="sidenav"></side-navigation>

  </div>
</template>

<script>
import { EventBus } from './../../event-bus';
import router from './../../router';
import store from 'store';
import { usePermissionStore } from "@/stores/PermissionStore";
import { hasPermission } from "@/services/PermissionService"
import { hasModule } from "@/services/LicenseService";
import SideNavigation from "@/components/menu/SideNavigation";

let BASE_URL = process.env.VUE_APP_BASE_URL ?? ''
let BUILDID = process.env.VUE_APP_BUILDID ?? 'local'
let GITCOMMIT = process.env.VUE_APP_GITCOMMIT ?? process.env.VUE_APP_GITINFO

export default {
  name: 'toolbar',
  components: {
    SideNavigation
  },
  setup() {
    const permissionStore = usePermissionStore()
    return { permissionStore }
  },
  data() {
    return {
      user: null,
      customIconAuswahlfelder: './../../assets/images/icn-auswahlfelder.svg',
      modules: [],
      buildId: BUILDID,
      gitId: GITCOMMIT ? GITCOMMIT.slice(0, 8) : 'n/a',
      VUE_APP_APP_CHAT: process.env.VUE_APP_APP_CHAT,
      activeModules: null
    }
  },
  props: {
    dataLoaded: {
      type: Boolean,
      required: false,
      default: false
    },
    menu: {
      type: Boolean,
      required: false,
      default: true
    },
    back: {
      type: Boolean,
      required: false,
      default: false
    },
    path: {
      type: String,
      required: false,
      default: ''
    },
    event: {
      required: false,
      default: false
    },
    cancel: {
      required: false,
      default: false
    },
    cancelLeft: {
      required: false,
      default: false
    },
    ok: {
      type: Boolean,
      required: false,
      default: false
    },
    edit: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    showMenu() {
      return (!this.back && !this.cancel && !this.cancelLeft)
    }
  },
  methods: {
    getUser() {
      this.user = store.get('user')

      if (this.user.role == "employee") {
        document.body.textContent = this.$t('no_permissions_cuberion');
        document.body.style.marginTop = "10px";
        document.body.style.marginLeft = "10px";
      }
    },
    menuClicked() {
      if (this.$refs.sidenav) {
        this.$refs.sidenav.toggle()
      }
    },
    cancelClicked() {
      if (event) {
        EventBus.$emit('cancel')
      } else {
        router.push(this.cancel)
      }
    },
    cancelLeftClicked() {
      EventBus.$emit('cancel')
    },
    okClicked() {
      EventBus.$emit('ok')
    },
    editClicked() {
      EventBus.$emit('edit')
    },
    backClicked() {
      EventBus.$emit('back', this.event)
    },
    searchClicked() {
      EventBus.$emit('search')
    },
    isAdmin() {
      return this.user && this.user.role === "admin"
    },
    isPermission(permissionString) {
      // Check if the permissionString is null or empty
      if (permissionString === null || permissionString.trim() === '') {
        return true; // return true if permissions not defined
      }

      // Split the permissionString into an array of permissions
      let permissions = permissionString.split(",");
    
      // Check if any of the permissions exist in the system
      return hasPermission(permissions);
    },
    canAccessWorkReports() {
      return hasModule('work_report')
    },
    canAccessAbsences() {
      return hasModule('absence') && (hasPermission(['absence_manage', 'absence_export_execute']) || this.isAdmin())
    },
    canAccessWorkReportsSettings() {
      return hasModule('work_report') && (hasPermission(['workreport_selectionlist_manage']) || this.isAdmin())
    },   
    checkPermission(permissionString) {
        if (permissionString == null) {
          return true;
        }

        let permissionArray = permissionString.split(",");

        return hasPermission(permissionArray) || this.isAdmin();
    }
  }
}
</script>

<style scoped>
.md-list-item-expand:before, .md-list-item-expand:after {
  content: none;
}
.quick-settings {
  border-top: var(--v-primary-base) 5px solid;
  overflow: hidden;
}

.md-sidenav-content .md-toolbar {
  background-color: #fff;
}
</style>
