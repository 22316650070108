<template>
  <div>
    <YoutubeModal ref="ytmodal" @onConfirm="addCommand" />
    <ImageModal ref="imgmodal" @onConfirm="addCommand" :timestamp="timestamp" :newsPostID="newsPostID" />
    <LinkModal ref="linkmodal" />

    <div class="menubar-wrap" :class="{'fixed': adminMenuBarFixed }">
      <div ref="editormenubar" >
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
          <div class="menubar">
            <button type="button" class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
              <icon name="format_bold" />
            </button>
            <button type="button" class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
              <icon name="format_italic" />
            </button>
            <button type="button" class="menubar__button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike">
              <icon name="format_strikethrough" />
            </button>
            <button type="button" class="menubar__button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
              <icon name="format_underlined" />
            </button>
            <button type="button" class="menubar__button" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
              <icon name="format_list_bulleted" />
            </button>
            <button type="button" class="menubar__button" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph">
              p
            </button>
            <button type="button" class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="commands.heading({ level: 1 })">
              H1
            </button>
            <button type="button" class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })">
              H2
            </button>
            <button type="button" class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({ level: 3 })">
              H3
            </button>
            <button
				type="button"
				class="menubar__button"
				@click="openLinkModal(commands.link)"
            >
				<Icon name="link"/>
            </button>
            <button
				type="button"
				class="menubar__button"
				@click="setUrl(commands.link, null)"
            >
				<Icon name="link_off"/>
            </button>
            <button type="button" class="menubar__button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote">
              <icon name="format_quote" />
            </button>
            <button type="button" class="menubar__button" @click="commands.horizontal_rule">
              <icon name="horizontal_rule" />
            </button>

            <button type="button" class="menubar__button" @click="openImageModal(commands.image);" v-if="!btnDisabled.includes('image')">
              <Icon name="image" />
            </button>

            <button type="button" class="menubar__button" @click="openYoutubeModal(commands.iframe)"  v-if="!btnDisabled.includes('iframe')">
              <svg viewBox="0 0 511.6 511.6" width="14" height="14">
                <path d="M511.3 213c-.1-10.3-1-23.3-2.4-39a354.4 354.4 0 0 0-6.1-42.1 66.4 66.4 0 0 0-19.9-35.1c-10.1-9.5-22-15-35.5-16.6-42.3-4.7-106.1-7.1-191.6-7.1-85.4 0-149.3 2.4-191.6 7.1a60.2 60.2 0 0 0-35.4 16.6 66.8 66.8 0 0 0-19.7 35.1A316 316 0 0 0 2.7 174a560.2 560.2 0 0 0-2.4 39 2430.9 2430.9 0 0 0 0 85.6 560 560 0 0 0 2.4 39c1.4 15.7 3.5 29.8 6.2 42.1a65.4 65.4 0 0 0 55.3 51.7c42.3 4.8 106.1 7.1 191.6 7.1s149.3-2.3 191.6-7.1c13.5-1.5 25.3-7 35.4-16.6a66.8 66.8 0 0 0 19.7-35c2.8-12.4 5-26.5 6.4-42.2 1.4-15.7 2.2-28.7 2.4-39a2450.8 2450.8 0 0 0 0-85.6zM357 271.2l-146.2 91.4a16.3 16.3 0 0 1-9.7 2.8c-2.9 0-5.8-.7-8.9-2.2a17 17 0 0 1-9.4-16V164.5a17 17 0 0 1 9.4-16 17.2 17.2 0 0 1 18.6.5L357 240.4c5.7 3.2 8.5 8.4 8.5 15.4s-2.8 12.2-8.5 15.4z" />
              </svg>
            </button>
            <button type="button" class="menubar__button" @click="commands.undo">
              <icon name="undo" />
            </button>
            <button type="button" class="menubar__button" @click="commands.redo">
              <icon name="redo" />
            </button>
          </div>
        </editor-menu-bar>
      </div>
    </div>
<!--     <editor-menu-bubble class="menububble" :editor="editor" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
      <div class="menububble" :class="{ 'is-active': menu.isActive }" :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`">

        <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
          <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu" />
          <button type="button" class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
            <icon name="link_off" />
          </button>
        </form>

        <template v-else>
          <button type="button" class="menububble__button" @click="showLinkMenu(getMarkAttrs('link'))" :class="{ 'is-active': isActive.link() }">
            <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
            <icon name="link" />
          </button>
        </template>

      </div>
    </editor-menu-bubble> -->
    <editor-content :class="[{ 'disabled': !isEditable }, 'editor__content']" :editor="editor" />
<!--     <div class="export">
      <h3>JSON</h3>
      <pre><code v-html="json"></code></pre>
      <h3>HTML</h3>
      <pre><code>{{ html }}</code></pre>
    </div> -->
  </div>
</template>
<script>
// import {HTTP} from './../auth'
// import axios from 'axios'
import { EventBus } from './../../event-bus'
// import _ from 'lodash'
// import Icon from 'components/wysiwyg/Icon'
import Icon from './Icon.vue'
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
import {
  Blockquote,
  HardBreak,
  Heading,
  HorizontalRule,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History
  // Image
} from 'tiptap-extensions'
// import Iframe from './tiptap-extensions/src/nodes/Iframe.js'
import Iframe from './Iframe.js'
import YoutubeModal from './YoutubeModal.vue'
import Image from './Image.js'
// import Link from './Link'
import ImageModal from './ImageModal.vue'
import LinkModal from './LinkModal.vue'

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble,
    Icon,
    YoutubeModal,
    ImageModal,
    LinkModal
  },
  data () {
    return {
      btnDisabled: [],
      adminMenuBarFixed: false,
      adminMenuBarOffsetTop: null,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(),
          new Iframe(),
          new Link()
        ],
        editorProps: { handleDOMEvents: {
          drop: (view, e) => { e.preventDefault() }
        } },
        dropCursor: { width: 0, color: 'transparent' },
        content: null,
        editable: this.isEditable,
        onInit: ({ state, view }) => {
          // console.log(state)
          // console.log(view)
        },
        onUpdate: ({ getJSON, getHTML }) => {
          this.json = getJSON()
          this.html = getHTML()
          // this.checkImages(this.json.content)
          EventBus.$emit('update-content', this.json.content, this.html)
        },
        onPaste: () => {

        },
        onDrop: (view, event, slice, moved) => {
          // console.log(`New content was added from the user's clipboard!`)
          // console.log(view, event, slice, moved)
        }
      }),
      json: 'Update content to see changes',
      html: 'Update content to see changes',
      linkUrl: null,
      linkMenuIsActive: false,
      images: []
    }
  },
  props: {
    uploadTarget: '',
    isEditable: null,
    timestamp: Number,
    newsContent: null,
    newsPostID: null,
    hideButtons: Array
  },
  created () {
    window.addEventListener('scroll', this.scrollListener)
    if (this.newsContent) {
      this.editor.setContent(this.newsContent, true)
    }
  },
  watch: {},
  mounted () {
    if(typeof this.hideButtons != 'undefined') {
      this.btnDisabled = this.hideButtons;
    }
    this.adminMenuBarOffsetTop = this.$refs.editormenubar.offsetTop
    EventBus.$on('set-link', function (data) {
      this.setUrl(data.command, data.src)
    }.bind(this))
  },
  beforeDestroy () {
    this.editor.destroy()
    window.removeEventListener('scroll', this.scrollListener)
  },
  destroyed () {
  },
  methods: {
    scrollListener: function (event) {
      // if (window.scrollY > this.$refs.editormenubar.offsetTop) {
      if (window.scrollY > this.adminMenuBarOffsetTop) {
        this.adminMenuBarFixed = true
      } else {
        this.adminMenuBarFixed = false
      }
      // console.log('window' + window.scrollY)
      // console.log('elementtop' + this.$refs.editormenubar.offsetTop)
      // console.log(this.$refs.editormenubar)
    },
    // checkImages (data) {
    //   // data = JSON.parse(data)
    //   // console.log(this.data)
    //   // console.log(this.data.length)
    //   // data.forEach(item => this.images.push(item))
    //   // for (var i = 0; i < this.data.length; i++) {
    //   //   console.log(data[i])
    //   // }
    // },
    showImagePrompt (command) {
      const src = prompt('Enter the url of your image here')
      if (src !== null) {
        command({ src })
      }
    },
    // showImageModal (command) {
    //   this.$refs.imageModal.showModal({command})
    // },
    openImageModal (command) {
      this.$refs.imgmodal.showImageModal(command)
    },
    openLinkModal (command) {
      const state = this.editor.state

      // get marks, if any from selected area
      const { from, to } = state.selection
      let marks = []
      state.doc.nodesBetween(from, to, (node) => {
        marks = [...marks, ...node.marks]
      })

      const mark = marks.find((markItem) => markItem.type.name === 'link')
      // let urlSetting = ''

      if (mark && mark.attrs.href) {
        const presetURL = mark.attrs.href
        // urlSetting = prompt('Please update url', presetURL) // let a user see the previously set URL
        this.$refs.linkmodal.showLinkModal(command, presetURL)
      } else {
        // urlSetting = prompt('Please add url', '') // a clean prompt, has had no anchor
        this.$refs.linkmodal.showLinkModal(command, null)
      }
    },
    openYoutubeModal (command) {
      this.$refs.ytmodal.showYoutubeModal(command)
    },
    addCommand (data) {
      if (data.command !== null) {
        data.command(data.data)
      }
    },
    setContent () {
      this.editor.setContent(this.content)
    },
    showLinkMenu (attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu () {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl (command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
    // updateLinkUrl () {
    //   // this.showLinkMenu(getMarkAttrs('link'))
    // },
    setUrl (command, url) {
      // const state = this.editor.state

      // // get marks, if any from selected area
      // const { from, to } = state.selection
      // let marks = []
      // state.doc.nodesBetween(from, to, (node) => {
      //   marks = [...marks, ...node.marks]
      // })

      // const mark = marks.find((markItem) => markItem.type.name === 'link')
      // console.log(state.selection)
      // console.log(marks)
      // console.log(mark)

      // // let urlSetting = ''

      // if (mark && mark.attrs.href) {
      //   // const presetURL = mark.attrs.href
      //   // urlSetting = prompt('Please update url', presetURL) // let a user see the previously set URL
      // } else {
      //   // urlSetting = prompt('Please add url', '') // a clean prompt, has had no anchor
      // }

      command({ href: url })
    }
  }
}

</script>
