<template>
    <tr :class="getClass">
      <td v-for="(header, index) in headers" :key="index" :style="'width: ' + header.width + 'px; padding: 0px; margin:0px;'">
        <slot :item="item" :name="columnName(header)">

       
          <div v-if="header.value == 'process'" :style="getAlignment(header) + '; cursor:pointer;'">
            <v-icon>
              mdi-drag
            </v-icon>
          </div>
          <div v-else-if="header.value == 'icon'" :style="getAlignment(header)">
            <v-icon>
              {{ getNonSlotValue(item, header) }}
            </v-icon>
          </div>
          <div v-else-if="header.value == 'type'" :style="getAlignment(header)">
            <v-icon v-if="getNonSlotValue(item, header) =='webview'" >share</v-icon>
            <v-icon v-if="getNonSlotValue(item, header) =='external_browser'" >share</v-icon>
            <v-icon v-if="getNonSlotValue(item, header) =='content_block'" >mdi-view-dashboard</v-icon>
            <v-icon v-if="getNonSlotValue(item, header) =='module'" >mdi-checkbox-blank-badge</v-icon>
          </div>
          <div v-else-if="header.value == 'edit'" :style="getAlignment(header)">
            <router-link :to="{ path: '/settings/menu/' + item.id }" class="edit-row-table">
              <v-icon
                small
                class="edit-row-button"
              >
                edit
              </v-icon>
            </router-link>
          </div>
          <div v-else-if="header.value == 'delete'" :style="getAlignment(header)">
            <v-icon
              small
              class="edit-row-button"
              @click="deleteItem(item)"
            >
              delete
            </v-icon>
          </div>
     
          <div v-else :style="getAlignment(header)">
            {{ getNonSlotValue(item, header) }}
          </div>
          
        </slot>
      </td>
    </tr>
  </template>
  
  <script>
  import { EventBus } from '@/event-bus'
   
  export default {
    name: "DataTableRowHandler",
    components: {},
    props: {
      itemClass: {
        type: String,
        default: "",
      },
      item: {
        type: Object,
        default: () => {
          return {}
        },
      },
      headers: {
        type: Array,
        default: () => {
          return []
        },
      },
    },
    data() {
      return {}
    },
    computed: {
      getClass() {
        return this.itemClass
      }
    },
    methods: {
      columnName(header) {
        return `item.${header.value}`
      },
      getAlignment(header) {
        const align = header.align ? header.align : "center"
        return `text-align: ${align}`
      },
      getNonSlotValue(item, header) {
        const val = item[header.value]

        if (val) {
          return val
        }
  
        return ""
      },
      deleteItem(item) {
        EventBus.$emit('delete-item', item)
      }
    }
  }
  </script>
  