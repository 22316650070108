const themesCda = {
  themes: {
    light: {
      primary: '#7E1630',
      secondary: '#E5D0D6',
      success: '#33CF77',
      warning: '#FB8C00',
      error: '#E30613',
      buttons:'#7E1630'
    }
  },
}

export default themesCda
