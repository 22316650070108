<template>
  <div>
    <toolbar :dataLoaded="dataLoaded">{{ t('title') }}</toolbar>

    <v-container fluid class="edit-screen">
      <app-year-picker class="sp-pad-bot-3" v-model="filter.year"/>

      <v-card elevation="0" class="sp-mar-bot-3">
        <v-card-title>{{ t('employee.group-title') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12"
                   sm="2"
                   md="2">{{ t('employee.first-name') }}
            </v-col>
            <v-col cols="12"
                   sm="2"
                   md="2">{{ data.employee.firstname }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="2"
                   md="2">{{ t('employee.last-name') }}
            </v-col>
            <v-col cols="12"
                   sm="2"
                   md="2">{{ data.employee.name }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
          v-if="!data.hasBalance"
          elevation="0"
          class="sp-mar-bot-3"
      >
        <v-card-title>{{ t('no-balance.group-title') }}</v-card-title>
        <v-card-text>

          <div>
            <div>{{ t('no-balance.text') }}</div>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-btn elevation="0"
                 color="primary"
                 outlined
                 class="icon-left"
                 @click="generateBalance">
            <v-icon>mdi-plus</v-icon>
            {{ t('no-balance.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
          elevation="0"
      >
        <v-card-title>{{ t('vacation.group-title') }}</v-card-title>
        <v-card-text>
          <v-row v-if="data.vacation.length == 0">
            <v-col class="pa-3">
              <div>{{ t('no-balance.text') }}</div>
            </v-col>
          </v-row>
          <v-row class="header-row ma-1" v-if="data.vacation.length > 0">
            <v-col class="header-header pa-3 mr-1 text-center" cols="12"
                   sm="2"
                   md="2">
              <span>{{ t('vacation.date') }}</span>
            </v-col>
            <v-col class="date-header pa-3 mb-2 mr-1 text-center" cols="12"
                   sm="3"
                   md="3">
              <span>{{ t('vacation.text') }}</span>
            </v-col>
            <v-col class="date-header pa-3 mb-2 mr-1 text-center" cols="12"
                   sm="2"
                   md="2">
              <span class="number">{{ t('vacation.credit') }}</span>
            </v-col>
            <v-col class="date-header pa-3 mb-2 mr-1 text-center" cols="12"
                   sm="2"
                   md="2">
              <span class="number">{{ t('vacation.debit') }}</span>
            </v-col>
            <v-col class="date-header pa-3 mb-2 mr-1 text-center" cols="12"
                   sm="2"
                   md="2">
              <span class="number">{{ t('vacation.balance') }}</span>
            </v-col>
          </v-row>

          <v-row class="content-row ma-1" v-for="entry in data.vacation" v-if="data.vacation.length > 0">
            <v-col class="content-header pa-3 bg-field mr-1 text-center" cols="12"
                   sm="2"
                   md="2">
              {{ formatDate(entry.date) }}
            </v-col>
            <v-col class="content-header pa-3 bg-field number mr-1 text-center" cols="12"
                   sm="3"
                   md="3">
              {{ entry.title }}
              <v-tooltip right v-if="entry.details !== ''">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">info</v-icon>
                </template>
                <div>
                  {{ entry.details }}
                </div>
              </v-tooltip>
            </v-col>
            <v-col class="content-header pa-3 bg-field number mr-1 text-center" cols="12"
                   sm="2"
                   md="2">
              {{ formatMinutesAsHours(entry.credit) }}
            </v-col>
            <v-col class="content-header pa-3 bg-field number mr-1 text-center" cols="12"
                   sm="2"
                   md="2">
              {{ formatMinutesAsHours(entry.debit) }}
            </v-col>
            <v-col class="content-header pa-3 bg-field number mr-1 text-center" cols="12"
                   sm="2"
                   md="2">
              {{ formatMinutesAsHours(entry.saldo) }}
            </v-col>
          </v-row>


        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialogs.addVacationBookingVisible = true"
                 elevation="0"
                 color="primary"
                 outlined
                 class="icon-left">
            <v-icon>mdi-plus</v-icon>
            {{ t('vacation.book-vacation') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
          elevation="0"
          class="sp-mar-top-3"
      >
        <v-card-title>{{ t('flextime.group-title') }}</v-card-title>
        <v-card-text>
          <v-row v-if="data.flextime.length == 0">
            <v-col class="pa-3">
              <div>{{ t('no-balance.text') }}</div>
            </v-col>
          </v-row>
          <v-row class="header-row ma-1" v-if="data.flextime.length > 0">
            <v-col class="header-header pa-3 mr-1 text-center"
                   cols="12"
                   sm="2"
                   md="2">
              <span>{{ t('flextime.date') }}</span>
            </v-col>
            <v-col class="date-header pa-3 mb-2 mr-1 text-center"
                   cols="12"
                   sm="3"
                   md="3">
              <span>{{ t('flextime.text') }}</span>
            </v-col>
            <v-col class="date-header pa-3 mb-2 mr-1 text-center"
                   cols="12"
                   sm="2"
                   md="2">
              <span class="number">{{ t('flextime.credit') }}</span>
            </v-col>
            <v-col class="date-header pa-3 mb-2 mr-1 text-center"
                   cols="12"
                   sm="2"
                   md="2">
              <span class="number">{{ t('flextime.debit') }}</span>
            </v-col>
            <v-col class="date-header pa-3 mb-2 mr-1 text-center"
                   cols="12"
                   sm="2"
                   md="2">
              <span class="number">{{ t('flextime.balance') }}</span>
            </v-col>
          </v-row>

          <v-row class="content-row ma-1" v-for="entry in data.flextime" v-if="data.flextime.length > 0">

            <v-col class="content-header pa-3 bg-field mr-1 text-center"
                   cols="12"
                   sm="2"
                   md="2"
            >
              {{ formatDate(entry.date) }}
            </v-col>

            <v-col class="content-header pa-3 bg-field number text-center mr-1"
                   cols="12"
                   sm="3"
                   md="3">
              {{ entry.title }}
              <v-tooltip right v-if="entry.details !== ''">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">info</v-icon>
                </template>
                <div>
                  {{ entry.details }}
                </div>
              </v-tooltip>
            </v-col>

            <v-col class="content-header pa-3 bg-field number text-center mr-1"
                   cols="12"
                   sm="2"
                   md="2"
            >
              {{ formatMinutesAsHours(entry.credit) }}  <span v-if="typeof entry.total_credit !== 'undefined' && theme === 'gia'">/ {{ formatMinutesAsHours(entry.total_credit) }}</span>
            </v-col>

            <v-col class="content-header pa-3 bg-field number text-center mr-1"
                   cols="12"
                   sm="2"
                   md="2">
              {{ formatMinutesAsHours(entry.debit) }}
            </v-col>

            <v-col class="content-header pa-3 bg-field number text-center mr-1"
                   cols="12"
                   sm="2"
                   md="2">
              {{ formatMinutesAsHours(entry.saldo) }}
            </v-col>

          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialogs.addFlextimeBookingCreditVisible = true" elevation="0"
                 color="primary"
                 outlined
                 class="icon-left">
            <v-icon>mdi-plus</v-icon>
            {{ t('target-hours.book-target-hours') }}
          </v-btn>
          <v-btn @click="dialogs.addFlextimeBookingDebitVisible = true" elevation="0"
                 color="primary"
                 outlined
                 class="icon-left">
            <v-icon>mdi-plus</v-icon>
            {{ t('target-hours.book-actual-hours') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
          elevation="0"
          class="sp-mar-top-3"
      >
        <v-card-title>{{ t('employment-degree.group-title') }}</v-card-title>
        <v-card-text>

          <v-row v-if="data.employmentDegree.length == 0">
            <v-col class="pa-3">
              <div>{{ t('no-balance.text') }}</div>
            </v-col>
          </v-row>

          <v-row class="header-row ma-1" v-if="data.employmentDegree.length > 0">
            <v-col class="header-header pa-3 mr-1 text-center" cols="12"
                   sm="3"
                   md="3">
              <span>{{ t('employment-degree.valid-from') }}</span>
            </v-col>
            <v-col class="date-header pa-3 mb-2 mr-1 text-center" cols="12"
                   sm="4"
                   md="2">
              <span>{{ t('employment-degree.degree') }}</span>
            </v-col>
          </v-row>
          <v-row class="content-row ma-1" v-for="entry in data.employmentDegree"
                 v-if="data.employmentDegree.length > 0">
            <v-col cols="12"
                   sm="3"
                   md="3" class="content-header pa-3 bg-field mr-1 text-center">
              {{ formatDate(entry.valid_from) }}
            </v-col>
            <v-col cols="12"
                   sm="4"
                   md="2" class="content-header pa-3 bg-field number mr-1 text-center">
              {{ entry.degree }}%
            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialogs.addEmploymentDegreeVisible = true" elevation="0"
                 color="primary"
                 outlined
                 class="icon-left">
            <v-icon>mdi-plus</v-icon>
            {{ t('employment-degree-booking.title') }}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-container>


    <v-dialog
        v-model="dialogs.addVacationBookingVisible"
        width="828"
        persistent
    >
      <time-management-balance-edit-vacation-booking
          @cancel="dialogs.addVacationBookingVisible = false"
          @save="saveAddVacationBooking"
      />
    </v-dialog>

    <v-dialog
        v-model="dialogs.addFlextimeBookingCreditVisible"
        width="828"
        persistent
    >
      <time-management-balance-edit-flextime-booking
          @cancel="dialogs.addFlextimeBookingCreditVisible = false"
          @save="saveAddFlextimeBookingCredit"
      />
    </v-dialog>

    <v-dialog
        v-model="dialogs.addFlextimeBookingDebitVisible"
        width="828"
        persistent
    >
      <time-management-balance-edit-flextime-booking
          @cancel="dialogs.addFlextimeBookingDebitVisible = false"
          @save="saveAddFlextimeBookingDebit"
      />
    </v-dialog>

    <v-dialog
        v-model="dialogs.addEmploymentDegreeVisible"
        width="828"
        persistent
    >
      <time-management-balance-edit-employment-degree
          @cancel="dialogs.addEmploymentDegreeVisible = false"
          @save="saveEmploymentDegree"
      />
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import loading from '../components/layouts/Loading.vue'
import toolbar from '../components/layouts/Navigation.vue'
import TimeManagementBalanceEditVacationBooking from "./TimeManagementBalanceEditVacationBooking.vue";
import TimeManagementBalanceEditFlextimeBooking from "./TimeManagementBalanceEditFlextimeBooking.vue";
import AppYearPicker from "../components/vuetify/AppYearPicker.vue";
import TimeManagementBalanceEditEmploymentDegree from "./TimeManagementBalanceEditEmploymentDegreeBooking.vue";
import store from "store";

export default {
  components: {
    TimeManagementBalanceEditEmploymentDegree,
    AppYearPicker,
    TimeManagementBalanceEditFlextimeBooking,
    TimeManagementBalanceEditVacationBooking,
    loading,
    toolbar,
  },
  data() {
    return {
      userId: null,
      data: {
        employee: {},
        hasBalance: false,
        vacation: [],
        flextime: [],
        employmentDegree: [],
      },
      filter: {
        year: new Date().getFullYear(),
      },
      dialogs: {
        addVacationBookingVisible: false,
        addFlextimeBookingCreditVisible: false,
        addFlextimeBookingDebitVisible: false,
        addEmploymentDegreeVisible: false,
      },
      dataLoaded: false,
    }
  },
  async created() {
    this.userId = this.$route.params.userId
  },
  async mounted() {
    await this.getData()
  },
  watch: {
    filter: {
      handler(val) {
        this.getData()
      },
      deep: true
    }
  },
  computed: {
    theme() {
      return store.get('theme')
    },
    hasChanges() {
      if (!this.dataOriginal) {
        return false;
      }
      return this.contentChanged
    },
    contentChanged() {
      return JSON.stringify(this.absenceData) !== JSON.stringify(this.dataOriginal)
    },
  },
  beforeDestroy() {
  },
  methods: {
    t: function (key) {
      return this.$t('time-management.detail.' + key);
    },
    formatMinutesAsHours(value) {
      return (value / 60).toFixed(2)
    },
    isCurrentEmploymentRate(entry) {
      return Date.parse(entry.valid_from) <= Date.now() && Date.now() <= Date.parse(entry.valid_to)
    },
    async generateBalance() {
      let data = {
        year: this.filter.year
      }
      await HTTP.post('timelog/balances/' + this.userId + '/generate-balance', data)
      await this.getData()
    },
    async saveAddVacationBooking(data) {
      await HTTP.post('timelog/balances/' + this.userId + '/add-vacation-booking', data)
      await this.getData()
      this.dialogs.addVacationBookingVisible = false
    },
    async saveAddFlextimeBookingCredit(data) {
      data.booking_type = 'credit'
      await HTTP.post('timelog/balances/' + this.userId + '/add-flextime-booking', data)
      await this.getData()
      this.dialogs.addFlextimeBookingCreditVisible = false
    },
    async saveAddFlextimeBookingDebit(data) {
      data.booking_type = 'debit'
      await HTTP.post('timelog/balances/' + this.userId + '/add-flextime-booking', data)
      await this.getData()
      this.dialogs.addFlextimeBookingDebitVisible = false
    },
    async saveEmploymentDegree(data) {
      let postData = {
        "valid_from": data.validFrom,
        "degree": data.degree,
      }
      try {
        await HTTP.post('timelog/balances/' + this.userId + '/add-employment-degree', postData)
      } catch (e) {
        if (e.response.status === 400 && e.response.data.rule === 'ENTRY_ALREADY_EXISTS_AT_DATE') {
          this.$root.infoNotification.showError(this.$t('errors.rules.ENTRY_ALREADY_EXISTS_AT_DATE'))
          return
        }
        throw e
      }
      await this.getData()
      this.dialogs.addEmploymentDegreeVisible = false
    },
    async getData() {
      let response = await HTTP.get('timelog/balances/' + this.userId + '?year=' + this.filter.year)
      this.data.employee = response.data.employee
      this.data.hasBalance = response.data.has_balance
      this.data.vacation = response.data.vacation
      this.data.flextime = response.data.flextime
      this.data.employmentDegree = response.data.employment_degree

      let vacSaldo = 0;
      this.data.vacation.forEach(function (item) {
        item.saldo = vacSaldo + item.credit - item.debit
        vacSaldo = item.saldo
      })

      let flexSaldo = 0;
      this.data.flextime.forEach(function (item) {
        item.saldo = flexSaldo - item.credit + item.debit
        flexSaldo = item.saldo
      })

      this.dataLoaded = true
    },
    cancel() {
      this.close();
    },
    close() {
      this.$router.replace({name: 'SettingsTimelogTimeManagement'})
    },
  }
}
</script>

<style scoped lang="scss">
.header-row {
  font-weight: bold;
  text-align: right;
}

.header-header {
  text-align: left;
}

.content-row {
  text-align: right;
}

.content-header {
  text-align: left;
}

.footer-row {
  font-weight: bold;
  text-align: right;
}

thead td {
  font-weight: bold;
}

td {
  padding-right: 8px;
}

.number {
  text-align: right;
}

.highlight {
  font-weight: bold;
}

.bg-field {
  padding: 8px;
  background-color: #F5F5F5;
}


</style>

