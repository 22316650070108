<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="settings">

      <loading :error="error" :dataLoaded="dataLoaded"></loading>

      <v-form ref="form">

        <div v-if="dataLoaded">

          <v-row class="settings-wrapper">

            <v-card
                v-if="moduleEnabled('timelog_wv')"
                elevation="0"
            >
              <v-card-title>Timelog</v-card-title>
              <v-card-text
                  v-if="dataLoadedTimelog"
              >

                <v-text-field
                    :label="settings.timelog_wv[0].label"
                    v-model="settings.timelog_wv[0].value"
                    @change="editItem(0, 'timelog_wv')"
                    filled
                    :rules="urlRule"
                ></v-text-field>

                <v-text-field
                    :label="settings.timelog_wv[1].label"
                    v-model="settings.timelog_wv[1].value"
                    @change="editItem(1, 'timelog_wv')"
                    filled
                    :rules="[
								() => !!settings.timelog_wv[1].value || settings.timelog_wv[1].label +  t('name-required')
							]"
                ></v-text-field>

              </v-card-text>
            </v-card>

            <v-card
                v-if="moduleEnabled('surveys_wv')"
                elevation="0"
            >
              <v-card-title>Survey</v-card-title>
              <v-card-text
                  v-if="dataLoadedSurvey"
              >

                <v-text-field
                    :label="settings.surveys_wv[0].label"
                    v-model="settings.surveys_wv[0].value"
                    @change="editItem(0, 'surveys_wv')"
                    filled
                    :rules="urlRule"
                ></v-text-field>

                <v-text-field
                    :label="settings.surveys_wv[1].label"
                    v-model="settings.surveys_wv[1].value"
                    @change="editItem(1, 'surveys_wv')"
                    filled
                    :rules="[
								() => !!settings.surveys_wv[1].value || settings.surveys_wv[1].label + t('name-required')
							]"
                ></v-text-field>

              </v-card-text>
            </v-card>

          </v-row>

        </div>

      </v-form>

    </v-container>


    <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        timeout="4000"
    >
      {{ message }}
    </v-snackbar>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
                color="primary"
                text
                class="icon-left"
                @click="clear()"
            >
              <v-icon>mdi-reload</v-icon>
              {{ t('reset') }}
            </v-btn>
            <v-spacer></v-spacer>

            <app-dialog-confirm-save
                :validateForm="$refs.form"
                @confirmed="event => update(event)"
            />

          </v-row>
        </v-container>
      </div>
    </transition>

  </div>
</template>

<script>
import { HTTP } from './../auth'
import { hasModule } from '@/services/LicenseService'
import toolbar from '../components/layouts/Navigation.vue'
import loading from '../components/layouts/Loading.vue'
import AppDialogConfirmSave from "../components/vuetify/AppDialogConfirmSave";

var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

export default {
  name: 'SettingsWebview',
  components: {
    toolbar,
    loading,
    AppDialogConfirmSave
  },
  data() {
    return {
      data: {
        timelog_wv: [],
        surveys_wv: []
      },
      settings: {
        timelog_wv: null,
        surveys_wv: null
      },
      urlRule: [
        (v) => pattern.test(v) || 'Bitte eine gültige URL eingeben'
      ],
      dataLoaded: false,
      dataLoadedTimelog: false,
      dataLoadedSurvey: false,
      message: 'Erfolgreich gespeichert.',
      messageSuccess: 'Erfolgreich gespeichert.',
      error: null,
      errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.',
      snackbar: false,
      formValid: false,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    moduleEnabled(string) {
      return hasModule(string)
  },
    t: function (key) {
      return this.$t('webview-settings.' + key);
    },
    update (isConfirmed) {
      if (!isConfirmed){
        this.clear()
        return
      }

      const dataMerged = [].concat(this.data.timelog_wv, this.data.surveys_wv)
      for (var item in dataMerged) {
        HTTP.patch('settings/' + dataMerged[item].id, dataMerged[item]).then(function (response) {
          this.data.timelog_wv = []
          this.data.surveys_wv = []
          this.message = this.messageSuccess
        }.bind(this)).catch(function (error) {
          if (error.response) {
            this.message = this.errorMessage
          }
        }.bind(this))
      }
      this.openSnackbar()
    },
    editItem(index, settingsType) {
      if (settingsType === 'timelog_wv') {
        this.data.timelog_wv[index] = this.settings.timelog_wv[index]
      } else if (settingsType === 'surveys_wv') {
        this.data.surveys_wv[index] = this.settings.surveys_wv[index]
      }
    },
    clear() {
      this.dataLoaded = false
      this.dataLoadedTimelog = false
      this.dataLoadedSurvey = false
      this.data = []
      this.getData()
    },
    getData() {
      if (this.moduleEnabled('timelog_wv')) {
        this.getTimelogWVData()
      }
      if (this.moduleEnabled('surveys_wv')) {
        this.getSurveyWVData()
      }
    },
    getTimelogWVData() {
      HTTP.get('settings?key=timelog_wv').then(function (response) {
        this.settings.timelog_wv = response.data
        this.dataLoaded = true
        this.dataLoadedTimelog = true
        this.error = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    getSurveyWVData() {
      HTTP.get('settings?key=surveys_wv').then(function (response) {
        this.settings.surveys_wv = response.data
        this.dataLoaded = true
        this.dataLoadedSurvey = true
        this.error = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 600)
    },
    openDialog() {
      if (this.$refs.form.validate()) {
        setTimeout(() => {
          this.dialog = true
        }, 600)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.settings-wrapper {
  justify-content: space-between;

  .v-card {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
}

</style>
