<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col">
          <h2>{{ $t('logout.title') }}</h2>
          <h3>
            {{ $t('logout.description') }}<br/>
            <v-btn color="primary" class="mt-4" :to="{ path: '/dashboard' }">{{ $t('logout.login') }}</v-btn>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLogout"
}
</script>

<style scoped>

</style>
