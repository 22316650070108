var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasicCreateEditContainer',{ref:"editor",attrs:{"title":_vm.mode == 'create' ? _vm.t('create.title') : _vm.t('edit.title'),"custom-class":"checklist-element-chooser","object-type":"Element","object-name":_vm.task.name,"data-loaded":_vm.dataLoaded,"save-action":_vm.saveTask,"allow-delete":_vm.mode === 'edit',"delete-action":_vm.deleteTask,"has-changes":_vm.hasChanges,"hidden-field-validation-message":_vm.t('validation.hidden-field-validation-subtasks'),"deletion-hint-text":_vm.deletionHintText,"deletion-hint-list":_vm.deletionHintList},on:{"cancel":_vm.close,"saved":_vm.onSaved,"deleted":_vm.onDeleted},scopedSlots:_vm._u([{key:"fluid",fn:function(){return [_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('create.card-title')))]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.t('create.placeholder.name'),"counter":"40","filled":"","rules":[
                  function () { return !!_vm.task.name || _vm.t('validation.error-name-required'); },
                  function () { return (_vm.task.name && _vm.task.name.length <= 40) || _vm.t('validation.error-max-name-40'); }
                ]},model:{value:(_vm.task.name),callback:function ($$v) {_vm.$set(_vm.task, "name", $$v)},expression:"task.name"}}),_c('v-text-field',{attrs:{"label":_vm.t('create.placeholder.description'),"counter":"120","filled":"","rules":[
                  function () { return (!_vm.task.description || (_vm.task.description && _vm.task.description.length <= 120)) || _vm.t('validation.error-max-name'); }
                ]},model:{value:(_vm.task.description),callback:function ($$v) {_vm.$set(_vm.task, "description", $$v)},expression:"task.description"}}),_c('v-text-field',{ref:"hiddenValidation",staticClass:"hidden",attrs:{"rules":[
                  function () { return !!_vm.noSubtasksValue || _vm.t('validation.hidden-field-validation-subtasks'); }
                ]},model:{value:(_vm.noSubtasksValue),callback:function ($$v) {_vm.noSubtasksValue=$$v},expression:"noSubtasksValue"}}),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("Tags")]),_c('v-card-text',[_c('v-chip-group',{attrs:{"column":"","multiple":""},on:{"change":_vm.updateTags},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}},_vm._l((_vm.tags),function(tag,index){return _c('v-chip',{key:index,attrs:{"filter":"","ripple":false}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('create.card-subtasks-title')))]),_c('v-card-text',[_c('ChecklistElementChooser',{attrs:{"object-type":"Subtask","subelements":_vm.task.subtasks,"validate-subelements":_vm.hasSubtasks}})],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }