<template>
  <div v-if="dataLoaded">

    <toolbar :dataLoaded="true">{{ t('create.title') }}</toolbar>

    <v-container fluid class="edit-screen">

      <v-form ref="form">

        <v-row>

          <!-- Left Column -->
          <v-col class="content-area" cols="12" sm="12" md="8">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ t('details.group-title') }}</v-card-title>
              <v-card-text>

                <v-text-field
                    :label="t('details.title') + '*'"
                    counter="120"
                    v-model="data.title"
                    data-cy="title"
                    filled
                    :rules="[
								() => !!data.title || $t('validation.required.title'),
								() => (data.title && data.title.length <= 120) || $t('validation.length.title')
							]"
                ></v-text-field>

                <v-text-field
                    :label="t('details.description') + '*'"
                    counter="120"
                    v-model="data.previewText"
                    data-cy="previewText"
                    filled
                    :rules="[
								() => !!data.previewText || $t('validation.required.description'),
								() => (data.previewText && data.previewText.length <= 120) || $t('validation.length.description')
							]"
                ></v-text-field>

              </v-card-text>
            </v-card>

            <v-card
                elevation="0"
                class="sp-mar-top-3"
            >
              <v-card-title>{{ $t('components.blocks-editor.label') }}*</v-card-title>
              <v-card-text>

                <v-menu
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        outlined
                        class="icon-left blocks-button"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-plus</v-icon>
                      {{ $t('components.blocks-editor.add-content') }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                        v-for="(blockitem, index) in blocksList"
                        :key="index"
                        class="blocks-list-item"
                    >
                      <v-list-item-title
                          @click="addBlock(blockitem.name)"
                      >
                        <v-icon>{{ blockitem.icon }}</v-icon>
                        {{ blockitem.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                    v-if="blocks.length > 0"
                    color="primary"
                    outlined
                    class="icon-left phone-preview"
                    @click="phonePreview"
                >
                  <v-icon>mdi-cellphone-check</v-icon>
                  {{ $t('components.blocks-editor.preview') }}
                </v-btn>

                <div v-show="isSubmitted && !hasBlockContent" class="has-no-content">
                  <v-icon>mdi-info</v-icon>
                  {{ $t('validation.required.blocks-editor') }}
                </div>

                <draggable element="div" v-model="blocks" data-cy="blocks" :sort="true" class="blocks-wrapper">

                  <v-card
                      elevation="0"
                      class="block-element"
                      v-for="(block, index) in blocks"
                      :key="index"
                  >
                    <v-card-title>
                      <v-icon>mdi-drag</v-icon>
                      {{ block.name }}
                      <span class="move-block">
										    <span v-if="index != 0" @click="moveupBlock(index)" class="moveup-block"
                              :title="$t('components.blocks-editor.moveup')"><v-icon>mdi-arrow-up-thin</v-icon></span>
										    <span v-if="index != blocks.length - 1" @click="movedownBlock(index)" class="movedown-block"
                              :title="$t('components.blocks-editor.movedown')"><v-icon>mdi-arrow-down-thin</v-icon></span>
									    </span>
                      <span @click="removeBlock(index)" class="remove-block"
                            :title="$t('components.blocks-editor.remove')"><v-icon>mdi-delete-outline</v-icon></span>
                    </v-card-title>
                    <v-card-text>

                      <div v-if="block.name == 'Text'">

                        <tiptap-vuetify
                            v-model="block.content"
                            data-cy="content"
                            :extensions="extensions"
                            :card-props="{ flat: true }"
                            @focus="editorFocused = true"
                            @blur="editorFocused = false"
                        />

                      </div>

                      <div v-if="block.name == 'Bild'">

                        <input
                            style="display:none;"
                            :ref="'input-' + index"
                            type="file"
                            :name="'image-' + index"
                            accept="image/*" @change="setBlockImage($event, index)"
                        />

                        <section v-show="block.showCropper" class="cropper-area">
                          <div class="img-cropper">
                            <vue-cropper
                                :ref="'cropper-' + index"
                                :src="block.content"
                                :preview="'.preview-' + index"
                                :zoomable="false"
                                :checkCrossOrigin="false"
                                :responsive="false"
                                :autoCropArea="1"
                            >
                            </vue-cropper>
                          </div>
                        </section>

                        <section v-show="!block.showCropper && block.cropImg" class="preview-area">
                          <div class="block-preview" :class="'preview-' + index">
                            <div class="cropped-image">
                              <img
                                  v-if="block.cropImg"
                                  :src="block.cropImg"
                                  alt="Cropped Image"
                              />
                            </div>
                          </div>
                        </section>

                        <div v-show="!block.showCropper" class="sp-float-right">
                          <v-btn
                              elevation="0"
                              color="primary"
                              outlined
                              class="icon-left"
                              @click="showBlockFileChooser(index)"
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('components.blocks-editor.blocks.image.upload') }}
                          </v-btn>
                        </div>

                        <div v-show="block.showCropper" class="sp-float-left">
                          <v-btn
                              elevation="0"
                              color="primary"
                              outlined
                              class="icon-left"
                              @click="resetBlockCropper(index)"
                          >
                            <v-icon>mdi-close</v-icon>
                            {{ $t('components.blocks-editor.blocks.image.cancel') }}
                          </v-btn>
                        </div>

                        <div v-show="block.showCropper" class="sp-float-right">
                          <v-btn
                              elevation="0"
                              color="primary"
                              outlined
                              class="icon-left"
                              @click="cropBlockImage(index)"
                          >
                            <v-icon>mdi-crop</v-icon>
                            {{ $t('components.blocks-editor.blocks.image.crop') }}
                          </v-btn>
                        </div>

                      </div>

                      <div v-if="block.name == 'Video'">

                        <input
                            style="display:none;"
                            :ref="'input-' + index"
                            type="file"
                            :name="'video-' + index"
                            accept="video/*" @change="setBlockVideo($event, index)"
                        />

                        <span v-if="block.video == '' && !block.sizeExceeded" class="video-size-hint">
                          <v-icon>mdi-information-outline</v-icon> {{
                            $t('components.blocks-editor.blocks.video.max-size')
                          }}: 32MB</span>
                        <span v-if="block.sizeExceeded" class="video-size-error">
                          <v-icon>mdi-alert-outline</v-icon> {{
                            $t('components.blocks-editor.blocks.video.size-exceeded')
                          }} (32MB)</span>

                        <div v-if="block.isUploading">
                          <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
                        </div>

                        <div v-if="block.video && !block.isUploading">

                          <div class="video-wrapper">
                            <video :src="tempVideoSrc(block.video)" controls></video>
                          </div>

                        </div>

                        <div v-show="true" class="sp-float-right">
                          <v-btn
                              elevation="0"
                              color="primary"
                              outlined
                              class="icon-left"
                              @click="showBlockVideoChooser(index)"
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('components.blocks-editor.blocks.video.upload') }}
                          </v-btn>
                        </div>

                      </div>

                      <div v-if="block.name == 'Youtube'">

                        <v-text-field
                            label="Youtube-ID*"
                            counter="11"
                            v-model="block.content"
                            data-cy="content"
                            filled
                            :rules="[
                              () => !!block.content || $t('validation.required.youtube-id'),
                              () => (block.content && block.content.length == 11) || $t('validation.length.youtube-id')
                            ]"
                            @keyup="(event) => block.content = getYoutubeId(event.target.value)"
                        ></v-text-field>

                      </div>

                    </v-card-text>
                  </v-card>

                </draggable>

                <v-menu
                    v-if="blocks.length > 2"
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        outlined
                        class="icon-left blocks-button blocks-bottom-button"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-plus</v-icon>
                      {{ $t('components.blocks-editor.add-content') }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                        v-for="(blockitem, index) in blocksList"
                        :key="index"
                        class="blocks-list-item"
                    >
                      <v-list-item-title
                          @click="addBlock(blockitem.name)"
                      >
                        <v-icon>{{ blockitem.icon }}</v-icon>
                        {{ blockitem.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                    v-if="blocks.length > 2"
                    color="primary"
                    outlined
                    class="icon-left phone-preview-bottom"
                    @click="phonePreview"
                >
                  <v-icon>mdi-cellphone-check</v-icon>
                  {{ $t('components.blocks-editor.preview') }}
                </v-btn>

              </v-card-text>
            </v-card>

          </v-col>

          <!-- Right Column -->
          <v-col class="meta-area" cols="12" sm="12" md="4">

            <!-- Kategorie -->
            <v-card
                elevation="0"
            >
              <v-card-title>{{ $t('components.category-select.label') + '*' }}</v-card-title>
              <v-card-text>

                <v-select
                    filled
                    :title="$t('components.category-select.select') + '*'"
                    :label="$t('components.category-select.select') + '*'"
                    v-model="data.categories"
                    data-cy="categories"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    :rules="[() => !!data.categories || $t('validation.required.category')]"
                ></v-select>

              </v-card-text>
            </v-card>

            <v-card
                elevation="0"
                class="sp-mar-top-3"
            >
              <v-card-title>{{ $t('components.post-push-settings.label') }}</v-card-title>
              <v-card-text>

                <v-radio-group v-model="pushStatus " data-cy="pushStatus">
                  <v-radio
                      key="manual"
                      :label="$t('components.post-push-settings.status.manual')"
                      value="manual"
                  ></v-radio>
                  <v-radio
                      key="automatic"
                      :label="$t('components.post-push-settings.status.auto')"
                      value="automatic"
                  ></v-radio>
                </v-radio-group>

              </v-card-text>
            </v-card>

            <v-card
                elevation="0"
                class="sp-mar-top-3"
            >
              <v-card-title>{{ $t('components.post-publish-settings.label') }}</v-card-title>
              <v-card-text>

                <v-radio-group v-model="publishStatus" data-cy="publishStatus">
                  <v-radio
                      key="onsave"
                      :label="$t('components.post-publish-settings.publish.onsave')"
                      value="onsave"
                  ></v-radio>
                  <v-radio
                      key="ondate"
                      :label="$t('components.post-publish-settings.publish.ondate')"
                      value="ondate"
                  ></v-radio>
                </v-radio-group>

                <div v-if="publishStatus === 'ondate'" class="radio-group-datepicker">

                  <app-date-time-picker
                      v-model="data.published_at"
                      data-cy="published_at"
                      date-format="dd.MM.yyyy"
                      :id="'publishedDate'"
                      :clearable="true"
                      :label="$t('components.post-publish-settings.publish-ondate')"
                  ></app-date-time-picker>
                  <span class="publish-error" v-if="!validPublishDate">{{
                      $t('validation.required.publish-date')
                    }}</span>

                </div>

              </v-card-text>
            </v-card>

            <v-card elevation="0" class="sp-mar-top-3">
              <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
              <v-card-text>
                <permission-group-selector
                    v-model="data.assigned_permission_groups"
                    :required="true"
                    mode="dropdown"/>

              </v-card-text>
            </v-card>

          </v-col>

        </v-row>

      </v-form>

    </v-container>

    <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        data-cy="snackbar"
        timeout="4000"
    >
      {{ errorMessage }}
    </v-snackbar>

    <v-dialog
        v-model="previewDialog"
        data-cy="previewDialog"
        width="414"
        persistent
        content-class="preview-dialog custom-class-dialog"
    >
      <v-card>
        <v-card-title>
          {{ $t('components.blocks-editor.preview') }}
        </v-card-title>
        <v-card-text>

          <div
              class="preview-element"
              v-for="(block, index) in blocks"
              :key="index"
          >

            <div v-if="block.name == 'Text'" v-html="block.content" class="text-block-preview"></div>

            <div v-if="block.name == 'Bild'" class="image-block-preview">

              <img :src="block.cropImg">

            </div>

            <div v-if="block.name == 'Video' && block.content != '' && !block.isUploading" class="video-block-preview">

              <video :src="tempVideoSrc(block.video)" controls></video>

            </div>

            <div v-if="block.name == 'Youtube'" class="youtube-block-preview">

              <div style="position:relative;overflow:hidden;padding-bottom:56.25%;margin:16px 0;">
                <iframe
                    :src="getYoutubeSrc(block.content)"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
                    style="position:absolute;width:100%;height:100%;"
                ></iframe>
              </div>

            </div>

          </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              elevation="0"
              class="icon-left"
              @click="previewDialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
            {{ $t('components.blocks-editor.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <cancel-button-dialog
                ref="cancelButtonDialog"
                :go-to-name="indexRouteName"
                :data-edited="dataEdited"
                @cancelEditConfirmed="getUserConfirmResponse"
            ></cancel-button-dialog>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                elevation="0"
                outlined
                class="icon-left"
                :loading="processing"
                @click="onSubmit(false)"
            >
              <v-icon>mdi-content-save</v-icon>
              {{ t('draft') }}
            </v-btn>
            <v-btn
                color="primary"
                elevation="0"
                class="icon-left"
                :loading="processing"
                @click="onSubmit(true)"
            >
              <v-icon>mdi-check</v-icon>
              <span v-if="publishStatus === 'onsave'">{{ t('publish-onsave') }}</span>
              <span v-if="publishStatus === 'ondate'">{{ t('publish-ondate') }}</span>
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import {EventBus} from './../event-bus'
import toolbar from '../components/layouts/Navigation.vue'
import moment from 'moment'
import AppDateTimePicker from '../components/vuetify/AppDateTimePicker'
import PermissionGroupSelector from "@/components/PermissionGroupSelector.vue"

import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
import draggable from 'vuedraggable'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
// dialog to confirm cancel if data edited
import CancelButtonDialog from "../components/layouts/CancelButtonDialog";

export default {
  name: 'JobsCreate',
  components: {
    CancelButtonDialog,
    toolbar,
    AppDateTimePicker,
    TiptapVuetify,
    draggable,
    VueCropper,
    PermissionGroupSelector
  },
  data() {
    return {
      dataLoaded: false,
      processing: false,
      data: {
        title: null,
        previewText: null,
        blocks: [],
        videos: [],
        public: false,
        published_at: null,
        categories: 1,
        assigned_permission_groups: [],
      },
      pushStatus: 'manual',
      publishStatus: 'onsave',
      categories: [1],
      category_id: null,
      error: null,
      errorMessage: this.$t("jobs.categories.detail.error"),
      cancelHTTP: null,
      errorCancel: null,
      validPublishDate: true,
      isSubmitted: false,
      snackbar: false,
      previewDialog: false,
      extensions: [
        History,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Paragraph,
        HardBreak
      ],
      blocks: [],
      editorFocused: false,
      hasBlockContent: false,
      dataEdited: false,
      cancelConfirmed: false,
      indexRouteName: 'Jobs'
    }
  },
  created() {
    this.getCategories()
  },
  computed: {
    blocksList() {
      return [
        {
          name: this.$t('components.blocks-editor.blocks.text.group-title'),
          icon: 'mdi-text-box-plus'
        },
        {
          name: this.$t('components.blocks-editor.blocks.image.group-title'),
          icon: 'mdi-file-image-plus-outline'
        },
        {
          name: this.$t('components.blocks-editor.blocks.video.group-title'),
          icon: 'mdi-video-plus-outline'
        },
        {
          name: this.$t('components.blocks-editor.blocks.youtube.group-title'),
          icon: 'mdi-youtube'
        }
      ]
    },
  },
  beforeRouteLeave(to, from, next) {
    // prompt if data edited and user leaves page via back browser button
    if (this.dataEdited && !this.cancelConfirmed) {
      this.$refs.cancelButtonDialog.cancel()
      next(false)
    } else {
      next()
    }
  },
  methods: {
    t: function (key) {
      return this.$t('jobs.detail.' + key);
    },
    // required for beforeRouteLeave hook
    getUserConfirmResponse(response) {
      this.cancelConfirmed = response
    },
    // Watch for changes if data edited
    fireWatcherForDataChange() {
      this.$watch(
          (vm) => [vm.data, vm.blocks, vm.publishStatus, vm.pushStatus],
          (newVal, oldVal) => {
            if (oldVal != undefined) {
              this.dataEdited = true
            }
          },
          {
            immediate: true,
            deep: true,
          }
      );
    },
    getCategories() {
      HTTP.get('jobs/categories?sortBy[]=name').then(function (response) {
        this.categories = response.data.data
        this.dataLoaded = true
        this.error = false
        this.fireWatcherForDataChange()
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    validateBlockContent() {
      if (this.blocks.length > 0) {
        this.blocks.forEach((block) => {
          if (block.name == 'Text' && block.content != '') {
            this.hasBlockContent = true
            return
          }
          if (block.name == 'Video' && block.content != '') {
            this.hasBlockContent = true
            return
          }
          if (block.name == 'Bild' && block.cropImg != '') {
            this.hasBlockContent = true
            return
          }
          if (block.name == 'Youtube' && block.content != '') {
            this.hasBlockContent = true
            return
          }
        })
      }
    },
    validatePublish(publish) {
      if (!publish) {
        return
      }
      if (this.publishStatus === 'ondate' && !this.data.published_at) {
        this.validPublishDate = false
      } else {
        this.validPublishDate = true
      }
    },
    addBlock(name) {
      switch (name) {
        case 'Bild':
          this.blocks.push({
            name: name,
            content: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAABAAEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9/KKKKAP/2Q==',
            cropImg: '',
            showCropper: false,
            order: null
          })
          break
        case 'Video':
          this.blocks.push({
            name: name,
            content: '',
            video: '',
            isUploading: false,
            order: null,
            sizeExceeded: false
          })
          break
        default:
          this.blocks.push({
            name: name,
            content: '',
            order: null
          })
      }
    },
    removeBlock(index) {
      this.blocks.splice(index, 1)
    },
    moveupBlock(index) {
      let temp = this.blocks[index]
      this.removeBlock(index)
      this.blocks.splice(index - 1, 0, temp)
    },
    movedownBlock(index) {
      let temp = this.blocks[index]
      this.removeBlock(index)
      this.blocks.splice(index + 1, 0, temp)
    },
    showBlockFileChooser(index) {
      this.$refs['input-' + index][0].click()
    },
    showBlockVideoChooser(index) {
      this.$refs['input-' + index][0].click()
    },
    setBlockImage(e, index) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Bitte ein Bild auswählen')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.blocks[index].content = event.target.result
          this.$refs['cropper-' + index][0].replace(event.target.result)
          this.blocks[index].showCropper = true
          this.$refs['input-' + index][0].value = null
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    setBlockVideo(e, index) {
      this.blocks[index].isUploading = true
      const video = e.target.files[0]
      if (video.type.indexOf('video/') === -1) {
        alert('Bitte eine Videodatei auswählen')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          let sizeExceeded = video.size > 32768000
          this.blocks[index].sizeExceeded = sizeExceeded
          if (!sizeExceeded) {
            this.blocks[index].content = video.name
            this.blocks[index].video = video
          }
          this.blocks[index].isUploading = false
          this.$refs['input-' + index][0].value = null
        }
        reader.readAsDataURL(video)
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    cropBlockImage(index) {
      this.blocks[index].showCropper = false
      this.blocks[index].content = this.$refs['cropper-' + index][0].getCroppedCanvas().toDataURL()
      this.blocks[index].cropImg = this.blocks[index].content
      this.refreshBlock(index)
    },
    resetBlockCropper(index) {
      this.$refs['cropper-' + index][0].replace(this.blocks[index].cropImg)
      this.blocks[index].content = this.blocks[index].cropImg
      this.$refs['input-' + index][0].value = null
      this.blocks[index].showCropper = false
      this.refreshBlock(index)
    },
    refreshBlock(index) {
      this.$nextTick(() => {
        let temp = this.blocks[index]
        this.removeBlock(index)
        this.$nextTick(() => {
          this.blocks.splice(index, 0, temp)
        })
      })
    },
    tempVideoSrc(video) {
      if (this.editorFocused) {
        return
      }
      return URL.createObjectURL(video)
    },
    phonePreview() {
      this.previewDialog = true
    },
    getYoutubeSrc(youtubeID) {
      return 'https://www.youtube.com/embed/' + youtubeID + '?rel=0'
    },
    getYoutubeId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      if (url.length > 11 && match) {
        return match && match[2].length === 11 ? match[2] : undefined;
      }
      return url
    },
    onSubmit(publish) {

      this.isSubmitted = true

      this.validateBlockContent()
      this.validatePublish(publish)

      if (this.$refs.form.validate() && this.hasBlockContent && this.validPublishDate) {

        if (this.processing === true) {
          return
        }

        this.processing = true
        this.dataLoaded = false

        if (publish && this.publishStatus === 'onsave') {
          this.data.published_at = moment().milliseconds(0).toISOString()
        }

        if (!publish) {
          this.data.published_at = null
        }

        let formData = new FormData()

        this.data.videos = []
        this.blocks.forEach((block, index) => {
          block.order = index
          if (block.name == 'Text' && block.content != '') {
            this.data.blocks.push(block)
          }
          if (block.name == 'Bild' && block.cropImg != '') {
            this.data.blocks.push(block)
          }
          if (block.name == 'Youtube' && block.content != '') {
            this.data.blocks.push(block)
          }
          if (block.name == 'Video' && block.content != '') {
            this.data.blocks.push({
              name: 'Video',
              order: index
            })
            formData.append('video-' + index, block.video)
            this.data.videos.push(index)
          }
        })

        formData.append('data', JSON.stringify(this.data))

        const config = {
          headers: {'content-type': 'multipart/form-data'}
        }

        HTTP.post('jobs', formData, config)
            .then(function (response) {
              this.dataEdited = false
              let tempPublishDate = this.data.published_at

              if (publish) {

                // send push if 'automatic' and publish 'onsave'
                if (this.pushStatus === 'automatic' && this.publishStatus === 'onsave') {

                  const config = {
                    headers: {'content-type': 'application/json'}
                  }

                  var pushData = {}
                  pushData.id = response.data.id
                  pushData.type = 'jobs'
                  pushData.source = 'request'

                  HTTP.post('push', pushData, config)
                      .then(function (response) {
                        this.$router.push({name: this.indexRouteName})
                      }.bind(this))
                      .catch(function (error) {
                        console.log(error)
                        if (error.response.data.errors) {
                          this.message = error.response.data.message
                          this.openSnackbar()
                        }
                      }.bind(this))

                  // add push cronjob if 'automatic' and publish 'ondate'
                } else if (this.pushStatus === 'automatic' && this.publishStatus === 'ondate') {

                  const config = {
                    headers: {'content-type': 'application/json'}
                  }

                  var cronData = {}
                  cronData.id = response.data.id
                  cronData.type = 'jobs'
                  cronData.publish_date = tempPublishDate

                  HTTP.post('cron', cronData, config)
                      .then(function (response) {
                        this.$router.push({name: this.indexRouteName})
                      }.bind(this))
                      .catch(function (error) {
                        console.log(error)
                        if (error.response.data.errors) {
                          this.message = error.response.data.message
                          this.openSnackbar()
                        }
                      }.bind(this))

                }

                this.$router.push({name: this.indexRouteName})

                // push 'manual'
              } else {

                this.$router.push({name: this.indexRouteName})

              }

            }.bind(this))
            .catch(function (error) {
              this.processing = false
              if (error.response.data.errors) {
                this.message = error.response.data.message
                this.openSnackbar()
              }
            }.bind(this))
      }
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 2000)
    }
  }
}
</script>

<style lang="scss">

/* Temp */
.menubar-wrap.fixed {
  position: static !important;
  width: 100%;
  padding: 0;
}

</style>

<style scoped lang="scss">
.radio-group-datepicker {
  margin-top: 16px;
}

.cropper-area {
  margin-bottom: 20px;
}

.preview-area {
  width: 50%;
  max-width: 400px;
  margin-bottom: 20px;
}

.preview {
  width: 100% !important;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  border: 1px solid rgba(227, 227, 227, 0.7);
}

.block-preview {
  overflow: hidden;
  border: 1px solid rgba(227, 227, 227, 0.7);
}

.preview img {
  width: 100% !important;
  height: 100% !important;
}

.cropped-image img {
  max-width: 100%;
}

.publish-error {
  display: inline-block;
  font-size: 12px;
  color: #FF5252;
}

.blocks-bottom-button,
.phone-preview-bottom {
  margin-top: 16px;
}

.blocks-list-item {
  cursor: pointer;
  padding: 0px 10px;

  i {
    margin-right: 8px;
  }

  &:hover {
    background: #F5F5F5;
  }
}

.block-element {
  margin-top: 16px;

  .v-card__title {
    cursor: grab;
    padding-right: 72px;
    background: #F5F5F5;
    border-bottom: 1px solid #E0E0E0;
  }
}

.remove-block {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 56px;
  height: 56px;
  padding: 16px;
  border-left: 1px solid #E0E0E0;
  transition: background 0.3s;

  &:hover {
    background: #FFFFFF;
  }
}

.move-block {
  position: absolute;
  right: 56px;
  top: 0;
  width: 112px;
  height: 56px;
  text-align: right;

  & > span {
    cursor: pointer;
    display: inline-block;
    width: 56px;
    height: 56px;
    padding: 16px;
    border-left: 1px solid #E0E0E0;
    transition: background 0.3s;

    &:hover {
      background: #FFFFFF;
    }
  }
}

.video-wrapper {
  height: 240px;
  margin-bottom: 16px;

  video {
    height: 240px;
  }
}

.video-size-hint,
.video-size-error {
  line-height: 36px;
}

.video-size-error {
  color: var(--v-error-base);

  i {
    color: var(--v-error-base);
  }
}

.phone-preview,
.phone-preview-bottom {
  float: right;
}

.preview-dialog {

  .v-card__text {
    padding: 16px !important;

    &.has-preview-header {
      padding: calc(56.25% + 16px) 16px 16px 16px !important;

      .preview-page-header {
        position: absolute;
        top: 0;
        left: -1px;
        width: calc(100% + 2px);

        img {
          min-width: 100%;
          border-bottom: 1px solid #F5F5F5;
        }
      }
    }
  }

  .preview-element {
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .video-block-preview video {
    width: 100%;
  }

  .text-block-preview {
    ::v-deep p {
      margin-bottom: 0.5em;
    }
  }
}

.has-no-content {
  color: #FF5252 !important;
  height: 20px;
  font-size: 12px;
  margin-top: 12px;
}
</style>
