import store from "store";
import moment from "moment/moment";

export default {

  hasValidToken() {
    let idToken = store.get('id_token')
    if (!idToken) {
      return false
    }

    let idTokenExpiry = store.get('id_token_expires')
    if (!idTokenExpiry) {
      return false
    }

    let expiry = store.get('id_token_expires')
    let validFor = moment(expiry).diff(moment(), 'seconds')
    return validFor > 100
  }
}
