import UsersIndex from "../../views/UsersIndex";
import UsersEdit from "../../views/UsersEdit";
import UsersCreate from "@/views/UsersCreate";

const usersRoutes = [
  {
    path: '/users',
    name: 'Users',
    component: UsersIndex,
    meta: {
      accessConfig: {
        moduleName: null,
        requiresAuth: true
      }
    }
  },
  {
    path: '/users/create',
    name: 'UsersCreate',
    component: UsersCreate,
    meta: {
      accessConfig: {
        moduleName: null,
        requiresAuth: true
      }
    }
  },
  {
    path: '/users/:id',
    name: 'UsersEdit',
    component: UsersEdit,
    meta: {
      accessConfig: {
        moduleName: null,
        requiresAuth: true
      }
    }
  }
]

export default usersRoutes
