<template>
    <div class="filter-status-filter">
      <div>
        <v-select
            v-model="selectedFilterStatus"
            :label="$t('components.content-filter-timesheet-status.label')"
            :items="filterStatus"
            item-text="name"
            item-value="slug"
            multiple
            chips
        ></v-select>
      </div>
    </div>
  
  </template>
  
  <script>
  export default {
    name: "TimesheetStatusFilter",
    props: {
        value: {
            type: String,
            default: ''
        },
        filter : {
            type: String,
            default: 'filter-item'
        }
    },
    data() {
      return {
        selectedFilterStatus: this.value,
        filterStatus: [
          {
            name: '',
            slug: 'open'
          },
          {
            name: '',
            slug: 'approved'
          },
          {
            name: '',
            slug: 'rejected'
          },
          {
            name: '',
            slug: 'cleared'
          },
          {
            name: '',
            slug: 'exported'
          }
        ],
      }
    },
    watch: {
      selectedFilterStatus: function (val) {
        this.$emit('input', val);
      }
    },
    created() {
      let me = this;
      this.filterStatus.forEach(function (value) {
        value.name = me.$t('timesheet-approval.data.status.' + value.slug);
      });

    },
    mounted() {
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  