import GalleryIndex from "../../views/GalleryIndex";
import GalleryCreate from "../../views/GalleryCreate.vue";
import GalleryEdit from "../../views/GalleryEdit.vue";
import GalleryFolderCreate from "../../views/GalleryFolderCreate.vue";
import GalleryFolderEdit from "../../views/GalleryFolderEdit.vue";

const galleriesRoutes = [
    {
        path: '/galleries',
        name: 'GalleryIndex',
        component: GalleryIndex,
        meta: {
            accessConfig:  {
              moduleName: ['gallery'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/galleries/create-gallery/:parentFolderId?',
        name: 'GalleryCreate',
        component: GalleryCreate,
        meta: {
            accessConfig:  {
              moduleName: ['gallery'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/galleries/create-folder/:parentFolderId?',
        name: 'GalleryFolderCreate',
        component: GalleryFolderCreate,
        meta: {
            accessConfig:  {
              moduleName: ['gallery'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/galleries/gallery-edit/:galleryId?',
        name: 'GalleryEdit',
        component: GalleryEdit,
        meta: {
            accessConfig:  {
              moduleName: ['gallery'],
              requiresAuth: true
            }
        }
    },
    {
        path: '/galleries/folder-edit/:galleryFolderId?',
        name: 'GalleryFolderEdit',
        component: GalleryFolderEdit,
        meta: {
            accessConfig:  {
              moduleName: ['gallery'],
              requiresAuth: true
            }
        }
    },
]

export default galleriesRoutes
