<template>
  <div class="filter-item">
    <div class="filter-area-filter">
      <v-select
          v-model="selectedFilterArea"
          :label="$t('components.content-filter-servappt-area.label')"
          :items="filterAreas"
          item-text="name"
          item-value="id"
          multiple
          chips
      ></v-select>
    </div>
  </div>

</template>

<script>
import {HTTP} from "@/auth";

export default {
  name: "ServapptAreaFilter",
  props: ['value'],
  data() {
    return {
      selectedFilterArea: this.value,
      filterAreas: [],
    }
  },
  watch: {
    selectedFilterArea: function (val) {
      this.$emit('input', val);
    }
  },
  created() {
    let me = this;
    this.getItems()
  },
  methods: {
    getItems() {
      HTTP.get('servappt/areas').then(function (response) {
        this.filterAreas = response.data.data
      }.bind(this))
    }
  }
}
</script>

<style scoped>

</style>
