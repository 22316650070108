<template>
  <div class="sp-flex-col sp-pwreset-token">
    <div class="sp-flex-col sp-flex-1 sp-flex-v-center">
      <div class="sp-login-logo"></div>
    </div>
    <md-layout md-gutter>
      <md-layout md-gutter md-hide-xsmall md-flex="10"></md-layout>
      <md-layout md-gutter md-flex-xsmall="100" md-flex="80">
        <md-whiteframe :md-elevation="0" style="overflow: hidden; width: 100%;">
          <md-input-container :class="{ 'md-input-invalid': errors.invalid_username != '' }">
            <label>Benutzername*</label>
            <md-input type="email" v-model="credentials.username"></md-input>
            <span class="md-error">
              <span v-for="(item, index) in errors.invalid_username">{{ item }}</span>
            </span>
          </md-input-container>
          <md-input-container :class="{ 'md-input-invalid': errors.invalid_password }">
            <label>Passwort*</label>
            <md-input type="password" v-model="credentials.password"></md-input>
            <span class="md-error">
              <span v-for="(item, index) in errors.invalid_password">{{ item }} </span>
            </span>
          </md-input-container>
          <md-input-container :class="{ 'md-input-invalid': errors.invalid_password }">
            <label>Passwort bestätigen*</label>
            <md-input type="password" v-model="credentials.password_confirmation"></md-input>
            <span class="md-error">
              <span v-for="(item, index) in errors.invalid_password">{{ item }} </span>
            </span>
          </md-input-container>
          <md-button class="md-raised md-accent md-fullwidth"  @click.native="resetPassword()">Reset</md-button>
        </md-whiteframe>
      </md-layout>
    </md-layout>
    <md-snackbar ref="snackbar" :md-duration="4000" @close="removeErrors()">
      <span v-for="error in errors.validationError" v-if="errors.validationError.length">{{ error }}</span>
      <span v-if="errors.errorMessage.length">{{ errors.errorMessage }}</span>
    </md-snackbar>
  </div>
</template>

<script>
  import axios from 'axios'
  import auth from './../auth/index'
  import router from './../router'
  import {EventBus} from './../event-bus'
  let BASE_URL = process.env.VUE_APP_API_BASE_URL ?? ''

  export default {
    data () {
      return {
        data: '',
        credentials: {
          // email: '',
          username: '',
          password: '',
          password_confirmation: '',
          token: ''
        },
        errors: {
          invalid: false,
          errorMessage: '',
          validationError: '',
          unauthorized: false,
          invalid_password: '',
          invalid_username: ''
        }
      }
    },
    mounted () {
      this.credentials.token = this.$route.params.token
      EventBus.$on('login-error', function (msg) {
        this.errors.unauthorized = true
      }.bind(this))
      EventBus.$on('login-success', function (msg) {
        auth.user.errors.unauthorized = false
        this.checkAuth()
      }.bind(this))
    },
    methods: {
      removeErrors () {
        this.errors.errorMessage = ''
        this.errors.validationError = ''
      },
      checkAuth () {
        if (!auth.user.errors.unauthorized) {

          this.errors.errorMessage = 'Sie werden weitergeleitet.'

          setTimeout(() => {
            router.push(auth.user.redirect)
          }, 2000)

        } else {
          this.errors.unauthorized = auth.user.errors.unauthorized
        }
      },
      resetErrors () {
        this.errors.invalid_password = ''
        this.errors.invalid_username = ''
      },
      resetPassword () {

        this.resetErrors()

        axios
            .post(BASE_URL + '/password/reset', this.credentials)
            .then(function (response) {
              auth.login(this, this.credentials, '/')
              this.errors.errorMessage = response.data.message
              this.openSnackbar()
              this.errors.invalid = false
            }.bind(this))
            .catch(function (error) {
              if (error.response) {
                this.errors.invalid = true

                if(error.response.data.errors.password) {
                  this.errors.invalid_password = error.response.data.errors.password
                }
                if(error.response.data.errors.username) {
                  this.errors.invalid_username = error.response.data.errors.username
                }
              }

              if (!error.status) {
                this.error = true
                this.dataLoaded = false
              }
            }.bind(this))
      },
      openSnackbar () {
        let that = this
        setTimeout(() => {
          that.$refs.snackbar.open()
        }, 500)
      }
    }
  }
</script>


<style>
  .md-snackbar .md-snackbar-content {
    flex-direction: column;
  }

</style>
