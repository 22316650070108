<template>
    <div>
        <BasicNewContainer
            :title="t('title')"
            ref="editor"
            @cancel="cancel"
            :saveAction="saveItem"
            @saved="close"
            :has-changes="hasChanges">
            <template v-slot:default>
                <v-card elevation="0">
                    <v-card-title>{{ t('details-title') }}</v-card-title>
                    <v-card-text>
                        <content-filter-customer
                            :label="t('choose_client')"
                            class="mt-3"
                            v-model="data.customer_id"
                            data-cy="customer_id"
                            :get-search-url="getCustomerSearchUrl"
                            :rules="[
                                () => !!data.customer_id || $t('validation.required.client')
							]"
                        />
                        
                        <v-row>
                            <v-col>
                                <content-filter-user
                                    class="mt-3"
                                    v-model="data.colleague_id"
                                    data-cy="colleague_id"
                                    :label="t('employee')"
                                    :rules="[
                                        () => !!data.colleague_id || $t('validation.required.employee')
                                    ]"
                                />
                            </v-col>
                        </v-row>
                        
                        <v-textarea
                            :label="t('notes')"
                            counter="1000"
                            class="mt-3"
                            v-model="data.notes"
                            data-cy="notes"
                            filled
                            hide-details="auto"
                            :rules="[
								() => !!data.notes || $t('validation.required.notes'),
                                () => (!data.notes || (data.notes && data.notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="mt-5">
                    <v-card-title>{{ t('picture-title') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <picture-upload-multi v-model="data.picture" ></picture-upload-multi>
                        </div>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('qm-title') }}</v-card-title>
                    <v-card-text>
                        <div class="custom-label ">{{ t('qm-enabled') }}</div>

                        <v-switch
                            class="mt-2"
                            v-model="data.qm"
                            data-cy="qm"
                            inset
                            :label="data.qm ? t('yes') : t('no')"
                        ></v-switch>
                            
                        <v-textarea
                            v-if="data.qm"
                            :label="t('qm_note')"
                            counter="1000"
                            class="mt-3"
                            v-model="data.qm_note"
                            data-cy="qm_note"
                            filled
                            :rules="[
                                () => (!data.qm_note || (data.qm_note && data.qm_note.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('special-cleaning-title') }}</v-card-title>
                    <v-card-text>

                        <div class="custom-label ">{{ t('special-cleaning-enabled') }}</div>
                        <v-switch
                            class="mt-2"
                            v-model="data.special_cleaning"
                            data-cy="special_cleaning"
                            inset
                            :label="data.special_cleaning ? t('yes') : t('no')"
                        ></v-switch>
                            
                        <v-textarea
                            v-if="data.special_cleaning"
                            :label="t('qm_note')"
                            counter="1000"
                            class="mt-3"
                            v-model="data.special_cleaning_notes"
                            data-cy="special_cleaning_notes"
                            filled
                            :rules="[
                                () => (!data.special_cleaning_notes || (data.special_cleaning_notes && data.special_cleaning_notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('proposal-title') }}</v-card-title>
                    <v-card-text>

                        <div class="custom-label ">{{ t('proposal-enabled') }}</div>
                        <v-switch
                            v-model="data.proposal"
                            data-cy="proposal"
                            inset
                            :label="data.proposal ? t('yes') : t('no')"
                            class="mb-5 mt-2"
                        ></v-switch>

                        <v-select
                            v-if="data.proposal"
                            :items="selectItems"
                            v-model="data.proposal_approved"
                            data-cy="proposal_approved"
                            item-text="label"
                            item-value="id"
                            :label="t('proposal-approved')"
                        ></v-select>

                        <v-textarea
                            v-if="data.proposal"
                            :label="t('proposal-notes')"
                            counter="1000"
                            class="mt-3"
                            v-model="data.proposal_notes"
                            data-cy="proposal_notes"
                            filled
                            :rules="[
                                () => (!data.proposal_notes || (data.proposal_notes && data.proposal_notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('leader-visitation-title') }}</v-card-title>
                    <v-card-text>
                        <div class="custom-label ">{{ t('leader-visitation-enabled') }}</div>

                        <v-switch
                            class="mt-2"
                            v-model="data.leader_visitation"
                            data-cy="leader_visitation"
                            inset
                            :label="data.leader_visitation ? t('yes') : t('no')"
                        ></v-switch>
                        
                        <v-row v-if="data.leader_visitation" class="mt-2">
                            <v-col>
                                <app-filter-date-picker
                                    :filterLabel="t('date')"
                                    v-model="data.leader_visitation_date"
                                    data-cy="leader_visitation_date"
                                    id="dateStart"
                                    :clearable="true"
                                ></app-filter-date-picker>
                            </v-col>
                            <v-col>
                                <content-filter-user
                                    :preselected="data.leader_id"
                                    v-model="data.leader_id"
                                    data-cy="leader_id"
                                    :label="t('leader')"
                                />
                            </v-col>
                        </v-row>
                            
                        <v-textarea
                            v-if="data.leader_visitation"
                            :label="t('leader_notes')"
                            counter="1000"
                            class="mt-3"
                            v-model="data.leader_notes"
                            data-cy="leader_notes"
                            filled
                            :rules="[
                                () => (!data.leader_notes || (data.leader_notes && data.leader_notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('client-communication-title') }}</v-card-title>
                    <v-card-text>

                        <div class="custom-label ">{{ t('client-communication-enabled') }}</div>
                        <v-switch
                            class="mt-2"
                            v-model="data.client_communication"
                            data-cy="client_communication"
                            inset
                            :label="data.client_communication ? t('yes') : t('no')"
                        ></v-switch>
                        
                        <v-row v-if="data.client_communication" class="mt-2">
                            <v-col>
                                <app-filter-date-picker
                                    :filterLabel="t('date')"
                                    v-model="data.client_communication_date"
                                    data-cy="client_communication_date"
                                    id="dateStart"
                                    :clearable="true"
                                ></app-filter-date-picker>
                            </v-col>
                            <v-col>
                                <content-filter-user
                                    :preselected="data.client_communication_user_id"
                                    v-model="data.client_communication_user_id"
                                    data-cy="client_communication_user_id"
                                    :label="t('as')"
                                />
                            </v-col>
                        </v-row>

                        <v-text-field
                            v-if="data.client_communication"
                            :label="t('contact_person')"
                            v-model="data.contact_person"
                            data-cy="contact_person"
                            counter=""
                            filled
                        ></v-text-field>
                            
                        <v-textarea
                            v-if="data.client_communication"
                            :label="t('leader_notes')"
                            counter="1000"
                            class="mt-3"
                            v-model="data.contact_person_notes"
                            data-cy="contact_person_notes"
                            filled
                            :rules="[
                                () => (!data.contact_person_notes || (data.contact_person_notes && data.contact_person_notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('offer-title') }}</v-card-title>
                    <v-card-text>

                        <div class="custom-label ">{{ t('offer-enabled') }}</div>
                        <v-switch
                            class="mt-2"
                            v-model="data.offer_created"
                            data-cy="offer_created"
                            inset
                            :label="data.offer_created ? t('yes') : t('no')"
                        ></v-switch>

                        <v-text-field
                            v-if="data.offer_created"
                            class="mt-5 mb-0 pb-0"
                            :label="t('offer-number')"
                            v-model="data.offer_number"
                            data-cy="offer_number"
                            counter=""
                            filled
                        ></v-text-field>

                        <div class="mt-3 custom-label ">{{ t('surface-enabled') }}</div>
                        <v-switch
                            v-model="data.surface_created"
                            data-cy="surface_created"
                            inset
                            :label="data.surface_created ? t('yes') : t('no')"
                        ></v-switch>

                        <picture-upload-multi
                            v-if="data.surface_created"
                            class="mt-5"
                            v-model="data.sample_area_picture">
                        </picture-upload-multi>

                        <app-filter-date-picker
                            v-if="data.surface_created"
                            class="mt-5"
                            :filterLabel="t('date')"
                            v-model="data.offer_date"
                            data-cy="offer_date"
                            id="dateStart"
                            :clearable="true"
                        ></app-filter-date-picker>

                        <v-textarea
                            v-if="data.surface_created"
                            :label="t('offer-notes')"
                            counter="1000"
                            v-model="data.offer_notes"
                            data-cy="offer_notes"
                            filled
                            :rules="[
                                () => (!data.offer_notes || (data.offer_notes && data.offer_notes.length <= 1000)) || t('validation.error-max-length')
							]"
                        ></v-textarea>
                    </v-card-text>
                </v-card>

            </template>

            <template v-slot:meta>
                <v-card elevation="0">
                    <v-card-title>{{ t('date-title') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <app-filter-date-picker
                                :filterLabel="t('note-date-choose')"
                                v-model="data.note_date"
                                data-cy="note_date"
                                id="dateStart"
                                :clearable="true"
                            ></app-filter-date-picker>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('status') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <customer-notes-status-filter v-model="data.status" data-cy="status"/>
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </BasicNewContainer>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicNewContainer from '@/components/inputs/BasicNewContainer'
import UserMultiSelector from '@/components/UserMultiSelector'
import PictureUpload from '@/components/inputs/PictureUpload'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import CustomerNotesStatusFilter from '@/components/filter-components/CustomerNotesStatusFilter'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import ContentFilterUser from '@/components/filter-components/ContentFilterUser'
import ContentFilterCustomer from '@/components/filter-components/ContentFilterCustomer'
import PictureUploadMulti from '@/components/inputs/PictureUploadMulti'

export default {
    name: 'CustomerNotesCreate',
    components: {
        PictureUpload,
        UserMultiSelector,
        BasicNewContainer,
        AppFilterDatePicker,
        CustomerNotesStatusFilter,
        ContentFilterEmployee,
        ContentFilterUser,
        ContentFilterCustomer,
        PictureUploadMulti
    },
    data() {
        return {
            data: {
                customer_id: null,
                colleague_id: null,
                notes: '',
                picture: '',
                sample_area_picture: '',
                note_date: new Date().toISOString().split('T')[0],
                status: '',
                qm: false,
                qm_note : '',
                special_cleaning: false,
                special_cleaning_notes: '',
                proposal: false,
                proposal_approved: false,
                proposal_notes: '',
                leader_visitation: false,
                leader_visitation_date: '',
                leader_id: {},
                leader_notes: '',
                client_communication: false,
                client_communication_date: '',
                client_communication_user_id: {},
                contact_person: '',
                contact_person_notes: '',
                offer_created: false,
                offer_number: '',
                surface_created: false,
                offer_date: '',
                offer_notes: ''
            },
            dataOriginal: null,
            selectItems: [
                { id: 0, label: this.t('no') },
                { id: 1, label: this.t('yes') }
            ]
        }
    },
    props: {
        itemId: null
    },
    mounted() {
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
        }
    },
    methods: {     
        getPreviewUrl(file) {
          return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve(reader.result);
              };
              reader.onerror = (error) => {
                reject(error);
              };
              reader.readAsDataURL(file);
          })
        },
        t: function (key) {
            return this.$t('customer_notes.create.' + key)
        },
        async saveItem() {
        
            let data = Object.assign({}, this.data);
            
            if (data.client_communication_user_id != null) {
                data.client_communication_user_id = data.client_communication_user_id.id
            }

            if (data.colleague_id != null) {
                data.colleague_id = data.colleague_id.id
            }

            if (data.customer_id != null) {
                data.customer_id = data.customer_id.id
            }

            if (data.leader_id != null) {
                data.leader_id = data.leader_id.id
            }

            data.qm = data.qm ? 1 : 0
            data.special_cleaning = data.special_cleaning ? 1 : 0
            data.proposal = data.proposal ? 1 : 0
            data.leader_visitation = data.leader_visitation ? 1 : 0
            data.client_communication = data.client_communication ? 1 : 0
            data.offer_created = data.offer_created ? 1 : 0
            data.surface_created = data.surface_created ? 1 : 0

            await HTTP.post('/customer_notes', data)
        },
        close() {
            this.cancel()
        },
        cancel() {
            this.$router.push({ name: 'CustomerNotesIndex' })
        },
        getCustomerSearchUrl(val) {
            return 'tasks/customers?q=' + val
        }
    }
}
</script>
