<template>
    <div>
        <li :class="[isFolder ? 'folder' : 'file', hasSubFolders ? 'has-subfolders' : '', isFocused ? 'focused' : '', fileChosen ? 'chosen' : '']" :id="'treeItem'+model.id" :ref="'treeItem'+model.id">

            <label
                    :class="{'open': open}"
                    @click="toggle(model)"
                    @dblclick="changeType">

                <md-icon v-if="!isFolder" class="material-icons-outlined">text_snippet</md-icon>
                <md-icon v-if="!open && isFolder">folder</md-icon>
                <md-icon v-if="open && isFolder">folder_open</md-icon>
                <p v-if="isFolder">{{ model.name }}</p>
                <p v-if="!isFolder">{{ model.title }}</p>

            </label>
            <ul v-show="open" v-if="isFolder" :class="{'open': open}">
                <file-tree-list-item
                        v-for="(model, index) in model.children"
                        :key="model.id"
                        :model="model"
                        :preselectedItems="preselectedItems"
                        @item-selected="onItemSelected"
                        @item-unselected="onItemUnselected"/>
            </ul>
        </li>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    emits: [
        'item-selected',
        'item-unselected',
    ],
    props: {
        model: Object,
        preselectedItems: Array
    },

    data: function () {
        return {
            open: false,
            isFocused: false,
            fileChosen: false
        };
    },
    created() {

    },
    mounted() {
        this.checkAndSetPreselected()
    },
    computed: {
        isFolder: function () {
            return !this.model.document_id
        },
        hasSubFolders: function () {
            return this.model.children && this.model.children.length;
        }
    },
    watch: {
        preselectedItems(val) {
            this.checkAndSetPreselected()
        }
    },
    methods: {
        checkAndSetPreselected: function () {
            if (this.model.document_id) {
                let that = this
                let found = this.preselectedItems.some(function (el) {
                    return el.document_id === that.model.document_id
                });

                if (found) {
                    this.fileChosen = true
                }
            }
        },
        toggle: function (item) {
            if (this.isFolder) { // folder clicked
                this.open = !this.open;
                this.isFocused = true
            } else { // file clicked
                if (!this.fileChosen) {
                    this.$emit('item-selected', item);
                    this.fileChosen = true
                } else {
                    this.$emit('item-unselected', item);
                    this.fileChosen = false
                }
            }
        },
        changeType: function () {
            if (!this.isFolder) {
                Vue.set(this.model, "children", []);
                this.addChild();
                this.open = true;
            }
        },
        onItemSelected(item) {
            this.$emit('item-selected', item);
        },
        onItemUnselected(item) {
            this.$emit('item-unselected', item);
        },
    }

}
</script>
