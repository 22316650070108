import store from "store";

function isModuleLicensed(module) {
    let system_modules = store.get('modules')
    return system_modules.some(mod => mod.module_name === module)
}

function hasModule(module) {
    let system_modules = store.get('modules')
    let activeModules = store.get('userModules')

    let moduleEnabled = false;

    if (activeModules != null) {
        activeModules.forEach(item => {
            if (item.active === true && item.module_name == module) {
                system_modules.forEach(system_module => {

                    if (system_module.active === true && system_module.module_name == module) {
                        moduleEnabled = true;
                    }
                });
            }
        });
    }

    return moduleEnabled;
}

export {hasModule, isModuleLicensed}