import Moment from 'moment'
import _ from 'lodash'
import moment from "moment/moment";

const mixin = {
    data() {
        return {
            datetime: {}
        }
    },

    methods: {
        initDateTime(datetime) {
            if (!_.isNull(datetime)) {
                this.datetime = new Moment(datetime)
                Moment.locale('de')
            }
        },

        formatDateTime(date) {
            const dateNotEmpty = !!date;
            if (dateNotEmpty) {
                // let offset = new Date().getTimezoneOffset(); console.log(offset);
                // const momentDate = Moment(date).subtract(offset, 'minutes');
                // return momentDate.format('DD.MM.YYYY') + ' / ' + momentDate.format('HH.mm') + ' Uhr'
                return Moment(date).format('DD.MM.YYYY') + ' / ' + Moment(date).format('HH:mm') + ' Uhr'
            }
            return null

        },

        formatDate(date) {
            const dateNotEmpty = !!date;
            if (dateNotEmpty) {
                return new Moment(date).format('DD.MM.YYYY')
            }
            return null
        },

        formatDay(date) {
            const dateNotEmpty = !!date;
            if (dateNotEmpty) {
                return new Moment(date).format('ddd')
            }
            return null
        },

        formatDateWithDay(date) {
            const dateNotEmpty = !!date;
            if (dateNotEmpty) {
                return new Moment(date).format('ddd DD.MM.YYYY')
            }
            return null
        },

        formatDateWeek(date) {
            const dateNotEmpty = !!date;
            if (dateNotEmpty) {
                return new Moment(date).isoWeek()
            }
            return null
        },

        formatDateBack(date) {
            if (!_.isNull(date)) {
                date = new Moment(date)
                return date.format('YYYY-DD-MM')
            }
            return null
        },

        formatTime(date) {
            if (!_.isNull(date)) {
                date = new Moment(date)
                return date.format('HH:mm') + ' Uhr'
            }
            return null
        },

        formatTimeShort(date) {
            if (!_.isNull(date)) {
                date = new Moment(date)
                return date.format('HH:mm')
            }
            return null
        },
        formatTimeShortForTimepicker(date) {
            if (!_.isNull(date)) {
                date = new Moment(date)
                return date.format('HH:mm')
            }
            return null
        },
        formatDurationAsHours(seconds) {
            var minutes = Math.floor(seconds / 60)
            seconds = seconds % 60
            var hours = Math.floor(minutes / 60)
            minutes = minutes % 60

            var newMinutes = Math.floor((minutes / 60 + 0.005) * 100)
            return `${hours}.${this.pad(newMinutes)}`
        },
        formatSecondsAsHours: function (value) {
            const sec = parseInt(value, 10)
            let hours = Math.floor(sec / 3600)
            let minutes = Math.floor((sec - (hours * 3600)) / 60)
            let minutesTo100 = parseInt(100 * (minutes / 60), 10)
            if (minutesTo100 < 10) {
                minutesTo100 = '0' + minutesTo100
            }
            return hours + '.' + minutesTo100
        },
        pad(num) {
            return ('0' + num).slice(-2)
        },

        formatTimeChat(date) {
            if (!_.isNull(date)) {
                date = new Moment(date)
                return date.format('HH.mm')
            }
            return null
        },
        getCurrentDateIso() {
            return (new Date()).toISOString().substring(0, 10)
        }
    }
}

export default {
    install(Vue, options) {
        Vue.mixin(mixin)
        Moment.locale('de-ch')
    }
}
