<template>
  <div>
    <v-card>
      <v-card-title>
        {{ t('title') }}
      </v-card-title>
      <v-card-text
          class="d-flex"
      >
        <v-container>
          <v-row>
            <v-text-field filled :label="t('form.degree')" v-model="data.degree"/>
          </v-row>
          <v-row>
            <span>{{ t('form.valid-from') }}</span>
            <v-date-picker v-model="data.validFrom"/>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()"
               text
        >
          <v-icon>mdi-close</v-icon>
          {{ t('cancel') }}
        </v-btn>
        <v-btn @click="save()"
               color="primary"
               elevation="0"
        >
          <v-icon>mdi-check</v-icon>
          {{ t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TimeManagementBalanceEditEmploymentDegree",
  data() {
    return {
      data: {
        degree: 100,
        validFrom: new Date().toISOString().substr(0, 10),
      }
    }
  },
  methods: {
    t: function (key) {
      return this.$t('time-management.detail.employment-degree-booking.' + key);
    },
    cancel() {
      this.$emit('cancel');
    },
    save() {
      this.$emit('save', this.data);
    },
  }
}
</script>

<style scoped>

</style>
