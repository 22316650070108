import {HTTP} from './../auth'

const helpers = {
    data() {
        return {
            helper: {}
        }
    },
    methods: {
        downloadPdf(id, name) {
            HTTP.get('reports/pdf/' + id, {responseType: 'arraybuffer'}).then(function (response) {
                let headers = response.headers
                let blob = new Blob([response.data], {type: headers['content-type']})
                let link = document.createElement('a')
                link.style = 'display: none'
                link.href = window.URL.createObjectURL(blob)
                link.download = name
                document.body.appendChild(link)
                link.click()
                setTimeout(function () {
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(blob)
                }, 100)
            })
        },
        async downloadCsv(url, name) {
            let response = await HTTP.post(url, {responseType: 'arraybuffer'})
            let headers = response.headers
            let blob = new Blob([response.data], {type: headers['content-type']})
            let link = document.createElement('a')
            link.style = 'display: none'
            link.href = window.URL.createObjectURL(blob)
            link.download = name
            document.body.appendChild(link)
            link.click()
            setTimeout(function () {
                document.body.removeChild(link)
                window.URL.revokeObjectURL(blob)
            }, 100)

        },
        getWithNotificationTypes(data) {
            let items = []
            for (var i = 0, x = data.length; i < x; i++) {
                items[i] = data[i]
                if (data[i].notifications.length > 0) {
                    items[i].notifications_warning = data[i].notifications.filter(function (item) {
                        return item.type === 'warning'
                    })
                    items[i].notifications_requirement = data[i].notifications.filter(function (item) {
                        return item.type === 'requirement'
                    })
                } else {
                    items[i].notifications_warning = []
                    items[i].notifications_requirement = []
                }
            }
            return items
        },
        setNotificationsSelected(data, mode = 'default') {
            if (mode !== 'default') {
                let items = []
                for (var i in data) {
                    items[i] = data[i]
                    if (i !== 'elements' && items[i].notifications !== 0) {
                        items[i].notifications_warning = data[i].notifications.filter(function (item) {
                            return item.type === 'warning'
                        })
                        items[i].notifications_requirement = data[i].notifications.filter(function (item) {
                            return item.type === 'requirement'
                        })
                    }
                }
                return items
            } else {
                let items = []
                for (var j in data) {
                    items[i] = data[i]
                    if (j !== 'elements') {
                        items[j].notifications_warning = data[i].notifications_warning
                        items[j].notifications_requirement = data[i].notifications_requirement
                    }
                }
                return items
            }
        }
    }
}
export default {
    install(Vue, options) {
        Vue.mixin(helpers)
    }
}
