<template>
  <div>

    <toolbar :dataLoaded="true">{{ $t('privacy-policy.title') }}</toolbar>

    <v-container fluid class="settings">

      <loading :error="error" :dataLoaded="dataLoaded"></loading>

      <v-form ref="form">
        <div v-if="dataLoaded">

          <v-row class="settings-wrapper">

            <v-col cols="8">
              <v-card
                  elevation="0"
              >
                <v-card-title>{{ $t('privacy-policy.title') }}</v-card-title>
                <v-card-text>
                  <wysiwyg :isEditable="true" :hideButtons="['image', 'iframe']" :newsContent="data[0].value"></wysiwyg>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="4">
              <v-card elevation="0">
                <v-card-title>{{ $t('privacy-policy.public-url') }}
                  <v-btn plain @click="copyUrl">
                    <v-icon>mdi-content-copy</v-icon>
                    {{ $t('privacy-policy.copy') }}
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <a :href="externalUrl">{{ externalUrl }}</a>
                </v-card-text>
              </v-card>
            </v-col>

          </v-row>

        </div>
      </v-form>

    </v-container>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
                color="primary"
                text
                class="icon-left"
                :disabled="isSaving"
                @click="clear()"
            >
              <v-icon>mdi-reload</v-icon>
              {{ $t('privacy-policy.reset') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                primary
                class="icon-left"
                :loading="isSaving"
                :disabled="!hasChanges"
                @click="save()"
            >
              <v-icon>mdi-check</v-icon>
              {{ $t('privacy-policy.save') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus'
import toolbar from '@/components/layouts/Navigation.vue'
import loading from '@/components/layouts/Loading.vue'
import Wysiwyg from '@/components/wysiwyg/Wysiwyg.vue'
import store from 'store'

export default {
  name: 'SettingsPrivacyPolicy',
  components: {
    toolbar,
    loading,
    Wysiwyg
  },
  data() {
    return {
      data: {
        content: null,
      },
      newsContentJson: null,
      dataOriginal: {},
      dataLoaded: false,
      message: this.$t('privacy-policy.successful-saved'),
      messageSuccess: this.$t('privacy-policy.successful-saved-message'),
      error: null,
      errorMessage: this.$t('privacy-policy.error'),
      isSaving: false
    }
  },
  created() {
    EventBus.$on('update-content', function (dataJson, dataHTML) {
      this.newsContentJson = dataJson
      this.data[0].value = dataHTML
    }.bind(this))
    this.getSettings()
  },
  computed: {
    externalUrl() {
      let baseUrl = store.get('base_url')
      return baseUrl + '/privacy-policy'
    },
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    }
  },
  methods: {
    save() {
      this.isSaving = true
      for (var item in this.data) {
        HTTP.patch('settings/' + this.data[item].id, this.data[item]).then(function (response) {
          this.data = response.data
          this.dataOriginal = JSON.parse(JSON.stringify(this.data))
          this.message = this.messageSuccess
          this.isSaving = false
          this.$root.infoNotification.showMessage(this.message)
        }.bind(this)).catch(function (error) {
          if (error.response) {
            this.message = this.errorMessage
          }
          this.isSaving = false
          this.$root.infoNotification.showMessage(this.message)
        }.bind(this))
      }
    },
    clear() {
      this.dataLoaded = false
      this.data = []
      this.getSettings()
    },
    getSettings() {
      HTTP.get('settings?key=privacy_policy').then(function (response) {
        this.data = response.data
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
        this.dataLoaded = true
        this.error = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    async copyUrl() {
      await navigator.clipboard.writeText(this.externalUrl)
    }
  }
}
</script>