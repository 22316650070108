<template>
  <div class="has-hero-container">
    <div :class="{'hide-content' : showResult}">

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter">

          <v-slide-group
              show-arrows
          >

            <v-slide-item>

              <div>
                <div class="filter-item">
                  <app-filter-date-picker
                      :filterLabel="$t('components.app-filter-date-picker.label.startdate')"
                      :date="dateStart"
                      id="dateStart"
                  ></app-filter-date-picker>
                </div>
              </div>

            </v-slide-item>
            <v-slide-item>

              <div>
                <div class="filter-item">
                  <app-filter-date-picker
                      :filterLabel="$t('components.app-filter-date-picker.label.enddate')"
                      :date="dateEnd"
                      id="dateEnd"
                  ></app-filter-date-picker>
                </div>
              </div>

            </v-slide-item>

          </v-slide-group>

        </v-container>

      </div>

      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <v-btn
              elevation="0"
              color="primary"
              class="icon-right"
              :loading="isProcessing"
              :disabled="isProcessing"
              @click="generate()"
          >
            {{ t('report-export') }}
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>

      <v-snackbar
          ref="snackbar"
          v-model="snackbar"
          timeout="4000"
      >
        {{ message }}
      </v-snackbar>

    </div>

    <show v-if="showResult" :reportId="reportId"></show>

  </div>

</template>

<script>
import {HTTP} from './../auth'
import moment from 'moment'
import {EventBus} from './../event-bus.js'
import toolbar from '../components/layouts/Navigation.vue'
import AppFilterDatePicker from "../components/vuetify/AppFilterDatePicker"
import show from '../views/ReportsShow.vue'
import loading from '../components/layouts/Loading.vue'

export default {
  name: 'TimesheetMonthIndex',
  components: {
    toolbar,
    AppFilterDatePicker,
    show,
    loading
  },
  watch: {},
  data() {
    return {
      showResult: false,
      dateStart: moment().subtract(28, 'days').format('YYYY-MM-DD'),
      dateEnd: moment().format('YYYY-MM-DD'),
      message: null,
      setting: null,
      startDay: null,
      endDay: null,
      isProcessing: false,
      error: null,
      httpCancelHandler: null,
      snackbar: false
    }
  },
  mounted() {
    EventBus.$on('filter-date-changed', function (msg) {
      if (msg.id === 'dateStart') {
        this.dateStart = msg.newDate
      } else {
        this.dateEnd = msg.newDate
      }
      // this.filterChanged = true
    }.bind(this))
    EventBus.$on('http-error', function (msg) {
      this.message = msg
      this.isProcessing = false
      this.error = true
      this.openSnackbar()
    }.bind(this))
  },
  beforeDestroy() {
    EventBus.$off('http-error')
  },
  methods: {
    t: function (key) {
      return this.$t('timelog-salaryreport.' + key);
    },
    generate() {
      this.isProcessing = true
      var url = this.prepareUrl()

      HTTP.post('statistics/monthlylohnrapport/' + 'de' + url, '', {responseType: 'arraybuffer'}).then(function (response) {
        let name = 'Gesamtübersicht'
        let headers = response.headers
        let blob = new Blob([response.data], {type: headers['content-type']})
        let link = document.createElement('a')
        link.style = 'display: none'
        link.href = window.URL.createObjectURL(blob)
        link.download = 'TimeApp_' + moment().format('DDMMYYYY-HHmm') + '_' + name.replace(' ', '-') + '.xlsx'
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(blob)
        }, 100)
        this.isProcessing = false
      }.bind(this))
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 2000)
    },
    prepareUrl() {
      let startTimestamp = moment(this.dateStart).set('hour', 0).set('minute', 0).set('seconds', 0).format('YYYY-MM-DD HH:mm:ss')
      let endTimestamp = moment(this.dateEnd).set('hour', 23).set('minute', 59).set('seconds', 59).format('YYYY-MM-DD HH:mm:ss')
      return '?after=' + encodeURIComponent(startTimestamp) + '&before=' + encodeURIComponent(endTimestamp)
    }
  }
}
</script>

<style scoped>

.user-selected .md-icon {
  margin: 4px !important;
  color: #FFF !important;
}

.user-selected .md-icon.delete {
  margin-left: auto !important;
}

.user-selected .md-icon.delete:hover {
  cursor: pointer;
}

</style>

