<template>
  <div>
    <BasicCreateEditContainer
      :title="mode == 'create' ? t('create.title') : t('edit.title')"
      ref="editor"
      custom-class="checklist-element-chooser"
      object-type="Checklist"
      :object-name="checklist.name"
      :data-loaded="dataLoaded"
      @cancel="close"
      :save-action="saveChecklist"
      @saved="onSaved"
      :allow-delete="mode === 'edit'"
      :delete-action="deleteChecklist"
      @deleted="onDeleted"
      :has-changes="hasChanges"
      :hidden-field-validation-message="t('validation.hidden-field-validation-tasks')"
      :deletion-hint-text="deletionHintText"
      :deletion-hint-list="deletionHintList"
    >

      <template v-slot:fluid>

        <v-col cols="12" sm="12" md="4">

          <v-card elevation="0">       
            <v-card-title>{{ t('create.card-title') }}</v-card-title>
            <v-card-text>

              <v-text-field
                  :label="t('create.placeholder.name')"
                  v-model="checklist.name"
                  counter="120"
                  filled
                  :rules="[
                    () => !!checklist.name || t('validation.error-name-required'),
                    () => (checklist.name && checklist.name.length <= 120) || t('validation.error-max-name')
                  ]"
              ></v-text-field>

              <div class="mb-5">
                <content-filter-checklist-customer
                  v-if="dataLoaded"  
                  v-model="checklist.customer"
                  :label="t('create.filter.customer-search')"
                  :get-search-url="getCustomersSearchUrl"
                  clearable
                  append-icon="mdi-magnify"
                  :preselected="assignedCustomer"
                  :rules="[
                    () => !!checklist.customer || t('validation.error-name-required')
                  ]"								
                ></content-filter-checklist-customer>
              </div>

              <div class="mb-4">
                <v-select
                  filled
                  :label="$t('components.permissiongroup-select.label') "
                  :items="permissionGroups"
                  item-text="name"
                  item-value="id"
                  append-icon="mdi-chevron-down"
                  v-model="checklist.assigned_permission_groups"
                  multiple/>
              </div>

              <div class="mb-4">
                <content-filter-checklist-user
                  :label="t('create.filter.employee-search')"
                  clearable
                  append-icon="mdi-magnify"
                  @item-selected="assignEmployee"
                  @items-loaded="employeesLoaded"							
                ></content-filter-checklist-user>
                <v-list v-if="checklist.assigned_users.length > 0" lines="one" class="assigned-users-list">
                  <v-list-item
                      v-for="(user, index) in checklist.assigned_users"
                      class="employee-element passive"
                      :key="index"
                  >
                      <span class="ellipsis"><v-icon>mdi-account-lock-open</v-icon>{{ user.display_name }}</span>
                      <v-icon class="employee-action" color="primary" @click.native="removeEmployee(index)">mdi-minus-circle</v-icon>
                  </v-list-item>
                </v-list>
              </div>

              <v-divider></v-divider>

              <div class="filter-toggle mt-4">
                <div class="filter-item">
                  <h4>{{ t('create.blueprint.label') }}</h4>
                  <v-switch
                    v-model="checklist.is_template"
                    :label="checklist.is_template ? t('create.blueprint.yes') : t('create.blueprint.no')"
                    color="primary"
                    hide-details
                  ></v-switch>
                </div>
              </div>

              <v-text-field
                ref="hiddenValidation"
                class="hidden"
                v-model="noTasksValue"
                :rules="[
                  () => !!noTasksValue || t('validation.hidden-field-validation-tasks')
                ]"
              ></v-text-field>

            </v-card-text>
          </v-card>

          <v-card elevation="0" class="mt-6">       
            <v-card-title>{{ t('create.card-active-title') }}</v-card-title>
            <v-card-text>

              <div class="filter-toggle">
                <div class="filter-item">
                  <h4>{{ t('create.active.label') }}</h4>
                  <v-switch
                    v-model="checklist.active"
                    :label="checklist.active ? t('create.active.yes') : t('create.active.no')"
                    color="primary"
                    hide-details
                  ></v-switch>
                </div>
              </div>

            </v-card-text>
          </v-card>  

        </v-col>

        <v-col cols="12" sm="12" md="8">

          <v-card elevation="0">
            <v-card-title>{{ t('create.card-tasks-title') }}</v-card-title>
            <v-card-text>

              <ChecklistElementChooser 
                object-type="Task" 
                :subelements="checklist.elements" 
                :validate-subelements="!hasTasks">
              </ChecklistElementChooser>

            </v-card-text>
          </v-card>

        </v-col>

      </template>

    </BasicCreateEditContainer>
  </div>
</template>

<script>
  import { HTTP } from '@/auth'
  import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
  import ContentFilterChecklistCustomer from '@/components/filter-components/ContentFilterChecklistCustomer'
  import ContentFilterChecklistUser from '@/components/filter-components/ContentFilterChecklistUser'
  import ChecklistElementChooser from '@/components/inputs/ChecklistElementChooser'

  export default {
    name: 'ChecklistCreateEditChecklist',
    components: {
      BasicCreateEditContainer,
      ContentFilterChecklistCustomer,
      ContentFilterChecklistUser,
      ChecklistElementChooser
    },
    data () {
      return {
        checklist: {
          name: '',
          group: null,
          customer: null,
          category: null,
          elements: [],
          assigned_users: [],
          assigned_permission_groups: [],
          selectedPlace: {
            lat: null,
            lng: null,
            radius: 50
          },
          is_template: false,
          active: true
        },
        dataOriginal: null,
        mode: null,
        employeeList: [],
        dataLoaded: false,
        assignedCustomer: null,
        permissionGroups: null,
        deletionHintText: '',
        deletionHintList: []
      }
    },
    created() {
      this.checklist.id = this.$route.params.id
      this.mode = this.checklist.id ? 'edit' : 'create'
      this.getPermissionGroups()   
      if (this.mode === 'edit') {
        this.getChecklist()
      } else {
        this.dataOriginal = JSON.parse(JSON.stringify(this.checklist))
        if (this.$route.query.fromID && Number.isInteger(this.$route.query.fromID)) {
          this.getChecklist(this.$route.query.fromID)
        } else {
          this.dataLoaded = true
        }
      }    
    },
    computed: {
      hasChanges() {
        return JSON.stringify(this.checklist) !== JSON.stringify(this.dataOriginal)
      },
      hasTasks() {
        return this.checklist.elements.length !== 0
      },
      noTasksValue() {
        return this.hasTasks ? 'hasTasks' : null
      }
    },
    methods: {
      t: function (key) {
        return this.$t('checklists.' + key)
      },
      getPermissionGroups() {
        HTTP.get('permission-groups').then(function (response) {
          this.permissionGroups = response.data.data
        }.bind(this)).catch(function (error) {
          this.error = error
        }.bind(this))
      },
      getChecklist (checklistCopyId) {
        let checklistId = checklistCopyId ? checklistCopyId : this.checklist.id
        HTTP.get('checklists/' + checklistId).then(function (response) {
          this.checklist = response.data
          this.checklist.elements.forEach((task) => {
            this.$set(task, 'expanded', false)
            this.$set(task, 'rename', task.rename ? task.rename : task.name)
            task.subtasks.forEach((subtask) => {
              let found = this.checklist.assigned_subtasks.filter(as => 
                as.pivot.task_id == task.id && 
                as.pivot.position == task.pivot.position && 
                as.pivot.subtask_id == subtask.id
              )
              this.$set(subtask, 'active', found && found.length > 0)
            })
          })
          this.checklist.assigned_permission_groups = this.checklist.assigned_permission_groups.map((apm) => apm.permission_group_id)
          if (checklistCopyId) {
            this.$delete(this.checklist, 'id')
          } else {
            this.deletionHintText = this.getUsageInfo().deletionHintText
            this.deletionHintList = this.getUsageInfo().deletionHintList
            this.dataOriginal = JSON.parse(JSON.stringify(this.checklist))
          }
          this.assignedCustomer = this.checklist.customer
          this.dataLoaded = true
        }.bind(this)).catch(function (error) {
          if (error.response.status && error.response.status === 404) {
              this.$root.infoNotification.showMessage(this.$t('checklists.create.not-found', { checklistId: this.checklist.id }))
          } else {
            this.$root.infoNotification.showMessage(this.t('create.unknown-error') + error.response.statusText)
          }
          this.close()
        }.bind(this))
      },
      saveChecklist () {

        const json = JSON.stringify({
          checklistData: this.checklist
        })

        if (this.mode == 'create') {
          return HTTP.post('checklists', { 'data': json })
        } else {
          return HTTP.patch('checklists/' + this.checklist.id, { 'data': json })
        }
      },
      onSaved () {
        this.dataOriginal = null
        this.close()
      },
      deleteChecklist () {
        return HTTP.delete('checklists/' + this.checklist.id);
      },
      onDeleted () {
        this.dataOriginal = null
        this.close()
      },
      close() {
        this.$router.push({ name: 'Checklists' })
      },
      getCustomersSearchUrl (val) {
          return 'checklists/getCustomers?q=' + val
      },
      assignEmployee (employeeId) {
        if (employeeId) {
          let duplicateEmployees =  this.checklist.assigned_users.filter(x => x.id === employeeId)
          if (duplicateEmployees.length === 0) {
            this.checklist.assigned_users.push(this.employeeList.filter(x => x.id === employeeId)[0])
          } else {
            this.$root.infoNotification.showMessage(this.$t('checklists.create.validate_duplicate', { userName: duplicateEmployees[0].display_name}))
          }
        }
      },
      removeEmployee (index) {
        this.checklist.assigned_users.splice(index, 1)
      },
      employeesLoaded (employees)  {
        this.employeeList = employees
      },
      getUsageInfo () {
        let usages = this.checklist.reports_created
        return {
          deletionHintText: usages.length > 0 ? this.$t('checklists.create.deletion-hint', { checklistName: this.checklist.name }) : '',
          deletionHintList: this.checklist.reports_created.map((u) => u.name + ' (' + this.formatDateTime(u.updated_at) + ')')
        }
      },
    }
  }
</script>
