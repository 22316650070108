import SurveysIndex from "../../views/SurveysIndex";
import SurveysEdit from "../../views/SurveysEdit";
import SurveysCreate from '../../views/SurveysCreate.vue'
import SurveysResultsIndex from "../../views/SurveysResultsIndex";
import SurveysReport from '../../views/SurveysReport.vue'

const routesSurveys =
    [
        {
            path: '/surveys',
            name: 'Surveys',
            component: SurveysIndex,
            meta: {
                accessConfig: {
                    moduleName: ['surveys'],
                    requiresAuth: true
                }
            }
        },
		{
			path: '/surveys/create',
			name: 'SurveysCreate',
			component: SurveysCreate,
			meta: {
				accessConfig:  {
					moduleName: ['surveys'],
					requiresAuth: true
				}
			}
		},
		{
            path: '/surveys/results',
            name: 'SurveysResultsIndex',
            component: SurveysResultsIndex,
            meta: {
                accessConfig: {
                    moduleName: ['surveys'],
                    requiresAuth: true
                }
            }
        },
        {
            path: '/surveys/:id?',
            name: 'SurveysSingle',
            component: SurveysEdit,
            meta: {
                accessConfig: {
                    moduleName: ['surveys'],
                    requiresAuth: true
                }
            }
        },		
		{
            path: '/surveys/results/:id?',
            name: 'SurveysReportSingle',
            component: SurveysReport,
            meta: {
                accessConfig: {
                    moduleName: ['surveys'],
                    requiresAuth: true
                }
            }
        }
    ]

export default routesSurveys
