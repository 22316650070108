import InstructionsIndex from '@/views/InstructionsIndex'
import InstructionsCreateEditFolder from '@/views/InstructionsCreateEditFolder'
import InstructionsCreateEditDocument from '@/views/InstructionsCreateEditDocument'
import InstructionsCreateEditInstruction from '@/views/InstructionsCreateEditInstruction'

const instructionsRoutes = [
    {
        path: '/instructions/:folderId?',
        name: 'Instructions',
        component: InstructionsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['instructions'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/instructions/folder/create/:parent_id?',
        name: 'InstructionsFolderCreate',
        component: InstructionsCreateEditFolder
    },
    {
        path: '/instructions/folder/:id',
        name: 'InstructionsFolderEdit',
        component: InstructionsCreateEditFolder
    },
    {
        path: '/instructions/document/create/:parent_id?',
        name: 'InstructionsDocumentCreate',
        component: InstructionsCreateEditDocument
    },
    {
        path: '/instructions/document/:id',
        name: 'InstructionsDocumentEdit',
        component: InstructionsCreateEditDocument
    },
    {
        path: '/instructions/instruction/create/:parent_id?',
        name: 'InstructionsInstructionCreate',
        component: InstructionsCreateEditInstruction
    },
    {
        path: '/instructions/instruction/:id',
        name: 'InstructionsInstructionEdit',
        component: InstructionsCreateEditInstruction
    }
]

export default instructionsRoutes
