<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter();">

          <app-filter-apply-button
              v-if="filterStore.hasChanges"
              @click="applyFilter();"
          />

          <app-refresh-button
              v-if="!filterStore.hasChanges"
              @click="applyFilter();"
          />

          <v-slide-group show-arrows>
            <v-slide-item>
              <div>
                <div class="filter-item">
                  <v-text-field :label="t('year')" filled v-model="filterStore.filter.year"/>
                </div>
              </div>
            </v-slide-item>
            <v-slide-item>
              <div class="filter-item filter-search-list">
                <content-filter-employee
                    v-model="filterStore.filter.selectedUser"
                />
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-container>
      </div>
      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row>
          <v-data-table
              calculate-widths
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="totalItems"
              :loading="loading"
              class="elevation-0"
              :sort-by.sync="filterStore.table.sortBy"
              :sort-desc.sync="filterStore.table.sortDesc"
              :page.sync="filterStore.table.currentPage"
              :items-per-page.sync="filterStore.table.itemsPerPage"
              :footer-props="{
						showFirstLastPage: true,
						firstIcon: 'mdi-arrow-collapse-left',
						lastIcon: 'mdi-arrow-collapse-right',
						prevIcon: 'mdi-minus',
						nextIcon: 'mdi-plus',
						'items-per-page-options': [25, 50, 100, 200],
						pageText: '{0}-{1} von {2}',
						'items-per-page-text': $t('components.data-table.items-per-page')
					}"
          >
            <v-progress-linear v-show="loading" slot="progress" color="primary" indeterminate></v-progress-linear>

            <template v-slot:[`item.user`]="{ item }">
              <span class="ellipsis">{{ item.user_display_name }}</span>
            </template>

            <template v-slot:[`item.vacation_carry_over`]="{ item }">
              <span>{{ formatMinutesAsHours(item.vacation_carry_over_minutes) }}</span>
            </template>
            <template v-slot:[`item.vacation_credit`]="{ item }">
              <span>{{ formatMinutesAsHours(item.vacation_credit_minutes) }}</span>
            </template>
            <template v-slot:[`item.vacation_total_available`]="{ item }">
              <span>{{ formatMinutesAsHours(item.vacation_carry_over_minutes + item.vacation_credit_minutes) }}</span>
            </template>
            <template v-slot:[`item.vacation_used`]="{ item }">
              <span>{{ formatMinutesAsHours(item.vacation_used_minutes) }}</span>
            </template>
            <template v-slot:[`item.vacation_balance`]="{ item }">
              <span>{{
                  formatMinutesAsHours(item.vacation_carry_over_minutes + item.vacation_credit_minutes - item.vacation_used_minutes)
                }}</span>
            </template>

            <template v-slot:[`item.flextime_carry_over`]="{ item }">
              <span>{{ formatMinutesAsHours(item.flextime_carry_over_minutes) }}</span>
            </template>
            <template v-slot:[`item.flextime_bookings`]="{ item }">
              <span>{{ formatMinutesAsHours(item.flextime_bookings_minutes) }}</span>
            </template>
            <template v-slot:[`item.flextime_target`]="{ item }">
              <span>{{ formatMinutesAsHours(item.flextime_target_minutes) }}</span>
            </template>
            <template v-slot:[`item.flextime_actual`]="{ item }">
              <span>{{ formatMinutesAsHours(item.flextime_actual_minutes) }}</span>
            </template>
            <template v-slot:[`item.flextime_balance`]="{ item }">
              <span>{{
                  formatMinutesAsHours(item.flextime_carry_over_minutes + item.flextime_bookings_minutes + item.flextime_actual_minutes - item.flextime_target_minutes)
                }}</span>
            </template>

            <template v-slot:[`item.edit`]="{ item }">

              <router-link :to="{ path: '/time-management/balances/' + item.user_id }" class="edit-row-table">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>
          </v-data-table>

        </v-row>

      </v-container>

    </div>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import axios from 'axios'
import toolbar from '../components/layouts/Navigation.vue'
import AppFilterDatePicker from "../components/vuetify/AppFilterDatePicker"
import AppFilterSearchField from "../components/vuetify/AppFilterSearchField"
import edit from '../views/AbsencesEdit.vue'
import ContentFilterEmployee from "@/components/filter-components/ContentFilterEmployee";
import AppFilterApplyButton from "@/components/vuetify/AppFilterApplyButton";
import {useTimeManagementFilterStore} from "@/stores/TimeManagementFilterStore";
import AppRefreshButton from "../components/vuetify/AppRefreshButton.vue";


export default {
  name: 'TimelogSettingsTimeManagement',
  components: {
    AppRefreshButton,
    AppFilterApplyButton,
    ContentFilterEmployee,
    toolbar,
    AppFilterDatePicker,
    AppFilterSearchField,
    edit
  },
  setup() {
    const filterStore = useTimeManagementFilterStore()
    return {filterStore}
  },
  data() {
    return {
      loading: false,
      totalItems: 0,
      items: [],
      options: {},
      dataLoaded: false,
    }
  },
  async created() {
    await this.getDataFromApi()
  },
  computed: {
    headers() {
      return [

        {
          text: this.t('table.user'),
          value: 'user',
          sortable: true
        },
        {
          text: this.t('table.vacation_carry_over'),
          value: 'vacation_carry_over',
          align: 'right',
          width: 125,
          sortable: false,
        },
        {
          text: this.t('table.vacation_credit'),
          value: 'vacation_credit',
          align: 'right',
          width: 125,
          sortable: false,
        },
        {
          text: this.t('table.vacation_total_available'),
          value: 'vacation_total_available',
          align: 'right',
          width: 125,
          sortable: false,
        },
        {
          text: this.t('table.vacation_used'),
          value: 'vacation_used',
          align: 'right',
          width: 125,
          sortable: false,
        },
        {
          text: this.t('table.vacation_balance'),
          value: 'vacation_balance',
          align: 'right',
          width: 125,
          sortable: false,
        },
        {
          text: this.t('table.flextime_carry_over'),
          value: 'flextime_carry_over',
          align: 'right',
          width: 125,
          sortable: false,
        },
        {
          text: this.t('table.flextime_bookings'),
          value: 'flextime_bookings',
          align: 'right',
          width: 125,
          sortable: false,
        },
        {
          text: this.t('table.flextime_target'),
          value: 'flextime_target',
          align: 'right',
          width: 125,
          sortable: false,
        },
        {
          text: this.t('table.flextime_actual'),
          value: 'flextime_actual',
          align: 'right',
          width: 125,
          sortable: false,
        },
        {
          text: this.t('table.flextime_balance'),
          value: 'flextime_balance',
          align: 'right',
          width: 125,
          sortable: false,
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 150
        }
      ];
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  methods: {
    t: function (key) {
      return this.$t('time-management.balance.list.' + key);
    },
    formatMinutesAsHours(value) {
      return (value / 60).toFixed(2)
    },
    async reload() {
      this.absencesItems = []
      await this.getDataFromApi()
    },
    async applyFilter() {
      this.options.page = 1
      await this.reload()
      this.filterStore.filterApplied()
    },
    async getDataFromApi() {
      this.loading = true
      this.dataLoaded = false

      const {sortBy, sortDesc, page, itemsPerPage} = this.options

      let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

      let response = await HTTP.get('timelog/balances' + url)
      this.items = response.data.data
      this.totalItems = response.data.total
      this.loading = false
      this.dataLoaded = true
    },

    prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.filterStore.filter.year) {
        queryStringParts.push('year=' + this.filterStore.filter.year)
      }
      if (this.filterStore.filter.selectedUser) {
        queryStringParts.push('userid=' + this.filterStore.filter.selectedUser?.id)
      }
      if (sortBy) {
        queryStringParts.push('sortBy=' + (sortBy[0] ?? ''))
        let sort = sortDesc[0] ? 'desc' : 'asc'
        queryStringParts.push('sortDesc=' + sort)
      }
      if (page) {
        page = page ? page : 1
        queryStringParts.push('page=' + page)
      }
      if (itemsPerPage) {
        queryStringParts.push('itemsPerPage=' + itemsPerPage)
      }
      return '?' + queryStringParts.join('&')
    }
  }
}
</script>

<style scoped lang="scss">
.sp-app.v-application {
  .container {
    &--fluid {
      max-width: 2200px;
    }
  }
}

</style>
