<template>
    <div>
        <img :src="initialPicture">
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import { Buffer } from 'buffer'

export default {
    name: "ContentImageToBase64",
    props: {
        value: {},
        index: {},
    },
    data() {
        return {
            block: this.value,
            initialPicture: null,
            picture: null,
        }
    },
    watch: {
        picture(val) {
            this.block.new_content = val
        }
    },
    async created() {
        if (this.value.id) {
            let response = await HTTP.get('news/image/' + this.block.id, {responseType: 'arraybuffer'})
            this.initialPicture = 'data:image/jpeg;base64,' + new Buffer(response.data, 'binary').toString('base64')
        }
    }
}
</script>
