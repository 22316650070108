<template>
  <div class="locale-switcher">
    <v-select v-model="$i18n.locale"
              @change="updateLanguage()"
              :items="locales"
              item-text="item"
              item-value="value"
              :label="$t('language.language')"
              background-color="primary"
              color="#fff"
              :rounded="false"
              dense
              filled
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'LocaleSwitcher',
  data () {
    return {
      locales: [
        {
          item: 'Deutsch',
          value: 'de',
        },
        {
          item: 'Français',
          value: 'fr',
        },
        {
          item: 'English',
          value: 'en',
        },
      ]
    }
  },
  mounted() {
    if (localStorage.getItem("currentLocale")) {
      this.$i18n.locale = localStorage.getItem("currentLocale");
    } else {
      localStorage.setItem("currentLocale", this.$i18n.locale);
    }
  },
  methods: {
    updateLanguage() {
      localStorage.setItem("currentLocale", this.$i18n.locale);
    },
  }
}
</script>

<style scoped>
.v-application .locale-switcher >>> .v-text-field .v-input__slot {
  border-radius: 0px;
}
</style>
