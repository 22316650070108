<template>
  <div>
    <BasicEditContainer
        :title="t('create.title')"
        :data-loaded="dataLoaded"
        ref="editor"
        @cancel="close"
        :saveAction="saveItem"
        @saved="onSaved"
        :delete-action="deleteItem"
        :object-type="$t('servappt.list.title')"
        :object-name="'#' + this.data.id"
        :has-changes="hasChanges">
      <template>
        <v-card
            elevation="0">
          <v-card-title>{{ t('group-title') }}</v-card-title>
          <v-card-text>
            <v-date-picker
                v-model="eventDate"
                @click:date="addDate"
                multiple
                color="primary"
                full-width
            ></v-date-picker>

            <!--  :events="events" -->
            <v-row class="mt-2" v-if="!dataLoaded">
              <v-col cols="8">
                <v-skeleton-loader
                    type="list-item-two-line"
                ></v-skeleton-loader>
              </v-col>

              <v-col cols="4">
                <v-skeleton-loader
                    type="actions"
                ></v-skeleton-loader>
              </v-col>

            </v-row>
            <v-row v-for="item in this.data.request_dates" class="mt-2" v-if="dataLoaded">
              <v-col cols="5">
                <p class="subtitle-1 mt-4">{{ item.title }}</p>
              </v-col>
              <v-col cols="3">
                <v-checkbox :label=" t('selection-period.morning')" @click="checkIfEmpty(item)" v-model="item.morning">{{
                    t('selection-period.morning')
                  }}
                </v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox :label=" t('selection-period.afternoon')" @click="checkIfEmpty(item)" v-model="item.afternoon">
                  {{ t('selection-period.afternoon') }}
                </v-checkbox>
              </v-col>
              <v-col cols="1" class="text-right">
                <v-btn
                    width="24"
                    height="24"
                    class="mt-5"
                    elevation="0"
                    fab
                    dark
                    small
                    color="primary"
                    @click="removeDate(item)"
                >
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:meta>
        <!-- Status -->
        <v-card
            elevation="0"
        >
          <v-card-title>{{ t('status.label') }}</v-card-title>
          <v-card-text>
            <v-select
                filled
                :label="t('status.label')"
                :title="t('status.label')"
                v-model="data.state"
                :items="states"
                item-text="name"
                item-value="value"
            ></v-select>
          </v-card-text>
        </v-card>

        <!-- Area -->
        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('area.group-title') }}*</v-card-title>
          <v-card-text>
            <v-select
                filled
                :title="t('area.select') + '*'"
                :label="t('area.select') + '*'"
                v-model="data.area"
                :items="areas"
                item-text="name"
                item-value="id"
                :rules="[v => !!v || $t('validation.required.area')]"
            ></v-select>
          </v-card-text>
        </v-card>

        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('phone.group-title') }}*</v-card-title>
          <v-card-text>
            <v-text-field v-model="data.phone" filled :label="t('phone.phone-number')"
                          :rules="[v => !!v || $t('validation.required.phone')]"
                          :placeholder="t('phone.placeholder')"/>
          </v-card-text>
        </v-card>

        <!-- Selection period -->
        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('selection-period.group-title') }}*</v-card-title>
          <v-card-text>
            <app-date-time-picker
                :label="t('selection-period.begin_date')"
                v-model="data.begin"
                date-format="dd.MM.yyyy"
                :clearable="false"
                :rules="[v => !!v || $t('validation.required.begin-date')]"
                :id="'beginDate'"
            ></app-date-time-picker>

            <app-date-time-picker
                :label="t('selection-period.end_date')"
                v-model="data.end"
                date-format="dd.MM.yyyy"
                :clearable="false"
                :rules="[v => !!v || $t('validation.required.end-date')]"
                :id="'endDate'"
            ></app-date-time-picker>
          </v-card-text>
        </v-card>
      </template>
    </BasicEditContainer>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import BasicEditContainer from "@/components/inputs/BasicEditContainer";
import AppDateTimePicker from "../components/vuetify/AppDateTimePicker";
import moment from "moment";

export default {
  name: 'ServapptRequestsEdit',
  components: {
    BasicEditContainer,
    AppDateTimePicker
  },
  data() {
    return {
      data: {
        area: null,
        begin: null,
        end: null,
        phone: null,
        dates: [],
        state: 'draft',
      },
      eventDate: null,
      dataLoaded: false,
      areas: [],
      states: [
        {value: "draft", name: this.t("status.draft")},
        {value: "published", name: this.t("status.published")},
        {value: "archived", name: this.t("status.archived")},
        {value: "deleted", name: this.t("status.deleted")}
      ],

      dataOriginal: null,
      indexRouteName: 'ServapptRequestsIndex'
    }
  },
  created() {
    this.itemId = this.$route.params.id
    this.getItem()
    this.getAreas()
  },
  mounted() {
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('servappt.requests.details.edit.' + key);
    },
    addDate() {

      this.arrayEvents = this.eventDate
    
      let newDataObject = []
      for (let i = 0; i < this.arrayEvents.length; i++) {
        let tmpOb = {
          title: moment(this.arrayEvents[i]).format('ll'),
          date: this.arrayEvents[i],
          morning: typeof this.data.request_dates[i] !== "undefined" ? this.data.request_dates[i].morning : true,
          afternoon: typeof this.data.request_dates[i] !== "undefined" ? this.data.request_dates[i].afternoon : true
        }
        newDataObject.push(tmpOb)
      }
      this.data.request_dates = newDataObject
      this.data.request_dates = this.data.request_dates.sort((a,b)=> (a.date > b.date ? 1 : -1))

    },
    removeDate(value) {
      const index = this.data.request_dates.indexOf(value);
      this.data.request_dates.splice(index, 1);

      let newDataObject = []
      for (let i = 0; i < this.data.request_dates.length; i++) {
        newDataObject.push(this.data.request_dates[i].date)
      }

      this.arrayEvents = newDataObject
      this.eventDate = this.arrayEvents
    },
    resetAllValuesToDatePicker() {

      this.data.request_dates = this.data.request_dates.sort((a,b)=> (a.date > b.date ? 1 : -1))

      let newDataObject = []
      for (let i = 0; i < this.data.request_dates.length; i++) {
        let date = this.data.request_dates[i].date.split("T");
      
        newDataObject.push(date[0])
      }

      this.arrayEvents = newDataObject
      this.eventDate = this.arrayEvents
      // Workraound to display the date
      this.addDate()
    },
    getAreas() {
      HTTP.get('servappt/areas', {params: {'sortBy': 'name'}}).then(function (response) {
        this.areas = response.data.data
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    getItem() {
      HTTP.get('servappt/requests/' + this.itemId).then(function (response) {
        this.data = response.data  
        this.data.area = this.data.area.id
        this.dataOriginal = JSON.parse(JSON.stringify(this.data));
        this.dataLoaded = true
        this.resetAllValuesToDatePicker()
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    saveItem() {
      return HTTP.put('servappt/requests/' + this.itemId, this.data);
    },
    async onSaved() {
      this.dataOriginal = null;
      this.close();
    },
    close() {
      this.$router.push({name: this.indexRouteName})
    },
    async deleteItem() {
      await HTTP.delete('servappt/requests/' + this.itemId)
      this.close()
    },
    checkIfEmpty(item) {
      if (item.morning == false && item.afternoon == false) {
        this.removeDate(item);
        
      }
    }
  },
  watch: {
    'data.request_dates': {
      handler: function (val) {
        // The 'val' parameter contains the updated value of 'data.request_dates'
        console.log('data.request_dates array changed:', val);

        // You can access individual items within the array and watch for their changes here
        val.forEach((item, index) => {
          this.$watch(
            () => val[index], // Watch for changes in the specific item at the given index
            (newItem, oldItem) => {
                if (newItem.afternoon == false && newItem.morning == false) {
                  this.removeDate(newItem);
                }
              // Perform any specific actions when an item changes here
            },
            { deep: true } // Enable deep watch for the specific item
          );
        });
      },
      deep: true // Enable deep watch for the entire 'data.request_dates' array
    }
  }
}
</script>

<style scoped lang="scss">

</style>
