<template>
  <div>
    <basic-settings-container
        :title="$t('timelog-settings.title')"
        :data-loaded="dataLoaded"
        :has-changes="hasChanges"
        @reset="reset"
        :save-action="saveItem"
    >
      <v-card elevation="0">
        <v-card-title>{{ $t('timelog-settings.record-mode.title') }}</v-card-title>
        <v-card-text>
          <v-radio-group v-model="item.record_mode">
            <v-radio
                :label="$t('timelog-settings.record-mode.fromto')"
                value="fromto"
            />
            <v-radio
                :label="$t('timelog-settings.record-mode.hours')"
                value="hours"
            />
          </v-radio-group>

        </v-card-text>
      </v-card>

      <v-card elevation="0" class="sp-mar-top-3">
        <v-card-title>{{ $t('timelog-settings.lock-time-mode.title') }}</v-card-title>
        <v-card-text>
          <v-radio-group v-model="item.locktime_mode">
            <v-radio
                :label="$t('timelog-settings.lock-time-mode.disabled')"
                value="disabled"
            />
            <v-radio
                :label="$t('timelog-settings.lock-time-mode.enabled')"
                value="enabled"
            />
          </v-radio-group>

        </v-card-text>
      </v-card>

      <v-card elevation="0" class="sp-mar-top-3">
        <v-card-title>{{ $t('timelog-settings.qr-record-mode.title') }}</v-card-title>
        <v-card-text>
          <v-radio-group v-model="item.qr_record_mode">
            <v-radio
                :label="$t('timelog-settings.qr-record-mode.isolated')"
                value="isolated"
            />
            <v-radio
                :label="$t('timelog-settings.qr-record-mode.linked')"
                value="linked"
            />
          </v-radio-group>

        </v-card-text>
      </v-card>

      <v-card elevation="0" class="sp-mar-top-3">
        <v-card-title>{{ $t('timelog-settings.qr-requires-location.title') }}</v-card-title>
        <v-card-text>
          <v-radio-group v-model="item.qr_requires_location">
            <v-radio
                :label="$t('timelog-settings.qr-requires-location.yes')"
                value="yes"
            />
            <v-radio
                :label="$t('timelog-settings.qr-requires-location.no')"
                value="no"
            />
          </v-radio-group>

        </v-card-text>
      </v-card>

      <v-card elevation="0" class="sp-mar-top-3">
        <v-card-title>{{ $t('timelog-settings.book-on-customer.title') }}</v-card-title>
        <v-card-text>
          <v-radio-group v-model="item.log_directly_on_customer_allowed">
            <v-radio
                :label="$t('timelog-settings.book-on-customer.yes')"
                :value="true"
            />
            <v-radio
                :label="$t('timelog-settings.book-on-customer.no')"
                :value="false"
            />
          </v-radio-group>

        </v-card-text>
      </v-card>

    </basic-settings-container>
  </div>
</template>


<script>
import { HTTP } from './../auth'
import toolbar from '@/components/layouts/Navigation.vue'
import loading from '@/components/layouts/Loading.vue'
import AppDialogConfirmSave from '@/components/vuetify/AppDialogConfirmSave'
import BasicSettingsContainer from '@/components/inputs/BasicSettingsContainer.vue'

export default {
  name: 'TimelogSettings',
  components: {
    BasicSettingsContainer,
    toolbar,
    loading,
    AppDialogConfirmSave
  },
  data() {
    return {
      item: {
        record_mode: '',
        lock_time_mode: '',
        qr_record_mode: '',
        qr_requires_location: '',
        log_directly_on_customer_allowed: false,
      },
      itemOriginal: null,
      dataLoaded: false,
    }
  },
  created() {
    this.getItem()
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
  },
  methods: {
    async getItem() {
      this.dataLoaded = false
      let response = await HTTP.get('timelog/settings')
      this.item = response.data
      this.itemOriginal = JSON.parse(JSON.stringify(this.item));
      this.dataLoaded = true
    },
    async reset() {
      await this.getItem()
    },
    async saveItem() {
      await HTTP.put('timelog/settings', this.item)
      this.$root.infoNotification.showMessage(this.$t('timelog-settings.save-confirmation-message'))
    },
  },
}

</script>
