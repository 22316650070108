<template>
  <v-select
      class="shop-categories-filter"
      v-model="selectedCategories"
      :label="$t('components.shop-categories-filter.label')"
      :items="categories"
      :loading="loading"
      item-text="name"
      item-value="id"
      multiple
      chips
  ></v-select>

</template>

<script>
import { HTTP } from '@/auth'

export default {
  name: "ShopCategoriesFilter",
  props: ['value'],
  data() {
    return {
      selectedCategories: this.value,
      categories: [],
      loading: false
    }
  },
  watch: {
    selectedCategories: function (val) {
      this.$emit('input', val)
    }
  },
  async created() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      await HTTP.get('shop_categories').then(function (response) {
        this.categories = response.data.data
        this.loading = false
      }.bind(this)).catch(function (error) {
        console.log(error)
        this.loading = false
      }.bind(this))
    }
  }
}
</script>

<style scoped lang="scss">
.shop-categories-filter {

  ::v-deep .v-select__selections {

    input {
      width: 0;
    }
  }
}
</style>
