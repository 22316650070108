<template>
  <div>
    <v-btn @click="year--" elevation="0" class="mr-2"
    >
      <v-icon>
        navigate_before
      </v-icon>
    </v-btn>
    {{ year }}
    <v-btn @click="year++" elevation="0" class="ml-2">
      <v-icon>
        navigate_next
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "AppYearPicker",
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  watch: {
    year: function (val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style scoped>

</style>
