var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasicCreateEditContainer',{ref:"editor",attrs:{"title":_vm.mode == 'create' ? _vm.t('create.title') : _vm.t('edit.title'),"custom-class":"checklist-element-chooser","object-type":"Checklist","object-name":_vm.checklist.name,"data-loaded":_vm.dataLoaded,"save-action":_vm.saveChecklist,"allow-delete":_vm.mode === 'edit',"delete-action":_vm.deleteChecklist,"has-changes":_vm.hasChanges,"hidden-field-validation-message":_vm.t('validation.hidden-field-validation-tasks'),"deletion-hint-text":_vm.deletionHintText,"deletion-hint-list":_vm.deletionHintList},on:{"cancel":_vm.close,"saved":_vm.onSaved,"deleted":_vm.onDeleted},scopedSlots:_vm._u([{key:"fluid",fn:function(){return [_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('create.card-title')))]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.t('create.placeholder.name'),"counter":"120","filled":"","rules":[
                  function () { return !!_vm.checklist.name || _vm.t('validation.error-name-required'); },
                  function () { return (_vm.checklist.name && _vm.checklist.name.length <= 120) || _vm.t('validation.error-max-name'); }
                ]},model:{value:(_vm.checklist.name),callback:function ($$v) {_vm.$set(_vm.checklist, "name", $$v)},expression:"checklist.name"}}),_c('div',{staticClass:"mb-5"},[(_vm.dataLoaded)?_c('content-filter-checklist-customer',{attrs:{"label":_vm.t('create.filter.customer-search'),"get-search-url":_vm.getCustomersSearchUrl,"clearable":"","append-icon":"mdi-magnify","preselected":_vm.assignedCustomer,"rules":[
                  function () { return !!_vm.checklist.customer || _vm.t('validation.error-name-required'); }
                ]},model:{value:(_vm.checklist.customer),callback:function ($$v) {_vm.$set(_vm.checklist, "customer", $$v)},expression:"checklist.customer"}}):_vm._e()],1),_c('div',{staticClass:"mb-4"},[_c('v-select',{attrs:{"filled":"","label":_vm.$t('components.permissiongroup-select.label'),"items":_vm.permissionGroups,"item-text":"name","item-value":"id","append-icon":"mdi-chevron-down","multiple":""},model:{value:(_vm.checklist.assigned_permission_groups),callback:function ($$v) {_vm.$set(_vm.checklist, "assigned_permission_groups", $$v)},expression:"checklist.assigned_permission_groups"}})],1),_c('div',{staticClass:"mb-4"},[_c('content-filter-checklist-user',{attrs:{"label":_vm.t('create.filter.employee-search'),"clearable":"","append-icon":"mdi-magnify"},on:{"item-selected":_vm.assignEmployee,"items-loaded":_vm.employeesLoaded}}),(_vm.checklist.assigned_users.length > 0)?_c('v-list',{staticClass:"assigned-users-list",attrs:{"lines":"one"}},_vm._l((_vm.checklist.assigned_users),function(user,index){return _c('v-list-item',{key:index,staticClass:"employee-element passive"},[_c('span',{staticClass:"ellipsis"},[_c('v-icon',[_vm._v("mdi-account-lock-open")]),_vm._v(_vm._s(user.display_name))],1),_c('v-icon',{staticClass:"employee-action",attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.removeEmployee(index)}}},[_vm._v("mdi-minus-circle")])],1)}),1):_vm._e()],1),_c('v-divider'),_c('div',{staticClass:"filter-toggle mt-4"},[_c('div',{staticClass:"filter-item"},[_c('h4',[_vm._v(_vm._s(_vm.t('create.blueprint.label')))]),_c('v-switch',{attrs:{"label":_vm.checklist.is_template ? _vm.t('create.blueprint.yes') : _vm.t('create.blueprint.no'),"color":"primary","hide-details":""},model:{value:(_vm.checklist.is_template),callback:function ($$v) {_vm.$set(_vm.checklist, "is_template", $$v)},expression:"checklist.is_template"}})],1)]),_c('v-text-field',{ref:"hiddenValidation",staticClass:"hidden",attrs:{"rules":[
                function () { return !!_vm.noTasksValue || _vm.t('validation.hidden-field-validation-tasks'); }
              ]},model:{value:(_vm.noTasksValue),callback:function ($$v) {_vm.noTasksValue=$$v},expression:"noTasksValue"}})],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('create.card-active-title')))]),_c('v-card-text',[_c('div',{staticClass:"filter-toggle"},[_c('div',{staticClass:"filter-item"},[_c('h4',[_vm._v(_vm._s(_vm.t('create.active.label')))]),_c('v-switch',{attrs:{"label":_vm.checklist.active ? _vm.t('create.active.yes') : _vm.t('create.active.no'),"color":"primary","hide-details":""},model:{value:(_vm.checklist.active),callback:function ($$v) {_vm.$set(_vm.checklist, "active", $$v)},expression:"checklist.active"}})],1)])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('create.card-tasks-title')))]),_c('v-card-text',[_c('ChecklistElementChooser',{attrs:{"object-type":"Task","subelements":_vm.checklist.elements,"validate-subelements":!_vm.hasTasks}})],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }