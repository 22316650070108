import SettingsGlobal from "../../views/SettingsGlobal";
import SettingsClient from "../../views/SettingsClient";
import SettingsTimeTracking from "../../views/SettingsTimeTracking";
import SettingsWebview from "../../views/SettingsWebview";
import SettingsUploadImport from "../../views/SettingsUploadImport";
import SettingsPermissionGroups from "../../views/SettingsPermissionGroups";
import SettingsPermissionGroupsCreate from "../../views/SettingsPermissionGroupsCreate";
import SettingsPermissionGroupsEdit from "../../views/SettingsPermissionGroupsEdit";
import SettingsSubjectAreaIndex from "../../views/SettingsSubjectAreaIndex"; 
import SettingsSubjectAreaCreate from "../../views/SettingsSubjectAreaCreate"; 
import SettingsSubjectAreaEdit from "../../views/SettingsSubjectAreaEdit"; 

import SettingsNotificationTypeIndex from "../../views/SettingsNotificationTypeIndex"; 
import SettingsNotificationTypeCreate from "../../views/SettingsNotificationTypeCreate"; 
import SettingsNotificationTypeEdit from "../../views/SettingsNotificationTypeEdit"; 
import SettingsNotificationRatingIndex from "../../views/SettingsNotificationRatingIndex"; 
import SettingsNotificationRatingCreate from "../../views/SettingsNotificationRatingCreate"; 
import SettingsNotificationRatingEdit from "../../views/SettingsNotificationRatingEdit";


import SettingsNotificationRoomIndex from "../../views/SettingsNotificationRoomIndex"; 
import SettingsNotificationRoomCreate from "../../views/SettingsNotificationRoomCreate"; 
import SettingsNotificationRoomEdit from "../../views/SettingsNotificationRoomEdit"; 

const settingsRoutes = [
    {
        path: '/settings/global',
        name: 'SettingsGlobal',
        component: SettingsGlobal,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/client',
        name: 'SettingsClient',
        component: SettingsClient,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/timetracking',
        name: 'SettingsTimeTracking',
        component: SettingsTimeTracking,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/webview',
        name: 'SettingsWebview',
        component: SettingsWebview,
        meta: {
            accessConfig:  {
                moduleName: ['timelog_wv', 'surveys_wv'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/upload/import',
        name: 'SettingsUploadImport',
        component: SettingsUploadImport,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/permission-groups',
        name: 'SettingsPermissionGroups',
        component: SettingsPermissionGroups,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/permission-groups/create',
        name: 'SettingsPermissionGroupsCreate',
        component: SettingsPermissionGroupsCreate,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/permission-groups/:id?',
        name: 'SettingsPermissionGroupsEdit',
        component: SettingsPermissionGroupsEdit,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/subject-area',
        name: 'SettingsSubjectAreaIndex',
        component: SettingsSubjectAreaIndex,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/subject-area/create',
        name: 'SettingsSubjectAreaCreate',
        component: SettingsSubjectAreaCreate,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/subject-area/:id?',
        name: 'SettingsSubjectAreaEdit',
        component: SettingsSubjectAreaEdit,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/notification-type',
        name: 'SettingsNotificationTypeIndex',
        component: SettingsNotificationTypeIndex,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/notification-type/create',
        name: 'SettingsNotificationTypeCreate',
        component: SettingsNotificationTypeCreate,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/notification-type/:id?',
        name: 'SettingsNotificationTypeEdit',
        component: SettingsNotificationTypeEdit,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/notification-rating',
        name: 'SettingsNotificationRatingIndex',
        component: SettingsNotificationRatingIndex,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/notification-rating/create',
        name: 'SettingsNotificationRatingCreate',
        component: SettingsNotificationRatingCreate,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/notification-rating/:id?',
        name: 'SettingsNotificationRatingEdit',
        component: SettingsNotificationRatingEdit,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },


    {
        path: '/settings/notification-room',
        name: 'SettingsNotificationRoomIndex',
        component: SettingsNotificationRoomIndex,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/notification-room/create',
        name: 'SettingsNotificationRoomCreate',
        component: SettingsNotificationRoomCreate,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/notification-room/:id?',
        name: 'SettingsNotificationRoomEdit',
        component: SettingsNotificationRoomEdit,
        meta: {
            accessConfig:  {
                moduleName: ['qc_notifications'],
                requiresAuth: true
            }
        }
    },

]

export default settingsRoutes
