var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasicCreateEditContainer',{ref:"editor",attrs:{"title":_vm.t('title'),"object-type":"Product","object-name":_vm.variant.name,"data-loaded":_vm.dataLoaded,"save-action":_vm.saveProduct,"allow-delete":false,"has-changes":_vm.hasChanges},on:{"cancel":_vm.close,"saved":_vm.onSaved},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('card-title')))]),_c('v-card-text',[_c('v-text-field',{staticClass:"sp-mar-top-3",attrs:{"label":_vm.t('details.name'),"filled":"","readonly":true},model:{value:(_vm.variant.name),callback:function ($$v) {_vm.$set(_vm.variant, "name", $$v)},expression:"variant.name"}}),_c('v-text-field',{staticClass:"sp-mar-top-3",attrs:{"label":_vm.t('details.sku'),"filled":"","readonly":true},model:{value:(_vm.variant.sku),callback:function ($$v) {_vm.$set(_vm.variant, "sku", $$v)},expression:"variant.sku"}}),(_vm.dataLoaded && _vm.variant.product.has_variants)?_c('v-text-field',{staticClass:"sp-mar-top-3",attrs:{"label":_vm.t('details.variant'),"filled":"","readonly":true},model:{value:(_vm.variantName),callback:function ($$v) {_vm.variantName=$$v},expression:"variantName"}}):_vm._e(),_c('div',{staticClass:"inventory-change-wrapper"},[_c('div',{staticClass:"inventory-stock"},[_c('div',{staticClass:"inventory-stock-label"},[_vm._v(_vm._s(_vm.t('details.stock')))]),_c('div',{staticClass:"inventory-stock-value"},[_vm._v(_vm._s(_vm.variant.inventory_sum))])]),_c('v-text-field',{staticClass:"inventory-stock-change",attrs:{"label":_vm.t('details.inventory-change'),"type":"number","filled":"","hide-details":"auto","rules":[
                            function () { return !isNaN(parseInt(_vm.inventory_change.amount)) || _vm.t('validation.required.inventory'); }
                        ]},model:{value:(_vm.inventory_change.amount),callback:function ($$v) {_vm.$set(_vm.inventory_change, "amount", _vm._n($$v))},expression:"inventory_change.amount"}}),_c('v-text-field',{staticClass:"inventory-stock-change-comment",attrs:{"label":_vm.t('details.inventory-change-comment'),"filled":"","hide-details":"auto"},model:{value:(_vm.inventory_change.comment),callback:function ($$v) {_vm.$set(_vm.inventory_change, "comment", _vm._n($$v))},expression:"inventory_change.comment"}})],1)],1)],1),(_vm.dataLoaded && _vm.variant.product.has_variants)?_c('v-card',{staticClass:"sp-mar-top-3",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('variants-list.card-title')))]),_c('v-card-text',[_c('v-data-table',{staticClass:"simple-data-table elevation-0 mb-0",attrs:{"headers":_vm.variantsHeaders,"items":_vm.variants,"loading":_vm.variantsLoading,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.link",fn:function(ref){
                        var item = ref.item;
return [_c('router-link',{staticClass:"edit-row-table",attrs:{"to":{ name: 'ShopInventoryDetails', params: { id: item.id } }}},[_c('v-icon',{staticClass:"edit-row-button",attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1)]}}],null,true)})],1)],1):_vm._e()]},proxy:true},{key:"meta",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('history.card-title')))]),_c('v-card-text',[_c('div',{staticClass:"history-wrapper history-stock"},[_c('div',[_vm._v(_vm._s(_vm.t('history.in-stock')))]),_c('div',[_vm._v(_vm._s(_vm.variant.inventory_sum))])]),_c('div',{staticClass:"history-list"},_vm._l((_vm.inventoryHistoryTruncated),function(vi,index){return _c('div',{key:index,staticClass:"history-wrapper history-list-item"},[_c('div',{staticClass:"transaction",class:[{ 'positiv': vi.amount >= 0 }]},[(vi.amount >= 0)?_c('span',[_vm._v("+")]):_c('span',[_vm._v("-")]),_vm._v(" "+_vm._s(_vm._f("absValue")(vi.amount))+" ")]),_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.inventoryHistory[index]))]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("momentDate")(vi.created_at)))]),_c('div',{staticClass:"comment"},[(vi.comment && vi.comment !== '')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(vi.comment))])]):_vm._e()],1)])}),0)])],1)]},proxy:true},{key:"secondary-action-buttons",fn:function(){return [_c('v-btn',{staticClass:"icon-left",attrs:{"color":"primary","elevation":"0","outlined":""},on:{"click":function($event){return _vm.navigateToProduct()}}},[_c('v-icon',[_vm._v("mdi-cog")]),_vm._v(" "+_vm._s(_vm.t('edit-product'))+" ")],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }