<template>
  <div>
    <BasicCreateEditContainer
        :title="mode == 'create' ? t('create.title') : t('edit.title')"
        ref="editor"
        custom-class="checklist-element-chooser"
        object-type="Element"
        :object-name="task.name"
        :data-loaded="dataLoaded"
        @cancel="close"
        :save-action="saveTask"
        @saved="onSaved"
        :allow-delete="mode === 'edit'"
        :delete-action="deleteTask"
        @deleted="onDeleted"
        :has-changes="hasChanges"
        :hidden-field-validation-message="t('validation.hidden-field-validation-subtasks')"
        :deletion-hint-text="deletionHintText"
        :deletion-hint-list="deletionHintList"
    >
      <template v-slot:fluid>

        <v-col cols="12" sm="12" md="4">

          <v-card elevation="0">       
            <v-card-title>{{ t('create.card-title') }}</v-card-title>
            <v-card-text>

              <v-text-field
                  :label="t('create.placeholder.name')"
                  v-model="task.name"
                  counter="40"
                  filled
                  :rules="[
                    () => !!task.name || t('validation.error-name-required'),
                    () => (task.name && task.name.length <= 40) || t('validation.error-max-name-40')
                  ]"
              ></v-text-field>

              <v-text-field
                  :label="t('create.placeholder.description')"
                  v-model="task.description"
                  counter="120"
                  filled
                  :rules="[
                    () => (!task.description || (task.description && task.description.length <= 120)) || t('validation.error-max-name')
                  ]"
              ></v-text-field>

              <v-text-field
                  ref="hiddenValidation"
                  class="hidden"
                  v-model="noSubtasksValue"
                  :rules="[
                    () => !!noSubtasksValue || t('validation.hidden-field-validation-subtasks')
                  ]"
              ></v-text-field>

              <v-card
                  elevation="0"
              >
                <v-card-title>Tags</v-card-title>
                <v-card-text>

                  <v-chip-group
                    v-model="selectedTags"
                    column
                    multiple
                    @change="updateTags"
                  >
                    <v-chip
                      filter
                      :ripple="false"
                      v-for="(tag, index) in tags" :key="index"
                    >
                      {{ tag.name }}
                    </v-chip>
                  </v-chip-group>

                </v-card-text>
              </v-card> 

            </v-card-text>
          </v-card>

        </v-col>

        <v-col cols="12" sm="12" md="8">

          <v-card elevation="0">
            <v-card-title>{{ t('create.card-subtasks-title') }}</v-card-title>
            <v-card-text>

              <ChecklistElementChooser 
                object-type="Subtask" 
                :subelements="task.subtasks" 
                :validate-subelements="hasSubtasks">
              </ChecklistElementChooser>

            </v-card-text>
          </v-card>

        </v-col>

      </template>

    </BasicCreateEditContainer>
  </div>
</template>

<script>
  import { HTTP } from '@/auth'
  import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
  import ChecklistElementChooser from '@/components/inputs/ChecklistElementChooser'

  export default {
    name: "ChecklistCreateEditTask",
    components: {
      BasicCreateEditContainer,
      ChecklistElementChooser
    },
    data () {
      return {
        task: {
          name: '',
          description: '',
          tags: [],
          subtasks: []
        },
        tags: [],
        selectedTags: [],
        dataOriginal: null,
        dataLoaded: false,
        error: false,
        mode: null,
        deletionHintText: '',
        deletionHintList: []
      }
    },
    created() {
      this.task.id = this.$route.params.id
      this.mode = this.task.id ? 'edit' : 'create'
      this.dataOriginal = JSON.parse(JSON.stringify(this.task))
      this.getTags()
    },
    computed: {
      hasChanges() {
        return JSON.stringify(this.task) !== JSON.stringify(this.dataOriginal)
      },
      hasSubtasks() {
        return this.task.subtasks.length !== 0
      },
      noSubtasksValue() {
        return this.hasSubtasks ? 'hasSubasks' : null
      }
    },
    methods: {
      t: function (key) {
        return this.$t('checklists.tasks.' + key)
      },
      onValidated () {
        this.validateOnSubmit = true
      },
      getTags () {
        HTTP.get('settings/checklist/tags').then(function (response) {
          this.tags = response.data
          if (this.mode === 'edit') {
            this.getTask()
          } else {
            this.dataLoaded = true
          }
        }.bind(this)).catch(function (error) {
            if (!error.status) {
              this.error = true
            }
        }.bind(this))
      },
      getTask () {
        HTTP.get('mainelements/' + this.task.id).then(function (response) {
          this.task = response.data
          this.tags.forEach((allTag, index) => {
            if (this.task.tags.filter((tag) => tag.id === allTag.id).length > 0) {
              this.selectedTags.push(index)
            }
          })
          this.deletionHintText = this.getUsageInfo().deletionHintText
          this.deletionHintList = this.getUsageInfo().deletionHintList
          this.dataOriginal = JSON.parse(JSON.stringify(this.task))
          this.dataLoaded = true
        }.bind(this)).catch(function (error) {
          if (error.response) {
            if (error.response.status && error.response.status === 404) {
              this.$root.infoNotification.showMessage(this.$t('checklists.tasks.create.not-found', { taskId: this.task.id }))
            } else {
              this.$root.infoNotification.showMessage(this.t('create.unknown-error') + error.response.statusText)
            }
          } else {
            this.$root.infoNotification.showMessage(this.t('create.unknown-error') + error)
          }
          this.close()
        }.bind(this))
      },
      saveTask () {
        const json = JSON.stringify({
          taskData: this.task
        })

        if (this.mode == 'create') {
          return HTTP.post('mainelements', { 'data': json })
        } else {
          return HTTP.patch('mainelements/' + this.task.id, { 'data': json })
        }
      },
      onSaved() {
        this.dataOriginal = null
        this.close();
      },
      deleteTask () {
        return HTTP.delete('mainelements/' + this.task.id);
      },
      onDeleted() {
        this.dataOriginal = null
        this.close();
      },
      updateTags (newTagIndices) {
        this.task.tags.splice(0)
        newTagIndices.forEach((tagIndex) => {
          this.task.tags.push(this.tags[tagIndex])
        })
      },
      getUsageInfo () {
        let usages = this.task.usages
        return {
          deletionHintText: usages.length > 0 ? this.$t('checklists.tasks.deletion-hint', { taskName: this.task.name }) : '',
          deletionHintList: this.task.usages.map((u) => u.name)
        }
      },
      close() {
        this.$router.push({ name: 'ChecklistTasksIndex' })
      }
    }
  }
</script>
