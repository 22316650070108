<template>
    <div class="filter-item">
      <div class="filter-requests-state-filter">
        <v-select
            v-model="selectedFilterArea"
            :label="$t('components.content-filter-servappt-appointment-states.label')"
            :items="filterArea"
            item-text="name"
            item-value="slug"
            multiple
            chips
        ></v-select>
      </div>
    </div>
  
  </template>
  
  <script>
  export default {
    name: "ServapptAppointmentStateFilter",
    props: ['value'],
    data() {
      return {
        selectedFilterArea: this.value,
        filterArea: [
          {
            name: this.t("confirmed"),
            slug: 'confirmed'
          },
          {
            name: this.t("cancelled"),
            slug: 'cancelled'
          }
        ],
      }
    },
    created() {
      let me = this;
      me.filterArea.forEach(function (value) {
        value.name = me.t(value.slug);
      });
    },
    watch: {
      selectedFilterArea: function (val) {
        this.$emit('input', val);
      }
    },
    methods: {
      t: function (key) {
        return this.$t('servappt.data.appointment.states.' + key);
      },
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  