<template>
    <div class="has-hero-container">
        <div>
            <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>
            <div class="sp-hero sp-bg-light-blue">
                <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

                    <app-filter-apply-button
                    v-if="filterStore.hasChanges"
                    @click="applyFilter(); options.page = 1"
                    ></app-filter-apply-button>

                    <v-slide-group show-arrows>

                        <v-slide-item>
                            <div class="filter-item">
                                <app-filter-date-picker
                                    :filterLabel="$t('components.shop-filters.from')"
                                    v-model="filterStore.filter.dateStart"
                                    id="dateStart"
                                    clearable
                                    data-cy="workStart"
                                ></app-filter-date-picker>
                            </div>
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-item">
                                <app-filter-date-picker
                                    :filterLabel="$t('components.shop-filters.to')"
                                    v-model="filterStore.filter.dateEnd"
                                    id="dateEnd"
                                    clearable
                                    data-cy="dateEnd"
                                ></app-filter-date-picker>
                            </div>
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-search-list">
                                <div class="filter-item">
                                <div class="filter-item-wrapper">
                                    <app-filter-search-field
                                        v-model="filterStore.filter.searchTerm"
                                        :filterLabel="t('filter.name-search')"
                                        :placeholder="t('filter.name-search-placeholder')"
                                        clearable
                                        append-icon="mdi-magnify"									
                                    ></app-filter-search-field>
                                </div>
                                </div>
                            </div>  
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-search-list">
                            <div class="filter-item">
                                <div class="filter-item-wrapper">
                                <content-filter-employee
                                    v-model="filterStore.filter.user"
                                    :include-inactive="true"
                                />
                                </div>
                            </div>
                            </div>
                        </v-slide-item>

                        <v-slide-item>
                            <div class="filter-item filter-status-filter">
                                <order-status-filter :multiple="true" v-model="filterStore.filter.status"/>
                            </div>
                        </v-slide-item>

                    </v-slide-group>

                </v-container>
            </div>

            <v-container fluid>

                <v-row class="data-table-toolbar"></v-row>

                <v-row v-if="dataLoaded && items.length === 0">
                  <div class="empty-datatable">
                    <v-icon>mdi-alert-circle-outline</v-icon>
                    {{ t('no-data') }}
                  </div>
                </v-row>

                <v-row v-else>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :options.sync="options"
                        :server-items-length="totalItems"
                        :loading="loading"
                        class="elevation-0"
                        :multi-sort="false"
                        :must-sort="true"
                        :sort-by.sync="filterStore.table.sortBy"
                        :sort-desc.sync="filterStore.table.sortDesc"
                        :page.sync="filterStore.table.currentPage"
                        :items-per-page.sync="filterStore.table.itemsPerPage"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus',
                            'items-per-page-options': [25, 50, 100, 200],
                            pageText: '{0}-{1} von {2}',
                            'items-per-page-text': t('table.items-per-page')
                        }"
                    >
                        <template v-slot:[`item.shop_orders.date`]="{ item }">
                            <span>{{ formatDate(item['shop_orders.date']) }}</span>
                        </template>

                        <template v-slot:[`item.shop_orders.number`]="{ item }">
                            <span>#{{ item['shop_orders.number'] }}</span>
                        </template>
                        
                        <template v-slot:[`item.shop_orders.status`]="{ item }">
                            <div class="status_blocks">
                                <order-status-icons :status="item['shop_orders.status']"/>
                                <div :class="'status-' + item['shop_orders.status']">
                                    {{ $t('order-status-icons.' + item['shop_orders.status']) }}
                                    <br>
                                    {{ formatDate(item['shop_orders.status_date']) }}
                                </div>
                            </div> 
                        </template>

                        <template v-slot:[`item.edit`]="{ item }">
                            <router-link :to="{ path: '/shop-orders/' + item.id }" class="edit-row-table" data-cy="editButton">
                                <v-icon
                                small
                                class="edit-row-button"
                                >
                                    mdi-eye-outline
                                </v-icon>
                            </router-link>
                        </template>
                    </v-data-table>
                </v-row>

            </v-container>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation.vue'
import loading from '@/components/layouts/Loading.vue'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import { useOrderFilterStore } from '@/stores/OrdersFilterStore'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import OrderStatusFilter from '@/components/filter-components/OrderStatusFilter'
import OrderStatusIcons from '@/components/layouts/OrderStatusIcons'

export default {
    name: 'ShopOrdersIndex',
    components: {
        toolbar,
        loading,
        AppFilterApplyButton,
        AppFilterSearchField,
        AppFilterDatePicker,
        ContentFilterEmployee,
        OrderStatusFilter,
        OrderStatusIcons
    },
    setup() {
        const filterStore = useOrderFilterStore()
        return { filterStore }
    },
    data() {
        return {
            loading: false,
            totalItems: 0,
            items: [],
            options: {},
            dataLoaded: false,
            message: null,
            error: false,
            cancelHTTP: null,
            errorCancel: null,
            status: ''
        }
    },
    watch: {
      options: {
        handler() {
          this.getOrders()
        },
        deep: true,
      }
    },
    methods: {
        t: function (key) {
            return this.$t('shop.orders.list.' + key)
        },
        reload () {
            this.items = []
            this.getOrders()
        },
        applyFilter(doReload = true) {
            this.filterStore.filterApplied()
            this.reload()
        },
        getOrders() {
            this.loading = true
            this.dataLoaded = false

            this.apiCall().then(data => {
                this.items = data.items
                this.totalItems = data.total
                this.loading = false
                this.dataLoaded = true
            })
        },
        apiCall() {
            return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options

                let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

                HTTP.get('shop_orders' + url).then(function (response) {

                    let items = response.data.data
                    let total = response.data.total
               
                    resolve({
                        items,
                        total,
                    })
                }.bind(this))
            })
        },
        prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
            let queryStringParts = []
            if (this.filterStore.filter.searchTerm) {
                queryStringParts.push('q=' + this.filterStore.filter.searchTerm)
            }
            if (this.filterStore.filter.dateStart) {
                queryStringParts.push('dateStart=' + this.filterStore.filter.dateStart)
            }
            if (this.filterStore.filter.dateEnd) {
                queryStringParts.push('dateEnd=' + this.filterStore.filter.dateEnd)
            }

            if (this.filterStore.filter.status.length > 0) {
                queryStringParts.push('st=' + this.filterStore.filter.status)
            }

            if (this.filterStore.filter.user != null) {
                queryStringParts.push('user_id=' + this.filterStore.filter.user.id)
            }
            if (sortBy) {
                queryStringParts.push('sortBy=' + (sortBy[0] ?? ''))
                let direction = sortDesc[0] ? 'desc' : 'asc'
                queryStringParts.push('direction=' + direction)
            }
            page = page ? page : 1
            queryStringParts.push('page=' + page)
            if (itemsPerPage != null) {
                queryStringParts.push('itemsPerPage=' + itemsPerPage)
            }
            return '?' + queryStringParts.join('&')
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: this.t('table.headers.employee'),
                    align: 'middle',
                    sortable: true,
                    value: 'users.name',
                    width: 300
                },
                {
                    text: this.t('table.headers.order_number'),
                    align: 'middle',
                    sortable: true,
                    value: 'shop_orders.number',
                    width: 150
                },
                {
                    text: this.t('table.headers.number'),
                    align: 'middle',
                    sortable: false,
                    value: 'count_products',
                    width: 150
                },
                {
                    text: this.t('table.headers.ordered_at'),
                    align: 'middle',
                    sortable: true,
                    value: 'shop_orders.date',
                    width: 150
                },
                {
                    text: this.t('table.headers.status'),
                    align: 'middle',
                    sortable: true,
                    value: 'shop_orders.status',
                    width: 150
                },
                {
                    text: this.t('table.headers.details'),
                    value: 'edit',
                    width: 100,
                    align: 'center',
                    sortable: false
                }
            ]
        }
    }
}
</script>

<style scoped lang="scss">
.sp-hero .filter-status-filter {
    width: 100%;
}
.status_blocks {
    display: flex;
    gap: 16px;

    & > div {
        align-self: center;
    }

    .status-new {
        color: #2196F3;
    }

    .status-processing {
        color: #FB8C00;
    }

    .status-ready,
    .status-delivered {
        color: #4CAF50;
    }

    .status-cancelled {
        color: #9E9E9E;
    }

    .status-completed {
        color: #000000;
    }
}
</style>