<template>
  <div>
    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="edit-screen no-hero-container">

      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="{ path: '/servappt/servappt-areas/create'}"
        >
          <v-icon>mdi-plus</v-icon>
          {{ t('create') }}
        </v-btn>
      </v-row>

      <v-row v-if="dataLoaded && totalItems === 0">
        <div class="empty-datatable">
          <v-icon>mdi-alert-circle-outline</v-icon>
          {{ t('no-data') }}
        </div>
      </v-row>

      <v-row v-if="dataLoaded && totalItems > 0">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            class="elevation-0"
            :sort-by="initialSortBy"
            :sort-desc="initialSortDesc"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
        >
          <v-progress-linear v-show="loading" slot="progress" color="primary" indeterminate></v-progress-linear>
          
          <template v-slot:[`item.name`]="{ item }">
            <span class="ellipsis"><b>{{ item.name }}</b></span>
          </template>

          <template v-slot:[`item.edit`]="{ item }">

            <router-link :to="{ path: '/servappt/servappt-areas/' + item.id }" class="edit-row-table">
              <v-icon
                  small
                  class="edit-row-button"
              >
                edit
              </v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import toolbar from '../components/layouts/Navigation.vue'

export default {
  name: 'ServapptAreasIndex',
  components: {
    toolbar,
  },
  data() {
    return {
      items: null,
      totalItems: 0,
      dataLoaded: false,
      error: false,
      loading: false,
      options: {
        itemsPerPage: 25
      },
      initialSortBy: 'name',
      initialSortDesc: false,
    }
  },
  created() {
    this.getItems()
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getItems()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('servappt.areas.list.' + key);
    },
    getItems() {
      this.loading = true
      HTTP.get('servappt/areas', {params: this.options}).then(function (response) {
        this.items = response.data.data
        this.totalItems = response.data.total
        this.dataLoaded = true
        this.loading = false
        this.error = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
  }
}
</script>

<style language="scss" scoped>

</style>
