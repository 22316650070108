var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-hero-container"},[_c('div',[_c('toolbar',{attrs:{"dataLoaded":true}},[_vm._v(_vm._s(_vm.t('title')))]),_c('div',{staticClass:"sp-hero sp-bg-light-blue"},[_c('v-container',{staticClass:"hero-filter",attrs:{"fluid":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.applyFilter(); _vm.options.page = 1}}},[(_vm.filterStore.hasChanges)?_c('app-filter-apply-button',{on:{"click":function($event){_vm.applyFilter(); _vm.options.page = 1}}}):_vm._e(),_c('v-slide-group',{attrs:{"show-arrows":""}},[_c('v-slide-item',[_c('div',{staticClass:"filter-search-list"},[_c('div',{staticClass:"filter-item"},[_c('div',{staticClass:"filter-item-wrapper"},[_c('app-filter-search-field',{attrs:{"filterLabel":_vm.t('filter.name-search'),"placeholder":_vm.t('filter.name-search-placeholder'),"clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.filterStore.filter.searchTerm),callback:function ($$v) {_vm.$set(_vm.filterStore.filter, "searchTerm", $$v)},expression:"filterStore.filter.searchTerm"}})],1)])])])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"data-table-toolbar"}),(_vm.dataLoaded && _vm.items.length === 0)?_c('v-row',[_c('div',{staticClass:"empty-datatable"},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.t('no-data'))+" ")],1)]):_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"multi-sort":false,"must-sort":true,"sort-by":_vm.filterStore.table.sortBy,"sort-desc":_vm.filterStore.table.sortDesc,"page":_vm.filterStore.table.currentPage,"items-per-page":_vm.filterStore.table.itemsPerPage,"footer-props":{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    'items-per-page-options': [25, 50, 100, 200],
                    pageText: '{0}-{1} von {2}',
                    'items-per-page-text': _vm.t('table.items-per-page')
                }},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){return _vm.$set(_vm.filterStore.table, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filterStore.table, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filterStore.table, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filterStore.table, "sortDesc", $event)},"update:page":function($event){return _vm.$set(_vm.filterStore.table, "currentPage", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.filterStore.table, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.filterStore.table, "itemsPerPage", $event)}},scopedSlots:_vm._u([{key:"item.shop_products.image",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('image-wrapper',{attrs:{"link":_vm.getPreviewPictureLink(item.product_id),"can-remove":false,"max-width":60,"max-height":60}})],1)]}},{key:"shop_products.title",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.shop_products.title))])]}},{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"edit-row-table",attrs:{"to":{ path: '/shop-inventory/' + item.id }}},[_c('v-icon',{staticClass:"edit-row-button",attrs:{"small":""}},[_vm._v(" edit ")])],1)]}}],null,true)})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }