import de from 'vuetify/lib/locale/de';

const themesHar = {
    themes: {
        light: {
            primary: '#E9521D',
            secondary: '#F3CCBF',
            success: '#71AE5C',
            warning: '#CB000C',
            error: '#CB000C',
            buttons: '#E9521D'
        }
    },
}

export default themesHar
