<template>
  <div>
    <BasicNewContainer
        :title="t('create.title')"
        ref="editor"
        @cancel="close"
        :saveAction="saveItem"
        @saved="onSaved"
        :has-changes="hasChanges">
      <template>
        <v-card
            elevation="0"
        >
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>

            <v-text-field
                :label="t('details.name')"
                v-model="data.name"
                counter="120"
                data-cy="name"
                filled
                :rules="[
								() => !!data.name || $t('validation.required.default'),
								() => (data.name && data.name.length <= 120) || $t('validation.length.default')
							]"
            ></v-text-field>

          </v-card-text>
        </v-card>

        <settings-permission-groups-roles :value="data.roles" @input="updateRoles"/>


      </template>
      <template v-slot:meta>
        <member-list-edit v-model="data.members" :users="users"></member-list-edit>
      </template>
    </BasicNewContainer>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import BasicNewContainer from "@/components/inputs/BasicNewContainer";
import MemberListEdit from "@/components/MemberListEdit";
import {hasModule} from "@/services/LicenseService";
import SettingsPermissionGroupsRoles from "@/views/SettingsPermissionGroupsRoles.vue";

export default {
  name: 'SettingsPermissionGroupsCreate',
  components: {
    SettingsPermissionGroupsRoles,
    MemberListEdit,
    BasicNewContainer
  },
  data() {
    return {
      data: {
        name: '',
        members: [],
        roles: [],
        modules: []
      },
      users: [],
      dataOriginal: null,
      dataLoaded: false,
      indexRouteName: 'SettingsPermissionGroups'
    }
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
    },
  },
  mounted() {
    this.dataLoaded = true
  },
  created() {
    this.dataOriginal = JSON.parse(JSON.stringify(this.data));

  },
  methods: {
    t: function (key) {
      return this.$t('settings.permission-groups.detail.' + key);
    },
    updateRoles(roles) {
      this.data.roles = roles;
    },
    saveItem() {
      return HTTP.post('permission-groups', this.data)
    },
    async onSaved() {
      this.dataOriginal = null;
      await this.close();
    },
    async close() {
      await this.$router.push({name: this.indexRouteName})
    },
  }
}
</script>

<style scoped lang="scss">
.analytics-user {
  padding: 10px;

  .md-icon {
    cursor: pointer;
  }

  &:nth-child(odd) {
    background: #f7f7f7;
  }
}

.sp-search-field-lists {
  float: none;
  max-width: 100%;
}

.hint {
  white-space: pre-line;
}
</style>
