<template>
    <div>
  
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>
  
      <v-container fluid class="settings">
  
        <loading :error="error" :dataLoaded="dataLoaded"></loading>
  
        <v-form ref="form">
            
          <div v-if="dataLoaded">
  
            <v-row class="settings-wrapper">
  
              <v-card
                  elevation="0"
              >
                <v-card-title>{{ t('form-title') }}</v-card-title>
                <v-card-text>
  
                  <v-switch
                      v-model="settings.service_email_enabled.value"
                      inset
                      :label="settings.service_email_enabled.value ? t('yes'): t('no')"
                  ></v-switch>
  
                  <br>
  
                  <v-text-field
                      label="Email"
                      v-model="settings.service_email.value"
                      filled
                      :rules="[
                                  v => (!settings.service_email_enabled.value || (settings.service_email_enabled.value && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v))) || 'Ungültige Empfängeremail'
                              ]"
                  ></v-text-field>
  
                </v-card-text>
              </v-card>
  
            </v-row>
  
          </div>
  
        </v-form>
  
      </v-container>
  
  
      <v-snackbar
          ref="snackbar"
          v-model="snackbar"
          timeout="4000"
      >
        {{ message }}
      </v-snackbar>
  
      <transition name="fade">
        <div class="bottom-navigation modal-nav">
          <v-container fluid>
            <v-row>
              <v-btn
                  color="primary"
                  text
                  class="icon-left"
                  @click="clear()"
              >
                <v-icon>mdi-reload</v-icon>
                Zurücksetzen
              </v-btn>
              <v-spacer></v-spacer>
              <app-dialog-confirm-save
                  :validateForm="$refs.form"
                  @confirmed="event => update(event)"
              />
            </v-row>
          </v-container>
        </div>
      </transition>
  
    </div>
  
  </template>
  
  
  <script>
  import {HTTP} from './../auth'
  import toolbar from '../components/layouts/Navigation.vue'
  import loading from '../components/layouts/Loading.vue'
  import AppDialogConfirmSave from "../components/vuetify/AppDialogConfirmSave";
  
  export default {
    name: 'SettingsServiceTermine',
    components: {
      toolbar,
      loading,
      AppDialogConfirmSave
    },
    data() {
      return {
        data: [],
        settings: {
          service_email_enabled: {},
          service_email: {},
        },
        dataLoaded: false,
        validated: false,
        message: 'Erfolgreich gespeichert.',
        messageSuccess: 'Erfolgreich gespeichert.',
        error: null,
        errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.',
        snackbar: false
      }
    },
    created() {
      this.getData()
    },
    methods: {
      reload() {
        this.$nextTick(function () {
          this.getData()
        }.bind(this))
      },
      t: function (key) {
        return this.$t('servappt.settings.' + key);
      },
      update (isConfirmed) {
        if(!isConfirmed){
          this.clear()
        }
  
        for (var item in this.settings) {
          HTTP.patch('settings/' + this.settings[item].id, this.settings[item]).then(function (response) {
            this.message = this.messageSuccess
            this.openSnackbar()
          }.bind(this)).catch(function (error) {
            if (error.response) {
              this.message = this.errorMessage
            }
          }.bind(this))
        }
      },
      clear() {
        this.dataLoaded = false
        this.data = []
        this.getData()
      },
      getData() {
        HTTP.get('settings?key=service_email').then(function (response) {

            this.settings.service_email_enabled = response.data.filter(item => item.key === 'service_email_enabled').shift();
            this.settings.service_email_enabled.value = this.settings.service_email_enabled.value === 'true'
            this.settings.service_email = response.data.filter(item => item.key === 'service_email').shift();
            this.dataLoaded = true;
            this.error = false;
        
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            console.log(error);
            this.error = true
          }
        }.bind(this))
      },
      openSnackbar() {
        setTimeout(() => {
          this.snackbar = true
        }, 600)
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  
  .apply-setting {
    color: #FFF !important;
    background: #FF5252 !important;
  }
  
  .settings-wrapper {
    justify-content: space-between;
  
    .v-card {
      width: calc(50% - 16px);
      margin-bottom: 32px;
    }
  }
  
  </style>
  