var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('toolbar',{attrs:{"dataLoaded":true}},[_vm._v(_vm._s(_vm.t('title')))]),_c('v-container',{staticClass:"edit-screen no-hero-container",attrs:{"fluid":""}},[_c('v-row',{staticClass:"data-table-toolbar"},[_c('v-spacer'),_c('v-btn',{staticClass:"icon-left",attrs:{"elevation":"0","color":"primary","to":{ path: '/settings/work-reports/selection-lists/create'}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.t('create'))+" ")],1)],1),(_vm.dataLoaded && _vm.totalItems === 0)?_c('v-row',[_c('div',{staticClass:"empty-datatable"},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.t('no-data'))+" ")],1)]):_vm._e(),(_vm.dataLoaded && _vm.items.length > 0)?_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":_vm.initialSortBy,"sort-desc":_vm.initialSortDesc,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-options': [25, 50, 100, 200],
            pageText: '{0}-{1} von {2}',
            'items-per-page-text': _vm.t('table.items-per-page')
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":props.items,"tag":"tbody","disabled":!_vm.allowDrag},on:{"end":function($event){return _vm.onDropCallback(props.items)}}},_vm._l((props.items),function(item,propIndex){return _c('tr',{on:{"click":function($event){$event.preventDefault();}}},_vm._l((_vm.headers),function(header,index){return _c('td',{key:index,style:('width: ' + header.width + 'px; padding: 0px; margin:0px;'),on:{"click":function($event){$event.preventDefault();}}},[(header.value == 'process')?_c('div',{style:(_vm.getAlignment(header) + '; cursor:pointer;')},[_c('v-icon',[_vm._v(" mdi-drag ")])],1):(header.value == 'name')?_c('div',{style:(_vm.getAlignment(header))},[_vm._v(" "+_vm._s(item.name)+" ")]):(header.value == 'edit')?_c('div',{style:(_vm.getAlignment(header))},[_c('router-link',{staticClass:"edit-row-table",attrs:{"to":{ path: '/settings/work-reports/selection-lists/' + item.id }}},[_c('v-icon',{staticClass:"edit-row-button",attrs:{"small":""}},[_vm._v(" edit ")])],1)],1):_vm._e()])}),0)}),0)]}}],null,false,838266297)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }