<template>
  <div>
    <BasicSettingsContainer
        :title="t('title')"
        :data-loaded="dataLoaded"
        :has-changes="hasChanges"
        @reset="reset"
        :save-action="save"
    >
      <v-card elevation="0">
        <v-card-title>{{ t('subtitle') }}</v-card-title>
        <v-card-text>

          <div class="chips-wrapper">
            <v-chip
              v-for="(tag, index) in item.tags"
              close
              @click:close="removeTag(index)"
            >
              {{ tag.name }}
            </v-chip>
          </div>

          <v-divider></v-divider>

          <div class="actions-wrapper">
            <v-text-field
              :placeholder="t('placeholder.name')"
              v-model="tagName"
              counter="40"
              dense
              filled
            ></v-text-field>
            <v-btn
              color="primary"
              elevation="0"
              class="icon-left"
              :disabled="tagName === '' || tagName.length > 40"
              @click="addTag"
            >
              <v-icon>mdi-plus</v-icon>
              {{ t('action.add-tag') }}
            </v-btn>
          </div>

        </v-card-text>
      </v-card>
    </BasicSettingsContainer>
  </div>
</template>

<script>
import { HTTP } from '../auth'
import BasicSettingsContainer from "@/components/inputs/BasicSettingsContainer.vue";

export default {
  name: 'SettingsChecklistTaskTags',
  components: {
    BasicSettingsContainer
  },
  data() {
    return {
      item: {
        tags: [{ name: 'Name' }],
      },
      tagName: '',
      itemOriginal: null,
      dataLoaded: false,
    }
  },
  async created() {
    await this.getData()
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('checklists.settings.' + key);
    },
    addTag () {
      this.item.tags.push({ name: this.tagName})
      this.tagName = ''
    },
    removeTag (index) {
      this.item.tags.splice(index, 1)
    },
    async getData() {
      this.dataLoaded = false
      let response = await HTTP.get('settings/checklist/tags')
      this.item.tags = response.data
      this.itemOriginal = JSON.parse(JSON.stringify(this.item))
      this.dataLoaded = true
    },
    async reset() {
      await this.getData()
    },
    async save() {
      await HTTP.post('settings/checklist/tags', this.item)
      await this.getData()
    },
  }
}
</script>

<style scoped lang="scss">

.chips-wrapper {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.actions-wrapper {
  display: flex;
  margin-left: auto;
  margin-top: 16px;
  margin-bottom: -12px;
  gap: 16px;

  .v-text-field__details {
    justify-content: flex-end;
  }

  .v-btn {
    height: 40px !important;
  }
}

</style>
