<template>
    <div>
      <BasicCreateEditContainer
          :title="mode == 'create' ? t('create.title') : t('edit.title')"
          ref="editor"
          object-type="Element"
          :object-name="'Kontrollelement'"
          :data-loaded="dataLoaded"
          @cancel="close"
          :save-action="saveElement"
          @saved="onSaved"
          :allow-delete="mode == 'edit'"
          :delete-action="deleteElement"
          @deleted="onDeleted"
          :has-changes="hasChanges"
      >
        <template v-slot:content>
          <v-card elevation="0">
            <v-card-title>{{ t('card-title') }}</v-card-title>
            <v-card-text>
  
              <v-text-field
                  :label="t('placeholder')"
                  v-model="element.question"
                  counter="120"
                  filled
                  :rules="[
                    () => !!element.question || t('validation.error-question-required'),
                    () => (element.question && element.question.length <= 120) || t('validation.error-max-question-120')
                  ]"
              ></v-text-field>
  
            </v-card-text>
          </v-card> 
        </template>

        <template v-slot:meta>
            <v-card elevation="0">
                <v-card-title>{{ t('active.card-title') }}</v-card-title>
                <v-card-text>
      
                    <v-switch
                        v-model="element.is_active"
                        :label="element.is_active ? t('active.yes') : t('active.no')"
                        color="primary"
                        hide-details
                    ></v-switch>
      
                </v-card-text>
              </v-card>
        </template>
  
      </BasicCreateEditContainer>
    </div>
  </template>
  
  <script>
    import { HTTP } from '@/auth'
    import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
  
    export default {
      name: 'ObjectControlElementsCreateEdit',
      components: {
        BasicCreateEditContainer,
      },
      data () {
        return {
          element: {
            id: null,
            question: '',
            is_active: true
          },
          dataOriginal: null,
          dataLoaded: false,
          mode: null,
          error: null
        }
      },
      created() {
        this.element.id = this.$route.params.id
        if (this.element.id) {
          this.getElement()
          this.mode = 'edit'
        } else {
          this.mode = 'create'
          this.dataOriginal = JSON.parse(JSON.stringify(this.element))
          this.dataLoaded = true
        }
      },
      computed: {
        hasChanges() {
          return JSON.stringify(this.element) !== JSON.stringify(this.dataOriginal)
        },
      },
      methods: {
        t: function (key) {
          return this.$t('object-control.elements.' + key)
        },
        getElement() {
          HTTP.get('object_control_elements/' + this.element.id).then(function (response) {
            this.element = response.data
            this.dataOriginal = JSON.parse(JSON.stringify(this.element))
            this.dataLoaded = true;
          }.bind(this)).catch(function (error) {
            if (error.response.status && error.response.status === 404) {
                this.$root.infoNotification.showMessage(this.$t('object-control.elements.validation.not-found', { elementId: this.element.id }))
            } else {
              this.$root.infoNotification.showMessage(this.$t('object-control.elements.validation.unknown-error') + error.response.statusText)
            }
            this.close()
          }.bind(this))
        },
        saveElement() {
          if (this.mode == 'create') {
            return HTTP.post('object_control_elements', this.element)
          } else {
            return HTTP.patch('object_control_elements/' + this.element.id, this.element)
          }
        },
        onSaved() {
          this.dataOriginal = null
          this.close()
        },
        deleteElement() {
          return HTTP.delete('object_control_elements/' + this.element.id)
        },
        onDeleted() {
          this.dataOriginal = null
          this.close()
        },
        close() {
          this.$router.push({ name: 'ObjectControlElementsIndex' })
        }
      }
    }
  </script>