<template>
  <div>
    <BasicNewContainer
        :title="t('create.title')"
        ref="editor"
        @cancel="close"
        :saveAction="save"
        @saved="onSaved"
        :has-changes="hasChanges">
      <v-card
          elevation="0">
        <v-card-title>{{ t('details.group-title') }}</v-card-title>
        <v-card-text>

          <v-text-field
              :label="t('details.name')"
              v-model="data.title"
              counter="120"
              filled
              :rules="[
								() => !!data.title || $t('validation.required.default'),
								() => (data.title && data.title.length <= 120) || $t('validation.length.default')
							]"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </BasicNewContainer>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import BasicNewContainer from "@/components/inputs/BasicNewContainer";

export default {
  name: 'GalleryCreate',
  components: {
    BasicNewContainer,
  },
  data() {
    return {
      data: {
        title: ''
      },
      dataOriginal: null,
      indexRouteName: 'GalleryIndex'
    }
  },
  created() {
    this.data.parentFolderId = this.$route.params.parentFolderId
    this.dataOriginal = JSON.parse(JSON.stringify(this.data))
  },
  mounted() {
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    },
  },
  methods: {
    t: function (key) {
      return this.$t('galleries.detail.' + key);
    },
    save() {
      return HTTP.post('galleries/create-gallery', this.data)
    },
    async onSaved() {
      this.dataOriginal = null
      this.close()
    },
    close() {
      //this.$router.push({name: this.indexRouteName})
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
