<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter">

          <v-slide-group show-arrows>

            <v-slide-item>

              <div>
                <div class="filter-item temporary">
                  <span class="md-caption">{{ t('filter.calendar-year') }}</span>
                  <date-picker
                      v-model="time1"
                      type="year"
                      @change="dateSelected('year')"
                      value-type="date"
                  ></date-picker>
                </div>
              </div>

            </v-slide-item>

          </v-slide-group>

        </v-container>

      </div>

      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              :disabled="!readyToDownload"
              :loading="isDownloadingCSV"
              @click="saveAsCSV()"
          >
            <v-icon>mdi-download</v-icon>
            {{ t('btn-export') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>

      <v-snackbar
          ref="snackbar"
          v-model="snackbar"
          timeout="4000"
      >
        {{ message }}
      </v-snackbar>

    </div>

  </div>

</template>

<script>
import { HTTP } from '@/auth'
import moment from 'moment'
import { EventBus } from '@/event-bus.js'
import toolbar from '@/components/layouts/Navigation.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import loading from '@/components/layouts/Loading.vue'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'

export default {
  name: 'TimeSheetAllIndex',
  components: {
    AppFilterApplyButton,
    toolbar,
    DatePicker,
    loading
  },
  data() {
    return {
      dateStart: {
        timestamp: moment().format()
      },
      dateEnd: {
        timestamp: moment().format()
      },
      time1: null,
      message: null,
      isDownloadingCSV: false,
      error: null,
      httpCancelHandler: null,
      snackbar: false
    }
  },
  mounted() {
    EventBus.$on('http-error', function (msg) {
      this.message = msg
      this.isDownloadingCSV = false
      this.error = true
      this.openSnackbar()
    }.bind(this))
  },
  beforeDestroy() {
    EventBus.$off('http-error')
  },
  computed: {
    readyToDownload: function () {
      return this.time1 && !this.isDownloadingCSV
    }
  },
  methods: {
    t: function (key) {
      return this.$t('timelog-reportoverview.' + key);
    },
    dateSelected(type) {
      this.dateStart.timestamp = moment(this.time1).startOf('year').format()
      this.dateEnd.timestamp = moment(this.time1).endOf('year').format()
      this.activePeriod = type
    },
    saveAsCSV() {
      this.isDownloadingCSV = true
      var url = this.preprareUrl()

      HTTP.post('statistics/rapport/' + 'de' + url, '', {responseType: 'arraybuffer'}).then(function (response) {
        let name = 'Gesamtübersicht'
        let headers = response.headers
        let blob = new Blob([response.data], {type: headers['content-type']})
        let link = document.createElement('a')
        link.style = 'display: none'
        link.href = window.URL.createObjectURL(blob)
        link.download = 'TimeApp_' + moment().format('DDMMYYYY-HHmm') + '_' + name.replace(' ', '-') + '.xlsx'
        document.body.appendChild(link)
        link.click()
        setTimeout(function () {
          document.body.removeChild(link)
          window.URL.revokeObjectURL(blob)
        }, 100)
        this.isDownloadingCSV = false
      }.bind(this))
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 2000)
    },
    preprareUrl() {
      let queryStringParts = []
      queryStringParts.push('after=' + encodeURIComponent(this.dateStart.timestamp))
      queryStringParts.push('before=' + encodeURIComponent(this.dateEnd.timestamp))
      queryStringParts.push('year=' + moment(this.dateStart.timestamp).year())
      return '?' + queryStringParts.join('&')
    }
  }
}
</script>

<style lang="scss">
/* temp */
.filter-item.temporary {

  .md-caption {
    display: block;
    font-weight: 500;
    letter-spacing: 0.01em;
    font-size: 14px;
    line-height: 24px;
  }

  .mx-datepicker {
    margin: 0;
    padding: 0;

    .mx-input-wrapper {
      border-radius: 4px;
    }
  }
}

</style>

<style scoped lang="scss">
/* temp */
.sp-hero .filter-search-list {
  padding-top: 22px;
  // width: calc(66.66% - 134px);
}

/* temp */
.sp-app.v-application .container--fluid.hero-filter {
  padding-bottom: 28px;
}

/* temp */
.data-table-toolbar h2 {
  margin: 0;
}

/* temp */
.md-table-card {
  margin-bottom: 60px;
}

.user-selected .md-icon {
  margin: 4px !important;
  color: #FFF !important;
}

.user-selected .md-icon.delete {
  margin-left: auto !important;
}

.user-selected .md-icon.delete:hover {
  cursor: pointer;
}

</style>

