<template>
  <div>
    <BasicSettingsContainer
      :title="t('title')"
      :data-loaded="true"
      :has-changes="false"
    >
      <v-card elevation="0">
        <v-card-title>{{t('chat-system')}}
        </v-card-title>
        <v-card-text>
          <v-btn
            :label="t('chat-sync')"
            @click="syncUsers"
          >
            {{t('chat-system')}}
          </v-btn>
        </v-card-text>
      </v-card>
    </BasicSettingsContainer>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import AppDialogConfirmSave from '@/components/vuetify/AppDialogConfirmSave'
import BasicSettingsContainer from '@/components/inputs/BasicSettingsContainer'

export default {
  name: 'ChatSettings',
  components: {
    BasicSettingsContainer,
    toolbar,
    loading,
    AppDialogConfirmSave
  },
  methods: {
    t: function (key) {
      return this.$t('chat-settings.' + key);
    },
    async syncUsers() {
      await HTTP.post('chat/sync-users')
      this.$root.infoNotification.showMessage('Users have been synchronized.');
    },
  }
}
</script>
