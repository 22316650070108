<template>
  <div>
    <BasicNewContainer
        :title="t('title')"
        ref="editor"
        @cancel="close"
        :saveAction="saveItem"
        @saved="onSaved"
        :has-changes="hasChanges">
      <template>
        <v-card
            elevation="0">
          <v-card-title>{{ t('information') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <content-filter-user :readonly="typeof this.itemId !== 'undefined'" v-model="data.user" role="customer" :rules="[v => !!v || $t('validation.required.default')]"
                                     :custom-label="t('client')"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-if="this.itemId" filled :label="t('selected_date')" :value="formatSelectedDate()"
                              readonly/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <app-date-time-picker
                    :label="t('selection-period.begin-date')"
                    v-model="data.begin"
                    date-format="dd.MM.yyyy"
                    time-format="HH:mm"
                    :clearable="false"
                    :rules="[v => !!v || $t('validation.required.begin-date')]"
                    :id="'beginDate'"
                ></app-date-time-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <app-date-time-picker
                    :label="t('selection-period.end-date')"
                    v-model="data.end"
                    date-format="dd.MM.yyyy"
                    time-format="HH:mm"
                    :clearable="false"
                    :rules="[v => !!v || $t('validation.required.end-date')]"
                    :id="'endDate'"
                ></app-date-time-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field filled :label="t('name')"  :rules="[v => !!v || $t('validation.required.default')]"  v-model="data.name"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field filled :label="t('street')"  :rules="[v => !!v || $t('validation.required.default')]" v-model="data.address"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field filled :label="t('zip')" v-model="data.zip"  :rules="[v => !!v || $t('validation.required.default')]" />
              </v-col>
              <v-col>
                <v-text-field filled :label="t('city')"  :rules="[v => !!v || $t('validation.required.default')]"  v-model="data.city"/>
              </v-col>
            </v-row>
            <!--
            <v-row>
              <v-col>
                <v-text-field v-if="this.itemId" filled :label="t('area')" v-model="data.area" readonly disabled/>
              </v-col>
            </v-row>
            -->
            <v-row>
              <v-col>
                {{ t('contact') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field filled :label="t('email')"  :rules="[v => !!v || $t('validation.required.default')]"  v-model="data.email"/>
              </v-col>
              <v-col>
                <v-text-field filled :label="t('phone')" v-model="data.phone"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
              elevation="0"
          >
            <v-card-title>{{ t('push.label') }}</v-card-title>
            <v-card-text>
              <v-text-field v-model="data.push_title_create" filled :label="t('push_title.label')"
                            :rules="[v => !!v || $t('validation.required.push_title')]"
                          />

              <v-textarea
                        filled
                        :label="t('push_text')"
                        v-model="data.push_text_create"
                        :rules="[() => !!data.push_text_create || $t('validation.required.push_text')]"
                    />

            </v-card-text>
        </v-card>

      </template>

      <template v-slot:meta>
        <!-- Status -->
        <v-card
            elevation="0"
        >
          <v-card-title>{{ t('status.label') }}</v-card-title>
          <v-card-text>
            <v-select
                filled
                :label="t('status.label')"
                :title="t('status.label')"
                v-model="data.state"
                :items="states"
                item-text="name"
                item-value="value"
            ></v-select>
          </v-card-text>
        </v-card>

        <v-card
            class="mt-4"
            elevation="0"
        >
          <v-card-title>{{ t('comment-from-client') }}</v-card-title>
          <v-card-text>
            <v-textarea filled :label="t('comment-from-client')" v-model="data.request_message" readonly disabled/>
          </v-card-text>
        </v-card>

        <v-card
            class="mt-4"
            elevation="0"
        >
          <v-card-title>{{ t('comment-to-client') }}</v-card-title>
          <v-card-text>
            <v-textarea filled :label="t('comment-to-client')" v-model="data.message"/>
          </v-card-text>
        </v-card>

        <v-card
            class="mt-4"
            elevation="0"
        >
          <v-card-title>{{ t('responsible-employee') }}</v-card-title>
          <v-card-text>

            <content-filter-user
                role="admin,coordinator,employee"
                v-model="data.employee"
                :label="t('content-filter-employee')"
                :rules="[v => !!v || $t('validation.required.default')]"
            />
          </v-card-text>
        </v-card>


      </template>
    </BasicNewContainer>
  </div>
</template>

<script>
import {HTTP} from '@/auth'
import BasicNewContainer from "@/components/inputs/BasicNewContainer";
import AppDateTimePicker from "@/components/vuetify/AppDateTimePicker";
import moment from "moment/moment";
import ContentFilterUser from "@/components/filter-components/ContentFilterUser.vue";

export default {
  name: 'ServapptAppointmentsCreate',
  components: {
    ContentFilterUser,
    BasicNewContainer,
    AppDateTimePicker,
  },
  data() {
    return {
      data: {
        user_id: null,
        state: "confirmed",
        begin: null,
        end: null,
        name: null,
        address: null,
        zip: null,
        city: null,
        email: null,
        phone: null,
        message: null,
        request_message: null,
        employee: null,
        employee_id: null,
        user: null,
        request_date_id: null,
        push_title_create: '',
        push_text_create: ''
      },
      areas: [],
      states: [
        {value: "confirmed", name: this.t("status.confirmed")},
        {value: "cancelled", name: this.t("status.cancelled")},
      ],
      dataOriginal: null,
      indexRouteName: 'ServapptAppointmentsIndex'
    }
  },
  created() {
    this.itemId = this.$route.params.id
    this.getItem()
    this.dataOriginal = JSON.parse(JSON.stringify(this.data))
    this.getPushConfig();
  },
  mounted() {
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('servappt.appointments.create.' + key);
    },
    formatTime(item) {
      if (item.morning == 1 && item.afternoon == 1) {
        return this.t('time.full_day')
      } else if (item.morning == 1) {
        return this.t('time.morning')
      } else if (item.afternoon == 1) {
        return this.t('time.afternoon')
      } else {
        return this.t('time.none')
      }
    },
    formatSelectedDate() {
      return moment(this.data.date).format('DD.MM.YYYY') + " " + this.formatTime(this.data);
    },
    getPushConfig() {
      HTTP.get('servappt/push-config').then(function (response) {
        this.data.push_title_create = response.data.appointment_created_title;
        this.data.push_text_create = response.data.appointment_created_message;
       // this.areas = response.data.data
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    getItem() {
      if (!this.itemId) {
        return
      }
      HTTP.get('servappt/responsebydate/' + this.itemId).then(function (response) {
        Object.assign(this.data, response.data);
        this.data.request_date_id = this.itemId;
        this.dataOriginal = JSON.parse(JSON.stringify(this.data));
        this.dataLoaded = true
        this.data.state = this.states[0].value;
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    saveItem() {
      this.data.user_id = this.data.user.id;
      this.data.employee_id = this.data.employee.id;

      return HTTP.post('servappt/appointments', this.data);
    },
    async onSaved() {
      this.dataOriginal = null;
      this.close();
    },
    close() {
      this.$router.push({name: this.indexRouteName})
    },
  }
}
</script>

<style scoped lang="scss">
.fc-button-group {

}
</style>
