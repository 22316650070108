<template>
    <div>
        <v-autocomplete
            v-model="select"
            :loading="loading"
            :items="items"
            item-text="name"
            :search-input.sync="search"
            filled
            hide-no-data
            :label="labelData"
            append-icon="mdi-magnify"
            return-object
            :disabled="readonly"
            clearable
            :rules="rules"
            :data-cy="dataCy"
        ></v-autocomplete>
    </div>
</template>

<script>
import { CancellingHttp } from '@/infrastructure/communication'

export default {
    name: "ContentFilterCustomer",
    emits: ['item-selected'],
    props: {
        dataCy: {
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: () => false,
        },
        preselected: null,
        value: {},
        getSearchUrl: null,
        label: {
            type: String,
        }
    },
    data() {
        return {
            com: new CancellingHttp(),
            abortController: null,
            loading: false,
            items: [],
            search: null,
            select: null,
        }
    },
    computed: {
        labelData() {
            return this.label ? this.label : "Nach Kunde / Objekt suchen";
        }
    },
    watch: {
        select(val) {
            this.$emit('input', val);
            this.$emit('item-selected', val?.id, val);
        },
        search(val) {
            val && val !== this.select && this.querySelections(val)
        }
    },
    created() {
        this.com.on('loading', (val) => {
            this.loading = val
        })
        if (this.preselected) {
            this.items.push(this.preselected)
            this.select = this.preselected
        }
    },
    methods: {
        async querySelections(val) {
            let url = this.getSearchUrl(val)
            let items =  await this.com.get(url)

            if (typeof items == "object" && typeof items.data !== "undefined") {
                this.items = items.data;
            } else {
                this.items = items;
            }
        },

    }
}
</script>

<style scoped>
.v-autocomplete >>> .v-label {
    font-size: 12px;
    left: 0px !important;
}

.v-autocomplete >>> .v-input__icon {
    position: relative;
}
</style>
