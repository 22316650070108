import SendbirdChat, {BaseChannel} from "@sendbird/chat";
import {GroupChannelHandler, GroupChannelModule} from "@sendbird/chat/groupChannel";
import {BaseMessage} from "@sendbird/chat/message";

let sb = null
let user = null
let channelHandler = null
let currentChannel = null

function initChat(appId) {
    sb = SendbirdChat.init({
        appId: appId,
        modules: [new GroupChannelModule(),],
    })

    channelHandler = new GroupChannelHandler({
        onMessageReceived: (channel, message) => {
            console.log(channel)
            console.log(message)
        },
    });
    sb.groupChannel.addGroupChannelHandler('primaryChannelHandler', channelHandler);
}

async function connectToChat(userId, token) {
    console.log(userId)
    console.log(token)
    try {
        user = await sb.connect(userId, token);
        console.log('chat connected')
    } catch (err) {
        console.log(err)
    }
}

async function connectToGroupChannel(channelId) {
    currentChannel = await sb.groupChannel.getChannel(channelId);
    return currentChannel.lastMessage
}

async function loadPreviousMessages(message) {
    let params ={
        limit: 10,
    };
    let query = currentChannel.createPreviousMessageListQuery(params);
    return await query.load();
}

async function sendMessageToChannel(message, cb) {
    console.log(message)
    try {
        currentChannel
            .sendUserMessage({message: message})
            .onSucceeded(newMsg => {
                cb(newMsg)
            })
    } catch (e) {
        console.log(e)
    }
}

export {
    initChat,
    connectToChat,
    connectToGroupChannel,
    sendMessageToChannel,
    loadPreviousMessages
}
