<template>
    <div>
  
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>
  
      <v-container fluid class="edit-screen no-hero-container">
  
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div>
            <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                :to="{ path: '/settings/subject-area/create'}"
                data-cy="createButton"
            >
              <v-icon>mdi-plus</v-icon>
              {{ t('create') }}
            </v-btn>
          </div>
        </v-row>
  
        <v-row v-if="dataLoaded && items.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>
  
        <v-row v-if="dataLoaded && items.length > 0">
          <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="items.length"
              :loading="loading"
              class="elevation-0"
              :hide-default-footer="true"
              :sort-by="initialSortBy"
              :sort-desc="initialSortDesc"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-options': [25, 50, 100, 200],
                pageText: '{0}-{1} von {2}',
                'items-per-page-text': t('table.items-per-page')
              }"
          >
            <v-progress-linear v-show="loading" slot="progress" color="primary" indeterminate></v-progress-linear>
  
            <template v-slot:[`item.name`]="{ item }">
              <span class="ellipsis"><b>{{ item.name }}</b></span>
            </template>
  
            <template v-slot:[`item.user_count`]="{ item }">
              <span>{{ item.user_count }}</span>
            </template>
  
            <template v-slot:[`item.edit`]="{ item }">
  
              <router-link data-cy="editButton" :to="{ path: '/settings/subject-area/' + item.id }" class="edit-row-table">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-row>
  
      </v-container>
  
    </div>
  </template>
  
  <script>
  import {HTTP} from './../auth'
  import toolbar from '../components/layouts/Navigation.vue'
  
  export default {
    name: 'SettingsSubjectAreaIndex',
    components: {
      toolbar,
    },
    data() {
      return {
        items: null,
        totalItems: null,
        dataLoaded: false,
        error: false,
        loading: false,
        options: {
          itemsPerPage: 25
        },
        initialSortBy: 'name',
        initialSortDesc: false,
      }
    },
    created() {
      this.getItems()
    },
    computed: {
      headers() {
        return [
          {
            text: this.t('table.title'),
            value: 'title',
            sortable: false
          },
          {
            text: this.t('table.edit'),
            value: 'edit',
            sortable: false,
            align: 'center'
          }
        ]
      }
    },
    watch: {
      options: {
        handler() {
          this.getItems()
        },
        deep: true,
      }
    },
    methods: {
      t: function (key) {
        return this.$t('settings.subject-area.list.' + key);
      },
      reload() {
        this.$nextTick(function () {
          this.getItems()
        }.bind(this))
      },
      getItems() {
        HTTP.get('subject_areas', {params: this.options}).then(function (response) {
          this.items = response.data
          this.dataLoaded = true
          this.error = false
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
          }
        }.bind(this))
      }
    }
  }
  </script>
  
  <style language="scss" scoped>
  
  </style>
  