<template>
    <div>
        <BasicEditContainer
            :title="t('edit.title')"
            ref="editor"
            object-type="Task"
            :object-name="taskData.title"
            :data-loaded="dataLoaded"
            @cancel="cancel"
            :save-action="saveItem"
            @saved="onSaved"
            :delete-action="deleteItem"
            @deleted="close"
            :has-changes="hasChanges">
            <template v-slot:default>
                <v-card v-if="dataLoaded" elevation="0">
                    <v-card-title>{{ t('details.group-title') }}</v-card-title>
                    <v-card-text>

                        <v-text-field
                            :label="t('details.title') + '*'"
                            counter="120"
                            v-model="taskData.title"
                            filled
                            data-cy="title"
                            :disabled="isReadonly"
                            :rules="[
                                () => !!taskData.title || $t('validation.required.title'),
                                () => taskData.title.length <= 120 || $t('validation.length.title')
                            ]"
                        ></v-text-field>

                        <v-textarea
                            :label="t('details.description') + '*'"
                            v-model="taskData.description"
                            filled
                            data-cy="description"
                            :disabled="isReadonly"
                            :rules="[() => !!taskData.description || $t('validation.required.description')]"
                        ></v-textarea>

                        <v-text-field
                            :label="t('details.created-by')"
                            filled
                            :value="author.name + ', ' + author.firstname"
                            :disabled="true"></v-text-field>
                    </v-card-text>
                </v-card>

                <v-card
                    elevation="0"
                    class="sp-mar-top-3"
                    style="display: none;"
                >
                    <v-card-title>
                        <span v-if="canUploadImage">{{ t('picture.group-title-edit') }}</span>
                        <span v-if="!canUploadImage">{{ t('picture.group-title-readonly') }}</span>
                    </v-card-title>
                    <v-card-text>

                        <div>
                            <PictureUpload v-model="taskData.tumbnail_url"></PictureUpload>
                        </div>

                    </v-card-text>
                </v-card>

                <v-card
                    elevation="0"
                    class="sp-mar-top-3"
                >
                    <v-card-title>{{ t('pictures.group-title') }}</v-card-title>
                    <v-card-text>

                        <div v-for="image in images" class="task-image">
                            <img :src="image">
                        </div>

                    </v-card-text>
                </v-card>
            </template>

            <template v-slot:meta>
                <!-- Kategorie -->
                <v-card
                    elevation="0"
                >
                    <v-card-title>{{ t('category.group-title') }}*</v-card-title>
                    <v-card-text>

                        <v-select
                            :title="t('category.hint')"
                            :label="t('category.hint')"
                            v-model="taskData.category_id"
                            :items="taskCategories"
                            :disabled="isReadonly"
                            data-cy="category_id"
                            item-text="name"
                            item-value="id"
                            filled
                        ></v-select>

                    </v-card-text>
                </v-card>

                <!-- Zuweisung Customer -->
                <v-card
                    v-if="moduleEnabled('customers')"
                    elevation="0"
                    class="sp-mar-top-3"
                >
                    <v-card-title>{{ t('customer-assignment.group-title') }}</v-card-title>
                    <v-card-text>
                        <content-filter-customer
                            v-if="dataLoaded"
                            :readonly="isReadonly"
                            @item-selected="setCustomer"
                            :preselected="assignedCustomer"
                            data-cy="customer_id"
                            :get-search-url="getCustomerSearchUrl"
                        />
                    </v-card-text>
                </v-card>

                <!-- Zuweisung Mitarbeiter -->
                <v-card
                    elevation="0"
                    class="sp-mar-top-3"
                >
                    <v-card-title>{{ t('employee-assignment.group-title') }}*</v-card-title>
                    <v-card-text>
                        <content-filter-employee
                            v-if="dataLoaded"
                            :readonly="isReadonly"
                            v-model="assignedUser"
                            @item-selected="userSelected"
                        />
                    </v-card-text>
                </v-card>

                <!-- Erledigungsdatum -->
                <v-card
                    elevation="0"
                    class="sp-mar-top-3"
                >
                    <v-card-title>{{ t('due-date.group-title') }}*</v-card-title>
                    <v-card-text>

                        <app-date-time-picker
                            :label="t('due-date.date')"
                            v-model="taskData.due_date"
                            :disabled="isReadonly"
                            date-format="dd.MM.yyyy"
                            :clearable="false"
                            data-cy="due_date"
                            :rules="[v => !!v || $t('validation.required.due-date')]"
                            :id="'dueDate'"
                            :required="true"
                        >
                        </app-date-time-picker>

                    </v-card-text>
                </v-card>

                <!-- Push -->
                <v-card
                    elevation="0"
                    class="sp-mar-top-3"
                >
                    <v-card-title>{{ t('push.group-title') }}</v-card-title>
                    <v-card-text>

                        <v-switch
                            v-model="sendPushOnUpdate"
                            inset
                            data-cy="sendPushOnUpdate"
                            :disabled="isReadonly"
                            :label="sendPushOnUpdate ? t('push.yes'): t('push.no')"
                        ></v-switch>

                    </v-card-text>
                </v-card>

            </template>
        </BasicEditContainer>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import { hasModule } from '@/services/LicenseService'
import moment from 'moment'
import toolbar from '@/components/layouts/Navigation'
import AppSearchField from '@/components/vuetify/AppSearchField'
import AppDateTimePicker from '@/components/vuetify/AppDateTimePicker'
import PictureUpload from '@/components/inputs/PictureUpload'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import ContentFilterCustomer from '@/components/filter-components/ContentFilterCustomer'

export default {
    name: 'TaskEdit',
    components: {
        ContentFilterCustomer,
        BasicEditContainer,
        ContentFilterEmployee,
        PictureUpload,
        toolbar,
        AppSearchField,
        AppDateTimePicker
    },
    data() {
        return {
            itemId: null,
            pushData: {
                type: 'task',
                id: null,
                source: 'request'
            },
            dataLoaded: false,
            taskData: {
                id: null,
                status: null,
                category_id: null,
                title: null,
                description: null,
                author_id: null,
                assignee_id: null,
                image_filename: null,
                images: [],
                due_date: null,
                customer_id: null,
            },
            taskDataOriginal: null,
            taskCategories: [],
            author: null,
            assignedUser: null,
            assignedCustomer: null,
            sendPushOnUpdate: false,
            indexRouteName: 'Pendenzen',
        }
    },
    created() {
        this.itemId = this.$route.params.id;
        this.getTaskCategories();
        this.getTask();
    },
    computed: {
        isReadonly() {
            return this.taskData.status === 'DONE';
        },
        images() {
            let BASE_URL = process.env.VUE_APP_API_BASE_URL ?? ''
            if (!this.taskData.images) {
                return []
            }
            return this.taskData.images.map(x => BASE_URL + '/api/tasks/image/' + this.taskData.id + '/' + x)
        },
        canUploadImage() {
            return !this.taskData.thumbnail_url
        },
        hasChanges() {
            if (!this.taskDataOriginal || this.isReadonly) {
                return false
            }
            return this.contentChanged || this.sendPushOnUpdate
        },
        contentChanged() {
            return JSON.stringify(this.taskData) !== JSON.stringify(this.taskDataOriginal)
        }
    },
    methods: {
        t: function (key) {
            return this.$t('tasks.detail.' + key)
        },
        moment: function () {
            return moment()
        },
        moduleEnabled(string) {
            return hasModule(string)
        },
        setPushData() {
            this.pushData.id = this.taskData.id
        },
        async getTaskCategories() {
            await HTTP.get('tasks/categories').then(function (response) {
                response.data.categories.forEach((element) => {
                    this.taskCategories.push({id: element.id, name: element.name})
                })
            }.bind(this))
        },
        async getTask() {
            await HTTP.get('tasks/' + this.itemId).then(function (response) {
                this.taskData.id = response.data.id
                this.taskData.status = response.data.status
                this.taskData.category_id = response.data.category_id
                this.taskData.title = response.data.title
                this.taskData.description = response.data.description
                this.taskData.author_id = response.data.author_id
                this.taskData.assignee_id = response.data.assignee_id
                this.taskData.image_filename = response.data.image_filename
                this.taskData.images = response.data.images
                this.taskData.due_date = response.data.due_date
                this.taskData.customer_id = response.data.customer_id

                this.author = response.data.author
                this.assignedUser = response.data.assignee
                this.assignedCustomer = response.data.customer

                this.setPushData()

                this.taskDataOriginal = JSON.parse(JSON.stringify(this.taskData))

                this.dataLoaded = true

            }.bind(this)).catch(function (error) {
                console.log(error)
                if (!error.status) {
                    this.error = true
                    this.dataLoaded = false
                }
            }.bind(this))
        },
        async sendPush() {
            const config = {
                headers: {'content-type': 'application/json'}
            }

            var pushData = {}
            pushData.id = this.taskData.id
            pushData.type = 'tasks'
            pushData.source = 'request'

            return HTTP.post('push', pushData, config);
        },
        cancel() {
            this.close()
        },
        close() {
            this.$router.push({ name: this.indexRouteName })
        },
        saveItem() {
            if (!this.contentChanged) {
                // Don't do anything to save, but Push will be sent.
                return new Promise(function (resolve) {
                    resolve()
                });
            }

            var data = {
                title: this.taskData.title,
                description: this.taskData.description,
                due_date: this.taskData.due_date,
                assignee_id: this.taskData.assignee_id,
                category_id: this.taskData.category_id,
                customer_id: this.taskData.customer_id,
            }

            return HTTP.post('tasks/' + this.taskData.id, data)
        },
        async onSaved() {
            this.taskDataOriginal = null
            // send push if toggle set
            if (this.sendPushOnUpdate) {
                await this.sendPush()
            }
            this.close()
        },
        deleteItem() {
            return HTTP.delete('tasks/' + this.taskData.id, this.taskData)
        },
        userSelected(id, data) {
            this.assignedUser = data
            this.taskData.assignee_id = id
        },
        setCustomer(id, data) {
            this.assignedCustomer = data
            this.taskData.customer_id = id
        },
        getCustomerSearchUrl(val) {
            return 'tasks/customers?q=' + val
        }
    }
}
</script>

<style>
.mx-datepicker.disabled .mx-input-wrapper {
    background: #f3f3f3;
}

.mx-datepicker.disabled .mx-input-wrapper input {
    color: #999;
}
</style>

<style lang="scss" scoped>
.sp-search-field-lists {
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  float: none;
}

.search-results {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  padding: 8px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%);
  z-index: 100;
}

.search-results > div {
  padding: 8px;
  background: #FFF;
  transition: background 0.3s;
  cursor: pointer;
}

.search-results > div:hover {
  background: rgba(153, 153, 153, 0.2);
}

#fileUpload {
  display: none;
}

.cropper-area {
  margin-bottom: 20px;
}

.preview-area {
  width: 50%;
  max-width: 400px;
  margin-bottom: 20px;
}

.preview {
  overflow: hidden;
}

.cropped-image img {
  max-width: 100%;
}

.user-assignment-disabled .assigned-user {
  padding-top: 4px;
}

.delete-task {
  margin: 0;
  float: right;
}

.user-assignment,
.customer-assignment {
  position: relative;
  width: 342px;
  padding-right: 80px;
  margin-top: 8px;
  padding-left: 12px;
  line-height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  button {
    position: absolute;
    right: 0;
  }
}

.customer-assignment {

  button {
    position: absolute;
    right: 0;
  }
}

.task-image {
  display: inline-block;
  width: calc(50% - 8px);
  margin-bottom: 16px;
  border: 1px solid rgba(227, 227, 227, .7);
}

.task-image:nth-child(even) {
  margin-left: 8px;
}

.task-image:nth-child(odd) {
  margin-right: 8px;
}
</style>
