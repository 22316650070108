<template>
  <v-select
      class=""
      v-model="selectedFilterStatus"
      :label="$t('components.workreport-status-filter.label')"
      :items="filterStatus"
      item-text="name"
      item-value="slug"
      multiple
      chips
  ></v-select>

</template>

<script>
export default {
  name: "WorkReportStatusFilter",
  props: ['value'],
  data() {
    return {
      selectedFilterStatus: this.value,
      filterStatus: [
        {
          name: '',
          slug: 'submitted'
        },
        {
          name: '',
          slug: 'approved'
        },
        {
          name: '',
          slug: 'invoiced'
        },
        {
          name: '',
          slug: 'deleted'
        }
      ],
    }
  },
  watch: {
    selectedFilterStatus: function (val) {
      this.$emit('input', val);
    }
  },
  created() {
    let me = this;
    this.filterStatus.forEach(function (value) {
      value.name = me.$t('workreport.data.status.' + value.slug);
    });
  }
}
</script>

<style scoped>

</style>
