<template>
    <div>  
        <picture-upload-dialogue v-model="uploadedImages" @finish="finishUpload()"></picture-upload-dialogue>
        
        <div v-for="(photo,index) in imagePreviews" class="photos pictureBlock">
            <img :src="photo" alt="Preview" class="picture">
            <div class="download-container">
                <v-icon class="mdi-download" style="color: white" @click="downloadPicture(index)">mdi-download</v-icon>
                <div class="round-overlay"></div>
            </div>
            <div class="icon-container">
                <v-icon class="trash-can" @click="deletePicture(index)">mdi-minus</v-icon>
                <div class="round-overlay"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import { Buffer } from 'buffer'
import PictureUploadDialogue from './PictureUploadDialogue.vue'

export default {
    name: "PictureUploadMulti",
    components: {
        PictureUploadDialogue
    },
    data() {
        return {
            imagePreviews: [],
            uploadedImages: [],
            addedImages: [],
            init: false
        }
    },
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
        model: {
            default: () => []
        }
    },
    created()  {
        this.initialize()
    },
    watch: {
        model(newValue, oldValue) {
            this.initialize()
        }
    },
    methods: {
        initialize() {
            if (!this.init) {
                 if (typeof this.model == "object") {
                    this.model.forEach(async item => {
                        this.addedImages.push(item)

                        const response = await HTTP.get(item, {responseType: 'arraybuffer'})
                        let base64 = 'data:image/jpeg;base64,' + new Buffer(response.data, 'binary').toString('base64')

                        this.imagePreviews.push(base64)
                    })
                    this.init = true
                }
            }
        },
        async finishUpload() {
            for (const photo of this.uploadedImages) {
                const previewUrl = await this.getPreviewUrl(photo)
                this.imagePreviews.push(previewUrl)
                this.addedImages.push(photo)
            }
            this.uploadedImages = []

            const base64Images = []
            for (const imageFile of this.addedImages) {
                try {
                    if (typeof imageFile === 'object' && imageFile !== null) {
                        const base64Data = await this.fileToBase64(imageFile)
                        base64Images.push(base64Data)
                    } else {
                        base64Images.push(imageFile)
                    }
                } catch (error) {
                    console.error("Error converting file to Base64:", error)
                }
            }
            this.init = true
            this.$emit('input', base64Images)
        },
        getPreviewUrl(file) {
          return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                resolve(reader.result)
              }
              reader.onerror = (error) => {
                reject(error)
              }
              reader.readAsDataURL(file)
          });
        },
        async deletePicture(key) {
            this.imagePreviews.splice(key, 1)
            this.addedImages.splice(key, 1)

            const base64Images = []
            for (const imageFile of this.addedImages) {
                try {
                    if (typeof imageFile === 'object' && imageFile !== null) {
                        const base64Data = await this.fileToBase64(imageFile)
                        base64Images.push(base64Data)
                    } else {
                        base64Images.push(imageFile)
                    }                
                } catch (error) {
                    console.error("Error converting file to Base64:", error)
                }
            }

            this.$emit('input', base64Images);

        },
        async downloadPicture(key) {
            let imagePreview = this.imagePreviews[key]
            let addedImage = this.addedImages[key]

            let fileName = ''
            if ( addedImage instanceof File ) {
                fileName = addedImage.name
            } else {
                fileName = addedImage.split('/').reverse()[0]
            }
            const downloadLink = document.createElement("a")
            downloadLink.href = imagePreview
            downloadLink.download = fileName
            downloadLink.click()
        },  
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                    resolve(reader.result)
                }
                reader.onerror = (error) => {
                    reject(error)
                }
                reader.readAsDataURL(file)
            });
        },
        containsBase64(imageFile) {
            // Regular expression to match a base64 string pattern
            const base64Pattern = /^data:image\/(png|jpg|jpeg|gif);base64,/            
            // Check if the imageFile is a string and matches the base64 pattern
            return typeof imageFile === 'string' && base64Pattern.test(imageFile)
        }
    }
}

</script>

<style scoped>
.delete {
  float: right;
  margin: 0;
}

.pictureBlock {
    position: relative;
    display: inline-block;
    margin: 15px;
}

.picture {
    max-width:200px; margin-right: 10px; max-height:125px;
}

.icon-container {
    display: inline-block;
}

.round-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    background-color: var(--v-primary-base);
    border-radius: 50%;
    opacity: 0.9;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.trash-can, .mdi-download {
    font-size: 18px !important;
    color: white !important;
    position: relative;
    z-index: 1;
}

.drag_and_drop {
    margin-top:15px;
    border-top: 2px dashed #E0E0E0;
    height: 125px;
}

.first_title {
    margin-top:15px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.0025em;
    text-align: center;
    color: #142C4666;
}

.second_title {
    margin-top: 1rem;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.0089em;
    text-align: center;
    color: #142C46;
    text-transform: uppercase;
}

.icon-container {
    position: absolute;
    top: -5px;
    right: 5px;
}

.download-container {
    position: absolute;
    top: -5px;
    right: 35px;
}
</style>
