<template>
  <v-snackbar
      ref="snackbar"
      v-model="show"
      timeout="4000"
  >
    <div>
      <div v-if="messageType === 'message'" class="message">
        {{ message }}
      </div>
      <div v-if="messageType === 'error'" class="error">
        {{ message }}
      </div>
    </div>
  </v-snackbar>
</template>

<script>
import auth from "@/auth";

export default {
  name: "InfoNotification",
  data() {
    return {
      show: false,
      message: '',
      messageType: '',
    }
  },
  mounted() {
    auth.infoNotification = this
  },
  methods: {
    showMessage(message) {
      this.messageType = 'message'
      this.message = message;
      this.show = true;
    },
    showError(message) {
      this.messageType = 'error'
      this.message = message;
      this.show = true;
    },
    showResponseError(error) {
      if (error.response && error.response.data) {
        this.showError(error.response.data.message)
      } else {
        this.showError(error)
      }
    }
  }
}
</script>

<style scoped>
.error {
  text-color: red;
}
</style>
