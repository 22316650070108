<template>
  <div class="filter-date-picker">

    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            :label="filterLabel"
            v-model="formattedDate"
            prepend-inner-icon="mdi-calendar"
            readonly
            filled
            v-bind="attrs"
            v-on="on"
            v-on:click:prepend-inner="menu = true"
            v-on:click="menu = true"
            :clearable="clearable"
            :data-cy="dataCy"
            @click:clear="clear"
            :rules="required ? [(v) => !!v || $t('validation.required.default')] : []"
        ></v-text-field>
      
      </template>
      
      <v-date-picker
          v-model="internalDate"
          scrollable
          locale="de-de"
          :first-day-of-week="1"
          @input="applyDate"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import { VDatePicker } from 'vuetify/lib';
import moment from 'moment'
import { EventBus } from './../../event-bus'

export default {
  name: "AppFilterDatePicker",
  extends: VDatePicker,
  props: {
    filterLabel: String,
    required: false,
    date: {
      required: true
    },
    dataCy: {
      default: ''
    },
    id: {
      required: true
    },
    clearable: Boolean,
  },
  model: {
    prop: 'date',
    event: 'date'
  },
  created() {
    this.internalDate = this.date;
  },
  data() {
    return {
      internalDate: null,
      menu: false
    }
  },
  methods: {
    applyDate: function () {
      this.menu = false
      let msg = {
        newDate: this.internalDate,
        id: this.id
      }
      EventBus.$emit('filter-date-changed', msg)
      this.$emit('date', this.internalDate);
    },
    clear() {
      this.$emit('date', null);
    },
  },
  computed: {
    computedProps() {
      return {
        ...this.$props,
      }
    },
    formattedDate: {
      get() {
        return this.date ? moment(this.date).format('DD.MM.YYYY') : ''
      },
      set() {
        this.date ? moment(this.date) : ''
      }
    }
  }
}
</script>

<style scoped>

</style>
