<template>
  <div>
    <v-text-field
      :label="t('name')"
      v-model="value.name"
      data-cy="name"
      filled
      :rules="[
        () => !!value.name || $t('validation.required.default')
      ]"
    />
    <customer-edit-address v-model="value.address"/>
    <customer-edit-extension-fields v-model="value.extension_fields" address-type="company"/>
  </div>
</template>

<script>
import CustomerEditAddress from '@/views/CustomerEditAddress.vue'
import CustomerEditExtensionFields from '@/views/CustomerEditExtensionFields.vue'

export default {
  name: "CustomerEditCompany",
  components: { CustomerEditExtensionFields, CustomerEditAddress },
  props: {
    value: Object,
  },
  data() {
    return {}
  },
  methods: {
    t: function (key) {
      return this.$t('customers.edit.company.' + key);
    },
  }
}
</script>
