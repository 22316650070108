import EnrollmentsIndex from "../../views/EnrollmentsIndex";
import EnrollmentsResultsIndex from "../../views/EnrollmentsResultsIndex";
import EnrollmentsEdit from "../../views/EnrollmentsEdit";
import EnrollmentsCreate from '../../views/EnrollmentsCreate.vue'
import EnrollmentsReport from '../../views/EnrollmentsReport.vue'

const enrollmentsRoutes = [
    {
        path: '/enrollments',
        name: 'Enrollments',
        component: EnrollmentsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['enrollments'],
                requiresAuth: true
            }
        }
    },
	{
		path: '/enrollments/create',
		name: 'EnrollmentsCreate',
		component: EnrollmentsCreate,
		meta: {
			accessConfig:  {
				moduleName: ['enrollments'],
				requiresAuth: true
			}
		}
	},
    {
        path: '/enrollments/results',
        name: 'EnrollmentsResultsIndex',
        component: EnrollmentsResultsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['enrollments'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/enrollments/:id?',
        name: 'EnrollmentsSingle',
        component: EnrollmentsEdit,
        meta: {
            accessConfig:  {
                moduleName: ['enrollments'],
                requiresAuth: true
            }
        }
    },
	{
		path: '/enrollments/results/:id?',
		name: 'EnrollmentsReportSingle',
		component: EnrollmentsReport,
		meta: {
			accessConfig: {
				moduleName: ['enrollments'],
				requiresAuth: true
			}
		}
	}
]

export default enrollmentsRoutes
