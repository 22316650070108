<template>
  <div>
    <BasicCreateEditContainer
        :title="mode == 'create' ? t('create.title') : t('edit.title')"
        ref="editor"
        object-type="Folder"
        :object-name="data.name"
        :data-loaded="dataLoaded"
        @cancel="close"
        :save-action="saveFolder"
        @saved="onSaved"
        :allow-delete="mode === 'edit'"
        :delete-action="deleteFolder"
        @deleted="onDeleted"
        :has-changes="hasChanges"
        :deletion-hint-text="deletionHintText"
        :deletion-hint-list="deletionHintList"
    >
      <template v-slot:content>

        <v-card elevation="0">
          <v-card-title>{{ t('group-title') }}</v-card-title>
          <v-card-text>

            <v-text-field
                :label="t('title')"
                counter="120"
                v-model="data.name"
                filled
                :rules="[
                  () => !!data.name || $t('validation.required.title'),
                  () => (data.name && data.name.length <= 120) || $t('validation.length.title')
                ]"
            ></v-text-field>

          </v-card-text>
        </v-card>

      </template>

      <template v-slot:meta>

        <v-card elevation="0">
          <v-card-title>{{ $t('components.permissiongroup-select.label') }}</v-card-title>
          <v-card-text>

            <permission-group-selector
              v-model="data.assigned_permission_groups"
              :required="true"
              mode="dropdown"/>

          </v-card-text>
        </v-card>

        <v-card v-if="mode === 'edit'" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('move-document.group-title') }}</v-card-title>
          <v-card-text>

            <div>
              <v-btn
                  elevation="0"
                  color="primary"
                  class="icon-left"
                  @click="dialogVisible=true"
              >
                <v-icon>mdi-folder-search-outline</v-icon>
                {{ t('move-document.choose-folder') }}
              </v-btn>
              <p v-if="moveToDir" class="sp-mar-top-2">{{ t('move-document.will-be-moved') }}: <b>{{ moveToDir.name }}</b></p>
            </div>

          </v-card-text>
        </v-card>

      </template>
    </BasicCreateEditContainer>

    <MoveToFolderDialog
      :dialog="dialogVisible"
      :root-name="$t('instructions.list.title')"
      :folder-id="data.id"
      :parent-folder-id="data.parent_folder_id"
      get-url="instructions_folders"
    ></MoveToFolderDialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus.js'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
import PermissionGroupSelector from '@/components/PermissionGroupSelector.vue'
import MoveToFolderDialog from '@/components/dialogs/MoveToFolderDialog'

export default {
  name: 'InstructionsCreateEditFolder',
  components: {
    BasicCreateEditContainer,
    PermissionGroupSelector,
    MoveToFolderDialog
  },
  data() {
    return {
      data: {
        id: null,
        name: '',
        assigned_permission_groups: [],
        parent_folder_id: null
      },
      dataLoaded: false,
      dataOriginal: null,
      mode: null,
      moveToDir: null,
      deletionHintText: '',
      deletionHintList: [],
      error: null,
      dialogVisible: false,
    }
  },
  created() {
    if (this.$route.name === 'InstructionsFolderCreate') {
      const parentFolderId = this.$route.params.parent_id
      if (!isNaN(parentFolderId)) {
        this.data.parent_folder_id = parentFolderId
      }
      this.mode = 'create'
      this.dataOriginal = JSON.parse(JSON.stringify(this.data))
      this.dataLoaded = true
    }
    if (this.$route.name === 'InstructionsFolderEdit') {
      const id = this.$route.params.id
      if (!isNaN(id)) {
        this.mode = 'edit'
        this.getFolder(id)
      }
    }
    EventBus.$on('move-to-dir-select', function (folder) {
  
      this.moveToDir = folder
      this.dialogVisible = false
    }.bind(this))
  },
  beforeDestroy() {
    EventBus.$off('move-to-dir-select')
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal) || !!this.moveToDir
    }
  },
  methods: {
    t: function (key) {
      return this.$t('instructions.folder.details.' + key)
    },
    close() {
      let path = '/instructions';
      if (this.data.parent_folder_id) {
        path += '/' + this.data.parent_folder_id
      }
      this.$router.push(path)
    },
    async getFolderTree() {
      let response = await HTTP.get('instructions_folders/')
      this.allFolders = response.data.children
    },
    async getFolder(id) {
      HTTP.get('instructions_folders/' + id).then(function (response) {
        this.data = response.data
        // Prepare permission groups for select input
        const tempPermGroupsIds = []
        response.data.assigned_permission_groups.forEach((element) => {
          tempPermGroupsIds.push(element.permission_group_id)
        })
        this.data.assigned_permission_groups = tempPermGroupsIds
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {
        if (error.response.status && error.response.status === 404) {
            this.$root.infoNotification.showMessage(this.$t('instructions.folder.not-found', { folderId: id }))
        } else {
          this.$root.infoNotification.showMessage(this.$t('instructions.folder.unknown-error') + error.response.statusText)
        }
        this.close()
      }.bind(this))  
    },
    saveFolder() {
      if (this.data.id) {

        let data = this.data;

        if (this.moveToDir != null) {
          data.parent_folder_id = this.moveToDir.folder_id;
          data.change_folder = true;
        }

        return HTTP.put('instructions_folders/' + this.data.id, data)
      } else {
        return HTTP.post('instructions_folders', this.data)
      }
    },
    onSaved() {
      this.dataOriginal = null
      this.close()
    },
    deleteFolder() {
      HTTP.delete('instructions_folders/' + this.data.id)
        .then(function (response) {
          //
        }.bind(this))
        .catch(function (error) {
          if (error.response.data.errors) {
              this.message = error.response.data.message
            }
            console.log(error.response)
        }.bind(this))
    },
    onDeleted() {
      this.dataOriginal = null
      this.close()
    }
  }
}
</script>
