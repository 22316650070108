<template>
	<div class="filter-search-field">
		<v-text-field
			:label="filterLabel"
      :loading="loading"
			filled
      persistent-placeholder
			v-bind="computedProps"
			v-on="$listeners"
      data-cy="filterSearch"
		>
		</v-text-field>
	</div>
</template>
<script>
import { VTextField } from 'vuetify/lib';

export default {
  name: "AppFilterSearchField",
  extends: VTextField,
  props: {
    filterLabel: String,
    placeholder: String,
    loading: Boolean
  },
  computed: {
    computedProps() {
      return {
        ...this.$props,
      }
    }
  }
}
</script>

<style scoped>
.filter-item .v-text-field__details {
	display: none;
}
</style>