<template>
  <div>
    <BasicNewContainer
        :title="t('title')"
        ref="editor"
        @cancel="close"
        :saveAction="saveItem"
        @saved="onSaved"
        :has-changes="hasChanges"
        draft-btn
    >
      <template>
        <v-card
            elevation="0">
          <v-card-title>{{ t('group-title') }}</v-card-title>
          <v-card-text>
            <v-date-picker
                v-model="eventDate"
                @click:date="addDate"
                multiple
                color="primary"
                full-width
            ></v-date-picker>
            

            <!--  :events="events" -->
            <v-row v-for="item in this.data.request_dates" class="mt-2">
              <v-col cols="7">
                <p class="subtitle-1 mt-4">{{ item.title }}</p>
              </v-col>
              <v-col cols="2">
                <v-checkbox :label=" t('selection-period.morning')" v-model="item.morning">{{
                    t('selection-period.morning')
                  }}
                </v-checkbox>
              </v-col>
              <v-col cols="2">
                <v-checkbox :label=" t('selection-period.afternoon')"  v-model="item.afternoon">
                  {{ t('selection-period.afternoon') }}
                </v-checkbox>
              </v-col>
              <v-col cols="1" class="text-right">
                <v-btn
                    width="24"
                    height="24"
                    class="mt-5"
                    elevation="0"
                    fab
                    dark
                    small
                    color="primary"
                    @click="removeDate(item)"
                >
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
              elevation="0"
          >
        <v-card-title>{{ t('push.label') }}</v-card-title>
        <v-card-text>
          <v-text-field v-model="data.push_title" filled :label="t('push_title.label')"
                        :rules="[v => !!v || $t('validation.required.push_title')]"
                      />

          <v-textarea
                    filled
                    :label="t('push_text')"
                    v-model="data.push_text"
                    :rules="[() => !!data.push_text || $t('validation.required.push_text')]"
                />

        </v-card-text>
      </v-card>
        
      </template>

      <template v-slot:meta>
        <!-- Status -->
        <v-card
            elevation="0"
        >
          <v-card-title>{{ t('status.label') }}</v-card-title>
          <v-card-text>
            <v-select
                filled
                :label="t('status.label')"
                :title="t('status.label')"
                v-model="data.state"
                :items="states"
                item-text="name"
                item-value="value"
            ></v-select>
          </v-card-text>
        </v-card>

        <!-- Area -->
        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('area.group-title') }}*</v-card-title>
          <v-card-text>
            <v-select
                filled
                :title="t('area.select') + '*'"
                :label="t('area.select') + '*'"
                v-model="data.area"
                :items="areas"
                item-text="name"
                item-value="id"
                :rules="[v => !!v || $t('validation.required.area')]"
            ></v-select>
          </v-card-text>
        </v-card>

        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('phone.group-title') }}*</v-card-title>
          <v-card-text>
            <v-text-field v-model="data.phone" filled :label="t('phone.phone-number')"
                          :rules="[v => !!v || $t('validation.required.phone')]"
                          :placeholder="t('phone.placeholder')"/>
          </v-card-text>
        </v-card>
        <!-- Selection period -->
        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >

          <v-card-title>{{ t('selection-period.group-title') }}*</v-card-title>
          <v-card-text>

            <app-date-time-picker
                :label="t('selection-period.begin_date')"
                v-model="data.begin"
                date-format="dd.MM.yyyy"
                :clearable="false"
                :rules="[v => !!v || $t('validation.required.begin-date')]"
                :id="'beginDate'"
            ></app-date-time-picker>

            <app-date-time-picker
                :label="t('selection-period.end_date')"
                v-model="data.end"
                date-format="dd.MM.yyyy"
                :clearable="false"
                :rules="[v => !!v || $t('validation.required.end-date')]"
                :id="'endDate'"
            ></app-date-time-picker>

          </v-card-text>
        </v-card>
      </template>
    </BasicNewContainer>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import BasicNewContainer from "@/components/inputs/BasicNewContainer";
import AppDateTimePicker from "../components/vuetify/AppDateTimePicker";

import AppFilterDatePicker from "@/components/vuetify/AppFilterDatePicker";
import moment from 'moment'

export default {
  name: 'ServapptRequestsCreate',
  components: {
    AppFilterDatePicker,
    BasicNewContainer,
    AppDateTimePicker
  },
  data() {
    return {
      arrayEvents: null,
      eventDate: [],

      data: {
        area: null,
        begin: null,
        end: null,
        phone: null,
        request_dates: [],
        state: null,
        push_title: '',
        push_text: ''
      },
      areas: [],
      states: [
        {value: "draft", name: this.t("status.draft")},
        {value: "published", name: this.t("status.published")},
      ],

      dataOriginal: null,
      indexRouteName: 'ServapptRequestsIndex'
    }
  },
  created() {
    this.dataOriginal = JSON.parse(JSON.stringify(this.data));
    this.getAreas()
    this.getPushConfig()
  },
  mounted() {

  },
  computed: {
    showDraft() {
      return true
    },
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
    },
  },
  methods: {

    t: function (key) {
      return this.$t('servappt.requests.details.create.' + key);
    },

    addDate(value) {

      this.arrayEvents = this.eventDate
      this.arrayEvents.sort()
      let newDataObject = []
      for (let i = 0; i < this.arrayEvents.length; i++) {
        let tmpOb = {
          title: moment(this.arrayEvents[i]).format('ll'),
          date: this.arrayEvents[i],
          morning: typeof this.data.request_dates[i] !== "undefined" ? this.data.request_dates[i].morning : true,
          afternoon: typeof this.data.request_dates[i] !== "undefined" ? this.data.request_dates[i].afternoon : true
        }
        newDataObject.push(tmpOb)
      }
      this.data.request_dates = newDataObject
      this.data.request_dates = this.data.request_dates.sort((a,b)=> (a.date > b.date ? 1 : -1))
    },
    removeDate(value) {
      const index = this.data.request_dates.indexOf(value);
      this.data.request_dates.splice(index, 1);

      let newDataObject = []
      for (let i = 0; i < this.data.request_dates.length; i++) {
        newDataObject.push(this.data.request_dates[i].date)
      }

      this.arrayEvents = newDataObject
      this.eventDate = this.arrayEvents
    },
    getAreas() {
      HTTP.get('servappt/areas', {params: {'sortBy': 'name'}}).then(function (response) {
        this.areas = response.data.data
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },

    getPushConfig() {
      HTTP.get('servappt/push-config').then(function (response) {
        this.data.push_title = response.data.user_request_created_title;
        this.data.push_text = response.data.user_request_created_message;
       // this.areas = response.data.data
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },

    saveItem() {

      let root = this.$root;

      return HTTP.post('servappt/requests', this.data);
         
    },
    async onSaved() {
      this.dataOriginal = null;
      this.close();
    },
    close() {
      this.$router.push({name: this.indexRouteName})
    },
    checkIfEmpty(item) {
      if (item.morning == false && item.afternoon == false) {
        this.removeDate(item);
        this.addDate();
      }
    }

  },
  watch: {
    'data.request_dates': {
      handler: function (val) {
        // The 'val' parameter contains the updated value of 'data.request_dates'
        console.log('data.request_dates array changed:', val);

        // You can access individual items within the array and watch for their changes here
        val.forEach((item, index) => {
          this.$watch(
            () => val[index], // Watch for changes in the specific item at the given index
            (newItem, oldItem) => {
                if (newItem.afternoon == false && newItem.morning == false) {
                  this.removeDate(newItem);
                }
              // Perform any specific actions when an item changes here
            },
            { deep: true } // Enable deep watch for the specific item
          );
        });
      },
      deep: true // Enable deep watch for the entire 'data.request_dates' array
    }
  }
}
</script>

<style scoped lang="scss">

</style>
