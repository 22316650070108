<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('create.title') }}</toolbar>

    <v-container fluid class="edit-screen">

      <v-form ref="form">

        <v-row>

          <!-- Left Column -->
          <v-col class="content-area" cols="12" sm="12" md="8">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ t('details.group-title') }}</v-card-title>
              <v-card-text>

                <v-text-field
                    :label="t('details.title')"
                    counter="120"
                    v-model="data.title"
                    filled
                    :rules="[
								() => data.title.length <= 120 || $t('validation.length.title')
							]"
                ></v-text-field>

                <v-text-field
                    :label="t('details.description')"
                    counter="120"
                    v-model="data.description"
                    filled
                    :rules="[
								() => data.description.length <= 120 || $t('validation.length.description')
							]"
                ></v-text-field>

                <v-file-input
                    :label="t('details.choose-file')"
                    truncate-length="40"
                    show-size
                    filled
                    v-model="inputDocumentFile"
                    @selected="onDocumentFileChange($event)"
                    :rules="[
								() => !!inputDocumentFile || $t('validation.required.default')
							]"
                ></v-file-input>

                <v-text-field
                    :label="t('details.keywords')"
                    v-model="data.keywords"
                    filled
                    :hint="t('details.keywords-hint')"
                    class="has-hint-message"
                ></v-text-field>

              </v-card-text>
            </v-card>

          </v-col>

          <!-- Right Column -->
          <v-col class="meta-area" cols="12" sm="12" md="4">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ $t('components.permissiongroup-select.label') }}</v-card-title>
              <v-card-text>

                <permission-group-selector
                  v-model="data.permission_groups"
                  :required="true"
                  mode="dropdown"/>

              </v-card-text>
            </v-card>

          </v-col>

        </v-row>

      </v-form>

    </v-container>

    <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        timeout="4000"
    >
      {{ errorMessage }}
    </v-snackbar>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
                color="primary"
                text
                class="icon-left"
                @click="cancel()"
            >
              <v-icon>mdi-close</v-icon>
              {{ $t('components.basic-new-container.cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                elevation="0"
                class="icon-left"
                @click="submit()"
            >
              <v-icon>mdi-check</v-icon>
              {{ $t('components.basic-new-container.save') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import {EventBus} from './../event-bus'
import toolbar from '../components/layouts/Navigation.vue'
import PermissionGroupSelector from "@/components/PermissionGroupSelector.vue";


export default {
  name: 'DocumentsCreateData',
  components: {
    toolbar,
    PermissionGroupSelector
  },
  data() {
    return {
      processing: false,
      data: {
        title: '',
        description: '',
        permission_groups: [],
        keywords: null,
        targetDir: 'documents'
      },
      permissionGroups: null,
      inputDocumentFile: null,
      error: null,
      errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.',
      cancelHTTP: null,
      errorCancel: null,
      snackbar: false
    }
  },
  props: {
    userData: null,
    currentDir: null
  },
  created() {
    this.getPermissionGroups()
    this.data.targetDir = this.currentDir
  },
  watch: {},
  methods: {
    t: function (key) {
      return this.$t('documents.detail.' + key);
    },
    getPermissionGroups() {
      HTTP.get('permission-groups?sortBy[]=name').then(function (response) {
        this.permissionGroups = response.data.data
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {
        this.error = error
        this.openSnackbar()
      }.bind(this))
    },
    close() {
      var data = {
        message: 'Dokument «' + this.data.title + ' | ' + this.data.mimetype + '» wurde erfolgreich hochgeladen.',
        item: this.data
      }
      EventBus.$emit('update-document', data)
    },
    cancel() {
      EventBus.$emit('cancel-document')
    },
    submit() {
      if (this.$refs.form.validate()) {

        if (this.processing === true) {
          return
        }
        this.processing = true

        const config = {
          headers: {'content-type': 'multipart/form-data'}
        }

        let formData = new FormData()
        if (this.inputDocumentFile) {
          formData.append('document', this.inputDocumentFile)
        }

        formData.append('data', JSON.stringify(this.data))

        HTTP.post('documents', formData, config)
            .then(function (response) {
              this.data = response.data.document
              this.isDownloadingCSV = false
              this.close()
              EventBus.$emit('refresh-document')
              this.processing = false
            }.bind(this))
            .catch(function (error) {
              if (error.response.data.errors) {
                this.message = error.response.data.message
              }
              console.log(error.response)
              this.processing = false
            }.bind(this))
      }
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 2000)
    }
  }
}
</script>
