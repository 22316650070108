<template>
  <div>
    <BasicNewContainer
        :title="t('create.title')"
        ref="editor"
        @cancel="close"
        :saveAction="saveItem"
        @saved="onSaved"
        :has-changes="hasChanges">
      <v-card
          elevation="0">
        <v-card-title>{{ t('details.group-title') }}</v-card-title>
        <v-card-text>

          <v-text-field
              :label="t('details.name')"
              v-model="data.name"
              counter="120"
              filled
              :rules="[
								() => !!data.name || $t('validation.required.default'),
								() => (data.name && data.name.length <= 120) || $t('validation.length.default')
							]"
          ></v-text-field>
          <v-textarea
            :label="t('details.zips')"
            v-model="data.zips"
            filled
            :rules="[
								() => !!data.zips || $t('validation.required.default')
							]" />
        </v-card-text>
      </v-card>
    </BasicNewContainer>
  </div>
</template>

<script>
import { HTTP } from './../auth'
import BasicNewContainer from "@/components/inputs/BasicNewContainer";

export default {
  name: 'ServapptAreasCreate',
  components: {
    BasicNewContainer,
  },
  data() {
    return {
      data: {
        name: '',
        zips: ''
      },
      dataOriginal: null,
      indexRouteName: 'ServapptAreasIndex'
    }
  },
  created() {
    this.dataOriginal = JSON.parse(JSON.stringify(this.data));
  },
  mounted() {
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('servappt.areas.detail.' + key);
    },
    saveItem() {
      return HTTP.post('servappt/areas', this.data);
    },
    async onSaved() {
      this.dataOriginal = null;
      this.close();
    },
    close() {
      this.$router.push({ name: this.indexRouteName })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
