var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('md-layout',{staticClass:"text-center"},[(_vm.status === 'new')?_c('span',{staticClass:"status-new"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-update")])]}}],null,false,1827272835)},[_c('span',[_vm._v(_vm._s(_vm.$t('status-icons.new')))])])],1):_vm._e(),(_vm.status === 'submitted')?_c('span',{staticClass:"status-submitted"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-update")])]}}],null,false,1827272835)},[_c('span',[_vm._v(_vm._s(_vm.$t('status-icons.submitted')))])])],1):_vm._e(),(_vm.status === 'completed')?_c('span',{staticClass:"status-completed"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle-outline")])]}}],null,false,3042569798)},[_c('span',[_vm._v(_vm._s(_vm.$t('status-icons.completed')))])])],1):_vm._e(),(_vm.status === 'approved')?_c('span',{staticClass:"status-approved"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle-outline")])]}}],null,false,3042569798)},[_c('span',[_vm._v(_vm._s(_vm.$t('status-icons.approved')))])])],1):_vm._e(),(_vm.status === 'invoiced')?_c('span',{staticClass:"status-invoiced"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-export-variant")])]}}],null,false,1141236172)},[_c('span',[_vm._v(_vm._s(_vm.$t('status-icons.invoiced')))])])],1):_vm._e(),(_vm.status === 'deleted')?_c('span',{staticClass:"status-deleted"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-close-circle-outline")])]}}],null,false,3730754134)},[_c('span',[_vm._v(_vm._s(_vm.$t('status-icons.deleted')))])])],1):_vm._e(),(_vm.status === 'filed')?_c('span',{staticClass:"status-filed"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-update")])]}}],null,false,1827272835)},[_c('span',[_vm._v(_vm._s(_vm.$t('status-icons.filed')))])])],1):_vm._e(),(_vm.status === 'rejected')?_c('span',{staticClass:"status-rejected"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-close-circle-outline")])]}}],null,false,3730754134)},[_c('span',[_vm._v(_vm._s(_vm.$t('status-icons.rejected')))])])],1):_vm._e(),(_vm.status === 'reimbursed')?_c('span',{staticClass:"status-invoiced"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-export-variant")])]}}],null,false,1141236172)},[_c('span',[_vm._v(_vm._s(_vm.$t('status-icons.reimbursed')))])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }