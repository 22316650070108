<template>
	<v-card
		elevation="0"
		class="statistics-chart"
	>	
		<v-card-title>
			{{ title }}
		</v-card-title>

		<v-card-text class="pt-8">
			<line-chart :chart-data="dataCollection" :options="options" :height="440"></line-chart>
		</v-card-text>	
	</v-card>
</template>

<script>

  import LineChart from './../charts/LineChart.js'

  export default {
    components: {
      LineChart
    },
    props: {
      dataCollection: null,
	  average: null,
      title: null,
	  trend: null
    },
    data () {
      return {
        options: {
          responsive: true,
          maintainAspectRatio: false,
		  tooltips: {
			callbacks: {
			    title: ((tooltipItems, data) => {
					return tooltipItems[0].yLabel + '%'
				}),
				label: ((tooltipItems, data) => {
					let datasetIndex = tooltipItems.datasetIndex
					let dataIndex = tooltipItems.index
					return data.datasets[datasetIndex].data[dataIndex].title
				}),
				footer: ((tooltipItems, data) => {
					let datasetIndex = tooltipItems[0].datasetIndex
					return [data.datasets[datasetIndex].data[tooltipItems[0].index].date, data.datasets[datasetIndex].data[tooltipItems[0].index].footer]
				})
			}
          },
		  horizontalLine: {
			lineColor: 'rgba(169,169,169, .5)',
			textColor: 'rgba(169,169,169, .8)',
			averageValue: this.average
		  },
		  trendLine: this.trend,
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                max: 100,
				callback: (value, index, values) => {
                  return value + '%'
                }
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
			  type: 'linear',
			  ticks: {
                min: this.dataCollection.startNumberOfDays,
                max: this.dataCollection.numberOfDays - this.dataCollection.endNumberOfDays,
				callback: (value, index, values) => {
                  return ''
                }
              },
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
		    display: false
          }
        }
      }
    }
  }
</script>

<style scoped>
	.v-card__title {
		width: 100%;
		display: block;
		text-align: center;
	}
</style>
