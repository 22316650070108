<template>
  <div class='modal' v-if='showYtbModal'>
    <div class='modal-content'>
      <h1>Youtube URL einfügen</h1>
      <md-input-container >
        <label>Youtube Link</label>
        <md-input type="text" v-model="url" :required="true"></md-input>
        <span class="md-error">Bitte ein Bild auswählen</span>
      </md-input-container>
      <footer class='modal-footer'>
        <md-button  @click.native="insertVideo" class="md-raised">Video einfügen</md-button>
        <md-button  @click.native="closeModal" class="md-raised">Schliessen</md-button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      url: '',
      command: null,
      showYtbModal: false
    }
  },
  computed: {
    youtubeID () {
      return this.youtubeParser(this.url)
    }
  },
  methods: {
    youtubeParser (url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#]*).*/
      const match = url.match(regExp)
      return match && match[7].length === 11 ? match[7] : false
    },
    showYoutubeModal (command) {
      // Add the sent command
      this.command = command
      this.showYtbModal = true
    },
    insertVideo () {
      // Some check can be done here, like if `youtubeID` is not false.
      const data = {
        command: this.command,
        data: {
          src: this.youtubeID
        }
      }

      this.$emit('onConfirm', data)
      this.showYtbModal = false
    },

    closeModal () {
      this.showYtbModal = false
      this.url = ''
    }
  }
}
</script>

<style scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  width: 50%;

}

.modal-footer {
  margin-top: 10px;
}

.md-button {
	margin: 0;
}

.md-button + .md-button {
	margin-left: 10px;
}
</style>
