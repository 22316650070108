<template>
    <v-select
        class=""
        v-model="selectedFilterStatus"
        :label="$t('components.contact-type-filter.label')"
        :items="filterStatus"
        item-text="name"
        item-value="slug"
        chips
        :data-cy="dataCy"
    ></v-select>
  
  </template>
  
<script>
    import {HTTP} from "../../auth";


    export default {
        name: "ContactTypeFilter",
        model: {
            prop: 'model',
            event: 'input'
        },
        props: {
          dataCy: {
            default: ''
          },
            model: {
                type: [String, Array, Number],
                default: () => [null]       
            },
        },
        data() {
            return {
                selectedFilterStatus: this.value,
                filterStatus: [
                
                ],
            }
        },
        watch: {
            selectedFilterStatus: function (val) {
                this.$emit('input', val);
            },
            model: function(val) {
                this.selectedFilterStatus = val;
            }
        },
        created() {
            let me = this;

            this.loadData(me);
        },
        methods: {
            async loadData(me) {

                let response = await HTTP.get('qc_notifications/receipt_types')
               
                response.data.forEach(function(item) {

                    me.filterStatus.push( {
                        name: me.$t('components.contact-type-filter.data.status.' + item.title),
                        slug: item.id
                    });
                    
                })
            },
        }
    }
</script>

<style scoped>

</style>
