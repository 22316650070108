import { defineStore, acceptHMRUpdate } from 'pinia'

const store = defineStore('PermissionStore', {
    state: () => {
        return {
            permissions: []
        }
    },
    persist: true,
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const usePermissionStore = store

