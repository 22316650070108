<template>

  <md-input-container class="sp-search-field">
    <div class="sp-search-field-inner sp-flex sp-flex-1">
      <md-icon>search</md-icon>
      <md-input type="text" v-model="search" placeholder="Suchen"></md-input>
    </div>
  </md-input-container>

</template>

<script>
  import _ from 'lodash'
  import {EventBus} from './../../event-bus.js'

  export default {
    data () {
      return {
        search: '',
        result: [],
        searchArray: this.searchItems
      }
    },
    props: {
      searchItems: null
    },
    watch: {
      search: function (val) {
        this.filteredItems()
      },
      result: function (val) {
        this.resultChanged(val)
      }
    },
    created () {
      this.filteredItems()
    },
    methods: {
      filteredItems () {
        let result = this.searchArray
        let search = this.search
        if (search === '') {
          this.result = this.searchArray
        } else {
          search = search.trim().toLowerCase()
          this.result = _.filter(result, function (item) {
            if (item.name.toLowerCase().indexOf(search) !== -1) {
              return item
            }
          })
        }
      },
      resultChanged (val) {
        EventBus.$emit('filtered', val)
      }
    }
  }
</script>
