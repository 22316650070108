<template>
  <tr :class="getClass">
    <td v-for="(header, index) in headers" :key="index" :style="[{'width': header.width + 'px'}, { 'cursor': header.value == 'edit' ? 'default' : 'grab'}]" :class="[{ 'pr-0': header.value == 'drag' }]">
      <slot :item="item" :name="columnName(header)">

        <div v-if="header.value == 'drag'" :style="getAlignment(header)">
          <v-icon class="drag-handle">mdi-drag</v-icon>
        </div>
     
        <div v-if="header.value == 'question'" :style="getAlignment(header)">
          <span class="ellipsis"><b>{{ item.question }}</b></span>
        </div>

        <div v-if="header.value == 'status'" :style="getAlignment(header)">
          <span class="status-chip active" v-if="item.is_active">            
            <v-icon left>
              mdi-eye-outline
            </v-icon>
            {{ t('table.active') }}
          </span>
          <span class="status-chip inactive" v-else>
            <v-icon left>
              mdi-eye-off-outline
            </v-icon>
            {{ t('table.inactive') }}
          </span>
        </div>

        <div v-if="header.value == 'edit'" :style="getAlignment(header)">
          <router-link :to="{ name: 'ObjectControlElementsEdit', params: { id: item.id } }" class="edit-row-table">
            <v-icon
                small
                class="edit-row-button"
            >
              edit
            </v-icon>
          </router-link>
        </div>

      </slot>
    </td>
  </tr>
</template>

<script> 
  export default {
    name: "DataTableObjectControlElementRowHandler",
    props: {
      itemClass: {
        type: String,
        default: "",
      },
      rowIndex: {
        type: Number,
        default: 0,
      },
      item: {
        type: Object,
        default: () => {
          return {}
        },
      },
      headers: {
        type: Array,
        default: () => {
          return []
        },
      },
    },
    data() {
      return {}
    },
    computed: {
      getClass() {
        return this.itemClass
      }
    },
    methods: {
      t: function (key) {
        return this.$t('object-control.elements.list.' + key)
      },
      columnName(header) {
        return `item.${header.value}`
      },
      getAlignment(header) {
        const align = header.align ? header.align : "center"
        return `text-align: ${align}`
      }
    }
  }
</script>

<style scoped lang="scss">
.status-chip {
  display: inline-block; 
  font-size: 14px;
  line-height: 20px;
  padding: 1px 8px;
  border-radius: 8px;

  &.active {
    color: var(--v-success-base);
    background: rgba(112, 210, 115, 0.19);

    .v-icon {
      color: var(--v-success-base);
    }
  }

  &.inactive {
    color: var(--v-warning-base);
    background: rgba(254, 197, 125, 0.24);

    .v-icon {
      color: var(--v-warning-base);
    }
  }
}
</style>