import router from './../router'
import {routes} from '../router/index.js'

import moment from 'moment'
import store from 'store'
import axios from 'axios'
import { EventBus } from './../event-bus'
import { usePermissionStore } from '@/stores/PermissionStore'
import jwt_decode from "jwt-decode"
import { initChat } from '@/services/ChatService'
import { hasModule } from '@/services/LicenseService'

// URL and endpoint
let API_BASE_URL = process.env.VUE_APP_API_BASE_URL ?? ''
let API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH ?? '/api'
export var API = API_BASE_URL + API_BASE_PATH

function requireAuth() {
    let requireAuth = true;
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const path = url.pathname;

    router.options.routes.forEach(route => {
        if (path == route.path) {
            if (typeof route.meta !== "undefined" && typeof route.meta.accessConfig !== "undefined" && typeof route.meta.accessConfig.requiresAuth !== "undefined") {
                requireAuth = route.meta.accessConfig.requiresAuth;
            }
        }
    });
    return requireAuth;
}

async function refreshToken() {
    let rt = store.get('refresh_token')
    let response = await axios.post(API + '/oidc-refresh', {'refresh_token': rt})
    if (response.data.error) {
        throw 'Token refresh failed.'
    } else {
        saveToken(response.data)
    }
}

function saveToken(data) {
    let expires = moment().add(data.expires_in, 'seconds').utc().toISOString()
    store.set('id_token', data.access_token)
    store.set('id_token_expires', expires)
    store.set('refresh_token', data.refresh_token)
}

export var HTTP;

export default {
    user: {
        errors: {
            unauthorized: false
        }
    },
    infoNotification: null,
    loginOidc(redirect) {
        let returnUri = encodeURIComponent(redirect)
        window.location.href = API_BASE_URL + '/api/oidc-login?redirect=' + returnUri
    },
    // To log out, we just need to remove the token
    async logout(context) {
        let rt = store.get('refresh_token')
        if (rt) {
            var rtdec = jwt_decode(rt)
            let validFor = moment.unix(rtdec.exp).diff(moment(), 'seconds');
            if (validFor > 5) {
                await HTTP.post('/oidc-logout', {refresh_token: rt})
            }
        }

        this.logoutCleanup()
        await router.push('/logout')
    },
    logoutCleanup() {
        store.remove('id_token')
        store.remove('id_token_expires')
        store.remove('user')
    },
    saveUser(data) {
        store.set('user', data)
    },
    async setupAxios() {
        if (!store.get('id_token')) {
            return
        }

        const self = this;

        HTTP = axios.create({
            baseURL: API,
        })

        HTTP.interceptors.request.use(async function (config) {

            if (!requireAuth() && router.currentRoute.name !== 'LoginCallback') {
                return config
            }

            let expiry = store.get('id_token_expires')
            let validFor = moment(expiry).diff(moment(), 'seconds')
            if (validFor < 100) {
                try {
                    await refreshToken()
                } catch {
                    self.loginOidc(router.currentRoute.path)
                    return
                }
            }

            config.headers = {
                Authorization: 'Bearer ' + store.get('id_token')
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        HTTP.interceptors.response.use(function (response) {
            return response
        }, function (error) {
            if (axios.isCancel(error)) {
                return Promise.reject(error)
            }
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        this.infoNotification.showError(error.response.data.message)
                        return Promise.reject(error)
                    case 401:
                        this.logoutCleanup()
                        self.loginOidc(router.currentRoute.path)
                        EventBus.$emit('http-error', error.response.statusText)
                        return Promise.reject(error)
                    case 403:
                        this.infoNotification.showError(error.response.data.message)
                        return Promise.reject(error)
                    case 500:
                        EventBus.$emit('http-error', error.response.statusText)
                        return Promise.reject(error)
                }
            } else {
                console.log(error);
            }
            return Promise.reject(error)
        }.bind(this))
    },
    async authSaveToken(data) {
        saveToken(data)
    },
    async loadConfig() {
        let BASE_URL = process.env.VUE_APP_API_BASE_URL ?? ''
        let config = await axios.get(BASE_URL + '/client-config?v2')
     
        // set allowed modules
        store.set('modules', config.data.modules)
        store.set('theme', config['data']['theme'])
        store.set('base_url', config['data']['base_url'])
        if (hasModule('chat')) {
            initChat(config['data']['chat_sendbird_appid'])
        }

        await this.loadModules();
    },
    async loadModules() {
        let url = 'me';
        let language = localStorage.getItem("currentLocale")

        if (language == null) {
            language = "de";
        }
        url += "?v2&language=" +language;

        if (typeof HTTP !== "undefined") {
            HTTP.get(url).then(function (response) {
                
                let modules = response.data.modules;
                if (modules.length > 0) {
                    store.set('userModules', modules)
                    EventBus.$emit('module-config-changed')
                }
            }.bind(this));
        }
    },
    async loadPermissions() {
        HTTP.get('security/user/permissions').then(function (response) {
            usePermissionStore().permissions = response.data
        }.bind(this))
    },
    async loadUser() {
        await HTTP.get('me?v2').then(function (response) {
            this.saveUser(response.data)
            EventBus.$emit('login-success')
        }.bind(this))
    },
}
