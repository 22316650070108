import JobsIndex from "../../views/JobsIndex";
import JobsCreate from "../../views/JobsCreate";
import JobsEdit from "../../views/JobsEdit";
import JobsCategoriesIndex from "../../views/JobsCategoriesIndex";
import JobsCategoriesCreate from "../../views/JobsCategoriesCreate";
import JobsCategoriesEdit from "../../views/JobsCategoriesEdit";

const jobsRoutes = [
    {
        path: '/jobs',
        name: 'Jobs',
        component: JobsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['jobs'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/jobs/create',
        name: 'JobsCreate',
        component: JobsCreate,
        meta: {
            accessConfig:  {
                moduleName: ['jobs'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/jobs/:id?',
        name: 'JobsEdit',
        component: JobsEdit,
        meta: {
            accessConfig:  {
                moduleName: ['jobs'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/jobs-categories',
        name: 'JobsCategoriesIndex',
        component: JobsCategoriesIndex,
        meta: {
            accessConfig:  {
                moduleName: ['jobs'],
                requiresAuth: true
            }
        }
    },    {
        path: '/settings/jobs-categories/create',
        name: 'JobsCategoriesCreate',
        component: JobsCategoriesCreate,
        meta: {
            accessConfig:  {
                moduleName: ['jobs'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/jobs-categories/:id?',
        name: 'JobsCategoriesEdit',
        component: JobsCategoriesEdit,
        meta: {
            accessConfig:  {
                moduleName: ['jobs'],
                requiresAuth: true
            }
        }
    },
/*    {
        path: '/jobs/:id?',
        name: 'JobsSingle',
        component: JobsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['jobs'],
                requiresAuth: true
            }
        }
    }*/
]

export default jobsRoutes
