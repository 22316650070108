<template>
  <div v-if="dataLoaded">
    <BasicCreateEditContainer
      :title="mode == 'create' ? t('create.title') : t('edit.title')"
      ref="editor"
      object-type="WorkReports"
      :object-name="item.title"
      :data-loaded="dataLoaded"
      @cancel="cancel"
      :save-action="saveItem"
      @saved="onSaved"
      :allow-delete="false"
      :has-changes="hasChanges"
    >
      <template v-slot:content>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>

            <v-text-field
              :label="t('details.order-number') + '*'"
              counter="120"
              v-model="item.order_number"
              filled
              :rules="[
                () => !!item.order_number || generateRequiredMessage(t('details.order-number')),
                () =>  item.order_number && item.order_number.length <= 120 || generateLengthMessage(t('details.order-number'), 120),
              ]"
            />

            <v-text-field
              :label="t('details.object') + '*'"
              counter="120"
              v-model="item.object"
              filled
              :rules="[
                () => !!item.object || generateRequiredMessage(t('details.object')),
                () => item.object && item.object.length <= 120 || generateLengthMessage(t('details.object'), 120)
              ]"
            ></v-text-field>

            <v-text-field
              :label="t('details.client') + '*'"
              counter="120"
              v-model="item.client"
              filled
              :rules="[
                () => !!item.client || generateRequiredMessage(t('details.client')),
                () => item.client && item.client.length <= 120 || generateLengthMessage(t('details.client'), 120)
              ]"
            />
          <v-row >
            <v-col>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="t('details.begin')"
                  v-model="item.work_start"
                  id="dateStart"
                ></app-filter-date-picker>
              </div>
            </v-col>
            <v-col>
              <div class="filter-item">
                <app-filter-date-picker
                  :filterLabel="t('details.end')"
                  v-model="item.work_end"
                  id="dateEnd"
                ></app-filter-date-picker>
              </div>
            </v-col>
          </v-row>
            <content-filter-employee
              :permission-group="permission_group"
              :label="t('details.assigned') + '*'"
              v-model="item.assigned_to"
              :rules="[
                () => !!item.assigned_to || generateRequiredMessage(t('details.assigned')),
              ]"
            />

            <v-textarea
              class="mt-2"
              :label="t('details.remark')"
              v-model="item.comment"
              filled/>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('pictures.group-title') }}</v-card-title>
          <v-card-text>
            <picture-upload-multi v-model="item.documents" @finish="finishUpload();"></picture-upload-multi>
          </v-card-text>
        </v-card>

        <v-card
          v-for="list in selectionLists"
          elevation="0"
          class="sp-mar-top-3"
        >
          <v-card-title> {{ list.name }}</v-card-title>
          <v-card-text>
            <div>
              <div v-for="entry in itemsOfSelectionList(list.id)">
                <v-row>
                  <v-col cols="2">
                    <v-select
                      :label="t('type')"
                      v-model="entry.selection_list_item_id"
                      @change="copyValuesFromItem(entry)"
                      :items="selectionItemForList(list.id)"
                      item-text="name"
                      item-value="id"
                      hide-details="auto"
                      filled
                      :rules="[
                        () => !!entry.selection_list_item_id || generateRequiredMessage(t('type')),
                      ]"
                    >
                      <template slot="item" slot-scope="data">
                        {{ data.item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field type="number" step="0.01" filled :label="t('amount')" v-model="entry.amount" hide-details="auto"/>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field filled readonly
                      :label="t('unit')"
                      v-if="entry.selection_list_item_id"
                      v-model="selectionListItemById(entry.selection_list_item_id).unit"
                      hide-details="auto"/>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field filled
                      :label="t('unit-price')"
                      v-if="entry.selection_list_item_id"
                      v-model="entry.price"
                      hide-details="auto"
                      type="number" step="0.01"/>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field 
                      filled 
                      :label="t('total')" 
                      readonly 
                      :value="totalLinePrice(entry)"
                      hide-details="auto"/>
                  </v-col>
                  <v-col cols="2" align="right">
                    <md-button 
                      @click="removeItem(entry)" 
                      class="sp-text-blue sp-mar-r-0"
                      :title="t('items.remove')">
                      <md-icon class="sp-text-blue">remove_circle</md-icon>
                    </md-button>
                  </v-col>
                </v-row>

              </div>
            </div>
            <v-row>
              <v-col cols="12" align="right">
                <md-button
                  @click="addNewItem(list.id)" 
                  class="sp-text-blue sp-mar-r-0"                      
                  :title="t('items.add')">
                  <md-icon class="sp-text-blue">add_circle</md-icon>
                </md-button>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
            elevation="0"
            class="sp-mar-top-3"
        >
          <v-card-title>{{ t('overall-total') }}</v-card-title>
          <v-card-text>
            <div>
              <div>
                <v-text-field filled hide-details="auto" :label=" t('total')" readonly :value="totalPrice()"/>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:meta>
        <!-- Status -->
        <v-card elevation="0">
          <v-card-title>{{ t('status.group-title') }}</v-card-title>
          <v-card-text>
            <md-input-container>
              <label>{{ t('status.status') }}</label>
              <md-select name="status" id="status" v-model="item.status">
                <md-option v-for="status in stati" :key="status.key" :value="status.key" >
                  {{ status.value }}
                </md-option>
              </md-select>
            </md-input-container>
          </v-card-text>
        </v-card>
        <v-card
            elevation="0"
            class="sp-mar-top-3"
            v-if="mode === 'edit'"
        >
          <v-card-title>{{ t('creator.group-title') }}</v-card-title>
          <v-card-text>
            <div>
              <div class="disabled-input">
                <label>{{ t('creator.created_at') }}:</label>
                <span>{{ formatDateTime(item.created_at) }}</span>
              </div>
              <div class="disabled-input" >
                <label>{{ t('creator.created_by') }}:</label>
                <span>{{ item.created_by.display_name }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </BasicCreateEditContainer>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import loading from '@/components/layouts/Loading'
import toolbar from '@/components/layouts/Navigation'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import { generateLengthMessage, generateRequiredMessage } from '@/mixins/validationMessages'
import PictureUploadMulti from '@/components/inputs/PictureUploadMulti'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
import AppDatePicker from '@/components/vuetify/AppDatePicker'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'

export default {
  components: {
    AppFilterDatePicker,
    AppDatePicker,
    BasicCreateEditContainer,
    ContentFilterEmployee,
    loading,
    toolbar,
    DatePicker,
    PictureUploadMulti
  },
  data() {
    return {
      mode: null,
      itemId: null,
      item: {
        status: 'submitted',
        order_number: '',
        client: '',
        assigned_to: null,
        documents: [],
        items: [],
        comment: "",
        work_start:moment().format('YYYY-MM-DD'),
        work_end: moment().format('YYYY-MM-DD'),
      },
      itemOriginal: {},
      selectionLists: [],
      selectionListItems: [],
      dataLoaded: false,
      imageData: {},
      data: {
        assigned_user: null,
      },
      assignedUser: null,
      indexRouteName: 'WorkReports',
      newItem: {
        id: null,
        selection_list_id: null,
        selection_list_item_id: null,
        amount: 0,
      },
      newPicture: null,
      permission_group: null
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  created() {
    HTTP.get('work-reports/settings').then(function (response) {
      this.permission_group = response.data.permission_group
    }.bind(this)) 
    if (this.$route.name === 'WorkReportsCreate') {
      this.mode = 'create'
      HTTP.get('work-reports/selection-list').then(async function (response) {
        this.selectionLists = response.data.selection_lists
        this.selectionListItems = response.data.selection_list_items
      }.bind(this))
      this.dataLoaded = true
    }
    if (this.$route.name === 'WorkReportsEdit') {
      this.itemId = this.$route.params.id
      if (!isNaN(this.itemId)) {
        this.mode = 'edit'
        this.loadItem()
      }
    }
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return this.contentChanged
    },
    contentChanged() {
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal)
    },
    stati() {
      return [
        { key: 'submitted', value: this.$t('workreport.data.status.submitted') },
        { key: 'approved', value: this.$t('workreport.data.status.approved') },
        { key: 'invoiced', value: this.$t('workreport.data.status.invoiced') },
        { key: 'deleted', value: this.$t('workreport.data.status.deleted') }
      ]
    },
    listItemToListMap() {
      var dict = {};
      this.selectionListItems.forEach(z => {
        dict[z.id] = z.selection_list_id
      })
      return dict
    },
  },
  methods: {
    generateLengthMessage,
    generateRequiredMessage,
    t: function (key) {
      return this.$t('workreport.workreports.detail.' + key);
    },
    moment: function () {
      return moment()
    },
    removeItem(entry) {
      this.item.items.splice(this.item.items.indexOf(entry), 1)
    },
    startDate(){

    },
    addNewItem(listId) {
      this.newItem.id = null
      this.newItem.selection_list_id = listId
      this.newItem.selection_list_item_id = null
      this.newItem.amount = 0
      this.newItem.price = 0
      this.newItem.name = ''
      this.item.items.push({...this.newItem})
    },
    copyValuesFromItem(entry) {
      let sourceItem = this.selectionListItemById(entry.selection_list_item_id)
      entry.price = sourceItem.price
      entry.name = sourceItem.name
    },
    async loadItem() {
      HTTP.get('work-reports/' + this.itemId).then(async function (response) {
        this.item = response.data.item
        this.selectionLists = response.data.selection_lists
        this.selectionListItems = response.data.selection_list_items

        this.dataLoaded = true

        this.imageData = {};
      
        this.itemOriginal = JSON.parse(JSON.stringify(this.item));
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    itemsOfSelectionList(selectionListId) {
      var map = this.listItemToListMap
      return this.item.items.filter(z => {
        if (z.selection_list_id === selectionListId) {
          return true
        }
        return map[z.selection_list_item_id] === selectionListId
      })
    },
    selectionItemForList(listId) {
      return this.selectionListItems.filter(z => z.selection_list_id === listId)
    },
    selectionListItemById(selectionListItemId) {
      return this.selectionListItems.filter(z => z.id === selectionListItemId)[0]
    },
    totalLinePrice(entry) {
      return (entry.amount * entry.price).toFixed(2)
    },
    totalPrice() {
      return this.item.items
          .filter((item) => item.selection_list_item_id)
          .reduce((sum, z) => sum + z.amount * z.price, 0)
          .toFixed(2)
    },
    saveItem() {
      let sort = 0;
      this.item.items.forEach(z => {
        z.sort = sort++
      })
      if (this.mode === 'edit')
      {
        return HTTP.put('work-reports/' + this.itemId, this.item)
      }
      if (this.mode === 'create')
      {
        return HTTP.post('work-reports', this.item)
      }
    },
    async onSaved() {
      this.close();
    },
    cancel() {
      this.close();
    },
    close() {
      this.$router.push({ name: this.indexRouteName })
    },
    onDeleted() {
      this.close()
    },
  }
}
</script>
