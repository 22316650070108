<template>
  <div>
    <toolbar :dataLoaded="true">{{t('title')}}</toolbar>
    <loading :dataLoaded="dataLoaded"></loading>
    <v-container fluid v-if="dataLoaded" class="settings">
      <v-row class="settings-wrapper">
        <v-card elevation="0">
          <v-card-title>{{t('settings')}}</v-card-title>
          <v-card-text>

            <v-row>
              <v-col>
                <v-text-field :label="t('android-id')" v-model="data.android_channel_id"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox :label="t('include-instance')" v-model="data.push_include_instance"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field :label="t('instance-name')" v-model="data.push_instance_name"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>

      <v-row class="mt-8 settings-wrapper">
        <v-card elevation="0">
          <v-card-title>{{t('push-text')}}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <content-filter-employee
                    v-model="testPush.selectedUser"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="testPush.message" :label="t('test-message')"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="sendTestPush">{{ t('test-push')}}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>

    <div class="bottom-navigation modal-nav">
      <v-container fluid>
        <v-row>
          <v-btn @click="save" color="primary"
                 elevation="0"

                 class="sp-float-right icon-left">{{ $t('module-config.save') }}
          </v-btn>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/auth";
import toolbar from '../components/layouts/Navigation.vue'
import Loading from "@/components/layouts/Loading";
import store from 'store';
import {EventBus} from "@/event-bus";
import ContentFilterEmployee from "@/components/filter-components/ContentFilterEmployee.vue";

export default {
  name: "PushConfiguration",
  components: {
    ContentFilterEmployee,
    Loading,
    toolbar
  },
  data() {
    return {
      dataLoaded: false,
      data: {
        android_channel_id: '',
        push_include_instance: true,
        push_instance_name: '',
      },
      testPush: {
        selectedUser: null,
        message: 'This is a test.'
      }
    }
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    t: function (key) {
      return this.$t('push-configuration.list.' + key);
    },
    async loadData() {
      this.dataLoaded = false
      try {
        let response = await HTTP.get('push-config')
        this.data.android_channel_id = response.data.android_channel_id;
        this.data.push_include_instance = response.data.push_include_instance;
        this.data.push_instance_name = response.data.push_instance_name;
      } finally {
        this.dataLoaded = true
      }

    },
    async save() {
      let dataCopy = JSON.parse(JSON.stringify(this.data))

      this.dataLoaded = false
      try {
        await HTTP.post('push-config', dataCopy)
      } finally {
        this.dataLoaded = true
      }

      await this.loadData()

      this.$root.infoNotification.showMessage('Push configuration saved.')
    },
    async sendTestPush() {
      await HTTP.post('push-test', {
        userid: this.testPush.selectedUser.id,
        message: this.testPush.message
      })
    }
  }

}
</script>

<style scoped>

</style>
