const themesJax = {
  themes: {
    light: {
      primary: '#003D66',
      secondary: '#A2CCED',
      success: '#4CAF50',
      warning: '#FB8C00',
      error: '#FF5252',
      buttons:'#003D66'
    }
  },
}

export default themesJax
