<template>
  <div>
    <v-card elevation="0" class="sp-mar-top-3" v-if="hasModule('news')">
      <v-card-title>{{ $t('settings.permissions.news.title') }}</v-card-title>
      <v-card-text>
        <div>
          <v-checkbox label="Administrator" v-model="rolesData.news_admin"
                      @change="(event)=>setRole('news_admin', event)"/>
          <div class="hint">{{ $t('settings.permissions.news.admin-hint') }}</div>
          <v-checkbox label="Contributor" v-model="rolesData.news_contributor"
                      @change="(event)=>setRole('news_contributor', event)"/>
          <div class="hint">{{ $t('settings.permissions.news.contributor-hint') }}</div>
          <v-checkbox label="Reader" v-model="rolesData.news_reader"
                      @change="(event)=>setRole('news_reader', event)"/>
          <div class="hint">{{ $t('settings.permissions.news.reader-hint') }}</div>
        </div>
      </v-card-text>
    </v-card>

    <v-card elevation="0" class="sp-mar-top-3" v-if="hasModule('absence')">
      <v-card-title>{{ $t('settings.permissions.absences.title') }}</v-card-title>
      <v-card-text>
        <div>
          <v-checkbox label="Administrator" v-model="rolesData.absence_admin"
                      @change="(event)=>setRole('absence_admin', event)"/>
          <div class="hint">{{ $t('settings.permissions.absences.admin-hint') }}</div>
          <v-checkbox label="Export" v-model="rolesData.absence_export"
                      @change="(event)=>setRole('absence_export', event)"/>
          <div class="hint">{{ $t('settings.permissions.absences.export-hint') }}</div>
          <v-checkbox label="Contributor" v-model="rolesData.absence_contributor"
                      @change="(event)=>setRole('absence_contributor', event)"/>
          <div class="hint">{{ $t('settings.permissions.absences.contributor-hint') }}</div>
          <v-checkbox label="Creator" v-model="rolesData.absence_creator"
                      @change="(event)=>setRole('absence_creator', event)"/>
          <div class="hint">{{ $t('settings.permissions.absences.creator-hint') }}</div>
        </div>
      </v-card-text>
    </v-card>

    <v-card elevation="0" class="sp-mar-top-3" v-if="hasModule('expense')">
      <v-card-title>{{ $t('settings.permissions.expenses.title') }}</v-card-title>
      <v-card-text>
        <div>
          <v-checkbox label="Admin" v-model="rolesData.expense_admin"
                      @change="(event)=>setRole('expense_admin', event)"/>
          <div class="hint">{{ $t('settings.permissions.expenses.admin-hint') }}</div>
          <v-checkbox label="Contributor" v-model="rolesData.expense_contributor"
                      @change="(event)=>setRole('expense_contributor', event)"/>
          <div class="hint">{{ $t('settings.permissions.expenses.contributor-hint') }}</div>
          <v-checkbox label="Creator" v-model="rolesData.expense_creator"
                      @change="(event)=>setRole('expense_creator', event)"/>
          <div class="hint">{{ $t('settings.permissions.expenses.creator-hint') }}</div>
        </div>
      </v-card-text>
    </v-card>

    <v-card elevation="0" class="sp-mar-top-3" v-if="hasModule('gallery')">
      <v-card-title>{{ $t('settings.permissions.gallery.title') }}</v-card-title>
      <v-card-text>
        <div>
          <v-checkbox label="Contributor" v-model="rolesData.gallery_contributor"
                      @change="(event)=>setRole('gallery_contributor', event)"/>
          <div class="hint">{{ $t('settings.permissions.gallery.contributor-hint') }}</div>
          <v-checkbox label="Reader" v-model="rolesData.gallery_reader"
                      @change="(event)=>setRole('gallery_reader', event)"/>
          <div class="hint">{{ $t('settings.permissions.gallery.reader-hint') }}</div>
        </div>
      </v-card-text>
    </v-card>


    <v-card elevation="0" class="sp-mar-top-3" v-if="hasModule('timelog_basic')">
      <v-card-title>{{ $t('settings.permissions.timelog.title') }}</v-card-title>
      <v-card-text>
        <div>
          <v-checkbox label="Admin" v-model="rolesData.timelog_admin"
                      @change="(event)=>setRole('timelog_admin', event)"/>
          <div class="hint">{{ $t('settings.permissions.timelog.admin-hint') }}</div>
          <v-checkbox label="Contributor" v-model="rolesData.timelog_contributor"
                      @change="(event)=>setRole('timelog_contributor', event)"/>
          <div class="hint">{{ $t('settings.permissions.timelog.contributor-hint') }}</div>
          <v-checkbox label="Creator" v-model="rolesData.timelog_creator"
                      @change="(event)=>setRole('timelog_creator', event)"/>
          <div class="hint">{{ $t('settings.permissions.timelog.creator-hint') }}</div>
        </div>
      </v-card-text>
    </v-card>

    <v-card elevation="0" class="sp-mar-top-3" v-if="hasModule('timesheet')">
      <v-card-title>{{ $t('settings.permissions.timelog.title') }}</v-card-title>
      <v-card-text>
        <div>
          <v-checkbox label="Admin" v-model="rolesData.timelog_admin"
                      @change="(event)=>setRole('timelog_admin', event)"/>
          <div class="hint">{{ $t('settings.permissions.timelog.admin-hint') }}</div>
          <v-checkbox label="Contributor" v-model="rolesData.timelog_contributor"
                      @change="(event)=>setRole('timelog_contributor', event)"/>
          <div class="hint">{{ $t('settings.permissions.timelog.contributor-hint') }}</div>
          <v-checkbox label="Creator" v-model="rolesData.timelog_creator"
                      @change="(event)=>setRole('timelog_creator', event)"/>
          <div class="hint">{{ $t('settings.permissions.timelog.creator-hint') }}</div>
        </div>
      </v-card-text>
    </v-card>

    <v-card elevation="0" class="sp-mar-top-3" v-if="hasModule('work_report')">
      <v-card-title>{{ $t('settings.permissions.work-reports.title') }}</v-card-title>
      <v-card-text>
        <div>
          <v-checkbox label="Admin" v-model="rolesData.workreport_admin"
                      @change="(event)=>setRole('workreport_admin', event)"/>
          <div class="hint">{{ $t('settings.permissions.work-reports.admin-hint') }}</div>
          <v-checkbox label="Contributor" v-model="rolesData.workreport_contributor"
                      @change="(event)=>setRole('workreport_contributor', event)"/>
          <div class="hint">{{ $t('settings.permissions.work-reports.contributor-hint') }}</div>
          <v-checkbox label="Approver" v-model="rolesData.workreport_approver"
                      @change="(event)=>setRole('workreport_approver', event)"/>
          <div class="hint">{{ $t('settings.permissions.work-reports.approver-hint') }}</div>
          <v-checkbox label="Creator" v-model="rolesData.workreport_creator"
                      @change="(event)=>setRole('workreport_creator', event)"/>
          <div class="hint">{{ $t('settings.permissions.work-reports.creator-hint') }}</div>
        </div>
      </v-card-text>
    </v-card>

    <v-card elevation="0" class="sp-mar-top-3" v-if="hasModule('servappt')">
      <v-card-title>{{ $t('settings.permissions.servappt.title') }}</v-card-title>
      <v-card-text>
        <div>
          <v-checkbox :label="$t('settings.permissions.servappt.servappt_appointment_manage')"
                      v-model="rolesData.servappt_appointment_manage"
                      @change="(event)=>setRole('servappt_appointment_manage', event)"/>
          <v-checkbox :label="$t('settings.permissions.servappt.servappt_request_manage')"
                      v-model="rolesData.servappt_request_manage"
                      @change="(event)=>setRole('servappt_request_manage', event)"/>

          <v-checkbox :label=" $t('settings.permissions.servappt.servappt_area_manage')"
                      v-model="rolesData.servappt_area_manage"
                      @change="(event)=>setRole('servappt_area_manage', event)"/>
          <v-checkbox :label="$t('settings.permissions.servappt.servappt_appointment_select')"
                      v-model="rolesData.servappt_appointment_select"
                      @change="(event)=>setRole('servappt_appointment_select', event)"/>

        </div>
      </v-card-text>
    </v-card>

    <v-card elevation="0" class="sp-mar-top-3" v-if="hasModule('shop')">
      <v-card-title>{{ $t('settings.permissions.shop.title') }}</v-card-title>
      <v-card-text>
        <div>
          <v-checkbox label="Administrator" v-model="rolesData.admin"
                      @change="(event)=>setRole('shop_admin', event)"/>
          <div class="hint">{{ $t('settings.permissions.shop.admin-hint') }}</div>
          <v-checkbox label="Dispatcher" v-model="rolesData.dispatcher"
                      @change="(event)=>setRole('shop_dispatcher', event)"/>
          <div class="hint">{{ $t('settings.permissions.shop.dispatcher-hint') }}</div>

        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from 'store'

export default {
  name: 'SettingsPermissionGroupsRoles',
  props: ['value'],
  data() {
    return {
      modules: store.get('modules')
    }
  },
  computed: {
    rolesData: {
      get() {
        let roleDict = {}
        this.value.forEach(role => {
          roleDict[role] = true
        });
        return roleDict
      },
      set(newRoleDict) {
        let rolesArray = Object.keys(newRoleDict).filter(role => newRoleDict[role])
        this.$emit('input', rolesArray)
      }
    }
  },
  methods: {
    t: function (key) {
      return this.$t('settings.permission-groups.detail.' + key)
    },
    hasModule(moduleName) {
      let module = this.modules.filter(module => module.module_name === moduleName)
      return module.length ? module[0].active : false
    },
    setRole(role, active) {
      let newData = { ...this.rolesData }
      if (active) {
        newData[role] = true
      } else {
        delete newData[role]
      }
      this.rolesData = newData
    },
  }
}

</script>
