<template>
  <div class="has-hero-container">

    <toolbar :dataLoaded="true">{{ t('results.title') }}</toolbar>

    <div class="sp-hero sp-bg-light-blue">
      <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">
        <app-filter-apply-button
            v-if="filterStore.hasChanges"
            @click="applyFilter(); options.page = 1"
        ></app-filter-apply-button>

        <v-slide-group show-arrows>

          <v-slide-item>

            <div class="filter-item">
              <app-filter-date-picker
                  :filterLabel="$t('components.app-filter-date-picker.label.startdate')"
                  v-model="filterStore.filter.dateStart"
                  id="dateStart"
                  :clearable="true"
              ></app-filter-date-picker>
            </div>

          </v-slide-item>
          <v-slide-item>

            <div class="filter-item">
              <app-filter-date-picker
                  :filterLabel="$t('components.app-filter-date-picker.label.enddate')"
                  v-model="filterStore.filter.dateEnd"
                  id="dateEnd"
                  :clearable="true"
              ></app-filter-date-picker>
            </div>

          </v-slide-item>

        </v-slide-group>
      </v-container>
    </div>

    <v-container fluid class="settings">

      <v-row v-if="dataLoaded && surveys.data.length === 0">
        <div class="empty-datatable">
          <v-icon>mdi-alert-circle-outline</v-icon>
          {{ $t('surveys.list.no-data') }}
        </div>
      </v-row>

      <v-row v-else>

        <v-data-table
            :headers="headers"
            :items="surveys.data"
            :options.sync="options"
            :server-items-length="dataTotal"
            :loading="loading"
            class="elevation-0"
            :sort-by.sync="filterStore.table.sortBy"
            :sort-desc.sync="filterStore.table.sortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
							showFirstLastPage: true,
							firstIcon: 'mdi-arrow-collapse-left',
							lastIcon: 'mdi-arrow-collapse-right',
							prevIcon: 'mdi-minus',
							nextIcon: 'mdi-plus',
							'items-per-page-options': [25, 50, 100, 200],
							pageText: '{0}-{1} von {2}',
							'items-per-page-text': 'Umfragen pro Seite'
						}"
        >
          <v-progress-linear v-show="loading" slot="progress" color="primary" indeterminate></v-progress-linear>

          <template v-slot:[`item.title`]="{ item }">
            <span class="ellipsis" v-if="item.title"><b>{{ item.title }}</b></span>
          </template>

          <template v-slot:[`item.start_date`]="{ item }">
            <span class="ellipsis" v-if="item.start_date">{{ item.start_date | momentDate }}</span>
          </template>

          <template v-slot:[`item.end_date`]="{ item }">
            <span class="ellipsis" v-if="item.end_date">{{ item.end_date | momentDate }}</span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span v-if="isDraft(item)" class="survey-status material-icons status-draft" title="Entwurf">drafts</span>
            <span v-if="isPublished(item)" class="survey-status material-icons status-published"
                  title="Veröffentlicht">published_with_changes</span>
            <span v-if="!isDraft(item) && isRunning(item)" class="survey-status material-icons status-running"
                  title="Running">directions_run</span>
            <span v-if="!isDraft(item) && isFinished(item)" class="survey-status material-icons status-finished"
                  title="Abgeschlossen">done_all</span>

          </template>

          <template v-slot:[`item.participants`]="{ item }">
					  <span v-if="item.analytics_calculated"
                  class="analytics no-icon">{{ item.analytics_count + ' von ' + item.analytics_total }}</span>
            <span v-else class="analytics-loading"></span>

          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <router-link :to="{ path: '/surveys/results/'+item.id }" class="edit-row-table">
              <v-icon
                  small
                  class="edit-row-button"
              >
                edit
              </v-icon>
            </router-link>
          </template>
        </v-data-table>

      </v-row>

    </v-container>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import toolbar from '../components/layouts/Navigation.vue'
import moment from 'moment'
import AppFilterDatePicker from "../components/vuetify/AppFilterDatePicker"
import AppFilterApplyButton from "@/components/vuetify/AppFilterApplyButton";
import {useSurveyFilterStore} from "@/stores/SurveyFilterStore";

export default {
  name: 'SurveysResultsIndex',
  components: {
    toolbar,
    AppFilterDatePicker,
    AppFilterApplyButton,
    useSurveyFilterStore
  },
  setup() {
    const filterStore = useSurveyFilterStore()
    return {filterStore}
  },
  data() {
    return {
      loading: false,
      surveys: {
        page: 1,
        current_page: 1,
        last_page: 1,
        total: null,
        data: []
      },
      dataLoaded: false,
      error: false,
      dataTotal: 0,
      options: {
        finished: 1
      }
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY');
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm');
    }
  },
  created() {
    this.applyFilter()
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.title'),
          align: 'start',
          sortable: true,
          value: 'title'
        },
        {
          text: this.t('table.start'),
          value: 'start_date',
          sortable: true,
          width: 150
        },
        {
          text: this.t('table.end'),
          value: 'end_date',
          sortable: true,
          width: 150
        },
        {
          text: this.t('table.status'),
          value: 'status',
          align: 'center',
          sortable: false,
          width: 120
        },
        {
          text: this.t('table.participants'),
          value: 'participants',
          sortable: false,
          align: 'center',
          width: 150,
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 150,
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getSurveys()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('surveys.list.' + key);
    },
    moment: function () {
      return moment();
    },
    reload() {
      this.$nextTick(function () {
        this.getSurveys()
      }.bind(this))
    },
    applyFilter() {
      this.filterStore.filterApplied()
      this.reload()
    },
    buildFilterParameters() {
      let filter = {...this.filterStore.filter}
      if(this.filterStore.filter.dateStart) {
        filter.dateStart = moment(this.filterStore.filter.dateStart).utc().format()
      }
      if(this.filterStore.filter.dateEnd) {
        filter.dateEnd = moment(this.filterStore.filter.dateEnd).endOf('day').utc().format()
      }
      return filter
    },
    getSurveys() {
      this.loading = true

      let filter = this.buildFilterParameters()

      let parameters = {...this.options, ...filter}
      HTTP.get('surveys', {params: parameters}).then(function (response) {
        this.surveys.data = response.data.data
        this.surveys.data.forEach((element, index) => {
          if (!element.analytics_calculated) {
            this.getAnalyticsData(element.id, index)
          }
        })
        this.surveys.last_page = response.data.last_page
        this.surveys.current_page = response.data.current_page
        this.surveys.total = response.data.total
        this.dataTotal = response.data.total
        this.error = false
        this.dataLoaded = true
        this.loading = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    getAnalyticsData(itemId, index) {
      HTTP.get('analytics/surveys/' + itemId)
          .then(function (response) {
            if (this.surveys.data[index]) {
              this.$set(this.surveys.data[index], 'analytics_count', response.data.read_total)
              this.$set(this.surveys.data[index], 'analytics_total', response.data.total)
              this.$set(this.surveys.data[index], 'analytics_calculated', true)
            }
          }.bind(this))
          .catch(function (error) {
            //
          }.bind(this))
    },
    isDraft(survey) {
      return survey.status === 'DRAFT'
    },
    isPublished(survey) {
      return survey.status === 'PUBLISHED' && moment().isBefore(survey.start_date)
    },
    isRunning(survey) {
      return moment().isAfter(survey.start_date) && moment().isBefore(survey.end_date)
    },
    isFinished(survey) {
      return moment().isAfter(survey.end_date)
    }
  }
}
</script>

<style scoped lang="scss">
.analytics {
  display: inline-block;
  position: relative;
  padding: 0 5px 0 28px;
  font-size: 14px;

  &.no-icon {
    padding-left: 5px;
  }

  .material-icons {
    position: absolute;
    left: 0;
  }
}

.analytics-loading {
  left: 40px;
}
</style>

