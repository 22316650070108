const themesKee = {
  themes: {
    light: {
      primary: '#33CF77',
      secondary: '#DEF7E9',
      success: '#33CF77',
      warning: '#FA9941',
      error: '#EC7474',
      buttons:'#33CF77'
    }
  },
}

export default themesKee
