<template>
  <div>
    <v-autocomplete
      v-model="selectedEmployee"
      :loading="loading"
      :items="items"
      :item-text="getItemText"
      :search-input.sync="search"
      filled
      hide-no-data
      :label="label"
      append-icon="mdi-magnify"
      return-object
      :disabled="readonly"
      clearable
      :rules="rules"
      :spellcheck="false"
      data-cy="employee"
    >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-if=" item.display_name">
          {{ item.display_name }}
          <span v-if="includeInactive && !item.active">{{ $t('components.content-filter-employee-search.inactive') }}</span>
        </v-list-item-title>
        <v-list-item-title v-else>
          {{ item.firstname + ' ' + item.name }}
          <span v-if="includeInactive && !item.active">{{ $t('components.content-filter-employee-search.inactive') }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.role ? $t('components.content-filter-employee-users'+ "." + item.role) : '' }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
  </div>
</template>

<script>
import { CancellingHttp } from '@/infrastructure/communication'
import i18n from '@/i18n'

export default {
  name: "ContentFilterEmployee",
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    value: {},
    label: {
      type: String,
      default: () => i18n.t('components.content-filter-employee-search.label')
    },
    includeInactive: {
      type: Boolean,
      default: () => false,
    },
    permissionGroup: {
      default: null,
      required: false
    }
  },
  data() {
    return {
      com: new CancellingHttp(),
      abortController: null,
      loading: false,
      items: [],
      search: null,
      selectedEmployee: null,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.items.push(newVal)
        this.selectedEmployee = newVal;
      },
    },
    selectedEmployee(val) {
      this.$emit('input', val)
      this.$emit('item-selected', val?.id, val)
    },
    search(val) {
      val && val !== this.selectedEmployee && this.querySelections(val)
    },
  },
  created() {
    this.com.on('loading', (val) => {
      this.loading = val
    })
  },
  methods: {
    getItemText(item) {
      if (item.display_name) {
        return this.includeInactive ? item.display_name + this.getActiveNameAddon(item) : item.display_name
      }
      return this.includeInactive ? `${item.firstname} ${item.name}` + this.getActiveNameAddon(item) : `${item.firstname} ${item.name}`
    },
    getActiveNameAddon(user) {
      return user.active ? '' : i18n.t('components.content-filter-employee-search.inactive')
    },
    async querySelections(val) {
      let url = 'users?q=' + encodeURIComponent(val.replace(',', ''))
      if (this.permissionGroup) {
        url = 'app/customer_notes/users?q=' + encodeURIComponent(val.replace(',', ''))
      }
      url = url + '&onlyActive=' + (this.includeInactive ? 'false' : 'true')
      if (this.permissionGroup) {
        url = url + '&permission_groups=' + this.permissionGroup
      }
      let response = await this.com.get(url)
      if (response) {
        this.items = response.sort((a, b) =>
            a.display_name.localeCompare(b.display_name, undefined, {sensitivity: 'accent'})
        )
      }
    }
  }
}
</script>
