import ShopProductsIndex from '@/views/ShopProductsIndex'
import ShopProductsCreateEdit from '@/views/ShopProductsCreateEdit'
import ShopOrdersIndex from '@/views/ShopOrdersIndex'
import ShopOrdersDetails from '@/views/ShopOrdersDetails'
import ShopCategoriesIndex from '@/views/ShopCategoriesIndex'
import ShopCategoriesCreateEdit from '@/views/ShopCategoriesCreateEdit'
import SettingsShop from '@/views/SettingsShop'
import ShopInventoryIndex from '@/views/ShopInventoryIndex'
import ShopInventoryDetails from '@/views/ShopInventoryDetails'

const shopRoutes = [
    {
        path: '/shop-products',
        name: 'ShopProductsIndex',
        component: ShopProductsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['shop'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/shop-products/create',
        name: 'ShopProductsCreate',
        component: ShopProductsCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['shop'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/shop-products/:id?',
        name: 'ShopProductsEdit',
        component: ShopProductsCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['shop'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/shop-orders',
        name: 'ShopOrdersIndex',
        component: ShopOrdersIndex,
        meta: {
            accessConfig:  {
                moduleName: ['shop'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/shop-orders/:id?',
        name: 'ShopOrdersDetails',
        component: ShopOrdersDetails,
        meta: {
            accessConfig:  {
                moduleName: ['shop'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/shop-categories',
        name: 'ShopCategoriesIndex',
        component: ShopCategoriesIndex,
        meta: {
            accessConfig:  {
                moduleName: ['shop'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/shop-categories/create',
        name: 'ShopCategoriesCreate',
        component: ShopCategoriesCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['shop'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/shop-categories/:id?',
        name: 'ShopCategoriesEdit',
        component: ShopCategoriesCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['shop'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/shop',
        name: 'SettingsShop',
        component: SettingsShop,
        meta: {
            accessConfig:  {
                moduleName: ['shop'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/shop-inventory',
        name: 'ShopInventoryIndex',
        component: ShopInventoryIndex,
        meta: {
            accessConfig:  {
                moduleName: ['shop'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/shop-inventory/:id?',
        name: 'ShopInventoryDetails',
        component: ShopInventoryDetails,
        meta: {
            accessConfig:  {
                moduleName: ['shop'],
                requiresAuth: true
            }
        }
    }
]

export default shopRoutes
