<template>
  <div>
    <BasicEditContainer
        ref="editor"
        :data-loaded="dataLoaded"
        v-if="dataLoaded"
        :title="t('title')"
        @cancel="cancel"
        :save-action="saveItem"
        @saved="close"
        object-type="CustomerProject"
        :object-name="item.name"
        :allow-delete="false"
        :has-changes="hasChanges">
      <template v-slot:default>

        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>

            <div>

              <v-text-field
                v-if="customer"
                :label="t('details.customer')"
                v-model="calculatedName"
                filled
                readonly
            ></v-text-field>

              <div class="disabled-input">
                <v-text-field
                    :label="t('details.name') + '*'"
                    counter="120"
                    v-model="item.name"
                    data-cy="name"

                    filled
                    :rules="[() => !!item.name || $t('validation.required.name')]"
                ></v-text-field>
              </div>

              <div class="disabled-input">
                <v-text-field
                    :label="t('details.cost-center')"
                    counter="120"
                    v-model="item.cost_center"
                    data-cy="cost_center"
                    filled
                ></v-text-field>
              </div>
              <div class="disabled-input">
                <v-text-field
                    :label="t('details.purchase-document')"
                    counter="120"
                    v-model="item.purchase_document"
                    data-cy="purchase_document"
                    filled
                ></v-text-field>
              </div>

              <v-checkbox v-model="item.time_type">
                <template v-slot:label>
                  <div>
                    {{ t('details.time_type') }}
                  </div>
                </template>
              </v-checkbox>
            </div>

          </v-card-text>
        </v-card>
      </template>
      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ t('status.group-title') }}</v-card-title>
          <v-card-text>

            <v-switch
                v-model="item.is_active"
                inset
                :label="t(item.is_active ? 'status.active': 'status.inactive')"
            ></v-switch>

          </v-card-text>
        </v-card>

      </template>
    </BasicEditContainer>
  </div>
</template>

<script>
import { HTTP } from "@/auth";
import BasicEditContainer from "@/components/inputs/BasicEditContainer";

export default {
  name: "CustomerProjectEdit",
  components: {
    BasicEditContainer,
  },
  data() {
    return {
      itemId: null,
      dataLoaded: false,
      loading: false,
      customer: null,
      item: {
        name: '',
        cost_center: '',
        is_active: true,
        purchase_document: '',
        customer_id: null,
        time_type: false
      },
      itemOriginal: null,
      dataEdited: false,
    }
  },
  created() {
    this.itemId = this.$route.params.id;
    this.loadData();
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
    calculatedName() {
     
     let name = '';
     if (this.customer != null) {
       if (this.customer.private != null && this.customer.private.firstname != null) {
         name = this.customer.private.firstname + ' ' + this.customer.private.lastname;
       } 
      
       if (this.customer.company != null && this.customer.company.name != null) {
         name = this.customer.company.name;
       } 
     }

     return name;
   }
  },
  methods: {
    t: function (key) {
      return this.$t('customer-projects.detail.' + key);
    },
    loadData() {
      this.loading = true;
      HTTP.get('customer-projects/' + this.itemId)
          .then(function (response) {
            this.item = response.data;
            this.itemOriginal = JSON.parse(JSON.stringify(this.item));
            this.dataLoaded = true;
            this.loadCustomer();
          }.bind(this))
          .finally(function () {
            this.loading = false;
          }.bind(this))
    },
    loadCustomer() {
      HTTP.get('/customers/' + this.item.customer_id)
          .then(function (response) {
            this.customer = response.data;
          }.bind(this))
    },
    saveItem() {
      return HTTP.post('customer-projects/' + this.itemId, this.item)
    },
    cancel() {
      this.close();
    },
    close() {
      //this.$router.push({ name: this.indexRouteName })
      this.$router.go(-1);
    },
  }
}
</script>

<style scoped>

</style>
