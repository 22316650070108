<template>
  <admin-container class="sp-hero" :class="bg">
    <md-layout md-gutter md-flex="100" class="sp-flex sp-flex-row sp-flex-h-end">
      <slot></slot>
    </md-layout>
  </admin-container>
</template>

<script>
  export default {
    props: {
      'background': { type: String, required: false, default: 'light-blue' }
    },
    computed: {
      bg () { return 'sp-bg-' + this.background }
    }
  }
</script>
