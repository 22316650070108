<template>
  <div>
    <BasicNewContainer
        :title="t('title')"
        ref="editor"
        @cancel="close"
        :saveAction="saveItem"
        @saved="onSaved"
        :has-changes="hasChanges">
      <template v-slot:default>

        <!-- Projekt Details -->
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
                v-if="customer"
                :label="t('details.customer')"
                v-model="calculatedName"
                filled
                readonly
                
            ></v-text-field>
            <v-text-field
                :label="t('details.name') + '*'"
                counter="120"
                v-model="item.name"
                data-cy="name"
                filled
                :rules="[() => !!item.name || $t('validation.required.name')]"
            ></v-text-field>
            <v-text-field
                :label="t('details.cost-center')"
                counter="120"
                v-model="item.cost_center"
                data-cy="cost_center"
                filled
            ></v-text-field>
            <v-text-field
                :label="t('details.purchase-document')"
                counter="120"
                v-model="item.purchase_document"
                data-cy="purchase_document"
                filled
            ></v-text-field>

            <v-checkbox v-model="item.time_type">
                <template v-slot:label>
                  <div>
                    {{ t('details.time_type') }}
                  </div>
                </template>
              </v-checkbox>
          </v-card-text>
        </v-card>


      </template>
      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ t('status.group-title') }}</v-card-title>
          <v-card-text>

            <v-switch
                v-model="item.is_active"
                inset
                :label="t(item.is_active ? 'status.active': 'status.inactive')"
            ></v-switch>

          </v-card-text>
        </v-card>

      </template>
    </BasicNewContainer>
  </div>
</template>

<script>
import BasicNewContainer from "@/components/inputs/BasicNewContainer";
import { HTTP } from "@/auth";

export default {
  name: "CustomerProjectCreate",
  components: { BasicNewContainer },
  data() {
    return {
      customerId: null,
      customer: null,
      item: {
        name: '',
        cost_center: '',
        is_active: true,
        purchase_document: '',
        time_type: false
      }
    }
  },
  created() {
    this.customerId = this.$route.query.custid;
  },
  mounted() {
    this.loadData();
  },
  computed: {
    hasChanges() {
      return this.item.name !== '' || this.item.cost_center !== ''
    },
    calculatedName() {
     
      let name = '';
      if (this.customer != null) {
        if (this.customer.private != null && this.customer.private.firstname != null) {
          name = this.customer.private.firstname + ' ' + this.customer.private.lastname;
        } 
       
        if (this.customer.company != null && this.customer.company.name != null) {
          name = this.customer.company.name;
        } 
      }

      return name;
    }
  },
  methods: {
    t: function (key) {
      return this.$t('customer-projects.detail.' + key);
    },
    loadData() {
      HTTP.get('/customers/' + this.customerId)
          .then(function (response) {
            this.customer = response.data;
          }.bind(this))
    },
    saveItem() {
      let createData = {};
      createData.customer_id = this.customerId;
      createData.name = this.item.name;
      createData.cost_center = this.item.cost_center;
      createData.is_active = this.item.is_active;
      createData.purchase_document = this.item.purchase_document;
      createData.time_type = this.item.time_type;
      return HTTP.post('customer-projects', createData);
    },
    onSaved() {
      this.close();
    },
    close() {
      this.$router.go(-1);
    },
  }
}
</script>

<style scoped>

</style>
