<template>
  <v-select
      class="shop-status-filter"
      v-model="selectedFilterStatus"
      :label="$t('components.shop-status-filter.label')"
      :items="filterStatus"
      item-text="name"
      item-value="slug"
      multiple
      chips
  ></v-select>
</template>

<script>
export default {
  name: "ShopStatusFilter",
  props: ['value'],
  data() {
    return {
      selectedFilterStatus: this.value,
      filterStatus: [
        {
          name: this.$t('shop.status.active'),
          slug: 'active'
        },
        {
          name: this.$t('shop.status.inactive'),
          slug: 'inactive'
        }
      ]
    }
  },
  watch: {
    selectedFilterStatus: function (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped lang="scss">
.shop-status-filter {

  ::v-deep .v-select__selections {

    input {
      width: 0;
    }
  }
}
</style>
