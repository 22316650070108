<template>
  <div :class="[{'is-overlay' : isOverlay}, 'edit-screen']">

    <toolbar v-if="isOverlay" :dataLoaded="dataLoaded" :cancelLeft="true" :event="true">{{ t('title') }}</toolbar>
	<toolbar v-else :dataLoaded="dataLoaded">{{ t('title') }}</toolbar>

    <loading :error="error" :dataLoaded="dataLoaded"></loading>

    <v-container v-if="dataLoaded">

		<v-row v-if="!isOverlay" class="pt-8 px-4 justify-end">
			<DeleteButton
				object-type="Report"
				:object-name="report.name"
				:processing="deleting"
				:disabled="false"
				:deletion-hint-text="deletionHintText"
				:deletion-hint-list="deletionHintList"
				@click="doDelete"></DeleteButton>
		</v-row>

		<v-layout>
			<h1 class="sp-mar-bot-3">{{ report.checklist.name }}</h1>
		</v-layout>

		<v-layout>
			<div class="sp-pad-l-2 report-info">
				<b>{{ t('object') }}</b><span>{{ report.checklist.customer.name }}</span><br>
				<b>{{ t('date') }}</b><span>{{ format(report.created_at, 'DD.MM.YYYY') }}</span><br>
				<b>{{ t('time') }}</b><span>{{ format(report.created_at, 'HH.mm') }}</span><br>
				<b>{{ t('company') }}</b><span>{{ report.coordinator.display_name }}</span>
			</div>
		</v-layout>

		<v-card elevation="0" class="mt-6">
			<v-card-title>{{ t('details') }}</v-card-title>
			<v-card-text>

				<div class="report-legende">
					<span class="legende-item done"><v-icon>mdi-check</v-icon> {{ t('legend.achieved') }}</span>
					<span class="legende-item failed"><v-icon>mdi-close</v-icon>{{ t('legend.not-achieved') }}</span>
					<span class="legende-item unrated"><v-icon>mdi-minus</v-icon>{{ t('legend.not-rated') }}</span>
				</div>

				<v-list dense>
					<v-list-item
						v-for="(item, index) in report.results"
						class="report-result"
						:key="index"
						:class="[
							{ 'is-task' : item.parent_element === null },
							{ 'last': report.results[index + 1] !== undefined && report.results[index + 1].parent_element === null },
							{ 'is-hidden': item.hidden },
							{ 'expanded': item.expanded }
						]"
					>
						<v-list-item-content>
							<span class="element-name">{{ item.element_rename }}</span>
							<span class="element-date">{{ format(item.updated_at, 'DD.MM.YYYY') }}</span>
							<span class="element-time">{{ format(item.updated_at, 'HH.mm') }}</span>
							<span class="element-comment">{{ item.remark }}</span>
							<span v-if="item.unrated" class="element-rating unrated"><v-icon>mdi-minus</v-icon></span>
							<span v-if="!item.unrated && item.value" class="element-rating done"><v-icon>mdi-check</v-icon></span>
							<span v-if="!item.unrated && !item.value" class="element-rating failed"><v-icon>mdi-close</v-icon></span>
							<span v-if="item.parent_element === null" class="element-toggle" :class="[{ 'expanded': item.expanded }]" @click="toggleParent(index)">
								<v-icon>mdi-chevron-down</v-icon>
							</span>
						</v-list-item-content>
					</v-list-item>
				</v-list>

			</v-card-text>
		</v-card>

    </v-container>

	<transition v-if="!isOverlay" name="fade">
        <div v-if="dataLoaded" class="bottom-navigation modal-nav">
          <v-container fluid>
            <v-row>
              <cancel-button-dialog
                  ref="cancelButtonDialog"
                  :data-edited="false"
                  @cancel="doCancel"></cancel-button-dialog>
            </v-row>
          </v-container>
        </div>
      </transition>

  </div>
</template>

<script>
import { HTTP } from '@/auth/index'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import moment from 'moment'
import DeleteButton from '@/components/inputs/DeleteButton'
import CancelButtonDialog from '@/components/layouts/CancelButtonDialog'

export default {
	name: 'ReportsShow',
    components: {
		toolbar,
		loading,
		DeleteButton,
		CancelButtonDialog
    },
    data () {
		return {
			report: {
				id: null,
				name: null,
				checklist: null,
				coordinator: null,
				results: [],
				filename: null
			},
			dataLoaded: false,
			deleting: false,
			deletionHintText: '',
			deletionHintList: [],
			error: false
		}
    },
	props: {
		reportId: null,
		isOverlay: {
			type: Boolean,
			default: false
		}
    },
    created () {
		this.report.id = this.reportId || this.$route.params.id;
		this.getReport()
    },
    methods: {
		t: function (key) {
				return this.$t('qc-reports.show.' + key);
		},
		reload () {
			this.$nextTick(function () {
				this.getReport()
			}.bind(this))
		},
		getReport () {
			HTTP.get('reports/' + this.report.id).then(function (response) {
				this.report = response.data
				this.report.results.forEach((result) => {
					if (result.parent_element === null) {
						this.$set(result, 'expanded', false)
					} else {
						this.$set(result, 'hidden', true)
					}
				})
				this.dataLoaded = true
				this.error = false
			}.bind(this)).catch(function (error) {
			if (!error.status) {
				this.error = true
				this.dataLoaded = true
			}
			}.bind(this))
		},
		format (date, format) {
			return moment(date).format(format)
		},
		doDelete () {
			this.deleting = true
			HTTP.delete('reports/' + this.report.id).then(function () {
			this.doCancel()
			}.bind(this)).catch(function (error) {
			if (!error.status) {
				this.error = true
				this.deleting = false
			}
			}.bind(this))
		},
		doCancel () {
			this.$router.push({ name: 'Reports' })
		},
		toggleParent(index) {
			this.report.results[index].expanded = !this.report.results[index].expanded
			let shouldSkip = false
			this.report.results.forEach((result, i) => {
				if (i > index && result.parent_element == null) {
					shouldSkip = true
				}
				if (i < index || shouldSkip) {
					return
				}
				result.hidden = !result.hidden
			})
		}
    }
}
</script>

<style lang="scss" scoped></style>
