<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="edit-screen no-hero-container">

      <v-row class="data-table-toolbar">
        <v-spacer></v-spacer>
        <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            :to="{ path: '/settings/work-reports/selection-lists/create'}"
        >
          <v-icon>mdi-plus</v-icon>
          {{ t('create') }}
        </v-btn>
      </v-row>

      <v-row v-if="dataLoaded && totalItems === 0">
        <div class="empty-datatable">
          <v-icon>mdi-alert-circle-outline</v-icon>
          {{ t('no-data') }}
        </div>
      </v-row>

      <v-row v-if="dataLoaded && items.length > 0">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            class="elevation-0"
            :sort-by="initialSortBy"
            :sort-desc="initialSortDesc"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
        >
          <v-progress-linear v-show="loading" slot="progress" color="primary" indeterminate></v-progress-linear>


          <template #body="props">
                    <draggable
                    :list="props.items"
                    tag="tbody"
                    :disabled="!allowDrag"
                    @end="onDropCallback(props.items)"
                    >
                   
                    <tr @click.prevent v-for="(item, propIndex) in props.items">
                  
                      <td  @click.prevent v-for="(header, index) in headers" :key="index" :style="'width: ' + header.width + 'px; padding: 0px; margin:0px;'">
                        
                      <div v-if="header.value == 'process'" :style=" getAlignment(header) + '; cursor:pointer;'">
                        
                        <v-icon>
                            mdi-drag
                        </v-icon>
                      </div>
                      <div v-else-if="header.value == 'name'" :style="getAlignment(header)">
                        {{ item.name }}
                      </div>

                      <div v-else-if="header.value == 'edit'" :style="getAlignment(header)">
                        <router-link :to="{ path: '/settings/work-reports/selection-lists/' + item.id }" class="edit-row-table">
                          <v-icon
                              small
                              class="edit-row-button"
                          >
                            edit
                          </v-icon>
                        </router-link>

                        </div>
                      </td>
                    </tr>

                   
                    </draggable>
                </template>
        </v-data-table>


      </v-row>

    </v-container>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import toolbar from '../components/layouts/Navigation.vue'
import draggable from 'vuedraggable';

export default {
  name: 'WorkReportsSelectionListsIndex',
  components: {
    toolbar,
    draggable
  },
  data() {
    return {
      items: null,
      totalItems: null,
      dataLoaded: false,
      loading: false,
      error: false,
      options: {
        itemsPerPage: 25
      },
      initialSortBy: 'name',
      initialSortDesc: false,
      allowDrag: true,

    }
  },
  created() {
    this.getItems()
  },
  computed: {
    headers() {
      return [
        {
            text: '#',
            align: 'center',
            sortable: false,
            value: 'process',
            width: 100
        },
        {
          text: this.t('table.name'),
          align: 'center',
          sortable: true,
          value: 'name',
          width: 500
          
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getItems()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('workreport.selectionlist.list.' + key);
    },
    getItems() {
      this.loading = true
      HTTP.get('work-reports/selection-lists', {params: this.options}).then(function (response) {
        this.items = response.data.data
        this.totalItems = response.data.total
        this.dataLoaded = true
        this.loading = false
        this.error = false
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    async onDropCallback(items) {
      let url = '/work-reports/selection-list-sort';

      await HTTP.post(url, items)
      .then(function (response) {
      }.bind(this))

    },
    getAlignment(header) {
        const align = header.align ? header.align : "center";
        return `text-align: ${align}`;
      },
  }
}
</script>

<style language="scss" scoped>

</style>
