<template>
  <div>
    <v-card elevation="0">
      <v-card-title>{{ $t('components.member-list-edit.add-user.group-title') }}</v-card-title>
      <v-card-text>
        <ContentFilterEmployee v-model="selectedUser"/>
        <div v-for="(id, idx) in idsSorted" class="analytics-user">
          <v-container v-if="usersData[id].active" class="pa-0 ma-0">
            <v-row class="pa-0 ma-0 mb-2 mt-2" no-gutters>
              <v-col cols="10" class="pl-2">
                <span>
                  <router-link :to="{ name: 'UsersEdit', params: {id: id} }">
                    {{ usersData[id].display_name }}
                  </router-link>
                </span>
              </v-col>
              <v-col cols="2">
                <div class="sp-float-right" @click="removeUser(id)" style="cursor: pointer">
                  <md-icon class="sp-text-blue cta-icon">delete</md-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ContentFilterEmployee from "@/components/filter-components/ContentFilterEmployee";

export default {
  name: "MemberListEdit",
  components: {ContentFilterEmployee},
  props: [
    'value',
    'users'
  ],
  data() {
    return {
      ids: new Set(this.value ?? []),
      usersData: this.users ?? {},
      selectedUser: null,
    }
  },
  computed: {
    idsSorted() {
      return Array.from(this.ids).sort((a, b) => {
        return this.usersData[a].display_name.localeCompare(
            this.usersData[b].display_name
        )
      })
    }
  },
  watch: {
    selectedUser: function (val, oldVal) {
      if (val) {
        this.usersData[val.id] = val
        this.ids.add(val.id)
        let firstSet = new Set(this.ids)
        let arraySorted = Array
            .from(firstSet)
            .sort((a, b) => this.usersData[a].display_name.localeCompare(this.usersData[b].display_name, undefined, {sensitivity: 'accent'}))
        this.ids = new Set(arraySorted)
        this.$emit('input', Array.from(this.ids))
      }
    },
    value: function (val) {
      this.ids = new Set(val ?? []);
    },
    users: function (val) {
      this.usersData = val;
    }
  },
  methods: {
    removeUser(id) {
      this.ids.delete(id)
      this.ids = new Set(this.ids)
      this.$emit('input', Array.from(this.ids))
    }
  }
}
</script>
