import SettingsPrivacyPolicy from "../../views/SettingsPrivacyPolicy";
import PrivacyPolicyIndex from "../../views/PrivacyPolicyIndex";

const privacyPolicyRoutes = [
    {
        path: '/settings/privacy-policy',
        name: 'SettingsPrivacyPolicy',
        component: SettingsPrivacyPolicy,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicyIndex',
        component: PrivacyPolicyIndex,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: false
            }
        }
    }
]

export default privacyPolicyRoutes
