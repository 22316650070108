<template>
  <md-table-card class="statistics-chart sp-flex-1 sp-mar-1" v-if="readyToDownload">
  <md-toolbar v-if="timeLogData.filterByOne">
    <h3 class="sp-text-white">Objekt {{timeLogData.customerId}} {{timeLogData.customerName}}</h3>
  </md-toolbar>
    <md-table class="excel-table" @sort="onSort">
      <md-table-header>
        <md-table-row>
        <md-table-head md-sort-by="startDate">Datum</md-table-head>
        <md-table-head md-sort-by="internId">Nummer</md-table-head>
        <md-table-head md-sort-by="lastName">Nachname</md-table-head>
        <md-table-head md-sort-by="firstName">Vorname</md-table-head>
        <md-table-head md-sort-by="customerName">Objekt</md-table-head>
        <md-table-head md-sort-by="startDate">Kommt</md-table-head>
        <md-table-head md-sort-by="endDate">Geht</md-table-head>
        <md-table-head md-sort-by="duration">Zeit</md-table-head>
        <md-table-head md-sort-by="totalDuration">Total</md-table-head>
        </md-table-row>
      </md-table-header>
      <md-table-body>
        <md-table-row v-for="obj in paginatedTimelogs" :key="obj.interId">
          <md-table-cell>{{ formatDateWithDay(obj.startDate) }}</md-table-cell>
          <md-table-cell>{{ obj.internId }}</md-table-cell>
          <md-table-cell>{{ obj.lastName }}</md-table-cell>
          <md-table-cell>{{ obj.firstName }}</md-table-cell>
          <md-table-cell>{{ obj.customerName }}</md-table-cell>
          <md-table-cell>{{ formatTimeShort(obj.startDate) }}</md-table-cell>
          <md-table-cell>{{ formatTimeShort(obj.endDate) }}</md-table-cell>
          <md-table-cell>{{ formatDurationAsHours(obj.duration) }}</md-table-cell>
          <md-table-cell><b>{{ formatDurationAsHours(obj.totalDuration) }}</b></md-table-cell>
        </md-table-row>
      </md-table-body>
    </md-table>
    <md-table-pagination
    :md-size="currentSize"
    :md-total="timelogs.length"
    md-page="1"
    md-label="Rows"
    md-separator="of"
    :md-page-options="[20, 50, 100]"
    @pagination="onPagination"></md-table-pagination>
  </md-table-card>
</template>

<script>
  import _ from 'lodash'
  import {EventBus} from '../../event-bus'

  export default {
    components: {
    },
    data () {
      return {
        allDataLoaded: false,
        timeLogData: null,
        timelogs: null,
        currentPage: 1,
        currentSize: 20,
        paginatedTimelogs: null

      }
    },
    props: {
      title: null,
      dataCollection: null,
      identifier: null
    },
    computed: {
      readyToDownload: function () {
        return this.allDataLoaded
      }
    },
    mounted () {
      this.allDataLoaded = false
      this.timeLogData = this.dataCollection
      this.timelogs = this.dataCollection.timelogs
      this.init()
    },
    methods: {
      checkDataLoaded () {
        if (this.timeLogData.length !== 0) {
          this.allDataLoaded = true
          return
        }
      },

      onSort (sort) {
        this.timelogs = _.orderBy(this.timelogs, [item => item[sort.name]], sort.type)
        EventBus.$emit('sorting-changed', sort)
        this.paginateTimelogs()
      },
      paginateTimelogs () {
        this.paginatedTimelogs = this.timelogs.slice((this.currentPage - 1) * this.currentSize, this.currentPage * this.currentSize)
      },
      onPagination (paging) {
        this.currentPage = paging.page
        this.currentSize = paging.size
        this.paginateTimelogs()
      },
      init () {
        this.checkDataLoaded()
        this.paginateTimelogs()

      }
    }
  }
</script>

<style scoped>
.excel-table {
  display: block;
}
</style>
