<template>
  <div>
    <BasicSettingsContainer
        :title="$t('global-settings.title')"
        :data-loaded="dataLoaded"
        :has-changes="hasChanges"
        @reset="reset"
        :save-action="save"
    >

      <v-card elevation="0">
        <v-card-title> {{ t('app-email') }}</v-card-title>
        <v-card-text>

          <v-text-field
              v-model="item.app_email"
              filled
              dense
              :rules="[
                    () => !!item.app_email ||t('required')
                  ]"
          />

        </v-card-text>
      </v-card>

      <v-card elevation="0" class="sp-mar-top-3">
        <v-card-title> {{ t('app-urls') }}</v-card-title>
        <v-card-text>

          <v-text-field
              :label="t('android')"
              v-model="item.app_url_android"
              filled
          />

          <v-text-field
              :label="t('ios')"
              v-model="item.app_url_ios"
              filled
          />
        </v-card-text>
      </v-card>


      <v-card elevation="0" class="sp-mar-top-3">
        <v-card-title>{{ t('name-display-mode.basic-configuration') }}</v-card-title>
        <v-card-text>
          <div>
            {{ t('name-display-mode.title') }}
            <v-radio-group v-model="item.name_display_mode">
              <v-radio
                  :label="t('name-display-mode.lastname-firstname')"
                  value="lastname-firstname"
              />
              <v-radio
                  :label="t('name-display-mode.firstname-lastname')"
                  value="firstname-lastname"
              />
            </v-radio-group>
          </div>
        </v-card-text>
      </v-card>

      <v-card elevation="0" class="sp-mar-top-3">
        <v-card-title>{{ t('can_upload_profile_image.title') }}</v-card-title>
        <v-card-text>
          <div>
            <v-radio-group v-model="item.can_upload_profile_image">
              <v-radio
                  :label="t('can_upload_profile_image.yes')"
                  value="1"
              />
              <v-radio
                  :label="t('can_upload_profile_image.no')"
                  value="0"
              />
            </v-radio-group>
          </div>
        </v-card-text>
      </v-card>

      

    </BasicSettingsContainer>

  </div>

</template>

<script>
import {HTTP} from './../auth'
import toolbar from '../components/layouts/Navigation.vue'
import loading from '../components/layouts/Loading.vue'
import AppDialogConfirmSave from "../components/vuetify/AppDialogConfirmSave";
import BasicSettingsContainer from "../components/inputs/BasicSettingsContainer.vue";

export default {
  name: 'SettingsGlobal',
  components: {
    BasicSettingsContainer,
    toolbar,
    loading,
    AppDialogConfirmSave
  },
  data() {
    return {
      item: {
        app_email: '',
        app_url_android: '',
        app_url_ios: '',
        name_display_mode: null,
        can_upload_profile_image: null
      },
      itemOriginal: null,
      dataLoaded: false,
    }
  },
  async created() {
    await this.getData()
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
  },

  methods: {
    t: function (key) {
      return this.$t('global-settings.' + key);
    },
    async getData() {
      this.dataLoaded = false
      let response = await HTTP.get('settings/global')
      this.item.app_email = response.data.app_email
      this.item.app_url_android = response.data.app_url_android
      this.item.app_url_ios = response.data.app_url_ios
      this.item.name_display_mode = response.data.name_display_mode
      this.item.can_upload_profile_image = response.data.can_upload_profile_image
      this.itemOriginal = JSON.parse(JSON.stringify(this.item));
      this.dataLoaded = true
    },
    async reset() {
      await this.getData()
    },
    async save() {
      await HTTP.put('settings/global', this.item)
      await this.getData()
    },
  }
}
</script>

<style scoped lang="scss">

.settings-wrapper {
  justify-content: space-between;

  .v-card {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
}

</style>
