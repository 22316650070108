var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('toolbar',{attrs:{"dataLoaded":true}},[_vm._v(_vm._s(_vm.t('title')))]),_c('div',{staticClass:"sp-hero sp-bg-light-blue"},[_c('v-container',{staticClass:"hero-filter",attrs:{"fluid":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.applyFilter(); _vm.options.page = 1}}},[_c('app-filter-apply-refresh-button',{attrs:{"has-changes":_vm.filterStore.hasChanges,"is-loading":_vm.loading},on:{"click":function($event){_vm.applyFilter();  _vm.options.page = 1}}}),_c('v-slide-group',{attrs:{"show-arrows":""}},[_c('v-slide-item',[_c('div',{staticClass:"filter-item"},[_c('v-checkbox',{attrs:{"label":_vm.$t('expense-type.list.filter.include-inactive')},model:{value:(_vm.filterStore.filter.withInactive),callback:function ($$v) {_vm.$set(_vm.filterStore.filter, "withInactive", $$v)},expression:"filterStore.filter.withInactive"}})],1)])],1)],1)],1),_c('v-container',{staticClass:"edit-screen no-hero-container",attrs:{"fluid":""}},[_c('v-row',{staticClass:"data-table-toolbar"},[_c('v-spacer'),_c('v-btn',{staticClass:"icon-left",attrs:{"elevation":"0","color":"primary","to":{ path: '/settings/expense-types/create'}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.t('create'))+" ")],1)],1),(_vm.dataLoaded && _vm.totalItems === 0)?_c('v-row',[_c('div',{staticClass:"empty-datatable"},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.t('no-data'))+" ")],1)]):_vm._e(),(_vm.dataLoaded && _vm.items.length > 0)?_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":_vm.initialSortBy,"sort-desc":_vm.initialSortDesc,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-options': [25, 50, 100, 200],
            pageText: '{0}-{1} von {2}',
            'items-per-page-text': _vm.t('table.items-per-page')
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [(item.name)?_c('span',{staticClass:"ellipsis"},[_c('b',[_vm._v(_vm._s(item.name))])]):_c('span',{staticClass:"ellipsis"},[_c('b',[_vm._v(_vm._s(_vm.t('expenses.data.type.' + item.name_code)))])])]}},{key:"item.edit",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"edit-row-table",attrs:{"to":{ path: '/settings/expense-types/' + item.id }}},[_c('v-icon',{staticClass:"edit-row-button",attrs:{"small":""}},[_vm._v(" edit ")])],1)]}}],null,true)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }