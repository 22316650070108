import de from 'vuetify/lib/locale/de';

const themesHis = {
    themes: {
        light: {
            primary: '#005596',
            secondary: '#D6DFED',
            success: '#71AE5C',
            warning: '#E30613',
            error: '#E30613',
            buttons: '#FB8C00'
        }
    },
}

export default themesHis
