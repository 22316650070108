const themesCub = {
  themes: {
    light: {
      primary: '#234263',
      secondary: '#DCE7EE',
      success: '#4CAF50',
      warning: '#FB8C00',
      error: '#FF5252',
      buttons: '#234263'
    }
  },
}

export default themesCub
