<template>
  <div>
    <div :class="[{'hide-content' : showCreateForm}, {'hide-content' : showEditForm }]">
    <toolbar :dataLoaded="dataLoaded">{{ t('title') }}</toolbar>
    <hero-container>
      <div class="sp-flex-1">
        <md-input-container class="sp-search-field-lists">
          <div class="sp-search-field-inner sp-flex sp-flex-1">
            <md-input type="text" v-model="searchTerm" :placeholder=" $t('search')"></md-input>
            <md-icon>search</md-icon>
          </div>
        </md-input-container>
      </div>
    </hero-container>

    <admin-container class="sp-checklists-index sp-list-content">

      <loading :error="error" :dataLoaded="dataLoaded"></loading>

      <md-layout v-if="dataLoaded">
        <div class="sp-flex-1">

          <!--<div class="sp-float-right sp-mar-bot-3">
            <md-button @click.native="createNew()" class="sp-text-blue sp-mar-r-0">erstellen <md-icon class="sp-text-blue">add_circle</md-icon></md-button>
          </div>
          <div class="sp-clearfix"></div>-->

          <div class="sp-flex-1">
            <div class="sp-pad-2">
              <span class="md-body-2">{{results}} {{ t('results') }}</span>
            </div>
            <md-divider></md-divider>

            <md-input-container class="sp-search-field" v-if="isShowSearchfield">
              <div class="sp-search-field-inner sp-flex sp-flex-1">
                <md-icon>search</md-icon>
                <md-input type="text" v-model="searchTerm" :placeholder="t('search')"></md-input>
              </div>
            </md-input-container>

            <md-list class="md-double-line">

              <md-list-item v-for="item in items" :key="item.id" @click.native="editItem(item.id)">
                <div class="md-list-text-container">
                  <span>{{ item.name }}</span>
                </div>
                <md-divider></md-divider>
              </md-list-item>

            </md-list>

          </div>
        </div>
      </md-layout>

      <md-snackbar ref="snackbar" :md-duration="4000">
        <span>{{ message }}</span>
      </md-snackbar>

    </admin-container>

    </div>

    <create v-if="showCreateForm" :type="'groups'" :title="'Gruppe'"></create>
    <edit v-if="showEditForm" :itemData="group" :itemType="'groups'" :title="t('group')"></edit>

  </div>

</template>

<script>
  import {HTTP} from './../auth'
  import axios from 'axios'
  import toolbar from '../components/layouts/Navigation.vue'
  import search from './../components/inputs/Search.vue'
  import {EventBus} from './../event-bus.js'
  import create from '../views/CreateNew.vue'
  import edit from './Edit.vue'
  import loading from '../components/layouts/Loading.vue'
  import _ from 'lodash'

  export default {
    components: {
      toolbar,
      search,
      create,
      edit,
      loading
    },
    data () {
      return {
        searchTerm: '',
        toolbar: {
          search: true
        },
        isShowSearchfield: false,
        groups: null,
        group: {
          id: null,
          name: null,
          quality_requirement: null,
          assigned_users: null,
          notifications_requirement: [],
          notifications_warning: []
        },
        results: 0,
        dataLoaded: false,
        showCreateForm: false,
        showEditForm: false,
        message: 'Erfolgreich erstellt.',
        error: false,
        type: 'groups',
        cancelHTTP: null,
        errorCancel: null
      }
    },
    created () {
      this.getItems()
    },
    mounted () {
      EventBus.$on('search', function () {
        if (this.isShowSearchfield) {
          this.isShowSearchfield = false
        } else {
          this.isShowSearchfield = true
        }
      }.bind(this))
      EventBus.$on('cancel', function (msg) {
        this.showCreateForm = false
        this.showEditForm = false
        this.showCreateFormElements = false
        this.searchTerm = ''
      }.bind(this))
      EventBus.$on('new-created-' + this.type, function (msg) {
        this.showCreateForm = false
        this.showCreateFormElements = false
        this.message = msg.message
        this.getItems()
        this.countResults()
        this.openSnackbar()
      }.bind(this))
      EventBus.$on('update-' + this.type, function (msg) {
        this.message = msg.message
        var index = _.findIndex(this.groups, ['id', msg.item.id])
        this.groups[index] = msg.item
        this.showCreateForm = false
        this.showCreateFormElements = false
        this.showEditForm = false
        this.reload()
        this.openSnackbar()
      }.bind(this))
      EventBus.$on('reload', function (msg) {
        this.reload()
      }.bind(this))
    },
    beforeDestroy () {
      EventBus.$off('cancel')
      EventBus.$off('update-' + this.type)
      EventBus.$off('reload')
      EventBus.$off('new-created-' + this.type)
    },
    watch: {
      searchTerm: function (val) {
        this.getSearchResult(encodeURIComponent(val))
      }
    },
    computed: {
      items: function () {
        return this.getWithNotificationTypes(this.groups)
      }
    },
    methods: {
      t: function (key) {
        return this.$t('group.list.' + key);
      },
      reload () {
        this.$nextTick(function () {
          this.getItems(1)
        }.bind(this))
      },
      openSnackbar () {
        let that = this
        setTimeout(() => {
          that.$refs.snackbar.open()
        }, 2000)
      },
      getItems () {
        HTTP.get('groups').then(function (response) {
          this.groups = response.data
          this.countResults()
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
          }
        }.bind(this))
      },
      createNew () {
        this.searchTerm = ''
        this.showCreateForm = true
      },
      getSearchResult (val) {
        if (this.cancelHTTP) {
          this.cancelHTTP()
        }
        HTTP.get('groups?q=' + val, {
          cancelToken: new axios.CancelToken(function executor (c) {
            this.cancelHTTP = c
          }.bind(this))
        }).then(function (response) {
          this.groups = response.data
          this.countResults()
        }.bind(this)).catch(function (error) { this.errorCancel = error }.bind(this))
      },
      countResults () {
        this.results = this.groups.length
        this.error = false
        this.dataLoaded = true
      },
      editItem (id) {
        var index = _.findIndex(this.groups, ['id', id])
        this.group = this.groups[index]
        this.showEditForm = true
      }
    }
  }
</script>
