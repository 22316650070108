<template>
	<div class="app-search-field">
		<v-text-field
			v-bind="computedProps"
			v-on="$listeners"
			filled
			:label="filterLabel"
			:loading="loading"
			hide-details="auto"
		>
			<!-- Dynamically inherit slots from parent -->
			<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
				<slot :name="slot" v-bind="scope" />
			</template>
		</v-text-field>
	</div>
</template>
<script>
import { VTextField } from 'vuetify/lib'

export default {
  name: "AppSearchField",
  extends: VTextField,
  props: {
    filterLabel: String,
  },
  computed: {
    computedProps() {
      return {
        ...this.$props,
      }
    }
  }
}
</script>

<style scoped>
.filter-item .v-text-field__details {
	display: none;
}
</style>
