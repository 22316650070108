var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-hero-container"},[_c('div',[_c('toolbar',{attrs:{"dataLoaded":true}},[_vm._v(_vm._s(_vm.$t('chat-groups.list.title')))]),_c('div',{staticClass:"sp-hero sp-bg-light-blue"},[_c('v-container',{staticClass:"hero-filter",attrs:{"fluid":""}})],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"data-table-toolbar"},[_c('v-spacer'),_c('v-btn',{staticClass:"icon-left",attrs:{"elevation":"0","color":"primary","to":{ path: '/chat-groups/create'}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('chat-groups.list.create'))+" ")],1)],1),(_vm.dataLoaded && _vm.items.length === 0)?_c('v-row',[_c('div',{staticClass:"empty-datatable"},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.t('no-data'))+" ")],1)]):_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"sort-by":_vm.initialSortBy,"sort-desc":_vm.initialSortDesc,"footer-props":{
						showFirstLastPage: true,
						firstIcon: 'mdi-arrow-collapse-left',
						lastIcon: 'mdi-arrow-collapse-right',
						prevIcon: 'mdi-minus',
						nextIcon: 'mdi-plus',
						'items-per-page-options': [10, 25, 50],
						pageText: '{0}-{1} von {2}',
						'items-per-page-text': _vm.$t('components.data-table.items-per-page')
					}},scopedSlots:_vm._u([{key:"item.picture",fn:function(ref){
					var item = ref.item;
return [_c('img',{attrs:{"height":"50","width":"50","src":item.picture_url}})]}},{key:"item.name",fn:function(ref){
					var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.members",fn:function(ref){
					var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.member_count)+" ")])]}},{key:"item.message",fn:function(ref){
					var item = ref.item;
return [_c('router-link',{staticClass:"edit-row-table",attrs:{"to":{ path: '/chat-messages/' + item.id }}},[_c('v-icon',{staticClass:"edit-row-button",attrs:{"small":""}},[_vm._v("messages")])],1)]}},{key:"item.details",fn:function(ref){
					var item = ref.item;
return [_c('router-link',{staticClass:"edit-row-table",attrs:{"to":{ path: '/chat-groups/' + item.id }}},[_c('v-icon',{staticClass:"edit-row-button",attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }