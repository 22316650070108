import i18n from "@/i18n";


export function generateRequiredMessage(fieldName) {
    return i18n.t('validation.required-message')
        .replace('{0}', fieldName);
}

export function generateLengthMessage(fieldName, maxLength) {
    return i18n.t('validation.length-message')
        .replace('{0}', fieldName)
        .replace('{1}', maxLength);
}