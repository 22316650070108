
const themesGia = {
  themes: {
    light: {
      primary: '#0D3371',
      secondary: '#BBC6D7',
      success: '#71AE5C',
      warning: '#E30713',
      error: '#E30713',
      buttons: '#1B4A82'
    }
  },
}

export default themesGia
