<template>
  <div>
    <BasicEditContainer
        :title="t('edit.title')"
        ref="editor"
        :object-type="$t('settings.permission-groups.data.object-type')"
        :object-name="data.name"
        :data-loaded="dataLoaded"
        @cancel="cancel"
        :save-action="saveItem"
        @saved="onSaved"
        :allowDelete="true"
        :delete-action="deleteItem"
        @deleted="onDeleted"
        :has-changes="hasChanges">

      <template v-slot:default>

        <v-card
            elevation="0"
        >
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
                :label="t('details.name')"
                v-model="data.name"
                counter="120"
                data-cy="name"
                filled
                :rules="[
                  () => !!data.name || $t('validation.required.default'),
                  () => (data.name && data.name.length <= 120) || $t('validation.length.default')
                ]"
            ></v-text-field>
          </v-card-text>
        </v-card>

        <settings-permission-groups-roles :value="data.roles" @input="updateRoles"/>

      </template>

      <template v-slot:meta>
        <member-list-edit v-model="data.members" :users="users"/>
      </template>
    </BasicEditContainer>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import loading from '../components/layouts/Loading.vue'
import toolbar from '../components/layouts/Navigation.vue'
import BasicEditContainer from "@/components/inputs/BasicEditContainer";
import ContentFilterEmployee from "@/components/filter-components/ContentFilterEmployee";
import MemberListEdit from "@/components/MemberListEdit";
import {usePermissionStore} from "@/stores/PermissionStore";
import SettingsPermissionGroupsRoles from "@/views/SettingsPermissionGroupsRoles.vue";

export default {
  name: 'SettingsPermissionGroupsEdit',
  components: {
    SettingsPermissionGroupsRoles,
    MemberListEdit,
    ContentFilterEmployee,
    BasicEditContainer,
    loading,
    toolbar
  },
  data() {
    return {
      dataLoaded: false,
      data: {
        name: '',
        members: [],
        roles: [],
        modules: []
      },
      dataOriginal: null,
      users: [],
      modules: [],
      indexRouteName: 'SettingsPermissionGroups',
    }
  },
  props: {},
  created() {
    this.itemId = this.$route.params.id
    this.getItem()
  },
  mounted() {

  },
  computed: {
    hasChanges() {
      if (!this.dataOriginal) {
        return false;
      }
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('settings.permission-groups.detail.' + key);
    },
    async getItem() {
      let response = await HTTP.get('permission-groups/' + this.itemId)
      this.data = response.data.data
      this.users = response.data.users
      this.dataOriginal = JSON.parse(JSON.stringify(this.data))
      this.dataLoaded = true
    },
    updateRoles(roles) {
      this.data.roles = roles;
    },
    saveItem() {
      return HTTP.patch('permission-groups/' + this.itemId, this.data)
    },
    deleteItem() {
      return HTTP.delete('permission-groups/' + this.itemId)
    },
    onDeleted() {
      this.dataOriginal = null;
      this.close();
    },
    async onSaved() {
      this.dataOriginal = null;
      await this.close();
    },
    cancel() {
      this.close();
    },
    async close() {
      await HTTP.get('security/user/permissions').then(function (response) {
        usePermissionStore().permissions = response.data
      }.bind(this))

      await this.$router.push({name: this.indexRouteName})
    },
    upperCase(val) {
      return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
    }
  }
}
</script>

<style scoped lang="scss">
.analytics-user {
  padding: 10px;

  .md-icon {
    cursor: pointer;
  }

  &:nth-child(odd) {
    background: #f7f7f7;
  }
}

.sp-search-field-lists {
  float: none;
  max-width: 100%;
}

.inactive {
  text-decoration: line-through;
}

.hint {
  white-space: pre-line;
}
</style>
