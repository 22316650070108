import {Line, mixins} from 'vue-chartjs'
const { reactiveProp } = mixins

export default Line.extend({
  mixins: [reactiveProp],
  props: ['options'],
  mounted () {
	var horizonalLinePlugin = {
      id: 'horizontalLine',
	  beforeDraw: function (chartInstance) {		  
        var canvas = chartInstance.chart
        var ctx = canvas.ctx
		ctx.fillStyle = "white"
		ctx.fillRect(0, 0, canvas.width, canvas.height)
	  },
      afterDraw: function (chartInstance) {
		  
        var canvas = chartInstance.chart
        var ctx = canvas.ctx
		var yValue
		var yScale = chartInstance.scales["y-axis-0"]
		var style;
		
		if (chartInstance.options.horizontalLine) {

			if (chartInstance.options.horizontalLine.lineColor) {
				style = chartInstance.options.horizontalLine.lineColor
			} else {
				style = "rgba(169,169,169, .5)"
			}
			
			if (chartInstance.options.horizontalLine.averageValue) {
				yValue = yScale.getPixelForValue(chartInstance.options.horizontalLine.averageValue)
			} else {
				yValue = yScale.getPixelForValue(0)
			}
			
		}
		
		ctx.lineWidth = 2

        if (yValue) {
          ctx.beginPath()
		  ctx.setLineDash([])
          ctx.moveTo(0, yValue)
          ctx.lineTo(canvas.width, yValue)
          ctx.strokeStyle = style
          ctx.stroke()
		  
		  if (chartInstance.options.horizontalLine.textColor) {
			  style = chartInstance.options.horizontalLine.textColor
		  } else {
			style = "rgba(169,169,169, .8)"
		  }
		  
		  ctx.fillStyle = style;
          ctx.fillText('Durchschnitt: ' + chartInstance.options.horizontalLine.averageValue + '%', 0, yValue - 2 * ctx.lineWidth)
		  
        }
		
		return
		
      }
    }
	var trendLinePlugin = {
      id: 'horizontalLine',
      afterDraw: function (chartInstance) {
		  
        var canvas = chartInstance.chart
        var ctx = canvas.ctx
		var yScale = chartInstance.scales["y-axis-0"]
		var xScale = chartInstance.scales["x-axis-0"]
		var style = "rgba(0, 0, 0, 0.5)"
		
		if (chartInstance.options.trendLine && chartInstance.options.trendLine.intercept > 0) {
		
			ctx.lineWidth = 2

				ctx.beginPath()
				ctx.setLineDash([10, 15])
				for (let i = 0; i < canvas.width; i++) {
					ctx.lineTo(xScale.getPixelForValue(i), yScale.getPixelForValue(chartInstance.options.trendLine.intercept + chartInstance.options.trendLine.slope * i))
				}
				ctx.strokeStyle = style
				ctx.stroke()
			  
			}
		
			return
		
		}
    }
	this.addPlugin(horizonalLinePlugin)
	this.addPlugin(trendLinePlugin)
    this.renderChart(this.chartData, this.options)
  }
})
