import ExpensesIndex from "../../views/ExpenseIndex";
import ExpenseEdit from "@/views/ExpenseEdit";
import ExpenseSettings from "@/views/ExpenseSettings.vue";
import ExpenseTypesIndex from "@/views/ExpenseTypesIndex.vue";
import ExpenseTypesEdit from "@/views/ExpenseTypesEdit.vue";
import ExpenseTypesCreate from "@/views/ExpenseTypesCreate.vue";


const expensesRoutes = [
  {
    path: '/expenses',
    name: 'Expenses',
    component: ExpensesIndex,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/expenses/:id?',
    name: 'Expense',
    component: ExpenseEdit,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/settings/expense',
    name: 'ExpenseSettings',
    component: ExpenseSettings,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/settings/expense-types',
    name: 'ExpenseTypesIndex',
    component: ExpenseTypesIndex,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/settings/expense-types/create',
    name: 'ExpenseTypeCreate',
    component: ExpenseTypesCreate,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/settings/expense-types/:id',
    name: 'ExpenseTypesEdit',
    component: ExpenseTypesEdit,
    meta: {
      accessConfig:  {
        moduleName: ['expense'],
        requiresAuth: true
      }
    }
  },
]

export default expensesRoutes
