import de from 'vuetify/lib/locale/de';

const themesBua = {
  themes: {
    light: {
      primary: '#004F29',
      secondary: '#BDD1C7',
      success: '#4CAF50',
      warning: '#FB8C00',
      error: '#FF5252',
      buttons: '#004F29'
    }
  },
}

export default themesBua
