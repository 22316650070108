<template>
    <div>
  
        <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

        <v-container fluid class="edit-screen">

            <loading :dataLoaded="dataLoaded"></loading>

            <v-row v-if="dataLoaded">

                <v-col class="content-area" cols="12" sm="12" md="8">

                    <v-card elevation="0">
                        <v-card-title>{{ t('overview.group-title') }}</v-card-title>
                        <v-card-text>
                            <v-text-field
                                :label="t('overview.customer')"
                                v-model="objectControl.object.name"
                                filled
                                readonly
                                hide-details="auto"
                            ></v-text-field>

                            <v-text-field
                                class="mt-4"
                                :label="t('overview.contact')"
                                v-model="objectControl.contact"
                                filled
                                readonly
                                hide-details="auto"
                            ></v-text-field>

                            <v-text-field
                                class="mt-4"
                                :label="t('overview.inspector')"
                                v-model="objectControl.inspector.display_name"
                                filled
                                readonly
                                hide-details="auto"
                            ></v-text-field>

                            <v-text-field
                                class="mt-4"
                                :label="t('overview.category')"
                                v-model="objectControl.category.title"
                                filled
                                readonly
                                hide-details="auto"
                            ></v-text-field>
                        </v-card-text>
                    </v-card>

                    <v-card elevation="0" class="sp-mar-top-3">
                        <v-card-title>{{ t('results.group-title') }}</v-card-title>
                        <v-card-text>
                            <v-text-field
                                :label="t('results.coworker')"
                                v-model="objectControl.coworker.display_name"
                                filled
                                readonly
                                hide-details="auto"
                            ></v-text-field>

                            <v-textarea
                                class="mt-4"
                                :label="t('results.comment')"
                                v-model="objectControl.comment"
                                filled
                                readonly
                                hide-details="auto"                          
                            ></v-textarea>
                            
                            <v-card elevation="0" class="sp-mar-top-3">
                                <v-card-title>{{ t('results.evaluations') }}</v-card-title>
                                <v-card-text class="pa-0">
                                    <div v-for="(evaluation, index) in objectControl.evaluations" :key="index" class="object-control-evaluation">
                                        <div class="evaluation-question">{{ evaluation.question }}</div>
                                        <div class="evaluation-values">
                                            <v-radio-group v-if="evaluation.value" v-model="evaluation.value" row readonly>
                                                <v-radio :label="'—'" :value="-1"></v-radio>
                                                <v-radio :label="'+'" :value="1"></v-radio>
                                                <v-radio :label="'++'" :value="2"></v-radio>
                                            </v-radio-group>
                                            <div v-else>
                                                {{ t('results.not-evaluated') }}
                                            </div>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                    </v-card>
                    
                </v-col>

                <v-col class="meta-area" cols="12" sm="12" md="4">

                    <v-card elevation="0">
                        <v-card-title>{{ t('date.group-title') }}</v-card-title>
                        <v-card-text>
                            <v-text-field
                                :label="t('date.control-date')"
                                v-model="controlDateFormatted"
                                filled
                                readonly
                                hide-details="auto"                          
                            ></v-text-field>
                        </v-card-text>
                    </v-card>

                </v-col>

            </v-row>

        </v-container>

        <transition name="fade">
            <div class="bottom-navigation modal-nav">
                <v-container fluid>
                    <v-row>
                        <v-btn
                          color="primary"
                          text
                          class="icon-left"
                          @click="close()"
                        >
                          <v-icon>mdi-close</v-icon>
                          {{ t('close') }}
                        </v-btn>
                    </v-row>
                </v-container>
            </div>
        </transition>

    </div>
</template>

<script>
import { HTTP } from '@/auth'
import loading from '@/components/layouts/Loading'
import toolbar from '@/components/layouts/Navigation'

export default {
    name: 'ObjectControlResultsView',
    components: {
        loading,
        toolbar
    },
    data() {
        return {
            objectControl: {
                id: null
            },
            dataLoaded: false,
            error: null,
            loading: false
        }
    },
    created() {
        this.objectControl.id = this.$route.params.id
        this.getObjectControl()
    },
    computed: {
        controlDateFormatted() {
            return this.formatDate(this.objectControl.control_date)
        }
    },
    methods: {
        t: function (key) {
            return this.$t('object-control.results.view.' + key)
        },
        getObjectControl() {
            HTTP.get('object_control/' + this.objectControl.id).then(function (response) {
                this.objectControl = response.data
                this.dataLoaded = true
                this.loading = false
            }.bind(this)).catch(function (error) {
                if (!error.status) {
                    this.error = true
                    this.loading = false
                }
            }.bind(this))
        },
        close() {
            this.$router.push({ name: 'ObjectControlResults' })
        }
    }
}
</script>

<style scoped lang="scss">
.object-control-evaluation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #E0E0E0;

    &:last-child {
        border-bottom: 0;
    }

    .evaluation-question {
        position: relative;
        top: 2px;
    }

    ::v-deep .v-input--radio-group__input {
        flex-wrap: nowrap;
    }

    ::v-deep .v-radio {
        margin-right: 0 !important;
        margin-left: 32px;
    }

    ::v-deep .v-input--selection-controls__input {
        margin-right: 4px !important;
    }

    ::v-deep .v-label {
        font-weight: 500;
    }
}
</style>
