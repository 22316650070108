<template>
  <div class="privacy-policy" v-html="data"></div>

</template>

<script>
import axios from "axios";

let BASE_URL = process.env.VUE_APP_API_BASE_URL ?? ''

export default {
  data() {
    return {
      data: null
    }
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      let response = await axios.get(BASE_URL + '/api/privacy-policy');
      this.data = response.data;
    }
  }
}
</script>
<style>
.privacy-policy {
  margin: 0 5% 5%;
  padding-bottom: 5%;
}

ul {
  list-style-type: disc;
}
p {
  min-height: 1rem;
}

h1 {
}

h2 {
  margin-top: 2rem;
  margin-bottom: 0.25rem;
}
</style>
