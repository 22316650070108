const themesSup = {
  themes: {
    light: {
      primary: '#000',
      secondary: '#FFDD00',
      success: '#4CAF50',
      warning: '#FB8C00',
      error: '#FF5252',
      buttons: '#000'
    }
  },
}

export default themesSup
