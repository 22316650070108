<template>
  <div>
    <BasicEditContainer
        ref="editor"
        :data-loaded="dataLoaded"
        v-if="dataLoaded"
        :title="t('title')"
        @cancel="close"
        :save-action="saveItem"
        @saved="close"
        :delete-action="deleteItem"
        @deleted="close"
        :object-type="$t('servappt.data.object-type')"
        :object-name="formatTitle(data)"
        :has-changes="hasChanges">
      <template>
        <v-card
            elevation="0">
          <v-card-title>{{ t('information') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <content-filter-user 
                  @item-selected="userSelected" 
                  v-model="data.user" 
                  role="customer"
                  :custom-label="t('client')"
                  :rules="[v => !!v || $t('validation.required.default')]"
                  />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <app-date-time-picker
                    :label="t('selection-period.begin-date')"
                    v-model="data.begin"
                    date-format="dd.MM.yyyy"
                    time-format="HH:mm"
                    :clearable="false"
                    :rules="[v => !!v || $t('validation.required.begin-date')]"
                    :id="'beginDate'"
                ></app-date-time-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <app-date-time-picker
                    :label="t('selection-period.end-date')"
                    v-model="data.end"
                    date-format="dd.MM.yyyy"
                    time-format="HH:mm"
                    :clearable="false"
                    :rules="[v => !!v || $t('validation.required.end-date')]"
                    :id="'endDate'"
                ></app-date-time-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field filled :label="t('name')" :rules="[v => !!v || $t('validation.required.default')]" v-model="data.name"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field filled :label="t('street')" :rules="[v => !!v || $t('validation.required.default')]" v-model="data.address"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field filled :label="t('zip')" :rules="[v => !!v || $t('validation.required.default')]" v-model="data.zip"/>
              </v-col>
              <v-col>
                <v-text-field filled :label="t('city')" :rules="[v => !!v || $t('validation.required.default')]" :value="data.city"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                {{ t('contact') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field filled :rules="[v => !!v || $t('validation.required.default')]" :label="t('email')" v-model="data.email"/>
              </v-col>
              <v-col>
                <v-text-field filled :label="t('phone')" v-model="data.phone"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
              elevation="0" v-show="data.state != dataOriginal.state"
          >
            <v-card-title>{{ t('push.label') }}</v-card-title>
            <v-card-text>
              <v-text-field v-model="data.push_title_status_change" filled :label="t('push_title.label')"
                            :rules="[v => !!v || $t('validation.required.push_title')]"
                          />

              <v-textarea
                        filled
                        :label="t('push_text')"
                        v-model="data.push_text_status_change"
                        :rules="[() => !!data.push_text_status_change || $t('validation.required.push_text')]"
                    />

            </v-card-text>
        </v-card>
      </template>

      <template v-slot:meta>
        <v-card
            elevation="0"
        >
          <v-card-title>{{ t('status-label') }}</v-card-title>
          <v-card-text>
            <v-select
                filled
                :label="t('status-label')"
                :title="t('status-label')"
                v-model="data.state"
                :items="states"
                item-text="name"
                item-value="value"
            ></v-select>
          </v-card-text>
        </v-card>

        <v-card
            class="mt-4"
            elevation="0"
        >
          <v-card-title>{{ t('comment-from-client') }}</v-card-title>
          <v-card-text>
            <v-textarea filled :label="t('comment-from-client')" v-model="data.request_message" readonly/>
          </v-card-text>
        </v-card>

        <v-card
            class="mt-4"
            elevation="0"
        >
          <v-card-title>{{ t('comment-to-client') }}</v-card-title>
          <v-card-text>
            <v-textarea filled :label="t('comment-to-client')" v-model="data.message"/>
          </v-card-text>
        </v-card>

        <v-card
            class="mt-4"
            elevation="0"
        >
          <v-card-title>{{ t('responsible-employee') }}</v-card-title>
          <v-card-text>
            <content-filter-user
                role="admin,coordinator,employee"
                v-model="data.employee" 
                @item-selected="employeeSelected"
                :rules="[v => !!v || $t('validation.required.default')]"
            />
          </v-card-text>
        </v-card>


      </template>
    </BasicEditContainer>
  </div>
</template>

<script>
import {HTTP} from '@/auth'
import DeleteButton from "@/components/inputs/DeleteButton";
import BasicEditContainer from "@/components/inputs/BasicEditContainer";
import AppDateTimePicker from "../components/vuetify/AppDateTimePicker";
import moment from "moment/moment";
import ContentFilterUser from "@/components/filter-components/ContentFilterUser.vue";

export default {
  name: 'ServapptAppointmentsEdit',
  components: {
    ContentFilterUser,
    BasicEditContainer,
    DeleteButton,
    AppDateTimePicker,
  },
  data() {
    return {
      data: {
        user_id: null,
        state: "pending",
        begin: null,
        end: null,
        name: null,
        address: null,
        zip: null,
        city: null,
        email: null,
        phone: null,
        message: null,
        request_message: null,
        employee_id: null,
        user: null,
        push_title_status_change: '',
        push_text_status_change: ''
      },
      states: [
        {value: "confirmed", name: this.t("status.confirmed")},
        {value: "cancelled", name: this.t("status.cancelled")},
      ],
      dataOriginal: null,
      dataLoaded: false,
      indexRouteName: 'ServapptAppointmentsIndex'
    }
  },
  created() {
    this.itemId = this.$route.params.id
    this.getItem()
    this.dataOriginal = JSON.parse(JSON.stringify(this.data))
  },
  mounted() {
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('servappt.appointments.edit.' + key);
    },
    formatTitle(item) {
      return this.data.name + " - " + moment(this.data.date, "d.m.Y")
    },
    getItem() {
      HTTP.get('servappt/appointments/' + this.itemId).then(function (response) {
        this.data = response.data;
        this.dataOriginal = JSON.parse(JSON.stringify(this.data));
        this.dataLoaded = true
        this.getPushConfig();
      }.bind(this)).catch(function (error) {
        console.log(error)
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    getPushConfig() {
      HTTP.get('servappt/push-config').then(function (response) {
        console.log(response.data);
        this.data.push_title_status_change = response.data.appointment_updated_title;
        this.data.push_text_status_change = response.data.appointment_updated_message;
       // this.areas = response.data.data
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    saveItem() {

      if (this.data.user != null) {
        this.data.user_id = this.data.user.id;
      }

      if (this.data.employee != null) {
        this.data.employee_id = this.data.employee.id;
      }

      return HTTP.put('servappt/appointments/' + this.itemId, this.data);
    },
    deleteItem() {
      return HTTP.delete('servappt/appointments/' + this.itemId, this.item);
    },
    async onSaved() {
      this.dataOriginal = null;
      this.close();
    },
    close() {
      this.$router.push({name: this.indexRouteName})
    },
    userSelected(id, data) {
      this.data.user_id = id
    },
    employeeSelected(id, data) {
      this.data.employee_id = id
    },
  }
}
</script>

<style scoped lang="scss">
.fc-button-group {

}
</style>
