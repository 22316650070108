<template>
  <div>
    <v-radio-group data-cy="status" v-model="publishMode">
      <v-radio
          key="draft"
          :label="$t('components.post-publish-settings.status.draft')"
          value="draft"
          data-cy="draft"
      />
      <v-radio
          key="publishat"
          :label="$t('components.post-publish-settings.status.publish-at')"
          value="publishat"
          data-cy="publish-at-radio"
      />
   
      <app-date-time-picker
          v-if="publishMode === 'publishat'"
          v-model="publishDate"
          @date-time-changed="changeDate"
          date-format="dd.MM.yyyy"
          time-format="HH:mm"
          :id="'publishedDate'"
          :clearable="true"
          :label="$t('components.post-publish-settings.publish-ondate')"
          data-cy="publish-at"
          :rules="[() => (publishMode === 'publishat' && !!publishDate) || $t('validation.required.default')]"

      />
      <span v-if="publishMode === 'publishat' && !publishAt" class="publish-error">
        {{ $t('validation.required.publish-date') }}
      </span>
      <span v-if="!validPublishDate" class="publish-error">
        {{ $t('components.post-publish-settings.error-text') }}
      </span>

      <v-radio
          key="published"
          :label="$t('components.post-publish-settings.status.published')"
          value="published"
          data-cy="published"
      />
    </v-radio-group>
  </div>
</template>

<script>

import AppDateTimePicker from "@/components/vuetify/AppDateTimePicker.vue"
import moment from 'moment'

export default {
  name: "NewsPublish",
  components: {AppDateTimePicker},
  emits: [
    'status',
    'publish-at',
    'valid',
  ],
  props: {
    initialStatus: {},
    publishAt: {},
  },
  data() {
    return {
      publishMode: this.initialStatus,
      publishDate: null,
      invalidPublishDate: false,
    }
  },
  computed: {
    validPublishDate() {
      return this.publishMode !== 'publishat'
          || moment(this.publishDate) > moment()
    }
  },
  watch: {
    publishMode(val) {

      this.validate()
      this.$emit('status', val)
    },
  
    initialStatus(val) {
      this.publishMode = val;
    },
    publishAt(val) {
      if (typeof val != "object")
        this.publishDate = val;
    }
  },
  methods: {
    validate() {
      this.$emit('valid',  this.validPublishDate)
    },
    changeDate() {
      this.validate()
      this.$emit('publish-at', this.publishDate)
    }
  }
}
</script>


<style scoped lang="scss">

</style>
