<template>
  <div>
	  <div :class="[{'hide-content' : showCreateData }, {'hide-content' : showEditData }, {'hide-content' : showCreateFolder }, {'hide-content' : showEditFolder }]">

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <ValidationObserver ref="validationInputs">

        <div class="breadcrumbs-bar">
          <v-container fluid>
              <span v-if="hasCurrentFolder" class="breadcrumb-item" :dirPath="null" @click="getDirectories(null)">{{ t('title') }}</span>
              <span v-else class="breadcrumb-item inactive">{{ t('title') }}</span>
              <span v-show="!loading" class="breadcrumb-item" v-for="(value, index) in breadcrumbs" :key="index" v-bind:dirPath="value.id" @click="getDirectories(value.folder_id)">
                <span v-if="value.folder_id !== hasCurrentFolder"> / {{ value.name }}</span>
              </span>
              <span v-if="!loading && hasCurrentFolder" class="breadcrumb-item" :dirPath="null"> / <span class="current-folder">{{ currentFolder.name }}</span></span>
          </v-container>
        </div>

        <v-container fluid v-if="!dataLoaded" class="statistics">
          <v-row>
            <loading :error="error" :dataLoaded="dataLoaded"></loading>
          </v-row>
        </v-container>

        <v-container fluid v-if="dataLoaded" class="edit-screen">

          <v-row class="data-table-toolbar">
            <v-spacer></v-spacer>
            <div>
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                @click="createNewFolder()"
              >
                <v-icon>mdi-folder-plus</v-icon>
                {{ t('create-folder') }}
              </v-btn>
            </div>
            <div>
              <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                @click="createNewData()"
              >
                <v-icon>mdi-file-upload-outline</v-icon>
                {{ t('upload-file') }}
              </v-btn>
            </div>
          </v-row>

          <!-- Label / Sorting button -->
          <md-layout class="" md-flex="100">
            <md-layout md-flex="50" class="sp-flex-align-self-center " md-flex-offset="0" >
              <div class="sp-pad-2">
                <span class="md-body-2" v-if="results === 1">{{results}} {{ t('results.single') }}</span>
                <span class="md-body-2" v-if="results !== 1">{{results}} {{ t('results.multiple') }}</span>
              </div>
            </md-layout>
          </md-layout>

          <md-layout class="" md-flex="100">
            <!-- List item -->
            <div class="sp-flex-1">

              <div class="sp-flex-1 sp-pad-l-2 sp-pad-r-2 drag-area-wrapper drag-list-style">
                <draggable element="div" v-model="folders" class="drag-area" :class="{'drag-area-how-to' : folders.length === 0}" @end="updateItemOrder">
                  <div class="drag-element sp-flex-1" :class="'type-' + item.type" v-for="(item, index) in folders" :key="item.id">

                    <div class="list-item-title" style="width:90%;height: 100%;" v-if="item.folder_id" @click="onListItemClick(index);">
                      <div class="subtitle_block">
                        <md-icon md-list-icon class="drag-type-icon">folder</md-icon>
                        {{item.name}}
                      </div>
                    </div>
                    <div class="list-item-title" style="width:90%; height: 100%;"  v-if="item.document_id" @click="onListItemClick(index);">
                      <div class="subtitle_block">
                      <md-icon md-list-icon class="drag-type-icon" v-if="item.mimetype.match(/pdf/)">picture_as_pdf</md-icon>
                      <md-icon md-list-icon class="drag-type-icon" v-else-if="item.mimetype.match(/image/)">image</md-icon>
                      <md-icon md-list-icon class="drag-type-icon" v-else-if="item.mimetype.match(/video/)">movie</md-icon>
                      <md-icon md-list-icon class="drag-type-icon" v-else>text_snippet</md-icon>
                      {{item.title}}
                      </div>
                    </div>

                    <div class="" @click="activeItem=item">
                      <md-icon class="sp-text-blue cta-icon" @click.native="editFolder(item.folder_id);" v-if="item.folder_id">edit</md-icon>
                      <md-icon class="sp-text-blue cta-icon" @click.native="openDialog('confirm-delete-folder');" v-if="item.folder_id">delete</md-icon>
                      <md-icon class="sp-text-blue cta-icon" v-if="item.document_id" @click.native="downloadFile(item)">download</md-icon>
                      <md-icon class="sp-text-blue cta-icon" @click.native="openDialog('confirm-delete-file');" v-if="item.document_id">delete</md-icon>
                    </div>


                  </div>
                </draggable>
              </div>


            </div>
          </md-layout>

        </V-container>

        <!-- Rename Folder dialog-->
        <md-dialog ref="rename-folder" :md-click-outside-to-close="false">
          <md-dialog-title>{{ t('rename-folder') }}</md-dialog-title>
          <md-dialog-content>

            <ValidationProvider rules="required" v-slot="{errors}" :name="'Name'" ref="renameInput">
              <md-input-container :class="{ 'md-input-invalid': errors[0] }">
                <label>{{ t('folder-name') }}</label>
                <md-input type="text" v-model="renameFolderName" :required="true" maxlength="120"></md-input>
                <span class="md-error">{{ errors[0] }}</span>
              </md-input-container>
            </ValidationProvider>

          </md-dialog-content>
          <md-dialog-actions>
            <md-button class="md-primary" @click="renameFolderName = null; activeItem = null; $refs.renameInput.reset(); closeDialog('rename-folder');  ">{{ t('cancel') }}</md-button>
            <md-button class="md-primary" @click="renameDirectory()">{{ t('save') }}</md-button>
          </md-dialog-actions>
        </md-dialog>

        <!-- Confirm dialog to delete folder and files in it -->
        <md-dialog-confirm
          :md-title="t('confirm-delete.folder.title')"
          :md-content-html="t('confirm-delete.folder.contentHtml')"
          :md-ok-text="t('confirm-delete.ok')"
          :md-cancel-text="t('confirm-delete.cancel')"
          @close="onConfirmClose"
          ref="confirm-delete-folder"
          :class="['confirm-delete']">
        </md-dialog-confirm>

        <!-- Confirm dialog to delete document-->
        <md-dialog-confirm
          :md-title="t('confirm-delete.document.title')"
          :md-content-html="t('confirm-delete.document.contentHtml')"
          :md-ok-text="t('confirm-delete.ok')"
          :md-cancel-text="t('confirm-delete.cancel')"
          @close="onConfirmClose"
          ref="confirm-delete-file"
          :class="['confirm-delete']">
        </md-dialog-confirm>

      </ValidationObserver>

      <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        timeout="4000"
      >
        {{ message }}
      </v-snackbar>

    </div>

    <documents-create-data v-if="showCreateData" :currentDir="currentFolder.id"></documents-create-data>
    <documents-edit-data v-if="showEditData" :itemId="editItemId"></documents-edit-data>

    <documents-create-folder v-if="showCreateFolder" :currentDir="currentFolder.id"></documents-create-folder>
    <documents-edit-folder v-if="showEditFolder" :itemId="editItemId"></documents-edit-folder>

  </div>
</template>

<script>
  import { HTTP } from '@/auth'
  import toolbar from '@/components/layouts/Navigation'
  import { EventBus } from '@/event-bus.js'
  import DocumentsEditData from '@/views/DocumentsEditData'
  import DocumentsCreateData from '@/views/DocumentsCreateData'
  import DocumentsEditFolder from '@/views/DocumentsEditFolder'
  import DocumentsCreateFolder from '@/views/DocumentsCreateFolder'
  import loading from '@/components/layouts/Loading'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import draggable from 'vuedraggable'

  export default {
    name: 'DocumentsIndex',
    components: {
      ValidationProvider,
      ValidationObserver,
      toolbar,
      DocumentsEditData,
      DocumentsCreateData,
      DocumentsEditFolder,
      DocumentsCreateFolder,
      loading,
      draggable
    },
    data () {
      return {
        activeItem: null,
        activeItemIndex: null,
        folders: null,
        currentFolder: null,
        curFolderId: null,
        breadcrumbs: [],
        newFolderName: null,
        renameFolderName: null,
        timer: null,
        timerDragDrop: null,
        results: 0,
        dataLoaded: false,
        showEditData: false,
        showCreateData: false,
        showEditFolder: false,
        showCreateFolder: false,
        message: null,
        error: false,
        cancelHTTP: null,
        errorCancel: null,
        confirm: {
          folder: {
            title: 'Ordner löschen',
            contentHtml: 'Möchten Sie den ausgewählten Ordner und dessen Inhalt wirklich löschen?'
          },
          document: {
            title: 'Dokument löschen',
            contentHtml: 'Möchten Sie das ausgewählte Dokument wirklich löschen?'
          },
          ok: 'Löschen',
          cancel: 'Abbrechen'
        },
		    snackbar: false,
        loading: false
      }
    },
    created () {
      this.getDirectories(this.curFolderId)
      EventBus.$on('cancel-document', function (msg) {
        this.showEditData = false
        this.showCreateData = false
        this.showEditFolder = false
        this.showCreateFolder = false
      }.bind(this))
      EventBus.$on('refresh-document', function (msg) {
        this.reload()
      }.bind(this))
      EventBus.$on('update-document', function (msg) {
        this.dataLoaded = false
        this.showEditData = false
        this.showCreateData = false
        this.showEditFolder = false
        this.showCreateFolder = false
        this.getDirectories(this.curFolderId)
      }.bind(this))
      EventBus.$on('reload', function (msg) {
        this.reload()
      }.bind(this))
    },
    beforeDestroy () {
      EventBus.$off('cancel-document')
      EventBus.$off('update-document')
      EventBus.$off('reload')
    },
    computed: {
      hasCurrentFolder() {
        return this.currentFolder && this.currentFolder.id
      }
    },
    methods: {
      t: function (key) {
        return this.$t('documents.list.' + key);
      },
      downloadFile(item){
        HTTP.get('documents/download/' + item.document_id, {
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', item.filename);
          document.body.appendChild(link);
          link.click();
        });
      },
      reload () {
        this.$nextTick(function () {
          this.showEditData = false
          this.showCreateData = false
          this.getDirectories(this.currentFolder.id)
        }.bind(this))
      },
      getDirectories (dir) {
        this.dataLoaded = false
        let url = 'documents/explorer'
        if (dir != null) {
          url = 'documents/explorer/' + dir
        }
        HTTP.get(url).then(function (response) {
          this.currentFolder = response.data.folders
          this.curFolderId = this.currentFolder.id
          this.folders = response.data.folders.children
          this.results = this.folders.filter(x => !x.is_directory).length
          this.breadcrumbs = response.data.breadcrumbs
          this.error = false
          this.dataLoaded = true
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
          }
        }.bind(this))
      },
      renameDirectory() {
        let data = {
          'name': this.renameFolderName
        }

        this.$refs.renameInput.validate()

        if (this.$refs.renameInput.flags.valid) {
          HTTP.patch('documents/folders/' + this.activeItem.folder_id, data)
          .then(function (response) {
            this.getDirectories(this.currentFolder.id)
            this.renameFolderName = null
            this.activeItem = null
            this.$refs.renameInput.reset()
            this.closeDialog('rename-folder')
          }.bind(this))
          .catch(function (error) {
            if (error.response.data.errors) {
              this.message = error.response.data.message
              this.openSnackbar()
            }
            console.log(error.response)
          }.bind(this))
        }
      },
      saveOrder() {
        let data = {
          'items': this.folders
        }
        HTTP.post('documents/folders/order',  data)
        .then(function (response) {
          this.getDirectories(this.currentFolder.id)
          this.message = 'Die Reihenfolge wurde erfolgreich geändert.'
          this.openSnackbar()
        }.bind(this))
        .catch(function (error) {
          if (error.response.data.errors) {
            this.message = error.response.data.message
            this.openSnackbar()
          }
          console.log(error.response)
        }.bind(this))
      },
      updateItemOrder: function() {
        if (this.folders.length > 1) {
          clearTimeout(this.timerDragDrop);

          this.timerDragDrop = setTimeout(() => {
            this.saveOrder()
          }, 1000);
        }
      },
      deleteItem(itemId, type) {
        let url = 'documents/' + itemId
        this.message = '«' + this.activeItem.title + '» wurde erfolgreich gelöscht.'

        if(type === 'folder') {
          url = 'documents/folders/' + itemId
          this.message = '«' + this.activeItem.name + '» wurde erfolgreich gelöscht.'
        }

        HTTP.delete(url)
        .then(function (response) {
          this.getDirectories(this.currentFolder.id)
          this.activeItem = null
          this.openSnackbar()
        }.bind(this))
        .catch(function (error) {
          if (error.response.data.errors) {
            this.message = error.response.data.message
            this.openSnackbar()
          }
          console.log(error.response)
        }.bind(this))
      },
      createNewData () {
        this.showCreateData = true
      },
      createNewFolder () {
        this.showCreateFolder = true
      },
      countResults () {
        this.results = this.documents.length
      },
      editItem (id) {
        this.editItemId = id
        this.showEditData = true
      },
      onListItemClick(index) {
        let currentListObject = this.folders[index]
        if (currentListObject.folder_id) {
          // open directory with double click
          this.getDirectories(currentListObject.folder_id)
          this.curFolderId = currentListObject.folder_id

        } else {
          this.editItem(currentListObject.document_id)
        }
        clearTimeout(this.timer)
        this.activeItem = null;
     },
      closeDialog (ref) {
        this.$refs[ref].close()
        this.activeFolder = null
        this.renameFolderName = ''
      },
      editFolder(id) {
        this.editItemId = id;
        this.showEditFolder = true
      },
      openDialog (ref) {
        let that = this
        setTimeout(() => {
          that.$refs[ref].open()
        }, 500)
      },
      onConfirmClose (type) {
        if (type === 'ok') {
          if(this.activeItem.folder_id) {
            this.deleteItem(this.activeItem.folder_id, 'folder')
          } else {
            this.deleteItem(this.activeItem.document_id, 'document')
          }
        } else {
          this.activeItem = null
        }
      },
      openSnackbar () {
        setTimeout(() => {
          this.snackbar = true
        }, 2000)
      }
    }
  }
</script>

<style scoped lang="scss">
	.sp-app.v-application .container--fluid.edit-screen {
		padding-top: 0;
	}
</style>

<style lang="scss">
	.md-list-item {
		cursor: default;
	}

	.list-ic-button {
		cursor: pointer;
	}

	.list-ic-button:hover .md-icon {
		color: var(--v-primary-base);
	}

	.icon-button[disabled] {
		color: rgba(0, 0, 0, 0.26) !important;
	}

	.icon-button[disabled] i {
		color: rgba(0, 0, 0, 0.26) !important;
	}

  .drag-list-style .drag-element {
    height: 40px;
  }

  .subtitle_block {
    margin-top:25px;
  }
</style>
