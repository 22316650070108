<template>
  <div id="app" class="sp-app v-application v-application--is-ltr" data-app>
    <router-view></router-view>
    <InfoNotification ref="infonotification"></InfoNotification>
  </div>
</template>

<script>
import InfoNotification from "@/components/InfoNotification";
import {EventBus} from "@/event-bus";
import Auth from "@/auth";
import store from "store";
import tokenHelper from "./token-helper";


let isOidcCallback = window.location.href.includes("/oidc-callback")
let BASE_URL = process.env.VUE_APP_API_BASE_URL ?? ''
let hasValidToken = tokenHelper.hasValidToken()

export default {
  name: 'app',
  components: {InfoNotification},
  data() {
    return {
      modules: [],
    }
  },
  methods: {
    applyTheme() {
      let theme = store.get('theme')

      if (typeof theme == "undefined") {
        theme = this.getTheme();
      }

      this.applyThemeOnApp(theme)
      this.applyThemeOnFavIcon(theme)
    },
    applyThemeOnApp(theme) {
      let htmlElement = document.documentElement;
      htmlElement.setAttribute('theme', theme);
    },
    applyThemeOnFavIcon(theme) {
      this.applyThemeOnFavIconSize(theme, '16x16')
      this.applyThemeOnFavIconSize(theme, '32x32')
      this.applyThemeOnFavIconSize(theme, '96x96')
    },
    applyThemeOnFavIconSize(theme, size) {
      var link = document.querySelector("link[rel~='icon'][size~='" + size + "']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        link.setAttribute('sizes', size)
        document.head.appendChild(link)
      }
      link.href = '/favicons/' + theme + '/favicon-' + size + '.png'
    },
    getTheme() {
      //Get url
      let urlString = window.location.href;

      // Create a URL object
      let url = new URL(urlString);

      // Extract the desired text from the hostname
      let link = url.hostname.split('.')[0];

      let theme = '';

      switch (link) {
        case 'burriachermann':
          theme = "bua";
        break;
        case 'supersaxo':
          theme = 'sup'
        break
        case 'har':
          theme = 'har'
        break
        case 'haegni':
          theme = 'hgi'
        break
        case 'zweifel':
          theme = 'zwe'
        break
        case 'jaislixamax':
          theme = 'jax'
        break
        case 'schlagenhauf':
          theme = 'sag'
        break
        case 'repoxit':
          theme = 'rep'
        break
        case 'cubera':
          theme = 'cub'
        break
        case 'carpenter':
          theme = 'car'
        break
        case 'freestyleacademy':
          theme = 'fsa'
        break
        case 'gemeinsamimalter':
          theme = 'gia'
        break
        case 'hardworker':
          theme = 'har'
        break
        case 'ponato':
          theme = 'pon'
        break
        default:
          theme = 'cub'
        break
      }
      return theme;
    }
  },
  async beforeCreate() {

      await Auth.setupAxios()

      if (this.$route.path !== '/oidc-callback') {
        await Auth.loadConfig()
      }

  },
  async created() {
  },
  async mounted() {
    this.$root.infoNotification = this.$refs.infonotification
    EventBus.$on('http-error', function (msg) {
      this.$root.infoNotification.showMessage(msg);
    }.bind(this))
    this.applyTheme()
  },
}
</script>

<style lang="scss">
@import "./assets/styles/app.scss";
</style>
