<template>
    <div>
        <BasicCreateEditContainer
            :title="mode == 'create' ? t('create.title') : $t('shop.categories.edit.title', { categoryName: category.name })"
            ref="editor"
            object-type="Category"
            :object-name="category.name"
            :data-loaded="dataLoaded"
            @cancel="close"
            :save-action="saveCategory"
            @saved="onSaved"
            :allow-delete="mode == 'edit'"
            :delete-action="deleteCategory"
            @deleted="onDeleted"
            :has-changes="hasChanges"
        >
            <template v-slot:content> 
                <v-card elevation="0">
                    <v-card-title>{{ t('create.card-title') }}</v-card-title>
                    <v-card-text>
        
                    <v-text-field
                        :label="t('create.placeholder.name')"
                        v-model="category.name"
                        counter="40"
                        filled
                        :rules="[
                            () => !!category.name || t('validation.error-name-required'),
                            () => (category.name && category.name.length <= 40) || t('validation.error-max-name')
                        ]"
                    ></v-text-field>
        
                    </v-card-text>
                </v-card> 
            </template>

            <template v-slot:meta>
                <v-card elevation="0">
                    <v-card-title>{{ t('status.card-title') }}</v-card-title>
                    <v-card-text>
        
                        <v-switch
                            v-model="category.status"
                            false-value="inactive"
                            true-value="active"
                            :label="category.status ? t('status.active') : t('status.inactive')"
                        ></v-switch>
        
                    </v-card-text>
                </v-card>
            </template>
    
        </BasicCreateEditContainer>
    </div>
  </template>

  <script>
  import { HTTP } from '@/auth'
  import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'

  export default {
    name: 'ShopCategoriesCreateEdit',
    components: {
      BasicCreateEditContainer,
    },
    data () {
      return {
        category: {
          id: null,
          name: '',
          order: null,
          status: 'active'
        },
        dataOriginal: null,
        dataLoaded: false,
        mode: null,
      }
    },
    created() {
        this.category.id = this.$route.params.id
        if (this.category.id) {
            this.getCategory()
            this.mode = 'edit'
        } else {
            this.mode = 'create'
            this.dataOriginal = JSON.parse(JSON.stringify(this.category))
            this.dataLoaded = true
        }
    },
    computed: {
      hasChanges() {
        return JSON.stringify(this.category) !== JSON.stringify(this.dataOriginal)
      },
    },
    methods: {
      t: function (key) {
        return this.$t('shop.categories.' + key)
      },
      getCategory() {
        HTTP.get('shop_categories/' + this.category.id).then(function (response) {
          this.category = response.data
          this.dataOriginal = JSON.parse(JSON.stringify(this.category))
          this.dataLoaded = true
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
            this.dataLoaded = false
          }
        }.bind(this))
      },
      saveCategory() {
        if (this.mode == 'create') {
          return HTTP.post('shop_categories', this.category)
        } else {
          return HTTP.patch('shop_categories/' + this.category.id, this.category)
        }
      },
      onSaved() {
        this.dataOriginal = null
        this.close()
      },
      deleteCategory() {
        return HTTP.delete('shop_categories/' + this.category.id, this.category)
      },
      onDeleted() {
        this.dataOriginal = null
        this.close()
      },
      close() {
        this.$router.push({ name: 'ShopCategoriesIndex' })
      }
    }
}
</script>