import DocumentsIndex from "../../views/DocumentsIndex";

const documentsRoutes = [
    {
        path: '/documents',
        name: 'Documents',
        component: DocumentsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['documents_explorer'],
                requiresAuth: true
            }
        }
    }
]

export default documentsRoutes
