<template>
  <div class="has-hero-container">
    <div>
      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

          <app-filter-apply-button
              v-if="filterStore.hasChanges"
              @click="applyFilter(); options.page = 1"
          ></app-filter-apply-button>
          
          <v-slide-group show-arrows>
      
            <v-slide-item>
            
              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <app-filter-search-field
                      v-model="filterStore.filter.searchTerm"
                      :filterLabel="t('filter.name-search')"
                      clearable
                      append-icon="mdi-magnify"									
                    ></app-filter-search-field>
                  </div>
                </div>
              </div>
          
            </v-slide-item>	
              
          </v-slide-group>
        </v-container>
      </div>

      <v-container fluid>

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div>
            <v-btn
                elevation="0"
                color="primary"
                class="icon-left"
                :to="{ name: 'ChecklistCreateSubtask' }"
            >
              <v-icon>mdi-plus</v-icon>
              {{ t('create') }}
            </v-btn>

          </div>
        </v-row>

        <v-row v-if="dataLoaded && elements.data.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>

          <v-data-table
              :headers="headers"
              :items="elements.data"
              :options.sync="options"
              :server-items-length="dataTotal"
              :loading="loading"
              class="elevation-0"
              :sort-by.sync="filterStore.table.sortBy"
              :sort-desc.sync="filterStore.table.sortDesc"
              :page.sync="filterStore.table.currentPage"
              :items-per-page.sync="filterStore.table.itemsPerPage"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-options': [25, 50, 100, 200],
                pageText: '{0}-{1} von {2}',
                'items-per-page-text': t('table.items-per-page')
              }"
          >
            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ name: 'ChecklistEditSubtask', params: { id: item.id } }" class="edit-row-table">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>
          </v-data-table>

        </v-row>
      </v-container>

    </div>
  </div>
</template>

<script>
  import { HTTP } from '../auth/index'
  import toolbar from '@/components/layouts/Navigation.vue'
  import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
  import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
  import { useChecklistSubtasksFilterStore } from '@/stores/ChecklistSubtasksFilterStore'
  import Vue from 'vue'

  export default {
	name: 'ChecklistSubtasksIndex',
    components: {
      toolbar,
      AppFilterApplyButton,
	    AppFilterSearchField
    },
    setup() {
      const filterStore = useChecklistSubtasksFilterStore()
      return { filterStore }
    },
    data () {
      return {
        loading: false,
        elements: {
          page: 1,
          current_page: 1,
          last_page: 1,
          total: null,
          data: []
        },
        dataLoaded: false,
        error: false,
        dataTotal: 0,
        options: {}
      }
    },
    created() {
      this.applyFilter(false)
    },
    computed: {
      headers() {
        return [
          {
            text: this.t('table.headers.name'),
            align: 'start',
            sortable: true,
            value: 'name'
          },
          {
            text: this.t('table.headers.edit'),
            value: 'edit',
            sortable: false,
            align: 'center',
            width: 150,
          }
        ]
      }
    },
    watch: {
      options: {
        handler() {
          this.getElements()
        },
        deep: true,
      }
    },
    methods: {
      t: function (key) {
        return this.$t('checklists.subtasks.list.' + key);
      },
      reload () {
        this.$nextTick(function () {
          this.getElements()
        }.bind(this))
      },
      applyFilter(doReload = true) {
        this.filterStore.filterApplied()
        if (doReload) {
          this.reload()
        }
      },
      getElements () {
        this.loading = true

        let q = ''
        if (this.filterStore.filter.searchTerm) {
          q = '?q=' + this.filterStore.filter.searchTerm
        }

        HTTP.get('elements' + q, {params: this.options})
          .then(function (response) {
            this.elements.current_page = response.data.current_page
            this.elements.last_page = response.data.last_page
            this.elements.data = response.data.data
            this.elements.total = response.data.total
            this.dataTotal = response.data.total
            this.error = false
            this.dataLoaded = true
            this.loading = false
          }.bind(this)).catch(function (error) {
            if (!error.status) {
              this.error = true
            }
        }.bind(this))
      }
    }
  }
</script>

<style scoped lang="scss">
	.sp-hero {
		
		.filter-search-list {
			width: calc(66.66% - 134px);
		}
	}
</style>
