<template>
    <div>

       <BasicCreateEditContainer
            :title="$t('menu.edit.header_title', { moduleName: data['title_de'] })"
            ref="editor"
            :data-loaded="dataLoaded"
            @cancel="cancel"
            :object-name="data.title_de"
            :saveAction="saveItem"
            :delete-action="deleteItem"
            @saved="close"
            @deleted="close"
            :has-changes="hasChanges"
            :hidden-field-validation-message="$t('menu.validation.hidden-field-validation')"

        >
            <template v-slot:content>

                <v-card elevation="0">

                    <v-card-title>{{ t('details-title') }}</v-card-title>

                    <v-card-text>

                        <div v-show="data.type == 'module'">
                            <loading v-if="!showModules"></loading>
                            <div v-else>
                                <div class="module-wrapper">
                                    <v-btn
                                        v-for="(item, index) in modules" :key="index" v-if="item.active &&  activeMenuItems.includes(item.id)"
                                        class="chooseIconButton"
                                        elevation="0"
                                        disabled
                                        @click="setActiveButton(item.id)"
                                        >
                                            <v-icon class="v-icon">{{ item.icon }}</v-icon>
                                            {{ $t(item.title) }}
                                            <v-icon>
                                                mdi-check-bold
                                            </v-icon>
                                        
                                    </v-btn>
                                </div>
                                <div class="module-wrapper" >
                                    <v-btn
                                        v-for="(item, index) in modules" :key="index" v-if="item.active  && !activeMenuItems.includes(item.id)"
                                        class="chooseIconButton"
                                        elevation="0"
                                        @click="setActiveButton(item.id)"
                                        >
                                            <v-icon class="v-icon">{{ item.icon }}</v-icon>
                                            {{ $t(item.title) }}
                                            <v-icon v-if="data.module_id == item.id">
                                                mdi-chevron-down-circle-outline
                                            </v-icon>
                                            <v-icon v-else>
                                                mdi-circle-outline
                                            </v-icon>
                                    </v-btn>
                                </div>
                                <div class="clear-both">
                                    <div class="ml-2">{{ t('other-modules') }}</div>
                                    <div class="module-wrapper">
                                        <v-btn
                                            v-for="(item, index) in modules" v-if="!item.active"
                                            class="chooseIconButton"
                                            elevation="0"
                                            disabled
                                            >
                                                <v-icon class="v-icon">{{ item.icon }}</v-icon>
                                                {{ $t(item.title) }}
                                        </v-btn>
                                    </div>
                                    <div class="mt-4 ml-2 clear-both" v-show="data.type == 'module'">
                                        <a href="mailto:michael.fretz@cubera.ch">{{ t('extend-solution') }}</a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div style="clear:both;;">

                            <choose-icon class="ml-2" v-model="data.icon" :compact="true"></choose-icon>
                    
                            <v-text-field
                                class="mt-8"
                                :label="t('title') + ' EN*'"
                                v-model="data.title_en"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_en || $t('validation.required.default'),
                                    () => (data.title_en && data.title_en.length <= 40) || $t('menu.validation.length-message')
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' DE*'"
                                v-model="data.title_de"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_de || $t('validation.required.default'),
                                    () => (data.title_de && data.title_de.length <= 40) || $t('menu.validation.length-message')
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' IT*'"
                                v-model="data.title_it"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_it || $t('validation.required.default'),
                                    () => (data.title_it && data.title_it.length <= 40) || $t('menu.validation.length-message')
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' FR*'"
                                v-model="data.title_fr"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_fr || $t('validation.required.default'),
                                    () => (data.title_fr && data.title_fr.length <= 40) || $t('menu.validation.length-message')
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' ES*'"
                                v-model="data.title_es"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_es || $t('validation.required.default'),
                                    () => (data.title_es && data.title_es.length <= 40) || $t('menu.validation.length-message')
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' PT*'"
                                v-model="data.title_pt"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_pt || $t('validation.required.default'),
                                    () => (data.title_pt && data.title_pt.length <= 40) || $t('menu.validation.length-message')
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' TR*'"
                                v-model="data.title_tr"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_tr || $t('validation.required.default'),
                                    () => (data.title_tr && data.title_tr.length <= 40) || $t('menu.validation.length-message')
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' UK*'"
                                v-model="data.title_uk"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_uk || $t('validation.required.default'),
                                    () => (data.title_uk && data.title_uk.length <= 40) || $t('menu.validation.length-message')
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' RU*'"
                                v-model="data.title_ru"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_ru || $t('validation.required.default'),
                                    () => (data.title_ru && data.title_ru.length <= 40) || $t('menu.validation.length-message')
                                ]"
                            ></v-text-field>
                            
                            <v-text-field
                                v-if="data.type == 'webview' || data.type == 'external_browser'"
                                :label="t('url')"
                                v-model="data.url"
                                filled
                                hide-details="auto"
                                :rules="[
                                    () => !!data.url || $t('validation.required.default'),
                                ]"
                            ></v-text-field>
                    
                            <v-text-field
                                ref="hiddenValidation"
                                class="hidden"
                                v-model="noIconValue"
                                :rules="[
                                    () => !!noIconValue || $t('menu.validation.hidden-field-validation')
                                ]"
                            ></v-text-field>

                            <content-editor v-show="data.type == 'content_block'" content-type="menu"  v-model="data.blocks"/>
                                
                        </div>
                    </v-card-text>

                    

                </v-card>

            </template>

            <template v-slot:meta>
                <v-card elevation="0">
                    <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
                    <v-card-text>
                        <permission-group-selector
                            v-model="data.assigned_permission_groups"
                            :required="true"
                            mode="dropdown"/>
                    </v-card-text>
                </v-card>
            </template>

        </BasicCreateEditContainer>
    </div>
</template>

<script>
import auth from './../auth/index'
import { HTTP } from '@/auth'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
import ChooseIcon from '@/components/dialogs/ChooseIcon'
import store from 'store'
import ContentEditor from '@/components/ContentEditor.vue'
import PermissionGroupSelector from '@/components/PermissionGroupSelector.vue'
import loading from '@/components/layouts/Loading.vue'


export default {
    name: 'MenuEdit',
    components: {
        BasicCreateEditContainer,
        ChooseIcon,
        ContentEditor,
        PermissionGroupSelector,
        loading
    },
    data() {
        return {
            itemId: null,
            dataLoaded: false,
            data: {
                title_en: '',
                title_de: '',
                title_it: '',
                title_fr: '',
                title_es: '',
                title_pt: '',
                title_tr: '',
                title_uk: '',
                title_ru: '',
                url: '',
                icon: '',
                type: '',
                assigned_permission_groups: [],
                module_id: 0,
                blocks: []
            },
            type: '',
            dataOriginal: null,
            showModules: false,
            permissionGroups: [],
            modules: store.get('modules'),
            activeMenuItems: [],
            maxTitleLength: 40
        }
    },
    mounted() {
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
    },
    created() {
        // You can set the value of 'type' here
        this.itemId = this.$route.params.id
        this.getPermissionGroups()
        this.data.type = this.$route.params.type
        this.getMenu()    
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
        },
        noIconValue() {
            return this.data.icon !== '' ? 'hasIcon' : null
        }
    },
    methods: {
        t: function (key) {
            return this.$t('menu.edit.' + key)
        },
        close() {
            this.cancel()
        },
        cancel() {
            this.$router.push('/settings/menu')
        },
        getPermissionGroups() {
            HTTP.get('permission-groups?sortBy[]=name').then(function (response) {
                this.permissionGroups = response.data.data
            }.bind(this)).catch(function (error) {
                this.error = error
                this.openSnackbar()
            }.bind(this))
        },
        deleteItem() {
            return HTTP.delete('menus/' + this.itemId)
        },
        getActiveMenuItems() {
            HTTP.get('activeMenuItems/' + this.data.module_id).then(function (response) {

                this.activeMenuItems = response.data
                this.showModules = true;
            }.bind(this)).catch(function (error) {
                this.error = error
                this.openSnackbar()
            }.bind(this))
        },
        async getMenu() {
            await HTTP.get('menus/' + this.itemId).then((response) => {

                let refactor = response.data;
                //if (refactor.customer != null) refactor.customer_id = refactor.customer;

                const tempPermGroupsIds = []
                response.data.assigned_permission_groups.forEach((element) => {
                    tempPermGroupsIds.push(element.permission_group_id)
                })

                refactor.assigned_permission_groups = tempPermGroupsIds
            
                this.dataLoaded = true
                this.data = refactor

                this.getActiveMenuItems()
            })
        },
        async saveItem() {

            let formData = new FormData()

            let i = 0
            this.data.blocks.forEach(z => {
                z.order = i++
                if (z.name === 'Video' && z.new_content) {
                    formData.append('video-' + z.order, z.video)
                }
            })

            formData.append('data', JSON.stringify(this.data))

            const config = {
                headers: {'content-type': 'multipart/form-data'}
            }

           await HTTP.post('/menus/' + this.data.id, formData, config)
        auth.loadConfig()
        }
    }
}
</script>

<style scoped lang="scss">
    .chooseIconButton {
        margin: 5px;
        float:left;
    }

    .chooseIconButton .v-icon {
        margin: 5px;
    }

    .clear-both {
        clear: both;
    }
</style>
