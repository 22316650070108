<template>
  <div>
    <BasicSettingsContainer
        :title="$t('time-tracking.title')"
        :data-loaded="dataLoaded"
        :has-changes="hasChanges"
        @reset="reset"
        :save-action="save"
    >

    <v-card elevation="0" class="sp-mar-top-3" >
          <v-card-title>{{ $t('settings.time-tracking.modules') }}</v-card-title>
          <v-card-text>
            <div>
              <v-checkbox v-model="item.timelog_qr">
                <template v-slot:label>
                  <div>
                    <v-icon>mdi-cellphone</v-icon>
                    {{ $t('navigation.timelog-qr') }}
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox v-model="item.timelog_stamp">
                <template v-slot:label>
                  <div>
                    <v-icon>mdi-desktop-mac</v-icon>
                    {{ $t('navigation.timelog-stamp') }}
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox v-model="item.time_weekly_report">
                <template v-slot:label>
                  <div>
                    <v-icon>mdi-cellphone</v-icon>
                    {{ $t('navigation.timelog-weekly-report') }}
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox v-model="item.timelog_basic">
                <template v-slot:label>
                  <div>
                    <v-icon>mdi-cellphone</v-icon>
                    <v-icon>mdi-desktop-mac</v-icon>
                    {{ $t('navigation.timelog-timelogs') }}
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox v-model="item.timelog_proof_report">
                <template v-slot:label>
                  <div>
                    <v-icon>mdi-desktop-mac</v-icon>
                    {{ $t('navigation.timelog-proof-report') }}
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox v-model="item.timelog_expense_report">
                <template v-slot:label>
                  <div>
                    <v-icon>mdi-desktop-mac</v-icon>
                    {{ $t('navigation.timelog-expense-report') }}
                  </div>
                </template>
              </v-checkbox>
             
              <v-checkbox v-model="item.timelog_approval">
                <template v-slot:label>
                  <div>
                    <v-icon>mdi-desktop-mac</v-icon>
                    {{ $t('navigation.timesheet') }}
                  </div>
                </template>
              </v-checkbox>
              
            </div>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3" >
          <v-card-title>{{ $t('settings.time-tracking.reports') }}</v-card-title>
          <v-card-text>
            <div>
              
              <v-checkbox v-model="item.timelog_report">
                <template v-slot:label>
                  <div>
                    <v-icon>mdi-desktop-mac</v-icon>
                    {{ $t('navigation.timelog-reports') }}
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox v-model="item.timelog_reportoverview">
                <template v-slot:label>
                  <div>
                    <v-icon>mdi-desktop-mac</v-icon>
                    {{ $t('navigation.timelog-reportoverview') }}
                  </div>
                </template>
              </v-checkbox>

              <v-checkbox v-model="item.time_sheets_month">
                <template v-slot:label>
                  <div>
                    <v-icon>mdi-desktop-mac</v-icon>
                    {{ $t('navigation.timelog-salaryreport') }}
                  </div>
                </template>
              </v-checkbox>
            </div>
          </v-card-text>
        </v-card>


    </BasicSettingsContainer>

  </div>

</template>

<script>
import {HTTP} from './../auth'
import toolbar from '../components/layouts/Navigation.vue'
import loading from '../components/layouts/Loading.vue'
import AppDialogConfirmSave from "../components/vuetify/AppDialogConfirmSave";
import BasicSettingsContainer from "../components/inputs/BasicSettingsContainer.vue";
import {EventBus} from "@/event-bus";
import Auth from "@/auth";
       

export default {
  name: 'SettingsGlobal',
  components: {
    BasicSettingsContainer,
    toolbar,
    loading,
    AppDialogConfirmSave
  },
  data() {
    return {
      item: {
        timelog_stamp: null,
        timelog_qr: null,
        timelog_basic: null,
        timelog_report: null,
        timelog_reportoverview: null,
        timelog_sheets_month: null,
        time_weekly_report: null,
        timelog_proof_report: null,
        timelog_expense_report: null,
        timelog_approval: null
      },
      itemOriginal: null,
      dataLoaded: false,
    }
  },
  async created() {
    await this.getData()
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
  },

  methods: {
    async getData() {
      this.dataLoaded = false
      let response = await HTTP.get('settings/timetracking')
      this.item.timelog_stamp = response.data.timelog_stamp
      this.item.timelog_qr = response.data.timelog_qr
      this.item.timelog_basic = response.data.timelog_basic
      this.item.timelog_report = response.data.timelog_report
      this.item.timelog_reportoverview = response.data.timelog_reportoverview
      this.item.time_sheets_month = response.data.time_sheets_month
      this.item.time_weekly_report = response.data.time_weekly_report
      this.item.timelog_proof_report = response.data.timelog_proof_report
      this.item.timelog_expense_report = response.data.timelog_expense_report
      this.item.timelog_approval = response.data.timelog_approval

      this.itemOriginal = JSON.parse(JSON.stringify(this.item));
      this.dataLoaded = true
    },
    async reset() {
      await this.getData()
    },
    async save() {
      await HTTP.put('settings/timetracking', this.item)
      await this.getData()
      await Auth.loadConfig()

    },
  }
}
</script>

<style scoped lang="scss">

.settings-wrapper {
  justify-content: space-between;

  .v-card {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
}

</style>
