<template>
  <div class='LinkModal linkmodal' v-if='showModal'>
    <div class='modal-content'>
      <h1>Link einfügen</h1>
      <md-input-container >
        <label>Link</label>
        <md-input type="text" v-model="url" :required="true"></md-input>
        <span class="md-error">Bitte ein Link einfügen</span>
      </md-input-container>
      <footer class='modal-footer'>
        <md-button  @click.native="insertLink" class="md-raised">{{ presetURL ? 'Link aktualisieren' : 'Link einfügen' }}</md-button>
        <md-button  @click.native="closeModal" class="md-raised">Schliessen</md-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { EventBus } from './../../event-bus'

export default {
  data () {
    return {
      url: '',
      command: null,
      showModal: false,
      presetURL: null
    }
  },
  computed: {

  },
  methods: {
    showLinkModal (command, presetURL) {
      // Add the sent command
      this.command = command
      this.url = presetURL
      this.showModal = true
    },
    insertLink () {
      const data = {
        command: this.command,
        src: this.url
      }
      EventBus.$emit('set-link', data)
      this.showModal = false
    },
    closeModal () {
      this.showModal = false
      this.url = ''
    }
  }
}
</script>

<style scoped>
.linkmodal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.modal-content {
  background-color: #FFF;
  padding: 30px;
  border-radius: 4px;
  width: 50%;
}

.modal-footer {
	margin-top: 10px;
}

.md-button {
	margin: 0;
}

.md-button + .md-button {
	margin-left: 10px;
}
</style>
