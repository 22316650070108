<template>
    <md-list-item>
        <v-icon>{{ item.icon }}</v-icon>
        <span class="module-title">{{ item.title }}</span>
        <md-list-expand>
            <md-list>
            <md-list-item @click.native="goToChat" class="md-inset custom-padding">
                <span>{{ $t('navigation.chat') }}</span>
            </md-list-item>
            <md-list-item class="md-inset">
                <router-link :to="{ path: '/chat-groups' }">
                <span>{{ $t('navigation.chat-groups') }}</span>
                </router-link>
            </md-list-item>
            </md-list>
        </md-list-expand>
    </md-list-item>
</template>

<script>

export default {
    name: 'ChatListItem',
    data() {
        return {
            
        }
    },
    props: {
        item: {
            type: Object,
            required: false,
            default: {}
        },
        user: {
            type: Object,
            required: false,
            default: {}
        },
        modules: {
            type: Array,
            required: false,
            default: []
        },
    }, 
    methods: {
        goToChat: function() {
            window.open('/chat' );
        }
    }
    
}
</script>