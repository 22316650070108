<template>
  <div>
    <toolbar :dataLoaded="true">{{ $t('module-config.title') }}</toolbar>
    <loading :dataLoaded="dataLoaded"></loading>
    <v-container fluid v-if="dataLoaded" class="mt-8 custompadding">
      <v-row>
        <v-col>
          <h3>{{ $t('module-config.communication') }}</h3>
        </v-col>
        <v-col>
          <h3>{{ $t('module-config.organisation') }}</h3>
        </v-col>
        <v-col>
          <h3>{{ $t('module-config.time') }}</h3>
        </v-col>
        <v-col>
          <h3>{{ $t('module-config.theme') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox v-for="(value,index) in data" v-if="value.type == 'communication'" :label="$t(value.title)" v-model="value.active">
          </v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox v-for="(value,index) in data" v-if="value.type == 'organisation'" :label="$t(value.title)" v-model="value.active">
          </v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox v-for="(value,index) in data" v-if="value.type == 'time'" :label="$t(value.title)" v-model="value.active">
          </v-checkbox>
        </v-col>
        <v-col>
          <v-text-field label="Theme/CustomerCode" v-model="theme" filled/>
          <v-btn href="/push-configuration"  filled elevation="0">
            Push Configuration
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div class="bottom-navigation modal-nav">
      <v-container fluid>
        <v-row>
          <v-btn @click="save" color="primary"
                 elevation="0"

                 class="sp-float-right icon-left">{{ $t('module-config.save') }}
          </v-btn>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/auth";
import toolbar from '../components/layouts/Navigation.vue'
import Loading from "@/components/layouts/Loading";
import store from 'store';
import {EventBus} from "@/event-bus";

export default {
  name: "ModulesConfiguration",
  components: {
    Loading,
    toolbar
  },
  data() {
    return {
      dataLoaded: false,
      data: {
        absence: false,
        chat: false,
        customers: false,
        documents_explorer: false,
        emergency_contacts: false,
        enrollments: false,
        expense: false,
        gallery: false,
        ideabox: false,
        jobs: false,
        news: false,
        phonebook: false,
        servappt: false,
        surveys: false,
        surveys_wv: false,
        tasks: false,
        timelog_basic: false,
        timelog_report: false,
        timelog_qr: false,
        timelog_targethours: false,
        timelog_wv: false,
        timesheet: false,
        quality: false,
        quality_report: false,
        work_report: false,
        customer_notes: false
      },
      theme: '',
    }
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      let self = this
      this.dataLoaded = false
      let response = await HTTP.get('client-config?v2')
      self.data = response.data.modules;
      
      this.theme = response.data.theme;
      this.dataLoaded = true
    },
    async save() {
      let dataCopy = JSON.parse(JSON.stringify(this.data))
     
     
      this.dataLoaded = false
      await HTTP.post('client-config', {
        modules: this.data,
        theme: this.theme,
      }).finally(function () {
        this.dataLoaded = true
      }.bind(this))

      store.set('modules', this.data)

      await this.loadData()

      this.$root.infoNotification.showMessage('Module configuration saved.')

      EventBus.$emit('module-config-changed')
    }
  }

}
</script>

<style scoped>
  .custompadding {
    padding-bottom: 100px !important;
  }
</style>
