import NewsIndex from "../../views/NewsIndex";
import NewsCategoriesIndex from "../../views/NewsCategoriesIndex";
import NewsCreate from "../../views/NewsCreate";
import NewsEdit from "../../views/NewsEdit";
import NewsCategoriesCreate from "../../views/NewsCategoriesCreate";
import NewsCategoriesEdit from "../../views/NewsCategoriesEdit";

const newsRoutes = [
    {
        path: '/news',
        name: 'News',
        component: NewsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['news'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/news/create',
        name: 'NewsCreate',
        component: NewsCreate,
        meta: {
            accessConfig:  {
                moduleName: ['news'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/news/:id?',
        name: 'NewsEdit',
        component: NewsEdit,
        meta: {
            accessConfig:  {
                moduleName: ['news'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/news-categories',
        name: 'NewsCategoriesIndex',
        component: NewsCategoriesIndex,
        meta: {
            moduleName: 'news',
            accessConfig:  {
                moduleName: ['news'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/news-categories/create',
        name: 'NewsCategoriesCreate',
        component: NewsCategoriesCreate,
        meta: {
            accessConfig:  {
                moduleName: ['news'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/news-categories/:id?',
        name: 'NewsCategoriesEdit',
        component: NewsCategoriesEdit,
        meta: {
            accessConfig:  {
                moduleName: ['news'],
                requiresAuth: true
            }
        }
    },/*,
    {
        path: '/news/:id?',
        name: 'NewsSingle',
        component: NewsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['news'],
                requiresAuth: true
            }
        }
    }*/
]

export default newsRoutes
