const themesSag = {
  themes: {
    light: {
      primary: '#254f91',
      secondary: '#14b8de',
      success: '#4CAF50',
      warning: '#FB8C00',
      error: '#FF5252',
      buttons:'#254f91'
    }
  },
}

export default themesSag
