<template>
  <div>
    <BasicNewContainer
        :title="t('title.create')"
        ref="editor"
        @cancel="close"
        :saveAction="saveItem"
        @saved="onSaved"
        :has-changes="hasChanges">
      <v-card elevation="0">
        <v-card-title>{{ t('detail.group-title') }}</v-card-title>
        <v-card-text>
          <v-text-field
              filled
              :label="t('detail.name') + '*'"
              counter="120"
              v-model="item.name"
              :rules="[
								  () => !!item.name || $t('validation.required.name'),
								  () => (item.name && item.name.length <= 120) || $t('validation.length.default')
							  ]"
          ></v-text-field>

        </v-card-text>
      </v-card>

      <v-card elevation="0"
              class="sp-mar-top-3">
        <v-card-title>{{ t('items.group-title') }}</v-card-title>
        <v-card-text class="drag-wrapper">
          <draggable tag="div" v-if="item.items.length > 0" v-model="item.items" v-bind="getOptions()" class=" mb-8">
            <div class="drag-element" v-for="(item, index) in item.items" :key="index">
              <v-row class="pb-1">
                <v-col cols="1">
                  <v-icon class="drag-indicator">mdi-drag</v-icon>
                </v-col>
                <v-col cols="3">
                  <v-text-field filled :label="t('items.name')" v-model="item.name"
                                :rules="[
								  () => !!item.name || $t('validation.required.name'),
								  () => (item.name && item.name.length <= 120) || $t('validation.length.default')
							  ]"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="3">
                  <v-text-field filled :label="t('items.unit')" v-model="item.unit" :rules="[
								  () => !!item.unit || $t('validation.required.unit'),
								  () => (item.unit && item.unit.length <= 120) || $t('validation.length.default')
							  ]" hide-details="auto"/>
                </v-col>
                <v-col cols="3">
                  <v-text-field filled :label="t('items.price')" v-model="item.price" :rules="[
								  () => !!item.price || $t('validation.required.price'),
								  () => (item.price && item.price.length <= 120) || $t('validation.length.default')
							  ]" hide-details="auto"/>
                </v-col>
                <v-col cols="1" align="right">
                  <md-button @click="removeItem(index)" class="sp-text-blue sp-mar-r-0"
                             :title="t('items.remove')">
                    <md-icon class="sp-text-blue">remove_circle</md-icon>
                  </md-button>
                </v-col>

              </v-row>

            </div>
          </draggable>
          <div>
            <v-row>
              <v-col class="mt-4">
                <strong>{{ t('add-new-position') }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field filled :label="t('items.name')" v-model="newItem.name" hide-details="auto"/>
              </v-col>
              <v-col cols="3">
                <v-text-field filled :label="t('items.unit')" v-model="newItem.unit" hide-details="auto"/>
              </v-col>
              <v-col cols="3">
                <v-text-field filled :label="t('items.price')" type="number" v-model="newItem.price"
                              hide-details="auto"/>
              </v-col>
              <v-col cols="1" align="right">
                <md-button @click="addNewItem" class="sp-text-blue sp-mar-r-0"
                           :title="t('items.add')">
                  <md-icon class="sp-text-blue">add_circle</md-icon>
                </md-button>
              </v-col>
            </v-row>

          </div>
        </v-card-text>
      </v-card>
    </BasicNewContainer>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import BasicNewContainer from "@/components/inputs/BasicNewContainer";
import draggable from "vuedraggable";

export default {
  name: 'WorkReportListItemsCreate',
  components: {
    BasicNewContainer,
    draggable
  },
  data() {
    return {
      item: {
        name: '',
        items: []
      },
      itemOriginal: null,
      newItem: {
        name: '',
        unit: '',
        price: 0.00,
      },
      indexRouteName: 'WorkReportsSelectionListsIndex'
    }
  },
  created() {
    this.itemOriginal = JSON.parse(JSON.stringify(this.item));
  },
  mounted() {
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('workreport.selectionlist.details.' + key);
    },
    getOptions() {
      return {
        sort: true,
        disabled: this.editDisabled
      }
    },
    removeItem(key) {
      this.item.items.splice(key, 1)
    },
    addNewItem() {
      this.item.items.push({...this.newItem})
      this.newItem.name = '';
      this.newItem.unit = '';
      this.newItem.price = 0;
    },
    saveItem() {
      return HTTP.post('work-reports/selection-lists', this.item);
    },
    async onSaved() {
      this.dataOriginal = null;
      this.close();
    },
    close() {
      this.$router.push({name: this.indexRouteName})
    }
  }
}
</script>

<style scoped lang="scss">
.drag-element {
  position: relative;
  border: 0;
  cursor: grab;
  padding: 5px 10px 2px 10px;

  .drag-indicator {
    position: absolute;
    left: 3px;
    top: 7px;
    font-size: 36px;
    color: #999;
    margin-right: 10px;
  }

  .entry-edit {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
  }

  .entry-remove {
    position: absolute;
    right: 45px;
    top: 12px;
    cursor: pointer;
  }

  .disable-drag {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }

  &.sortable-chosen {
    cursor: grabbing;
  }

  .cta-icon {
    cursor: pointer;
  }
}
</style>
