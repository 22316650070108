<template>
  <div >
    <v-dialog
        v-model="dialog"
        content-class="dialog"
        width="1000"
        class="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          elevation="0"
          color="primary"
          class="icon-left"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t('components.picture-upload.add') }}
        </v-btn>

      </template>
      <v-card>
        <v-card-title>
          {{ $t('components.picture-upload.title') }}
        </v-card-title>

        <v-card-text>
          <div v-for="(file, index) in uploadedFiles" class="pictureBlock" :key="index">
            <img :src="previewUrls[index]" alt="Preview" class="picture">

            <div class="icon-container">
              <v-icon class="trash-can" @click="deletePicture(index)">mdi-minus</v-icon>
              <div class="round-overlay"></div>
            </div>
          </div>

          <v-file-input
              v-show="false"
              v-model="selectedFiles"
              id="fileInput"
              label="Drag and drop files here or click to browse"
              multiple
              @change="handleFileUpload"
          ></v-file-input>

          <div class="drag_and_drop" @dragover.prevent @drop="handleFileDrop" @click="openFileExplorer">
              <p class="first_title">{{ $t('components.picture-upload.drag_and_drop') }}</p>
              <p class="first_title">{{ $t('components.picture-upload.or') }}</p>
              <p class="second_title">{{ $t('components.picture-upload.browse_computer') }}</p>
          </div>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
            {{ $t('components.picture-upload.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="finishUpload()"
          >
            <v-icon>mdi-check</v-icon>
            {{ $t('components.picture-upload.done') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
  export default {
    name: 'PictureUploadDialogue',
    props: {
      objectType: String,
      objectName: String,
    },
    data() {
      return {
        selectedFiles: [],
        uploadedFiles: [],
        previewUrls: [],
        dialog: false
      }
    },
    methods: {
      async handleFileUpload() {
          for (const file of this.selectedFiles) {
              const previewUrl = await this.getPreviewUrl(file)
              this.previewUrls.push(previewUrl)
              this.uploadedFiles.push(file)
          }
          this.selectedFiles = []
      },
      getPreviewUrl(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
              resolve(reader.result)
            };
            reader.onerror = (error) => {
              reject(error)
            };
            reader.readAsDataURL(file)
        });
      },
      deletePicture(key) {
          this.previewUrls.splice(key, 1)
          this.uploadedFiles.splice(key, 1)
      },
      async handleFileDrop(event) {
          event.preventDefault()
          const files = event.dataTransfer.files

          for (const file of files) {
              const previewUrl = await this.getPreviewUrl(file)
              this.previewUrls.push(previewUrl)
              this.uploadedFiles.push(file)
          }
          this.selectedFiles = []
      },
      openFileExplorer() {
          document.querySelectorAll('.dialog').forEach(dialog => {
              if (window.getComputedStyle(dialog).display !== 'none') {
                  var fileInput = dialog.querySelector('#fileInput')
                  
                  if (fileInput) {
                      fileInput.click()
                  }
              }
          });       
      },
      finishUpload() {
        this.$emit('input', this.uploadedFiles)
        this.$emit('finish', this.uploadedFiles)

        this.uploadedFiles = []
        this.previewUrls = []
        
        this.dialog = false
      }
    }
  }
</script>
  
<style scoped>
.delete {
  float: right;
  margin: 0;
}

.pictureBlock {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.picture {
  max-width:200px;
  margin-right: 10px;
  max-height:125px;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.round-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  background-color: var(--v-primary-base);
  border-radius: 50%;
  opacity: 0.9;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.trash-can {
  font-size: 24px;
  color: white !important;
  position: relative;
  z-index: 1;
}

.drag_and_drop {
  margin-top:15px;
  border-top: 2px dashed #E0E0E0;
  height: 125px;
}

.first_title {
  margin-top:15px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.0025em;
  text-align: center;
  color: #142C4666;
}

.second_title {
  margin-top: 1rem;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.0089em;
  text-align: center;
  color: #142C46;
  text-transform: uppercase;
}

.icon-container {
  position: absolute;
  top: -5px;
  right: 5px;
}
</style>
  