<template>
  <div>
    <v-card>
      <v-card-title>
        {{ t('title') }}
      </v-card-title>
      <v-card-text>
        <div class="disabled-input">
          <label>{{ t('employee') }}</label>
          <span>{{ employeeName }}</span>
        </div>
        <div class="disabled-input">
          <label>{{ t('week') }}</label>
          <span>{{ formatDateWeek(submissionDate) }}</span>
        </div>
        <v-text-field
            v-model="message"
            :label="t('notification-message')"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
          {{ t('cancel') }}
        </v-btn>
        <v-btn
            color="primary"
            elevation="0"
            class="delete-task"
            @click="confirm"
        >
          <v-icon>mdi-check</v-icon>
          {{ t('confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>


<script>

export default {
  name: "TimesheetApprovalRequestResubmission",
  emits: ['confirm', 'close'],
  props: {
    employeeName: {
      type: String,
      required: true,
    },
    submissionDate: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      message: "Die Zeiten wurden korrigiert. Bitte neu einreichen.",
    };
  },
  computed: {},
  mounted() {
  },
  methods: {
    t: function (key) {
      return this.$t('timesheet-approval.request-resubmission.' + key);
    },
    cancel() {
      this.$emit('close');
    },
    async confirm() {
      this.$emit('confirm', {message: this.message});
    },
  }
}
</script>

<style scoped lang="scss">
.disabled-input {
  margin-bottom: 24px;
  font-size: 16px;

  label {
    line-height: 24px;
    display: block;
    color: rgba(0, 0, 0, 0.54);
  }

  span {
    display: block;

    &.small {
      font-size: 14px;
    }
  }
}

</style>