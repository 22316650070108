<template>
  <div>
    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="settings">

      <loading :dataLoaded="dataLoaded"></loading>

      <v-form ref="form">

        <div v-if="dataLoaded">

          <v-row class="settings-wrapper">

            <v-card elevation="0">
              <v-card-title>{{ t('email-recipient') }}</v-card-title>
              <v-card-text>

                <v-switch
                  v-model="data.shop_email_enabled.value"
                  inset
                  :label="data.shop_email_enabled.value ? t('yes') : t('no')"
                ></v-switch>

                <br>

                <v-text-field
                  label="Email"
                  v-model="data.shop_email.value"
                  filled
                  :rules="[
                    v => (data.shop_email_enabled.value && !data.shop_email.value) ? $t('validation.required.default') : true
							    ]"
                ></v-text-field>

              </v-card-text>
            </v-card>

          </v-row>

        </div>

      </v-form>

    </v-container>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-btn
                color="primary"
                text
                class="icon-left"
                @click="clear()"
            >
              <v-icon>mdi-reload</v-icon>
              {{ t('reset') }}
            </v-btn>
            <v-spacer></v-spacer>
            <app-dialog-confirm-save
                :validateForm="$refs.form"
                :processing="processing"
                @confirmed="event => update(event)"
            />
          </v-row>
        </v-container>
      </div>
    </transition>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'
import loading from '@/components/layouts/Loading'
import AppDialogConfirmSave from '@/components/vuetify/AppDialogConfirmSave'

export default {
  name: 'SettingsShop',
  components: {
    toolbar,
    loading,
    AppDialogConfirmSave
  },
  data() {
    return {
      data: [],
      settings: {
        shop_email: {},
        shop_email_enabled: {}
      },
      dataLoaded: false,
      processing: false
    }
  },
  created() {
    this.getData()
  },
  methods: {
    t: function (key) {
      return this.$t('shop.settings.' + key)
    },
    reload() {
      this.$nextTick(function () {
        this.getData()
      }.bind(this))
    },
    update (isConfirmed) {
      if (!isConfirmed) {
        this.clear()
      }
      this.processing = true
      for (var item in this.data) {
        HTTP.patch('settings/' + this.data[item].id, this.data[item]).then(function () {
          this.$root.infoNotification.showMessage(this.$t('request-notifications.saving-success'));
        }.bind(this)).catch(function () {
          this.$root.infoNotification.showMessage(this.$t('request-notifications.saving-error'));
        }.bind(this)).finally(function () {
          this.processing = false
        }.bind(this))
      }
    },
    clear() {
      this.dataLoaded = false
      this.data = []
      this.getData()
    },
    getData() {
      HTTP.get('settings?key=shop').then(function (response) {
        this.settings.shop_email = response.data.filter(item => item.key === 'shop_email').shift()
        this.settings.shop_email_enabled = response.data.filter(item => item.key === 'shop_email_enabled').shift()
        this.data = Object.assign({}, this.settings)
        this.data.shop_email_enabled.value = this.data.shop_email_enabled.value === 'true'
        this.dataLoaded = true
      }.bind(this)).catch(function () {
        this.$root.infoNotification.showMessage(this.$t('request-notifications.loading-error'));
      }.bind(this))
    }
  }
}
</script>

<style scoped lang="scss">
.settings-wrapper {
  justify-content: space-between;

  .v-card {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
}
</style>
