<template>
    <div class="has-hero-container">
        <div>	
            <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>
        
            <div class="sp-hero sp-bg-light-blue">
      
                <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">
              
                    <app-filter-apply-button
                      v-if="filterStore.hasChanges"
                      @click="applyFilter(); options.page = 1"
                    ></app-filter-apply-button>
                
                    <v-slide-group show-arrows>

                        <v-slide-item>
					
                            <div class="filter-search-list">
                              <div class="filter-item">
                                <div class="filter-item-wrapper">
                                  <app-filter-search-field
                                    v-model="filterStore.filter.searchTerm"
                                    :filterLabel="t('filter.name-search')"
                                    :placeholder="t('filter.name-search-placeholder')"
                                    clearable
                                    append-icon="mdi-magnify"									
                                  ></app-filter-search-field>
                                </div>
                              </div>
                            </div>
                                      
                        </v-slide-item>

                        <v-slide-item>
					
                            <div class="filter-search-list">
                              <div class="filter-item">
                                <div class="filter-item-wrapper">
                                  <shop-categories-filter v-model="filterStore.filter.categories"></shop-categories-filter>
                                </div>
                              </div>
                            </div>
                                      
                        </v-slide-item>

                        <v-slide-item>
					
                            <div class="filter-search-list">
                              <div class="filter-item">
                                <div class="filter-item-wrapper">
                                  <shop-status-filter v-model="filterStore.filter.status"></shop-status-filter>
                                </div>
                              </div>
                            </div>
                                      
                        </v-slide-item>

                    </v-slide-group>

                </v-container>

             </div>

             <v-container fluid>  

                <v-row class="data-table-toolbar">
                  <v-spacer></v-spacer>
                  <v-btn
                    elevation="0"
                    color="primary"
                    class="icon-left"
                    :to="{ name: 'ShopProductsCreate' }"
                  >
                    <v-icon>mdi-plus</v-icon>
                    {{ t('create') }}
                  </v-btn>
                </v-row>
        
                <v-row v-if="dataLoaded && products.data.length === 0">
                  <div class="empty-datatable">
                    <v-icon>mdi-alert-circle-outline</v-icon>
                    {{ t('no-data') }}
                  </div>
                </v-row>
        
                <v-row v-else>

                  <v-data-table
                    :headers="headers"
                    :items="products.data"
                    :options.sync="options"
                    :server-items-length="dataTotal"
                    :loading="loading"
                    class="elevation-0"
                    :multi-sort="false"
                    :must-sort="true"
                    :sort-by.sync="filterStore.table.sortBy"
                    :sort-desc.sync="filterStore.table.sortDesc"
                    :page.sync="filterStore.table.currentPage"
                    :items-per-page.sync="filterStore.table.itemsPerPage"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-arrow-collapse-left',
                      lastIcon: 'mdi-arrow-collapse-right',
                      prevIcon: 'mdi-minus',
                      nextIcon: 'mdi-plus',
                      'items-per-page-options': [25, 50, 100, 200],
                      pageText: '{0}-{1} von {2}',
                      'items-per-page-text': t('table.items-per-page')
                    }"
                  >
                    <template v-slot:[`item.thumbnail`]="{ item }">
                      <div>
                        <image-wrapper
                          :link="getPreviewPictureLink(item.id)"
                          :can-remove="false"
                          :max-width="100"
                          :max-height="100" />
                      </div>
                    </template>

                    <template v-slot:[`item.title`]="{ item }">
                      <div class="ellipsis-wrapper">
                        <span class="ellipsis ellipsis-title" v-if="item.title"><b>{{ item.title }}</b></span>
                        <span class="ellipsis preview-text">{{ item.description }}</span>
                      </div>
                    </template>

                    <template v-slot:[`item.categories`]="{ item }">
                        {{ getCatgeoryNames(item.categories) }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      <span class="status-chip active" v-if="item.status === 'active'">            
                          <v-icon left>
                              mdi-eye-outline
                          </v-icon>
                          {{ t('table.status.active') }}
                      </span>
                      <span class="status-chip inactive" v-if="item.status === 'inactive'">
                        <v-icon left>
                            mdi-eye-off-outline
                        </v-icon>
                        {{ t('table.status.inactive') }}
                      </span>
                      <span class="status-chip inactive" v-if="item.status === 'archived'">
                        <v-icon left>
                            mdi-eye-off-outline
                        </v-icon>
                        {{ t('table.status.archived') }}
                      </span>
                    </template>

                    <template v-slot:[`item.edit`]="{ item }">
                      <router-link :to="{ path: '/shop-products/' + item.id }" class="edit-row-table">
                        <v-icon
                            small
                            class="edit-row-button"
                        >
                          edit
                        </v-icon>
                      </router-link>
                    </template>
                  </v-data-table>

                </v-row>

            </v-container>
      
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation.vue'
import loading from '@/components/layouts/Loading.vue'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import ShopCategoriesFilter from '@/components/filter-components/ShopCategoriesFilter'
import ShopStatusFilter from '@/components/filter-components/ShopStatusFilter'
import ImageWrapper from '@/components/ImageWrapper'
import { useShopFilterStore } from '@/stores/ShopFilterStore'

export default {
    name: 'ShopProductsIndex',
    components: {
        toolbar,
        loading,
        AppFilterApplyButton,
        AppFilterSearchField,
        ShopCategoriesFilter,
        ShopStatusFilter,
        ImageWrapper
    },
    setup() {
        const filterStore = useShopFilterStore()
        return { filterStore }
    },
    data () {
        return {
            loading: false,
            products: {
                page: 1,
                current_page: 1,
                last_page: 1,
                total: null,
                data: []
            },
            dataLoaded: false,
            error: false,
            dataTotal: 0,
            options: {},
            pictureCache: {}
        }
    },
    created() {
        this.applyFilter(false)
    },
    computed: {
      headers() {
        return [
          {
            text: this.t('table.headers.thumbnail'),
            align: 'start',
            sortable: false,
            value: 'thumbnail',
            width: 150
          },
          {
            text: this.t('table.headers.title'),
            align: 'start',
            sortable: false,
            value: 'title',
            cellClass: 'text-middle'
          },
          {
            text: this.t('table.headers.category'),
            align: 'start',
            sortable: false,
            value: 'categories',
            cellClass: 'text-middle'
          },
          {
            text: this.t('table.headers.status'),
            align: 'start',
            sortable: false,
            value: 'status',
            cellClass: 'text-middle'
          },
          {
            text: this.t('table.headers.edit'),
            value: 'edit',
            sortable: false,
            align: 'center',
            width: 100,
            cellClass: 'text-middle'
          }
        ]
      }
    },
    watch: {
      options: {
        handler() {
          this.getProducts()
        },
        deep: true,
      }
    },
    methods: {
      t: function (key) {
          return this.$t('shop.products.list.' + key)
      },
      reload () {
          this.$nextTick(function () {
              this.getProducts()
          }.bind(this))
      },
      applyFilter(doReload = true) {
          this.filterStore.filterApplied()
          if (doReload) {
              this.reload()
          }
      },
      prepareUrl() {
          let queryStringParts = []
          if (this.filterStore.filter.searchTerm) {
              queryStringParts.push('q=' + this.filterStore.filter.searchTerm)
          }
          if (this.filterStore.filter.categories.length > 0) {
              queryStringParts.push('c=' + this.filterStore.filter.categories.join(','))
          }
          if (this.filterStore.filter.status.length > 0) {
              queryStringParts.push('st=' + this.filterStore.filter.status.join(','))
          }
          return '?' + queryStringParts.join('&')
      },
      getProducts () {
          this.loading = true

          let url = this.prepareUrl()

          HTTP.get('shop_products' + url, { params: this.options })
          .then(function (response) {
              this.products.data = response.data.data.data
              this.products.last_page = response.data.data.last_page
              this.products.current_page = response.data.data.current_page
              this.products.total = response.data.data.total
              this.dataTotal = response.data.data.total
              this.error = false
              this.dataLoaded = true
              this.loading = false
          }.bind(this)).catch(function (error) {
              if (!error.status) {
                  this.error = true
                  this.dataLoaded = true
                  this.loading = false
              }
          }.bind(this))
      },
      getPreviewPictureLink(id) {
          if (this.pictureCache[id]) {
            // If it's in the cache, return the cached value
            return this.pictureCache[id]
          } else {
            // If it's not in the cache, generate the picture link
            let cacheBuster = new Date().getTime()
            let val = '/shop_products/image/' + id
            let selectedPicture = `${val}?${cacheBuster}`

            // Save the picture link in the global array
            this.pictureCache[id] = selectedPicture

            // Return the generated picture link
            return selectedPicture
          }
      },
      getCatgeoryNames(categories) {
        let names =  categories.map((cat) => cat.name)
        return names.join(', ')
      }
    }
}
</script>

<style scoped lang="scss">
.sp-hero .filter-search-list {
  width: unset;
}

.ellipsis-wrapper {
  position: relative;
  height: 100%;
  min-height: 84px;
  min-width: 300px;

  .ellipsis {
    position: absolute;
    padding-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    &.ellipsis-title {
      top: 20px;
    }

    &.preview-text {
      top: 42px;
    }
  }
}

.preview-text {
  color: #000000DE;
  font-size: 14px;
}

.status-chip {
  display: inline-block; 
  font-size: 14px;
  line-height: 20px;
  padding: 1px 8px;
  border-radius: 8px;

  &.active {
    color: var(--v-success-base);
    background: rgba(112, 210, 115, 0.19);

    .v-icon {
      color: var(--v-success-base);
    }
  }

  &.inactive {
    color: var(--v-warning-base);
    background: rgba(254, 197, 125, 0.24);

    .v-icon {
      color: var(--v-warning-base);
    }
  }
}
</style>