<template>
  <div>
    <v-text-field
        :label="t('address_line_1')"
        v-model="value.address_line_1"
        data-cy="address.address_line_1"
        filled
    />
    <v-text-field
        :label="t('address_line_2')"
        v-model="value.address_line_2"
        data-cy="address.address_line_2"
        filled
    />
    <v-text-field
        :label="t('zip_code')"
        v-model="value.zip_code"
        data-cy="address.zip_code"
        filled
    />
    <v-text-field
        :label="t('city')"
        v-model="value.place"
        data-cy="address.place"
        filled
    />
  </div>
</template>

<script>
import SalutationSelect from '@/components/SalutationSelect.vue'

export default {
  name: "CustomerEditAddress",
  components: { SalutationSelect },
  props: {
    value: Object,
  },
  data() {
    return {}
  },
  methods: {
    t: function (key) {
      return this.$t('customers.edit.address.' + key);
    },
  }
}
</script>
