<template>
    <div>
        <BasicCreateEditContainer
            :title="t('title')"
            ref="editor"
            object-type="Product"
            :object-name="variant.name"
            :data-loaded="dataLoaded"
            @cancel="close"
            :save-action="saveProduct"
            @saved="onSaved"
            :allow-delete="false"
            :has-changes="hasChanges"
        >
        <template v-slot:content> 
            <v-card elevation="0">
                <v-card-title>{{ t('card-title') }}</v-card-title>
                <v-card-text>

                    <v-text-field
                        class="sp-mar-top-3"
                        :label="t('details.name')"
                        v-model="variant.name"
                        filled
                        :readonly="true"
                    ></v-text-field>

                    <v-text-field
                        class="sp-mar-top-3"
                        :label="t('details.sku')"
                        v-model="variant.sku"
                        filled
                        :readonly="true"
                    />

                    <v-text-field
                        v-if="dataLoaded && variant.product.has_variants"
                        class="sp-mar-top-3"
                        :label="t('details.variant')"
                        v-model="variantName"
                        filled
                        :readonly="true"
                    />

                    <div class="inventory-change-wrapper">
                        <div class="inventory-stock">
                            <div class="inventory-stock-label">{{ t('details.stock') }}</div>
                            <div class="inventory-stock-value">{{ variant.inventory_sum }}</div>
                        </div>

                        <v-text-field
                            class="inventory-stock-change"
                            :label="t('details.inventory-change')"
                            type="number"
                            v-model.number="inventory_change.amount"
                            filled
                            hide-details="auto"
                            :rules="[
                                () => !isNaN(parseInt(inventory_change.amount)) || t('validation.required.inventory')
                            ]"
                        ></v-text-field>

                        <v-text-field
                            class="inventory-stock-change-comment"
                            :label="t('details.inventory-change-comment')"
                            v-model.number="inventory_change.comment"
                            filled
                            hide-details="auto"
                        ></v-text-field>
                    </div>

                </v-card-text>
            </v-card>

            <v-card v-if="dataLoaded && variant.product.has_variants" elevation="0" class="sp-mar-top-3">
                <v-card-title>{{ t('variants-list.card-title') }}</v-card-title>
                <v-card-text>

                    <v-data-table
                        :headers="variantsHeaders"
                        :items="variants"
                        :loading="variantsLoading"
                        class="simple-data-table elevation-0 mb-0"
                        :items-per-page="-1"
                        hide-default-footer
                    >
                        <template v-slot:[`item.link`]="{ item }">
                            <router-link :to="{ name: 'ShopInventoryDetails', params: { id: item.id } }" class="edit-row-table">
                                <v-icon
                                    small
                                    class="edit-row-button"
                                >
                                    mdi-chevron-right
                                </v-icon>
                            </router-link>
                        </template>
                    </v-data-table>

                </v-card-text>
            </v-card>
        </template>

        <template v-slot:meta>

            <v-card elevation="0">
                <v-card-title>{{ t('history.card-title') }}</v-card-title>
                <v-card-text>

                    <div class="history-wrapper history-stock">
                        <div>{{ t('history.in-stock') }}</div>
                        <div>{{ variant.inventory_sum }}</div>
                    </div>

                    <div class="history-list">
                        <div v-for="(vi, index) in inventoryHistoryTruncated" :key="index" class="history-wrapper history-list-item">
                            <div class="transaction" :class="[{ 'positiv': vi.amount >= 0 }]">
                                <span v-if="vi.amount >= 0">+</span>
                                <span v-else>-</span>
                                {{ vi.amount | absValue }}
                            </div>
                            <div class="count">{{ inventoryHistory[index] }}</div>
                            <div class="date">{{ vi.created_at | momentDate }}</div>
                            <div class="comment">
                                <v-tooltip v-if="vi.comment && vi.comment !== ''" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="info">mdi-information-outline</v-icon>
                                    </template>
                                    <span>{{ vi.comment }}</span>
                                </v-tooltip>
                            </div>    
                        </div>
                    </div>

                </v-card-text>
            </v-card>

        </template>

        <template v-slot:secondary-action-buttons>
            <v-btn
                color="primary"
                elevation="0"
                class="icon-left"
                outlined
                @click="navigateToProduct()"
            >
            <v-icon>mdi-cog</v-icon>
                {{ t('edit-product') }}
            </v-btn>
        </template>

      </BasicCreateEditContainer>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import moment from 'moment'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'

export default {
    name: 'ShopInventoryDetails',
    components: {
        BasicCreateEditContainer
    },
    data () {
        return {
            variant: {},
            variants: [],
            dataOriginal: null,
            dataLoaded: false,
            inventory_change: {
                amount: '',
                comment: ''
            },
            variantsLoading: false,
            inventoryHistory: []
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.variant.id = to.params.id
        if (this.variant.id) {
            this.dataLoaded = false
            this.variants = []
            this.inventoryHistory = []
            this.getVariant()
            next()
        }
    },
    filters: {
        momentDate: function (date) {
            return moment(date).format('DD.MM.YYYY');
        },
        absValue: function (value) {
            return Math.abs(value)
        }
    },
    created() {
        this.variant.id = this.$route.params.id
        if (this.variant.id) {
            this.getVariant()
        }
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.inventory_change) !== JSON.stringify(this.dataOriginal)
        },
        variantName() {
            return this.dataLoaded ? this.variant.product_variant_values.map((pvv) => pvv.value).join(' | ') : ''
        },
        variantsHeaders() {
            return [
                {
                    text: this.t('table.headers.sku'),
                    align: 'left',
                    sortable: false,
                    value: 'shop_product_variants.sku',
                    width: 200
                },
                {
                    text: this.t('table.headers.variant_name'),
                    align: 'left',
                    sortable: false,
                    value: 'combined_name'
                },            
                {
                    text: this.t('table.headers.inventory'),
                    align: 'middle',
                    sortable: false,
                    value: 'amount',
                    width: 180
                },
                {
                    text: '',
                    value: 'link',
                    align: 'center',
                    sortable: false,
                    width: 50
                }
            ]
        },
        inventoryHistoryTruncated() {
            return this.dataLoaded ? this.variant.inventory.slice(0, 20) : []
        }
    },
    methods: {
        t: function (key) {
            return this.$t('shop.inventory.edit.' + key)
        },
        getVariant() {
            HTTP.get('shop_inventories/' + this.variant.id).then(function (response) {
                this.variant = response.data
                this.dataOriginal = JSON.parse(JSON.stringify(this.inventory_change))
                this.dataLoaded = true
                this.calculateHistory()
                this.getVariants()
            }.bind(this)).catch(function (error) {
                console.log(error)
                this.dataLoaded = true
            }.bind(this))
        },
        getVariants() {
            this.variantsLoading = true
            HTTP.get('shop_inventories?pid=' + this.variant.product.id + '&vid=' + this.variant.id).then(function (response) {
                this.variants = response.data.data
                this.variantsLoading = false
            }.bind(this)).catch(function (error) {
                console.log(error)
                this.variantsLoading = false
            }.bind(this))   
        },
        saveProduct() {
            this.inventory_change.variant_id = this.variant.id
            return HTTP.post('shop_inventories', this.inventory_change)
        },
        onSaved() {
            this.dataOriginal = null;
            this.reload()
        },
        calculateHistory() {
            let inventory_sum_copy = this.variant.inventory_sum
            this.variant.inventory.forEach((i, index) => {
                if (index > 0) {
                    let adjustedSum = inventory_sum_copy - this.variant.inventory[index - 1].amount
                    this.inventoryHistory.push(adjustedSum)
                    inventory_sum_copy = adjustedSum
                } else {
                    this.inventoryHistory.push(inventory_sum_copy)
                }
            })
        },
        navigateToProduct() {
            this.$router.push({ name: 'ShopProductsEdit', params: { id: this.variant.product.id } })
        },
        reload() {
            this.$router.go()
        },
        close() {
           this.$router.push({ name: 'ShopInventoryIndex' })
        }
    }
}
</script>

<style scoped lang="scss">
.history-wrapper {
    display: flex;

    &.history-stock {
        justify-content: space-between;
        color: black;
        font-size: 16px;

        & > div:last-child {
            font-weight: bold;
        }
    }

    &.history-list-item {
        padding: 10px;
        margin-top: 10px;
        background: #F5F5F5;
        border-radius: 4px;
        font-weight: bold;

        .transaction {
            width: 80px;
            color: #FB8C00;

            &.positiv {
                color: #49894B;
            }         
        }

        .count {
            width: 80px;
            color: black;
        }

        .date {
            width: calc(100% - 210px);
            text-align: right;
        }

        .comment {
            width: 50px;
            position: relative;

            i {
                position: absolute;
                right: 0;
                top: -2px;
            }
        }
    }
}
.inventory-change-wrapper {
    display: flex;
    gap: 16px;

    .inventory-stock {
        padding: 0 0 0 10px;

        .inventory-stock-label {
            font-size: 12px;
            padding-top: 3px;
        }

        .inventory-stock-value {
            color: black;
            font-size: 16px;
        }
    }

    .inventory-stock-change {
        max-width: 200px;
    }
}
</style>