import MenuIndex from '@/views/MenuIndex'
import MenuEdit from '@/views/MenuEdit'
import MenuCreate from '@/views/MenuCreate'

const menuRoutes = [
  {
    path: '/settings/menu',
    name: 'Menu',
    component: MenuIndex,
    meta: {
      accessConfig:  {
          requiresAuth: true
      }
    }
  },
  {
    path: '/settings/menu/create/:type',
    name: 'MenuCreate',
    component: MenuCreate,
    meta: {
      accessConfig:  {
          requiresAuth: true
      }
    }
  },
  {
    path: '/settings/menu/:id?',
    name: 'MenuEdit',
    component: MenuEdit,
    meta: {
      accessConfig:  {
          requiresAuth: true
      }
    } 
  }
]

export default menuRoutes
