<template>
    <div>
      <BasicEditContainer
          :title="t('edit.title')"
          ref="editor"
          :object-type="$t('settings.permission-groups.data.object-type')"
          :data-loaded="dataLoaded"
          @cancel="cancel"
          :save-action="saveItem"
          @saved="onSaved"
          :allowDelete="true"
          :delete-action="deleteItem"
          @deleted="onDeleted"
          :has-changes="hasChanges">
        <template v-slot:default>
          <v-card
              elevation="0"
          >
            <v-card-title>{{ t('details.title') }}</v-card-title>
            <v-card-text>
                <v-text-field
                    :label="t('details.title')"
                    v-model="data.title"
                    counter="120"
                    filled
                    :rules="[
                        () => !!data.title || $t('validation.required.default'),
                        () => (data.title && data.title.length <= 120) || $t('validation.length.default')
                    ]"
                ></v-text-field>
            </v-card-text>
          </v-card>
        </template>
      </BasicEditContainer>
    </div>
  </template>
  
  <script>
  import {HTTP} from './../auth'
  import loading from '../components/layouts/Loading.vue'
  import toolbar from '../components/layouts/Navigation.vue'
  import BasicEditContainer from "@/components/inputs/BasicEditContainer";
  import {usePermissionStore} from "@/stores/PermissionStore";
  
  export default {
    name: 'SettingsNotificationRatingEdit',
    components: {
      BasicEditContainer,
      loading,
      toolbar
    },
    data() {
      return {
        dataLoaded: false,
        data: {
          id: null,
          title: ''
        },
        dataOriginal: null,
        indexRouteName: 'SettingsNotificationRatingIndex',
      }
    },
    props: {},
    created() {
      this.itemId = this.$route.params.id
      this.getItem()
    },
    mounted() {
      this.getItem()
    },
    computed: {
      hasChanges() {
        if (!this.dataOriginal) {
          return false;
        }
        return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
      },
    },
    methods: {
      t: function (key) {
        return this.$t('settings.notification-rating.detail.' + key);
      },
      getItem() {
        HTTP.get('notification_ratings/' + this.itemId).then(function (response) {
          this.data = response.data
          this.dataOriginal = JSON.parse(JSON.stringify(this.data))
          this.dataLoaded = true
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
            this.dataLoaded = false
          }
        }.bind(this))
      },
      setRole(role, active) {
        let newList = new Set([...this.data.roles])
        if (active) {
          newList.add(role)
        } else {
          newList.delete(role)
        }
        this.data.roles = Array.from(newList);
      },
      saveItem() {
        return HTTP.patch('notification_ratings/' + this.itemId, this.data)
      },
      deleteItem() {
        return HTTP.delete('notification_ratings/' + this.itemId)
      },
      onDeleted() {
        this.dataOriginal = null;
        this.close();
      },
      async onSaved() {
        this.dataOriginal = null;
        await this.close();
      },
      cancel() {
        this.close();
      },
      async close() {
        await HTTP.get('security/user/permissions').then(function (response) {
          usePermissionStore().permissions = response.data
        }.bind(this))
  
        await this.$router.push({name: this.indexRouteName})
      }
    }
  }
  </script>
  