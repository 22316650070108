<template>
  <div>
    <v-select
      class="order-status-filter"
      v-model="selectedFilterStatus"
      :label="$t('components.order-status-filter.label')"
      :items="filterStatus"
      item-text="name"
      item-value="slug"
      :multiple="multiple"
      chips
      data-cy="status"
      :rules="[
        () => !!selectedFilterStatus || $t('validation.required.status'),
      ]"
    ></v-select>
  </div>  
</template>
    
<script>
export default {
  name: "OrderStatusFilter",
  model: {
      prop: 'model',
      event: 'input'
  },
  props: {
    dataCy: {
      default: ''
    },
    model: {
      type: [String, Array],
      default: () => [null]       
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedFilterStatus: this.model,
      filterStatus: [       
        {
          name: '',
          slug: 'new'
        },
        {
          name: '',
          slug: 'processing'
        },
        {
          name: '',
          slug: 'ready'
        },
        {
          name: '',
          slug: 'delivered'
        },
        {
          name: '',
          slug: 'completed'
        },
        {
          name: '',
          slug: 'cancelled'
        }
      ],
    }
  },
  watch: {
      selectedFilterStatus: function (val) {
          this.$emit('input', val)
      },
      model: function(val) {
          this.selectedFilterStatus = val
      }
  },
  created() {
    let me = this;
    this.filterStatus.forEach(function (value) {
      value.name = me.$t('shop.orders.data.status.' + value.slug)
    });
  }
}
</script>
    
<style scoped lang="scss">
.order-status-filter {

  ::v-deep .v-select__selections {

    input {
      width: 0;
    }
  }
}
</style>
    