<template>
  <div>
    <BasicEditContainer
        ref="editor"
        :data-loaded="dataLoaded"
        v-if="dataLoaded"
        :title="t('edit.title')"
        @cancel="cancel"
        :save-action="saveItem"
        @saved="close"
        :delete-action="deleteItem"
        @deleted="close"
        :object-type="$t('absence.data.object-type')"
        :object-name="item.name"
        :has-changes="hasChanges">
      <v-card
          elevation="0"
      >
        <v-card-title>{{ t('details.group-title') }}</v-card-title>
        <v-card-text>

          <v-text-field
              :label="t('details.name')"
              v-model="item.name"
              counter="120"
              filled
              :rules="[
								() => !!item.name || $t('validation.required.default'),
								() => (item.name && item.name.length <= 120) || $t('validation.length.default')
							]"
          ></v-text-field>

        </v-card-text>
      </v-card>
    </BasicEditContainer>
  </div>
</template>
<script>
import { HTTP } from './../auth'
import DeleteButton from "@/components/inputs/DeleteButton";
import BasicEditContainer from "@/components/inputs/BasicEditContainer";

export default {
  components: {
    BasicEditContainer,
    DeleteButton,
  },
  data() {
    return {
      item: null,
      itemOriginal: null,
      indexRouteName: 'AbsencesReasonsIndex',
      dataLoaded: false,
    }
  },
  created() {
    this.itemId = this.$route.params.id
    this.getItem()
  },
  mounted() {
  },
  computed:{
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('absences.reasons.detail.' + key);
    },
    getItem() {
      HTTP.get('absences/reasons/' + this.itemId).then(function (response) {
        this.item = response.data
        this.itemOriginal = JSON.parse(JSON.stringify(this.item));
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    saveItem() {
      return HTTP.patch('absences/reasons/' + this.itemId, this.item);
    },
    deleteItem() {
      return HTTP.delete('absences/reasons/' + this.itemId, this.item);
    },
    async onSaved() {
      this.dataOriginal = null;
      this.close();
    },
    cancel() {
      this.close();
    },
    close() {
      this.$router.push({ name: this.indexRouteName })
    }
  },
}
</script>

<style scoped lang="scss">

</style>

