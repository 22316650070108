const themesRep = {
  themes: {
    light: {
      buttons: '#F28C00',
      primary: '#EB4300',
      secondary: '#FFD8C9',
      success: '#4CAF50',
      warning: '#D30033',
      error: '#D30033'
    }
  }
}

export default themesRep
