import ChatGroupsIndex from "@/views/ChatGroupsIndex";
import ChatGroupCreate from "@/views/ChatGroupCreate";
import ChatGroupEdit from "@/views/ChatGroupEdit";
import ChatMessages from "@/views/ChatMessages";
import ChatSettings from "../../views/ChatSettings.vue";

const chatRoutes = [
  {
    path: '/chat-groups',
    name: 'ChatGroupIndex',
    component: ChatGroupsIndex,
    meta: {
      accessConfig: {
        moduleName: ['chat'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/chat-groups/create',
    name: 'ChatGroupCreate',
    component: ChatGroupCreate,
    meta: {
      accessConfig: {
        moduleName: ['chat'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/chat-groups/:id?',
    name: 'ChatGroupEdit',
    component: ChatGroupEdit,
    meta: {
      accessConfig: {
        moduleName: ['chat'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/chat-messages/:id?',
    name: 'ChatMessages',
    component: ChatMessages,
    meta: {
      accessConfig: {
        moduleName: ['chat'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/settings/chat',
    name: 'ChatSettings',
    component: ChatSettings,
    meta: {
      accessConfig: {
        moduleName: ['chat'],
        requiresAuth: true
      }
    }
  },
]

export default chatRoutes
