<template>
  <li :class="[isFolder ? 'folder has-subfolders' : 'folder', isFocused ? 'focused' : '']">

    <label
        :class="{'open': open}"
        @click="selectFolder"
    >
      <v-icon v-if="!open">mdi-folder</v-icon>
      <v-icon v-if="open">mdi-folder-open</v-icon>
      <p>{{ item.name }}</p>
    </label>
    <ul v-show="open" v-if="isFolder" :class="{'open': open}">
      <documents-edit-folder-item
          v-for="(entry, index) in item.children"
          :key="index"
          v-model="item.children[index]"/>
    </ul>
  </li>
</template>

<script>
import {EventBus} from "../event-bus";
import Vue from "vue";

export default {
  name: "DocumentsEditFolderItem",
  props: ['value'],
  data() {
    return {
      item: this.value,
      open: false,
      isFocused: false,
    }
  },
  async mounted() {
    EventBus.$on('reset-focused-item', function (path) {
      this.isFocused = false
    }.bind(this))
  },
  computed: {
    isFolder: function () {
      return this.item.children && this.item.children.length;
    }
  },
  methods: {
    selectFolder() {
      if (this.isFolder) {
        this.open = !this.open;
      }
      EventBus.$emit('set-moveToDir', this.item)
      EventBus.$emit('reset-focused-item', this.item)
      this.isFocused = true
    },
  }
}
</script>

<style scoped>

</style>