<template>
    <div>
        <BasicCreateEditContainer
            :title="t('title')"
            ref="editor"
            object-type="QCNotification"
            :data-loaded="dataLoaded"
            @cancel="close"
            :save-action="saveQCNotification"
            @saved="onSaved"
            :allow-delete="mode == 'edit'"
            :delete-action="deleteQCNotification"
            @deleted="onDeleted"
            :has-changes="hasChanges"
        >
            <template v-slot:content>

                <v-card elevation="0">
                    <v-card-title>{{ t('details-title') }}</v-card-title>
                    <v-card-text>
                        <content-customer
                            :label="t('choose_client')"
                            v-model="data.customer_id"
                            data-cy="customer_id"
                            :get-search-url="getCustomerSearchUrl"
                            :rules="[
                                () => !!data.customer_id || $t('validation.required.client')
                            ]"
                        />
                        <v-text-field
                            class="mt-1 mb-0 pb-0"
                            :label="t('contact_person')"
                            v-model="data.contact_person"
                            data-cy="contact_person"
                            hide-details="auto"
                            filled
                        ></v-text-field>

                        <contact-type-filter 
                            class="mt-3"
                            v-model="data.contact_type_id"
                            data-cy="contact_type_id"
                        ></contact-type-filter>
                        
                        <content-filter-user
                            :preselected="data.project_leader_id"
                            class="mt-3"
                            v-model="data.project_leader_id"
                            data-cy="customproject_leader_ider_id"
                            :rules="[
                                () => !!data.project_leader_id || $t('validation.required.project_leader')
                            ]"
                        />
                        
                        <q-c-notification-room-filter class="mt-1" v-model="data.room_id" data-cy="room_id"/>

                        <q-c-subject-area-filter v-model="data.subject_area_id" data-cy="subject_area_id"/>
                        
                        <v-textarea
                            :label="t('notes')"
                            class="no-validation mt-3"
                            v-model="data.notes"
                            data-cy="notes"
                            filled
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('picture-title') }}</v-card-title>
                    <v-card-text>
                        <div v-if="mode === 'edit'">
                            <img :src="data.thumbnail_url">
                        </div>
                        <div v-else>
                            <picture-upload
                                :show-preview="true"
                                v-model="data.picture"
                                aspectMode="quadratic"/>
                        </div>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('immediate_measures-title') }}</v-card-title>
                    <v-card-text>
                        <div class="d-flex justify-space-between">
                            <div>
                                <div class="custom-label ">{{ t('immediate_measures-enabled') }}</div>
                                <v-switch
                                    class="mt-2"
                                    v-model="data.immediate_measures"
                                    data-cy="immediate_measures"
                                    inset
                                    :label="data.immediate_measures ? t('yes') : t('no')"
                                ></v-switch>
                            </div>
                            <div v-show="data.immediate_measures" class="text-right">
                                <div class="custom-label ">{{ t('done') }}</div>
                                <v-switch
                                    class="mt-2"
                                    v-model="data.immediate_measures_done"
                                    data-cy="immediate_measures_done"
                                    inset
                                    :label="data.immediate_measures_done ? t('yes') : t('no')"
                                ></v-switch>
                            </div>
                        </div>    
                        
                        <v-row v-show="data.immediate_measures" class="mt-2">
                            <v-col>
                                <content-filter-user
                                    :preselected="data.immediate_measures_user_id"
                                    v-model="data.immediate_measures_user_id"
                                    data-cy="immediate_measures_user_id"
                                    :label="t('who')"
                                />
                                
                            </v-col>
                            <v-col>
                                <app-filter-date-picker
                                    :filterLabel="t('due-date')"
                                    v-model="data.immediate_measures_date"
                                    data-cy="immediate_measures_date"
                                    id="dateStart"
                                    :clearable="true"
                                ></app-filter-date-picker>
                            </v-col>
                        </v-row>
                            
                        <v-textarea
                            v-if="data.immediate_measures"
                            :label="t('qm_note')"
                            class="no-validation"
                            v-model="data.immediate_measures_notes"
                            data-cy="immediate_measures_notes"
                            filled
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('preventive_measures-title') }}</v-card-title>
                    <v-card-text>
                        <div class="d-flex justify-space-between">
                            <div>
                                <div class="custom-label ">{{ t('preventive_measures-enabled') }}</div>
                                <v-switch
                                    class="mt-2"
                                    v-model="data.preventive_measures"
                                    data-cy="preventive_measures"
                                    inset
                                    :label="data.preventive_measures ? t('yes') : t('no')"
                                ></v-switch>
                            </div>
                            <div v-show="data.preventive_measures" class="text-right"> 
                                    <div class="custom-label ">{{ t('done') }}</div>
                                    <v-switch
                                        class="mt-2"
                                        v-model="data.preventive_measures_done"
                                        data-cy="preventive_measures_done"
                                        inset
                                        :label="data.preventive_measures_done ? t('yes') : t('no')"
                                    ></v-switch>
                                </div>
                            </div>
                                                    
                            <v-row v-show="data.preventive_measures" class="mt-2">
                                <v-col>
                                    <content-filter-user
                                        :preselected="data.preventive_measures_user_id"
                                        v-model="data.preventive_measures_user_id"
                                        data-cy="preventive_measures_user_id"
                                        :label="t('who')"
                                    />
                                </v-col>
                                <v-col>
                                    <app-filter-date-picker
                                        :filterLabel="t('due-date')"
                                        v-model="data.preventive_measures_date"
                                        data-cy="preventive_measures_date"
                                        id="dateStart"
                                        :clearable="true"
                                    ></app-filter-date-picker>
                                </v-col>
                            </v-row>
                                                
                            <v-textarea
                                v-if="data.preventive_measures"
                                :label="t('qm_note')"
                                class="no-validation"
                                v-model="data.preventive_measures_notes"
                                data-cy="preventive_measures_notes"
                                filled
                            ></v-textarea>
                        </v-card-text>
                    </v-card>

                    <v-card elevation="0" class="sp-mar-top-3">
                        <v-card-title>{{ t('cause-title') }}</v-card-title>
                        <v-card-text>
                            <content-filter-user
                                :preselected="data.cause_user_id"
                                v-model="data.cause_user_id"
                                data-cy="cause_user_id"
                                :label="t('who')"
                            />
                            <v-textarea
                                :label="t('cause_notes')"
                                class="no-validation"
                                v-model="data.cause_notes"
                                data-cy="cause_notes"
                                filled
                            ></v-textarea>
                        </v-card-text>
                    </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('phone-feedback-title') }}</v-card-title>
                    <v-card-text>
                        <div class="d-flex justify-space-between">
                            <div>
                                <div class="custom-label ">{{ t('phone-feedback-enabled') }}</div>
                                <v-switch
                                    class="mt-2"
                                    v-model="data.phone_feedback"
                                    data-cy="phone_feedback"
                                    inset
                                    :label="data.phone_feedback ? t('yes') : t('no')"
                                ></v-switch>
                            </div>
                            <div v-show="data.phone_feedback" class="text-right"> 
                                <div class="custom-label ">{{ t('done') }}</div>
                                <v-switch
                                    class="mt-2"
                                    v-model="data.phone_feedback_done"
                                    data-cy="phone_feedback_done"
                                    inset
                                    :label="data.phone_feedback_done ? t('yes') : t('no')"
                                ></v-switch>
                            </div>
                        </div>

                        <v-row v-if="data.phone_feedback" class="mt-2">
                            <v-col>
                                <content-filter-user
                                    v-if="data.phone_feedback"
                                    :preselected="data.phone_feedback_user_id"
                                    v-model="data.phone_feedback_user_id"
                                    data-cy="phone_feedback_user_id"
                                    :label="t('who')"
                                />
                            </v-col>
                            <v-col>
                                <app-filter-date-picker
                                    :filterLabel="t('due-date')"
                                    v-model="data.phone_feedback_date"
                                    data-cy="phone_feedback_date"
                                    id="dateStart"
                                    :clearable="true"
                                ></app-filter-date-picker>
                            </v-col>
                        </v-row>
                        <v-textarea
                            v-if="data.phone_feedback"
                            :label="t('phone_notes')"
                            class="no-validation"
                            v-model="data.phone_feedback_notes"
                            data-cy="phone_feedback_notes"
                            filled
                        ></v-textarea>
                    </v-card-text>
                </v-card>

                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('aek-title') }}</v-card-title>
                    <v-card-text>
                        <div class="d-flex justify-space-between">
                            <div>
                                <div class="custom-label ">{{ t('aek-enabled') }}</div>
                                <v-switch
                                    class="mt-2"
                                    v-model="data.aek"
                                    data-cy="aek"
                                    inset
                                    :label="data.aek ? t('yes') : t('no')"
                                ></v-switch>
                            </div>
                            <div v-show="data.aek" class="text-right"> 
                                <div class="custom-label ">{{ t('done') }}</div>
                                <v-switch
                                    class="mt-2"
                                    v-model="data.aek_done"
                                    data-cy="aek_done"
                                    inset
                                    :label="data.aek_done ? t('yes') : t('no')"
                                ></v-switch>
                            </div>
                        </div>
                                            
                        <v-row v-show="data.aek" class="mt-2">
                            <v-col>
                                <content-filter-user
                                    :preselected="data.aek_user_id"
                                    v-model="data.aek_user_id"
                                    data-cy="aek_user_id"
                                    :label="t('who')"
                                />
                            </v-col>
                            <v-col>
                                <app-filter-date-picker
                                    :filterLabel="t('due-date')"
                                    v-model="data.aek_date"
                                    data-cy="aek_date"
                                    id="dateStart"
                                    :clearable="true"
                                ></app-filter-date-picker>
                            </v-col>
                        </v-row>
                                                
                        <v-textarea
                            v-if="data.aek"
                            :label="t('qm_note')"
                            class="no-validation"
                            v-model="data.aek_notes"
                            data-cy="aek_notes"
                            filled
                        ></v-textarea>
                    </v-card-text>
                </v-card>
            </template>

            <template v-slot:meta>
                <v-card elevation="0">
                    <v-card-title>{{ t('notification-type') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <q-c-notification-type-filter
                                :filterLabel="t('notification-type-filter')"
                                v-model="data.type_id"
                                data-cy="type_id"
                                id="dateStart"
                                :clearable="true"
                            ></q-c-notification-type-filter>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('notification-rating') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <q-c-notification-rating-filter
                                :filterLabel="t('notification-rating-filter')"
                                v-model="data.rating_id"
                                data-cy="rating_id"
                                id="dateStart"
                                :clearable="true"
                            ></q-c-notification-rating-filter>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('date-title') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <app-filter-date-picker
                                :filter-label="t('date-choose')"
                                v-model="data.qc_date"
                                data-cy="qc_date"
                                id="dateStart"
                                :clearable="true"
                            ></app-filter-date-picker>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card elevation="0" class="sp-mar-top-3">
                    <v-card-title>{{ t('status') }}</v-card-title>
                    <v-card-text>
                        <div>
                            <q-c-notifications-status-filter
                                v-model="data.status"
                                :filter-label="t('status-filter')"
                                data-cy="status"
                            />
                        </div>
                    </v-card-text>
                </v-card>
            </template>
        </BasicCreateEditContainer>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
import PictureUpload from '@/components/inputs/PictureUpload'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import ContentCustomer from '@/components/filter-components/ContentCustomer'
import ContentFilterUser from '@/components/filter-components/ContentFilterUser'
import QCNotificationsStatusFilter from '@/components/filter-components/QCNotificationsStatusFilter'
import QCNotificationRatingFilter from '@/components/filter-components/QCNotificationRatingFilter'
import QCNotificationTypeFilter from '@/components/filter-components/QCNotificationTypeFilter'
import QCSubjectAreaFilter from '@/components/filter-components/QCSubjectAreaFilter'
import ContactTypeFilter from '@/components/filter-components/ContactTypeFilter'
import QCNotificationRoomFilter from '@/components/filter-components/QCNotificationRoomFilter'
import moment from 'moment'

export default {
    name: 'QCNotificationsCreateEdit',
    components: {
        PictureUpload,
        BasicCreateEditContainer,
        AppFilterDatePicker,
        ContentFilterEmployee,
        ContentCustomer,
        ContentFilterUser,
        QCNotificationsStatusFilter,
        QCNotificationRatingFilter,
        QCNotificationTypeFilter,
        QCSubjectAreaFilter,
        QCNotificationRoomFilter,
        ContactTypeFilter
    },
    created() {
        this.data.id = this.$route.params.id
        if (this.data.id) {
            this.getQCNotification()
            this.mode = 'edit'
        } else {
            this.mode = 'create'
            this.dataOriginal = JSON.parse(JSON.stringify(this.data))
            this.dataLoaded = true
        }
    },
    data() {
        return {
            dataLoaded: false,
            dataOriginal: null,
            data: {
                id: null,
                dataLoaded: false,
                customer_id: null,
                contact_person: '',
                contact_type_id: null,
                project_leader_id: null,
                room_id: '',
                subject_area_id: null,
                notes: '',
                type_id: null,
                rating_id: null,
                qc_date: '',
                status: '',
                picture: '',
                immediate_measures: false,
                immediate_measures_user_id: null,
                immediate_measures_date: '',
                immediate_measures_notes: '',
                immediate_measures_done: 0,
                immediate_measures_done_date: null,
                preventive_measures: false,
                preventive_measures_user_id: null,
                preventive_measures_date: '',
                preventive_measures_notes: '',
                preventive_measures_done: 0,
                preventive_measures_done_date: null,
                cause_notes: '',
                cause_user_id: null,
                phone_feedback: false,
                phone_feedback_user_id: null,
                phone_feedback_date: '',
                phone_feedback_notes: '',
                phone_feedback_done: 0,
                phone_feedback_done_date: null,
                aek: false,
                aek_user_id: null,
                aek_date: '',
                aek_notes: '',
                aek_done: 0,
                aek_done_date: null
            }
        }
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
        }
    },
    methods: {
        t: function (key) {
            return this.$t('qc-notifications.create.' + key)
        },
        getQCNotification() {
            HTTP.get('qc_notifications/' + this.data.id).then(function (response) {
                let refactor = response.data

                if (refactor.customer != null) refactor.customer_id = refactor.customer
                if (refactor.project_leader != null) refactor.project_leader_id = refactor.project_leader
                if (refactor.i_measures_user != null) refactor.immediate_measures_user_id = refactor.i_measures_user
                if (refactor.p_measures_user != null) refactor.preventive_measures_user_id = refactor.p_measures_user
                if (refactor.aek_user != null)  refactor.aek_user_id = refactor.aek_user
                if (refactor.phone_feedback_user != null) refactor.phone_feedback_user_id = refactor.phone_feedback_user
                if (refactor.cause_user != null) refactor.cause_user_id = refactor.cause_user 

                this.data = refactor
                this.dataOriginal = JSON.parse(JSON.stringify(this.data))
                this.dataLoaded = true
            }.bind(this)).catch(function (error) {
                console.log(error)
                this.dataLoaded = true
            }.bind(this))
        },      
        saveQCNotification() {       
            let data = Object.assign({}, this.data)

            if (data.aek_user_id != null) data.aek_user_id = data.aek_user_id.id
            if (data.customer_id != null) data.customer_id = data.customer_id.id
            if (data.project_leader_id != null) data.project_leader_id = data.project_leader_id.id
            if (data.immediate_measures_user_id != null) data.immediate_measures_user_id = data.immediate_measures_user_id.id
            if (data.preventive_measures_user_id != null) data.preventive_measures_user_id = data.preventive_measures_user_id.id
            if (data.phone_feedback_user_id != null) data.phone_feedback_user_id = data.phone_feedback_user_id.id
            if (data.cause_user_id != null) data.cause_user_id = data.cause_user_id.id
                
            data.aek = data.aek ? 1 : 0
            data.immediate_measures = data.immediate_measures ? 1 : 0
            data.phone_feedback = data.phone_feedback ? 1 : 0    
            data.preventive_measures = data.preventive_measures ? 1 : 0

            if (this.dataOriginal.immediate_measures_done !== data.immediate_measures_done) {
                data.immediate_measures_done_date = data.immediate_measures_done ? moment().format('YYYY-MM-DD') : null
            }
            if (this.dataOriginal.preventive_measures_done !== this.data.preventive_measures_done) {
                data.preventive_measures_done_date = data.preventive_measures_done ? moment().format('YYYY-MM-DD') : null
            }
            if (this.dataOriginal.phone_feedback_done !== data.phone_feedback_done) {
                data.phone_feedback_done_date = data.phone_feedback_done ? moment().format('YYYY-MM-DD') : null
            }
            if (this.dataOriginal.aek_done !== data.aek_done) {
                data.aek_done_date = data.aek_done ? moment().format('YYYY-MM-DD') : null
            }

            if (this.mode === 'create') {
                return HTTP.post('/qc_notifications', data)
            } else {
                return HTTP.put('/qc_notifications/' + data.id, data)
            }
        },
        onSaved() {
            this.dataOriginal = null
            this.close()
        },
        deleteQCNotification() {
            return HTTP.delete('qc_notifications/' + this.data.id)
        },
        onDeleted() {
            this.dataOriginal = null
            this.close()
        },
        close() {
            this.cancel()
        },
        cancel() {
            this.$router.push({name: 'QCNotificationsIndex'})
        },
        getCustomerSearchUrl(val) {
            return 'tasks/customers?q=' + val
        }
    }
}
</script>