var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('toolbar',{attrs:{"dataLoaded":true}},[_vm._v(_vm._s(_vm.t('title')))]),_c('v-container',{staticClass:"settings",attrs:{"fluid":""}},[_c('loading',{attrs:{"error":_vm.error,"dataLoaded":_vm.dataLoaded}}),(_vm.dataLoaded)?_c('v-row',{staticClass:"settings-wrapper"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('categories.group-title')))]),_c('v-card-text',[_c('div',{staticClass:"button-wrapper"},[_c('v-btn',{staticClass:"icon-left",attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){return _vm.openCategoryDialog(null)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.t('categories.create'))+" ")],1)],1),(_vm.dataLoaded)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.categories,"server-items-length":_vm.categories.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ellipsis"},[_c('b',[_vm._v(_vm._s(item.name))])])]}},{key:"item.user_count",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user_count))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"edit-row-table",on:{"click":function($event){return _vm.openCategoryDialog(item, index)}}},[_c('v-icon',{staticClass:"edit-row-button",attrs:{"small":""}},[_vm._v(" edit ")])],1)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"edit-row-table",on:{"click":function($event){return _vm.removeCategory(index)}}},[_c('v-icon',{staticClass:"edit-row-button",attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)}):_vm._e()],1)],1)],1):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"bottom-navigation modal-nav"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-btn',{staticClass:"icon-left",attrs:{"color":"primary","text":"","disabled":_vm.dataSaving},on:{"click":function($event){return _vm.clear()}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.t('reset'))+" ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"icon-left",attrs:{"color":"primary","disabled":_vm.dataSaving,"loading":_vm.dataSaving},on:{"click":function($event){return _vm.saveData()}}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.t('save'))+" ")],1)],1)],1)],1)]),_c('v-dialog',{attrs:{"content-class":"category-dialog","width":"600","persistent":""},model:{value:(_vm.categoryDialog),callback:function ($$v) {_vm.categoryDialog=$$v},expression:"categoryDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.t('dialog.title'))+" ")]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"label":_vm.t('dialog.placeholder'),"counter":"120","filled":"","rules":[
                        function () { return !!_vm.categoryTitle || _vm.t('validation.error-title-required'); },
                        function () { return (_vm.categoryTitle && _vm.categoryTitle.length <= 120) || _vm.t('validation.error-max-title-120'); }
                      ]},model:{value:(_vm.categoryTitle),callback:function ($$v) {_vm.categoryTitle=$$v},expression:"categoryTitle"}})],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"icon-left px-4",attrs:{"elevation":"0","color":"primary","text":""},on:{"click":function($event){return _vm.closeCategoryDialog()}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.t('dialog.cancel'))+" ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"icon-left px-4",attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){_vm.editItem ? _vm.editCategory() : _vm.addCategory()}}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.editItem ? _vm.t('dialog.apply') : _vm.t('dialog.add'))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }