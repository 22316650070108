<template>

  <div class="sp-flex-1">
    <span v-if="filterParam == null" class="md-caption sp-text-white">Filterung nach Checkliste, Element, Guppe, Kategorie oder Mitarbeiter verfügbar.</span>
    <span v-if="filterParam != null && filterParam.find(x => x == 'user') && !filterParam.find(x => x == 'customer')" class="md-caption sp-text-white">Filterung nach Mitarbeiter verfügbar.</span>
    <span v-if="filterParam != null && filterParam.find(x => x == 'user') && filterParam.find(x => x == 'customer')" class="md-caption sp-text-white">Filterung nach Mitarbeiter oder Kunde verfügbar.</span>
    <md-input-container class="sp-search-field-lists">
      <div v-if="!filterSelected" class="sp-search-field-inner sp-flex sp-flex-1">
        <md-input type="text" v-model="searchTerm" :placeholder="placeholder" :title="placeholder"></md-input>
        <md-icon>search</md-icon>
      </div>

      <div v-if="filterSelected" class="sp-flex sp-flex-1 sp-search-field-inner">
        <md-input class="sp-text-blue" disabled :value="filteredName"></md-input>
        <md-icon class="delete sp-text-blue" @click.native="clearFilter()">close</md-icon>
      </div>

      <div class="sp-users-index sp-list-content" v-if="filterDataLoaded && filterData.length < 25" v-bind:class="{'sp-search-open' : isShowSearchfield }">
        <md-layout>
          <md-list class="md-double-line">
            <md-list-item v-for="item in filterData" :key="item.id" @click.native="setFilter(item, item.type)">
              <div class="md-list-text-container">
                <p class="sp-mar-bot-0 sp-mar-top-0">
                  <span v-if="item.type === 'user' || item.type === 'coordinator'">{{ item.firstname }} </span>
                  <span v-if="item.type !== 'element'">{{ item.name }}</span>
                  <span v-if="item.type === 'element'">{{ item.name.de }}</span>
                </p>
                <span>{{item.type}}</span>
              </div>
              <md-divider></md-divider>
            </md-list-item>
          </md-list>
        </md-layout>
      </div>

    </md-input-container>
  </div>
</template>

<script>

  import {HTTP} from '../../auth/index'
  import {EventBus} from '../../event-bus'
  import axios from 'axios'

  export default {
    components: {
    },
    data () {
      return {
        searchTerm: '',
		filterData: [],
        filterDataLoaded: false,
        error: null,
        selectedFilter: null,
        filterSelected: false,
        isShowSearchfield: false,
        showResult: false,
        filter: null,
        showFilter: false,
        cancel: null,
        errorCancel: null
      }
    },
    created () {
      EventBus.$on('search', function () {
        if (this.isShowSearchfield) {
          this.isShowSearchfield = false
        } else {
          this.isShowSearchfield = true
        }
      }.bind(this))
    },
    computed: {
      filteredName: function () {
        if (this.filter === 'user' || this.filter === 'coordinator') {
          return this.selectedFilter.firstname + ' ' + this.selectedFilter.name
        } else if (this.filter === 'element') {
          return this.selectedFilter.name.de
        } else {
          return this.selectedFilter.name
        }
      }
    },
    props: {
      outputTemplate: null,
      filterParam: null,
      reportGroupType: null,
	  placeholder: {
		type: String,
		default: 'Suchen'
	}
    },
    watch: {
      searchTerm: function (val) {
        if (val === '') {
          return
        }
        this.getSearchResult(encodeURIComponent(val))
      }
    },
    methods: {
      getSearchResult (val) {
        if (this.cancel) {
          this.cancel()
        }
        HTTP.get('statistics/search?q=' + val + '&reportGroupType=' + this.reportGroupType, {
          cancelToken: new axios.CancelToken(function executor (c) {
            this.cancel = c
          }.bind(this))
        }).then(function (response) {
          if (this.filterParam != null) {
            this.filterData = response.data.filter(x => this.filterParam.find(y => y === x.type))
          } else {
            this.filterData = response.data
          }
          this.filterDataLoaded = true
        }.bind(this)).catch(function (error) { this.errorCancel = error }.bind(this))
      },
      setFilter (item, type) {
        this.all = []
        this.filterSelected = true
        this.filterDataLoaded = false
        this.selectedFilter = item
        this.filter = type
        const copyOfTemplate = JSON.parse(JSON.stringify(this.outputTemplate))
        copyOfTemplate.forEach(x => {
          x.visible = true
        })

        const backendFilter = {
          id: null,
          filter: {
            filterGroups: []
          },
          name: null,
          output: copyOfTemplate
        }

        var value = ''

        if (item.type === 'user' || item.type === 'coordinator') {
          value = item.firstname + ' ' + item.name
        } else if (item.type === 'element') {
          value = item.name.de
        } else {
          value = item.name
        }

        backendFilter.filter.filterGroups.push({
          type: type,
          operator: 'is',
          value: value,
          id: item.id
        })

        backendFilter.filter.reportGroupType = this.reportGroupType
        this.$nextTick(function () {
          EventBus.$emit('current-filter', backendFilter)
        })
      },
      clearFilter () {
        this.filterSelected = false
        this.filter = null
        this.filterDataLoaded = false
        this.isShowSearchfield = false
        this.selectedFilter = null
        this.searchTerm = ''
        const copyOfTemplate = JSON.parse(JSON.stringify(this.outputTemplate))
        copyOfTemplate.forEach(x => {
          x.visible = true
        })
        const backendFilter = {
          id: null,
          filter: {
            filterGroups: [],
            reportGroupType: this.reportGroupType
          },
          name: null,
          output: copyOfTemplate
        }
        this.$nextTick(function () {
          EventBus.$emit('current-filter', backendFilter)
        })
      }
    }
  }
</script>
<style scoped>

  .sp-search-field-inner {
    position: relative;
    background: #fff;
    border-radius: 2px;
    padding: 8px 16px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,.26);
    border: 1px solid rgba(29, 86, 165,.78);
  }

  .sp-list-content {
    position: absolute;
    width: 100%;
    top: 66px;
    border-radius: 2px;
    padding: 0;
    left: 0px;
    background: #fff;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,.5);
  }

  .md-double-line {
    width:100%
  }

  .sp-search-field-lists {
    max-width: initial;
    margin-bottom: 57px;
    z-index: 2;
  }

  .sp-search-field-lists:after {
    background-color: #FFF !important;
  }

  .sp-search-field-lists.md-input-container {
    margin-top: 11px;
  }

</style>

