import moment from "moment/moment";

const mixin = {
  methods: {
    startDataDownload: (data, contentType, filename) => {
      let timestamp = moment().format('YYYYMMDD_HHmm')
      let blob = new Blob([data], { type: contentType })
      let link = document.createElement('a')
      link.style = 'display: none'
      link.href = window.URL.createObjectURL(blob)
      link.download = filename.replace('%TIMESTAMP%', timestamp)
      document.body.appendChild(link)
      link.click()
      setTimeout(function () {
        document.body.removeChild(link)
        window.URL.revokeObjectURL(blob)
      }, 100)
    }
  }
}

export default {
  install(Vue, options) {
    Vue.mixin(mixin)
  }
}

