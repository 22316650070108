<template>
  <div>
    <BasicEditContainer
        :title="t('edit.title')"
        ref="editor"
        :data-loaded="dataLoaded"
        v-if="dataLoaded"
        @cancel="cancel"
        :save-action="saveItem"
        @saved="close"
        :has-changes="hasChanges"
        :allowDelete="false">

      <v-card
          elevation="0"
      >
        <v-card-title>{{ t('details.group-title') }}</v-card-title>
        <v-card-text>

          <v-text-field
              :label="t('details.name')"
              v-model="data.name"
              counter="120"
              data-cy="name"
              filled
              :rules="[
								() => !!data.name || t('details.error-max-description'),
								() => (data.name && data.name.length <= 120) || t('details.error-max-description')
							]"
          ></v-text-field>

        </v-card-text>
      </v-card>
    </BasicEditContainer>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import BasicEditContainer from "@/components/inputs/BasicEditContainer";

export default {
  name: 'JobsCategoriesEdit',
  components: {
    BasicEditContainer
  },
  data() {
    return {
      itemId: null,
      data: null,
      dataOriginal: null,
      dataLoaded: false,
      indexRouteName: 'JobsCategoriesIndex'
    }
  },
  created() {
    this.itemId = this.$route.params.id
    this.getItem()
  },
  computed: {
    hasChanges() {
      if (!this.dataOriginal) {
        return false;
      }
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('jobs.categories.detail.' + key);
    },
    getItem() {
      HTTP.get('jobs/categories/' + this.itemId).then(function (response) {
        this.data = response.data
        this.dataOriginal = JSON.parse(JSON.stringify(this.data));
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    saveItem() {
      return HTTP.patch('jobs/categories/' + this.itemId, this.data);
    },
    async onSaved() {
      this.dataOriginal = null;
      this.close();
    },
    cancel() {
      this.close();
    },
    close() {
      this.$router.push({name: this.indexRouteName})
    }
  }
}
</script>

<style scoped lang="scss">

</style>

