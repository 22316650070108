var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasicNewContainer',{ref:"editor",attrs:{"title":_vm.t('title.create'),"saveAction":_vm.saveItem,"has-changes":_vm.hasChanges},on:{"cancel":_vm.close,"saved":_vm.onSaved}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('detail.group-title')))]),_c('v-card-text',[_c('v-text-field',{attrs:{"filled":"","label":_vm.t('detail.name') + '*',"counter":"120","rules":[
								  function () { return !!_vm.item.name || _vm.$t('validation.required.name'); },
								  function () { return (_vm.item.name && _vm.item.name.length <= 120) || _vm.$t('validation.length.default'); }
							  ]},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1)],1),_c('v-card',{staticClass:"sp-mar-top-3",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('items.group-title')))]),_c('v-card-text',{staticClass:"drag-wrapper"},[(_vm.item.items.length > 0)?_c('draggable',_vm._b({staticClass:" mb-8",attrs:{"tag":"div"},model:{value:(_vm.item.items),callback:function ($$v) {_vm.$set(_vm.item, "items", $$v)},expression:"item.items"}},'draggable',_vm.getOptions(),false),_vm._l((_vm.item.items),function(item,index){return _c('div',{key:index,staticClass:"drag-element"},[_c('v-row',{staticClass:"pb-1"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"drag-indicator"},[_vm._v("mdi-drag")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.t('items.name'),"rules":[
								  function () { return !!item.name || _vm.$t('validation.required.name'); },
								  function () { return (item.name && item.name.length <= 120) || _vm.$t('validation.length.default'); }
							  ],"hide-details":"auto"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.t('items.unit'),"rules":[
								  function () { return !!item.unit || _vm.$t('validation.required.unit'); },
								  function () { return (item.unit && item.unit.length <= 120) || _vm.$t('validation.length.default'); }
							  ],"hide-details":"auto"},model:{value:(item.unit),callback:function ($$v) {_vm.$set(item, "unit", $$v)},expression:"item.unit"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.t('items.price'),"rules":[
								  function () { return !!item.price || _vm.$t('validation.required.price'); },
								  function () { return (item.price && item.price.length <= 120) || _vm.$t('validation.length.default'); }
							  ],"hide-details":"auto"},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('md-button',{staticClass:"sp-text-blue sp-mar-r-0",attrs:{"title":_vm.t('items.remove')},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('md-icon',{staticClass:"sp-text-blue"},[_vm._v("remove_circle")])],1)],1)],1)],1)}),0):_vm._e(),_c('div',[_c('v-row',[_c('v-col',{staticClass:"mt-4"},[_c('strong',[_vm._v(_vm._s(_vm.t('add-new-position')))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.t('items.name'),"hide-details":"auto"},model:{value:(_vm.newItem.name),callback:function ($$v) {_vm.$set(_vm.newItem, "name", $$v)},expression:"newItem.name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.t('items.unit'),"hide-details":"auto"},model:{value:(_vm.newItem.unit),callback:function ($$v) {_vm.$set(_vm.newItem, "unit", $$v)},expression:"newItem.unit"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.t('items.price'),"type":"number","hide-details":"auto"},model:{value:(_vm.newItem.price),callback:function ($$v) {_vm.$set(_vm.newItem, "price", $$v)},expression:"newItem.price"}})],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('md-button',{staticClass:"sp-text-blue sp-mar-r-0",attrs:{"title":_vm.t('items.add')},on:{"click":_vm.addNewItem}},[_c('md-icon',{staticClass:"sp-text-blue"},[_vm._v("add_circle")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }