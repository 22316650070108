<template>
    <div class="has-hero-container">
        <div>

            <toolbar :dataLoaded="true">{{ $t('chat-messages.title') }}</toolbar>

            <div v-for="msg in messages" :key="msg.id">
                <testimonial-block>
                    <q><p>
                        {{ msg.message }}
                    </p></q>
                    <footer>
                        {{ msg.sender.nickname }}<br/>{{ getCreatedAt(msg) }}
                    </footer>
                </testimonial-block>
            </div>
            <div>
                <v-text-field v-model="text" :label="$t('chat-messages.chat-message-label')"/>
                <v-btn @click="sendMessage">{{ $t('chat-messages.send') }}</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import toolbar from '../components/layouts/Navigation.vue'
import {connectToChat, connectToGroupChannel, loadPreviousMessages, sendMessageToChannel} from "@/services/ChatService";
import {HTTP} from "@/auth";
import moment from "moment/moment";

export default {
    name: "ChatMessages",
    components: {
        toolbar
    },
    data() {
        return {
            groupId: null,
            messages: [],
            text: ''
        }
    },
    async created() {
        this.groupId = this.$route.params.id;
        let userId = await this.getUserId()
        let tokenInfo = await this.getToken()
        await connectToChat(userId, tokenInfo.token)
        let lastMessage = await connectToGroupChannel(this.groupId)
        let prevMessages = await loadPreviousMessages(lastMessage)
        this.messages = [...prevMessages, lastMessage]
    },
    methods: {
        async getUserId() {
            let response = await HTTP.get('/me')
            return response.data.chat_user_id
        },
        async getToken() {
            let response = await HTTP.post('chat/token')
            return response.data
        },
        async sendMessage() {
            await sendMessageToChannel(this.text, (msg) => {
                this.messages.push(msg)
            })
        },
        getCreatedAt(msg) {
            return this.formatDateTime(moment(msg.createdAt))
        }
    }
}
</script>

<style lang="scss" scoped>
$bg: darkslateblue;
$size: 10pt;

testimonial-container {
  size: $size;
  background: $bg;
  display: block;
}

testimonial-block {
  display: block;
  position: relative;
  margin-bottom: calc($size * 2);
  padding: $size;
  background-color: $bg;
  border-radius: calc($size / 1);
  color: white;
  font-size: calc($size * 1.2);
  line-height: 1.4;
  font-family: sans-serif;
}

testimonial-block:nth-of-type(even) {
  --bg: slateblue;
}

testimonial-block::after {
  --translate: -100%;
  content: '';
  width: calc($size * 2);
  height: calc($size * 2);
  background: $bg;
  position: absolute;
  top: calc(100% - $size - 1px);
  left: calc(100% - ($size * 2));
  transform: translateX(var(--translate)) rotateZ(45deg);
  z-index: -1;
}

testimonial-block q {
  display: block;
}

testimonial-block q::before {
  content: '“';
}

testimonial-block q::after {
  content: '”';
}

testimonial-block p {
  display: inline;
  margin: 0;
}

testimonial-block footer {
  margin-top: calc($size / 2);
  font-size: $size;
}

/* Responsive Styles */
testimonial-block footer {
  text-align: right;
}

testimonial-block footer::before {
  content: '– ';
}

</style>
