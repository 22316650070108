import { render, staticRenderFns } from "./ContentCustomer.vue?vue&type=template&id=2ccf8bdf&scoped=true"
import script from "./ContentCustomer.vue?vue&type=script&lang=js"
export * from "./ContentCustomer.vue?vue&type=script&lang=js"
import style0 from "./ContentCustomer.vue?vue&type=style&index=0&id=2ccf8bdf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ccf8bdf",
  null
  
)

export default component.exports