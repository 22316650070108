<template>
  <v-layout :class="'sp-text-center sp-mar-top-3'" v-if="!dataLoaded || error">
    <div v-if="!dataLoaded && !error" class="sp-flex-1">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-if="error" class="sp-flex-1">
      <p>{{ $t('loading-error') }}</p>
      <v-btn elevation="0" color="warning" @click.native="reload()" class="mt-3 icon-left">
        <v-icon>replay</v-icon>
        Reload
      </v-btn>
    </div>
  </v-layout>
</template>

<script>
import {EventBus} from './../../event-bus'

export default {
  props: {
    error: false,
    dataLoaded: true
  },
  methods: {
    reload() {
      EventBus.$emit('reload')
    }
  }
}
</script>
