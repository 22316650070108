<template>
    <div >
        <div>
            <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>        
          
            <v-container fluid>
                <v-row class="data-table-toolbar">
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn
                            elevation="0"
                            color="primary"
                            class="icon-left"
                            @click="menuCreateDialog = true"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('menu.list.create') }}
                        </v-btn>
                        
                        <menu-create-new-dialog v-model="menuCreateDialog"></menu-create-new-dialog>
                        
                    </div>
                </v-row>
                <v-row v-if="dataLoaded && items.length === 0">
                    <div class="empty-datatable">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                        {{ t('no-data') }}
                    </div>
                </v-row>
                <v-row v-else>
                    <v-data-table
                        calculate-widths
                        :loading="loading"
                        :options.sync="options"
                        :sort-by.sync="filterStore.table.sortBy"
                        :sort-desc.sync="filterStore.table.sortDesc"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus',
                            'items-per-page-options': [25, 50, 100, 200],
                            pageText: '{0}-{1} von {2}',
                            'items-per-page-text': t('table.items-per-page')
                        }"
                        v-model="selected"
                        :headers="headers"
                        :items="items"
                        class="elevation-0"
                        >
                            <template #body="props">
                                <draggable
                                :list="props.items"
                                tag="tbody"
                                :disabled="!allowDrag"
                                :move="onMoveCallback"
                                :clone="onCloneCallback"
                                @end="onDropCallback(props.items)"
                                >                  
                                <data-table-row-handler
                                    v-for="(item, index) in props.items"
                                    :key="index"
                                    :item="item"
                                    :headers="headers"
                                    :item-class="getClass(item)"
                                >   
                                </data-table-row-handler>
                                </draggable>
                              
                            </template>
                        </v-data-table>
                </v-row>
            </v-container>

            <v-dialog
                v-model="dialog"
                width="500"
                persistent
                content-class="confirm-delete custom-class-dialog"
            >
                <v-card>
                    <v-card-title>
                        {{ t('confirm-delete.title') }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t('menu.list.confirm-delete.content', { moduleName: deleteItem ? deleteItem.title : '' }) }}
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="primary"
                            text
                            class="icon-left"
                            @click="cancelDeletion()"
                        >
                            <v-icon>mdi-close</v-icon>
                            {{ t('confirm-delete.cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            elevation="0"
                            color="primary"
                            class="icon-left"
                            :loading="isDeleting"
                            @click="doDeletion()"
                        >
                            <v-icon>mdi-check</v-icon>
                            {{ t('confirm-delete.ok') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </div>
    </div>
</template>

<script>
import auth from './../auth/index'
import { HTTP } from './../auth'
import { EventBus } from '@/event-bus'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import toolbar from '@/components/layouts/Navigation.vue'
import moment from 'moment'
import { useMenuFilterStore } from '@/stores/MenuFilterStore'
import MenuCreateNewDialog from '@/components/dialogs/MenuCreateNewDialog.vue'
import draggable from 'vuedraggable'
import DataTableRowHandler from '@/components/DataTableRowHandler.vue'

export default {
    name: 'MenuIndex',
    components: {
        AppFilterApplyButton,
        toolbar,
        MenuCreateNewDialog,
        draggable,
        DataTableRowHandler
    },
    setup() {
        const filterStore = useMenuFilterStore()
        return { filterStore }
    },
    data() {
        return {
            loading: false,
            dataLoaded: false,
            totalItems: 0,
            items: [],
            options: {},
            menuCreateDialog: false,
            allowDrag: true,
            selected: [],
            dialog: false,
            deleteItem: null,
            isDeleting: false
        }
    },
    mounted() {
        EventBus.$on('delete-item', function (item) {
            this.deleteItem = item
            this.dialog = true
        }.bind(this))
    },
    beforeDestroy() {
        EventBus.$off('delete-item')
    },
    computed: {
        headers() {
            return [
                {
                    text: this.t('table.process'),
                    align: 'center',
                    sortable: true,
                    value: 'process',
                    width: 55
                },
                {
                    text: this.t('table.icon'),
                    align: 'center',
                    sortable: true,
                    value: 'icon',
                    width: 55
                },
                {
                    text: this.t('table.title'),
                    align: 'left',
                    sortable: true,
                    value: 'title',
                    width: 400
                },
                {
                    text: this.t('table.type'),
                    align: 'center',
                    sortable: true,
                    value: 'type',
                    width: 30
                },
                {
                    text: this.t('table.edit'),
                    value: 'edit',
                    width: 30,
                    align: 'center',
                    sortable: false
                },
                {
                    text: this.t('table.delete'),
                    value: 'delete',
                    width: 30,
                    align: 'center',
                    sortable: false
                }
            ]
        }
    },
    watch: {
      options: {
        handler() {
          this.getDataFromApi()
        },
        deep: true,
      }
    },
    methods: {     
        getClass(item) {
            return item.calories > 500
                ? "cal-high"
                : item.calories > 400
                ? "cal-medium"
                : "cal-low";
        },
        onCloneCallback(item) {
            // Create a fresh copy of item
            const cloneMe = JSON.parse(JSON.stringify(item))
            return cloneMe
        },
        onMoveCallback(evt, originalEvent) {
            const item = evt.draggedContext.element;
            const itemIdx = evt.draggedContext.futureIndex

            if (item.locked) {
                return false
            }

            return true
        },
        async onDropCallback(items) {
            let url = '/menu-sort'
            url += "?language=" + localStorage.getItem("currentLocale")

            await HTTP.post(url, items).then(function () {
                EventBus.$emit('module-config-changed')
            }.bind(this))
        },
        t: function (key) {
            return this.$t('menu.list.' + key)
        },
        moment: function () {
            return moment()
        },
        applyFilter() {
            this.filterStore.filterApplied()
            this.reload()
        },
        reload() {
            this.items = []
            this.getDataFromApi()
        },
        getDataFromApi() {
            this.loading = true
            this.dataLoaded = false
            this.apiCall().then(data => {
                this.items = data.items
                this.loading = false
                this.dataLoaded = true
            })
        },
        apiCall() {
            return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options

                let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)
                url += "&language=" + localStorage.getItem("currentLocale")

                HTTP.get('menus' + url).then(function (response) {
                    let items = response.data
                    resolve({
                        items,
                    })
                }.bind(this))
            })
        },
        prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
            let queryStringParts = []
            
            if (sortBy) {
                queryStringParts.push('sortBy=' + (sortBy[0] ?? ''))
                let direction = sortDesc[0] ? 'desc' : 'asc'
                queryStringParts.push('direction=' + direction)
            }

            return '?' + queryStringParts.join('&')
        },
        doDeletion() {
            try {
                this.isDeleting = true
                HTTP.delete('menus/' + this.deleteItem.id).then(function () {
                    this.isDeleting = false
                    this.dialog = false
                    this.items = []
                    this.getDataFromApi()
                    auth.loadConfig()
                }.bind(this))
            } catch (error) {
                console.error('Error deleting item:', error)
            }
        },
        cancelDeletion() {
            this.dialog = false
            this.deleteItem = null
        }
    }
}
</script>
