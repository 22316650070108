<template>
  <div>
    <BasicNewContainer
        :title="t('create.title')"
        ref="editor"
        @cancel="close"
        :save-action="saveItem"
        @saved="onSaved"
        :has-changes="hasChanges"
    >
      <v-card
          elevation="0"
      >
        <v-card-title>{{ t('details.group-title') }}</v-card-title>
        <v-card-text>

          <v-text-field
              :label="t('details.name')"
              v-model="data.name"
              data-cy="name"
              counter="120"
              filled
              :rules="[
								() => !!data.name || $t('validation.required.category-name'),
								() => (data.name && data.name.length <= 120) || $t('validation.length.category-name')
							]"
          ></v-text-field>

        </v-card-text>
      </v-card>
    </BasicNewContainer>
  </div>
</template>

<script>
import { HTTP } from './../auth'
import BasicNewContainer from "@/components/inputs/BasicNewContainer";

export default {
  name: 'NewsCategoriesCreate',
  components: {
    BasicNewContainer,
  },
  data() {
    return {
      data: {
        name: ''
      },
      dataOriginal: null,
      indexRouteName: 'NewsCategoriesIndex'
    }
  },
  created() {
    this.dataOriginal = JSON.parse(JSON.stringify(this.data));
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('news.categories.detail.' + key);
    },
    saveItem() {
      return HTTP.post('news/categories', this.data);
    },
    async onSaved() {
      this.dataOriginal = null;
      this.close();
    },
    close() {
      this.$router.push({ name: this.indexRouteName })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
