<template>
  <div class="has-hero-container">

    <div>

      <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">

        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

          <app-filter-apply-button
              v-if="filterStore.hasChanges"
              @click="applyFilter(); options.page = 1"
          ></app-filter-apply-button>

          <v-slide-group
              show-arrows
          >
            <v-slide-item>

              <div class="filter-search-list">
                <div class="filter-item">
                  <div class="filter-item-wrapper">
                    <app-filter-search-field
                        v-model="filterStore.filter.searchTerm"
                        :filterLabel="$t('components.app-filter-search-field.label.title')"
                        clearable
                        @click:clear="clearSearchTerm"
                        append-icon="mdi-magnify"
                    ></app-filter-search-field>
                  </div>
                </div>
              </div>

            </v-slide-item>
            <v-slide-item>

              <div class="filter-status-filter">
                <div class="filter-item">
                  <v-select
                      v-model="filterStore.filter.status"
                      :label="t('filter.status')"
                      :items="filterStatus"
                      item-text="name"
                      item-value="slug"
                      multiple
                      data-cy="status"
                      chips
                  ></v-select>
                </div>
              </div>

            </v-slide-item>

          </v-slide-group>

        </v-container>

      </div>

      <v-container fluid>

        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              data-cy="createButton"
              :to="{ path: '/jobs/create'}"
          >
            <v-icon>mdi-plus</v-icon>
            {{ t('create') }}
          </v-btn>
        </v-row>

        <v-row v-if="dataLoaded && jobsPosts.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>

          <v-data-table
              :headers="headers"
              :items="jobsPosts"
              :options.sync="options"
              :server-items-length="totalJobs"
              :loading="loading"
              class="elevation-0"
              :sort-by.sync="filterStore.table.sortBy"
              :sort-desc.sync="filterStore.table.sortDesc"
              :page.sync="filterStore.table.currentPage"
              :items-per-page.sync="filterStore.table.itemsPerPage"
              :footer-props="{
						showFirstLastPage: true,
						firstIcon: 'mdi-arrow-collapse-left',
						lastIcon: 'mdi-arrow-collapse-right',
						prevIcon: 'mdi-minus',
						nextIcon: 'mdi-plus',
						'items-per-page-options': [25, 50, 100, 200],
						pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
					}"
          >
            <v-progress-linear v-show="loading" slot="progress" color="primary" indeterminate></v-progress-linear>

            <template v-slot:[`item.title`]="{ item }">
              <span class="ellipsis" v-if="item.title"><b>{{ item.title }}</b></span>
              <span class="ellipsis preview-text">{{ item.preview_text }}</span>
            </template>

            <template v-slot:[`item.published_at`]="{ item }">
						<span v-if="item.published_at && !item.deleted_at" class="has-column-icon">
							<span v-if="isPublished(item.published_at)" :title="t('status.published')"><v-icon>mdi-checkbox-marked-circle-outline</v-icon>{{
                  item.published_at | momentDate
                }}<br>{{ item.published_at | momentTime }} {{ t('status.clock') }}</span>
							<span v-if="!item.deleted_at && !isPublished(item.published_at)" :title="t('status.planned')"><v-icon>mdi-clock-outline</v-icon>{{
                  item.published_at | momentDate
                }}<br>{{ item.published_at | momentTime }} {{ t('status.clock') }}</span>
						</span>
              <span v-else class="has-column-icon">
							<span v-if="!item.published_at && !item.deleted_at" :title="t('status.draft')"><v-icon>mdi-email-open</v-icon>{{
                  t('status.draft')
                }}</span>
							<span v-if="item.deleted_at" :title="t('status.archive')"><v-icon>mdi-delete-outline</v-icon>{{
                  t('status.archive')
                }}</span>
						</span>
            </template>

            <template v-slot:[`item.pushed_at`]="{ item }">
						<span v-if="item.push_status_received" class="has-column-icon">
							<span v-if="item.pushed_at" :title="t('status.push-sent')" class="d-block"><v-icon>mdi-bell-outline</v-icon>{{
                  item.pushed_at | momentDate
                }}<br>{{ item.pushed_at | momentTime }} {{ t('status.clock') }}</span>
							<span v-if="item.pending_push" :title="t('status.planned')" class="d-block"><v-icon>mdi-clock-outline</v-icon>{{
                  item.published_at | momentDate
                }}<br>{{ item.published_at | momentTime }} {{ t('status.clock') }}</span>
						</span>
              <span v-else class="analytics-loading"></span>
            </template>

            <template v-slot:[`item.read`]="{ item }">
              <span v-if="item.analytics_calculated">{{
                  item.analytics_count + ' ' + t('status.from') + ' ' + item.analytics_total
                }}</span>
              <span v-else class="analytics-loading"></span>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ path: '/jobs/' + item.id }" class="edit-row-table">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>
          </v-data-table>

        </v-row>

      </v-container>

      <v-snackbar
          ref="snackbar"
          v-model="snackbar"
          timeout="4000"
      >
        {{ message }}
      </v-snackbar>

    </div>

  </div>

</template>

<script>
import {HTTP} from './../auth'
import axios from 'axios'
import toolbar from '../components/layouts/Navigation.vue'
import {EventBus} from './../event-bus.js'
import AppFilterSearchField from "../components/vuetify/AppFilterSearchField"
import {useJobsFilterStore} from "@/stores/JobsFilterStore";
import AppFilterApplyButton from "@/components/vuetify/AppFilterApplyButton";


import moment from 'moment'

export default {
  name: 'JobsIndex',
  components: {
    toolbar,
    AppFilterSearchField,
    AppFilterApplyButton
  },
  setup() {
    const filterStore = useJobsFilterStore()
    return {filterStore}
  },
  data() {
    return {
      loading: false,
      jobsPosts: [],
      totalJobs: 0,
      options: {},
      dataLoaded: false,
      showCreate: false,
      message: null,
      error: false,
      cancelHTTP: null,
      errorCancel: null,
      snackbar: false
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY');
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm');
    }
  },
  created() {
    this.applyFilter()
  },
  computed: {
    filterStatus() {
      return [
        {
          name: this.$t('jobs.data.status.published'),
          slug: 'PUBLISHED'
        },
        {
          name: this.$t('jobs.data.status.draft'),
          slug: 'DRAFT'
        },
        {
          name: this.$t('jobs.data.status.archived'),
          slug: 'ARCHIVED'
        }
      ]
    },
    headers() {
      return [
        {
          text: this.t('table.title'),
          value: 'title',
          sortable: true,
          width: 400
        },
        {
          text: this.t('table.published-at'),
          value: 'published_at',
          sortable: true,
          width: 150
        },
        {
          text: this.t('table.pushed-at'),
          value: 'pushed_at',
          sortable: true,
          width: 150
        },
        {
          text: this.t('table.read'),
          value: 'read',
          sortable: false,
          align: 'center',
          width: 150
        },
        {
          text: this.t('table.edit'),
          value: 'edit',
          sortable: false,
          align: 'center',
          width: 150
        }
      ];
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    selectedFilterStatus: function (after, pre) {
      this.filterChanged = after.length !== pre.length
    },
    searchTerm: function (after, pre) {
      if (typeof after === 'string' || after instanceof String) {
        this.filterChanged = this.filterChanged || (pre.length > 1 && after.length !== pre.length)
      } else {
        this.searchTerm = ''
      }
    }
  },
  beforeDestroy() {
  },
  methods: {
    t: function (key) {
      return this.$t('jobs.list.' + key);
    },
    moment: function () {
      return moment();
    },
    reload() {
      this.$nextTick(function () {
        this.jobsPosts = []
        this.getDataFromApi()
      }.bind(this))
    },
    applyFilter() {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      this.filterStore.filterApplied()
      this.reload()
    },
    clearSearchTerm() {
      this.filterStore.filter.searchTerm = ''
    },
    prepareUrl(sortBy, sortDesc, page, itemsPerPage) {
      let queryStringParts = []
      if (this.filterStore.filter.status.length > 0) {
        queryStringParts.push('st=' + this.filterStore.filter.status)
      }
      if (this.filterStore.filter.searchTerm ?? '' > 1) {
        queryStringParts.push('term=' + this.filterStore.filter.searchTerm)
      }
      queryStringParts.push('sortBy=' + sortBy[0])
      let sort = sortDesc[0] ? 'desc' : 'asc'
      queryStringParts.push('sortDesc=' + sort)
      page = page ? page : 1
      queryStringParts.push('page=' + page)
      queryStringParts.push('itemsPerPage=' + itemsPerPage)
      return '?' + queryStringParts.join('&')
    },
    getDataFromApi() {
      this.loading = true
      this.dataLoaded = false
      this.apiCall().then(data => {
        this.jobsPosts = data.jobsPosts
        this.totalJobs = data.total
        this.loading = false
        this.dataLoaded = true
      })
    },
    apiCall() {
      return new Promise((resolve, reject) => {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options

        let url = this.prepareUrl(sortBy, sortDesc, page, itemsPerPage)

        HTTP.get('jobs' + url).then(function (response) {
          let jobsPosts = response.data.data
          jobsPosts.forEach((element, index) => {
            if (!element.analytics_calculated) {
              this.getAnalyticsData(element.id, index)
            }
            if (!element.push_status_received) {
              this.getPushStatus(element.id, index)
            }
          })
          let total = response.data.total

          resolve({
            jobsPosts,
            total,
          })
        }.bind(this)).catch(function (error) {
          if (!error.status) {
            this.error = true
          }
        }.bind(this))

      })
    },
    getAnalyticsData(itemId, index) {
      HTTP.get('analytics/jobs/' + itemId, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      })
          .then(function (response) {
            if (this.jobsPosts[index]) {
              this.$set(this.jobsPosts[index], 'analytics_count', response.data.read_total)
              this.$set(this.jobsPosts[index], 'analytics_total', response.data.total)
              this.$set(this.jobsPosts[index], 'analytics_calculated', true)
            }
          }.bind(this))
          .catch(function (error) {
            //
          }.bind(this))
    },
    getPushStatus(itemId, index) {
      HTTP.get('jobs/' + itemId, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      })
          .then(function (response) {
            if (this.jobsPosts[index]) {
              this.$set(this.jobsPosts[index], 'pending_push', response.data.pending_cronjob)
              this.$set(this.jobsPosts[index], 'push_status_received', true)
            }
          }.bind(this))
          .catch(function (error) {
            //
          }.bind(this))
    },
    isPublished(publishedAt) {
      return moment().isAfter(publishedAt)
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 2000)
    },
  }
}
</script>

<style scoped lang="scss">

.sp-hero .filter-status-filter,
.sp-hero .filter-search-list {
  width: calc(50% - 134px);
}

.ellipsis {
  display: block;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 400px;
}

.preview-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.has-column-icon {
  display: inline-block;
  position: relative;
  padding: 0 5px 0 28px;
  font-size: 14px;

  i {
    position: absolute;
    left: 0;
  }
}

.analytics-loading {
  left: 40px;
}

</style>

