<template>
  <v-btn
      elevation="0"
      color="primary"
      class="filter-apply icon-left"
      @click="$emit('click', arguments)"
  >
    <v-icon>mdi-reload</v-icon>

  </v-btn>
</template>

<script>
export default {
  name: "AppRefreshButton"
}
</script>

<style scoped>

</style>
