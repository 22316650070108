<template>
  <div class="dashboard-card">

    <v-card
        elevation="0"
        v-if="hasPermission"
    >
      <v-card-title>
        <v-icon>mdi-account</v-icon>
        {{ $t('dashboard.user-statistics.title') }}
      </v-card-title>
      <v-card-text>

        <div v-if="userStatistics && !isLoading" class="sp-flex sp-flex-dir-col analytics-container">
          <div class="text-center">
            <v-progress-circular
                rotate="360"
                size="200"
                width="25"
                :value="Number(registeredPercentage).toFixed(0)"
                color="primary"
                class="mb-8"
            >
              {{
                Number(registeredPercentage).toFixed(1)
              }}%
            </v-progress-circular>
          </div>
          <div>
            <b>{{ $t('dashboard.user-statistics.active-users') }}</b><span> {{
              Number(registeredPercentage).toFixed(1)
            }}% ({{ registeredUsersTotal }} {{ $t('dashboard.user-statistics.from') }} {{ allUsersTotal }})</span>
            <br>
          </div>
          <div v-if="notRegisteredUsers.length > 0">
            <div><b>{{ $t('dashboard.user-statistics.inactive-users') }}</b>
              <v-icon @click="exportInactiveUsers">download</v-icon>
            </div>
            <div class="list-content">
              <div v-for="entry in notRegisteredUsers" :key="entry.id" class="analytics-item one-line">
                {{ entry.name }}, {{ entry.firstname }} ( {{ entry.updated_at}} )
              </div>
            </div>
          </div>
        </div>
        <div v-else class="analytics-loading-wrapper">
          <span v-if="isLoading" class="analytics-loading"></span>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import store from "store";
import {HTTP} from './../../auth'
import * as XLSX from "xlsx";

export default {
  name: 'DashboardUsers',
  data() {
    return {
      user: null,
      isLoading: true,
      userStatistics: null,
    }
  },
  async mounted() {
    this.user = store.get('user')
    if (['admin'].includes(this.user.role)) {
      await this.loadData()
    }
  },
  methods: {
    async loadData() {
      let response = await HTTP.get('user_statistics')
      this.userStatistics = response.data.users
      this.isLoading = false
    },
    exportInactiveUsers() {
      let data = this.notRegisteredUsers.map(z => {
        return {
          Nachname: z.name,
          Vorname: z.firstname,
          EMail: z.email
        };
      })

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, 'InaktiveBenutzer.xlsx');
    },
  },
  computed: {
    hasPermission() {
      if (!this.user) {
        return false
      }
      return ['admin'].includes(this.user.role)
    },
    registeredUsersTotal() {
      return this.registeredUsers.length
    },
    registeredUsers() {
      return this.userStatistics.filter(entry => entry.recently_active === 1)
    },
    notRegisteredUsers() {
      return this.userStatistics.filter(entry => entry.recently_active === 0)

    },
    allUsersTotal() {
      return this.userStatistics.length
    },
    registeredPercentage() {
      return this.registeredUsersTotal / this.allUsersTotal * 100
    },
  }
}
</script>

<style scoped>
.list-content {
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
}
</style>
