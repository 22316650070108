<template>
    <tiptap-vuetify
            v-model="value.content"
            :extensions="extensions"
            :card-props="{ flat: true }"
            @focus="editorFocused = true"
            @blur="editorFocused = false"
    />
</template>
<script>
import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    HardBreak,
    History
} from 'tiptap-vuetify'

export default {
    name: "ContentEditorBlockText",
    components: {
        TiptapVuetify,
    },
    props: {
        value: {},
        index: {},
    },
    data() {
        return {
            editorFocused: false,
        }
    },
    computed: {
        extensions() {
            return [
                History,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }],
                Bold,
                Paragraph,
                HardBreak
            ]
        },
    },
}
</script>

<style scoped lang="scss">

</style>
