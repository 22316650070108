import ObjectControlResults from '@/views/ObjectControlResults'
import ObjectControlResultsView from '@/views/ObjectControlResultsView'
import ObjectControlElementsIndex from '@/views/ObjectControlElementsIndex'
import ObjectControlElementsCreateEdit from '@/views/ObjectControlElementsCreateEdit'
import ObjectControlSettings from '@/views/SettingsObjectControl'

const objectControlRoutes = [
    {
        path: '/object-control',
        name: 'ObjectControlResults',
        component: ObjectControlResults,
        meta: {
            accessConfig:  {
                moduleName: ['object_control'],
                requiresAuth: true
            }
        }
    },
   {
        path: '/object-control/:id?',
        name: 'ObjectControlResultsView',
        component: ObjectControlResultsView,
        meta: {
            accessConfig:  {
                moduleName: ['object_control'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/object-control-elements',
        name: 'ObjectControlElementsIndex',
        component: ObjectControlElementsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['object_control'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/object-control-elements/create',
        name: 'ObjectControlElementsCreate',
        component: ObjectControlElementsCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['object_control'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/object-control-elements/:id?',
        name: 'ObjectControlElementsEdit',
        component: ObjectControlElementsCreateEdit,
        meta: {
            accessConfig:  {
                moduleName: ['object_control'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/object-control-settings',
        name: 'ObjectControlSettings',
        component: ObjectControlSettings,
        meta: {
            accessConfig:  {
                moduleName: ['object_control'],
                requiresAuth: true
            }
        }
    }
]

export default objectControlRoutes
