<template>
  <div></div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import auth from './../auth'

export default {
  name: "LoginCallback",
  async mounted() {
    let BASE_URL = process.env.VUE_APP_API_BASE_URL ?? ''
    let url = BASE_URL + '/api/oidc-callback?' + require('qs').stringify(this.$route.query)
    let response = await axios.post(url, null, {withCredentials: true})

    if (typeof response.data.token == "undefined") {
      window.location.href = "/";
      return;
    }

    await auth.authSaveToken(response.data.token)
    await auth.setupAxios()
    await auth.loadConfig()
    await auth.loadUser()
    await auth.loadPermissions()
    await router.push(response.data.redirect ?? '/')
  }
}
</script>

<style scoped>

</style>
