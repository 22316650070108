<template>
  <div>
    <basic-settings-container
        :title="t('title')"
        :data-loaded="dataLoaded"
        :has-changes="hasChanges"
        @reset="reset"
        :save-action="save"
    >
      <v-card
          elevation="0"
      >
        <v-card-title>{{ t('recipient') }}</v-card-title>
        <v-card-text>

          <v-switch
              v-model="settings.expense_email_created_enabled"
              inset
              :label="settings.expense_email_created_enabled ?  $t('surveys.detail.push.yes') : $t('surveys.detail.push.no')"
          />

          <br>

          <v-text-field
              :label="t('email')"
              v-model="settings.expense_email_created_to"
              filled
              :rules="[
								() => !settings.expense_email_created_enabled || (settings.expense_email_created_to && settings.expense_email_created_to.length > 0) || $t('validation.length.default')
							]"
          />

        </v-card-text>
      </v-card>
    </basic-settings-container>
  </div>
</template>


<script>
import {HTTP} from './../auth'
import BasicSettingsContainer from "@/components/inputs/BasicSettingsContainer.vue";

export default {
  name: 'ExpenseSettings',
  components: {
    BasicSettingsContainer,
    toolbar,
  },
  data() {
    return {
      settingsOriginal: '',
      settings: {
        expense_email_created_enabled: false,
        expense_email_created_to: '',
      },
      dataLoaded: false,
    }
  },
  async created() {
    await this.getData()
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.settings) !== JSON.stringify(this.settingsOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('expenses-settings.' + key);
    },
    async reset() {
      await this.getData()
    },
    async save() {
      await HTTP.post('/expense-settings', this.settings)
      await this.getData()
    },
    async getData() {
      let response = await HTTP.get('/expense-settings')
      this.settings.expense_email_created_enabled = response.data.expense_email_created_enabled
      this.settings.expense_email_created_to = response.data.expense_email_created_to
      this.settingsOriginal = JSON.parse(JSON.stringify(this.settings));
      this.dataLoaded = true
    },
  }
}
</script>

<style scoped lang="scss">

.apply-setting {
  color: #FFF !important;
  background: #FF5252 !important;
}

.settings-wrapper {
  justify-content: space-between;

  .v-card {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
}

</style>
