<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('title') }}</toolbar>

    <v-container fluid class="edit-screen">

      <loading :error="errorLoading" :dataLoaded="dataLoaded"></loading>

      <v-form ref="form">

        <div v-if="dataLoaded">

          <v-row class="emergency-cards">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ t('list.title') }}</v-card-title>
              <v-card-text class="drag-wrapper">

                <draggable tag="div" v-model="emergencyContacts" v-bind="getOptions()">

                  <div class="drag-element" v-for="(entry, index) in emergencyContacts" :key="index">
                    <v-icon class="drag-indicator">mdi-drag</v-icon>
                    <span class="entry-title">{{ entry.title }}</span>
                    <span class="entry-description">{{ entry.description }}</span>
                    <span class="entry-number">{{ entry.phone_number }}</span>
                    <v-icon v-if="!editDisabled" class="entry-edit" @click.native="editEntry(entry, index)"
                            :title="t('edit')">mdi-pencil
                    </v-icon>
                    <v-icon v-if="!editDisabled" class="entry-remove" @click.native="deleteEntry(entry, index)"
                            :title="t('delete')">mdi-delete
                    </v-icon>
                    <div class="sp-clearfix"></div>
                  </div>

                </draggable>

                <div v-if="isEditEntry" class="disable-drag"></div>

              </v-card-text>
            </v-card>

            <v-card
                elevation="0"
            >
              <v-card-title v-if="!isEditEntry">{{ t('detail.create.title') }}</v-card-title>
              <v-card-title v-else>{{ t('detail.edit.title') }}</v-card-title>
              <v-card-text>

                <v-text-field
                    :label="t('detail.details.title') + '*'"
                    v-model="emergencyContactTitle"
                    data-cy="emergencyContactTitle"
                    filled
                    :disabled="editDisabled"
                ></v-text-field>

                <v-textarea
                    :label="t('detail.details.description')"
                    class="no-validation emergency-description"
                    v-model="emergencyContactDescription"
                    data-cy="emergencyContactDescription"
                    filled
                    :disabled="editDisabled"
                ></v-textarea>

                <v-text-field
                    :label="t('detail.details.contact-phone') + '*'"
                    v-model="emergencyContactNumber"
                    data-cy="emergencyContactNumber"
                    filled
                    placeholder="+41 "
                    :disabled="editDisabled"
                ></v-text-field>

                <div class="clearfix" v-if="validEntry">

                  <v-btn
                      v-if="!editDisabled"
                      color="primary"
                      text
                      class="icon-left cancel-contact"
                      @click="cancelEntry()"
                      data-cy="cancelButton"
                  >
                    <v-icon>mdi-close</v-icon>
                    {{ t('detail.cancel') }}
                  </v-btn>

                  <v-btn
                      v-if="!editDisabled && !isEditEntry"
                      color="primary"
                      elevation="0"
                      class="icon-left add-contact"
                      @click="addEntry()"
                      data-cy="addButton"
                  >
                    <v-icon>mdi-plus</v-icon>
                    {{ t('detail.add') }}
                  </v-btn>

                  <v-btn
                      v-if="!editDisabled && isEditEntry"
                      color="primary"
                      elevation="0"
                      class="icon-left update-contact"
                      @click="updateEntry()"
                      data-cy="updateButton"
                  >
                    <v-icon>mdi-check</v-icon>
                    {{ t('detail.update') }}
                  </v-btn>

                </div>

              </v-card-text>
            </v-card>

          </v-row>

        </div>

      </v-form>

    </v-container>

    <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        data-cy="snackbar"
        timeout="4000"
    >
      {{ errorMessage }}
    </v-snackbar>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                elevation="0"
                class="icon-left"
                @click="onSubmit()"
                data-cy="saveButton"
            >
              <v-icon>mdi-check</v-icon>
              {{ t('detail.save') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import {EventBus} from './../event-bus'
import loading from '../components/layouts/Loading.vue'
import toolbar from '../components/layouts/Navigation.vue'
import draggable from 'vuedraggable'

export default {
  name: 'EmergencyContacts',
  components: {
    loading,
    toolbar,
    draggable
  },
  data() {
    return {
      emergencyContacts: [],
      emergencyContactTitle: '',
      emergencyContactDescription: '',
      emergencyContactNumber: '',
      editEntryIndex: null,
      dataLoaded: false,
      processing: false,
      editDisabled: false,
      isEditEntry: false,
      error: null,
      errorLoading: false,
      errorMessage: this.t('error-msg-no-internet'),
      snackbar: false
    }
  },
  computed: {
    validEntry: function () {
      return this.emergencyContactTitle.length > 0 && this.emergencyContactNumber.length > 2
    }
  },
  created() {
    this.getEmergencyContacts()
    this.dataLoaded = true
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    t: function (key) {
      return this.$t('emergency-contacts.' + key);
    },
    getOptions() {
      return {
        sort: true,
        disabled: this.editDisabled
      }
    },
    getEmergencyContacts() {
      HTTP.get('emergency-contacts').then(function (response) {
        this.emergencyContacts = response.data
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {
        this.error = error
        this.openSnackbar()
      }.bind(this))
    },
    onSubmit() {
      if (this.processing === true) {
        return
      }
      this.processing = true

      if (this.emergencyContacts.length > 0) {

        this.emergencyContacts.forEach((element, index) => {
          element.order = index
        })

        const json = JSON.stringify({
          emergencyContactsData: this.emergencyContacts
        })

        HTTP.post('emergency-contacts', {'data': json})
            .then(function (response) {
              this.emergencyContacts = response.data
              this.close()
            }.bind(this))
            .catch(function (error) {
              this.processing = false
              if (error.response.data.errors) {
                this.errorMessage = error.response.data.message
                this.openSnackbar()
              }
            }.bind(this))
      }
    },
    addEntry() {
      this.emergencyContacts.push({
        title: this.emergencyContactTitle,
        description: this.emergencyContactDescription,
        phone_number: this.emergencyContactNumber
      })
      this.cancelEntry()
    },
    editEntry(entry, index) {
      this.emergencyContactTitle = entry.title
      this.emergencyContactDescription = entry.description
      this.emergencyContactNumber = entry.phone_number
      this.isEditEntry = true
      this.editEntryIndex = index
    },
    updateEntry() {
      this.emergencyContacts[this.editEntryIndex].title = this.emergencyContactTitle
      this.emergencyContacts[this.editEntryIndex].description = this.emergencyContactDescription
      this.emergencyContacts[this.editEntryIndex].phone_number = this.emergencyContactNumber
      this.cancelEntry()
      this.editEntryIndex = null
    },
    deleteEntry(entry, index) {
      this.emergencyContacts.splice(index, 1)
    },
    cancelEntry() {
      this.isEditEntry = false
      this.emergencyContactTitle = ''
      this.emergencyContactDescription = ''
      this.emergencyContactNumber = ''
    },
    close() {
      this.processing = false
      this.errorMessage = this.t('success-msg-saved'),
          this.openSnackbar()
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 600)
    }
  }
}
</script>

<style scoped lang="scss">

.emergency-cards {
  justify-content: space-between;

  .v-card {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
}

.emergency-description {
  margin-bottom: 28px !important;
}

.cancel-contact {
  float: left;
}

.add-contact,
.update-contact {
  float: right;
}

.entry-title {
  display: block;
  width: calc(100% - 230px);
  font-size: 16px;
  font-weight: bold;
  padding-left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.entry-description {
  position: relative;
  top: -5px;
  display: block;
  width: calc(100% - 230px);
  height: 18px;
  font-size: 14px;
  padding-left: 30px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.entry-number {
  display: block;
  position: absolute;
  right: 90px;
  top: 11px;
  font-size: 16px;
  font-weight: bold;
  background: #FFF;
  padding: 2px 6px;
  border-radius: 10px;
}

.drag-element {
  display: block;
  position: relative;
  border: 0;
  cursor: grab;
  padding: 5px 10px 2px 10px;

  .drag-indicator {
    position: absolute;
    left: 3px;
    top: 7px;
    font-size: 36px;
    color: #999;
    margin-right: 10px;
  }

  .entry-edit {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
  }

  .entry-remove {
    position: absolute;
    right: 45px;
    top: 12px;
    cursor: pointer;
  }

  .disable-drag {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }

  &.sortable-chosen {
    cursor: grabbing;
  }

  .cta-icon {
    cursor: pointer;
  }
}

.clearfix:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0px;
}

</style>

