import { render, staticRenderFns } from "./CustomerNotesStatusFilter.vue?vue&type=template&id=fd8c8b28&scoped=true"
import script from "./CustomerNotesStatusFilter.vue?vue&type=script&lang=js"
export * from "./CustomerNotesStatusFilter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd8c8b28",
  null
  
)

export default component.exports