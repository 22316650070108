const themesHgi = {
  themes: {
    light: {
      primary: '#008C5A',
      secondary: '#D5ECDF',
      success: '#4CAF50',
      warning: '#FB8C00',
      error: '#FF5252',
      buttons:'#008C5A'
    }
  },
}

export default themesHgi
