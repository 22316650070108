<template>
  <div>
    <salutation-select
      v-model="value.salutation"
    />
    <v-text-field
      :label="t('firstname')"
      v-model="value.firstname"
      data-cy="firstname"
      filled
    />
    <v-text-field
      :label="t('lastname')"
      v-model="value.lastname"
      data-cy="lastname"
      filled
    />
    <customer-edit-address v-model="value.address"/>
    <customer-edit-extension-fields v-model="value.extension_fields" address-type="private"/>
  </div>
</template>

<script>
import SalutationSelect from '@/components/SalutationSelect.vue'
import CustomerEditAddress from '@/views/CustomerEditAddress.vue'
import CustomerEditExtensionFields from '@/views/CustomerEditExtensionFields.vue'

export default {
  name: "CustomerEditPrivate",
  components: {
    CustomerEditExtensionFields, 
    CustomerEditAddress, 
    SalutationSelect
  },
  props: {
    value: Object,
  },
  data() {
    return {}
  },
  methods: {
    t: function (key) {
      return this.$t('customers.edit.private.' + key);
    },
  }
}
</script>
