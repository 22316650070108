<template>
  <div>
    <BasicCreateEditContainer
        :title="mode == 'create' ? t('create.title') : t('edit.title')"
        ref="editor"
        object-type="Instruction"
        :object-name="instructionData.title"
        :data-loaded="dataLoaded"
        @cancel="close"
        :save-action="saveInstruction"
        @saved="onSaved"
        :allow-delete="mode === 'edit'"
        :delete-action="deleteInstruction"
        @deleted="onDeleted"
        :has-changes="hasChanges"
        :deletion-hint-text="deletionHintText"
        :deletion-hint-list="deletionHintList"
    >
      <template v-slot:content>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>

            <v-text-field
                filled
                :label="t('details.title')"
                counter="120"
                v-model="instructionData.title"
                :rules="[
                  () => !!instructionData.title || $t('validation.required.title'),
                  () => (instructionData.title && instructionData.title.length <= 120) || $t('validation.length.title')
                ]"
            />

            <v-text-field
                :label="t('details.keywords')"
                v-model="instructionData.keywords"
                filled
                hide-details="auto"
            ></v-text-field>

          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.blocks-editor.label') + '*' }}</v-card-title>
          <v-card-text>
            <content-editor content-type="instructions" v-model="instructionData.blocks"/>
          </v-card-text>
          <v-card-actions>
            <v-btn
                v-if="hasNewBlocksContent"
                color="primary"
                outlined
                class="icon-left phone-preview"
                @click="showPhonePreview"
            >
              <v-icon>mdi-cellphone-check</v-icon>
              {{ $t('components.blocks-editor.preview') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>

      <template v-slot:meta>

        <v-card elevation="0">
          <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
          <v-card-text>
            <permission-group-selector
                v-model="instructionData.assigned_permission_groups"
                :required="true"
                mode="dropdown"/>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.post-publish-settings.label') }}</v-card-title>
          <v-card-text>
            <instructions-publish
                :initial-status="instructionData.status"
                :publish-at="instructionData.published_at"
                @status="setStatus"
                @publish-at="setPublishDate"
                @valid="updatePublishValid"
            />
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.post-push-settings.label') }}</v-card-title>
          <v-card-text>
            <news-push
                v-model="instructionData.push"
                :show-last-push="false"
                :can-push-now="false"
                :can-configure-push="true"
                :can-push-on-publish="true"
                @valid="updatePushValid"
            />
          </v-card-text>
        </v-card>

        <v-card v-if="mode === 'edit'" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('move-document.group-title') }}</v-card-title>
          <v-card-text>

            <div>
              <v-btn
                  elevation="0"
                  color="primary"
                  class="icon-left"
                  @click="dialogVisible=true"
              >
                <v-icon>mdi-folder-search-outline</v-icon>
                {{ t('move-document.choose-folder') }}
              </v-btn>
              <p v-if="moveToDir" class="sp-mar-top-2">{{ t('move-document.will-be-moved') }}: {{ moveToDir.name }}</p>
            </div>

          </v-card-text>
        </v-card>

        <v-card v-if="mode === 'edit' && dataLoaded" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.post-read-analytics.label') }}</v-card-title>
          <v-card-text>
            <analytics-info :get-url="getAnalyticsUrl"/>
          </v-card-text>
        </v-card>

      </template>
    </BasicCreateEditContainer>

    <MoveToFolderDialog
      :dialog="dialogVisible"
      :root-name="$t('instructions.list.title')"
      :parent-folder-id="instructionData.parent_folder_id"
      get-url="instructions_folders"
    ></MoveToFolderDialog>

    <v-dialog
        v-model="showPhonePreviewDialog"
        width="414"
        persistent
        content-class="preview-dialog custom-class-dialog"
    >
      <v-card>
        <v-card-title>
          {{ $t('components.blocks-editor.preview') }}
        </v-card-title>
        <v-card-text>
          <news-preview
              :preview-image="instructionData.thumbnail_url"
              :blocks="instructionData.blocks"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              elevation="0"
              class="icon-left"
              @click="showPhonePreviewDialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
            {{ $t('components.blocks-editor.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus.js'
import moment from 'moment'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
import ContentEditor from '@/components/ContentEditor.vue'
import PermissionGroupSelector from '@/components/PermissionGroupSelector.vue'
import InstructionsPublish from '@/views/InstructionsPublish.vue'
import NewsPush from '@/views/NewsPush.vue'
import AnalyticsInfo from '@/components/AnalyticsInfo.vue'
import NewsPreview from '@/views/NewsPreview.vue'
import MoveToFolderDialog from '@/components/dialogs/MoveToFolderDialog'
import { hasModule } from '@/services/LicenseService'

export default {
  name: 'InstructionsCreateEditInstruction',
  components: {
    NewsPreview,
    AnalyticsInfo,
    NewsPush,
    InstructionsPublish,
    PermissionGroupSelector,
    ContentEditor,
    BasicCreateEditContainer,
    MoveToFolderDialog
  },
  data() {
    return {
      instructionData: {
        id: null,
        parent_folder_id: null,
        status: 'draft',
        title: null,
        keywords: null,
        blocks: [],
        videos: [],
        assigned_permission_groups: [],
        published_at: null,
        push: {
          mode: 'onpublish',
          date: null,
        },
      },
      mode: null,
      moveToDir: null,
      deletionHintText: '',
      deletionHintList: [],
      validation: [],
      indexRouteName: 'Instructions',
      dialogVisible: false,
      dataLoaded: false,
      dataOriginal: null,
      showPhonePreviewDialog: false,
    }
  },
  created() {
    if (this.$route.name === 'InstructionsInstructionCreate') {
      const parentFolderId = this.$route.params.parent_id
      if (!isNaN(parentFolderId)) {
        this.instructionData.parent_folder_id = parentFolderId
      }
      this.mode = 'create'
      this.dataLoaded = true
    }
    if (this.$route.name === 'InstructionsInstructionEdit') {
      const id = this.$route.params.id
      if (!isNaN(id)) {
        this.mode = 'edit'
        this.getInstruction(id)
      }
    }
    EventBus.$on('move-to-dir-select', function (folder) {
      this.moveToDir = folder
      this.dialogVisible = false
    }.bind(this))
  },
  beforeDestroy() {
    EventBus.$off('move-to-dir-select')
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.instructionData) !== JSON.stringify(this.dataOriginal) || !!this.moveToDir
    },
    hasNewBlocksContent() {
      return this.instructionData.blocks && this.instructionData.blocks.length > 0
    },
    dataValid() {
      let isValid = true;
      if (this.instructionData.blocks.length == 0) {
        isValid = false;
      }
      return isValid;
    }
  },
  methods: {
    hasModule,
    t: function (key) {
      return this.$t('instructions.instruction.' + key)
    },
    phonePreview() {
      this.previewDialog = true
    },
    getInstruction(id) {
      HTTP.get('instructions/' + id).then(function (response) {
        this.instructionData = response.data
        // Prepare permission groups for select input
        const tempPermGroupsIds = []
        response.data.assigned_permission_groups.forEach((element) => {
          tempPermGroupsIds.push(element.permission_group_id)
        })
        this.instructionData.assigned_permission_groups = tempPermGroupsIds

        this.dataOriginal = JSON.parse(JSON.stringify(this.instructionData))

        this.dataLoaded = true
      }.bind(this)).catch(function (error) {
        if (error.response.status && error.response.status === 404) {
            this.$root.infoNotification.showMessage(this.$t('instructions.instruction.not-found', { instructionId: id }))
        } else {
          this.$root.infoNotification.showMessage(this.$t('instructions.instruction.unknown-error') + error.response.statusText)
        }
        this.close()
      }.bind(this))
    },
    saveInstruction() {
      if (!this.dataValid) {
        return
      }
  
      let formData = new FormData()

      let i = 0
      this.instructionData.blocks.forEach(z => {
        z.order = i++
        if (z.name === 'Video' && z.new_content) {
          formData.append('video-' + z.order, z.video)
        }
      })

      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }

      if (this.instructionData.id) {

        let data = this.instructionData;

        if (this.moveToDir != null) {
          data.parent_folder_id = this.moveToDir.folder_id;
          data.change_folder = true;
        }

        formData.append('data', JSON.stringify(this.instructionData))
        // use formData later
        return HTTP.post('instructions/' + this.instructionData.id, formData, config)
      } else {

        formData.append('data', JSON.stringify(this.instructionData))
        return HTTP.post('instructions', formData, config)
      }
    },
    onSaved() {
      this.close()
    },
    deleteInstruction() {
      HTTP.delete('instructions/' + this.instructionData.id)
        .then(function (response) {
          //
        }.bind(this))
        .catch(function (error) {
          if (error.response.data.errors) {
              this.message = error.response.data.message
            }
            console.log(error.response)
        }.bind(this))
    },
    onDeleted() {
      this.close()
    },
    close() {
      let path = '/instructions';
      if (this.instructionData.parent_folder_id) {
        path += '/' + this.instructionData.parent_folder_id
      }
      this.$router.push(path)
    },
    showPhonePreview() {
      this.showPhonePreviewDialog = true
    },
    updatePublishValid(val) {
      this.validation.validPublish = val
    },
    updatePushValid(val) {
      this.validation.validPush = val
    },
    setPublishDate(val) {
      this.instructionData.published_at = moment(val) > moment() ? val : moment()
    },
    setStatus(val) {
      this.instructionData.status = val
    },
    getAnalyticsUrl() {
      return 'analytics/instructions/' + this.instructionData.id
    },
  }
}
</script>
