<template>
  <div>

    <toolbar :dataLoaded="dataLoaded">{{ $t("profile.title") }}</toolbar>

    <v-container fluid class="edit-screen">
      <loading :dataLoaded="dataLoaded"/>

      <div v-if="dataLoaded">
        <v-card elevation="0">
          <v-card-title>{{ $t("profile.user-information") }}</v-card-title>
          <v-card-text>

            <div>{{ $t("profile.username") }} {{ username }}</div>
          </v-card-text>
        </v-card>

        <v-card elevation="0" v-if="ical_link" class="mt-8">
          <v-card-title>{{ $t("profile.absence-calendar") }}</v-card-title>
          <v-card-text>

            <div>
              <p>{{ ical_link }}</p>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import toolbar from "../components/layouts/Navigation.vue";
import loading from "../components/layouts/Loading.vue";

export default {
  name: "ProfileIndex",
  components: {
    toolbar,
    loading,
  },
  data() {
    return {
      username: '',
      dataLoaded: false,
      ical_link: null,
    }
  },
  async created() {
    await this.loadData()

  },
  methods: {
    async loadData() {
      let response = await HTTP.get('/me')
      this.username = response.data.username
      this.ical_link = response.data.absence.ical_link
      this.dataLoaded = true
    }
  }
}
</script>

<style scoped>

</style>
