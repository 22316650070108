const themesZwe = {
  themes: {
    light: {
      primary: '#79B93A',
      secondary: '#DDEDCE',
      success: '#4CAF50',
      warning: '#FB8C00',
      error: '#FF5252',
      buttons:'#79B93A'
    }
  },
}

export default themesZwe