<template>
  <div class="dashboard-card">

    <v-card elevation="0">

      <v-card-title>
        <v-icon>mdi-store</v-icon>
        {{ $t('dashboard.app-stores.title') }}
      </v-card-title>

      <v-card-text>
        <div v-if="appStoreUrlsLoaded">
          <v-tabs v-model="tab" bg-color="primary">
            <v-tab>
            <b>{{ $t('dashboard.app-stores.android') }}</b>
            </v-tab>
            <v-tab >
            <b>{{ $t('dashboard.app-stores.ios') }}</b>
            </v-tab>
          </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-container v-if="!urlAvailableAndroid">
                  <v-row>
                    <h2>{{ $t('dashboard.app-stores.url-not-found') }}</h2>
                  </v-row>
                </v-container>
                <v-container v-if="urlAvailableAndroid">
                  <v-row>
                    <v-col style="width: 50%; min-width: 150px" class="ml-0 pl-0">
                      <v-img width="150" height="150" :aspect-ratio="1/1" :src="qrcodeprefix+storeUrls.android"></v-img>
                    </v-col>
                    <v-col style="width: 50%">
                      <a :href="storeUrls.android">{{ storeUrls.android }}</a>
                      <br>
                      <v-btn plain @click="copyAndroidUrl" outlined small color="primary" class="mt-2">
                        <v-icon small>mdi-content-copy</v-icon>
                        {{ $t('dashboard.app-stores.copy-url') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item >
                <v-container v-if="!urlAvailableIOS">
                  <v-row>
                    <h2>{{ $t('dashboard.app-stores.url-not-found') }}</h2>
                  </v-row>
                </v-container>
                <v-container v-if="urlAvailableIOS">
                  <v-row>
                    <v-col style="width: 50%; min-width: 150px" class="ml-0 pl-0">
                      <v-img width="150" height="150" :src="qrcodeprefix+storeUrls.ios"></v-img>
                    </v-col>
                    <v-col style="width: 50%">
                      <a :href="storeUrls.ios">{{ storeUrls.ios }}</a><br/>
                      <v-btn plain @click="copyIosUrl" outlined small color="primary" class="mt-2">
                        <v-icon small>mdi-content-copy</v-icon>
                        {{ $t('dashboard.app-stores.copy-url') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
        </div>
        <div v-if="!appStoreUrlsLoaded" class="analytics-loading-wrapper">
          <span class="analytics-loading"></span>
        </div>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import { HTTP } from '@/auth'

export default {
  name: "DashboardStoreUrls",
  data() {
    return {
      qrcodeprefix: 'https://quickchart.io/chart?cht=qr&chs=150x150&chl=',
      storeUrls: {
        android: '',
        ios: '',
      },
      appStoreUrlsLoaded: false,
      urlAvailableIOS: true,
      urlAvailableAndroid: true,
      urlAvailable: true,
      tab: 0
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      let response = await HTTP.get('settings/global')
      this.storeUrls.android = response.data.app_url_android
      this.storeUrls.ios = response.data.app_url_ios
      this.appStoreUrlsLoaded = true
      await this.showAppStore()
    },
    async copyAndroidUrl() {
      await navigator.clipboard.writeText(this.storeUrls.android)
    },
    async copyIosUrl() {
      await navigator.clipboard.writeText(this.storeUrls.ios)
    },
    async showAppStore() {
      this.urlAvailableIOS = this.storeUrls.ios !== '';
      this.urlAvailableAndroid = this.storeUrls.android !== '' ;
      this.urlAvailable = this.urlAvailableIOS || this.urlAvailableAndroid;
    },
  }
}
</script>
