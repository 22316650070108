<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        elevation="0"
        class="icon-left"
        :disabled="processing"
        :loading="processing"
        @click="validateBeforeOpening"
      >
        <v-icon>mdi-check</v-icon>
        Speichern
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5">
        Anpassungen anwenden?
      </v-card-title>
      <v-card-text>{{ $t('components.cancel-button.confirmation-question-message') }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          elevation="0"
          text
          @click="show = false"
        >
          <v-icon>mdi-close</v-icon>
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          @click="$emit('confirmed', true); show = false"
        >
          <v-icon>mdi-check</v-icon>
          Bestätigen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AppDialogConfirmSave",
  data() {
    return {
      show: false,
      formValid: false
    }
  },
  props: {
    validateForm: null,
    processing: false
  },
  methods: {
    validateBeforeOpening() {
      if(this.validateForm && this.validateForm.validate()) {
        this.show = true
      } else if (!this.validateForm) {
        this.show = true
      }
    }
  }
}
</script>
