<template>
  <div>

    <admin-container class="edit-screen">

      <h2 v-if="data.name">{{title}} «{{data.name}}» {{ t('change') }}<span v-if="type === 'category'">| {{data.customer.name}}</span></h2>
      <md-layout class="sp-mar-bot-3 md-gutter" md-flex="100" v-if="dataLoaded">

        <md-layout class="md-gutter">

          <md-layout md-flex="50" class="sp-mar-bot-3 sp-flex-align-self-start">
            <div class="sp-flex-1">
              <md-layout md-flex="100">
                <div class="sp-flex-1 edit-list">
                  <div class="sp-pad-2 sp-flex-1 md-subheading edit-list-title">{{ t('description') }}</div>
                  <div class="sp-pad-2">
                    <div>
                      <md-input-container>
                        <label>{{ t('name') }}</label>
                        <md-input type="text" v-model="data.name"></md-input>
                      </md-input-container>
                    </div>
                  </div>
                </div>
              </md-layout>
              <!--
              <md-layout class="sp-mar-top-3" md-flex="100">
                <div class="sp-flex-1 edit-list">
                  <div class="sp-pad-2 md-subheading edit-list-title">Zugriffsrechte</div>
                  <div class="sp-pad-2">
                    <div>
                      <md-input-container>
                        <label>Benutzer suchen</label>
                        <md-input type="text" v-model="searchUser"></md-input>
                      </md-input-container>
                    </div>
                    <div v-if="selected.users.length !== 0" v-for="(user, index) in selected.users" :key="user.id" class="search-items item-selected search-multi">
                      <span>{{user.firstname}} {{user.name}}</span>
                      <div class="sp-float-right"><md-icon class="sp-text-white" @click.native="removeItem(user, index)">remove_circle</md-icon> </div>
                    </div>
                    <div class="search-items" v-for="(item, index) in users" :key="item.id" @click="selectItem(item, index)" v-if="searchUser !== ''">{{item.firstname}} {{item.name}}</div>
                  </div>
                </div>
              </md-layout>
              -->
            </div>
          </md-layout>
          <!--
          <md-layout md-flex="50" class="sp-flex-align-self-start">
            <md-layout  md-flex="100">
              <div class="sp-flex-1 edit-list">
                <div class="sp-pad-2 sp-flex-1 md-subheading edit-list-title">Qualitätskontrolle</div>
                <div class="sp-pad-2">
                  <div class="md-body-2">Mindest Qualitätswert</div>
                  <div class="sp-pad-bot-2">
                    <range :target="'quality-warning-' + data.id" :min="0" :max="100" :value="data.quality_warning"></range>
                    <div v-if="data.quality_warning > 0">Benachrichtigung bei einer Qualitätswert-Unterschreitung von <span>{{data.quality_warning}}</span>%</div>
                  </div>
                </div>

                <div class="sp-pad-2">
                  <div class="md-body-2">Benachrichtigungen</div>
                  <div>
                    <md-input-container>
                      <label>E-Mail-Adresse hinzufügen</label>
                      <md-input type="email" v-model="email_warning"></md-input>
                    </md-input-container>
                  </div>

                  <div v-if="notifications_warning.emails.length > 0" v-for="(email, index) in notifications_warning.emails" :key="email" class="search-items item-selected search-multi">
                    <span>{{email}}</span>
                    <div class="sp-float-right"><md-icon class="sp-text-white" @click.native="removeEmail(email, 'warning')">remove_circle</md-icon> </div>
                  </div>

                  <div v-if="email_warning.length > 0" class="sp-mar-2 sp-mar-top-2 sp-mar-bot-3 sp-mar-r-0 sp-pad-bot-3">
                    <md-button class="sp-mar-r-0 md-accent sp-float-right" @click.native="addEmail('warning')">hinzufügen</md-button>
                  </div>
                </div>

                <md-divider></md-divider>

                <div class="sp-pad-2">
                  <div class="md-body-2">Ziel Qualitätswert</div>
                  <div>
                    <range :target="'quality-requirement-' + data.id" :min="0" :max="100" :value="data.quality_requirement"></range>
                    <div v-if="data.quality_requirement > 0">Benachrichtigung bei einer Qualitätswert-Unterschreitung von <span>{{data.quality_requirement}}</span>%</div>
                  </div>
                  <div>
                  </div>
                </div>

                <div class="sp-pad-2">
                  <div class="md-body-2">Benachrichtigungen</div>
                  <div>
                    <md-input-container>
                      <label>E-Mail-Adresse hinzufügen</label>
                      <md-input type="email" v-model="email_requirement"></md-input>
                    </md-input-container>
                  </div>

                  <div v-if="notifications_requirement.emails.length > 0" v-for="(email, index) in notifications_requirement.emails" :key="email" class="search-items item-selected search-multi">
                    <span>{{email}}</span>
                    <div class="sp-float-right"><md-icon class="sp-text-white" @click.native="removeEmail(email, 'requirement')">remove_circle</md-icon> </div>
                  </div>

                  <div v-if="email_requirement.length > 0" class="sp-mar-2 sp-mar-top-2 sp-mar-bot-3 sp-mar-r-0 sp-pad-bot-3">
                    <md-button class="sp-mar-r-0 md-accent sp-float-right" @click.native="addEmail('requirement')">hinzufügen</md-button>
                  </div>
                </div>

              </div>
            </md-layout>
          </md-layout>
-->
        </md-layout>

      </md-layout>
    </admin-container>

    <md-snackbar ref="snackbar" :md-duration="4000">
      <span>{{ errorMessage }}</span>
    </md-snackbar>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <admin-container class="sp-text-white">
          <transition name="fade">
            <div @click="cancel()" class="bottom-navigation-back md-list-item-container">
              <div class="sp-float-left">
                <md-icon>cancel</md-icon>
                <span>{{t('cancel')}}</span>
              </div>
            </div>
          </transition>
          <div class="bottom-navigation-next md-list-item-container">
            <transition name="fade">
              <div @click="submit()" class="sp-float-right">
                <span>{{ t('save') }}</span>
                <md-icon>save</md-icon>
              </div>
            </transition>
          </div>
        </admin-container>
      </div>
    </transition>
  </div>

</template>

<script>
  import {HTTP} from './../auth'
  import axios from 'axios'
  import {EventBus} from './../event-bus.js'
  import toolbar from '../components/layouts/Navigation.vue'
  import range from './../components/inputs/RangeInput.vue'
  import _ from 'lodash'

  export default {
    components: {
      toolbar,
      range
    },
    data () {
      return {
        data: {
          id: null,
          name: null,
          assigned_users: [],
          notifications: [],
          notifications_requirement: [],
          notifications_warning: [],
          quality_requirement: 85,
          quality_warning: 85
        },
        notifications_warning: {
          emails: []
        },
        notifications_requirement: {
          emails: []
        },
        selected: {
          users: []
        },
        email_warning: '',
        email_requirement: '',
        notificationType: '',
        searchUser: '',
        users: null,
        type: '',
        error: null,
        errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbndung und versuchen Sie es nochmal.',
        cancelHTTP: null,
        errorCancel: null,
        dataLoaded: false
      }
    },
    props: {
      itemType: null,
      title: null,
      itemData: null
    },
    created () {
      this.type = this.itemType
    },
    mounted () {
      this.setData()
      EventBus.$on('quality-requirement-' + this.data.id, function (msg) {
        this.data.quality_requirement = msg
      }.bind(this))
      EventBus.$on('quality-warning-' + this.data.id, function (msg) {
        this.data.quality_warning = msg
      }.bind(this))
      EventBus.$on('quality-warning-' + this.data.id, function (msg) {
        this.data.quality_warning = msg
      }.bind(this))
    },
    beforeDestroy () {
      EventBus.$off('quality-warning-' + this.data.id)
      EventBus.$off('quality-requirement-' + this.data.id)
    },
    watch: {
      searchUser: function (val) {
        this.getSearchResult(encodeURIComponent(val))
      }
    },
    methods: {
      t: function (key) {
        return this.$t('edit.list.' + key);
      },
      setData () {
        this.data.id = this.itemData.id
        this.data.name = this.itemData.name
        this.data.notifications = this.itemData.notifications
        this.data.notifications_warning = this.itemData.notifications_requirement
        this.data.notifications_warning = this.itemData.notifications_warning
        this.data.quality_warning = this.itemData.quality_warning
        this.data.quality_requirement = this.itemData.quality_requirement
        this.loadSelectedUsers()
        this.loadNotifications('warning')
        this.loadNotifications('requirement')
        this.setNotificationType(this.type)
        this.dataLoaded = true
      },
      loadSelectedUsers () {
        for (var item in this.itemData.assigned_users) {
          this.selected.users.push(this.itemData.assigned_users[item])
          this.data.assigned_users.push(this.itemData.assigned_users[item].id)
        }
      },
      loadNotifications (type) {
        for (var items in this.itemData['notifications_' + type]) {
          this['notifications_' + type].emails.push(this.itemData['notifications_' + type][items].email)
        }
      },
      checkDuplicatedUsers (item) {
        if (!this.inArray(item.id, this.data.assigned_users)) {
          this.data.assigned_users.push(item.id)
          this.selected.users.push(item)
        }
      },
      getSearchResult (val) {
        if (this.cancelHTTP) {
          this.cancelHTTP()
        }
        HTTP.get('users?q=' + val, {
          cancelToken: new axios.CancelToken(function executor (c) {
            this.cancelHTTP = c
          }.bind(this))
        }).then(function (response) {
          this.users = response.data
        }.bind(this)).catch(function (error) { this.errorCancel = error }.bind(this))
      },
      selectItem (item) {
        this.checkDuplicatedUsers(item)
        this.searchUser = ''
      },
      removeItem (item, index) {
        this.data.assigned_users.splice(this.data.assigned_users.indexOf(item.id), 1)
        this.selected.users.splice(index, 1)
      },
      addEmail (type) {
        let searchTermType = 'email_' + type
        this['notifications_' + type].emails.push(this[searchTermType])
        this.checkDuplicates(type)
        this[searchTermType] = ''
      },
      removeEmail (item, type) {
        this['notifications_' + type].emails.splice(this['notifications_' + type].emails.indexOf(item), 1)
      },
      checkDuplicates (type) {
        this['notifications_' + type].emails = _.uniqBy(this['notifications_' + type].emails, function (e) {
          return e
        })
      },
      submit () {
        HTTP.patch(this.type + '/' + this.data.id, this.data).then(function (response) {
          this.data.id = response.data.id
          this.data.assigned_users = response.data.assigned_users
          this.submitNotifications(response.data.id)
        }.bind(this)).catch(function (error) {
          this.error = error
          this.openSnackbar()
        }.bind(this))
      },
      submitNotifications (id) {
        HTTP.post('notifications/' + this.notificationType + '/' + id + '/warning', this.notifications_warning).then(function (response) {
          this.data.notifications_warning = response.data
          HTTP.post('notifications/' + this.notificationType + '/' + id + '/requirement', this.notifications_requirement).then(function (response) {
            this.data.notifications_requirement = response.data
            this.close()
          }.bind(this))
        }.bind(this))
      },
      setNotificationType () {
        if (this.type === 'groups' || this.type === 'group') {
          this.notificationType = 'groups'
          return true
        }
        if (this.type === 'categories' || this.type === 'category') {
          this.notificationType = 'categories'
        }
      },
      openSnackbar () {
        let that = this
        setTimeout(() => {
          that.$refs.snackbar.open()
        }, 2000)
      },
      close () {
        var data = {
          message: '«' + this.data.name + '» wurde erfolgreich gespeichert.',
          item: this.data
        }
        EventBus.$emit('update-' + this.type, data)
      },
      cancel () {
        EventBus.$emit('cancel')
      },
      inArray (needle, haystack) {
        var length = haystack.length
        for (var i = 0; i < length; i++) {
          if (haystack[i] === needle) {
            return true
          }
        }
        return false
      }
    }
  }
</script>

<style scoped>

  h2 span {
    font-weight: normal;
    font-size: 80%;
  }

</style>
