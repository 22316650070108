<template>
  <div>
    <BasicSettingsContainer
        :title="$t('global-client.title')"
        :data-loaded="dataLoaded"
        :has-changes="hasChanges"
        @reset="reset"
        :save-action="save"
    >

      <v-card elevation="0" class="sp-mar-top-3">
        <v-card-title>{{ $t('global-client.block-title') }}</v-card-title>
        <v-card-text>
          <div>
            <v-radio-group v-model="item.show_all_clients">
              <v-radio
                  :label="$t('global-client.show-all-clients')"
                  value="yes"
              />
              <v-radio
                  :label="$t('global-client.show-connected-users')"
                  value="no"
              />
            </v-radio-group>
          </div>
        </v-card-text>
      </v-card>

      <v-card elevation="0" class="sp-mar-top-3">
        <v-card-title>{{ $t('global-expenses.block-title') }}</v-card-title>
        <v-card-text>
          <div>
            <v-radio-group v-model="item.show_all_expenses_users">
              <v-radio
                  :label="$t('global-expenses.show-all-users')"
                  value="yes"
              />
              <v-radio
                  :label="$t('global-expenses.show-connected-users')"
                  value="no"
              />
            </v-radio-group>
          </div>
        </v-card-text>
      </v-card>


    </BasicSettingsContainer>

  </div>

</template>

<script>
import {HTTP} from './../auth'
import toolbar from '../components/layouts/Navigation.vue'
import loading from '../components/layouts/Loading.vue'
import AppDialogConfirmSave from "../components/vuetify/AppDialogConfirmSave";
import BasicSettingsContainer from "../components/inputs/BasicSettingsContainer.vue";

export default {
  name: 'SettingsGlobal',
  components: {
    BasicSettingsContainer,
    toolbar,
    loading,
    AppDialogConfirmSave
  },
  data() {
    return {
      item: {
        show_all_clients: null,
        show_all_expenses_users: null
      },
      itemOriginal: null,
      dataLoaded: false,
    }
  },
  async created() {
    await this.getData()
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
  },

  methods: {
    async getData() {
      this.dataLoaded = false
      let response = await HTTP.get('settings/client')
      this.item.show_all_clients = response.data.show_all_clients
      this.item.show_all_expenses_users = response.data.show_all_expenses_users
      
      this.itemOriginal = JSON.parse(JSON.stringify(this.item));
      this.dataLoaded = true
    },
    async reset() {
      await this.getData()
    },
    async save() {
      await HTTP.put('settings/client', this.item)
      await this.getData()
    },
  }
}
</script>

<style scoped lang="scss">

.settings-wrapper {
  justify-content: space-between;

  .v-card {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
}

</style>
