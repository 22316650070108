<template>
  <div v-if="dataLoaded">
    <basic-new-container
      title="News"
      ref="editor"
      @cancel="cancel"
      :saveAction="saveItem"
      :has-changes="hasChanges"
      >
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>

            <v-text-field
              filled
              label="Titel*"
              counter="120"
              v-model="newsData.title"
              :rules="[
                () => !!newsData.title || $t('validation.required.title'),
                () => (newsData.title && newsData.title.length <= 120) || $t('validation.length.title')
              ]"
            />

            <v-text-field
              label="Kurze Beschreibung (Preview)*"
              counter="120"
              v-model="newsData.preview_text"
              filled
              :rules="[
                () => !!newsData.preview_text || $t('validation.required.description'),
                () => (newsData.preview_text && newsData.preview_text.length <= 120) || $t('validation.length.description')
              ]"
            />

          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('thumbnail.group-title') + '*' }}</v-card-title>
          <v-card-text>
            <div>
              <picture-upload
                :show-preview="true"
                :required="true"
                :initial-picture="newsData.picture"
                v-model="newsData.picture"
                control-id="news-preview"/>
            </div>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.blocks-editor.label') + '*' }}</v-card-title>
          <v-card-text>
            <content-editor content-type="news" v-model="newsData.blocks"/>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="hasNewBlocksContent"
              color="primary"
              outlined
              class="icon-left phone-preview"
              @click="showPhonePreview"
            >
              <v-icon>mdi-cellphone-check</v-icon>
              {{ $t('components.blocks-editor.preview') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ $t('components.category-select.label') + '*' }}</v-card-title>
          <v-card-text>
            <news-category-selector
              v-model="newsData.assigned_category"/>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
          <v-card-text>
            <permission-group-selector
              v-model="newsData.assigned_permission_groups"
              :required="true"
              mode="dropdown"/>

          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.post-publish-settings.label') }}</v-card-title>
          <v-card-text>
            <news-publish
              initial-status="draft"
              :publish-at="newsData.publish_at"
              :auto-archive-date="newsData.auto_archive_at"
              @status="setStatus"
              @publish-at="setPublishDate"
              @archive-at="setArchiveDate"
              @valid="updatePublishValid"
            />
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.post-push-settings.label') }}</v-card-title>
          <v-card-text>
            <news-push
              v-model="newsData.push"
              :show-last-push="false"
              :can-push-now="false"
              :can-configure-push="canConfigurePush"
              :can-push-on-publish="true"
              :push-not-before="minPushDate"
              @valid="updatePushValid"
            />
          </v-card-text>
        </v-card>

        <v-card v-if="hasModule('documents_explorer')" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.link-documents.label') }}</v-card-title>
          <v-card-text>
            <news-document-selector
              v-model="newsData.documents"/>
          </v-card-text>
        </v-card>
      </template>
    </basic-new-container>
    <v-dialog
      v-model="showPhonePreviewDialog"
      width="414"
      persistent
      content-class="preview-dialog custom-class-dialog"
    >
      <v-card>
        <v-card-title>
          {{ $t('components.blocks-editor.preview') }}
        </v-card-title>
        <v-card-text>
          <img v-if="newsData.picture !== ''" :src="newsData.picture">
          <news-preview
            :preview-image="newsData.thumbnail_url"
            :blocks="newsData.blocks"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            elevation="0"
            class="icon-left"
            @click="showPhonePreviewDialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
            {{ $t('components.blocks-editor.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { hasModule } from '@/services/LicenseService'
import { Buffer } from 'buffer'
import moment from 'moment'
import AppDateTimePicker from '@/components/vuetify/AppDateTimePicker'
import BasicNewContainer from '@/components/inputs/BasicNewContainer'
import PictureUpload from '@/components/inputs/PictureUpload'
import ContentEditor from '@/components/ContentEditor'
import NewsCategorySelector from '@/views/NewsCategorySelector'
import PermissionGroupSelector from '@/components/PermissionGroupSelector'
import NewsPublish from '@/views/NewsPublish'
import NewsPush from '@/views/NewsPush'
import NewsDocumentSelector from '@/views/NewsDocumentSelector'
import NewsPreview from '@/views/NewsPreview'

export default {
  name: 'NewsCreate',
  components: {
    NewsPreview,
    NewsDocumentSelector,
    NewsPush,
    NewsPublish,
    PermissionGroupSelector,
    NewsCategorySelector,
    ContentEditor,
    PictureUpload,
    BasicNewContainer,
    AppDateTimePicker,
  },
  data() {
    return {
      newsData: {
        status: 'draft',
        title: null,
        preview_text: null,
        blocks: [],
        videos: [],
        picture: '',
        assigned_category: null,
        assigned_permission_groups: [],
        documents: [],
        publish_at: null,
        auto_archive_at: null,
        push: {
          mode: 'onpublish',
          date: null,
        },
      },
      validation: [],
      indexRouteName: 'News',
      dataLoaded: false,
      showPhonePreviewDialog: false,
    }
  },
  emits: ['cancel'],
  async created() {
    let copyId = this.$route.query.fromId
    if (copyId) {
      await this.copyFrom(copyId)
    } else
      this.dataLoaded = true
  },
  computed: {
    hasChanges() {
      return true
    },
    hasNewBlocksContent() {
      return this.newsData.blocks.length > 0
    },
    minPushDate() {
      return this.newsData.publish_at
          ? moment(this.newsData.publish_at)
          : moment()
    },
    canConfigurePush() {
      return this.newsData.status === 'publishat'
          || this.newsData.status === 'published'
    },
    dataValid() {
      let isValid = true;
      if (this.newsData.picture.length == 0) {
        isValid = false;
      }

      if (this.newsData.blocks.length == 0) {
        isValid = false;
      }

      return isValid;
    }
  },
  methods: {
    hasModule,
    t: function (key) {
      return this.$t('news.detail.' + key)
    },
    async copyFrom(sourceId) {
      await this.copyMainData(sourceId)
      await this.copyPreviewImage(sourceId)
      await this.copyBlocks()
      this.dataLoaded = true
    },
    async copyMainData(sourceId) {
      let response = await HTTP.get('news/' + sourceId)
      this.newsData.title = response.data.data.title
      this.newsData.preview_text = response.data.data.preview_text
      this.newsData.blocks = response.data.data.blocks
      this.newsData.assigned_category = response.data.data.assigned_category
      this.newsData.assigned_permission_groups = response.data.data.assigned_permission_groups
    },
    async copyPreviewImage(sourceId) {
      let responsePreview = await HTTP.get('news/thumb/' + sourceId, {responseType: 'arraybuffer'})
      this.newsData.picture = 'data:image/jpeg;base64,' + new Buffer(responsePreview.data, 'binary').toString('base64')
    },
    async copyBlocks() {
      await this.newsData.blocks.forEach(async (e) => {
        if (e.name === 'Bild') {
          let picResponse = await HTTP.get('news/image/' + e.id, {responseType: 'arraybuffer'})
          e.new_content = 'data:image/jpeg;base64,' + new Buffer(picResponse.data, 'binary').toString('base64')
        }
      })
    },
    phonePreview() {
      this.previewDialog = true
    },
    async submit() {      
      if (!this.dataValid) {
        return
      }
  
      let formData = new FormData()

      let i = 0
      this.newsData.blocks.forEach(z => {
        z.order = i++
        if (z.name === 'Video' && z.new_content) {
          formData.append('video-' + z.order, z.video)
        }
      })

      formData.append('data', JSON.stringify(this.newsData))

      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }
      await HTTP.post('news', formData, config)
      this.close()
    },
    cancel() {
      this.close()
    },
    saveItem() {
      return this.submit()
    },
     close() {
       this.$router.push({ name: 'News' })
    },
    showPhonePreview() {
      this.showPhonePreviewDialog = true
    },
    updatePublishValid(val) {
      this.validation.validPublish = val
    },
    updatePushValid(val) {
      this.validation.validPush = val
    },
    setPublishDate(val) {
      this.newsData.publish_at = moment(val) > moment() ? val : moment()
    },
    setArchiveDate(val) {
      this.newsData.auto_archive_at = val
    },
    setStatus(val) {
      this.newsData.status = val
      if (val === 'draft') {
        this.newsData.push.mode = 'off'
        this.newsData.push.date = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.phone-preview,
.phone-preview-bottom {
  float: right;
}
</style>
