import { Node } from 'tiptap'

export default class Iframe extends Node {
  get name () {
    return 'iframe'
  }

  get schema () {
    return {
      attrs: {
        src: {
          default: null
        }
      },
      group: 'block',
      selectable: false,
      parseDOM: [
        {
          tag: 'iframe',
          getAttrs: dom => ({
            src: dom.getAttribute('src').replace(/\?rel=0/g, '')
          })
        }
      ],
      toDOM: node => ['div', {class: 'video-full-width'}, ['div', {class: 'resp-iframe iframe-container ratio-16-9'}, [
        'iframe',
        {
          src: (node.attrs.src.indexOf('https://www.youtube.com/embed/') !== -1 ? `${node.attrs.src}` + '?rel=0' : `https://www.youtube.com/embed/${node.attrs.src}`+ '?rel=0'),
          frameborder: 0,
          allowfullscreen: 'true',
          allow: 'accelerometer autoplay encrypted-media gyroscope picture-in-picture'
          // You can set the width and height here also
        }
      ]]]
    }
  }

  // return a vue component
  // this can be an object or an imported component
  // get view () {
  //   return {
  //     // there are some props available
  //     // `node` is a Prosemirror Node Object
  //     // `updateAttrs` is a function to update attributes defined in `schema`
  //     // `view` is the ProseMirror view instance
  //     // `options` is an array of your extension options
  //     // `selected` is a boolean which is true when selected
  //     // `editor` is a reference to the TipTap editor instance
  //     // `getPos` is a function to retrieve the start position of the node
  //     // `decorations` is an array of decorations around the node
  //     props: ['node', 'updateAttrs', 'view'],
  //     computed: {
  //       src: {
  //         get () {
  //           console.log(this.node.attrs.src)
  //           return 'https://www.youtube.com/embed/' + this.node.attrs.src
  //         },
  //         set (src) {
  //           console.log(src)
  //           // we cannot update `src` itself because `this.node.attrs` is immutable
  //           this.updateAttrs({
  //             src
  //           })
  //         }
  //       }
  //     },
  //     template: `
  //       <div class="resp-iframe iframe-container ratio-16-9">
  //         <iframe class="iframe__embed" :src="src" ></iframe>
  //       </div>
  //     `
  //   }
  // }

  commands ({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state
      const position = selection.$cursor
        ? selection.$cursor.pos
        : selection.$to.pos
      const node = type.create(attrs)
      const transaction = state.tr.insert(position, node)
      dispatch(transaction)
    }
  }
}
