<template>
  <div class="order-status text-center">

    <div v-if="status === 'new'" class="status-new">
      <v-icon>mdi-plus-circle-outline</v-icon>
    </div>

    <div v-if="status === 'processing'" class="status-processing">
        <v-icon>mdi-clock-time-four-outline</v-icon>
    </div>

    <div v-if="status === 'ready'" class="status-ready">
        <v-icon>mdi-check-circle-outline</v-icon>
    </div>

    <div v-if="status === 'delivered'" class="status-delivered">
      <v-icon>mdi-truck-delivery-outline</v-icon>
    </div>

    <div v-if="status === 'cancelled'" class="status-cancelled">
        <v-icon>mdi-cancel</v-icon>
    </div>

    <div v-if="status === 'completed'" class="status-completed">
        <v-icon>mdi-check-all</v-icon>
    </div> 

  </div>
</template>
  
<script>
  export default {
    name: "StatusIcons",
    props: {
      status: String
    }
  }
  </script>
  
  <style scoped lang="scss">
  span {
    width: 100%;
  }

  .status-new i {
    color: #2196F3;
  }

  .status-processing i {
    color: #FB8C00;
  }

  .status-ready i,
  .status-delivered i {
    color: #49894B;
  }

  .status-cancelled i {
    color: #9E9E9E;
  }

  .status-completed i {
    color: #000000;
  }

  .status i,
  .status-new i,
  .status-ready i,
  .status-delivered i,
  .status-processing i,
  .status-completed i,
  .status-cancelled i {
    padding-top:15px;
    padding-bottom:15px;
    font-size: 30px;
    position: relative;
  }
</style>
  