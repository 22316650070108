import GroupsIndex from "../../views/GroupsIndex";

const groupsRoutes = [
    {
        path: '/groups',
        name: 'Groups',
        component: GroupsIndex,
        meta: {
            accessConfig:  {
                moduleName: null,
                requiresAuth: true
            }
        }
    },
]

export default groupsRoutes
