<template>
  <v-select
      class=""
      v-model="selectedFilterStatus"
      :label="$t('components.survey-status-filter.label')"
      :items="filterStatus"
      item-text="name"
      item-value="slug"
      multiple
      chips
  >
  </v-select>

</template>

<script>
export default {
  name: "SurveyStatusFilter",
  props: ['value'],
  data() {
    return {
      maxDisplay: 3,
      selectedFilterStatus: this.value,
    }
  },
  computed: {
    filterStatus() {
      return [
        {
          name: this.$t('surveys.data.status.draft'),
          slug: 'draft'
        },
        {
          name: this.$t('surveys.data.status.published'),
          slug: 'published'
        },
        {
          name: this.$t('surveys.data.status.running'),
          slug: 'running'
        },
        {
          name: this.$t('surveys.data.status.finished'),
          slug: 'finished'
        }
      ];
    },
  },
  watch: {
    selectedFilterStatus: function (val) {
      this.$emit('input', val);
    }
  },
  created() {
    let me = this;
    this.filterStatus.forEach(function (value) {
      value.name = me.$t('surveys.data.status.' + value.slug);
    });
  }
}
</script>

<style scoped>

</style>
