<template>
    <div>
        <picture-upload
                v-model="picture"
                :initial-picture="initialPicture"
                :control-id="'content-block-picture' + index"
                :aspectShow="true"
        />
    </div>
</template>
<script>
import PictureUpload from "@/components/inputs/PictureUpload.vue";
import {HTTP} from "@/auth";
import {Buffer} from "buffer";

export default {
    name: "ContentEditorBlockImage",
    components: {
        PictureUpload,
    },
    props: {
        value: {},
        index: {},
        contentType: {
            default: 'news'
        }
    },
    data() {
        return {
            block: this.value,
            initialPicture: null,
            picture: null,
        }
    },
    watch: {
        picture(val) {
            this.block.new_content = val
        }
    },
    async created() {
        if (this.value.id) {
            let response = await HTTP.get(this.contentType + '/image/' + this.block.id, {responseType: 'arraybuffer'})
            this.initialPicture = 'data:image/jpeg;base64,' + new Buffer(response.data, 'binary').toString('base64')
        }
    }
}
</script>


<style scoped lang="scss">

</style>
