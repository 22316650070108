<template>
    <v-select
        class=""
        v-model="selectedFilterStatus"
        :label="$t('components.qc-notifications-rating.status-filter.label')"
        :items="filterStatus"
        item-text="title"
        item-value="id"
        chips
        :data-cy="dataCy"
    ></v-select>
</template>

<script>
import { HTTP } from '@/auth'

export default {
    name : "QCSubjectAreaFilter",
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
      dataCy: {
        default: ''
      },
        model: {
            type: [Number, Array],
            default: () => [null]       
        },
    },
    data() {
        return {
            selectedFilterStatus: null,
            filterStatus: [],
        }
    },
    watch: {
        selectedFilterStatus: function (val) {
            this.$emit('input', val)
        },
        model: function(val) {
            this.selectedFilterStatus = val
        }
    },
    created() {
        this.getSubjectAreas()
    },
    methods: {
        async getSubjectAreas() {
            await HTTP.get('subject_areas').then((response) => {
                this.filterStatus = response.data
            })
        }
    }
}
</script>