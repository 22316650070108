<template>
  <div>
    <BasicCreateEditContainer
        :title="mode == 'create' ? t('create.title') : t('edit.title')"
        ref="editor"
        object-type="Element"
        :object-name="subtask.name"
        :data-loaded="dataLoaded"
        @cancel="close"
        :save-action="saveSubtask"
        @saved="onSaved"
        :allow-delete="mode == 'edit'"
        :delete-action="deleteSubtask"
        @deleted="onDeleted"
        :has-changes="hasChanges"
        :deletion-hint-text="deletionHintText"
        :deletion-hint-list="deletionHintList"
    >
      <template v-slot:content>

        <v-card elevation="0">
          <v-card-title>{{ t('create.card-title') }}</v-card-title>
          <v-card-text>

            <v-text-field
                :label="t('create.placeholder')"
                v-model="subtask.name"
                counter="40"
                filled
                :rules="[
                  () => !!subtask.name || t('validation.error-name-required'),
                  () => (subtask.name && subtask.name.length <= 40) || t('validation.error-max-name-40')
                ]"
            ></v-text-field>

          </v-card-text>
        </v-card>

      </template>

    </BasicCreateEditContainer>
  </div>
</template>

<script>
  import { HTTP } from '@/auth'
  import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'

  export default {
    name: 'ChecklistCreateEditSubtask',
    components: {
      BasicCreateEditContainer,
    },
    data () {
      return {
        subtask: {
          id: null,
          name: ''
        },
        dataOriginal: null,
        dataLoaded: false,
        mode: null,
        error: null,
        deletionHintText: '',
        deletionHintList: []
      }
    },
    created() {
      this.subtask.id = this.$route.params.id
      if (this.subtask.id) {
        this.getSubtask()
        this.mode = 'edit'
      } else {
        this.mode = 'create'
        this.dataOriginal = JSON.parse(JSON.stringify(this.subtask))
        this.dataLoaded = true
      }
    },
    computed: {
      hasChanges() {
        return JSON.stringify(this.subtask) !== JSON.stringify(this.dataOriginal)
      },
    },
    methods: {
      t: function (key) {
        return this.$t('checklists.subtasks.' + key)
      },
      getSubtask() {
        HTTP.get('elements/' + this.subtask.id).then(function (response) {
          this.subtask = response.data;
          this.deletionHintText = this.getUsageInfo().deletionHintText
          this.deletionHintList = this.getUsageInfo().deletionHintList
          this.dataOriginal = JSON.parse(JSON.stringify(this.subtask))
          this.dataLoaded = true;
        }.bind(this)).catch(function (error) {
          if (error.response.status && error.response.status === 404) {
              this.$root.infoNotification.showMessage(this.$t('checklists.subtasks.create.not-found', { subtaskId: this.subtask.id }))
          } else {
            this.$root.infoNotification.showMessage(this.t('create.unknown-error') + error.response.statusText)
          }
          this.close()
        }.bind(this))
      },
      saveSubtask () {
        if (this.mode == 'create') {
          return HTTP.post('elements', this.subtask)
        } else {
          return HTTP.patch('elements/' + this.subtask.id, this.subtask)
        }
      },
      onSaved() {
        this.dataOriginal = null
        this.close()
      },
      deleteSubtask () {
        return HTTP.delete('elements/' + this.subtask.id)
      },
      onDeleted() {
        this.dataOriginal = null
        this.close()
      },
      getUsageInfo () {
        let usages = this.subtask.usages
        return {
          deletionHintText: usages.length > 0 ? this.$t('checklists.subtasks.deletion-hint', { subtaskName: this.subtask.name }) : '',
          deletionHintList: this.subtask.usages.map((u) => u.name)
        }
      },
      close() {
        this.$router.push({ name: 'ChecklistElements' })
      }
    }
  }
</script>
