<template>
  <div>
    <v-text-field
        :label="t('phone')"
        v-model="value.phone"
        data-cy="phone"
        filled
    />

    <v-text-field
        :label="t('mobile')"
        v-model="value.mobile_phone"
        data-cy="mobile_phone"
        filled
    />

    <v-text-field
        :label="t('email')"
        v-model="value.email"
        data-cy="email"
        filled
    />

    <div v-if="addressType === 'private'">
      <app-filter-date-picker
          :filterLabel="t('birth_date')"
          v-model="value.birth_date"
          id="dateStart"
          data-cy="birth_date"
          :clearable="true"
      />
      <app-filter-date-picker
          :filterLabel="t('die_date')"
          v-model="value.die_date"
          id="dateStart"
          data-cy="die_date"
          :clearable="true"
      />
      <v-textarea
          :label="t('mediate_from')"
          v-model="value.mediate_from"
          data-cy="mediate_from"
          filled
      />
      <v-textarea
          :label="t('family')"
          v-model="value.family"
          data-cy="family"
          filled
      />
    </div>

    <v-textarea
        :label="t('notes')"
        v-model="value.notes"
        data-cy="notes"
        filled
    />
    <div v-if="addressType === 'private'">
      <span>{{ t('cooperation') }}</span>
      <v-row class="mt-1">
        <v-col>
          <app-filter-date-picker
              :filterLabel="t('cooperation_start_date')"
              v-model="value.cooperation_start_date"
              data-cy="cooperation_start_date"
              id="dateStart"
              :clearable="true"
          />
        </v-col>
        <v-col>
          <app-filter-date-picker
              :filterLabel="t('cooperation_end_date')"
              v-model="value.cooperation_end_date"
              data-cy="cooperation_end_date"
              id="dateStart"
              :clearable="true"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import SalutationSelect from '@/components/SalutationSelect.vue'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker.vue'

export default {
  name: "CustomerEditExtensionFields",
  components: { AppFilterDatePicker, SalutationSelect },
  props: {
    value: Object,
    addressType: String,
  },
  data() {
    return {}
  },
  methods: {
    t: function (key) {
      return this.$t('customers.edit.extension-fields.' + key);
    },
  }
}
</script>
