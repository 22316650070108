import SettingsIdeaBox from "../../views/SettingsIdeaBox";

const ideaboxRoutes = [
  {
    path: '/settings/ideabox',
    name: 'SettingsIdeaBox',
    component: SettingsIdeaBox,
    meta: {
      accessConfig:  {
        moduleName: ['ideabox'],
        requiresAuth: true
      }
    }
  },
]

export default ideaboxRoutes
