const themesFsa = {
  themes: {
    light: {
      primary: '#1B4A82',
      secondary: '#87ADDA',
      success: '#4CAF50',
      warning: '#FB8C00',
      error: '#FF5252',
      buttons:'#1B4A82'
    }
  },
}

export default themesFsa
