<template>
  <div>
    <v-card
        elevation="0"
    >
      <v-card-title>{{ t('details.group-title') }}</v-card-title>
      <v-card-text>

        <v-text-field
            :label="t('details.name')"
            v-model="item.name"
            counter="120"
            filled
            :rules="[
								() => !!item.name || $t('validation.required.default'),
								() => (item.name && item.name.length <= 120) || $t('validation.length.default')
							]"
        />
        <v-text-field
            :label="t('details.code')"
            v-model="item.fs_code"
            counter="120"
            filled
        />
        {{ t("details.type") }}
        <v-radio-group v-model="item.calculation_type_code">
          <v-radio
              :label="$t('expense-type.data.type.AT_COST')"
              value="AT_COST"
          />
          <div v-if="item.calculation_type_code === 'AT_COST'">
          </div>

          <v-radio
              :label="$t('expense-type.data.type.HOURLY_RATE')"
              value="HOURLY_RATE"
          />
          <div v-if="item.calculation_type_code === 'HOURLY_RATE'">
          </div>


          <v-radio
              :label="$t('expense-type.data.type.DAILY_RATE')"
              value="DAILY_RATE"
          />
          <div v-if="item.calculation_type_code === 'DAILY_RATE'">
          </div>


          <v-radio
              :label="$t('expense-type.data.type.FACTOR')"
              value="FACTOR"
          />
          <div v-if="item.calculation_type_code === 'FACTOR'">
            <v-text-field
                :label="t('details.unit')"
                v-model="item.unit"
                counter="10"
                filled
            />

          </div>
        </v-radio-group>

        <v-checkbox v-model="item.is_active" :label="t('details.active')"/>
      </v-card-text>
    </v-card>
    <v-card
        elevation="0"
        class="sp-mar-top-3"
    >
      <v-card-title>{{ t('reimbursement.group-title') }}</v-card-title>
      <v-card-text>
        <v-checkbox
            :label="t('details.is_reimbursed')"
            v-model="item.is_reimbursed"
        />
        <v-text-field
            v-if="showReimburseFactor"
            :label="t('details.employee_factor')"
            v-model="item.employee_factor"
            filled
            type="number"
            step="0.01"
            :rules="[() => validateReimbursementFactor]"
        />
      </v-card-text>
    </v-card>

    <v-card
        elevation="0"
        class="sp-mar-top-3"
    >
      <v-card-title>{{ t('documents.group-title') }}</v-card-title>
      <v-card-text>
        <v-checkbox
            :label="t('details.requires_receipt')"
            v-model="item.requires_receipt"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DeleteButton from "@/components/inputs/DeleteButton";
import BasicEditContainer from "@/components/inputs/BasicEditContainer";

export default {
  components: {
    BasicEditContainer,
    DeleteButton,
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      item: {
        requires_receipt: false,
        is_reimbursed: false,
        unit: '',
        employee_factor: 0,
        calculation_type_code: '',
      },
    }
  },
  created() {
    console.log("created")
    console.log(this.value)
    this.item = {...this.value}
  },
  mounted() {
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
    showReimburseFactor() {
      return this.item.is_reimbursed && this.item.calculation_type_code !== 'AT_COST'
    },
    validateReimbursementFactor() {
      if (!this.showReimburseFactor) {
        return true
      }
      if (this.item.employee_factor) {
        return true
      }
      return this.$t('errors.rules.required', {0: this.t('details.employee_factor')})
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.item = {...newVal};
      },
      deep: true
    },
    item: {
      handler(newVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.value)) {
          this.$emit('input', newVal);
        }
      },
      deep: true
    }
  },
  methods: {
    t: function (key) {
      return this.$t('expense-type.detail.' + key);
    },
  },
}
</script>

<style scoped lang="scss">

</style>