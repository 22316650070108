<template>
  <div>

    <BasicNewContainer
        :title="t('title')"
        ref="editor"
        @cancel="close"
        :saveAction="saveItem"
        @saved="onSaved"
        :has-changes="hasChanges && dateApproved">
      <template>
        <v-card elevation="0">
          <v-card-title>{{ t('details-group-title') }}</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <content-filter-employee
                      @item-selected="userSelected"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-date-picker
                      format="dd.MM.YYYY"
                      value-type="date"
                      v-if="data.user_id"
                      v-model="data.date"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                      filled
                      :label="t('details-customer')"
                      :title="t('details-customer')"
                      v-if="data.user_id"
                      :items="customers"
                      item-text="display_name"
                      item-value="id"
                      v-model="data.customer_id"
                      v-on:change="customerSelected"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                      filled
                      :label="t('details-project')"
                      :title="t('details-project')"
                      v-if="data.user_id && data.customer_id"
                      :items="projects"
                      item-text="name"
                      item-value="id"
                      v-model="data.project_id"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="config.record_mode === 'hours'">
                  <v-text-field
                      filled
                      :label="t('details-hours')"
                      hide-details="auto"
                      type="number"
                      v-if="data.user_id && data.customer_id"
                      v-model="data.duration_hours"
                      :rules="[
                        () => !!data.duration_hours || $t('validation.required.default'),
                        () => (data.duration_hours && data.duration_hours > 0) || $t('validation.number.must-be-positive')
                      ]"
                  />
                </v-col>
                <v-col v-if="config.record_mode === 'fromto'">
                  <v-time-picker
                      ref="timer"
                      format="24hr"
                      class="v-time-picker-custom"
                      v-if="data.user_id && data.customer_id"
                      v-model="data.from"
                      full-width
                      :timezone="'UTC'"
                  />
                </v-col>
                <v-col v-if="config.record_mode === 'fromto'">
                  <v-time-picker
                      ref="timer"
                      format="24hr"
                      class="v-time-picker-custom"
                      v-if="data.user_id && data.customer_id"
                      v-model="data.to"
                      full-width
                      :timezone="'UTC'"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                      filled
                      :label="t('details-description')"
                      v-if="data.user_id && data.customer_id"
                      v-model="data.comment"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>


      </template>

    </BasicNewContainer>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import ContentFilterEmployee from "@/components/filter-components/ContentFilterEmployee";
import BasicNewContainer from "@/components/inputs/BasicNewContainer";

export default {
  name: 'TimeCreate',
  components: {
    BasicNewContainer,
    ContentFilterEmployee,
  },
  data() {
    return {
      config: {
        record_mode: null,
      },
      data: {
        user_id: null,
        date: this.getCurrentDateIso(),
        customer_id: null,
        project_id: null,
        duration_hours: 0,
        comment: '',
        fromUTC: '',
        toUTC: '',
        from: null,
        to: null
      },
      customers: [],
      projects: [],
    }
  },
  async created() {
    await this.loadConfig()
  },
  computed: {
    hasChanges() {
      return this.data.user_id && this.data.customer_id;
    },
    dateApproved() {
      return (this.config.record_mode == "fromto" && this.data.from != null && this.data.to != null) ||  (this.config.record_mode == "hours" && this.data.duration_hours > 0);
    }
  },
  methods: {
    t: function (key) {
      return this.$t('time-create.' + key)
    },
    saveItem() {

      if (this.config.record_mode == "fromto") {

        // Parse date and time strings
        let [year, month, day] = this.data.date.split('-').map(Number);
        let [hourFrom, minuteFrom] = this.data.from.split(':').map(Number);
        let [hourTo, minuteTo] = this.data.to.split(':').map(Number);

        // Create new Date object
        let originalDateFrom = new Date(year, month - 1, day, hourFrom, minuteFrom);
        let originalDateTo = new Date(year, month - 1, day, hourTo, minuteTo);

        // Get current timezone offset in milliseconds
        let timezoneOffset = originalDateFrom.getTimezoneOffset() * 60 * 1000;

        // Adjust the date by subtracting the offset milliseconds
        let adjustedDateFrom = new Date(originalDateFrom.getTime() + timezoneOffset);
        let adjustedDateTo = new Date(originalDateTo.getTime() + timezoneOffset);


        // Get adjusted date and time strings
        let adjustedDateStringFrom = adjustedDateFrom.toISOString().split('T')[0];
        let adjustedTimeStringFrom = adjustedDateFrom.toTimeString().split(' ')[0];
        let adjustedTimeStringTo = adjustedDateTo.toTimeString().split(' ')[0];

        // Update this.data.date and this.data.from with adjusted strings
        this.data.date =  adjustedDateStringFrom;
        this.data.fromUTC = adjustedTimeStringFrom;
        this.data.toUTC = adjustedTimeStringTo;
      }

      return HTTP.post('timelog', JSON.stringify(this.data))
    },
    async onSaved() {
      this.close()
    },
    close() {
      this.$router.go(-1)
    },
    async userSelected(id) {
      this.data.user_id = id
      await this.loadCustomers()
    },
    async customerSelected(item) {
      this.projects = this.customers.find(z => z.id === item).projects
    },
    async loadConfig() {
      let response = await HTTP.get('timelog/settings')
      this.config.record_mode = response.data.record_mode
    },
    async loadCustomers() {
      let userId = this.data.user_id
      let response = await HTTP.get('timelog/customers?userId=' + userId)
      this.customers = response.data
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

