import {defineStore, acceptHMRUpdate} from 'pinia'
import moment from 'moment'

const store = defineStore('AbsenceFilterStore', {
    state: () => {
        return {
            filter: {
                selectedUser: null,
                dateStart: moment().subtract(6, 'months').format('YYYY-MM-DD'),
                dateEnd: moment().add(6, 'months').format('YYYY-MM-DD'),
                status: ['open', 'approved', 'declined'],
                reason: [],
            },
            appliedFilter: '',
            table: {
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: ['start_date'],
                sortDesc: [false]
            },
        }
    },
    getters: {
        hasChanges: (state) => {
            return JSON.stringify(state.filter) !== JSON.stringify(state.appliedFilter)
        }
    },
    actions: {
        filterApplied() {
            this.appliedFilter = {...this.filter}
        }
    },
    persist: true,
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const useAbsenceFilterStore = store

