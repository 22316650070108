<template>
    <v-select
        class=""
        v-model="selectedFilterStatus"
        :label="label"
        :items="filterStatus"
        item-text="title"
        item-value="id"
        chips
        :data-cy="dataCy"
    ></v-select>
</template>

<script>
import { HTTP } from '@/auth'

export default {
    name : "QCNotificationTypeFilter",
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
        dataCy: {
            default: ''
        },
        filterLabel: String,
        model: {
            type: [Number, Array],
            default: () => [null]       
        }
    },
    data() {
        return {
            selectedFilterStatus: null,
            filterStatus: [],
        }
    },
    watch: {
        selectedFilterStatus: function (val) {
            this.$emit('input', val)
        },
        model: function(val) {
            this.selectedFilterStatus = val;
        }
    },
    created() {
        this.getNotificationTypes()
    },
    computed: {
        label() {
            return this.filterLabel !== '' ? this.filterLabel : this.$t('components.qc-notifications-type.status-filter.label')
        }
    },
    methods: {
        async getNotificationTypes() {
            await HTTP.get('notification_types').then((response) => {
                this.filterStatus = response.data
            })
        }
    }
}
</script>