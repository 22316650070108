import {defineStore, acceptHMRUpdate} from 'pinia'

const store = defineStore('UserFilterStore', {
    state: () => {
        return {
            filter: {
                searchTerm: '',
                roles: ['admin', 'coordinator', 'employee'],
                onlyActive: true,
                permission_groups: []
            },
            appliedFilter: '',
            table: {
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: ['name'],
                sortDesc: [false]
            },
        }
    },
    getters: {
        hasChanges: (state) => {
            return JSON.stringify(state.filter) !== JSON.stringify(state.appliedFilter)
        }
    },
    actions: {
        filterApplied() {
            this.appliedFilter = {...this.filter}
        }
    },
    persist: true,
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const useUserFilterStore = store

