<template>
  <div>

    <toolbar :dataLoaded="true">{{ t('edit.title') }}
      <span v-if="!jobsData.deleted_at">{{ t('edit.title-edit') }}</span>
      <span v-if="jobsData.deleted_at">{{ t('edit.title-readonly') }}</span>
    </toolbar>

    <v-container fluid class="edit-screen">

      <loading :error="errorLoading" :dataLoaded="dataLoaded"></loading>

      <v-form ref="form">

        <v-row v-if="dataLoaded">

          <!-- Left Column -->
          <v-col class="content-area" cols="12" sm="12" md="8">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ t('details.group-title') }}</v-card-title>
              <v-card-text>

                <v-text-field
                    label="Titel*"
                    counter="120"
                    filled
                    :disabled="jobsData.deleted_at != null"
                    v-model="jobsData.title"
                    :rules="[
								() => !!jobsData.title || $t('validation.required.title'),
								() => (jobsData.title && jobsData.title.length <= 120) || $t('validation.length.title')
							]"
                ></v-text-field>

                <v-text-field
                    label="Kurze Beschreibung (Preview)*"
                    counter="120"
                    filled
                    :disabled="jobsData.deleted_at != null"
                    v-model="jobsData.preview_text"
                    :rules="[
								() => !!jobsData.preview_text || $t('validation.required.description'),
								() => (jobsData.preview_text && jobsData.preview_text.length <= 120) || $t('validation.length.description')
							]"
                ></v-text-field>

              </v-card-text>
            </v-card>

            <v-card
                v-if="!hasNewBlocksContent || convertToBlocksContent"
                elevation="0"
                class="sp-mar-top-3"
            >
              <v-card-title>
                {{ t('old-content-readonly.group-title') }}
                <v-btn
                    v-if="!convertToBlocksContent"
                    elevation="0"
                    color="primary"
                    outlined
                    class="icon-left"
                    @click="convert"
                >
                  <v-icon>mdi-refresh</v-icon>
                  {{ t('old-content-readonly.add-new-content') }}
                </v-btn>
              </v-card-title>
              <v-card-text>

                <span v-html="jobsData.content"></span>

              </v-card-text>
            </v-card>

            <v-card
                v-if="hasNewBlocksContent || convertToBlocksContent"
                elevation="0"
                class="sp-mar-top-3"
            >
              <v-card-title>{{ $t('components.blocks-editor.label') + '*' }}</v-card-title>
              <v-card-text>

                <v-menu
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        outlined
                        class="icon-left blocks-button"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-plus</v-icon>
                      {{ $t('components.blocks-editor.add-content') }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                        v-for="(blockitem, index) in blocksList"
                        :key="index"
                        class="blocks-list-item"
                    >
                      <v-list-item-title
                          @click="addBlock(blockitem.name)"
                      >
                        <v-icon>{{ blockitem.icon }}</v-icon>
                        {{ blockitem.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                    v-if="blocks.length > 0"
                    color="primary"
                    outlined
                    class="icon-left phone-preview"
                    @click="phonePreview"
                >
                  <v-icon>mdi-cellphone-check</v-icon>
                  {{ $t('components.blocks-editor.preview') }}
                </v-btn>

                <div v-show="isSubmitted && !hasBlockContent" class="has-no-content">
                  <v-icon>mdi-info</v-icon>
                  {{ $t('validation.required.blocks-editor') }}
                </div>

                <draggable element="div" v-model="blocks" :sort="true" class="blocks-wrapper">

                  <v-card
                      elevation="0"
                      class="block-element"
                      v-for="(block, index) in blocks"
                      :key="index"
                  >
                    <v-card-title>
                      <v-icon>mdi-drag</v-icon>
                      {{ block.name }}
                      <span class="move-block">
										    <span v-if="index != 0" @click="moveupBlock(index)" class="moveup-block"
                              :title="$t('components.blocks-editor.moveup')"><v-icon>mdi-arrow-up-thin</v-icon></span>
										    <span v-if="index != blocks.length - 1" @click="movedownBlock(index)" class="movedown-block"
                              :title="$t('components.blocks-editor.movedown')"><v-icon>mdi-arrow-down-thin</v-icon></span>
									    </span>
                      <span @click="removeBlock(index)" class="remove-block"
                            :title="$t('components.blocks-editor.remove')"><v-icon>mdi-delete-outline</v-icon></span>
                    </v-card-title>
                    <v-card-text>

                      <div v-if="block.name == 'Text'">

                        <tiptap-vuetify
                            v-model="block.content"
                            :extensions="extensions"
                            :card-props="{ flat: true }"
                            @focus="editorFocused = true"
                            @blur="editorFocused = false"
                        />

                      </div>

                      <div v-if="block.name == 'Bild'">

                        <input
                            style="display:none;"
                            :ref="'input-' + index"
                            type="file"
                            :name="'image-' + index"
                            accept="image/*" @change="setBlockImage($event, index)"
                        />

                        <section v-show="block.showCropper" class="cropper-area">
                          <div class="img-cropper">
                            <vue-cropper
                                :ref="'cropper-' + index"
                                :src="block.content"
                                :preview="'.preview-' + index"
                                :zoomable="false"
                                :checkCrossOrigin="false"
                                :responsive="false"
                                :autoCropArea="1"
                            >
                            </vue-cropper>
                          </div>
                        </section>

                        <section v-show="!block.showCropper && block.cropImg" class="preview-area">
                          <div class="block-preview" :class="'preview-' + index">
                            <div class="cropped-image">
                              <img
                                  v-if="block.cropImg"
                                  :src="block.cropImg"
                                  alt="Cropped Image"
                              />
                            </div>
                          </div>
                        </section>

                        <div v-show="!block.showCropper" class="sp-float-right">
                          <v-btn
                              elevation="0"
                              color="primary"
                              outlined
                              class="icon-left"
                              @click="showBlockFileChooser(index)"
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('components.blocks-editor.blocks.image.upload') }}
                          </v-btn>
                        </div>

                        <div v-show="block.showCropper" class="sp-float-left">
                          <v-btn
                              elevation="0"
                              color="primary"
                              outlined
                              class="icon-left"
                              @click="resetBlockCropper(index)"
                          >
                            <v-icon>mdi-close</v-icon>
                            {{ $t('components.blocks-editor.blocks.image.cancel') }}
                          </v-btn>
                        </div>

                        <div v-show="block.showCropper" class="sp-float-right">
                          <v-btn
                              elevation="0"
                              color="primary"
                              outlined
                              class="icon-left"
                              @click="cropBlockImage(index)"
                          >
                            <v-icon>mdi-crop</v-icon>
                            {{ $t('components.blocks-editor.blocks.image.crop') }}
                          </v-btn>
                        </div>

                      </div>

                      <div v-if="block.name == 'Video'">

                        <input
                            style="display:none;"
                            :ref="'input-' + index"
                            type="file"
                            :name="'video-' + index"
                            accept="video/*" @change="setBlockVideo($event, index)"
                        />

                        <span v-if="block.video == '' && !block.sizeExceeded" class="video-size-hint">
                          <v-icon>mdi-information-outline</v-icon> {{
                            $t('components.blocks-editor.blocks.video.max-size')
                          }}: 32MB</span>
                        <span v-if="block.sizeExceeded" class="video-size-error">
                          <v-icon>mdi-alert-outline</v-icon> {{
                            $t('components.blocks-editor.blocks.video.size-exceeded')
                          }} (32MB)</span>

                        <div v-if="block.isUploading">
                          <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
                        </div>

                        <div
                            v-if="block.video && !block.isUploading && (typeof block.video === 'string' || block.video instanceof String)">

                          <div class="video-wrapper">
                            <video :src="block.video" controls></video>
                          </div>

                        </div>

                        <div v-if="block.video && !block.isUploading && block.video instanceof Object">

                          <div class="video-wrapper">
                            <video :src="tempVideoSrc(block.video)" controls></video>
                          </div>

                        </div>

                        <div v-show="true" class="sp-float-right">
                          <v-btn
                              elevation="0"
                              color="primary"
                              outlined
                              class="icon-left"
                              @click="showBlockVideoChooser(index)"
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('components.blocks-editor.blocks.video.upload') }}
                          </v-btn>
                        </div>

                      </div>

                      <div v-if="block.name == 'Youtube'">

                        <v-text-field
                            label="Youtube-ID*"
                            counter="11"
                            v-model="block.content"
                            filled
                            :rules="[
                                  () => !!block.content || $t('validation.required.youtube-id'),
                                  () => (block.content && block.content.length == 11) || $t('validation.length.youtube-id')
                            ]"
                            @keyup="(event) => block.content = getYoutubeId(event.target.value)"
                        ></v-text-field>

                      </div>

                    </v-card-text>
                  </v-card>

                </draggable>

                <v-menu
                    v-if="blocks.length > 2"
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        outlined
                        class="icon-left blocks-button blocks-bottom-button"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-plus</v-icon>
                      {{ $t('components.blocks-editor.add-content') }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                        v-for="(blockitem, index) in blocksList"
                        :key="index"
                        class="blocks-list-item"
                    >
                      <v-list-item-title
                          @click="addBlock(blockitem.name)"
                      >
                        <v-icon>{{ blockitem.icon }}</v-icon>
                        {{ blockitem.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                    v-if="blocks.length > 2"
                    color="primary"
                    outlined
                    class="icon-left phone-preview-bottom"
                    @click="phonePreview"
                >
                  <v-icon>mdi-cellphone-check</v-icon>
                  {{ $t('components.blocks-editor.preview') }}
                </v-btn>

              </v-card-text>
            </v-card>

          </v-col>

          <!-- Right Column -->
          <v-col class="meta-area" cols="12" sm="12" md="4">

            <!-- Kategorie -->
            <v-card
                elevation="0"
            >
              <v-card-title>{{ $t('components.category-select.label') + '*' }}</v-card-title>
              <v-card-text>

                <v-select
                    filled
                    :title="$t('components.category-select.select') + '*'"
                    :label="$t('components.category-select.select') + '*'"
                    v-model="jobsData.category_id"
                    :items="categories"
                    :disabled="jobsData.deleted_at != null"
                    item-text="name"
                    item-value="id"
                    :rules="[() => !!jobsData.category_id || $t('validation.required.category')]"
                ></v-select>

              </v-card-text>
            </v-card>

            <v-card
                elevation="0"
                class="sp-mar-top-3"
            >
              <v-card-title>{{ $t('components.post-push-settings.label') }}</v-card-title>
              <v-card-text>

                <div v-if="jobsData.pending_cronjob" class="pending-push-notification">

                  <span><v-icon>mdi-information-outline</v-icon>{{
                      $t('components.post-push-settings.push-pending')
                    }}:<br>{{
                      initialjobsData.published_at | momentDate
                    }} {{ initialjobsData.published_at | momentTime }} </span>

                  <v-btn
                      color="warning"
                      elevation="0"
                      class="icon-left push-abort"
                      @click="pushAbort()"
                  >
                    <v-icon>mdi-cancel</v-icon>
                    {{ $t('components.post-push-settings.cancel-push') }}
                  </v-btn>

                </div>

                <div
                    v-if="initialjobsData.published_at && (isPublished(initialjobsData.published_at) || isArchived(initialjobsData.deleted_at))"
                    class="push-notification">

                  <v-btn
                      color="primary"
                      elevation="0"
                      class="icon-left push-abort"
                      :disabled="!isPublished(jobsData.published_at)"
                      @click="push()"
                  >
                    <v-icon>mdi-bell</v-icon>
                    {{ $t('components.post-push-settings.do-push') }}
                  </v-btn>

                  <div v-if="jobsData.pushed_at" class="push-date">
                    <span>{{ $t('components.post-push-settings.last-push-at') }}:<br>{{
                        jobsData.pushed_at | momentDate
                      }} {{ jobsData.pushed_at | momentTime }} </span>
                  </div>

                </div>

                <div
                    v-if="!jobsData.published_at || (!isPublished(initialjobsData.published_at) && !jobsData.pending_cronjob && status === 'published')">

                  <v-radio-group v-model="pushStatus">
                    <v-radio
                        key="manual"
                        :label="$t('components.post-push-settings.status.manual')"
                        value="manual"
                    ></v-radio>
                    <v-radio
                        key="automatic"
                        :label="$t('components.post-push-settings.status.auto')"
                        value="automatic"
                    ></v-radio>
                  </v-radio-group>

                </div>

              </v-card-text>
            </v-card>

            <v-card
                elevation="0"
                class="sp-mar-top-3"
            >
              <v-card-title>{{ $t('components.post-publish-settings.label') }}</v-card-title>
              <v-card-text>

                <div
                    v-show="initialjobsData.published_at && !isPublished(initialjobsData.published_at) && status ==='published'"
                    class="pending-publish-notification">
                  <span><v-icon>mdi-information-outline</v-icon> {{
                      $t('components.post-publish-settings.publish-pending')
                    }}<br>
                    {{ initialjobsData.published_at | momentDate }} {{ initialjobsData.published_at | momentTime }} Uhr</span>
                </div>

                <v-radio-group v-model="status">
                  <v-radio
                      key="published"
                      :label="$t('components.post-publish-settings.status.published')"
                      value="published"
                  ></v-radio>
                  <div v-if="!isPublished(initialjobsData.published_at) && status ==='published'"
                       class="in-radio-position">
                    <app-date-time-picker
                        v-model="jobsData.published_at"
                        date-format="dd.MM.yyyy"
                        time-format="HH:mm"
                        :id="'publishedDate'"
                        :clearable="true"
                        :label="$t('components.post-publish-settings.publish-ondate')"
                    ></app-date-time-picker>
                    <span class="publish-error" v-if="invalidPublishDate">{{
                        $t('validation.required.publish-date')
                      }}</span>
                  </div>
                  <v-radio
                      key="draft"
                      :label="$t('components.post-publish-settings.status.draft')"
                      value="draft"
                  ></v-radio>
                  <v-radio
                      key="deleted"
                      :label="$t('components.post-publish-settings.status.archived')"
                      value="deleted"
                  ></v-radio>
                </v-radio-group>

              </v-card-text>
            </v-card>

            <v-card
                elevation="0"
                class="sp-mar-top-3"
            >
              <v-card-title>{{ $t('components.post-read-analytics.label') }}</v-card-title>
              <v-card-text>

                <div :class="{ 'is-loading': !analyticsLoaded }">

                  <div class="sp-flex sp-flex-dir-col full-width" v-if="analyticsLoaded">
                    <div>{{ $t('components.post-read-analytics.read') }}:
                      {{ analytics.read_total + '/' + analytics.total }}
                    </div>
                    <div @click="showAnalyticsUsers = !showAnalyticsUsers" class="show-analytics-users"
                         v-if="analytics.read_total > 0">
                      <md-icon>supervisor_account</md-icon>
                      {{ $t('components.post-read-analytics.show-users') }}
                    </div>
                    <div v-if="showAnalyticsUsers">
                      <div v-for="analytics_user in analytics.users" :key="analytics_user.id" class="analytics-user">
                        {{ analytics_user }}
                      </div>
                    </div>
                  </div>

                  <div class="sp-flex sp-flex-dir-col" v-if="analyticsError">
                    <span>{{ $t('components.post-read-analytics.error-onload') }}</span>
                  </div>

                </div>

              </v-card-text>
            </v-card>

            <v-card elevation="0" class="sp-mar-top-3">
              <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
              <v-card-text>
                <permission-group-selector
                    v-model="jobsData.assigned_permission_groups"
                    :required="true"
                    mode="dropdown"/>

              </v-card-text>
            </v-card>

          </v-col>

        </v-row>

      </v-form>

    </v-container>

    <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        timeout="4000"
    >
      {{ errorMessage }}
    </v-snackbar>

    <v-dialog
        v-model="previewDialog"
        width="414"
        persistent
        content-class="preview-dialog custom-class-dialog"
    >
      <v-card>
        <v-card-title>
          {{ $t('components.blocks-editor.preview') }}
        </v-card-title>
        <v-card-text>

          <div
              class="preview-element"
              v-for="(block, index) in blocks"
              :key="index"
          >

            <div v-if="block.name == 'Text'" v-html="block.content" class="text-block-preview"></div>

            <div v-if="block.name == 'Bild'" class="image-block-preview">

              <img :src="block.cropImg">

            </div>

            <div v-if="block.name == 'Video' && block.content != '' && !block.isUploading" class="video-block-preview">

              <video :src="tempVideoSrc(block.video)" controls></video>

            </div>

            <div v-if="block.name == 'Video' && block.content == '' && !block.isUploading" class="video-block-preview">

              <video :src="block.video" controls></video>

            </div>

            <div v-if="block.name == 'Youtube'" class="youtube-block-preview">

              <div style="position:relative;overflow:hidden;padding-bottom:56.25%;margin:16px 0;">
                <iframe
                    :src="getYoutubeSrc(block.content)"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
                    style="position:absolute;width:100%;height:100%;"
                ></iframe>
              </div>

            </div>

          </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              elevation="0"
              class="icon-left"
              @click="previewDialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
            {{ $t('components.blocks-editor.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <cancel-button-dialog
                ref="cancelButtonDialog"
                :go-to-name="indexRouteName"
                :data-edited="dataEdited"
                @cancelEditConfirmed="getUserConfirmResponse"
            ></cancel-button-dialog>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                elevation="0"
                class="icon-left"
                :disabled="!hasNewBlocksContent && !wantToArchive"
                :loading="processing"
                @click="status === 'deleted' ? deleteItem() : submit()"
            >
              <v-icon>mdi-check</v-icon>
              {{ $t('components.basic-edit-container.save') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

  </div>

</template>
<script>
import {HTTP} from './../auth'
import {EventBus} from './../event-bus'
import loading from '../components/layouts/Loading.vue'
import toolbar from '../components/layouts/Navigation.vue'
import AppDateTimePicker from '../components/vuetify/AppDateTimePicker'
import PermissionGroupSelector from "@/components/PermissionGroupSelector.vue"


import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
import draggable from 'vuedraggable'
import moment from 'moment'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
// dialog to confirm cancel if data edited
import CancelButtonDialog from "../components/layouts/CancelButtonDialog";

export default {
  name: 'JobsEdit',
  components: {
    CancelButtonDialog,
    toolbar,
    loading,
    AppDateTimePicker,
    TiptapVuetify,
    draggable,
    VueCropper,
    PermissionGroupSelector
  },
  data() {
    return {
      itemId: null,
      processing: false,
      isPublishedAtChanged: false,
      jobsData: {
        title: null,
        preview_text: null,
        blocks: [],
        videos: [],
        published_at: null,
        assigned_categories: [],
        assigned_permission_groups: [],
      },
      data: {},
      pushData: {
        type: 'jobs',
        id: null,
        source: 'request'
      },
      status: '',
      pushStatus: 'manual',
      categories: null,
      dataLoaded: false,
      errorLoading: false,
      error: null,
      errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.',
      cancelHTTP: null,
      errorCancel: null,
      initialjobsData: {},
      analytics: {},
      showAnalyticsUsers: false,
      analyticsLoaded: false,
      analyticsError: false,
      wysiwygIsValid: true,
      isSubmitted: false,
      changedValues: false,
      invalidPublishDate: false,
      snackbar: false,
      dialog: false,
      previewDialog: false,
      extensions: [
        History,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Paragraph,
        HardBreak
      ],
      blocks: [],
      editorFocused: false,
      hasBlockContent: false,
      changedThumbnail: false,
      hasNewBlocksContent: false,
      convertToBlocksContent: false,
      dataEdited: false,
      cancelConfirmed: false,
      indexRouteName: 'Jobs'
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY');
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm');
    }
  },
  created() {
    this.itemId = this.$route.params.id
    EventBus.$on('reload-item', function () {
      this.getJobsPost()
      this.changedValues = true
    }.bind(this))
    this.getCategories()
  },
  computed: {
    blocksList() {
      return [
        {
          name: this.$t('components.blocks-editor.blocks.text.group-title'),
          icon: 'mdi-text-box-plus'
        },
        {
          name: this.$t('components.blocks-editor.blocks.image.group-title'),
          icon: 'mdi-file-image-plus-outline'
        },
        {
          name: this.$t('components.blocks-editor.blocks.video.group-title'),
          icon: 'mdi-video-plus-outline'
        },
        {
          name: this.$t('components.blocks-editor.blocks.youtube.group-title'),
          icon: 'mdi-youtube'
        }
      ]
    },
    wantToArchive() {
      // possibility to archive old post without new content-blocks
      return this.status == 'deleted' && this.jobsData.deleted_at === null
    }
  },
  beforeRouteLeave(to, from, next) {
    // prompt if data edited and user leaves page via back browser button
    if (this.dataEdited && !this.cancelConfirmed) {
      this.$refs.cancelButtonDialog.cancel()
      next(false)
    } else {
      next()
    }
  },
  beforeDestroy() {
    EventBus.$off('reload-item')
  },
  methods: {
    t: function (key) {
      return this.$t('jobs.detail.' + key);
    },
    // required for beforeRouteLeave hook
    getUserConfirmResponse(response) {
      this.cancelConfirmed = response
    },
    // Watch for changes if data edited
    fireWatcherForDataChange() {
      this.$watch(
          (vm) => [vm.jobsData, vm.blocks, vm.status],
          (newVal, oldVal) => {
            if (oldVal != undefined) {
              this.dataEdited = true
            }
          },
          {
            immediate: true,
            deep: true,
          }
      );
    },
    moment: function () {
      return moment();
    },
    getCategories() {
      HTTP.get('jobs/categories?sortBy[]=name').then(function (response) {
        this.categories = response.data.data
        this.getJobsPost()
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    isPublished(publishedAt) {
      return moment().isAfter(publishedAt)
    },
    isArchived(deletedAt) {
      return moment().isAfter(deletedAt)
    },
    setPushData() {
      this.pushData.id = this.jobsData.id
    },
    getJobsPost() {
      HTTP.get('jobs/' + this.itemId)
          .then(function (response) {
            this.jobsData = response.data


            const tempPermGroupsIds = []
            this.jobsData.assigned_permission_groups.forEach((element) => {
              tempPermGroupsIds.push(element.permission_group_id)
            })
            this.jobsData.assigned_permission_groups = tempPermGroupsIds

            this.hasNewBlocksContent = this.jobsData.blocks.length > 0
            
            this.initialjobsData = Object.assign({}, this.jobsData)

            if (this.jobsData.deleted_at != null) {
              this.status = 'deleted'
            } else if (this.initialjobsData.published_at || this.isPublished(this.initialjobsData.published_at)) {
              this.status = 'published'
            } else {
              this.status = 'draft'
            }

            response.data.blocks.forEach((element) => {
              this.blocks.push(element)
            })

            this.setPushData()

            this.dataLoaded = true
            this.dataEdited = false

            this.getAnalyticsData()
            this.fireWatcherForDataChange()

          }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    getAnalyticsData() {
      HTTP.get('analytics/jobs/' + this.itemId)
          .then(function (response) {
            this.analytics = response.data
            this.analyticsLoaded = true
          }.bind(this))
          .catch(function (error) {
            this.analyticsError = true
          }.bind(this))
    },
    validateBlockContent() {
      if (this.blocks.length > 0) {
        this.blocks.forEach((block) => {
          if (block.name == 'Text' && block.content != '') {
            this.hasBlockContent = true
            return
          }
          if (block.name == 'Video' && block.video != '') {
            this.hasBlockContent = true
            return
          }
          if (block.name == 'Bild' && block.cropImg != '') {
            this.hasBlockContent = true
            return
          }
          if (block.name == 'Youtube' && block.content != '') {
            this.hasBlockContent = true
            return
          }
        })
      }
    },
    addBlock(name) {
      switch (name) {
        case 'Bild':
          this.blocks.push({
            name: name,
            content: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAABAAEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9/KKKKAP/2Q==',
            cropImg: '',
            showCropper: false,
            changedImage: false,
            order: null
          })
          break
        case 'Video':
          this.blocks.push({
            name: name,
            content: '',
            video: '',
            isUploading: false,
            order: null
          })
          break
        default:
          this.blocks.push({
            name: name,
            content: '',
            order: null
          })
      }
    },
    removeBlock(index) {
      this.blocks.splice(index, 1)
    },
    moveupBlock(index) {
      let temp = this.blocks[index]
      this.removeBlock(index)
      this.blocks.splice(index - 1, 0, temp)
    },
    movedownBlock(index) {
      let temp = this.blocks[index]
      this.removeBlock(index)
      this.blocks.splice(index + 1, 0, temp)
    },
    showBlockFileChooser(index) {
      this.$refs['input-' + index][0].click()
    },
    showBlockVideoChooser(index) {
      this.$refs['input-' + index][0].click()
    },
    setBlockImage(e, index) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Bitte ein Bild auswählen')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.blocks[index].content = event.target.result
          this.$refs['cropper-' + index][0].replace(event.target.result)
          this.blocks[index].showCropper = true
          this.$refs['input-' + index][0].value = null
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    setBlockVideo(e, index) {
      this.blocks[index].isUploading = true
      const video = e.target.files[0]
      if (video.type.indexOf('video/') === -1) {
        alert('Bitte eine Videodatei auswählen')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          let sizeExceeded = video.size > 32768000
          this.blocks[index].sizeExceeded = sizeExceeded
          if (!sizeExceeded) {
            this.blocks[index].content = video.name
            this.blocks[index].video = video
            this.blocks[index].changedVideo = true
          }
          this.blocks[index].isUploading = false
          this.$refs['input-' + index][0].value = null
        }
        reader.readAsDataURL(video)
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    cropBlockImage(index) {
      this.blocks[index].showCropper = false
      this.blocks[index].content = this.$refs['cropper-' + index][0].getCroppedCanvas().toDataURL()
      this.blocks[index].cropImg = this.blocks[index].content
      this.blocks[index].changedImage = true
      this.refreshBlock(index)
    },
    resetBlockCropper(index) {
      this.$refs['cropper-' + index][0].replace(this.blocks[index].cropImg)
      this.blocks[index].content = this.blocks[index].cropImg
      this.$refs['input-' + index][0].value = null
      this.blocks[index].showCropper = false
      this.refreshBlock(index)
    },
    refreshBlock(index) {
      this.$nextTick(() => {
        let temp = this.blocks[index]
        this.removeBlock(index)
        this.$nextTick(() => {
          this.blocks.splice(index, 0, temp)
        })
      })
    },
    tempVideoSrc(video) {
      if (this.editorFocused) {
        return
      }
      return URL.createObjectURL(video)
    },
    phonePreview() {
      this.previewDialog = true
    },
    getYoutubeSrc(youtubeID) {
      return 'https://www.youtube.com/embed/' + youtubeID + '?rel=0'
    },
    getYoutubeId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      if (url.length > 11 && match) {
        return match && match[2].length === 11 ? match[2] : undefined;
      }
      return url
    },
    submit() {
      this.isSubmitted = true

      this.validateBlockContent()

      if (this.status === 'published' && !this.jobsData.published_at) {
        this.invalidPublishDate = true
        return
      }

      if (this.$refs.form.validate() && this.hasBlockContent) {

        if (this.processing === true) {
          return
        }
        this.processing = true
        this.dataLoaded = false

        let formData = new FormData()

        this.jobsData.videos = []
        this.jobsData.blocks = []
        this.blocks.forEach((block, index) => {
          block.order = index
          if (block.name == 'Text') {
            this.jobsData.blocks.push(block)
          }
          if (block.name == 'Bild') {
            this.jobsData.blocks.push(block)
          }
          if (block.name == 'Youtube') {
            this.jobsData.blocks.push(block)
          }
          if (block.name == 'Video') {
            this.jobsData.blocks.push({
              id: block.id,
              name: 'Video',
              order: index,
              changedVideo: block.changedVideo
            })
            if (block.changedVideo) {
              formData.append('video-' + index, block.video)
            }
            this.jobsData.videos.push(index)
          }
        })

        formData.append('data', JSON.stringify(this.jobsData))
        formData.append('status', this.status)

        const config = {
          headers: {'content-type': 'multipart/form-data'}
        }

        HTTP.post('jobs/' + this.jobsData.id, formData, config)
            .then(function (response) {

              this.data = response.data

              if (this.pushStatus === 'automatic' && this.status === 'published') {

                const config = {
                  headers: {'content-type': 'application/json'}
                }

                var cronData = {}
                cronData.id = response.data.id
                cronData.type = this.pushData.type
                cronData.publish_date = this.jobsData.published_at

                HTTP.post('cron', cronData, config)
                    .then(function (response) {
                      //
                    })
                    .catch(function (error) {
                      console.log(error)
                    }.bind(this))

              }

              if (this.pushStatus === 'manual' && this.isPublishedAtChanged) {

                const config = {
                  headers: {'content-type': 'application/json'}
                }

                var cronDataUpdate = {}
                cronDataUpdate.id = response.data.id
                cronDataUpdate.type = this.pushData.type
                cronDataUpdate.publish_date = this.jobsData.published_at

                HTTP.post('updatecron', cronDataUpdate, config)
                    .then(function (response) {
                      //
                    })
                    .catch(function (error) {
                      console.log(error)
                    }.bind(this))

              }

              if (this.status === 'draft') {

                const config = {
                  headers: {'content-type': 'application/json'}
                }

                var cronDataAbort = {}
                cronDataAbort.id = this.jobsData.id
                cronDataAbort.type = this.pushData.type

                HTTP.post('abortcron', cronDataAbort, config)
                    .then(function (response) {
                      //
                    })
                    .catch(function (error) {
                      console.log(error)
                    }.bind(this))

              }

              this.processing = false
              this.dataEdited = false
              this.$router.push({name: this.indexRouteName})

            }.bind(this))
            .catch(function (error) {
              if (error.response.data.errors) {
                this.message = error.response.data.message
              }
              this.errorMessage = 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.'
              this.openSnackbar()
              console.log(error.response)
              this.processing = false
            }.bind(this))
      }
    },
    push() {
      const config = {
        headers: {'content-type': 'application/json'}
      }

      HTTP.post('push', this.pushData, config)
          .then(function (response) {
            EventBus.$emit('reload-item')
          })
          .catch(function (error) {
            console.log(error)
          }.bind(this))
    },
    pushAbort() {
      const config = {
        headers: {'content-type': 'application/json'}
      }

      var cronData = {}
      cronData.id = this.jobsData.id
      cronData.type = this.pushData.type

      HTTP.post('abortcron', cronData, config)
          .then(function (response) {
            EventBus.$emit('reload-item')
          })
          .catch(function (error) {
            console.log(error)
          }.bind(this))
    },
    deleteItem() {
      if (this.jobsData.deleted_at) {
        this.errorMessage = 'Job bereits archiviert'
        this.openSnackbar()
      } else {
        HTTP.delete('jobs/' + this.jobsData.id, this.data)
            .then(function (response) {
              this.dataEdited = false
              this.$router.push({name: this.indexRouteName})
            }.bind(this)).catch(function (error) {
          this.error = error
          this.openSnackbar()
        }.bind(this))
      }
    },
    convert() {
      this.hasNewBlocksContent = true
      this.convertToBlocksContent = true
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 2000)
    }
  }
}
</script>

<style lang="scss">

/* Temp */
.menubar-wrap.fixed {
  position: static !important;
  width: 100%;
  padding: 0;
}

</style>

<style lang="scss" scoped>

.push-notification button {
  margin: 0;
}

.push-date {
  margin-top: 16px;
}

.show-analytics-users {
  display: inline-block;
  margin: 10px 0;
  cursor: pointer;
}

.show-analytics-users i {
  color: rgba(0, 0, 0, 0.54);
}

.analytics-user {
  padding: 10px;
}

.analytics-user:nth-child(odd) {
  background: #f7f7f7;
}

.cropper-area {
  margin-bottom: 20px;
}

.preview-area {
  width: 50%;
  max-width: 400px;
  margin-bottom: 20px;
}

.preview {
  width: 100% !important;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  border: 1px solid rgba(227, 227, 227, 0.7);
}

.preview img {
  width: 100% !important;
  height: 100% !important;
}

.cropped-image img {
  max-width: 100%;
}

.push-notification button {
  margin: 0;
}

.push-date {
  margin-top: 16px;
}

.in-radio-position {
  padding: 10px 0 20px 28px;
  max-width: calc(100% - 12px);
}

.pending-push-notification {
  color: var(--v-error-base);
}

.pending-publish-notification {
  color: var(--v-error-base);
}

.pending-push-notification span,
.pending-publish-notification span {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 16px;
}

.pending-push-notification span i,
.pending-publish-notification span i {
  position: absolute;
  left: 0;
  display: inline-block;
  color: var(--v-error-base);
  vertical-align: middle;
  line-height: 20px;
}

.pending-push-notification + .push-notification {
  margin-top: 16px;
}

.push-abort {
  // margin-top: 10px;
}

.push-abort i {
  color: inherit;
  position: relative;
  top: -2px;
}

.publish-error {
  display: inline-block;
  padding: 10px 0 0 0;
  font-size: 12px;
  color: var(--v-error-base);
}

.blocks-bottom-button,
.phone-preview-bottom {
  margin-top: 16px;
}

.blocks-list-item {
  cursor: pointer;
  padding: 0px 10px;

  i {
    margin-right: 8px;
  }

  &:hover {
    background: #F5F5F5;
  }
}

.block-element {
  margin-top: 16px;

  .v-card__title {
    cursor: grab;
    padding-right: 72px;
    background: #F5F5F5;
    border-bottom: 1px solid #E0E0E0;
  }
}

.remove-block {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 56px;
  height: 56px;
  padding: 16px;
  border-left: 1px solid #E0E0E0;
  transition: background 0.3s;

  &:hover {
    background: #FFFFFF;
  }
}

.move-block {
  position: absolute;
  right: 56px;
  top: 0;
  width: 112px;
  height: 56px;
  text-align: right;

  & > span {
    cursor: pointer;
    display: inline-block;
    width: 56px;
    height: 56px;
    padding: 16px;
    border-left: 1px solid #E0E0E0;
    transition: background 0.3s;

    &:hover {
      background: #FFFFFF;
    }
  }
}

.video-wrapper {
  height: 240px;
  margin-bottom: 16px;

  video {
    height: 240px;
  }
}

.video-size-hint,
.video-size-error {
  line-height: 36px;
}

.video-size-error {
  color: var(--v-error-base);

  i {
    color: var(--v-error-base);
  }
}

.phone-preview,
.phone-preview-bottom {
  float: right;
}

.preview-dialog {

  .v-card__text {
    padding: 16px !important;

    &.has-preview-header {
      padding: calc(56.25% + 16px) 16px 16px 16px !important;

      .preview-page-header {
        position: absolute;
        top: 0;
        left: -1px;
        width: calc(100% + 2px);

        img {
          min-width: 100%;
          border-bottom: 1px solid #F5F5F5;
        }
      }
    }
  }

  .preview-element {
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .video-block-preview video {
    width: 100%;
  }

  .text-block-preview {
    ::v-deep p {
      margin-bottom: 0.5em;
    }
  }
}

.has-no-content {
  color: #FF5252 !important;
  height: 20px;
  font-size: 12px;
  margin-top: 12px;
}
</style>

