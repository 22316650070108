<template>
    <div>

        <BasicCreateEditContainer
            :title="t('header_title')"
            ref="editor"
            :data-loaded="dataLoaded"
            @cancel="cancel"
            :save-action="saveItem"
            @saved="close"
            :allow-delete="false"
            :has-changes="hasChanges"
            :hidden-field-validation-message="$t('menu.validation.hidden-field-validation')"
        >
            <template v-slot:content>
                
                <v-card elevation="0">

                    <v-card-title>{{ t('details-title') }}</v-card-title>

                    <v-card-text>
                        
                        <div v-show="data.type == 'module'">

                            <loading v-if="!showModules"></loading>
                            <div v-else>
                                <div class="module-wrapper">
                                    <v-btn
                                        v-for="(item,index) in modules" v-if="showModules && item.active && activeMenuItems.includes(item.id)"
                                        class="chooseIconButton"
                                        elevation="0"
                                        disabled
                                        >
                                            <v-icon class="v-icon">{{ item.icon }}</v-icon>
                                            {{ item.title_de }}
                                            <v-icon>
                                                mdi-check-bold
                                            </v-icon>
                                            
                                    </v-btn>
                                </div>

                                <div class="module-wrapper" >
                                    <v-btn
                                        v-for="(item,index) in modules" v-if="showModules && item.active && !activeMenuItems.includes(item.id)"
                                        class="chooseIconButton"
                                        elevation="0"
                                        outlined
                                        @click="setActiveButton(item)"
                                        >
                                            <v-icon class="v-icon">{{ item.icon }}</v-icon>
                                            {{ item.title_de }}
                                            <v-icon v-if="data.module_id == item.id">
                                                mdi-chevron-down-circle-outline
                                            </v-icon>
                                            <v-icon v-else>
                                                mdi-circle-outline
                                            </v-icon>
                                    </v-btn>
                                </div>

                                <div>{{ t('other-modules') }}</div>
                                <div class="module-wrapper">
                                    <v-btn
                                        v-for="(item,index) in modules" v-if="showModules && !item.active"
                                        class="chooseIconButton"
                                        elevation="0"
                                        disabled
                                        >
                                            <v-icon class="v-icon">{{ item.icon }}</v-icon>
                                            {{ item.title_de }}
                                    </v-btn>
                                </div>
                                
                                <div class="mt-4" v-show="data.type == 'module'">
                                    <a href="mailto:michael.fretz@cubera.ch">{{ t('extend-solution') }}</a>
                                </div>
                            </div>
                        </div>

                        <choose-icon v-model="data.icon" :compact="true"></choose-icon>

                        <v-text-field
                            class="mt-8"
                            :label="t('title') + ' EN*'"
                            v-model="data.title_en"
                            :counter="maxTitleLength"
                            filled
                            :rules="[
                                () => !!data.title_en || $t('validation.required.default'),
                                () => (data.title_en && data.title_en.length <= 40) || $t('menu.validation.length-message')
                            ]"
                        ></v-text-field>

                        <v-text-field
                            :label="t('title') + ' DE*'"
                            v-model="data.title_de"
                            :counter="maxTitleLength"
                            filled
                            :rules="[
                                () => !!data.title_de || $t('validation.required.default'),
                                () => (data.title_de && data.title_de.length <= 40) || $t('menu.validation.length-message')
                            ]"
                        ></v-text-field>

                        <v-text-field
                            :label="t('title') + ' IT*'"
                            v-model="data.title_it"
                            :counter="maxTitleLength"
                            filled
                            :rules="[
                                () => !!data.title_it || $t('validation.required.default'),
                                () => (data.title_it && data.title_it.length <= 40) || $t('menu.validation.length-message')
                            ]"
                        ></v-text-field>

                        <v-text-field
                            :label="t('title') + ' FR*'"
                            v-model="data.title_fr"
                            :counter="maxTitleLength"
                            filled
                            :rules="[
                                () => !!data.title_fr || $t('validation.required.default'),
                                () => (data.title_fr && data.title_fr.length <= 40) || $t('menu.validation.length-message')
                            ]"
                        ></v-text-field>

                        <v-text-field
                            :label="t('title') + ' ES*'"
                            v-model="data.title_es"
                            :counter="maxTitleLength"
                            filled
                            :rules="[
                                () => !!data.title_es || $t('validation.required.default'),
                                () => (data.title_es && data.title_es.length <= 40) || $t('menu.validation.length-message')
                            ]"
                        ></v-text-field>

                        <v-text-field
                            :label="t('title') + ' PT*'"
                            v-model="data.title_pt"
                            :counter="maxTitleLength"
                            filled
                            :rules="[
                                () => !!data.title_pt || $t('validation.required.default'),
                                () => (data.title_pt && data.title_pt.length <= 40) || $t('menu.validation.length-message')
                            ]"
                        ></v-text-field>

                        <v-text-field
                            :label="t('title') + ' TR*'"
                            v-model="data.title_tr"
                            :counter="maxTitleLength"
                            filled
                            :rules="[
                                () => !!data.title_tr || $t('validation.required.default'),
                                () => (data.title_tr && data.title_tr.length <= 40) || $t('menu.validation.length-message')
                            ]"
                        ></v-text-field>

                        <v-text-field
                            :label="t('title') + ' UK*'"
                            v-model="data.title_uk"
                            :counter="maxTitleLength"
                            filled
                            :rules="[
                                () => !!data.title_uk || $t('validation.required.default'),
                                () => (data.title_uk && data.title_uk.length <= 40) || $t('menu.validation.length-message')
                            ]"
                        ></v-text-field>

                        <v-text-field
                            :label="t('title') + ' RU*'"
                            v-model="data.title_ru"
                            :counter="maxTitleLength"
                            filled
                            :rules="[
                                () => !!data.title_ru || $t('validation.required.default'),
                                () => (data.title_ru && data.title_ru.length <= 40) || $t('menu.validation.length-message')
                            ]"
                        ></v-text-field>
                        
                        <v-text-field
                            v-if="data.type == 'webview' || data.type == 'external_browser'"
                            :label="t('url')"
                            v-model="data.url"
                            filled
                            hide-details="auto"
                            :rules="[
                                () => !!data.url || $t('validation.required.default'),
                            ]"
                        ></v-text-field>

                        <v-text-field
                            ref="hiddenValidation"
                            class="hidden"
                            v-model="noIconValue"
                            :rules="[
                                () => !!noIconValue || $t('menu.validation.hidden-field-validation')
                            ]"
                        ></v-text-field>

                        <content-editor v-show="data.type == 'content_block'" v-model="data.blocks"/>
                            
                    </v-card-text>

                </v-card>

            </template>

            <template v-slot:meta>
                <v-card elevation="0">
                <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
                <v-card-text>
                    <permission-group-selector
                        v-model="data.assigned_permission_groups"
                        :required="true"
                        mode="dropdown"/>
                </v-card-text>
                </v-card>
            </template>

        </BasicCreateEditContainer>
    </div>
</template>

<script>
import auth from './../auth/index'
import { HTTP } from '@/auth'
import loading from '@/components/layouts/Loading.vue'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
import ChooseIcon from '@/components/dialogs/ChooseIcon'
import store from 'store'
import ContentEditor from '@/components/ContentEditor.vue'
import PermissionGroupSelector from '@/components/PermissionGroupSelector.vue'

export default {
    name: 'MenuCreate',
    components: {
        loading,
        BasicCreateEditContainer,
        ChooseIcon,
        ContentEditor,
        PermissionGroupSelector
    },
    data() {
        return {
            data: {
                title_en: '',
                title_de: '',
                title_it: '',
                title_fr: '',
                title_es: '',
                title_pt: '',
                title_tr: '',
                title_uk: '',
                title_ru: '',
                url: '',
                icon: '',
                type: '',
                assigned_permission_groups: [],
                module_id: 0,
                blocks: []
            },
            dataLoaded: true,
            showModules: false,
            type: '',
            dataOriginal: null,
            permissionGroups: [],
            modules: store.get('modules'),
            activeMenuItems: [],
            maxTitleLength: 40
        }
    },
    mounted() {
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
    },
    created() {
        this.getPermissionGroups()
        this.getActiveMenuItems()
        this.data.type = this.$route.params.type
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
        },
        noModuleValue() {
            return this.data.module_id > 0 ? 'hasModule' : null
        },
        noIconValue() {
            return this.data.icon !== '' ? 'hasIcon' : null
        }
    },
    methods: {
        setActiveButton(item) {
            if (this.data.module_id != item.id) {
                this.data.module_id = item.id;
                this.data.icon = item.icon;
                this.data.title_en = item.title_en;
                this.data.title_de = item.title_de;
                this.data.title_it = item.title_it;
                this.data.title_fr = item.title_fr;
                this.data.title_es = item.title_es;
                this.data.title_pt = item.title_pt;
                this.data.title_tr = item.title_tr;
                this.data.title_uk = item.title_uk;
                this.data.title_ru = item.title_ru;
            }
        },
        t: function (key) {
            return this.$t('menu.create.' + key)
        },
        async saveItem() {

            let formData = new FormData()

            let i = 0
            this.data.blocks.forEach(z => {
                z.order = i++
                if (z.name === 'Video' && z.new_content) {
                    formData.append('video-' + z.order, z.video)
                }
            })

            formData.append('data', JSON.stringify(this.data))
          
            const config = {
                headers: {'content-type': 'multipart/form-data'}
            }

            await HTTP.post('/menus', formData, config)
            auth.loadConfig()
        },
        close() {
            this.cancel()
        },
        cancel() {
            this.$router.push('/settings/menu')
        },
        getPermissionGroups() {
            HTTP.get('permission-groups?sortBy[]=name').then(function (response) {
                this.permissionGroups = response.data.data
            }.bind(this)).catch(function (error) {
                this.error = error
                this.openSnackbar()
            }.bind(this))
        },
        getActiveMenuItems() {
            HTTP.get('activeMenuItems').then(function (response) {
                this.activeMenuItems = response.data
                this.showModules = true
            }.bind(this)).catch(function (error) {
                this.error = error
                this.openSnackbar()
            }.bind(this))
        }
    }
}
</script>

<style scoped lang="scss">
    .module-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 16px 0;
    }

    .chooseIconButton .v-icon {
        margin: 5px;
    }
</style>
