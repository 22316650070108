<template>
  <div v-if="dataLoaded">

    <toolbar :dataLoaded="true">{{ t('create.title') }}</toolbar>

    <v-container fluid class="edit-screen">

      <v-form ref="form">

        <v-row>

          <!-- Left Column -->
          <v-col class="content-area" cols="12" sm="12" md="8">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ t('details.group-title') }}</v-card-title>
              <v-card-text>

                <v-text-field
                    :label="t('details.title') + '*'"
                    counter="120"
                    v-model="data.title"
                    filled
                    :rules="[
								() => !!data.title || $t('validation.required.title'),
								() => (data.title && data.title.length <= 120) || $t('validation.length.title')
							]"
                ></v-text-field>

                <v-textarea
                    :label="t('details.description')"
                    class="no-validation"
                    v-model="data.description"
                    filled
                ></v-textarea>

                <div class="custom-label">{{ t('details.time-range') }}</div>

                <div class="survey-start">
                  <app-date-time-picker
                      :label="t('details.startdate') + '*'"
                      v-model="data.start_date"
                      date-format="dd.MM.yyyy"
                      :id="'startDate'"
                      :clearable="false"
                  ></app-date-time-picker>
                  <span v-if="isSubmitted && !data.start_date" class="md-error">{{
                      $t('validation.required.start-date')
                    }}</span>
                </div>

                <div class="survey-end">
                  <app-date-time-picker
                      :label="t('details.enddate') + '*'"
                      v-model="data.end_date"
                      date-format="dd.MM.yyyy"
                      :id="'endDate'"
                      :clearable="false"
                  ></app-date-time-picker>
                  <span v-if="isSubmitted && !data.end_date" class="md-error">{{
                      $t('validation.required.end-date')
                    }}</span>
                </div>

                <div class="custom-label sp-mar-top-3">{{ t('details.is-anonym') }}</div>

                <v-switch
                    v-model="data.anonymized"
                    inset
                    :label="data.anonymized ? t('details.yes') : t('details.no')"
                ></v-switch>

              </v-card-text>
            </v-card>

          </v-col>


          <!-- Right Column -->
          <v-col class="meta-area" cols="12" sm="12" md="4">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
              <v-card-text>

                <v-select
                    :title="$t('components.permissiongroup-select.select')"
                    :label="$t('components.permissiongroup-select.select')"
                    v-model="data.assigned_permission_groups"
                    :items="permissionGroups"
                    multiple
                    filled
                    item-text="name"
                    item-value="id"
                    :rules="[() => data.assigned_permission_groups.length > 0 || $t('validation.required.permission-groups')]"
                >
                  <template slot="item" slot-scope="data">
                    {{ data.item.name }} ({{ data.item.user_count }})
                  </template>
                </v-select>

              </v-card-text>
            </v-card>

            <v-card
                elevation="0"
                class="sp-mar-top-3"
            >
              <v-card-title>{{ t('push.group-title') }}</v-card-title>
              <v-card-text>

                <v-switch
                    v-model="pushOnStart"
                    inset
                    :label="pushOnStart ? t('push.yes') : t('push.no')"
                ></v-switch>

              </v-card-text>
            </v-card>

          </v-col>

        </v-row>

        <v-row>

          <!-- Left Column -->
          <v-col class="content-area" cols="12" sm="12" md="8">

            <v-card
                elevation="0"
            >
              <v-card-title v-if="!isEditQuestion">{{ t('add-question.group-title') }}</v-card-title>
              <v-card-title v-else>{{ t('add-question.group-title-edit') }}</v-card-title>
              <v-card-text>

                <v-select
                    :title="t('add-question.question-type') + '*'"
                    :label="t('add-question.question-type') + '*'"
                    v-model="selectedQuestionType"
                    v-on:change="onselectedQuestionTypeChange"
                    :items="questionTypes"
                    item-text="name"
                    item-value="id"
                    filled

                ></v-select>

                <div class="question-description">{{ selectedQuestionTypeDescription }}</div>

                <v-text-field
                    :label="t('add-question.question-title') + '*'"
                    :title="t('add-question.question-title') + '*'"
                    v-model="selectedQuestionTitle"
                    filled
                ></v-text-field>

                <v-textarea
                    :label="t('add-question.question-description')"
                    :title="t('add-question.question-description')"
                    class="no-validation"
                    v-model="selectedQuestionDescription"
                    filled
                ></v-textarea>

                <div class="sp-mar-bot-3" v-if="[2, 3].includes(selectedQuestionType)">
                  <div class="custom-label">{{ t('add-question.add-choice') + '*' }}</div>
                  <div class="sp-mar-bot-1 selected-options-list">
                    <div v-for="(option, index) in selectedOptions" :key="index">
                      <md-button @click.native="removeOption(index)" class="sp-text-blue sp-mar-r-0"
                                 :title="t('add-question.add')">
                        <md-icon class="sp-text-blue">do_not_disturb_on</md-icon>
                      </md-button>
                      <span>{{ option }}</span>
                    </div>
                  </div>
                  <div class="add-option">
                    <md-button @click.native="addOption()" class="sp-text-blue sp-mar-r-0"
                               :title="t('add-question.add')">
                      <md-icon class="sp-text-blue">add_circle</md-icon>
                    </md-button>
                    <md-input-container>
                      <md-input v-model="selectedOption" name="questionOption"></md-input>
                    </md-input-container>
                  </div>
                </div>

                <div class="button-wrapper" v-if="selectedQuestionType && selectedQuestionTitle && validOptions">

                  <v-btn
                      v-if="!isEditQuestion"
                      color="primary"
                      elevation="0"
                      outlined
                      class="sp-float-right icon-left"
                      @click="addQuestion()"
                  >
                    <v-icon>mdi-plus</v-icon>
                    {{ t('add-question.add') }}
                  </v-btn>

                  <v-btn
                      v-if="isEditQuestion"
                      color="primary"
                      text
                      class="icon-left"
                      @click="cancelUpdateQuestion()"
                  >
                    <v-icon>mdi-close</v-icon>
                    {{ t('add-question.cancel') }}
                  </v-btn>

                  <v-btn
                      v-if="isEditQuestion"
                      color="primary"
                      elevation="0"
                      outlined
                      class="sp-float-right icon-left"
                      @click="updateQuestion()"
                  >
                    <v-icon>mdi-check</v-icon>
                    {{ t('add-question.update') }}
                  </v-btn>

                </div>

              </v-card-text>
            </v-card>

          </v-col>

          <!-- Right Column -->
          <v-col class="meta-area" cols="12" sm="12" md="4">

            <v-card
                elevation="0"
            >
              <v-card-title>{{ t('add-question.components') + '*' }}</v-card-title>
              <v-card-text class="drag-wrapper">

                <draggable element="div" v-model="questions" :sort="true">
                  <div class="drag-element" v-for="(question, index) in questions" :key="index">
                    <div class="sp-float-left question-label">{{ question.title }}</div>
                    <md-icon class="sp-text-blue sp-float-right cta-icon" @click.native="editQuestion(question, index)"
                             :title="t('add-question.edit')">edit
                    </md-icon>
                    <md-icon class="sp-text-blue sp-float-right cta-icon"
                             @click.native="deleteQuestion(question, index)" :title="t('add-question.delete')">delete
                    </md-icon>
                    <div class="sp-clearfix"></div>
                  </div>
                </draggable>
                <div v-if="isEditQuestion" class="disable-drag"></div>
                <span v-if="isSubmitted && !questions.length"
                      class="md-error">{{ $t('validation.required.question-components') }}</span>

              </v-card-text>
            </v-card>

          </v-col>

        </v-row>

      </v-form>

    </v-container>

    <v-snackbar
        ref="snackbar"
        v-model="snackbar"
        timeout="4000"
    >
      {{ errorMessage }}
    </v-snackbar>

    <transition name="fade">
      <div class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <cancel-button-dialog
                ref="cancelButtonDialog"
                :go-to-name="indexRouteName"
                :data-edited="dataEdited"
                @cancel="doCancel">
              >
            </cancel-button-dialog>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                elevation="0"
                outlined
                class="icon-left"
                @click="onSubmit(false)"
            >
              <v-icon>mdi-content-save</v-icon>
              {{ t('draft') }}
            </v-btn>
            <v-btn
                color="primary"
                elevation="0"
                class="icon-left"
                @click="onSubmit(true)"
            >
              <v-icon>mdi-check</v-icon>
              {{ t('publish') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </transition>

  </div>
</template>

<script>
import {HTTP} from './../auth'
import {EventBus} from './../event-bus'
import toolbar from '../components/layouts/Navigation.vue'
import AppDateTimePicker from '../components/vuetify/AppDateTimePicker'
import moment from 'moment'
import draggable from 'vuedraggable'
import CancelButtonDialog from "../components/layouts/CancelButtonDialog"

export default {
  name: 'SurveysCreate',
  components: {
    toolbar,
    CancelButtonDialog,
    AppDateTimePicker,
    draggable
  },
  data() {
    return {
      processing: false,
      loaded: {
        permissionGroups: false,
      },
      data: {
        title: null,
        description: null,
        start_date: null,
        end_date: null,
        status: 'DRAFT',
        anonymized: false,
        assigned_permission_groups: []
      },
      questions: [],
      selectedQuestionType: null,
      selectedQuestionTypeDescription: '',
      selectedQuestionTitle: null,
      selectedQuestionDescription: null,
      selectedOption: null,
      selectedOptions: [],
      isEditQuestion: false,
      editQuestionIndex: null,
      permissionGroups: null,
      pushOnStart: false,
      isSubmitted: false,
      error: null,
      errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.',
      cancelHTTP: null,
      errorCancel: null,
      snackbar: false,
      dataEdited: false,
      cancelConfirmed: false,
      indexRouteName: 'Surveys',
    }
  },
  emits: ['cancel'],
  created() {
    this.getPermissionGroups()
  },
  computed: {
    dataLoaded: function () {
      let allLoaded = this.loaded.permissionGroups
      if (allLoaded && !this.watcherAttached) {
        this.fireWatcherForDataChange()
      }
      return allLoaded
    },
    validOptions: function () {
      return [1, 4].includes(this.selectedQuestionType) || [2, 3].includes(this.selectedQuestionType) && this.selectedOptions.length > 0
    },
    questionTypes() {
      return [
        {
          id: 1,
          name: this.t('add-question.types.rating.title'),
          description: this.t('add-question.types.rating.description')
        },
        {
          id: 2,
          name: this.t('add-question.types.single-choice.title'),
          description: this.t('add-question.types.single-choice.description')
        },
        {
          id: 3,
          name: this.t('add-question.types.multiple-choice.title'),
          description: this.t('add-question.types.multiple-choice.description')
        },
        {
          id: 4,
          name: this.t('add-question.types.text-input.title'),
          description: this.t('add-question.types.text-input.description')
        }
      ]
    }
  },
  methods: {
    t: function (key) {
      return this.$t('surveys.detail.' + key);
    },
    doCancel() {
      this.$emit('cancel');
    },
    // required for beforeRouteLeave hook
    getUserConfirmResponse(response) {
      this.cancelConfirmed = response
    },
    // Watch for changes if data edited
    fireWatcherForDataChange() {
      this.watcherAttached = true
      this.$watch(
          (vm) => [vm.data, vm.data.assigned_permission_groups, vm.questions],
          (newVal, oldVal) => {
            if (oldVal != undefined) {
              this.dataEdited = true
            }
          },
          {
            immediate: true,
            deep: true,
          }
      );
    },
    getPermissionGroups() {
      HTTP.get('permission-groups?sortBy[]=name').then(function (response) {
        this.permissionGroups = response.data.data
        this.loaded.permissionGroups = true
      }.bind(this)).catch(function (error) {
        this.error = error
        this.openSnackbar()
      }.bind(this))
    },
    onSubmit(publish) {
      this.isSubmitted = true
      this.dataEdited = false

      if (this.$refs.form.validate() && this.data.start_date && this.data.end_date && this.questions.length > 0) {

        if (this.processing === true) {
          return
        }
        this.processing = true

        this.data.status = publish ? 'PUBLISHED' : 'DRAFT'
        this.data.push_on_start = this.pushOnStart

        this.reorderAndAddQuestions()

        const json = JSON.stringify({
          surveyData: this.data
        })

        HTTP.post('surveys', {'data': json})
            .then(function (response) {

              if (publish && this.pushOnStart) {

                // send push if immediate running, else add push cronjob
                if (this.isRunning(response.data)) {

                  const config = {
                    headers: {'content-type': 'application/json'}
                  }

                  var pushData = {}
                  pushData.id = response.data.id
                  pushData.type = 'surveys'
                  pushData.source = 'request'

                  HTTP.post('push', pushData, config)
                      .then(function (response) {
                        //
                      })
                      .catch(function (error) {
                        console.log(error)
                      }.bind(this))

                } else {

                  const config = {
                    headers: {'content-type': 'application/json'}
                  }

                  var cronData = {}
                  cronData.id = response.data.id
                  cronData.type = 'surveys'
                  cronData.publish_date = this.data.start_date

                  HTTP.post('cron', cronData, config)
                      .then(function (response) {
                        //
                      })
                      .catch(function (error) {
                        console.log(error)
                      }.bind(this))

                }

              }

              this.$router.push({name: this.indexRouteName})

            }.bind(this))
            .catch(function (error) {
              this.processing = false
              if (error.response.data.errors) {
                this.message = error.response.data.message
                this.openSnackbar()
              }
            }.bind(this))
      }
    },
    addOption() {
      if (this.selectedOption) {
        this.selectedOptions.push(this.selectedOption)
        this.selectedOption = null
      }
    },
    removeOption(index) {
      this.selectedOptions.splice(index, 1)
    },
    addQuestion() {
      // in case user forgets to add the last option
      if (this.selectedOption != null) {
        this.addOption()
      }
      this.questions.push({
        order: this.questions.length + 1,
        type: this.selectedQuestionType,
        title: this.selectedQuestionTitle,
        description: this.selectedQuestionDescription,
        options: [2, 3].includes(this.selectedQuestionType) ? this.selectedOptions : null
      })
      this.resetSelectedQuestion()
    },
    editQuestion(question, index) {
      this.selectedQuestionType = question.type
      this.selectedQuestionTypeDescription = this.questionTypes.filter(type => type.id === question.type)[0].description
      this.selectedQuestionTitle = question.title
      this.selectedQuestionDescription = question.description
      this.selectedOptions = question.options ? question.options : []
      this.isEditQuestion = true
      this.editQuestionIndex = index
    },
    updateQuestion() {
      // in case user forgets to add the last option
      if (this.selectedOption != null) {
        this.addOption()
      }
      let order = this.questions[this.editQuestionIndex].order
      this.questions[this.editQuestionIndex] = {
        order: order,
        type: this.selectedQuestionType,
        title: this.selectedQuestionTitle,
        description: this.selectedQuestionDescription,
        options: [2, 3].includes(this.selectedQuestionType) ? this.selectedOptions : null
      }
      this.cancelUpdateQuestion()
    },
    cancelUpdateQuestion() {
      this.resetSelectedQuestion()
      this.isEditQuestion = false
      this.editQuestionIndex = null
    },
    deleteQuestion(question, index) {
      this.questions.splice(index, 1)
    },
    reorderAndAddQuestions() {
      let order = 1
      this.questions.forEach((element) => {
        element.order = order
        order++
      })
      this.data.questions = this.questions
    },
    resetSelectedQuestion() {
      this.selectedQuestionType = null
      this.selectedQuestionTypeDescription = ''
      this.selectedQuestionTitle = null
      this.selectedQuestionDescription = null
      this.selectedOption = null
      this.selectedOptions = []
    },
    onselectedQuestionTypeChange(event) {
      this.selectedQuestionTypeDescription = this.questionTypes.filter(type => type.id === event)[0].description
      this.selectedOptions = []
    },
    isRunning(survey) {
      return moment().isAfter(survey.start_date) && moment().isBefore(survey.end_date)
    },
    openSnackbar() {
      setTimeout(() => {
        this.snackbar = true
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>

.custom-label {
  clear: both;
  padding: 16px 0;
  font-size: 16px;
}

.custom-label-inline {
  width: 50px;
  float: left;
  line-height: 52px;
  font-size: 16px;
}

.survey-start {
  float: left;
  width: calc(50% - 16px);

  .md-error {
    display: block;
  }
}

.survey-end {
  float: right;
  width: calc(50% - 16px);

  .md-error {
    display: block;
  }
}

.question-description {
  padding: 0 12px 16px 12px;
  min-height: 32px;
  font-style: italic;
  color: #999;
}

.button-wrapper {
  padding-top: 16px;
}

.add-option button,
.selected-options-list button {
  float: left;
  padding: 0;
  margin: 0;
  width: 36px;
  min-width: unset;
}

.selected-options-list span {
  display: block;
  line-height: 36px;
}

.add-option .md-input-container {
  width: calc(100% - 36px);
  padding: 0;
  margin: 0;
  min-height: unset;
}

.disable-drag {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.drag-element {
  display: block;
  padding-right: 10px;
  border: 0;
  cursor: grab;

  &.sortable-chosen {
    cursor: grabbing;
  }

  .cta-icon {
    cursor: pointer;
  }
}

.drag-element .md-icon {
  margin-left: 10px;
}

</style>

