import AbsencesIndex from '@/views/AbsencesIndex'
import AbsencesEdit from '@/views/AbsencesEdit'
import AbsencesReasonsIndex from '@/views/AbsencesReasonsIndex'
import AbsencesReasonsCreate from '@/views/AbsencesReasonsCreate'
import AbsencesReasonsEdit from '@/views/AbsencesReasonsEdit'
import SettingsAbsence from '@/views/SettingsAbsence'

const absencesRoutes = [
    {
        path: '/absences',
        name: 'AbsencesIndex',
        component: AbsencesIndex,
        meta: {
            accessConfig:  {
                moduleName: ['absence'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/absences-reasons',
        name: 'AbsencesReasonsIndex',
        component: AbsencesReasonsIndex,
        meta: {
            accessConfig:  {
                moduleName: ['absence'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/absences-reasons/create',
        name: 'AbsencesReasonsCreate',
        component: AbsencesReasonsCreate,
        meta: {
            accessConfig:  {
                moduleName: ['absence'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/absences-reasons/:id?',
        name: 'AbsencesReasonsEdit',
        component: AbsencesReasonsEdit,
        meta: {
            accessConfig:  {
                moduleName: ['absence'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/absences/:id?',
        name: 'AbsencesEdit',
        component: AbsencesEdit,
        meta: {
            accessConfig:  {
                moduleName: ['absence'],
                requiresAuth: true
            }
        }
    },
    {
        path: '/settings/absence',
        name: 'SettingsAbsence',
        component: SettingsAbsence,
        meta: {
            accessConfig:  {
                moduleName: ['absence'],
                requiresAuth: true
            }
        }
    },
]

export default absencesRoutes
