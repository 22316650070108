<template>
  <div>

    <toolbar :dataLoaded="true">{{ $t('dashboard.title') }}</toolbar>

    <v-container fluid class="dashboard" v-if="modules">

      <h2 v-if="user" class="full-width">{{ $t('dashboard.welcome') }} {{ user.firstname }} {{ user.name }}</h2>

      <v-row class="dashboard-wrapper">

        <dashboard-news-favorites v-if="user != null && moduleEnabled('news') && ['admin'].includes(user.role)"/>

        <dashboard-jobs-favorites v-if="user != null && moduleEnabled('jobs') && ['admin'].includes(user.role)"/>

        <dashboard-absences-open v-if="user != null && moduleEnabled('absence') && ['admin'].includes(user.role)"/>

        <dashboard-surveys-running v-if="user != null && moduleEnabled('surveys') && ['admin'].includes(user.role)"/>

        <dashboard-users-token v-if="user != null && ['admin'].includes(user.role)"/>

        <dashboard-users-os/>

        <dashboard-store-urls/>

        <!-- <dashboard-users-per-day/> -->

      </v-row>

    </v-container>
  </div>
</template>

<script>
import store from 'store'
import { hasModule } from '@/services/LicenseService'
import toolbar from './../components/layouts/Navigation.vue'
import DashboardNewsFavorites from "./dashboard/NewsFavorites";
import DashboardJobsFavorites from "./dashboard/JobsFavorites";
import DashboardAbsencesOpen from "./dashboard/AbsencesOpen";
import DashboardSurveysRunning from "./dashboard/SurveysRunning";
import DashboardUsersToken from "./dashboard/UsersWithToken";
import DashboardStoreUrls from "./dashboard/StoreUrls.vue";
import DashboardUsersOs from "./dashboard/UsersOs.vue";
import DashboardUsersPerDay from "@/views/dashboard/UsersPerDay";

export default {
  name: 'Dashboard',
  components: {
    DashboardUsersPerDay,
    DashboardUsersOs,
    DashboardStoreUrls,
    DashboardNewsFavorites,
    DashboardJobsFavorites,
    DashboardAbsencesOpen,
    DashboardSurveysRunning,
    DashboardUsersToken,
    toolbar
  },
  data() {
    return {
      user: null,
      modules: [],
    }
  },
  mounted() {
    this.user = store.get('user')
  },
  methods: {
    moduleEnabled(string) {
      return hasModule(string)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
