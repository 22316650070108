<template>
  <div>
    <BasicEditContainer
        ref="editor"
        :data-loaded="dataLoaded"
        v-if="dataLoaded"
        :title="t('edit.title')"
        @cancel="cancel"
        :save-action="saveItem"
        @saved="close"
        :allow-delete="false"
        :object-type="$t('expense-type.data.object-type')"
        :object-name="item.name"
        :has-changes="hasChanges">
      <template v-slot:default>
        <expense-types-content v-model="item"/>
      </template>
    </BasicEditContainer>
  </div>
</template>
<script>
import {HTTP} from './../auth'
import DeleteButton from "@/components/inputs/DeleteButton";
import BasicEditContainer from "@/components/inputs/BasicEditContainer";
import ExpenseTypesContent from "@/views/ExpenseTypesContent.vue";

export default {
  components: {
    ExpenseTypesContent,
    BasicEditContainer,
    DeleteButton,
  },
  data() {
    return {
      item: {},
      itemOriginal: null,
      indexRouteName: 'ExpenseTypesIndex',
      dataLoaded: false,
    }
  },
  async created() {
    this.itemId = this.$route.params.id
    await this.getItem()
  },
  mounted() {
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('expense-type.detail.' + key);
    },
    async getItem() {
      let response = await HTTP.get('expense-types/' + this.itemId)
      this.item = response.data
      this.itemOriginal = JSON.parse(JSON.stringify(this.item));
      this.dataLoaded = true
    },
    saveItem() {
      return HTTP.put('expense-types/' + this.itemId, this.item);
    },
    async onSaved() {
      this.dataOriginal = null;
      this.close();
    },
    cancel() {
      this.close();
    },
    close() {
      this.$router.push({name: this.indexRouteName})
    }
  },
}
</script>

<style scoped lang="scss">

</style>

