<template>
  <tr :class="getClass">
    <td v-for="(header, index) in headers" :key="index" :style="'width: ' + header.width + 'px;'">
      <slot :item="item" :name="columnName(header)">
     
        <!-- instruction type -->
        <div v-if="header.value == 'type'" :style="getAlignment(header)">
          <v-icon v-show="item.type == 'document'">mdi-file-pdf-box</v-icon>
          <v-icon v-show="item.type == 'instruction'">mdi-folder-image</v-icon>
          <v-icon v-show="item.type == 'folder'">mdi-folder</v-icon>

        </div>

        <!-- instruction name -->
        <div v-if="header.value == 'name'" :style="getAlignment(header) + cursorPointer(item)" @click="rowClicked(item)">
           {{ item.title }}
        </div>

        <!-- instruction analytics -->
        <div v-if="header.value == 'analytics'" :style="getAlignment(header)">
          <span v-if="(item.type === 'instruction' || item.type === 'document') && item.analytics">{{ item.analytics.read + ' ' + $t('instructions.list.table.from') + ' ' +  item.analytics.total }}</span>
        </div>

        <!-- instruction download -->
        <div v-if="header.value == 'download'" :style="getAlignment(header)">
            <v-icon
              @click.native="downloadFile(item)"
              small
              class="edit-row-button"
              v-show="item.type == 'document'"
              style="cursor:pointer;"
            >
              download
            </v-icon>
        </div>

        <!-- instruction edit -->
        <div v-if="header.value == 'edit'" :style="getAlignment(header)">
          <router-link :to="getDynamicEditPath(item)" class="edit-row-table">
            <v-icon
              small
              class="edit-row-button"
            >
              edit
            </v-icon>
          </router-link>
        </div>

        <!-- instruction delete -->
        <div v-if="header.value == 'delete'" :style="getAlignment(header)">
          <v-icon
            small
            class="edit-row-button"
            @click="openDeleteDialog(rowIndex, item)"
          >
            delete
          </v-icon>
        </div>
        
      </slot>
    </td>
  </tr>
</template>

<script>
  import { EventBus } from '@/event-bus'
  import { HTTP } from './../auth'
  
  export default {
    name: "DataTableRowHandler",
    props: {
      itemClass: {
        type: String,
        default: "",
      },
      rowIndex: {
        type: Number,
        default: 0,
      },
      item: {
        type: Object,
        default: () => {
          return {}
        },
      },
      headers: {
        type: Array,
        default: () => {
          return []
        },
      },
    },
    data() {
      return {}
    },
    computed: {
      getClass() {
        return this.itemClass
      }
    },
    methods: {
      rowClicked(item) {
        if (item.type == 'folder') {
          EventBus.$emit('row-selected', item)
        }
      },
      columnName(header) {
        return `item.${header.value}`
      },
      getAlignment(header) {
        const align = header.align ? header.align : "center"
        return `text-align: ${align}`
      },
      cursorPointer(item) {
        let style = '';
        if (item.type == 'folder') {
          style = '; cursor: pointer;'
        }
        return style;
      },
      getDynamicEditPath(item) {
        return {
          'name': 'Instructions' + item.type.charAt(0).toUpperCase() + item.type.slice(1) + 'Edit',
          'params': { 'id' : item.id}
          
        }
      },
      openDeleteDialog(index, item) {
        EventBus.$emit('open-delete-dialog', index, item)
      },
      deleteItem(index, item) {         
        let url = '';
        if (item.type == 'instruction') {
          url = 'instructions/' + item.id
        } else if (item.type == 'folder') {
          url = 'instructions_folders/' + item.id
        } else if (item.type == 'document') {
          url = 'instructions_documents/' + item.id
        }
        
        HTTP.delete(url)
        .then(function () {
          EventBus.$emit('row-deleted', index)
        }.bind(this))
        .catch(function (error) {
          if (error.response.data.errors) {
            this.message = error.response.data.message
            this.openSnackbar()
          }
        }.bind(this))
      },
      downloadFile(item) {
        HTTP.get('instructions_documents/download/' + item.id, {
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', item.fileName)
          document.body.appendChild(link)
          link.click()
        })
      }
    }
  }
</script>